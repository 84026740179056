import { useEffect, useState } from "react";
import { postApi } from "../../../services/common";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ApplicationWidget1 } from "../../../components/widgets/Applications1";

export const Vfdashboard = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const [count, setCount] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const payload = {
                    UserId: userDetail?.user_id,
                    RoleId: userDetail?.role_id
                }
                const response = await postApi(payload, '/GetModTotalApplicationStatus');
                if (response?.data.success) {
                    setCount(response?.data.data)
                }
                else {
                    toast('Something went wrong')
                }
            } catch (err) {
                toast(err.message || err.massage || 'Something went wrong')
            }
        };

        fetchData();
    }, [])

    return (
        <div>
            <div className="mb-[30px] grid lg:grid-cols-3 lg:gap-x-9 gap-y-9">
                <ApplicationWidget1 title="Total Record" numberOfApplications={count[0]?.TotalRecords || 0} />
                <ApplicationWidget1 title="Kyc Done" numberOfApplications={count[0]?.KYCDone || 0} />
                <ApplicationWidget1 title="Final Rejected" numberOfApplications={count[0]?.TotalReject || 0} />
                <ApplicationWidget1 title="Work In progress" numberOfApplications={count[0]?.TotalInProgress || 0} />
                <ApplicationWidget1 title="Rework" numberOfApplications={count[0]?.TotalRMRework || 0} />
                <ApplicationWidget1 title="Review Pending" numberOfApplications={count[0]?.TotalReviewPending || 0} />
                <ApplicationWidget1 title="SendToLD Pending" numberOfApplications={0} />
            </div>
        </div>
    );
};
