import { MainTitle } from '../../../components/common/MainTitle';
import { Declaration } from './Declaration';
import { Segment } from './Segment';
import { Transaction } from './Transaction';
import { SingleDetail } from '../SingleDetail';
export const BrokerageStep = ({ reviewData,removeSegment,reviewData2}) => {
	const Equity = reviewData?.Equity?.split(',').map(item => item.trim()) || [];
	const Derivative = reviewData?.Derivative !== '' && reviewData?.Derivative !== null
	const Commodity = reviewData?.Commodity !== '' && reviewData?.Commodity !== null;
	const MF = reviewData?.AccountType?.includes('MF');
	console.log(reviewData2,'reviewData2');

	return (
		<div className="w-full pb-2.5 flex flex-col border-b border-solid border-[#D9D9D9]">
			<div className="mb-5">
				<Transaction reviewData={reviewData} />
			</div>
			<div className="mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]">
				<Segment reviewData={reviewData}
				remove={removeSegment}
				/>
			</div>
			<MainTitle title="Brokerage Scheme" />
			<div className="w-full mb-2 grid md:grid-cols-12 gap-2">
				<div className="md:col-span-6">
					<SingleDetail label="Branch Code" value={reviewData?.BranchCode || '-'} />
				</div>
				<div className="md:col-span-6">
					<SingleDetail label="Scheme" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'MyeTrade_PlanA':reviewData?.BrokerageScheme || '-'} />
				</div>
				{Equity &&
					<>
						<div className="md:col-span-6">
							<SingleDetail label="Trading" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18': reviewData?.EquityTrading || '-'} />
						</div>
						<div className="md:col-span-6">
							<SingleDetail label="Delivery :" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.EquityDelivery || '-'} />
						</div>
					</>
				}
				{
					Derivative &&
					<>
						<div className="md:col-span-6">
							<SingleDetail label="Currency Future" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.CurrencyFuture || '-'} />
						</div>
						<div className="md:col-span-6">
							<SingleDetail label="Currency Option :" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.CurrencyOption || '-'} />
						</div></>
				}

				{
					MF &&
					<>
						<div className="md:col-span-6">
							<SingleDetail label="Future" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.EquityFuture || '-'} />
						</div>
						<div className="md:col-span-6">
							<SingleDetail label="Option" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.EquityOption || '-'} />
						</div>
					</>
				}

				{
					Commodity &&
					<>
						<div className="md:col-span-6">
							<SingleDetail label="Commodity Future" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.McxFuture || '-'} />
						</div>

						<div className="md:col-span-6">
							<SingleDetail label="Commodity Option" value={(reviewData?.channel == "Bigul" || reviewData?.channel== "BIGUL") ?'ORD18':reviewData?.McxOption || '-'} />
						</div>
					</>
				}

				<div className="md:col-span-12">
					<SingleDetail label="DP Tariff" value={reviewData?.DPTariff || '-'} />
				</div>

			</div>
			{
					reviewData?.DPCode !== null &&
					<>
					<MainTitle title="Only Trading" />
						<div className="md:col-span-6">
							<SingleDetail label="DP Code" value={reviewData?.DPCode} />
						</div>

						<div className="md:col-span-6">
							<SingleDetail label="Dp Id" value={reviewData?.DPId} />
						</div>
					</>
				}
			{/* <BrokerageScheme reviewData={reviewData} /> */}
			<div>
				<MainTitle title="Declaration & Preferences" />
				<Declaration 
				 reviewData={reviewData || {}}
				 reviewData2={reviewData2 || {}} />
			</div>
			{/* <div className="w-full grid grid-cols-12 md:gap-10">
			<div className="col-span-6">
				<DisplayTab label="As per SEBI" />
			</div>
			<div className="col-span-6">
				<DisplayTab label="Physical" />
			</div>
		</div> */}
		</div>
	)


}
