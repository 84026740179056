import { clearKyc, setChannel, setClientKycStatus, setSendDkycLink, isBankProofUploaded, isIncomeProofUploaded , isSelfieUploaded, isSignatureUploaded} from '../../actions/kyc/kyc.action';

const mutableState = {
	channel: '',
	clientKycStatus: {},
	isSendDkycLink: false,
	isBankProofUploaded: {
		success : false
	},
	isIncomeProofUploaded: {
		success : false
	},
	isSelfieUploaded: {
		success : false
	},
	isSignatureUploaded: {
		success : false
	},
};

const initState = {
	...mutableState
};

export const kycReducer = (state = initState, action) => {
	switch (action.type) {
	case clearKyc:
		return {
			...mutableState
		};
	case setChannel:
		return {
			...state,
			channel: action.payload.channel
		};
	case setClientKycStatus:
		return {
			...state,
			clientKycStatus: action.payload
		};
	case setSendDkycLink:
		return {
			...state,
			isSendDkycLink: action.payload
		};
	case isBankProofUploaded:
		return {
			...state,
			isBankProofUploaded: action.payload
		};
	case isIncomeProofUploaded:
		return {
			...state,
			isIncomeProofUploaded: action.payload
		};
	case isSelfieUploaded:
		return {
			...state,
			isSelfieUploaded: action.payload
		};
	case isSignatureUploaded:
		return {
			...state,
			isSignatureUploaded: action.payload
		};
	default:
		return state;
	}
};
