import react, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { pngAssets, svgAssets } from '../../../assets/asset';
import { BackBtn } from '../../../components/buttons/BackBtn';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { InputBtnIcon } from '../../../components/inputs/InputBtnIcon';
import { ReviewBtn } from '../../../components/buttons/ReviewBtn';
import {
	changeStep8ErrorAction, finalSubmitAction,
	uploadSignatureAction, callSendSignatureLinkAction
} from '../../../redux/actions/kyc/step8.action';
import { UploadSelfie } from '../step7/UploadSelfie';
import { DOMAIN } from '../../../config/config';

export const UploadSignature = ({ step, index, backBtn, continueBtn, reviewBtn }) => {
	const dispatch = useDispatch();
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const { panInput } = useSelector(state => state?.kycStep2 || {});
	const { signatureImage, isError, errorText } = useSelector(state => state?.kycStep8 || '');
	const validatePanDetails = useSelector(state => state?.kycStep2.validatePanDetails.data || {});
	const kycStep8 = useSelector(state => state?.kycStep8 || {});

	const handleImage = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		dispatch(uploadSignatureAction({
			PanNo: panInput?.value || 'CSSPP3435J',
			Document: 'Client Signature',
			DocumentType: 'NA',
			CreatedBy: userDetail.created_by,
			'DocumentImages[]': e.target.files[0]
		}));
	};

	const handleContinueBtn = () => {
		const isValidSignature = signatureImage !== '';

		if (!signatureImage || !isValidSignature) {
			dispatch(changeStep8ErrorAction({ isError: true, errorText: 'invalid input!' }));
		} else {
			dispatch(finalSubmitAction({ mobile: kycStep1.mobileInput.value }));
			setIsSuccessModalOpen(true);
			// continueBtn({ step, index });
		}
	};

	const handleCloseModal = () => {
		setIsSuccessModalOpen(false);
	}

	const handleSendLink = () => {
		dispatch(callSendSignatureLinkAction(
			{
				"name": validatePanDetails.firstName + " " + validatePanDetails.lastName,
				"mobile": kycStep1.mobileInput.value,
				"link":  `${DOMAIN}/dkyc-step4`
			}
		));
	}

	return (
		<div>
			<div className="min-h-[calc(100vh-300px)]">
				<UploadSelfie isChildComp={true} />
				<div className="w-full flex flex-col mb-10">
					<div className="mb-8 grid md:grid-cols-2 gap-x-10 items-end">
						<div className="flex flex-col ">
							<InputBtnIcon
								icon={svgAssets.kyc.signatureInput}
								label="Signature Link"
								// subLabel="Share below link with client"
								isImportant={true}
								placeholder="pqK0QFWZ6lS6VP5ss5iRvx/DKYC-UI?node-id=0%3A"
								btnWidth="67px"
								btnIcon={svgAssets.kyc.kycMessage}
								inputMessage={errorText}
								inputMessageType={isError && 'error'}
								handleSubmit={handleSendLink}
							/>
							{
								kycStep8.isSendLink && <>
									<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
										<div className="grid md:grid-cols-5 gap-1">
											<h6 className="font-poppinsMedium font-medium col-span-2 whitespace-nowrap flex">Signature link send successfully, please check email.
												<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} />
											</h6>
										</div>
										<button className='btn btn-primary bg-black text-white px-3 rounded mt-4' onClick={handleSendLink}>Resend Link</button>
									</div>
								</>
							}
						</div>
						<div className="w-[113px] flex flex-col items-center relative">
							<input
								type="file"
								accept="image/*"
								onChange={(e) => handleImage(e)}
							/>
							{signatureImage
								? <img
									className="w-[113px] h-[113px] rounded-[10px]"
									alt="user_image"
									src={signatureImage}
								/>
								: (<img
									className="w-[113px] h-[113px] p-[13.5px] rounded-[10px] border"
									alt="user_image"
									src={pngAssets.kyc.clientSign}
								/>)
							}
						</div>
						{/* <div className=" flex items-center gap-x-2 px-5 mt-4">
								<img className="w-[19px] h-[19px]" alt="back_icon" src={svgAssets.kyc.greencheck} />
								<span className="col-span-5 text-[#007813] text-[13px] leading-[18px] font-medium font-poppinsMedium ">
									Signature successfully submitted by client
								</span>
							</div> */}
					</div>
				</div>
			</div>
			<Modal
				// title={title || ''}
				open={isSuccessModalOpen}
				onCancel={() => handleCloseModal()}
				centered={true}
				width={900}
				closeIcon={false}
				footer={null}
				className="w-full"
			>
				<div className='py-5'>
					<div className="flex flex-col items-center justify-center min-h-[calc(100vh-300px)]">
						<h6 className="mb-6 text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
							Congrats<br></br>
							You have Successfully completed all the steps
						</h6>
						<img
							className="mb-5"
							alt="success_kyc"
							src={pngAssets.kyc.kycSuccess}
						/>
					</div>
					<div className="flex item justify-center gap-x-3 mt-5">
						{/* <BackBtn handleBackBtn={() => backBtn({ step, index })} /> */}
						<ReviewBtn handleReviewBtn={reviewBtn} />
					</div>
				</div>
			</Modal>

			<div className="flex item justify-between">
				<BackBtn handleBackBtn={() => backBtn({ step, index })} />
				{
					signatureImage === null ?
						<ContinueBtn isDisable={true} />
						:
						<ContinueBtn handleContinueBtn={handleContinueBtn} />
				}
			</div>
		</div>
	);
};
