import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import dayjs from 'dayjs';
import CustomDatePicker from '../../components/inputs/CustomDatePicker';
import { MyPopover } from '../../components/popover/Popover';
import { StepProgressBar } from '../../components/progressBar/ProgressBar';
import { PageCount } from '../../components/reactTable/PageCount';
import { ReactTable } from '../../components/reactTable/ReactTable';
import { ReactTableHeader } from '../../components/reactTable/ReactTableHeader';
import { PopoverChildComp, PopoverParentComp } from '../../components/reactTable/ReactTablePopupBtn';
import { SearchBar } from '../../components/searchbar/SearchBar';
import { verificationSteps } from '../../containers/kyc/kycData';
import { getApplicationAction } from '../../redux/actions/application.action';
import FilterPopover from './filterPopover';
import { TableProgressBar } from '../../components/progressBar/TableProgressBar';

export const ResubmittedApplication = () => {
	const dispatch = useDispatch();
	const [steps, setSteps] = useState(verificationSteps || []);
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const applicationData = useSelector(state => state?.application?.resubmittedApplication?.data || []);
	const [selectedOption, setSelectedOption] = useState('');

	const [isLoading, setIsLoading] = useState(true);
	const [search1, setSearch] = useState('');

	useEffect(() => {
		// setTimeout(() => {
			if(applicationData)
			setIsLoading(false);
		// }, 2000);

	}, []);


	const handleSelectChange = (e) => {
		setSelectedOption(e.target.value);
	  };

	const columns = [
		{
			Header: 'Client',
			accessor: 'clientName',
			minWidth: 130,
			Cell: ({ row }) => (
				`${row.original?.FName || ''} ${row.original?.MName || ''} ${row.original?.LName || ''}`
			),
		},
		{
			Header: 'PAN',
			accessor: 'pan',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.PanNo
			)
		},
		{
			Header: "Type",
			accessor: "type",
			minWidth: 130,
			Cell: ({ row }) => 
			row?.original?.IsETrade == null?'Bonanza':"Bigul",

		  },
		
		// {
		// 	Header: 'Steps',
		// 	accessor: 'steps',
		// 	minWidth: 150,
		// 	Cell: ({ row }) => (
		// 		<TableProgressBar 	
		// 		selectedStep={row.original.step}
		// 		steps={steps || []}
		// 		width="w-full"
		// 		circleDim="10px"
		// 		circleRadius="1px"
		// 		lineDim="h-[2px]"
		// 	  />
		// 	)
		// },
		{
			Header: 'Created At',
			accessor: 'createdAt',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.created_at && dayjs(row.original?.created_at).format('DD MMM YYYY'+' '+'HH:mm:ss')
			)
		},
		{
			Header: 'Last Updated',
			accessor: 'updatedAt',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.updated_at && dayjs(row.original?.updated_at).format('DD MMM YYYY'+' '+'HH:mm:ss')
			)
		},
		// UserName
		{
			Header: 'RM',
			accessor: 'rm',
			minWidth: 130,
			Cell: ({ row }) => (
				row?.original?.rm_name
			)
		},
		{
			Header: "verifier name",
			accessor: "v_name",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.verifier_name
		},
		// {
		// 	Header: 'Verifier',
		// 	accessor: 'verifier',
		// 	minWidth: 130,
		// 	Cell: ({ row }) => (
		// 		row.original?.FinalVerifyBy
		// 	)
		// },
		
		
		{
			Header: 'Actions',
			accessor: 'actions',
			minWidth: 60,
			Cell: ({ row }) => (
				<MyPopover
					PopoverParentComp={PopoverParentComp}
					PopoverChildComp={PopoverChildComp}
					isVerify={true}
					redirectUrl="/application/new-application"
					applicationType="allApplication"
					selectedData={row.original}
					isView={false}
					handleEdit={true}
					viewUrl="/application/view-application"
				/>
			)
		}
	];

	const handleApplication = ({ start, end, search }) => {
		dispatch(getApplicationAction('resubmittedApplication', {
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			// KYCStatus: 'rework',
			start: start || 1,
			end: end || 20,
			search: search || '',
			channel_type:selectedOption
		}));
	};

	useEffect(() => {
		handleApplication({ start: 1, end: 50, search: search1 });
	}, [selectedOption]);

	const handleSearch = ({ searchVal }) => {
		setSearch(searchVal)
// if (searchVal.length == 0) {
// 		  handleApplication({ start: 1, end: 50, search: "" });
// 		}
		// else {
		  handleApplication({ start: "", end: "", search: searchVal });
		// }
	
	  };
	
	return (
		<>
			<div className="w-full flex flex-col ">
				<div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex flex-col md:flex-row gap-y-5  items-center justify-between mb-7">
						<ReactTableHeader
							title="Resubmitted"
							numberOfApplications={applicationData && applicationData?.length}

						/>
						{/* <PageCount /> */}
					</div>
					<div className="mb-6 flex items-center">
						  <SearchBar border={"border"}
              data={applicationData}
              handleSelect={handleSearch} />
			  <div style={{ height: '50px', width: '150px', borderWidth: 0.2, marginLeft: '20px', borderRadius: '10px', textAlign: 'center', alignItems: 'center' }}>
              <select value={selectedOption} onChange={handleSelectChange} style={{ width: '100%', height: '100%', border: 'none', outline: 'none', borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
                <option value="All">All</option>
                <option value="Bigul">Bigul</option>
                <option value="Bonanza">Bonanza</option>
              </select>
            </div>
						
					</div>
					

					{isLoading ?
						<>
							<SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
								<Skeleton count={10} style={{ height: '30px', marginTop: '10px' }} borderRadius={10} />
							</SkeletonTheme>
						</>
						:
						<ReactTable
							columns={columns}
							data={applicationData || []}
							displayBlock={true}
						/>
					}
					{console.log("applicationData====", applicationData)}
				</div>
			</div>
		</>
	);
};
