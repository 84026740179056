import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setLoginUserAction } from '../../redux/actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';

export const Username = () => {
    const { rm, page } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    console.log(rm, page, 'rm');

 useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Replace with your API endpoint
                const response = await axios.post('https://ekycapi.bigul.co/GetUserDetailByUsername', {
                    UserName: rm
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                // Handle successful response

                if (response.data.success) {
                    // toast.success('User data fetched successfully');
                    dispatch(setLoginUserAction({ userDetails: [response?.data?.data[0]] || [] }))
                    if (!page) {
                        navigate('/')
                        return
                    }
                    if (page == 'verified' || page == 'rejected' || page == 'pending' || page == 'incomplete') {
                        navigate('/application/' + page)
                    }
                    else {
                        navigate('/' + page)
                    }
                } else {
                    toast.error('user not found')
                    navigate('/')
                }
            } catch (error) {
                // Handle error
                toast.error(error?.response?.data?.message || error.message || 'Something went wrong');
            }
        };

        fetchUserData();
    }, [rm]); // Run effect when `rm` changes

    return null; // Render nothing
};
