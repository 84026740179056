import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { kycSteps } from '../../containers/kyc/kycData';
import { KycModal } from '../../components/modal/kyc/KycModal';
import { StepProgressBar } from '../../components/progressBar/ProgressBar';
import { VerifyMobileEmail } from '../../containers/kyc/step1/VerifyMobileEmail';
import { VerifyPan } from '../../containers/kyc/step2/VerifyPan';
import { PersonalDetail } from '../../containers/kyc/step3/PersonalDetail';
import { OccuptionDetail } from '../../containers/kyc/step4/OccupationDetail';
import { VerifyBankDetails } from '../../containers/kyc/step5/VerifyBankDetails';
import { BrokerageStep } from '../../containers/kyc/step6/BrokerageStep';
import { KycFilesSteps } from '../../containers/kyc/step7/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changeStep4InputAction } from '../../redux/actions/kyc/step4.action';
import { saveBankAllInfoAction, changeStep5InputAction, verifyBankAccountAction } from '../../redux/actions/kyc/step5.action';
import { getPanCardDetailAction, getValidatePanDetailsAction, changeStep2InputAction } from '../../redux/actions/kyc/step2.action';
import { setChannelAction } from '../../redux/actions/kyc/kyc.action';
import { changeStep1StatusAction } from '../../redux/actions/kyc/step1.action';
export const Kyc = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const selectedStep = location.state && location.state?.step;
	const selectedStepData = location.state && location.state?.selectedData;
	const rejectedFieldsData = location.state && location.state?.rejectedFields;
	const channel = useSelector(state => state.kyc.channel || '');
	const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
	const [isModelOpen, setIsModelOpen] = useState(selectedStep > 2 ? false : true);
	const [steps, setSteps] = useState(kycSteps || []);

	useEffect(() => {
		dispatch(setChannelAction({ channel: 'Bigul' }))

	}, [])
	const update = () => [
		{
			label: '1',
			value: 'step1',
			status: '"success"',
			isSuccess: true,
			component: <VerifyMobileEmail />
		},
		{
			label: '2',
			value: 'step2',
			status: 'success',
			isSuccess: true,
			component: <VerifyMobileEmail />
		},
		{
			label: '3',
			value: 'step3',
			status: 'success',
			isSuccess: true,
			component: <VerifyMobileEmail />
		},
		{
			label: '4',
			value: 'step4',
			status: 'success',
			isSuccess: true,
			component: <VerifyPan />
		},
		{
			label: '5',
			value: 'step5',
			status: 'success',
			isSuccess: true,
			component: <VerifyPan />
		},
		{
			label: '6',
			value: 'step6',
			status: 'success',
			isSuccess: true,
			component: <PersonalDetail />
		},
		{
			label: '7',
			value: 'step7',
			status: 'success',
			isSuccess: true,
			component: <OccuptionDetail />
		},
		{
			label: '8',
			value: 'step8',
			status: 'success',
			isSuccess: true,
			component: <VerifyBankDetails />
		},
		{
			label: '9',
			value: 'step9',
			status: 'success',
			isSuccess: true,
			component: <BrokerageStep />
		},
		{
			label: '10',
			value: 'step10',
			status: 'success',
			isSuccess: true,
			component: <KycFilesSteps />
		},
		{
			label: '11',
			value: 'step11',
			status: 'active',
			isSuccess: true,
			component: <KycFilesSteps />
		},
		// {
		// 	label: '12',
		// 	value: 'step12',
		// 	status: 'success',
		// 	isSuccess: true,
		// 	component:<KycFilesSteps />
		// },
		// {
		// 	label: '13',
		// 	value: 'step13',
		// 	status: 'inactive',
		// 	isSuccess: true,
		// 	component:<KycFilesSteps />
		// },


	];
	useEffect(() => {
		if (clientKycStatus?.data?.step > 0) {
			const updatedSteps = steps.map((el, i) => {
				if (clientKycStatus?.data?.step == 15 || clientKycStatus?.data?.esign_flag == 1) {
					navigate('/kyc/review');
				} else
					if (i === 0 && el.status == 'active') {
						return ({
							...el,
							status: 'inactive'
						});
					} else if (i == clientKycStatus?.data?.step - 1) {
						// if (clientKycStatus?.data?.dob)
						// 	dispatch(getPanCardDetailAction({
						// 		PanNo: clientKycStatus?.data?.pan,
						// 		BirthDate: clientKycStatus?.data?.dob,
						// 		mobile: clientKycStatus?.data?.mobile
						// 	}));
						const handleCallBack = (res) => {

							dispatch(changeStep2InputAction({ type: "panInput", value: clientKycStatus?.data?.pan.toUpperCase() }));
							dispatch(changeStep2InputAction({ type: "dobInput", value: clientKycStatus?.data?.dob }));
							dispatch(changeStep4InputAction({ type: 'educationDetail', value: res.data.EducationId }));
							dispatch(changeStep4InputAction({ type: 'experienceDetail', value: res.data.CommodityExperience }));
							if (res.data?.BankName) {
								dispatch(verifyBankAccountAction({
									beneficiary_ifsc: res.data.BankIFSC,
									beneficiary_account_no: res.data.BankAccountNumber
								}));
								setTimeout(() => {
									dispatch(saveBankAllInfoAction({
										PanNo: clientKycStatus?.data?.pan,
										BankName: res.data.BankName,
										BankMICR: res.data.BankMICR,
										BankIFSC: res.data.BankIFSC,
										BankAccountNumber: res.data.BankAccountNumber,
										MFBankName: res.data.BankName
									}));
								}, 1000);
								dispatch(changeStep5InputAction({ type: 'bankAccountNo', value: res.data.BankAccountNumber }));
								dispatch(changeStep5InputAction({ type: 'confirmBankAccountNo', value: res.data.BankAccountNumber }));
								dispatch(changeStep5InputAction({ type: 'ifsc', value: res.data.BankIFSC }));

							}

						};
						dispatch(getValidatePanDetailsAction({
							PanNo: clientKycStatus?.data?.pan,

						}, handleCallBack));
						return ({
							...el,
							status: 'active'
						});
					}
				if (clientKycStatus?.data?.step - 1 < i) {
					return ({
						...el,
						status: 'inactive'
					});
				}
				else {
					return ({
						...el,
						status: 'success'
					});
				}

			});
			setSteps(clientKycStatus?.data?.step == 11 || clientKycStatus?.data?.step == 12 || clientKycStatus?.data?.step == 13 || clientKycStatus?.data?.step == 14 || clientKycStatus?.data?.step == 16 ? update() : updatedSteps);
		}

	}, [clientKycStatus?.data?.step, clientKycStatus])

	useEffect(() => {
		if (selectedStep > 0) {
			const updatedSteps = steps.map((el, i) => {
				if (selectedStep == 15) {
					navigate('/kyc/review');
				} else
					if (i === 0 && el.status == 'active') {
						return ({
							...el,
							status: 'inactive'
						});
					} else if (i == selectedStep) {
						return ({
							...el,
							status: 'active'
						});
					} else {
						return el;
					}
			});
			setSteps(updatedSteps);
		}

	}, [selectedStep])


	const handleBackBtn = ({ step, index }) => {
		const updatedSteps = steps.map((el, i) => {
			if (i === index) {
				return ({
					...el,
					status: 'inactive'
				});
			} else if (i === index - 1) {
				return ({
					...el,
					status: 'active'
				});
			} else {
				return el;
			}
		});
		setSteps(updatedSteps);
	};

	const handleContinueBtn = ({ step, index }) => {

		const updatedSteps = steps.map((el, i) => {
			if (i === index) {
				return ({
					...el,
					status: 'success'
				});
			} else if (i === (index == 1 ? index + 2 : index == 3 ? index + 2 : index + 1)) {
				return ({
					...el,
					status: 'active'
				});
			} else {
				return el;
			}
		});
		setSteps(updatedSteps);
	};

	const handleReviewBtn = () => {
		navigate('/kyc/review');
	};

	const handleKycModel = ({ isOpen }) => {
		if (isOpen) {
			setIsModelOpen(selectedStepData.mobile ? false : true);
		} else {
			setIsModelOpen(false);
		}
	};

	useEffect(() => {
		if (channel === '') {
			setTimeout(() => {
				handleKycModel({ isOpen: true });
			}, 100);
		}
	}, []);

	return (
		<>
			{
				steps?.length > 0
					? steps.map((step, index) => step.status === 'active'
						? (
							<div key={`kyc_step_${index}`}>
								<div style={{ marginTop: '10px' }} className="w-full pb-4 mb-10 bg-[#F6F8F9] sticky top-[50px] md:top-[10px] z-0 ">
									<StepProgressBar selectedStep={index} steps={steps} showStepLabel={true} />
								</div>
								{/* {step.value === 'step1' && <VerifyMobileEmail step={step} index={index} continueBtn={handleContinueBtn} />}
								{step.value === 'step2' && <VerifyPan step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step3' && <PersonalDetail step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step4' && <OccuptionDetail step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step5' && <VerifyBankDetails step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step6' && <BrokerageStep step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step7' && <KycFilesSteps step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} reviewBtn={handleReviewBtn} selectedStepData={selectedStepData} rejectedFieldsData={rejectedFieldsData} />}
								{step.value === 'step8' && <>
									<UploadSignature step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} reviewBtn={handleReviewBtn} /> */}
								{step.value === 'step1' && <VerifyMobileEmail step={step} index={index} continueBtn={handleContinueBtn} selectedData={selectedStepData} />}
								{step.value === 'step2' && <VerifyMobileEmail step={step} index={index} continueBtn={handleContinueBtn} selectedData={selectedStepData} />}
								{step.value === 'step3' && <VerifyMobileEmail step={step} index={index} continueBtn={handleContinueBtn} selectedData={selectedStepData} />}
								{step.value === 'step4' && <VerifyPan step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step5' && <VerifyPan step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step6' && <PersonalDetail step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step7' && <OccuptionDetail step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step8' && <VerifyBankDetails step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{step.value === 'step9' && <BrokerageStep step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} />}
								{(step.value === 'step10' || step.value === 'step11' || step.value === 'step12' || step.value === 'step13') && <KycFilesSteps step={step} index={index} backBtn={handleBackBtn} continueBtn={handleContinueBtn} reviewBtn={handleReviewBtn} selectedStepData={selectedStepData} rejectedFieldsData={rejectedFieldsData} />}
							</div>)
						: null
					)
					: null
			}
			{/* {
				// !check && 
				<KycModal isModalOpen={isModelOpen} sle handleKycModel={handleKycModel} selectedStepData={selectedStepData == null ?"No":selectedStepData} />
			} */}


			< ToastContainer autoClose={100} />
		</>
	);
};
