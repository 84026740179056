import { all, call, put, takeEvery } from 'redux-saga/effects';
import { startLoader, stopLoaderAction } from '../redux/actions/app.action';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function * loaderSaga () {
	try {
		yield call(delay, 50000);
		yield put(stopLoaderAction());
	} catch (err) {
		console.log(err);
	};
};

function * appSaga () {
	yield all([
		takeEvery(startLoader, loaderSaga)
	]);
}

export default appSaga;
