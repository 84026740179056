// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { CommonBtn } from '../components/buttons/CommonBtn';
// import CustomDatePicker from '../components/inputs/CustomDatePicker';
// import { PageCount } from '../components/reactTable/PageCount';
// import { ReactTable } from '../components/reactTable/ReactTable';
// import { reactTableStatusValue } from '../components/reactTable/reactTableData';
// import { ReactTableHeader } from '../components/reactTable/ReactTableHeader';
// import { SearchBar } from '../components/searchbar/SearchBar';
// import { getUserListAction } from '../redux/actions/user.action';
// import { AddUserFormModal } from '../components/modal/admin/userDetails/AddUserModel';
// import { EditUserModal } from '../components/modal/admin/userDetails/EditUserModel';
// import { postApi } from '../services/common';
// import { pngAssets } from '../assets/asset';

// const data = [
// 	{ name: 'Vikas', userName: 'Vikas_dy', designation: 'Branch Manager', role: 'RM', email: 'vi@bigul.co', mobileNo: '9988774455', branch: 'Janzeerwala Square', status: 'Active', createdDate: '02-01-2023', lastUpdatedAt: '02-01-2023', brokerageMapped: 'Low' },
// 	{ name: 'Vikas', userName: 'Vikas_dy', designation: 'Branch Manager', role: 'RM', email: 'vi@bigul.co', mobileNo: '9988774455', branch: 'Janzeerwala Square', status: 'InActive', createdDate: '02-01-2023', lastUpdatedAt: '02-01-2023', brokerageMapped: 'Low' },
// 	{ name: 'Vikas', userName: 'Vikas_dy', designation: 'Branch Manager', role: 'RM', email: 'vi@bigul.co', mobileNo: '9988774455', branch: 'Janzeerwala Square', status: 'Active', createdDate: '02-01-2023', lastUpdatedAt: '02-01-2023', brokerageMapped: 'Low' },
// 	{ name: 'Vikas', userName: 'Vikas_dy', designation: 'Branch Manager', role: 'RM', email: 'vi@bigul.co', mobileNo: '9988774455', branch: 'Janzeerwala Square', status: 'InActive', createdDate: '02-01-2023', lastUpdatedAt: '02-01-2023', brokerageMapped: 'Low' }
// ];

// export const UserDetail = () => {

// 	const dispatch = useDispatch();

// 	const [isRefresh, setRefresh] = useState(false);
// 	const [editUserData, setEditUserData] = useState([]);
// 	const [isAddUserModelOpen, setIsAddUserModelOpen] = useState(false);
// 	const [isEditUserModelOpen, setIsEditUserModelOpen] = useState(false);
// 	const userDetail = useSelector(state => state.userList?.userList || []);

// 	const handleAddUser = () => {
// 		setIsAddUserModelOpen(true);
// 	};

// 	const RefreshData = () => {
// 		setRefresh(!isRefresh)
// 	}

// 	const handleEdit = (user_id) => {
// 		const payload = { UserId: user_id };
// 		postApi(payload, '/GetUserIdDetails')

// 			.then((response) => {
// 				const userData = response?.data;
// 				setEditUserData(userData?.data[0]);
// 				console.log("editUserData",editUserData)
// 				setIsEditUserModelOpen(true);
// 			})
// 			.catch((error) => {
// 				console.error(error);
// 			});
// 	};

// 	const columns = [
// 		{ Header: 'Name', accessor: 'fname', minWidth: 150 },
// 		{ Header: 'User Name', accessor: 'username', minWidth: 120 },
// 		{ Header: 'Designation', accessor: 'Designation', minWidth: 140 },
// 		{ Header: 'Role', accessor: 'role_id', minWidth: 60 },
// 		{ Header: 'Email', accessor: 'email', minWidth: 150 },
// 		{ Header: 'Mobile No.', accessor: 'mobile', minWidth: 120 },
// 		{ Header: 'Branch', accessor: 'BranchName', minWidth: 120 },
// 		{
// 			Header: 'Status',
// 			accessor: 'status',
// 			minWidth: 50,
// 			Cell: ({ row }) => (
// 				<div
// 					className="min-w-[95px] px-3 py-1 inline-flex justify-center rounded-[6px] text-xs font-medium font-poppinsMedium"
// 					style={{
// 						backgroundColor: reactTableStatusValue[row.original.status]?.bgColor
// 					}}
// 				>
// 					{row.original.status}
// 				</div>
// 			)
// 		},
// 		{ Header: 'Created date', accessor: 'created_at', minWidth: 150 },
// 		{ Header: 'Last updated At', accessor: 'updated_at', minWidth: 150 },
// 		{ Header: 'Brokerage Mapped', accessor: 'brokerageMapped', minWidth: 110 },
// 		{
// 			Header: 'Action',
// 			accessor: 'id',
// 			minWidth: 110,
// 			Cell: ({ row }) => (
// 				<button
// 					className="h-[47px] px-5 mx-7 flex items-center rounded-[10px] leading-6 text-white
// 				bg-black"
// 					// onClick={() => handleEdit(row.original?.user_id)}
// 				>
// 					Edit
// 				</button>
// 			)
// 		}
// 	];


// 	useEffect(() => {
// 		dispatch(getUserListAction());
// 	}, [isRefresh]);

// 	return (
// 		<>
// 			<div className="w-full flex flex-col overflow-hidden">
// 				<div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
// 					<div className="flex flex-col md:flex-row gap-y-5  items-center justify-between mb-7 ">
// 						<ReactTableHeader
// 							title="Users Detail"
// 						/>
// 						<div className="flex">
// 							<div className="mr-[20px]">
// 								<PageCount />
// 							</div>
// 							<CommonBtn
// 								preIcon={pngAssets.reactTable.addUserWhite}
// 								btnText="Add User"
// 								handleBtn={handleAddUser}
// 							/>
// 						</div>
// 					</div>
// 					<div className="mb-6 flex items-center justify-between">
// 						<SearchBar border={'border'} />
// 						<div className="flex flex-col md:flex-row md:items-center">
// 							<span className="text-[#787878] mr-3 font-medium font-poppinsMedium">Select Date</span>
// 							<div className="flex gap-5 mt-3 md:mt-0">
// 								<CustomDatePicker placeholder="From" />

// 								<CustomDatePicker placeholder="To" />
// 							</div>
// 						</div>
// 					</div>
// 					<ReactTable columns={columns} data={userDetail || []} displayBlock={true} />
// 				</div>
// 			</div>

// 			<AddUserFormModal
// 				title="Add User"
// 				isModalOpen={isAddUserModelOpen}
// 				closeModal={() => setIsAddUserModelOpen(false)}
// 				RefreshData={RefreshData}
// 			/>

// 			<EditUserModal
// 				title="Edit User"
// 				isModalOpen={isEditUserModelOpen}
// 				closeModal={() => setIsEditUserModelOpen(false)}
// 				editUserData={editUserData}
// 				RefreshData={RefreshData}
// 			/>
// 		</>
// 	);
// };


import { useEffect, useState } from 'react';

import { ReactTable } from '../components/reactTable/ReactTable';
import { reactTableStatusValue } from '../components/reactTable/reactTableData';
import { ReactTableHeader } from '../components/reactTable/ReactTableHeader';
import { Modal } from 'antd';
import { CommonInput } from '../components/inputs/CommonInput';

export const UserDetail = () => {
	const [modal, setModal] = useState(false)
	const [branchCode, setBranchCode] = useState('')
	const [introducer, setIntroducer] = useState('')
	const [online, setOnline] = useState('')
	const [rmcode, setRmcode] = useState('')
	const [errorBranch, setErrorBranch] = useState('')
	const [errorInduce, setErrorinducer] = useState('')
	const [errorOnline, setErronline] = useState('')
	const [errorRm, setErrRm] = useState('')

	const userDetail = [
		{
			fname: 'mahesh',
			lname: 'mahajan',
			email: 'm@gmail.com',
			mobile: 9898989898,
			BranchName: 'A',
			status: 1
		},
		{
			fname: 'mahesh',
			lname: 'mahajan',
			email: 'm@gmail.com',
			mobile: 9898989898,
			BranchName: 'A',
			status: 1
		},
		{
			fname: 'mahesh',
			lname: 'mahajan',
			email: 'm@gmail.com',
			mobile: 9898989898,
			BranchName: 'A',
			status: 1
		},
		{
			fname: 'mahesh',
			lname: 'mahajan',
			email: 'm@gmail.com',
			mobile: 9898989898,
			BranchName: 'A',
			status: 1
		},
		{
			fname: 'mahesh',
			lname: 'mahajan',
			email: 'm@gmail.com',
			mobile: 9898989898,
			BranchName: 'A',
			status: 1
		},
		{
			fname: 'mahesh',
			lname: 'mahajan',
			email: 'm@gmail.com',
			mobile: 9898989898,
			BranchName: 'A',
			status: 1
		},
	];

	const handleEdit = (user_id) => {
		setModal(true)
		setBranchCode('')
		setIntroducer('')
		setOnline('')
		setRmcode('')
		setErrorBranch('')
		setErrorinducer('')
		setErronline('')
		setErrRm('')
	};

	const handleCancel = () => {
		setModal(false)
	}

	const handleInput = ({ type, value }) => {
		if (type == "branch_code") {
			setBranchCode(value)
			setErrorBranch('')
		}
		if (type == "introducer") {
			setIntroducer(value)
			setErrorinducer('')
		}
		if (type == "online_branch") {
			setOnline(value)
			setErronline('')
		}
		if (type == "rm_code") {
			setRmcode(value)
			setErrRm('')
		}
	};

	const columns = [
		{ Header: 'Name', accessor: 'fname', minWidth: 200 },
		{ Header: 'LName', accessor: 'lname', minWidth: 200 },
		{ Header: 'Email', accessor: 'email', minWidth: 200 },
		{ Header: 'Mobile No.', accessor: 'mobile', minWidth: 200 },
		{ Header: 'Branch', accessor: 'BranchName', minWidth: 200 },
		{
			Header: 'Status',
			accessor: 'status',
			minWidth: 200,
			Cell: ({ row }) => (
				<div
					className="min-w-[95px] px-3 py-1 inline-flex justify-center rounded-[6px] text-xs font-medium font-poppinsMedium"
					style={{
						backgroundColor: reactTableStatusValue[row.original.status]?.bgColor
					}}
				>
					{row.original.status}
				</div>
			)
		},

		{
			Header: 'Action',
			accessor: 'id',
			minWidth: 200,
			Cell: ({ row }) => (
				<button
					className="h-[47px] px-5 mx-7 flex items-center rounded-[10px] leading-6 text-white
				bg-black"
					onClick={() => handleEdit(row.original?.user_id)}
				>
					Verify
				</button>
			)
		}
	];

	const handleSubmit = () => {
		if (!branchCode) {
			setErrorBranch('Enter branch code')
		}
		if (!introducer) {
			setErrorinducer('Enter introducer')
		}
		if (!online) {
			setErronline('Enter online')
		}
		if (!rmcode) {
			setErrRm('Enter rm code')
		}
		else {
			alert()
		}
	}

	return (
		<>
			<div className="w-full flex flex-col overflow-hidden">
				<div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex flex-col md:flex-row gap-y-5  items-center justify-between mb-7 ">
						<ReactTableHeader
							title="Profile Details"
						/>
						<Modal
							open={modal}
							onCancel={handleCancel}
							centered={true}
							width={900}
							// closeIcon={false}
							footer={null}
							className="w-full"
						>
							<div className="w-full px-5 max-h-[calc(100vh-100px)]">
								<div className="">
									<div>
										<h1 className='text-xl py-4 font-semibold'> Add User</h1>
									</div>
									<div className="mb-4 grid grid-cols-2 gap-10">
										<CommonInput
											label="Branch code"
											isImportant={false}
											placeholder="Please enter branch code"
											height="45px"
											inputType="branch_code"
											handleInput={handleInput}
											value={branchCode}
											error={errorBranch} />

										<CommonInput
											label="Introducer code"
											isImportant={false}
											placeholder="Please enter introducer code"
											height="45px"
											inputType="introducer"
											handleInput={handleInput}
											value={introducer}
											error={errorInduce} />
									</div>

									<div className="mb-8 grid grid-cols-2 gap-10">
										<CommonInput
											label="Online branch"
											isImportant={false}
											placeholder="Please enter online branch"
											inputType="online_branch"
											handleInput={handleInput}
											value={online}
											error={errorOnline} />

										<CommonInput
											label="Rm code"
											isImportant={false}
											placeholder="Please enter rm code"
											inputType="rm_code"
											handleInput={handleInput}
											value={rmcode}
											error={errorRm} />
									</div>
								</div>
								<div className="flex items-center justify-center">
									<div onClick={handleSubmit}
										className={`${'35px'} px-8 py-2 flex items-center rounded-[10px] leading-6 text-white bg-black font-poppinsRegular cursor-pointer`}
									>
										<p style={{ color: 'white', fontWeight: 'bold' }}>Submit</p>
									</div>
								</div>
							</div>
						</Modal>
					</div>
					<ReactTable columns={columns} data={userDetail || []} displayBlock={true} />
				</div>
			</div>
		</>
	);
};
