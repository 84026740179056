import { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XCircleIcon } from '@heroicons/react/24/solid'
import { pngAssets, svgAssets } from '../../../assets/asset';
import { BankAccNumberInput } from '../../../components/inputs/BankAccNumberInput';
import { IfscInput } from '../../../components/inputs/IFSCInput';
import { VerifyBankBtn } from '../../../components/buttons/VerifyBankBtn';
import { SearchIfcModal } from '../../../components/modal/kyc/SearchIfcModal';
import { MainTitle } from '../../../components/common/MainTitle';
import { changeStep5ErrorAction, changeStep5InputAction, saveBankAllInfoAction, verifyBankAccountAction, fetchBankAccountAction, setClearBankInfoAction, getBanksNameListAction, setIfscCodeAction, setIsVerifyBankDetailsAction } from '../../../redux/actions/kyc/step5.action';
import { validateAccountNo, validateIfscNo } from '../../../utils/verifyInput';
import { BackBtn } from '../../../components/buttons/BackBtn';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { BuildingLibraryIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DigiImage, postApi, postApiWithoutLoader } from '../../../services/common';
import html2canvas from 'html2canvas';
import { message } from 'antd';

export const VerifyBankDetails = ({ step, index, backBtn, continueBtn }) => {
	const dispatch = useDispatch();
	const divRef = useRef(null);
	const [isModelOpen, setIsModelOpen] = useState(false);
	const kycStep2 = useSelector(state => state?.kycStep2 || {});
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const kycStep5 = useSelector(state => state?.kycStep5 || {});
	const verifiedBankDetails = useSelector(state => state?.kycStep5.verifyBankDetails || {});
	const [inputValue, setInputValue] = useState(kycStep5?.ifsc.value ? kycStep5?.ifsc.value : "");
	const [inputValueBankNumber, setInputValueBankNumber] = useState(kycStep5?.bankAccountNo.value ? kycStep5?.bankAccountNo.value : "");
	const [inputValueConfirmBankNumber, setInputValueConfirmBankNumber] = useState(kycStep5?.confirmBankAccountNo.value ? kycStep5?.confirmBankAccountNo.value : "");
	const [isValid, setIsValid] = useState(true);
	const [isValidBankNumber, setIsValidBankNumber] = useState(true);
	const [isValidConfirmBankNumber, setIsValidConfirmBankNumber] = useState(true);
	const [isInValidBankNumber, setIsInValidBankNumber] = useState(false);
	const [isInValidConfirmBankNumber, setIsInValidConfirmBankNumber] = useState(false);
	const [isShowBankNumber, setIsShowBankNumber] = useState(false);
	const [isBankDetailsLoad, setIsBankDetailsLoad] = useState(true);
	const [isClearCode, setIsClearCode] = useState(false);
	const [isValidIfscCode, setIsValidIfscCode] = useState(false);
	const [statusBank, checkStatus] = useState(false);
	const [isClearData, setIsClearData] = useState(false);
	const [isBankProofUploaded, setIsBankProofUploaded] = useState(false);
	const [pan, setPan] = useState('');
	const [success, setSuccess] = useState(false);
	const [bankstatus, setBankstatus] = useState(false);
	console.log(success, 'kkk');

	const handleClearBankDetails = ({ type, isError, errorText }) => {
		dispatch(setClearBankInfoAction({}));
	};


	const fetchData = async () => {
		try {
			const payload = {
				mobile: kycStep1?.mobileInput?.value,
				journey: "DKYC",
			}
			const response = await postApi(payload, '/getKycJourney');
			if (response?.data?.success) {
				setPan(response?.data?.data[0])
			}
			else {
				toast('something went to wrong')
				backBtn({ step, index })
			}
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	useEffect(() => {
		fetchData()
	}, [])

	// const digio = async (id,pan) => {
	// 	try {
	// 		const payload = {
	// 			mobile: kycStep1?.mobileInput?.value,
	// 			file_id: id,
	// 			pan: pan ,
	// 			journey : "DKYC",
	// 		};
	// 		const fileName = 'downloaded_image.jpg';
	// 		const response = await DigiImage(payload, 'https://bigul.co/api/digio-image-user-path');
	// 		const image_path = response?.data?.imagePath;
	// 		if (image_path) {
	// 			setImageData(image_path);
	// 		}
	// 	} catch (error) {
	// 		console.error('Error fetching data:', error);
	// 	}
	// };

	// 	const saveImageToLocal = (imagePath) => {

	// 		fetch(imagePath)
	// 			.then((response) => response.blob())
	// 			.then((blob) => {
	// const reader = new FileReader();
	// 				reader.onload = () => {
	// 					setImageData(reader.result);
	// 				};
	// 				reader.readAsDataURL(blob);
	// 			})
	// 			.catch((error) => {
	// 				console.error('Error saving image:', error);
	// 			});
	// 	};
	// const uploadImage = () => {
	// 	if (divRef.current) {
	// 		html2canvas(divRef.current).then((canvas) => {
	// 			canvas.toBlob((blob) => {
	// 				if (blob) {
	// 					const formData = new FormData();
	// 					formData.append('DocumentImages[]', blob, 'captured_image.png');
	// 					formData.append('Document', 'Other');
	// 					formData.append('DocumentType', 'NA');
	// 					formData.append('CreatedBy', '2261');
	// 					formData.append('PanNo', pan);
	// 					formData.append('step', '8');
	// 					formData.append('journey', 'DKYC');
	// 					formData.append('mobile', kycStep1?.mobileInput?.value,);

	// 					// Send the formData to your API using an HTTP POST request
	// 					fetch('https://dkyc.bonanzaonline.com/ekycapi/uploadfiles', {
	// 						method: 'POST',
	// 						body: formData,
	// 						headers: {
	// 							'Authorization': `Bearer ${'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc'}` // Include the token in the 'Authorization' header
	// 						},
	// 					})
	// 						.then((response) => response.json())
	// 						.then((data) => {
	// 							if (data?.success) {
	// 								setSuccess(true)
	// 								toast('Gio location document upload succesfully')

	// 							}
	// 							else {
	// 								toast('Gio lacatioion upload document failed')

	// 							}

	// 						})
	// 						.catch((error) => {
	// 							console.error('Error:', error);
	// 						});
	// 				}
	// 			}, 'image/png', 0.7); // Specify the desired image format ('image/jpeg' for JPG, 'image/png' for PNG)
	// 		});
	// 	}
	// };


	useEffect(() => {
		setIsBankDetailsLoad(true);
		dispatch(getBanksNameListAction())
		setTimeout(() => {
			setIsBankDetailsLoad(false);
		}, 1000);
	}, [kycStep5?.fetchBankData?.BANK])

	useEffect(() => {
		if (kycStep5.ifsc.value && kycStep5.ifsc.value?.length > 10) {
			setIsBankDetailsLoad(true);
			setTimeout(() => {
				dispatch(fetchBankAccountAction({
					BankVal: kycStep5.ifsc.value,
					journey: "DKYC",
					mobile: kycStep1?.mobileInput?.value,
				}));
				setIsBankDetailsLoad(false);
			}, 500);
		}
	}, [kycStep5.ifsc.value])

	const handleKycModel = (condition) => {
		if (condition) {
			setIsModelOpen(true);
			dispatch(getBanksNameListAction())
		} else {
			setIsModelOpen(false);
		}
	};
	const handleGetBankNameList = (condition) => {
		dispatch(getBanksNameListAction())
	};

	const isValidISFC = (isfcCode) => {
		const isfcRegex = /^[A-Z]{4}\d{7}$/;
		return isfcRegex.test(isfcCode);
	}

	const handleInput = ({ event, type, value }) => {
		dispatch(changeStep5InputAction({ type, value: value.toUpperCase() }));
		if (type == 'ifsc') {
			if (verifiedBankDetails?.verified === false) {
				verifiedBankDetails.verified = 'pending';
			}
			const isValidCode = isValidISFC(value);
			setIsValidIfscCode(isValidCode);
			if (/^[a-zA-Z0-9_]*$/.test(value)) {
				setInputValue(value.toUpperCase());
				setIsValid(true);
			} else {
				setIsValid(false);
			}
			if (value.length > 10) {
				handleClearBankDetails({});
				// dispatch(fetchBankAccountAction({
				// 	BankVal: value?.toUpperCase(),
				// }));
			}
		}
		if (type == 'bankAccountNo') {
			setInputValueConfirmBankNumber('');
			setIsBankProofUploaded(false)
			if (verifiedBankDetails?.verified === false) {
				verifiedBankDetails.verified = 'pending';;
			}
			let BankVal = event.target.value;


			const sanitizedValue = BankVal.replace(/\D/g, ''); // Remove non-numeric characters

			if (sanitizedValue !== BankVal) {
				event.target.value = sanitizedValue;
			}

			if (/^[a-zA-Z0-9_]*$/.test(value)) {
				setInputValueBankNumber(sanitizedValue);
				setIsValidBankNumber(true);
			} else {
				setIsValidBankNumber(false);
			}

			if (/^[a-zA-Z0-9_]*$/.test(value)) {
				setInputValueBankNumber(value);
				setIsValidBankNumber(true);
			} else {
				setIsValidBankNumber(false);
			}
		}
		if (type == 'confirmBankAccountNo') {
			if (verifiedBankDetails?.verified === false) {
				verifiedBankDetails.verified = 'pending';;
			}
			const ConfirmBankVal = event.target.value;

			const sanitizedValue = ConfirmBankVal.replace(/\D/g, ''); // Remove non-numeric characters

			if (sanitizedValue !== ConfirmBankVal) {
				event.target.value = sanitizedValue;
			}
			if (/^[a-zA-Z0-9_]*$/.test(value)) {
				setInputValueConfirmBankNumber(sanitizedValue);
				setIsValidConfirmBankNumber(true);
			} else {
				setIsValidConfirmBankNumber(false);
			}
		}
		if (type == 'bankAccountNo') {

			if (value.length < 3) {
				handleInputError({ type: 'bankAccountNo', isError: false, errorText: '' });
			} else if (value.length > 20 || value.length < 9) {

				if (value.length >= 20) {
					event.preventDefault();
					const trimmedValue = value.slice(0, 20);
					value = trimmedValue;
					setInputValueBankNumber(value);
					setIsInValidBankNumber(false);
				}
				else {
					handleInputError({ type: 'bankAccountNo', isError: true, errorText: 'The Bank account number should range from 9 to 18 digits.' });
				}

			} else if (value !== kycStep5?.confirmBankAccountNo.value && kycStep5?.confirmBankAccountNo.value.length > kycStep5?.bankAccountNo.value.length || value !== kycStep5?.confirmBankAccountNo.value && value.length > kycStep5?.confirmBankAccountNo.value.length && kycStep5?.confirmBankAccountNo.value.length !== 0) {
				handleInputError({ type: 'bankAccountNo', isError: true, errorText: 'The Bank account numbers do not match!.' });
				setIsInValidBankNumber(true);
			} else {
				handleInputError({ type: 'bankAccountNo', isError: false, errorText: 'The Bank account numbers do not match!.' });
				handleInputError({ type: 'confirmBankAccountNo', isError: false, errorText: 'The Bank account numbers do not match!.' });
				setIsInValidBankNumber(false);
				setIsInValidConfirmBankNumber(false);
			}

		} else if (type == 'confirmBankAccountNo') {
			if (value.length < 3) {
				handleInputError({ type: 'confirmBankAccountNo', isError: false, errorText: '' });
			} else if (value.length > 20 || value.length < 9) {

				if (value.length >= 20) {
					event.preventDefault();
					const trimmedValue = value.slice(0, 20);
					value = trimmedValue;
					setInputValueConfirmBankNumber(value);
					setIsInValidConfirmBankNumber(false);

				}
				else {
					handleInputError({ type: 'confirmBankAccountNo', isError: true, errorText: 'The Bank account number should range from 9 to 18 digits.' });
				}
			} else if (value !== kycStep5?.bankAccountNo.value && kycStep5?.confirmBankAccountNo.value.length > kycStep5?.bankAccountNo.value.length || value !== kycStep5?.bankAccountNo.value && kycStep5?.confirmBankAccountNo.value.length > 5) {
				handleInputError({ type: 'confirmBankAccountNo', isError: true, errorText: 'The Bank account numbers do not match!.' });
				setIsInValidConfirmBankNumber(true);
			} else {
				handleInputError({ type: 'confirmBankAccountNo', isError: false, errorText: 'The Bank account numbers do not match!.' });
				handleInputError({ type: 'bankAccountNo', isError: false, errorText: 'The Bank account numbers do not match!.' });
				setIsInValidBankNumber(false);
				setIsInValidConfirmBankNumber(false);
			}
		}
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeStep5ErrorAction({ type, isError, errorText }));
	};

	const handleVerifyBtn = () => {
		setBankstatus(false)
		const isValidBankAccountNo = validateAccountNo(kycStep5.bankAccountNo.value || '');
		const isValidConfirmBankAccountNo = validateAccountNo(kycStep5.confirmBankAccountNo.value || '') && kycStep5.bankAccountNo.value === kycStep5.confirmBankAccountNo.value;

		if (isValidBankAccountNo && isValidConfirmBankAccountNo) {
			dispatch(verifyBankAccountAction({
				beneficiary_ifsc: kycStep5.ifsc.value,
				beneficiary_account_no: kycStep5.bankAccountNo.value,
				journey: "DKYC",
				mobile: kycStep1?.mobileInput?.value,

			}, useCallback1));


			if (verifiedBankDetails?.verified === false) {
				setIsBankProofUploaded(false)
			}
		} if (!isValidBankAccountNo) {
			handleInputError({ type: 'bankAccountNo', isError: true, errorText: 'Invalid Account no!' });
		} if (!isValidConfirmBankAccountNo) {
			handleInputError({ type: 'confirmBankAccountNo', isError: true, errorText: 'The Bank account numbers do not match!.' });
		}
	};


	const calculateNameMatchPercentage = (name1, name2) => {
		const distance = levenshteinDistance(name1, name2);
		const maxLength = Math.max(name1.length, name2.length);
		const matchPercentage = (1 - distance / maxLength) * 100;
		console.log('matchPercentage', matchPercentage);
		return matchPercentage.toFixed(2);
	};
	const levenshteinDistance = (s1, s2) => {
		const m = s1.length;
		const n = s2.length;
		const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

		for (let i = 0; i <= m; i++) {
			for (let j = 0; j <= n; j++) {
				if (i === 0) {
					dp[i][j] = j;
				} else if (j === 0) {
					dp[i][j] = i;
				} else {
					dp[i][j] = Math.min(
						dp[i - 1][j - 1] + (s1[i - 1] !== s2[j - 1] ? 1 : 0),
						dp[i - 1][j] + 1,
						dp[i][j - 1] + 1
					);
				}
			}
		}

		return dp[m][n];
	};

	const useCallback1 = async (resp) => {
		if (resp?.verified == true) {
			const nameMatchPercentage = calculateNameMatchPercentage(pan?.full_name.toLowerCase(), resp?.beneficiary_name_with_bank.toLowerCase());
			if (resp?.beneficiary_name_with_bank !== null && nameMatchPercentage > 50) {
				handlefinalcall(1, nameMatchPercentage, resp)
			} else {
				handlefinalcall(2, nameMatchPercentage, resp)
			}
		}
		else {
			handlefinalcall(2, 0, resp)
		}


	}

	const handlefinalcall = async (status, match, resp) => {
		try {
			const payload = {
				mobile: kycStep1.mobileInput.value,
				status: status,
				pennydrop_data: JSON.stringify(resp),
				nameMatchPercentage: match,
				journey: "DKYC",
			}


			if (payload.status == 2) {
				setBankstatus(true)
				dispatch(setIsVerifyBankDetailsAction({}));

			}

			const response = await postApiWithoutLoader(payload, '/penny-drop-status');
			if (response?.data?.success) {
				dispatch(saveBankAllInfoAction({
					PanNo: pan?.pan,
					BankName: kycStep5?.fetchBankData?.data?.data[0]?.bank_name,
					BankMICR: kycStep5?.fetchBankData?.data?.data[0]?.micr_code || '000000000',
					BankIFSC: kycStep5?.ifsc.value,
					BankAccountNumber: kycStep5?.bankAccountNo.value,
					MFBankName: kycStep5?.fetchBankData?.data?.data[0]?.bank_name || '-',
					journey: "DKYC",
					mobile: kycStep1?.mobileInput?.value,
					// accountHolderName:resp?.beneficiary_name_with_bank ||''
				}, handleBankCallback));
			}
			else {
				toast(response?.message ? response?.message : response?.data.message ? response?.data.message : 'penny Drop failed please try again')
			}
		}
		catch (error) {
			toast('something went wrong')
		}
	}


	const handleBankCallback = (res) => {
		if (res == undefined || res.success == false) {
			toast.error(res.message);
			dispatch(setClearBankInfoAction({}));
			setInputValueBankNumber('');
			setInputValueConfirmBankNumber('');
		}
		if (res.success) {
			setSuccess(true)

		}

	};

	const setValidIfscCode = () => {
		setIsValidIfscCode(true);
	};


	const handlePaste = (event) => {
		event.preventDefault();
	}

	const handleClearCode = () => {
		dispatch(setIfscCodeAction(''));
		handleClearBankDetails({});
		setIsClearData(true);
	}

	const handleVerifyAgain = (check) => {
		setSuccess(check)
		dispatch(setIsVerifyBankDetailsAction({ verified: 'pending' }));
		setBankstatus(false)

		setInputValueBankNumber();
		setInputValueConfirmBankNumber();
		setIsInValidBankNumber(false);
		setIsInValidConfirmBankNumber(false);
		setIsBankProofUploaded(false);
		verifiedBankDetails(false)
		setIsValidBankNumber(false);
		setIsValidConfirmBankNumber(false);

	};

	const mahi = () => {
		toast('Bank Details verified Succesfully.')
		continueBtn({ step, index })

	}

	const handleEnterKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleVerifyBtn()
		}
	};


	return (
		<div className="w-full flex flex-col">
			<ToastContainer autoClose={1000} />
			<div className="min-h-[calc(100vh-300px)]">
				<MainTitle title="Bank Details" />
				<div className={`grid md:grid-cols-2 gap-10 mb-8 ${!kycStep5?.ifsc?.isError && 'items-start'}`}>
					<div className='className="grid md:grid-cols-2 gap-10 mb-10"'>
						<div className='relative'>
							<IfscInput
								icon={svgAssets.kyc.panCard}
								label="IFSC"
								isImportant={true}
								placeholder="Please enter ifsc"
								inputType="ifsc"
								value={kycStep5?.ifsc?.value ? kycStep5?.ifsc?.value : ''}
								handleInput={handleInput}
								inputMessage={kycStep5?.ifsc?.errorText || ''}
								inputMessageType={kycStep5?.ifsc?.isError && 'error'}
								autoFocus={false}
								isDisable={verifiedBankDetails?.verified === 'pending' ? false : verifiedBankDetails?.verified ? true : false}

							/>
							{
								kycStep5?.ifsc?.value && !verifiedBankDetails?.verified &&
								<XCircleIcon className="h-6 w-6 text-black-500 absolute right-4 bottom-3 cursor-pointer" onClick={handleClearCode} />
							}
						</div>

						{
							!isValid && <p style={{ color: 'red', fontSize: '13px', marginTop: '10px' }}>Invalid IFSC code! Special characters are not allowed.</p>
						}
						{
							!isValidIfscCode && kycStep5.ifsc.value?.length > 7 && !kycStep5.ifsc.value && <p style={{ color: 'red', fontSize: '13px', marginTop: '10px' }}>Invalid IFSC code.</p>
						}
						{
							kycStep5?.fetchBankData?.data?.data[0]?.bank_name && kycStep5?.ifsc?.value?.length === 11 &&
							<>
								{
									isBankDetailsLoad ?


										<div className='mt-3 p-4 py-5 bg-white shadow-lg rounded-2xl'>
											<BuildingLibraryIcon className="h-14 w-14 text-black-400" />
											<h6 className='mt-4'>Fetching bank details please wait ...</h6>
										</div>
										:
										kycStep5?.fetchBankData?.data?.data[0]?.bank_name.includes('PAYTM') || kycStep5?.fetchBankData?.data?.data[0]?.bank_name.includes('paytm') ?
											<div className='mt-3 p-4 py-5 bg-white shadow-lg rounded-2xl'>
												<BuildingLibraryIcon className="h-14 w-14 text-black-400" />
												<p style={{ color: 'red' }}>We no longer accept Paytm Payments Bank Ltd (PPBL) accounts. Please give details from another bank.</p>
											</div>
											:
											<div className='mt-3 p-4 py-5 bg-white shadow-lg rounded-2xl'>
												<BuildingLibraryIcon className="h-14 w-14 text-black-400" />
												<p className='text-sm mt-4'>
													<span className='font-semibold'>Bank Name </span>
													- {kycStep5?.fetchBankData?.data?.data[0]?.bank_name}
												</p>
												<p className='text-sm'>
													<span className='font-semibold'>Branch Name </span>
													- {kycStep5?.fetchBankData?.data?.data[0]?.branch_name}
												</p>
												<p className='text-sm'>
													<span className='font-semibold'>ISFC Code </span>
													- {kycStep5?.fetchBankData?.data?.data[0]?.ifsc}

												</p>
												{/* <p className='text-sm'>
												<span className='font-semibold'>State </span>
												- {kycStep5?.fetchBankData?.STATE}
											</p> */}
												<p className='text-sm'>
													<span className='font-semibold'>City </span>
													-  {kycStep5?.fetchBankData?.data?.data[0]?.bank_address}

												</p>
												<p className='text-sm'>
													<span className='font-semibold'>Address </span>
													-{kycStep5?.fetchBankData?.data?.data[0]?.bank_address}
												</p>
											</div>
								}

							</>
						}
					</div>


					<div className="flex items-center mt-10">
						<span className="mr-[26px] font-semibold font-poppinsSemiBold">OR</span>
						<button
							className={verifiedBankDetails?.verified && verifiedBankDetails?.verified !== 'pending' ? "h-[47px] px-5 flex items-center rounded-[10px] leading-6 text-white bg-[#dedede]" : "h-[47px] px-5 flex items-center rounded-[10px] leading-6 text-white bg-black"}
							onClick={() => handleKycModel(true)}
							// onClick={() => { }}
							disabled={verifiedBankDetails?.verified === 'pending' ? false : verifiedBankDetails?.verified ? true : false}
						>
							<img className="mr-2 text-xl leading-[30px] font-semibold" alt="back_icon" src={pngAssets.kyc.search} />
							<span>Search IFSC</span>
						</button>
					</div>
				</div>
				{
					kycStep5?.fetchBankData?.data?.data[0]?.bank_name.includes('PAYTM') || kycStep5?.fetchBankData?.data?.data[0]?.bank_name.includes('paytm') ?
						null
						:
						<div className="grid md:grid-cols-2 gap-10 mb-10">
							<div className='relative'>
								<BankAccNumberInput
									icon={svgAssets.kyc.passbook}
									label="Bank Account Number"
									isImportant={true}
									placeholder="Please enter bank a/c number"
									inputType="bankAccountNo"
									value={inputValueBankNumber || ''}
									handleInput={handleInput}
									inputMessage={kycStep5?.bankAccountNo?.errorText || ''}
									inputMessageType={kycStep5?.bankAccountNo?.isError && 'error'}
									type={'password'}
									isDisable={verifiedBankDetails?.verified === 'pending' ? false : verifiedBankDetails?.verified ? true : false}
								/>
								{
									!isValidBankNumber && <p style={{ color: 'red', fontSize: '13px', marginTop: '10px' }}>Invalid account number! Special characters are not allowed.</p>
								}
							</div>
							<div className='relative'>
								<BankAccNumberInput
									icon={svgAssets.kyc.passbook}
									label="Confirm Bank Account Number"
									isImportant={true}
									placeholder="Please enter confirm bank a/c number"
									inputType="confirmBankAccountNo"
									value={inputValueConfirmBankNumber || ''}
									handleInput={handleInput}
									inputMessage={kycStep5?.confirmBankAccountNo?.errorText || ''}
									inputMessageType={kycStep5?.confirmBankAccountNo?.isError && 'error'}
									type={isShowBankNumber ? 'text' : 'text'}
									onPaste={handlePaste}
									isDisable={verifiedBankDetails?.verified === 'pending' ? false : verifiedBankDetails?.verified ? true : false}
									handleEnterKeyPress={handleEnterKeyPress}

								/>
								{/* {
							isShowBankNumber ?
								<EyeIcon className="cursor-pointer h-6 w-6 text-black-500 absolute right-5 top-12" onClick={handleAccNumber} />
								:
								<EyeSlashIcon className="cursor-pointer h-6 w-6 text-black-500 absolute right-5 top-12" onClick={handleAccNumber} />
						} */}

								{
									!isValidConfirmBankNumber && <p style={{ color: 'red', fontSize: '13px', marginTop: '10px' }}>Invalid account number! Special characters are not allowed.</p>
								}
							</div>
						</div>


				}

				<div className="flex items-center justify-end md:justify-center mb-5 md:mb-1">

					{
						(!inputValueConfirmBankNumber || !isValidConfirmBankNumber || isInValidBankNumber || isInValidConfirmBankNumber) ?
							<VerifyBankBtn
								isDisable={true}
								status={verifiedBankDetails.verified === 'pending' ? 'pending' : verifiedBankDetails.verified ? 'success' : !verifiedBankDetails.verified ? 'failed' : 'pending'}
							/>
							:
							<VerifyBankBtn
								verifyBtn={() => handleVerifyBtn()}
								isDisable={inputValueConfirmBankNumber !== inputValueBankNumber}
								isVerify={verifiedBankDetails?.verified}
								status={verifiedBankDetails.verified === 'pending' ? 'pending' : verifiedBankDetails.verified ? 'success' : !verifiedBankDetails.verified ? 'failed' : 'pending'}
							/>
					}
					{
						verifiedBankDetails.verified !== 'pending' &&
						<button
							className={`px-9 h-[${'47px'}] flex items-center rounded-[10px] leading-6 text-white`}
							// onClick={() => { }}
							onClick={() => handleVerifyAgain(false)}
							style={{ background: '#000', marginLeft: '15px' }}

						>
							<span>{'Re-enter Details'}</span>
						</button>
					}
				</div>
				{verifiedBankDetails?.verified === true &&
					<div className="bg-[#E0FFE5]  p-4 rounded mx-auto my-5 ">
						<div className="w-[40%]">
							{/* <div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between">
								<p>Bank Name</p>
								<p>{kycStep5?.fetchBankData?.BANK}</p>
							</div> */}
							<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
								<p>Bank Account Holder’s Name</p>
								<p>{verifiedBankDetails?.beneficiary_name_with_bank}</p>
							</div>
							<div className="flex"></div>
						</div>
					</div>

				}
				{
					verifiedBankDetails?.verified === false &&
					<div className="bg-[#E0FFE5]  p-4 rounded mx-auto my-5 ">
						<div className="w-[70%]">
							<div className="flex gap-5   lg:pl-5 justify-between my-2">
								{/* <p>Error while verifying bank account</p> */}
								<p className='text-[#FF4343]'>{'Bank details not verified.Please Re-enter bank details or click continue to proceed.'}</p>
							</div>
							<div className="flex"></div>
						</div>
					</div>
				}
				{
					bankstatus === true &&
					<div className="bg-[#E0FFE5]  p-4 rounded mx-auto my-5 ">
						<div className="w-[70%]">
							<div className="flex gap-5   lg:pl-5 justify-between my-2">
								{/* <p>Error while verifying bank account</p> */}
								<p className='text-[#FF4343]'>{'Your Name On PAN and Bank Account Does Not Match,You would be asked for Bank-Proof.'}</p>
							</div>
							<div className="flex"></div>
						</div>
					</div>
				}
				{/* {
					verifiedBankDetails?.verified === false &&
					<>
						<UploadBankProof  uploadDone={uploadDone}/>
					</>
				} */}

			</div>
			<div style={{ marginBottom: '30px' }} className="flex item justify-between">
				<BackBtn handleBackBtn={() => backBtn({ step, index })} />

				{
					success == true ?
						<ContinueBtn handleContinueBtn={() => mahi()} />
						:
						<ContinueBtn isDisable={true} />
				}

			</div>
			<SearchIfcModal setValidIfscCode={setValidIfscCode} isModalOpen={isModelOpen} handleKycModel={handleKycModel} isClearCode={isClearCode} isClearData={isClearData} handleGetBankNameList={handleGetBankNameList} />
		</div>

	);
};
