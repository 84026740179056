import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dayjs from "dayjs";
import CustomDatePicker from "../../components/inputs/CustomDatePicker";
import { MyPopover } from "../../components/popover/Popover";
import { PageCount } from "../../components/reactTable/PageCount";
import { ReactTable } from "../../components/reactTable/ReactTable";
import { ReactTableHeader } from "../../components/reactTable/ReactTableHeader";
import {
  PopoverChildComp,
  PopoverParentComp,
} from "../../components/reactTable/ReactTablePopupBtn";
import { SearchBar } from "../../components/searchbar/SearchBar";
import {
  getApplicationAction,
  getApplicationStatusAction,
} from "../../redux/actions/application.action";
import FilterPopover from "./filterPopover";
import { ReAssignModal } from "../../components/modal/admin/ReAssignModal";

export const PendingApplication = () => {
  const dispatch = useDispatch();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const userDetail = useSelector(
    (state) => state?.user?.userDetails?.[0] || {}
  );
  const { pendingApplication } = useSelector((state) => state?.application || []);
  console.log(pendingApplication, 'pendingApplication');
  const [isLoading, setIsLoading] = useState(true);
  const [clientId, setClientId] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [search1, setSearch] = useState('');


  console.log(pendingApplication, 'applicationData');
  const handleReassignModal = (data) => {
    setIsModelOpen(true);
    setClientId(data.ClientId);
  };


  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const columns = [
    {
      Header: "Client",
      accessor: "clientName",
      minWidth: 130,
      Cell: ({ row }) =>
        `${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
        }`,
    },
    {
      Header: "PAN",
      accessor: "pan",
      minWidth: 130,
      Cell: ({ row }) => row.original?.PanNo,
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 130,
      Cell: ({ row }) => 
      row?.original?.IsETrade == null?'Bonanza':"Bigul",

    },
   
    {
      Header: "Created At",
      accessor: "createdAt",
      minWidth: 130,
      Cell: ({ row }) =>
        row.original?.created_at &&
        dayjs(row.original?.created_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
      sortable: true,
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      minWidth: 130,
      Cell: ({ row }) =>
        row.original?.updated_at &&
        dayjs(row.original?.updated_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
    },
    { Header: "RM", 
    accessor: "rm", 
    Cell: ({ row }) => row.original?.rm_name,
    minWidth: 100 },
    {
      Header: "Verifier",
      accessor: "verifier",
      minWidth: 100,
      Cell: ({ row }) => row?.original?.verifier_name,
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 60,
      Cell: ({ row }) => (
        <MyPopover
          PopoverParentComp={PopoverParentComp}
          PopoverChildComp={PopoverChildComp}
          isVerify={true}
          redirectUrl="/application/new-application"
          applicationType="allApplication"
          selectedData={row.original}
          isView={true}
          handleReassignModal={handleReassignModal}
          viewUrl="/application/view-application"
          handleEdit={true}
        />
      ),
    },
  ];

  const handleApplication = ({ start, end, search }) => {
    dispatch(
      getApplicationAction("pendingApplication", {
        UserId: userDetail.user_id,
        RoleId: userDetail.role_id,
        // KYCStatus: "pending",
        start: start || 1,
        end: end || 20,
        search: search || "",
        channel_type:selectedOption

      })
    );
  };
  // const handleApplicationfilter = ({ start, end, search }) => {
  //   dispatch(
  //     getApplicationAction("pendingApplication", {
  //       UserId: userDetail.user_id,
  //       RoleId: userDetail.role_id,
  //       // KYCStatus: "pending",
  //       start: start || 1,
  //       end: end || 20,
  //       search: search || "",
  //       channel_type:selectedOption
  //     })
  //   );
  // };

  useEffect(() => {
    handleApplication({ start: 1, end: 50, search:search1 });
  }, [selectedOption]);
  
  // useEffect(() => {
  //   handleApplicationfilter({ start: 1, end: 50, search: "" });
  // }, [selectedOption]);
  
  const handleSearch = ({ searchVal }) => {
    setSearch(searchVal)
  // if (searchVal.length == 0) {
  //     handleApplication({ start: 1, end: 50, search: "" });
  //   }
  //   else {
      handleApplication({ start: "", end: "", search: searchVal });
    // }

  };

  return (
    <>
      <div className="w-full flex flex-col ">
        <div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
          <div className="flex flex-col md:flex-row gap-y-5  items-center justify-between mb-7 ">
            <ReactTableHeader
              title="Pending Verification"
              numberOfApplications={pendingApplication && pendingApplication?.length}
            />
            {/* <PageCount /> */}
          </div>
          <div className="mb-6 flex items-center ">
         
         
          <SearchBar border={"border"}
              data={pendingApplication.data}
              handleSelect={handleSearch} />

              <div style={{ height: '50px', width: '150px', borderWidth: 0.2, marginLeft: '20px', borderRadius: '10px', textAlign: 'center', alignItems: 'center' }}>
              <select value={selectedOption} onChange={handleSelectChange} style={{ width: '100%', height: '100%', border: 'none', outline: 'none', borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
                <option value="All">All</option>
                <option value="Bigul">Bigul</option>
                <option value="Bonanza">Bonanza</option>
              </select>
            </div>
          
          </div>
          {isLoading ? (
            <>
              <SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
                <Skeleton
                  count={10}
                  style={{ height: "30px", marginTop: "10px" }}
                  borderRadius={10}
                />
              </SkeletonTheme>
            </>
          ) : (
            <ReactTable columns={columns} data={pendingApplication?.data || []} />
          )}
        </div>
      </div>
      <ReAssignModal
        title="Re-assign Tasks"
        isModalOpen={isModelOpen}
        clientId={clientId}
        closeModal={() => {
          setIsModelOpen(false);
          setClientId();
        }}
      />
    </>
  );
};
