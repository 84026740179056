export const getUserList = 'GET_USER_LIST';
export const setUserList = 'SET_USER_LIST';

export const getUserListAction = (payload) => ({
	type: getUserList,
	payload
});

export const setUserListAction = (payload) => ({
	type: setUserList,
	payload
});
