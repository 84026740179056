import { all } from 'redux-saga/effects';
import appSaga from './app.saga';
import authSaga from './auth.saga';
import reviewApplicationSaga from './kyc/review.saga';
import verifyApplicationSaga from './kyc/verify.saga';
import kycSaga from './kyc/kyc.saga';
import kycStep1Saga from './kyc/step1.saga';
import kycStep2Saga from './kyc/step2.saga';
import kycStep3Saga from './kyc/step3.saga';
import kycStep4Saga from './kyc/step4.saga';
import kycStep5Saga from './kyc/step5.saga';
import kycStep6Saga from './kyc/step6.saga';
import applicationSaga from './application.saga';
import userSaga from './user.saga';
import dkycStep2Saga from './dkyc/step2.saga';
import dkycSaga from './dkyc/dkyc.saga.';
import e_SignReportSaga from './e-sign-report.saga';
import manageUserSaga from './manageuser.saga';

export default function * rootSaga () {
	yield all([
		appSaga(),
		authSaga(),
		kycSaga(),
		kycStep1Saga(),
		kycStep2Saga(),
		kycStep3Saga(),
		kycStep4Saga(),
		kycStep5Saga(),
		kycStep6Saga(),
		dkycSaga(),
		dkycStep2Saga(),
		reviewApplicationSaga(),
		verifyApplicationSaga(),
		applicationSaga(),
		userSaga(),
		e_SignReportSaga(),
		manageUserSaga(),
	]);
};
