import { svgAssets } from '../../assets/asset';



export const sidebarData = [
	{
		name: 'Dashboard',
		route: '/',
		icon: svgAssets.sidebar.dashboard,
		selectedIcon: svgAssets.sidebar.selectedDashboard,
		roles: ['rm', 'verifier', 'admin', 'TC']
	},
	{
		
		name: 'Start Bigul KYC',
		route: '/kyc',
		icon: svgAssets.sidebar.startKyc,
		selectedIcon: svgAssets.sidebar.selectedStartKyc,
		roles: ['rm','admin'],
		
	},
	{
		name: 'Start Bonanza KYC',
		route: '/kyc1',
		icon: svgAssets.sidebar.startKyc,
		selectedIcon: svgAssets.sidebar.selectedStartKyc,
		roles: ['rm','admin'],
		
		
	},
	{
		name: 'E-sign Report',
		route: '/e-sign-report',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm', 'verifier','admin']
	},
	
	{
		name: 'Application',
		route: '/application',
		icon: svgAssets.sidebar.application,
		selectedIcon: svgAssets.sidebar.selectedApplication,
		roles: ['rm', 'verifier', 'admin', 'TC'],
		nestedRoutes: [
			
			{
				name: 'Verified Applicants',
				route: '/verified',
				roles: ['rm', 'verifier', 'admin', 'TC'],
			},
			{
				name: 'Rejected Applicants',
				route: '/rejected',
				roles: ['rm',  'admin', 'TC',],
			},
			{
				name: 'Pending Applicants',
				route: '/pending',
				roles: ['rm', 'verifier', 'admin', 'TC'],
			},
			{
				name: 'Incomplete Applicants',
				route: '/incomplete',
				roles: ['rm', 'admin', 'TC'],
			},
			{
				name: 'Resubmitted',
				route: '/resubmitted',
				roles: ['verifier',],
			}
		]
	},
	
	{
		name: 'User List',
		route: '/user-list',
		icon: svgAssets.sidebar.userList,
		selectedIcon: svgAssets.sidebar.selectedUserList,
		roles: []
	},
	
	{
		name: 'Reassignment Report',
		route: '/reassignment-report',
		icon: svgAssets.sidebar.report,
		selectedIcon: svgAssets.sidebar.selectedReport,
		roles: []
	},
	
	{
		name: 'Download Forms',
		route: '/download-forms',
		icon: svgAssets.sidebar.download,
		selectedIcon: svgAssets.sidebar.selectedDownload,
		roles: ['rm', 'verifier']
	},
	{
		name: 'Link Aadhar',
		// route: '/link-aadhar',
		icon: svgAssets.sidebar.linkAadhar,
		selectedIcon: svgAssets.sidebar.selectedLinkAadhar,
		roles: ['rm', 'verifier', 'admin']
	},
	{
		name: 'Other',
		route: '/other',
		icon: svgAssets.sidebar.report,
		selectedIcon: svgAssets.sidebar.selectedReport,
		roles: ['rm','admin','verifier'],
		nestedRoutes: [
			{
				name: ' Segment & Income',
				route: '/other2',
				roles: ['rm','admin'],
			},
			{
				name: 'Nominee Updation',
				route: '/nominee_udateion',
				roles: ['rm','admin'],
			},
			{
				name: 'Bigul Reactivation ',
				route: '/bigul',
				roles: ['rm','admin','verifier'],
			},
			{
				name: 'Bonanza Reactivation ',
				route: '/bonanza',
				roles: ['rm','admin','verifier'],
			},
			{
				name: 'DDPI Activation',
				route: '/Ddpi',
				roles: ['rm','admin'],
			},
			{
				name: 'Add Segment / Income verifier url',
				route: '/seg',
				roles: ['verifier'],
			},
			]
	},
	{
		name: 'Support',
		route: '/support',
		// isPopup: true,
		icon: svgAssets.sidebar.logout,
		selectedIcon: svgAssets.sidebar.selectedLogout,
		roles: ['rm', 'verifier','admin']
	},
	// {
	// 	name: 'profile',
	// 	route: '/user-detail',
	// 	// isPopup: true,
	// 	icon: svgAssets.sidebar.application,
	// 	selectedIcon: svgAssets.sidebar.selectedApplication,
	// 	roles: ['verifier']
	// },
	{
		name: 'FAQ',
		route: '/faq',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm', 'verifier','admin']
	},
	{
		name: 'BA onboarding',
		route: '/link',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm'] 
	},
	{
		name: 'Rm Buyback',
		// route: '/link',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm', 'verifier','admin']

	},
	{
		name: 'PreTrade Confirmation',
		// route: '/link',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm']

	},
	{
		name: 'Logout',
		route: '',
		isPopup: true,
		icon: svgAssets.sidebar.logout,
		selectedIcon: svgAssets.sidebar.selectedLogout,
		roles: ['rm', 'verifier', 'admin', 'TC']
	},
	
	
];

export const sidebarData1 = [
	{
		name: 'Dashboard',
		route: '/',
		icon: svgAssets.sidebar.dashboard,
		selectedIcon: svgAssets.sidebar.selectedDashboard,
		roles: ['rm', 'verifier', 'admin', 'TC']
	},
	{
		
		name: 'Start Bigul KYC',
		route: '/kyc',
		icon: svgAssets.sidebar.startKyc,
		selectedIcon: svgAssets.sidebar.selectedStartKyc,
		roles: ['rm','admin'],
		
	},
	
	{
		name: 'E-sign Report',
		route: '/e-sign-report',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm', 'verifier','admin']
	},
	
	{
		name: 'Application',
		route: '/application',
		icon: svgAssets.sidebar.application,
		selectedIcon: svgAssets.sidebar.selectedApplication,
		roles: ['rm', 'verifier', 'admin', 'TC'],
		nestedRoutes: [
			// {
			// 	name: 'All Application',
			// 	route: '/all',
			// 	roles: ['rm', 'verifier', 'admin', 'TC'],
			// },
			{
				name: 'Verified Applicants',
				route: '/verified',
				roles: ['rm', 'verifier', 'admin', 'TC'],
			},
			{
				name: 'Rejected Applicants',
				route: '/rejected',
				roles: ['rm',  'admin', 'TC',],
			},
			{
				name: 'Pending Applicants',
				route: '/pending',
				roles: ['rm', 'verifier', 'admin', 'TC'],
			},
			{
				name: 'Incomplete Applicants',
				route: '/incomplete',
				roles: ['rm', 'admin', 'TC'],
			},
			{
				name: 'Resubmitted',
				route: '/resubmitted',
				roles: ['verifier',],
			}
		]
	},

	// {
	// 	name: 'Modification',
	// 	route: '/modification',
	// 	icon: svgAssets.sidebar.dashboard,
	// 	selectedIcon: svgAssets.sidebar.selectedDashboard,
	// 	roles: ['verifier']
	// },
	// {
	// 	name: 'Account List',
	// 	route: '/account-list',
	// 	icon: svgAssets.sidebar.accountList,
	// 	selectedIcon: svgAssets.sidebar.selectedAccountList,
	// 	roles: ['rm', 'verifier', 'admin']
	// },
	{
		name: 'User List',
		route: '/user-list',
		icon: svgAssets.sidebar.userList,
		selectedIcon: svgAssets.sidebar.selectedUserList,
		roles: []
	},
	// {
	// 	name: 'User Detail',
	// 	route: '/user-detail',
	// 	icon: svgAssets.sidebar.userList,
	// 	selectedIcon: svgAssets.sidebar.selectedUserList,
	// 	roles: ['admin']
	// },
	{
		name: 'Reassignment Report',
		route: '/reassignment-report',
		icon: svgAssets.sidebar.report,
		selectedIcon: svgAssets.sidebar.selectedReport,
		roles: []
	},
	// {
	// 	name: 'Reports',
	// 	route: '/reports',
	// 	icon: svgAssets.sidebar.report,
	// 	selectedIcon: svgAssets.sidebar.selectedReport,
	// 	roles: ['admin'],
	// 	nestedRoutes: [
	// 		{
	// 			name: 'E-Sign Report',
	// 			route: '/e-sign-report',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		},
	// 		{
	// 			name: 'Sales Report',
	// 			route: '/sales-report',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		},
	// 		{
	// 			name: 'Monthly Report',
	// 			route: '/monthly-report',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		},
	// 		{
	// 			name: 'Verifier Report',
	// 			route: '/verifier-report',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		},
	// 		{
	// 			name: 'Ageing Report',
	// 			route: '/ageing-report',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		}
	// 	]
	// },
	// {
	// 	name: 'Brokerage Master',
	// 	route: '/brokerage-master',
	// 	icon: svgAssets.sidebar.master,
	// 	selectedIcon: svgAssets.sidebar.selectedMaster,
	// 	roles: ['admin'],
	// 	nestedRoutes: [
	// 		{
	// 			name: 'Brokerage Plans',
	// 			route: '/brokerage-plans',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		},
	// 		{
	// 			name: 'Map Brokerage',
	// 			route: '/map-brokerage',
	// 			roles: ['rm', 'verifier', 'admin', 'TC'],
	// 		}
	// 	]
	// },
	{
		name: 'Download Forms',
		route: '/download-forms',
		icon: svgAssets.sidebar.download,
		selectedIcon: svgAssets.sidebar.selectedDownload,
		roles: ['rm', 'verifier']
	},
	{
		name: 'Link Aadhar',
		// route: '/link-aadhar',
		icon: svgAssets.sidebar.linkAadhar,
		selectedIcon: svgAssets.sidebar.selectedLinkAadhar,
		roles: ['rm', 'verifier', 'admin']
	},
	// {
	// 	name: 'Settings',
	// 	route: '/settings',
	// 	icon: svgAssets.sidebar.setting,
	// 	selectedIcon: svgAssets.sidebar.selectedSetting,
	// 	roles: ['rm', 'verifier', 'admin']
	// },
	{
		name: 'Other',
		route: '/other',
		icon: svgAssets.sidebar.report,
		selectedIcon: svgAssets.sidebar.selectedReport,
		roles: ['rm','admin','verifier'],
		nestedRoutes: [
			{
				name: ' Segment & Income',
				route: '/other2',
				roles: ['rm','admin'],
			},
			{
				name: 'Nominee Updation',
				route: '/nominee_udateion',
				roles: ['rm','admin'],
			},
			{
				name: 'Bigul Reactivation ',
				route: '/bigul',
				roles: ['rm','admin','verifier'],
			},
			{
				name: 'Bonanza Reactivation ',
				route: '/bonanza',
				roles: ['rm','admin','verifier'],
			},
			{
				name: 'DDPI Activation',
				route: '/Ddpi',
				roles: ['rm','admin'],
			},

		]
	},
	{
		name: 'Support',
		route: '/support',
		// isPopup: true,
		icon: svgAssets.sidebar.application,
		selectedIcon: svgAssets.sidebar.accountList,
		roles: ['rm', 'verifier','admin']
	},
	{
		name: 'FAQ',
		route: '/faq',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm', 'verifier','admin']
	},
	{
		name: 'BA onboarding',
		route: '/link',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm']
	},
	{
		name: 'Rm Buyback',
		// route: '/link',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm', 'verifier','admin']

	},
	{
		name: 'PreTrade Confirmation',
		// route: '/link',
		icon: svgAssets.sidebar.eSign,
		selectedIcon: svgAssets.sidebar.selectedESign,
		roles: ['rm']

	},
	{
		name: 'Logout',
		route: '',
		isPopup: true,
		icon: svgAssets.sidebar.logout,
		selectedIcon: svgAssets.sidebar.selectedLogout,
		roles: ['rm', 'verifier', 'admin', 'TC']
	},
	
	
];
