import { useEffect } from 'react';
import { svgAssets } from '../../../assets/asset';
import { PanInput } from '../../../components/inputs/PanInput';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import { VerifyBtn } from '../../../components/buttons/VerifyBtn';
import { SendDigilockerBtn } from '../../../components/buttons/SendDigilockerBtn';
import { MainTitle } from '../../../components/common/MainTitle';
// import { changeStep2ErrorAction, changeStep2InputAction, getPanCardDetailAction, addSegmentInfoAction, getValidatePanDetailsAction, callSendDigilockerAction, callIsDigioKycCompletedDataAction, setIsSendDigioLockerLinkAction } from '../../../redux/actions/kyc/step2.action';
import { useDispatch, useSelector } from 'react-redux';
import { validatePanNo } from '../../../utils/verifyInput';
import { KraModal } from '../../../components/modal/kyc/KraModal';
import { useState } from 'react';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { BackBtn } from '../../../components/buttons/BackBtn';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApCo, postApi, postFormApiCo, postFormApiCo1 } from '../../../services/common';
import copy from '../../../assets/svg/copy-svgrepo-com.svg'

import { changeStep2ErrorAction, addSegmentInfo, getValidatePanDetails, callSendDigilockerLink, isDigioKycCompleteData, getPanCardDetail, setKRAAction, setPanCardDetailAction, setValidatePanDetailsAction, getSendDigilockerLinkDataAction, setIsSendDigioLockerLinkAction, setDigioLokerDataAction, callSendDigilockerAction, callIsDigioKycCompletedDataAction, getPanCardDetailAction, addSegmentInfoAction, getValidatePanDetailsAction, changeStep2InputAction } from '../../../redux/actions/kyc/step2.action';
import { BranchCodeDropdown } from '../../../components/dropdown/branchCodeDropdown';
import { Modal } from 'antd';

export const VerifyPan = ({ step, index, backBtn, continueBtn }) => {
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const kycStep2 = useSelector(state => state?.kycStep2 || {});
	const channel = useSelector(state => state?.kyc?.channel || '');
	const [branch, setSelectBranch] = useState('')
	const validatePanDetails = useSelector(state => state?.kycStep2?.validatePanDetails?.data || {});
	const isDigilockerKycComplete = useSelector(state => state?.kycStep2?.digilockerLinkData || {});
	const [kraModal, setKraModal] = useState({
		isOpen: false,
		isKra: false
	});
	const [bonanza, setBonanza] = useState(false);
	const [digioLink, setDigioLink] = useState('');
	const [isSendDigilockerLink, setIsSendDigilockerLink] = useState(false);
	const [isSendDigilockerLinkLoading, setIsSendDigilockerLinkLoading] = useState(false);
	const channel2 = userDetail?.BranchCode == 90 ? 'Bigul' : channel;
	const m = userDetail?.BranchCode?.split(',')
	const branchCode = m
	const filteredBranchCode = branchCode?.filter(code => code?.length <= 3);
	const [isModalOpen, setIsmodel] = useState(false);
	const [name, setName] = useState('')
	const [nameError, setNameError] = useState('')
	const [checkDigio, setCheckDigio] = useState(false)


	const handleInput = ({ type, value }) => {
		dispatch(changeStep2InputAction({ type, value: value.toUpperCase() }));
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeStep2ErrorAction({ type, isError, errorText }));
	};

	const extractAppStatus = (xmlResponse, check) => {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
		const appStatusElement = xmlDoc.querySelector(check);
		if (appStatusElement) {
			const appStatusCode = appStatusElement.textContent;
			return appStatusCode.toString();
		} else {
			return '';
		}
	};

	const handleInput1 = ({ type, value }) => {
		setSelectBranch(value)
	}

	const validateAppStatus = (appStatus) => {
		// const validCodes = ['002', '102', '202', '302', '402', '007', '107', '207', '307', '407'];
		const validCodes = ['007', '107', '207', '307', '407'];
		// Add your 15 valid codes here
		return validCodes.includes(appStatus) ? true : false;
	};

	const validateUPDTStatus = (appStatus) => {
		// const validCodes = ['002', '102', '202', '302', '402', '502', '007', '107', '207', '307', '407', '507'];
		const validCodes = ['002', '005', '007', '107', '207', '307', '407'];
		return validCodes.includes(appStatus) ? true : false;
	};

	const findKRADescription = (kraCode) => {
		const KraCodes = [
			{ '002': 'CVLKRA' },
			{ '007': 'CVLKRA' },
			{ '102': 'NDML' },
			{ '107': 'NDML' },
			{ '202': 'DOTEX' },
			{ '207': 'DOTEX' },
			{ '302': 'CAMS' },
			{ '307': 'CAMS' },
			{ '402': 'KARVY' },
			{ '407': 'KARVY' },
			{ '502': 'BSE' },
			{ '507': 'BSE' }
		]
		const response = KraCodes.find(item => Object.keys(item)[0] === kraCode);
		return response ? response[kraCode] : null;
	}
	const handleCallBack = async (isKRA) => {
		setCheckDigio(false)
		setNameError('')
		kycStep2.panInput.isError = false;
		if (isKRA.status == false) {
			if (isKRA?.message == 'Your account with Bonanza already exists.') {
				setBonanza(true)
				return
			}
			if (isKRA?.message == 'Please enter Name as per PAN.') {
				setNameError(isKRA.message)
				return
			}

			else {
				kycStep2.panInput.errorText = isKRA.message;
				kycStep2.panInput.isError = true;
			}
		}
		else {
			if (isKRA.data.seeding == 'Y') {
				setBonanza(false)
				const panStatusData = await getApCo('kyraapi?panNo=', kycStep2.panInput.value)
				const status = extractAppStatus(panStatusData, 'APP_STATUS');
				const updatedStatus = extractAppStatus(panStatusData, 'APP_UPDT_STATUS');
				const KRA_APPROVED = validateAppStatus(status);
				const KRA_UPDATED = validateUPDTStatus(updatedStatus);
				const dob = kycStep2.dobInput.value
				const APP_PER_ADD_PROOF = extractAppStatus(panStatusData, 'APP_PER_ADD_PROOF');
				const description = findKRADescription(status);
				const InputXML = `<APP_REQ_ROOT>
	  										<APP_PAN_INQ>
	  										<APP_PAN_NO>${kycStep2.panInput.value}</APP_PAN_NO>
	  										<APP_DOB_INCORP>${dob}</APP_DOB_INCORP>
	  										<APP_POS_CODE>1200000235</APP_POS_CODE>
	  										<APP_RTA_CODE>1200000235</APP_RTA_CODE>
	  									<APP_KRA_CODE>${description}</APP_KRA_CODE>
	  									<FETCH_TYPE>I</FETCH_TYPE>
	  									</APP_PAN_INQ>
	  										</APP_REQ_ROOT>`;
				var postdata = {
					InputXML: InputXML,
					journey: "DKYC",
					mobile: kycStep1.mobileInput.value,
				};
				const response = await postFormApiCo1(postdata, 'kyraapisecond')
				if (response.data) {
					const Addr1 = extractAppStatus(response.data, 'APP_COR_ADD1');
					const Addr2 = extractAppStatus(response.data, 'APP_COR_ADD2');
					const Addr_per1 = extractAppStatus(response.data, 'APP_PER_ADD1');
					const Addr_per2 = extractAppStatus(response.data, 'APP_PER_ADD2');
					const xmlDoc = new DOMParser().parseFromString(response?.data, 'application/xml');
					const Error = xmlDoc.querySelector('APP_ERROR_CODE');
					if (Error) {
						dispatch(setKRAAction({
							data: {},
							update_status: { app_statue: status, update_status: updatedStatus } || {},
							isKRA: false,
						}));
						dispatch(setPanCardDetailAction({
							isShowDigilockerLinkButton: true

						}));
						// dispatch(changeStep2ErrorAction({ 
						// 	type: 'panInput',
						// 		isError: true,
						// 		errorText: "Please enter valid date of birth",
						//  }));

					}
					else {
						dispatch(changeStep2ErrorAction({
							type: 'panInput',
							isError: false,
							errorText: "",
						}));
						if ((KRA_APPROVED && KRA_UPDATED) && (APP_PER_ADD_PROOF == '31' || APP_PER_ADD_PROOF == '01' || APP_PER_ADD_PROOF == "02" || APP_PER_ADD_PROOF == '06') && (Addr1.trim().length > 3 && Addr2.trim().length > 3 && Addr_per1.trim().length > 3 && Addr_per2.trim().length > 3)) {
							setDigioLink('')
							const payload1 = {
								mobile: kycStep1.mobileInput.value,
								app_no: status,
								xml_data: response?.data,
								journey: "DKYC",

							}
							const response1 = await postFormApiCo(payload1, 'save-kra-data');
							if (response1?.data?.status == 200) {
								const payload = {
									PanNo: kycStep2.panInput.value,
									BirthDate: kycStep2.dobInput.value,
									mobile: kycStep1.mobileInput.value,
									kra_status: 1,
									journey: "DKYC",
									fullName: isKRA?.data?.Name

								}
								const responseKra = await postApi(payload, '/GetPanName');
								if (responseKra.status == 200 && responseKra?.data?.success) {

									dispatch(setPanCardDetailAction({
										data: {
											APP_F_NAME: extractAppStatus(response.data, 'APP_F_NAME'),
											APP_POS_CODE: extractAppStatus(response.data, 'APP_POS_CODE'),
											APP_TYPE: extractAppStatus(response.data, 'APP_TYPE'),
											APP_NO: extractAppStatus(response.data, 'APP_NO'),
											APP_PAN_COPY: extractAppStatus(response.data, 'APP_PAN_COPY'),
											APP_PAN_COPY: extractAppStatus(response.data, 'APP_PAN_COPY'),
											APP_NAME: extractAppStatus(response.data, 'APP_NAME'),
											APP_DOB_DT: extractAppStatus(response.data, 'APP_DOB_DT'),
											APP_COR_ADD1: extractAppStatus(response.data, 'APP_COR_ADD1'),
											APP_COR_ADD2: extractAppStatus(response.data, 'APP_COR_ADD2'),
											APP_COR_ADD3: extractAppStatus(response.data, 'APP_COR_ADD3'),
											APP_COR_CITY: extractAppStatus(response.data, 'APP_COR_CITY'),
											APP_COR_PINCD: extractAppStatus(response.data, 'APP_COR_PINCD'),
											APP_COR_STATE: extractAppStatus(response.data, 'APP_COR_STATE'),
											APP_COR_CTRY: extractAppStatus(response.data, 'APP_COR_CTRY'),
											APP_MOB_NO: extractAppStatus(response.data, 'APP_MOB_NO'),
											APP_EMAIL: extractAppStatus(response.data, 'APP_EMAIL'),
											APP_COR_ADD_PROOF: extractAppStatus(response.data, 'APP_COR_ADD_PROOF'),
											APP_PER_ADD1: extractAppStatus(response.data, 'APP_PER_ADD1'),
											APP_PER_ADD2: extractAppStatus(response.data, 'APP_PER_ADD2'),
											APP_PER_ADD3: extractAppStatus(response.data, 'APP_PER_ADD3'),

											APP_PER_CITY: extractAppStatus(response.data, 'APP_PER_CITY'),
											APP_PER_PINCD: extractAppStatus(response.data, 'APP_PER_PINCD'),
											APP_PER_STATE: extractAppStatus(response.data, 'APP_PER_STATE'),
											APP_PER_CTRY: extractAppStatus(response.data, 'APP_PER_CTRY'),
											APP_PER_ADD_PROOF: extractAppStatus(response.data, 'APP_PER_ADD_PROOF'),
											APP_PER_ADD_REF: extractAppStatus(response.data, 'APP_PER_ADD_REF'),
											APP_COR_ADD_REF: extractAppStatus(response.data, 'APP_COR_ADD_REF'),
											APP_KRA_INFO: extractAppStatus(response.data, 'APP_KRA_INFO'),
											APP_MAR_STATUS: extractAppStatus(response.data, 'APP_MAR_STATUS'),
											APP_GEN: extractAppStatus(response.data, 'APP_GEN'),
											KRA_ID: extractAppStatus(response.data, 'APP_OTHKRA_BATCH'),

										} || {},
										isKRA: true,
										isShowDigilockerLinkButton: false,
										update_status: { app_statue: status, update_status: updatedStatus }
									}));
									dispatch(setKRAAction({
										data: {
											APP_F_NAME: extractAppStatus(response.data, 'APP_F_NAME'),
											APP_POS_CODE: extractAppStatus(response.data, 'APP_POS_CODE'),
											APP_TYPE: extractAppStatus(response.data, 'APP_TYPE'),
											APP_NO: extractAppStatus(response.data, 'APP_NO'),
											APP_PAN_COPY: extractAppStatus(response.data, 'APP_PAN_COPY'),
											APP_PAN_COPY: extractAppStatus(response.data, 'APP_PAN_COPY'),
											APP_NAME: extractAppStatus(response.data, 'APP_NAME'),
											APP_DOB_DT: extractAppStatus(response.data, 'APP_DOB_DT'),
											APP_COR_ADD1: extractAppStatus(response.data, 'APP_COR_ADD1'),
											APP_COR_ADD2: extractAppStatus(response.data, 'APP_COR_ADD2'),
											APP_COR_ADD3: extractAppStatus(response.data, 'APP_COR_ADD3'),
											APP_COR_CITY: extractAppStatus(response.data, 'APP_COR_CITY'),
											APP_COR_PINCD: extractAppStatus(response.data, 'APP_COR_PINCD'),
											APP_COR_STATE: extractAppStatus(response.data, 'APP_COR_STATE'),
											APP_COR_CTRY: extractAppStatus(response.data, 'APP_COR_CTRY'),
											APP_MOB_NO: extractAppStatus(response.data, 'APP_MOB_NO'),
											APP_EMAIL: extractAppStatus(response.data, 'APP_EMAIL'),
											APP_COR_ADD_PROOF: extractAppStatus(response.data, 'APP_COR_ADD_PROOF'),
											APP_PER_ADD1: extractAppStatus(response.data, 'APP_PER_ADD1'),
											APP_PER_ADD2: extractAppStatus(response.data, 'APP_PER_ADD2'),
											APP_PER_ADD3: extractAppStatus(response.data, 'APP_PER_ADD3'),
											APP_PER_CITY: extractAppStatus(response.data, 'APP_PER_CITY'),
											APP_PER_PINCD: extractAppStatus(response.data, 'APP_PER_PINCD'),
											APP_PER_STATE: extractAppStatus(response.data, 'APP_PER_STATE'),
											APP_PER_CTRY: extractAppStatus(response.data, 'APP_PER_CTRY'),
											APP_PER_ADD_PROOF: extractAppStatus(response.data, 'APP_PER_ADD_PROOF'),
											APP_PER_ADD_REF: extractAppStatus(response.data, 'APP_PER_ADD_REF'),
											APP_COR_ADD_REF: extractAppStatus(response.data, 'APP_COR_ADD_REF'),
											APP_KRA_INFO: extractAppStatus(response.data, 'APP_KRA_INFO'),
											APP_MAR_STATUS: extractAppStatus(response.data, 'APP_MAR_STATUS'),
											APP_GEN: extractAppStatus(response.data, 'APP_GEN'),
											KRA_ID: extractAppStatus(response.data, 'APP_OTHKRA_BATCH'),

										},
										update_status: { app_statue: status, update_status: updatedStatus }
									}));
								}
								else {
									toast(response?.data?.msg ? response?.data.msg : 'something went wrong')
								}

							}
							else {

								toast(response?.message ? response?.message : 'something went wrong')
							}
						}
						else {
							const payload = {
								PanNo: kycStep2.panInput.value,
								BirthDate: kycStep2.dobInput.value,
								mobile: kycStep1.mobileInput.value,
								kra_status: 2,
								journey: "DKYC",
								fullName: isKRA?.data?.Name

							}
							const response = await postApi(payload, '/GetPanName');
							if (response.status == 200 && response?.data?.success) {
								dispatch(setKRAAction({
									data: {},
									update_status: { app_statue: status, update_status: updatedStatus } || {},
									isKRA: false,
								}));
								dispatch(setPanCardDetailAction({
									isShowDigilockerLinkButton: true
								}));
							}
							else {
								toast(response?.data?.msg ? response?.data.msg : 'something went wrong')

							}
						}
					}
				}
				else {
					const payload = {
						PanNo: kycStep2.panInput.value,
						BirthDate: kycStep2.dobInput.value,
						mobile: kycStep1.mobileInput.value,
						kra_status: 2,
						journey: "DKYC",
						fullName: isKRA?.data?.Name

					}
					const response = await postApi(payload, '/GetPanName');
					if (response.status == 200 && response?.data?.success) {
						dispatch(setKRAAction({
							data: {},
							update_status: { app_statue: status, update_status: updatedStatus } || {},
							isKRA: false,
						}));
						dispatch(setPanCardDetailAction({
							isShowDigilockerLinkButton: true
						}));
					}
					else {
						toast(response?.data?.msg ? response?.data.msg : 'something went wrong')
					}
				}
			}

			if (isKRA.data.seeding == 'R') {
				toast("PAN Number Rejected! Please recheck and submit again for KYC verification.")
				return
			}
			if (isKRA.data.seeding == 'N') {
				toast("PAN and Aadhaar Not Linked! Please link to proceed with KYC.")
				return
			}
		}
		const payloadKyc = {
			mobile: kycStep1.mobileInput.value,
			journey: "DKYC",

		}
		const responseKyc = await postApi(payloadKyc, '/getKycJourney');
		if (responseKyc.data.data[0].json_data !== null) {
			setCheckDigio(true)
		}



	};
	const handleContinueBtn = async () => {
		const payload = {
			PanNo: kycStep2.panInput.value,
			fullName: kycStep2?.validatePanDetails?.data?.firstName + ' ' + kycStep2?.validatePanDetails?.data?.middleName + ' ' + kycStep2?.validatePanDetails?.data?.lastName,
			mobile: kycStep1.mobileInput.value,
			BirthDate: kycStep2?.dobInput?.value,
			kra_status: kycStep2.isKRA ? 1 : 2,
			journey: "DKYC",
		}
		const response = await postApi(payload, '/GetPanName');
		if (response?.data?.success) {
			dispatch(addSegmentInfoAction({
				PanNo: kycStep2.panInput.value,
				AccountType: ["TRADING", "CDSL"],
				Equity: ["NSE", "BSE"],
				Derivative: ["NSEF", "NSEC", "BSEF", "BSEC", "MCX"],
				Commodity: ["NCDX"],
				UserId: userDetail.user_id,
				BranchCode: branch,
				LDCode: kycStep2.panInput.value,
				TerminalCode: kycStep2.panInput.value,
				channel: channel2,
				mobile: kycStep1.mobileInput.value,
				journey: "DKYC",
			}, callbackcontinoue));
		}
		else {
			toast(response?.data?.message)
		}
	};

	const callbackcontinoue = (resp) => {
		if (resp == 'success') {
			continueBtn({ step, index })
			toast('PAN verification successfull.')
		}
		else {
			toast(resp?.message ? resp?.message : 'something went wrong')
		}
	}

	const handleIsSendDigioLockerLink = (data) => {
		setDigioLink(data?.data?.link)

	};

	const handleIsDigioKYCCompleted = (data) => {
		console.log('IsDigioKYCCompleted data', data)
	};

	const handleVerify = () => {
		dispatch(setIsSendDigioLockerLinkAction(false));
		const isValidPan = validatePanNo(kycStep2.panInput.value || '')
		const isValidDOB = kycStep2.dobInput.value !== '';
		if (isValidPan & isValidDOB) {
			const currentDate = new Date();
			const inputDate = new Date(kycStep2.dobInput.value);
			const yearsDiff = currentDate.getFullYear() - inputDate.getFullYear();
			if (yearsDiff >= 100) {
				handleInputError({ type: 'dobInput', isError: true, errorText: 'Date of Birth is invalid. Please enter correct Date of Birth!' });
				return;
			}
			dispatch(getPanCardDetailAction({
				PanNo: kycStep2.panInput.value,
				BirthDate: kycStep2.dobInput.value,
				mobile: kycStep1.mobileInput.value,
				name: name,
				journey: "DKYC",

			}, handleCallBack));
		} if (!isValidPan) {
			handleInputError({ type: 'panInput', isError: true, errorText: 'Invalid PanNo!' });
		}
		if (kycStep2.panInput.value === '') {
			handleInputError({ type: 'panInput', isError: true, errorText: 'PAN number is required.' });
		}
		if (!isValidDOB) {
			handleInputError({ type: 'dobInput', isError: true, errorText: 'DOB is Required!' });
		}
	};
	useEffect(() => {
		let digioInterval;
		if (kycStep2.isSendDigilockerLink && !kycStep2?.digilockerLinkData?.success) {
			digioInterval = setInterval(() => {

				dispatch(callIsDigioKycCompletedDataAction({
					mobile: kycStep1.mobileInput.value,
					journey: "DKYC",

				}, handleIsDigioKYCCompleted));
			}, 20000);
		}
		return () => clearInterval(digioInterval);
	}, [kycStep2.isSendDigilockerLink, kycStep2?.digilockerLinkData?.success]);

	const sendLinkBtn = () => {
		// setCheckDigio(false)
		setIsmodel(false);
		dispatch(setPanCardDetailAction({
			isShowDigilockerLinkButton: true

		}));
		dispatch(setKRAAction({
			data: {},
			update_status: { app_statue: {}, update_status: {} } || {},
			isKRA: false,
		}));
		setIsSendDigilockerLink(false);
		setIsSendDigilockerLinkLoading(true);
		dispatch(setIsSendDigioLockerLinkAction(true));
		setTimeout(() => {
			setIsSendDigilockerLink(true);
			dispatch(callSendDigilockerAction({
				name: validatePanDetails?.firstName + " " + validatePanDetails?.lastName,
				mobile: kycStep1.mobileInput.value,
				journey: "DKYC",
			}, handleIsSendDigioLockerLink));
			setIsSendDigilockerLinkLoading(false);
		}, 2000);
	}

	const handleEnterKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleVerify()
		}
	};

	const copyUrl = () => {
		const textToCopy = digioLink;

		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				toast('Customer link copied to clipboard!')
			})
			.catch((err) => {
				toast(err)
				// setCopySuccess('Copy failed');
				// console.error('Unable to copy text: ', err);
			});
	}


	const handleCancel = () => {
		setIsmodel(false);
	};

	const showDetails = () => {
		setIsmodel(true);

	}

	return (
		<div className="w-full flex flex-col">
			<ToastContainer autoClose={100} />
			<div className="min-h-[calc(100vh-300px)]">
				<MainTitle title="PAN Details" />
				<div className="w-full mb-10 md:grid md:grid-cols-12 gap-5">
					<div className="w-full md:col-span-12 mt-4 md:mt-0">
						<div style={{ marginRight: 10 }} className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap
			${'mb-[5px]'}
		`}>
							{'Select Branch Code'}
						</div>
						<div style={{ width: '600px' }}>
							<BranchCodeDropdown
								placeholder="Please select branch code"
								options={filteredBranchCode || []}
								inputType="BranchCode"
								channel={channel}
								handleSelect={handleInput1}

							/>
						</div>
					</div>
				</div>
				<div className="mb-10  grid  md:grid-cols-2 gap-10">
					<div className="">
						<PanInput
							icon={svgAssets.kyc.panCard}
							label="PAN"
							isImportant={true}
							placeholder="Enter PAN number"
							value={kycStep2.panInput.value}
							inputType="panInput"
							handleInput={handleInput}
							inputMessage={kycStep2.panInput.errorText}
							inputMessageType={kycStep2.panInput.isError && 'error'}
							// isDisable={kycStep2.status === 'success'}
							autoFocus={true}
						/>
					</div>
					<div>
						<DatePickerInput
							icon={svgAssets.kyc.dateOfBirth}
							label="Date Of Birth"
							isImportant={true}
							placeholder="DD-MM-YYYY"
							value={kycStep2?.dobInput?.value}
							inputType="dobInput"
							handleInput={handleInput}
							handleInputError={handleInputError}
							inputMessage={kycStep2.dobInput.errorText}
							inputMessageType={kycStep2.dobInput.isError && 'error'}
							// isDisable={kycStep2.status === 'success'}
							handleEnterKeyPress={handleEnterKeyPress}
						/>
						{
							!kycStep2?.dobInput?.errorText &&
							<p className='mt-2 text-sm' style={kycStep2?.dobInput?.value?.length === 10 ? { color: '#007813' } : { color: '#000' }}>(Note - Minimum age requirement is 18 years)</p>
						}
					</div>

					<div className="w-full flex flex-col">
						<label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">
							{
								<img
									className="max-h-[22px] mr-1.5 object-contain"
									alt="input_icon"
									src={svgAssets.kyc.panCard}
								/>
							}
							<span className="mr-1">{'NAME'}</span>
							{<span className="text-[#EA0000]">*</span>}
						</label>
						<input
							type={'text'}
							className={`px-4 text-[#353535] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular
				 leading-6 focus:outline-none `}
							style={{
								height: '47px',
								textTransform: 'uppercase'
							}}

							// disabled={isDisable}
							value={name}
							placeholder={'Name as per pan'}

							onChange={(e) => setName(e.target.value.toUpperCase())}

						/>
						{
							nameError && (
								<div
									style={{
										color: 'red'
									}}
									className="my-2.5 flex items-center text-sm font-poppinsRegular"
								>
									{/* <img className="mr-1.5" alt="input_message_type_icon" src={messageTypeParameter[inputMessageType].icon} /> */}
									<span className="inline-flex items-center">{nameError}</span>
								</div>
							)
						}

					</div>

				</div>
				<div className="flex items-center justify-end md:justify-center ">
					{
						kycStep2?.dobInput?.value?.length !== 10 || kycStep2?.dobInput?.isError || kycStep2?.panInput?.value?.length < 10 || name.length == 0 ?
							<VerifyBtn
								isDisable={true}
							/>
							:
							<VerifyBtn
								// isDisable={kycStep2.status === 'success'}
								// isVerify={kycStep2.status === 'success' && !kycStep2.isShowDigilockerLinkButton}
								verifyBtn={handleVerify}
							/>
					}

				</div>
				{
					bonanza &&
					<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
						<div className="mb-[18px] grid md:grid-cols-7 gap-1">
							<h6 className="font-poppinsMedium font-medium col-span-2">Name As On PAN</h6>
							<span className="capitalize font-poppinsMedium font-medium col-span-5">{validatePanDetails[0]?.Name}</span>
							{
								validatePanDetails?.message
							}
						</div>
						<div className="grid md:grid-cols-7 gap-1">
							<span className={` col-span-5 text-[#007813]`}>
								{
									kycStep2?.validatePanDetails?.message
								}
							</span>
						</div>
					</div>
				}
				{
					kycStep2.status == 'success' && validatePanDetails && (
						<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
							<div className="mb-[18px] grid md:grid-cols-7 gap-1">
								<h6 className="font-poppinsMedium font-medium col-span-2">Name As On PANs</h6>
								<span className="capitalize font-poppinsMedium font-medium col-span-5">{validatePanDetails.firstName + " " + validatePanDetails?.middleName + " " + validatePanDetails.lastName}</span>
							</div>
							<div className="grid md:grid-cols-7 gap-1">
								<h6 className="font-poppinsMedium font-medium col-span-2 whitespace-nowrap flex">PAN linked with Aadhaar
									{validatePanDetails.seeding == 'Y' ?
										<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} />
										:
										<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.infoIcon} />
									}
								</h6>
								<span className={` col-span-5
									${validatePanDetails.seeding == 'Y' ? 'text-[#007813] font-poppinsMedium font-medium' : 'text-[#FF4343] font-poppinsRegular'}
								`}>
									{
										validatePanDetails.seeding == 'Y'
											? 'Your PAN is linked with Aadhar'
											: 'PAN is not linked with Aadhar. Please ask your client to link their PAN with Aadhar'
									}
								</span>
							</div>
						</div>
					)
				}

				{
					isSendDigilockerLink &&
					<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
						<div className="grid md:grid-cols-5 gap-1">
							<h6 className="font-poppinsMedium font-medium col-span-2 whitespace-nowrap flex">Link sent successfully, please check email.
								<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} />
							</h6>
						</div>
					</div>
				}
				{
					isDigilockerKycComplete.success && <>
						<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
							<div className="grid md:grid-cols-5 gap-1">
								<h6 className="font-poppinsMedium font-medium col-span-2 whitespace-nowrap flex">Digilocker KYC Completed Successfully.
									<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} />
								</h6>
							</div>
						</div>
					</>
				}
			</div>
			<div className="flex item justify-between">
				<Modal
					title="KRA Details"
					className="w-full p-[33px]"
					width={'50%'}
					style={{ textAlign: 'center' }}
					open={isModalOpen}
					centered={true}
					onCancel={handleCancel}
					footer={null}
				>
					<div className="mx-auto px-6 py-5 ">
						<div className="flex justify-between mb-2.5">

							<div className="">
								<div style={{
									width: '300px', border: '2px solid', color: 'gray',
									padding: '10px', marginBottom: '20px'
								}}>
									<p>Name : {kycStep2?.data?.APP_NAME}</p>
								</div>
							</div>
							<div style={{
								width: '300px', border: '2px solid', color: 'gray',
								padding: '10px', marginBottom: '20px'
							}}>
								<p>FatherName : {kycStep2?.data?.APP_F_NAME}</p>
							</div>
						</div>
						<div className="flex justify-between mb-2.5">

							<div className="">
								<div style={{
									width: '300px', border: '2px solid', color: 'gray',
									padding: '10px', marginBottom: '20px'
								}}>
									<p>Marital Status : {kycStep2?.data?.APP_MAR_STATUS == 2 ? 'Single' : 'Married'}</p>
								</div>
							</div>
							<div style={{
								width: '300px', border: '2px solid', color: 'gray',
								padding: '10px', marginBottom: '20px'
							}}>
								<p>Birth of City : {kycStep2?.data?.APP_COR_CITY}</p>
							</div>
						</div>
						<div style={{
							width: '640px', border: '2px solid', color: 'gray',
							padding: '10px', marginBottom: '20px'
						}}>
							<p> {kycStep2?.data?.APP_PER_ADD1}</p>
						</div>
						<div style={{
							width: '640px', border: '2px solid', color: 'gray',
							padding: '10px', marginBottom: '20px'
						}}>
							<p>{kycStep2?.data?.APP_PER_ADD2}</p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<span style={{ fontWeight: 'bold', color: 'gray' }}>Note : Message pending </span>
							<SendDigilockerBtn
								isDisable={isSendDigilockerLink}
								isSend={isSendDigilockerLink}
								isSendDigilockerLink={setIsSendDigilockerLink}
								isSendDigilockerLinkLoading={isSendDigilockerLinkLoading}
								sendLinkBtn={sendLinkBtn}
							>
							</SendDigilockerBtn>
						</div>

					</div>
				</Modal>
				<div></div>
				{

					<>
						{/* {
							kycStep2?.isKRA &&
							<>
								<button style={{maxWidth:'175px'}}
				className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
				onClick={() => {showDetails()}}
			>
			<span className='ml-2'>Check KRA Details</span>
			</button>
							</>
						} */}
						{
							kycStep2.isShowDigilockerLinkButton &&
							<>
								<SendDigilockerBtn
									isDisable={isSendDigilockerLink}
									isSend={isSendDigilockerLink}
									isSendDigilockerLink={setIsSendDigilockerLink}
									isSendDigilockerLinkLoading={isSendDigilockerLinkLoading}
									sendLinkBtn={sendLinkBtn}
								>
								</SendDigilockerBtn>
							</>
						}
					</>
				}
			</div>

			{
				digioLink?.length > 0 &&
				<button style={{ maxWidth: '175px' }}
					className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
					onClick={() => { copyUrl() }}
				>
					<img src={copy} width={'15px'} />
					<span className='ml-2'>Copy Digio link</span>
				</button>

			}
			{
				digioLink?.length > 0 &&
				<h6 style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} className="font-poppinsMedium font-medium col-span-2 whitespace-nowrap flex mt-3 mb-3">After completing the Digio process, please do not refresh the page until the continue button appears automatically.</h6>
			}
			{
				kycStep2?.isKRA &&
				<div>
					<p style={{ textAlign: 'center' }}>I hereby provide consent for downloading my updated KYC details from KRA, to open my Bigul account</p>
				</div>

			}
			<div className="flex item justify-between">
				<div></div>

				{
					kycStep2.status === 'success' && !kycStep2.isShowDigilockerLinkButton || isDigilockerKycComplete.success || checkDigio ?
						<ContinueBtn handleContinueBtn={() => handleContinueBtn()} />
						:
						<ContinueBtn isDisable={true} />
				}
			</div>
			{/* {
				kycStep2.status === 'success' && kycStep2.isKRA &&

				<KraModal
					isModalOpen={kraSuccessModal}
					handleKycModel={handleKycModelClose}
					isKraApproved={kycStep2.status === 'success' ? true : false}
				/>
			}
			<KraModal
				isModalOpen={kraModal.isOpen}
				handleKycModel={handleKycModel}
				isKraApproved={kraModal.isKra}
			/> */}
		</div>
	);
};


