export const changeStep6Input = 'CHANGE_STEP6_INPUT';
export const changeStep6Error = 'CHANGE_STEP6_ERROR';
export const getTransactionDetail = 'GET_TRANSACTION_DETAIL';
export const setTransactionDetail = 'SET_TRANSACTION_DETAIL';
export const updateTransactionValue = 'UPDATE_TRANSACTION_VALUE';
export const updateSegmentValue = 'UPDATE_SEGMENT_VALUE';
export const getBrokerageSchemeDetail = 'GET_BROKERAGE_SCHEME';
export const getBrokerageSchemeDataDetail = 'GET_BROKERAGE_SCHEME_DATA';
export const setBrokerageSchemeDataDetail = 'SET_BROKERAGE_SCHEME_DATA';
export const setBrokerageSchemeDetail = 'SET_BROKERAGE_SCHEME';
export const getDPTariffDetail = 'GET_DP_TARIFF_DETAIL';
export const setDPTariffDetail = 'SET_DP_TARIFF_DETAIL';
export const getDeclarationList = 'GET_DECLARATION_LIST';
export const setDeclarationList = 'SET_DECLARATION_LIST';
export const updateDeclarationValue = 'UPDATE_DECLARATION_VALUE';
export const getIncomeProofList = 'GET_INCOME_PROOF_LIST';
export const setIncomeProofList = 'SET_INCOME_PROOF_LIST';
export const uploadBankProof = 'UPLOAD_BANK_PROOF';
export const uploadSalarySlip = 'UPLOAD_SALARY_SLIP';
export const updateTermsAndConditionValue = 'UPDATE_TERMS_AND_CONDITION_VALUE';
export const saveBusinessInfo = 'SAVE_BUSINESS_INFO';
export const saveBrokerageInfo = 'SAVE_BROKERAGE_INFO';
export const saveSegmentInfo = 'SAVE_SEGMENT_INFO';
export const updateSegmentInfo = 'UPDATE_SEGMENT_INFO';
export const saveDeclarationInfo = 'SAVE_DECLARATION_INFO';
export const saveDeclarationInfoData = 'SAVE_DECLARATION_INFO_DATA';
export const callSendUploadIncomeProofLink = 'CALL_SEND_UPLOAD_INCOME_PROOF_LINK';
export const sendUploadIncomeProofLink = 'SEND_UPLOAD_INCOME_PROOF_LINK';
export const setBrokerageSchemeList = 'SET_BROKERAGE_SCHEME_LIST';
export const getBrokerageSchemeList = 'GET_BROKERAGE_SCHEME_LIST';
export const changeSegmentValue = 'CHANGE_SEGMENT_VALUE';
export const segmentsValue = 'SEGMENT_VALUE';


export const changeStep6InputAction = (payload) => ({
	type: changeStep6Input,
	payload
});

export const changeStep6ErrorAction = (payload) => ({
	type: changeStep6Error,
	payload
});

export const getTransactionDetailAction = (callback) => ({
	type: getTransactionDetail,
	callback
});

export const setTransactionDetailAction = (payload) => ({
	type: setTransactionDetail,
	payload
});

export const updateTransactionValueAction = (payload) => ({
	type: updateTransactionValue,
	payload
});

export const updateSegmentValueAction = (payload) => {

	return (
		{
			type: updateSegmentValue,
			payload
		}
	)
};

export const getBrokerageSchemeDetailAction = (callback) => ({
	type: getBrokerageSchemeDetail,
	callback
});

export const getBrokerageSchemeDetailDataAction = (payload) => ({
	type: getBrokerageSchemeDataDetail,
	payload
});

export const setBrokerageSchemeDetailDataAction = (payload) => ({
	type: setBrokerageSchemeDataDetail,
	payload
});

export const setBrokerageSchemeDetailAction = (payload) => ({
	type: setBrokerageSchemeDetail,
	payload
});

export const getDPTariffDetailAction = (callback) => ({
	type: getDPTariffDetail,
	callback
});

export const setDPTariffDetailAction = (payload) => ({
	type: setDPTariffDetail,
	payload
});

export const getDeclarationListAction = (callback) => ({
	type: getDeclarationList,
	callback
});

export const setDeclarationListAction = (payload) => ({
	type: setDeclarationList,
	payload
});

export const updateDeclarationValueAction = (payload) => ({
	type: updateDeclarationValue,
	payload
});

export const getIncomeProofListAction = (callback) => ({
	type: getIncomeProofList,
	callback
});

export const setIncomeProofListAction = (payload) => ({
	type: setIncomeProofList,
	payload
});

export const uploadBankProofAction = (payload) => {
	return (
		{
			type: uploadBankProof,
			payload
		}
	)
};
export const uploadSalarySlipAction = (payload) => {
	return (
		{
			type: uploadSalarySlip,
			payload
		}
	)
};

export const updateTermsAndConditionValueAction = (payload) => ({
	type: updateTermsAndConditionValue,
	payload
});

export const saveBusinessInfoAction = (payload,callback) => ({
	type: saveBusinessInfo,
	payload,
	callback
});

export const saveBrokerageInfoAction = (payload,callback) => ({
	type: saveBrokerageInfo,
	payload,
	callback
});

export const saveSegmentInfoAction = (payload) => ({
	type: saveSegmentInfo,
	payload
});
export const updateSegmentInfoAction = (payload,callback) => ({
	type: updateSegmentInfo,
	payload,
	callback
});

export const saveDeclarationInfoAction = (payload,callback) => ({
	type: saveDeclarationInfo,
	payload,
	callback
});

export const saveDeclarationInfoDataAction = (payload) => ({
	type: saveDeclarationInfoData,
	payload
});

export const callSendUploadIncomeProofLinkAction = (payload) => {
	return (
		{
			type: callSendUploadIncomeProofLink,
			payload
		}
	)
};

export const sendUploadIncomeProofLinkAction = (payload) => {
	return (
		{
			type: sendUploadIncomeProofLink,
			payload
		}
	)
};


export const getBrokerageSchemeListAction = (payload) => {
	return (
		{
			type: getBrokerageSchemeList,
			payload
		}
	)
};
export const setBrokerageSchemeListAction = (payload) => {
	return (
		{
			type: setBrokerageSchemeList,
			payload
		}
	)
};



export const segmentsValueAction = (payload) => ({
	type: segmentsValue,
	payload
});


// export const changeStep6Input = 'CHANGE_STEP6_INPUT';
// export const changeStep6Error = 'CHANGE_STEP6_ERROR';
// export const getTransactionDetail = 'GET_TRANSACTION_DETAIL';
// export const setTransactionDetail = 'SET_TRANSACTION_DETAIL';
// export const updateTransactionValue = 'UPDATE_TRANSACTION_VALUE';
// export const updateSegmentValue = 'UPDATE_SEGMENT_VALUE';
// export const getBrokerageSchemeDetail = 'GET_BROKERAGE_SCHEME';
// export const getBrokerageSchemeDataDetail = 'GET_BROKERAGE_SCHEME_DATA';
// export const setBrokerageSchemeDataDetail = 'SET_BROKERAGE_SCHEME_DATA';
// export const setBrokerageSchemeDetail = 'SET_BROKERAGE_SCHEME';
// export const getDPTariffDetail = 'GET_DP_TARIFF_DETAIL';
// export const getDPTariffDetail1 = 'GET_DP_TARIFF_DETAIL1';
// export const setDPTariffDetail = 'SET_DP_TARIFF_DETAIL';
// export const setDPTariffDetail1 = 'SET_DP_TARIFF_DETAIL1';
// export const getDeclarationList = 'GET_DECLARATION_LIST';
// export const setDeclarationList = 'SET_DECLARATION_LIST';
// export const updateDeclarationValue = 'UPDATE_DECLARATION_VALUE';
// export const getIncomeProofList = 'GET_INCOME_PROOF_LIST';
// export const setIncomeProofList = 'SET_INCOME_PROOF_LIST';
// export const uploadBankProof = 'UPLOAD_BANK_PROOF';
// export const uploadSalarySlip = 'UPLOAD_SALARY_SLIP';
// export const updateTermsAndConditionValue = 'UPDATE_TERMS_AND_CONDITION_VALUE';
// export const saveBusinessInfo = 'SAVE_BUSINESS_INFO';
// export const saveBrokerageInfo = 'SAVE_BROKERAGE_INFO';
// export const saveSegmentInfo = 'SAVE_SEGMENT_INFO';
// export const updateSegmentInfo = 'UPDATE_SEGMENT_INFO';
// export const saveDeclarationInfo = 'SAVE_DECLARATION_INFO';
// export const saveDeclarationInfoData = 'SAVE_DECLARATION_INFO_DATA';
// export const callSendUploadIncomeProofLink = 'CALL_SEND_UPLOAD_INCOME_PROOF_LINK';
// export const sendUploadIncomeProofLink = 'SEND_UPLOAD_INCOME_PROOF_LINK';
// export const setBrokerageSchemeList = 'SET_BROKERAGE_SCHEME_LIST';
// export const getBrokerageSchemeList = 'GET_BROKERAGE_SCHEME_LIST';
// export const changeSegmentValue = 'CHANGE_SEGMENT_VALUE';
// export const segmentsValue = 'SEGMENT_VALUE';


// export const changeStep6InputAction = (payload) => ({
// 	type: changeStep6Input,
// 	payload
// });

// export const changeStep6ErrorAction = (payload) => ({
// 	type: changeStep6Error,
// 	payload
// });

// export const getTransactionDetailAction = (callback) => ({
// 	type: getTransactionDetail,
// 	callback
// });

// export const setTransactionDetailAction = (payload) => ({
// 	type: setTransactionDetail,
// 	payload
// });

// export const updateTransactionValueAction = (payload) => ({
// 	type: updateTransactionValue,
// 	payload
// });

// export const updateSegmentValueAction = (payload) => {

// 	return (
// 		{
// 			type: updateSegmentValue,
// 			payload
// 		}
// 	)
// };

// export const getBrokerageSchemeDetailAction = (callback) => ({
// 	type: getBrokerageSchemeDetail,
// 	callback
// });

// export const getBrokerageSchemeDetailDataAction = (payload) => ({
// 	type: getBrokerageSchemeDataDetail,
// 	payload
// });

// export const setBrokerageSchemeDetailDataAction = (payload) => ({
// 	type: setBrokerageSchemeDataDetail,
// 	payload
// });

// export const setBrokerageSchemeDetailAction = (payload) => ({
// 	type: setBrokerageSchemeDetail,
// 	payload
// });

// export const getDPTariffDetailAction = (callback) => ({
// 	type: getDPTariffDetail,
// 	callback
// });
// export const getDPTariffDetailAction1 = (callback) => ({
// 	type: getDPTariffDetail1,
// 	callback
// });

// export const setDPTariffDetailAction = (payload) => ({
// 	type: setDPTariffDetail,
// 	payload
// });
// export const setDPTariffDetailAction1 = (payload) => ({
// 	type: setDPTariffDetail1,
// 	payload
// });

// export const getDeclarationListAction = (callback) => ({
// 	type: getDeclarationList,
// 	callback
// });

// export const setDeclarationListAction = (payload) => ({
// 	type: setDeclarationList,
// 	payload
// });

// export const updateDeclarationValueAction = (payload) => ({
// 	type: updateDeclarationValue,
// 	payload
// });

// export const getIncomeProofListAction = (callback) => ({
// 	type: getIncomeProofList,
// 	callback
// });

// export const setIncomeProofListAction = (payload) => ({
// 	type: setIncomeProofList,
// 	payload
// });

// export const uploadBankProofAction = (payload) => {
// 	console.log("uploadSalarySlip",payload)

// 	return (
// 		{
// 			type: uploadBankProof,
// 			payload
// 		}
// 	)
// };
// export const uploadSalarySlipAction = (payload) => {
// 	return (
// 		{
// 			type: uploadSalarySlip,
// 			payload
// 		}
// 	)
// };

// export const updateTermsAndConditionValueAction = (payload) => ({
// 	type: updateTermsAndConditionValue,
// 	payload
// });

// export const saveBusinessInfoAction = (payload) => ({
// 	type: saveBusinessInfo,
// 	payload
// });

// export const saveBrokerageInfoAction = (payload) => ({
// 	type: saveBrokerageInfo,
// 	payload
// });

// export const saveSegmentInfoAction = (payload) => ({
// 	type: saveSegmentInfo,
// 	payload
// });
// export const updateSegmentInfoAction = (payload) => ({
// 	type: updateSegmentInfo,
// 	payload
// });

// export const saveDeclarationInfoAction = (payload) => ({
// 	type: saveDeclarationInfo,
// 	payload
// });

// export const saveDeclarationInfoDataAction = (payload) => ({
// 	type: saveDeclarationInfoData,
// 	payload
// });

// export const callSendUploadIncomeProofLinkAction = (payload) => {
// 	return (
// 		{
// 			type: callSendUploadIncomeProofLink,
// 			payload
// 		}
// 	)
// };

// export const sendUploadIncomeProofLinkAction = (payload) => {
// 	return (
// 		{
// 			type: sendUploadIncomeProofLink,
// 			payload
// 		}
// 	)
// };


// export const getBrokerageSchemeListAction = (callback) => {
// 	return (
// 		{
// 			type: getBrokerageSchemeList,
// 			callback
// 		}
// 	)
// };
// export const setBrokerageSchemeListAction = (payload) => {
// 	return (
// 		{
// 			type: setBrokerageSchemeList,
// 			payload
// 		}
// 	)
// };



// export const segmentsValueAction = (payload) => ({
// 	type: segmentsValue,
// 	payload
// });
