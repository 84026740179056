import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getClientFiles, getReviewApplication, setClientFilesAction, setReviewApplicationAction, setReviewApplication2Action, sendEquityEsignLink, sendEquityEsign, getEquityEsignLinkResAction, getEquityEsignResAction } from '../../redux/actions/kyc/review.action';
import { postApi, postApiWithoutLoader } from '../../services/common';
import { toast } from 'react-toastify';

function* getReviewApplicationSaga({ payload, callback }) {
	try {
		const data2 = yield call(postApiWithoutLoader, { 
			mobile: payload.mobile
		}, '/get-application-for-review');
		if (data2) {
			yield put(setReviewApplication2Action({ data2: data2?.data?.data || {} }));
			const data = yield call(postApiWithoutLoader, {
				ClientId: payload.ClientId
			}, '/GetCltFullInfo');
			if (data?.data?.success) {
				yield put(setReviewApplicationAction({ data: data?.data?.data || {} }));
				yield call(callback, data?.data?.data);
			}
			else{
				yield put(setReviewApplicationAction({}));
				yield call(callback, {});
			}
		}
		else{
			yield put(setReviewApplication2Action({}));

		}
		
	} catch (err) {
		console.log(err);
	};
};

function* sendEquityEsignLinkSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/send-esign-link');
		
		if(data?.data?.success == true){
			yield put(getEquityEsignLinkResAction(data?.data || {}));
}
		else{
			toast(data.message)
			yield put(getEquityEsignLinkResAction({} || {}));
		}
	} catch (err) {
		toast('somthing went wrong')
	};
};

function* sendEquityEsignSaga({ payload, callback }) {
	
	const datapayload = {
		ClientId: payload.ClientId,
		"IsESign": "Yes",
		"IsType": "equity",
		view_html: 1,
		rejection:payload?.rejection?.length>0 ?1:0
		
	}
	try {
		const data = yield call(postApiWithoutLoader, datapayload, (payload?.channel =="Bigul" ||payload?.channel =="BIGUL")?'/GetESignMyetrade':'/GetESign');
		if (data.response) {
			alert(data.message + 'or Maximum execution time of 30 seconds exceeded');
			yield call(callback,'err');
		} else if (data.status == 200) {
			yield put(getEquityEsignResAction(data?.data || {}));
			yield call(callback, data?.data);
}
else{
	toast(data.message)
	yield call(callback, data?.data);
}
	} catch (err) {
		yield call(callback,err);

	}; 
};

function* getClientFilesSaga({ payload, callback }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/GetClientAllFiles');
		yield put(setClientFilesAction({ data: data?.data?.data || [] }));
		yield call(callback, data?.data?.data);

	} catch (err) {
		console.log(err);
	};
};

function* reviewApplicationSaga() {
	yield all([
		takeEvery(getReviewApplication, getReviewApplicationSaga),
		takeEvery(sendEquityEsignLink, sendEquityEsignLinkSaga),
		takeEvery(sendEquityEsign, sendEquityEsignSaga),
		takeEvery(getClientFiles, getClientFilesSaga)
	]);
}

export default reviewApplicationSaga;
