import { svgAssets } from '../../assets/asset';

export const SendESignBtn = ({ handleBtn , name, successName, isSuccess, isDisabled,color}) => {
	return (
		<button
		style={{backgroundColor:color?color:'black'}}
			className={isSuccess ? "h-[47px] px-5 flex items-center rounded-[10px] leading-6 text-white  ml-2 cursor-default" : "h-[47px] px-5 flex items-center rounded-[10px] leading-6	 text-white bg-black ml-2 cursor-poiner"}
			onClick={() => handleBtn()}
			disabled={isDisabled}
		>
			<span className="mr-2 font-medium font-poppinsMedium">{isSuccess ? successName : name}</span>
			<img alt="Review_icon" src={isSuccess ? svgAssets.kyc.verify : svgAssets.kyc.continueIcon} />
		</button>
	);
};
