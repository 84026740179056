
export const BASE_URL = "https://ekycapi.bigul.co"; 
export const TOKEN = "https://uat_ekyc.bigul.co";
// export const CLIENT_PUBLIC_URL = "https://dkyc.bigul.app";
export const CLIENT_PUBLIC_URL = "https://ekycapi.bigul.co";
// export const DOMAIN = "https://staging.bigul.app";
export const DOMAIN = "https://dkyc.bigul.co";
export const BANKURL="https://py.bigul.app/banks" // using all api all bank verification and details
export const PENNYDROPURL="https://penny.bigul.app/verify-bank-account" 
export const Digio="https://bigul.co/api/" 
export const modification="https://dkyc.bonanzaonline.com/mod_ekyc" 






