import { startLoader, stopLoader } from '../actions/app.action';

const initState = {
	isLoading: false
};

export const appReducer = (state = initState, action) => {
	switch (action.type) {
	case startLoader:
		return {
			...state,
			isLoading: true
		};
	case stopLoader:
		return {
			...state,
			isLoading: false
		};
	default:
		return state;
	}
};
