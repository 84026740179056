// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import dayjs from "dayjs";
// import CustomDatePicker from "../../components/inputs/CustomDatePicker";
// import { MyPopover } from "../../components/popover/Popover";
// import { PageCount } from "../../components/reactTable/PageCount";
// import { ReactTable } from "../../components/reactTable/ReactTable";
// import { reactTableStatusValue } from "../../components/reactTable/reactTableData";
// import { ReactTableHeader } from "../../components/reactTable/ReactTableHeader";
// import {
//   PopoverChildComp,
//   PopoverParentComp,
// } from "../../components/reactTable/ReactTablePopupBtn";
// import { SearchBar } from "../../components/searchbar/SearchBar";
// import {
//   getApplicationAction,
//   getApplicationStatusAction,
// } from "../../redux/actions/application.action";
// import { ReAssignModal } from "../../components/modal/admin/ReAssignModal";

// export const VerifiedApplication = () => {
//   const dispatch = useDispatch();
//   const [isModelOpen, setIsModelOpen] = useState(false);
//   const userDetail = useSelector(
//     (state) => state?.user?.userDetails?.[0] || {}
//   );
//   const applicationData = useSelector(
//     (state) => state?.application?.verifiedApplication || []
//   );

// 	const {applicationStatus } = useSelector(state => state?.application || []);
  

//   const [isLoading, setIsLoading] = useState(true);
//   const [clientId, setClientId] = useState();

//   const handleReassignModal = (data) => {
//     setIsModelOpen(true);
//     setClientId(data.ClientId);
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       setIsLoading(false);
//     }, 2000);
//   }, []);

//   const handleApplication = ({ start, end, search }) => {
//     dispatch(
//       getApplicationAction("verifiedApplication", {
//         UserId: userDetail?.user_id,
//         RoleId: userDetail?.role_id,
//         // KYCStatus: "kycdone",
//         start: start || 1,
//         end: end || 100,
//         search: search || "",
//       })
//     );
//   };

//   useEffect(() => {
//     handleApplication({ start: 1, end:100, search: "" });
//   }, []);

//   const columns = [
//     {
//       Header: "Client",
//       accessor: "clientName",
//       minWidth: 130,
//       Cell: ({ row }) =>
//         `${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
//         }`,
//     },
   
//     {
//       Header: "Pan No", accessor: "pan", minWidth: 130,
//       Cell: ({ row }) => row.original?.PanNo,

//     },
//     {
//       Header: "Type",
//       accessor: "type",
//       minWidth: 130,
//       Cell: ({ row }) => 
//       row?.original?.IsETrade == null?'Bonanza':"Bigul",
//     },
//     // {
//     //   Header: "Verify by",
//     //   accessor: "type",
//     //   minWidth: 130,
//     //   Cell: ({ row }) => row?.original?.VerifierName || "-",
//     // },
//     {
//       Header: "Created At",
//       accessor: "createdAt",
//       minWidth: 130,
//       Cell: ({ row }) =>
//         row.original?.created_at &&
//         dayjs(row.original?.created_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
//       sortable: true,
//     },
//     {
//       Header: "Last Updated",
//       accessor: "updatedAt",
//       minWidth: 130,
//       Cell: ({ row }) =>
//         row.original?.updated_at &&
//         dayjs(row?.original?.updated_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
//     },


//     {
//       Header: "RM",
//       accessor: "rm",
//       minWidth: 100,
//       Cell: ({ row }) => row?.original?.rm_name,
//     },
//     {
//       Header: "Verifier",
//       accessor: "verifier",
//       minWidth: 100,
//       Cell: ({ row }) => row?.original?.VerifierName,
//     },
//     {
//       Header: "Actions",
//       accessor: "actions",
//       minWidth: 60,
//       Cell: ({ row }) => (
//         <MyPopover
//           PopoverParentComp={PopoverParentComp}
//           PopoverChildComp={PopoverChildComp}
//           isVerify={false}
//           redirectUrl="/application/new-application"
//           applicationType="allApplication"
//           selectedData={row.original}
//           isView={true}
//           handleEdit={true}

//           handleReassignModal={handleReassignModal}
//           viewUrl="/application/view-application"
//         />
//       ),
//     },
//   ];

//   // const handleApplication = ({ applicationType, status }) => {
//   // 	dispatch(getApplicationAction(applicationType, {
//   // 		UserId: userDetail.user_id,
//   // 		RoleId: userDetail.role_id,
//   // 		KYCStatus: status,
//   // 		start: 1,
//   // 		end: 50,
//   // 		search: ''

//   // 	}));
//   // };

//   // useEffect(() => {
//   // 	dispatch(getApplicationStatusAction({ UserId: userDetail.user_id, RoleId: userDetail.role_id }));
//   // 	handleApplication({ applicationType: 'applicationData', status: 'kycdone' });
//   // }, []);
//   const handleSelectvalue = (value) => {
//     if (value.searchVal.length == 0) {
//       handleApplication({ start: 1, end:100, search: "" });
//     }
//     else {
//       handleApplication({ start: "", end: "", search: value.searchVal });
//     }

//   }

//   return (
//     <>
//       <div className="w-full flex flex-col ">
//         <div className="w-full py-5 px-[25px] mb-[30px] rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
//           <div className="flex items-center flex-col md:flex-row gap-y-5 justify-between mb-7">
//             <ReactTableHeader
//               title="Verified Applications "
//               numberOfApplications={applicationData && applicationData?.data?.length}
//             />
//             {/* <PageCount /> */}
//           </div>
//           <div className="mb-6 flex  items-center justify-between">

//             <SearchBar border={"border"}
//              data={applicationData?.data}
//               handleSelect={handleSelectvalue} />

//             {/* <div className="flex flex-col md:flex-row gap-5 md:items-center relative md:static">
//               <span className="text-[#787878] mr-3 font-medium font-poppinsMedium whitespace-nowrap">
//                 Select Date
//               </span>
//               <div className="flex justify-end">
//                 <div className="mr-3">
//                   <CustomDatePicker placeholder="From" />
//                 </div>
//                 <div>
//                   <CustomDatePicker placeholder="To" />
//                 </div>
//               </div>
              
//             </div> */}
//           </div>
//           {isLoading ? (
//             <>
//               <SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
//                 <Skeleton
//                   count={10}
//                   style={{ height: "30px", marginTop: "10px" }}
//                   borderRadius={10}
//                 />
//               </SkeletonTheme>
//             </>
//           ) : (
//             <ReactTable columns={columns} data={applicationData?.data || []} />
//           )}
//         </div>
//       </div>

//       {/* <ReAssignModal
//         title="Re-assign Tasks"
//         isModalOpen={isModelOpen}
//         clientId={clientId}
//         closeModal={() => {
//           setIsModelOpen(false);
//           setClientId();
//         }}
//       /> */}
//     </>
//   );
// };


import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dayjs from "dayjs";
import CustomDatePicker from "../../components/inputs/CustomDatePicker";
import { MyPopover } from "../../components/popover/Popover";
import { PageCount } from "../../components/reactTable/PageCount";
import { ReactTable } from "../../components/reactTable/ReactTable";
import { reactTableStatusValue } from "../../components/reactTable/reactTableData";
import { ReactTableHeader } from "../../components/reactTable/ReactTableHeader";
import {
  PopoverChildComp,
  PopoverParentComp,
} from "../../components/reactTable/ReactTablePopupBtn";
import { SearchBar } from "../../components/searchbar/SearchBar";
import {
  getApplicationAction,
  getApplicationStatusAction,
} from "../../redux/actions/application.action";
import { ReAssignModal } from "../../components/modal/admin/ReAssignModal";

export const VerifiedApplication = () => {
  const dispatch = useDispatch();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const userDetail = useSelector(
    (state) => state?.user?.userDetails?.[0] || {}
  );
  const applicationData = useSelector(
    (state) => state?.application?.verifiedApplication || []
  );

  const { applicationStatus } = useSelector(state => state?.application || []);


  const [isLoading, setIsLoading] = useState(true);
  const [clientId, setClientId] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [search1, setSearch] = useState('');

  const handleReassignModal = (data) => {
    setIsModelOpen(true);
    setClientId(data.ClientId);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleApplication = ({ start, end, search }) => {
    dispatch(
      getApplicationAction("verifiedApplication", {
        UserId: userDetail?.user_id,
        RoleId: userDetail?.role_id,
        // KYCStatus: "kycdone",
        start: start || 1,
        end:100,
        search: search?.searchVal || "",
        channel_type: selectedOption

      })
    );
  };
  // const handleApplicationfilter = ({ start, end, search }) => {
  //   dispatch(
  //     getApplicationAction("verifiedApplication", {
  //       UserId: userDetail?.user_id,
  //       RoleId: userDetail?.role_id,
  //       // KYCStatus: "kycdone",
  //       start: start || 1,
  //       end:100,
  //       search: search || "",
  //       channel_type: selectedOption
  //     })
  //   );
  // };

  useEffect(() => {
    handleApplication({ start: 1, end: 100, search:search1 });
  }, [selectedOption]);

  // useEffect(() => {
  //   handleApplicationfilter({ start: 1, end: 100, search: "" });
  // }, [selectedOption]);

  const columns = [
    {
      Header: "Client",
      accessor: "clientName",
      minWidth: 130,
      Cell: ({ row }) =>
        `${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
        }`,
    },

    {
      Header: "Pan No", accessor: "pan", minWidth: 130,
      Cell: ({ row }) => row.original?.PanNo,

    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 130,
      Cell: ({ row }) =>
        row?.original?.IsETrade == null ? 'Bonanza' : "Bigul",
    },
    // {
    //   Header: "Verify by",
    //   accessor: "type",
    //   minWidth: 130,
    //   Cell: ({ row }) => row?.original?.VerifierName || "-",
    // },
    {
      Header: "Created At",
      accessor: "createdAt",
      minWidth: 130,
      Cell: ({ row }) =>
        row.original?.created_at &&
        dayjs(row.original?.created_at).format("DD MMM YYYY" + ' ' + 'HH:mm:ss'),
      sortable: true,
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      minWidth: 130,
      Cell: ({ row }) =>
        row.original?.updated_at &&
        dayjs(row?.original?.updated_at).format("DD MMM YYYY" + ' ' + 'HH:mm:ss'),
    },


    {
      Header: "RM",
      accessor: "rm",
      minWidth: 100,
      Cell: ({ row }) => row?.original?.rm_name,
    },
    {
      Header: "Verifier",
      accessor: "verifier",
      minWidth: 100,
      Cell: ({ row }) => row?.original?.verifier_name,
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 60,
      Cell: ({ row }) => (
        <MyPopover
          PopoverParentComp={PopoverParentComp}
          PopoverChildComp={PopoverChildComp}
          isVerify={false}
          redirectUrl="/application/new-application"
          applicationType="allApplication"
          selectedData={row.original}
          isView={true}
          handleEdit={true}

          handleReassignModal={handleReassignModal}
          viewUrl="/application/view-application"
        />
      ),
    },
  ];

  // const handleApplication = ({ applicationType, status }) => {
  // 	dispatch(getApplicationAction(applicationType, {
  // 		UserId: userDetail.user_id,
  // 		RoleId: userDetail.role_id,
  // 		KYCStatus: status,
  // 		start: 1,
  // 		end: 50,
  // 		search: ''

  // 	}));
  // };

  // useEffect(() => {
  // 	dispatch(getApplicationStatusAction({ UserId: userDetail.user_id, RoleId: userDetail.role_id }));
  // 	handleApplication({ applicationType: 'applicationData', status: 'kycdone' });
  // }, []);
  const handleSelectvalue = (value) => {
  
    setSearch(value)
      handleApplication({ start: "", end: "", search: value });
    

  }

  return (
    <>
      <div className="w-full flex flex-col ">
        <div className="w-full py-5 px-[25px] mb-[30px] rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
          <div className="flex items-center flex-col md:flex-row gap-y-5 justify-between mb-7">
            <ReactTableHeader
              title="Verified Applications "
              numberOfApplications={applicationData && applicationData?.data?.length}
            />
            {/* <PageCount /> */}
          </div>
          <div className="mb-6 flex  items-center ">

            <SearchBar border={"border"}
              data={applicationData?.data}
              handleSelect={handleSelectvalue} />

            <div style={{ height: '50px', width: '150px', borderWidth: 0.2, marginLeft: '20px', borderRadius: '10px', textAlign: 'center', alignItems: 'center' }}>
              <select value={selectedOption} onChange={handleSelectChange} style={{ width: '100%', height: '100%', border: 'none', outline: 'none', borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
                <option value="All">All</option>
                <option value="Bigul">Bigul</option>
                <option value="Bonanza">Bonanza</option>
              </select>
            </div>


            {/* <div className="flex flex-col md:flex-row gap-5 md:items-center relative md:static">
              <span className="text-[#787878] mr-3 font-medium font-poppinsMedium whitespace-nowrap">
                Select Date
              </span>
              <div className="flex justify-end">
                <div className="mr-3">
                  <CustomDatePicker placeholder="From" />
                </div>
                <div>
                  <CustomDatePicker placeholder="To" />
                </div>
              </div>
              
            </div> */}
          </div>
          {isLoading ? (
            <>
              <SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
                <Skeleton
                  count={10}
                  style={{ height: "30px", marginTop: "10px" }}
                  borderRadius={10}
                />
              </SkeletonTheme>
            </>
          ) : (
            <ReactTable columns={columns} data={applicationData?.data || []} />
          )}
        </div>
      </div>

      {/* <ReAssignModal
        title="Re-assign Tasks"
        isModalOpen={isModelOpen}
        clientId={clientId}
        closeModal={() => {
          setIsModelOpen(false);
          setClientId();
        }}
      /> */}
    </>
  );
};

