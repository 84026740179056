// export const getRole = (role) => {
// 	if (role === 'Employee' || role === 'RM') {
// 		return 'rm';
// 	} else if (role === 'VRF') {
// 		return 'verifier';
// 	} else {
// 		return 'admin';
// 	}


export const getRole = (role) => {
	if (role === 'Employee' || role === 'RM') {
		return 'rm';
	} else if (role === 'VRF') {
		return 'verifier';
	} else if (role === 'Telecaller') {
		return 'TC';
	} else {
		return 'admin';
	}
};