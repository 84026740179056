import { changeLoginError, changeLoginInput, clearLoginInput, setLoginUser, setToken, showPassword } from '../actions/auth.action';

const inputEmptyValue = {
	value: '',
	isError: false,
	errorText: ''
};

const initState = {
	userId: {
		...inputEmptyValue
	},
	password: {
		...inputEmptyValue,
		showPassword: false
	},
	confirmPassword: {
		...inputEmptyValue,
		showPassword: false
	},
	userDetails: {},
	accessToken: '',
	isLogin: false
};


export const userReducer = (state = initState, action) => {
	const { type, value, isError, errorText } = action?.payload || {};
	switch (action.type) {
	case setToken:
		return {
			...state,
			accessToken: action.payload.token
		};
	case clearLoginInput:
		return {
			...initState,
			userId: {
				...inputEmptyValue
			},
			password: {
				...inputEmptyValue,
				showPassword: false
			},
			confirmPassword: {
				...inputEmptyValue,
				showPassword: false
			},
			userDetails: {},
			accessToken: '',
			isLogin: false
		};
	case changeLoginInput:
		return {
			...state,
			[type]: {
				...state[type],
				value,
				isError: false,
				errorText: ''
			}
		};
	case changeLoginError:
		return {
			...state,
			[type]: {
				...state[type],
				isError,
				errorText
			}
		};
	case showPassword:
		return {
			...state,
			[type]: {
				...state[type],
				showPassword: !state[type].showPassword
			}
		};
	case setLoginUser:
	

		return {
			...state,
			userDetails: action.payload.userDetails,
			isLogin: true
		};
	default:
		return state;
	}
};
