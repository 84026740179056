import { clearKyc } from '../../actions/kyc/kyc.action';
import { setKRA } from '../../actions/kyc/step2.action';
import { changeStep3Error, changeStep3Input } from '../../actions/kyc/step3.action';
const mutableState = {
	maritalStatus: {
		value: '',
		isError: false,
		errorText: '', 
		status: 'edit',
		options: [
			{ label: 'Single', value: 'Single' },
			{ label: 'Married', value: 'Married' },
			{ label: 'Separated', value: 'Separated' },
			{ label: 'Others', value: 'Others' }
		]
	},
	fatherSpoName: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	gender: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		options: [
			{ label: 'Male', value: 'M' },
			{ label: 'Female', value: 'F' },
			{ label: 'Transgender', value: 'T' },
		]
	},
	birthPlace: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	status: ''
};

const initState = {
	...mutableState
};

export const kycStep3Reducer = (state = initState, action) => {  
	const { type, value, isError, errorText } = action?.payload || {};
	switch (action.type) {
	case clearKyc:
		return {
			...mutableState
		};
	case changeStep3Input:
		return {
			...state,
			[type]: {
				...state[type],
				value,
				isError: false,
				errorText: ''
			}
		};
	case changeStep3Error:
		return {
			...state,
			[type]: {
				...state[type],
				isError,
				errorText
			}
		};
	case setKRA:
		return {
			...state,
			maritalStatus: {
				...state.maritalStatus,
				value: action.payload.data.APP_MAR_STATUS
			},
			fatherSpoName: {
				...state.fatherSpoName,
				value: action.payload.data.APP_F_NAME
			},
			gender: {
				...state.gender,
				value: action.payload.data.APP_GEN
			},
			birthPlace: {
				...state.birthPlace,
				value: action.payload.data.APP_COR_CITY
			}
		};
	default:
		return state;
	}
};
