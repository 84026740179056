import { all, call, put, takeEvery } from 'redux-saga/effects';
import { changeStep1ErrorAction, changeStep1StatusAction, sendEmailOtp, sendMobileOtp, checkMobileOtp, checkEmailOtp, verifyEmailOtp, verifyMobileOtp } from '../../redux/actions/kyc/step1.action';
import { setClientKycStatusAction } from '../../redux/actions/kyc/kyc.action';
import { postApi, postApiWithoutLoader } from '../../services/common';

function* sendMobileOtpSaga({ payload,callback }) {
try {
		const data = yield call(postApi, payload, '/kyc-journey/step-11');
		if (data?.status == 200 && data.data.success) {
			yield call(callback, data.data);

			if (data?.data?.mobile_status == 0) {
				yield put(changeStep1StatusAction({ type1: 'mobileInput', value1: 'done', type2: 'mobileOtp', value2: 'edit' }));

				// yield put(changeStep1StatusAction({ type1: 'mobileInput', value1: 'done', type2: 'mobileOtp', value2: 'edit' }));
			}
			else if (data?.data?.data?.verify_email_status == 0) {
				yield put(changeStep1StatusAction({ type1: 'mobileInput', value1: 'done', type2: 'mobileOtp', value2: 'done', value3: true }));
			}
			else{
				yield put(setClientKycStatusAction(data?.data));
			}
}
		else {
		if(data?.data.type == 'Newkyc' || data.data.message == 'You are not allowed.This account is already Punched with New KYC'){
				window.location.href = data.data?.redirecturl;
				return
			}
			yield call(callback, data.data);
			yield put(changeStep1ErrorAction({ type: 'mobileInput', isError: true, errorText: data.data.message }));

		}
	} catch (err) {
		yield put(changeStep1ErrorAction({ type: 'mobileInput', isError: true, errorText: 'something went wrong' }));

	};
};


function* checkMobileOtpSaga({ payload }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/is-mobile-otp-verified');
		if (data?.data?.error) {
			yield put(changeStep1ErrorAction({ type: 'mobileInput', isError: true, errorText: data.data.message.mobile[0] }));
		} else {
			if (data.data.success === true) {
				yield put(changeStep1StatusAction({ type1: 'mobileOtp', value1: 'done', type2: 'emailInput', value2: 'edit', value3: true }));
				yield put(setClientKycStatusAction(data?.data));
			} else {
				yield put(changeStep1ErrorAction({ type: 'mobileInput', isError: true, errorText: data.data.message.mobile[0] }));
			}
		}
	} catch (err) {
		console.log(err);
	};
};

function* checkEmailOtpSaga({ payload }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/is-email-otp-verified');
		if (data?.data?.error) {
			yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: data.data.message.email[0] }));
		} else {
			if (data.data.success === true) {
				yield put(changeStep1StatusAction({ type1: 'emailOtp', value1: 'done', type2: 'emailInput', value2: 'done', value3: true }));
			} else {
				yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: data.data.message.email[0] }));
			}
		}
		// if (data?.data?.error) {
		// 	yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: data.data.message.email[0] }));
		// } else {
		// 	if(data.data.success === true){
		// 		yield put(changeStep1StatusAction({ type1: 'emailOtp', value1: 'done', type2: 'emailInput', value2: 'edit' , value3: true}));
		// 	}else{
		// 		yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: data.data.message.email[0] }));
		// 	}
		// }
	} catch (err) {
		console.log(err);
	};
};

function* verifyMobileOtpSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/verify-mobile-otp');
		console.log('data==>', data)
		if (!data?.data?.success) {
			yield put(changeStep1ErrorAction({ type: 'mobileOtp', isError: true, errorText: data.data.message }));
		} else {
			yield put(changeStep1StatusAction({ type1: 'mobileOtp', value1: 'done', type2: 'emailInput', value2: 'edit' }));
			yield put(setClientKycStatusAction(data?.data));
		}
	} catch (err) {
		console.log(err);
	};
};

function* sendEmailOtpSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/send-email-otp');
		// if (data?.response.status == 500) {
		// 	yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: data.data.message.mobile[0] }));
		// } else {
		// 	yield put(changeStep1StatusAction({ type1: 'emailInput', value1: 'done', type2: 'emailOtp', value2: 'edit' }));
		// }
		if (data.status == 200 && data?.data?.success) {
			yield put(changeStep1StatusAction({ type1: 'emailInput', value1: 'done', type2: 'emailOtp', value2: 'edit' }));

		} else {
			yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: data.data.message }));

		}

	} catch (error) {
		yield put(changeStep1ErrorAction({ type: 'emailInput', isError: true, errorText: 'something went wrong' }));

	};
};

function* verifyEmailOtpSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/verify-email-otp');
		console.log("verifyEmailOtpSaga", data)
		if (!data?.data?.success) {
			yield put(changeStep1ErrorAction({ type: 'emailOtp', isError: true, errorText: data.data.message }));
		} else {
			yield put(changeStep1StatusAction({ type1: 'emailOtp', value1: 'done', type2: 'emailInput', value2: 'done' }));
		}
	} catch (err) {
		console.log(err);
	};
};

function* kycStep1Saga() {
	yield all([
		takeEvery(sendMobileOtp, sendMobileOtpSaga),
		takeEvery(checkMobileOtp, checkMobileOtpSaga),
		takeEvery(verifyMobileOtp, verifyMobileOtpSaga),
		takeEvery(sendEmailOtp, sendEmailOtpSaga),
		takeEvery(checkEmailOtp, checkEmailOtpSaga),
		takeEvery(verifyEmailOtp, verifyEmailOtpSaga)
	]);
}


export default kycStep1Saga;
