import { clearKyc } from '../../actions/kyc/kyc.action';
import { setClientFiles, setReviewApplication, setReviewApplication2 , getEquityEsignLinkRes, getEquityEsignRes} from '../../actions/kyc/review.action';

const mutableState = {
	reviewData: {},
	reviewData2: {},
	filesData: [],
	sendEsignLinkRes:{}, 
	equityEsignRes:{}
};

const initState = {
	...mutableState
};
export const reviewApplicationReducer = (state = initState, action) => {
	switch (action.type) {
	case clearKyc:
		return {
		...mutableState
	};
	case setReviewApplication:
		return {
			...state,
			reviewData: action.payload.data
		};
	case setReviewApplication2:
		return {
			...state,
			reviewData2: action.payload.data2
		};
	case setClientFiles:
		return {
			...state,
			filesData: action.payload.data
		};
	case getEquityEsignLinkRes:
		return {
			...state,
			sendEsignLinkRes: action.payload
		};
	case getEquityEsignRes:
		return {
			...state,
			equityEsignRes: action.payload
		};
	default:
		return state;
	}
};
