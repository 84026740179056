import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svgAssets } from '../../../assets/asset';
import { BackBtn } from '../../../components/buttons/BackBtn';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { MainTitle } from '../../../components/common/MainTitle';
import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
import { changeStep4ErrorAction, changeStep4InputAction, getAnnualIncomeAction, getEducationDetailAction, getExperienceDetailAction, getOccuptionDetailAction, saveOccuptionAllInfoAction, unsetOccuptionOptionAction } from '../../../redux/actions/kyc/step4.action';

import { validateCommonInput } from '../../../utils/verifyInput';
import { postApi, postApiWithoutLoader } from '../../../services/common';
import { toast } from 'react-toastify';

export const OccuptionDetail = ({ step, index, backBtn, continueBtn }) => {
	const dispatch = useDispatch();
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const [error, setError] = useState('')
	const getCvlDetails = useSelector(state => state?.kycStep2?.data || {});
	const { occuptionDetail, annualIncome, educationDetail, experienceDetail } = useSelector(state => state?.kycStep4 || {});
const [detail,setDetail]=useState('')
	const handleInput = ({ type, value }) => {
		setError('')
		dispatch(changeStep4InputAction({ type, value }));
		if (type == 'annualIncome' && parseInt(value) > 3) {
			setError('Proof of income is needed only if your annual income exceeds Rs 10 Lakhs.');
			return;
		}
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeStep4ErrorAction({ type, isError, errorText }));
	};

	const handleInputUnset = ({ type, value, isError, errorText }) => {
		dispatch(unsetOccuptionOptionAction({ type, value, isError, errorText }));
	};


	const handleContinueBtn = async () => {
		if (getCvlDetails.APP_OCC) {
			occuptionDetail.value = getCvlDetails.APP_OCC == '00' ? occuptionDetail.value : getCvlDetails.APP_OCC == '01' ? '1' :
				getCvlDetails.APP_OCC == '02' ? '2' :
					getCvlDetails.APP_OCC == '03' ? '3' :
						getCvlDetails.APP_OCC == '04' ? '4' :
							getCvlDetails.APP_OCC == '05' ? '5' :
								getCvlDetails.APP_OCC == '06' ? '6' :
									getCvlDetails.APP_OCC == '07' ? '7' :
										getCvlDetails.APP_OCC == '08' ? '8' :
											getCvlDetails.APP_OCC == '09' ? '9' :
												getCvlDetails.APP_OCC == '10' ? '10' :
													getCvlDetails.APP_OCC == '11' ? '11' : occuptionDetail.value
		}
		if (getCvlDetails.APP_INCOME) {
			annualIncome.value = getCvlDetails.APP_INCOME == '00' ? annualIncome.value : getCvlDetails.APP_INCOME == '01' ? '1' :
				getCvlDetails.APP_INCOME == '02' ? '2' :
					getCvlDetails.APP_INCOME == '03' ? '3' :
						getCvlDetails.APP_INCOME == '04' ? '4' :
							getCvlDetails.APP_INCOME == '05' ? '5' :
								getCvlDetails.APP_INCOME == '06' ? '6' : annualIncome.value
		}
		const isOccuptionDetailError = validateCommonInput(occuptionDetail.value);
		const isAnnualIncomeError = validateCommonInput(annualIncome.value);
		const isEducationDetailError = validateCommonInput(educationDetail.value);
		const isExperienceError = validateCommonInput(experienceDetail.value);

		if (isOccuptionDetailError && isAnnualIncomeError && isEducationDetailError && isExperienceError) {
			try {
				const payload = {
					occupation_id: occuptionDetail.value,
					education_id: educationDetail.value,
					income_id: annualIncome.value,
					trading_experience: experienceDetail.value,
					occupation_name:detail,
					mobile: kycStep1.mobileInput.value,
					journey: "DKYC",
					// "income_name": '1-5 LAC',
					// "occupation_name": 'Public Sector',
					// "trading_experience_name": 'New',
				}
				const response = await postApi(payload, '/save-occupational-info ');
				if (response?.data?.success) {
					// toast('Occupational Details verified Succesfully.')
					continueBtn({ step, index });
				}
				else {
					toast('something went wrong')
				}
			}
			catch (error) {
				if (error.response) {
					toast(`API error: ${error.response.status} - ${error.response.data.message}`);
				} else if (error.message) {

					toast(`API error: ${error.message}`);
				} else {
					toast('API error. Please try again.');
				}

			}
		} if (!isOccuptionDetailError) {
			handleInputError({ type: 'occuptionDetail', isError: true, errorText: 'invalid input!' });
		} if (!isAnnualIncomeError) {
			handleInputError({ type: 'annualIncome', isError: true, errorText: 'invalid input!' });
		} if (!isEducationDetailError) {
			handleInputError({ type: 'educationDetail', isError: true, errorText: 'invalid input!' });
		} if (!isExperienceError) {
			handleInputError({ type: 'experienceDetail', isError: true, errorText: 'invalid input!' });
		}
	};

	useEffect(() => {
		occuptionDetail?.options?.length < 1 && dispatch(getOccuptionDetailAction());
		annualIncome?.options?.length < 1 && dispatch(getAnnualIncomeAction());
		educationDetail?.options?.length < 1 && dispatch(getEducationDetailAction());
		experienceDetail?.options?.length < 1 && dispatch(getExperienceDetailAction());
	}, []);

	const handleBack = () => {
		backBtn({ step, index });
		handleInputUnset({ type: 'occuptionDetail', value: '', isError: false, errorText: 'invalid input!' });
		handleInputUnset({ type: 'annualIncome', value: '', isError: false, errorText: 'invalid input!' });
		handleInputUnset({ type: 'educationDetail', value: '', isError: false, errorText: 'invalid input!' });
		handleInputUnset({ type: 'experienceDetail', value: '', isError: false, errorText: 'invalid input!' });
	}

	return (

		<div className="w-full flex flex-col mb-10">

			<div className="lg:min-h-[calc(100vh-300px)] mb-6">
				<MainTitle title="Occupational Details" />
				<div className="grid md:grid-cols-2 gap-10 mb-8">

					<SelectDropdown
						icon={svgAssets.kyc.occupation}
						label="Occupation"
						isImportant={true}
						placeholder="Please select occupation"
						selectedValue={
							getCvlDetails.APP_OCC == '00' ? occuptionDetail.value : getCvlDetails.APP_OCC == '01' ? '1' :
								getCvlDetails.APP_OCC == '02' ? '2' :
									getCvlDetails.APP_OCC == '03' ? '3' :
										getCvlDetails.APP_OCC == '04' ? '4' :
											getCvlDetails.APP_OCC == '05' ? '5' :
												getCvlDetails.APP_OCC == '06' ? '6' :
													getCvlDetails.APP_OCC == '07' ? '7' :
														getCvlDetails.APP_OCC == '08' ? '8' :
															getCvlDetails.APP_OCC == '09' ? '9' :
																getCvlDetails.APP_OCC == '10' ? '10' :
																	getCvlDetails.APP_OCC == '11' ? '11' : occuptionDetail.value}
						options={occuptionDetail?.options || []}
						inputType="occuptionDetail"
						handleSelect={handleInput}
						inputMessage={occuptionDetail.errorText}
						inputMessageType={occuptionDetail.isError && 'error'}
						isDisabled={
							getCvlDetails.APP_OCC == '01' ||
							getCvlDetails.APP_OCC == '02' ||
							getCvlDetails.APP_OCC == '03' ||
							getCvlDetails.APP_OCC == '04' ||
							getCvlDetails.APP_OCC == '05' ||
							getCvlDetails.APP_OCC == '06' ||
							getCvlDetails.APP_OCC == '07' ||
							getCvlDetails.APP_OCC == '08' ||
							getCvlDetails.APP_OCC == '09' ||
							getCvlDetails.APP_OCC == '10' ||
							getCvlDetails.APP_OCC == '11'
						}
					/>
					{
						occuptionDetail.value == '11' &&
						<div className="w-full flex flex-col">
							{(
								<label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">

									<span className="mr-2">{'Occupation Details'}</span>
								</label>
							)}
							<div
								className={`w-full h-full rounded-[10px]
								${"bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10"
									}
						  ${"h-[47px]"}
							`}
							>
								<input
									className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white mt-2.5 ml-5"
									type="text"
									placeholder={'Enter Your Occupation'}
									onChange={(e)=>{setDetail(e.target.value)}}
									value={detail}

								/>
							</div>
						</div>
					}
					<SelectDropdown
						icon={svgAssets.kyc.income}
						label="Annual Income"
						isImportant={true}
						placeholder="Please select annual income"
						error={error}
						selectedValue={
							getCvlDetails.APP_INCOME == '00' ? annualIncome.value : getCvlDetails.APP_INCOME == '01' ? '1' :
								getCvlDetails.APP_INCOME == '02' ? '2' :
									getCvlDetails.APP_INCOME == '03' ? '3' :
										getCvlDetails.APP_INCOME == '04' ? '4' :
											getCvlDetails.APP_INCOME == '05' ? '5' :
												getCvlDetails.APP_INCOME == '06' ? '6' : annualIncome.value}
						options={annualIncome?.options || []}
						inputType="annualIncome"
						handleSelect={handleInput}
						inputMessage={annualIncome.errorText}
						inputMessageType={annualIncome.isError && 'error'}
						isDisabled={
							getCvlDetails.APP_INCOME == '01' ||
							getCvlDetails.APP_INCOME == '02' ||
							getCvlDetails.APP_INCOME == '03' ||
							getCvlDetails.APP_INCOME == '04' ||
							getCvlDetails.APP_INCOME == '05' ||
							getCvlDetails.APP_INCOME == '06'
						}
					/>

				</div>
				<div className="grid md:grid-cols-2 gap-10">
					<SelectDropdown
						icon={svgAssets.kyc.education}
						label="Education"
						isImportant={true}
						placeholder="Please select education"
						selectedValue={educationDetail.value}
						options={educationDetail?.options || []}
						inputType="educationDetail"
						handleSelect={handleInput}
						inputMessage={educationDetail.errorText}
						inputMessageType={educationDetail.isError && 'error'}
					/>
					<SelectDropdown
						icon={svgAssets.kyc.trading}
						label="Trading Experience"
						isImportant={true}
						placeholder="Please select trading experience"
						selectedValue={experienceDetail.value}
						options={experienceDetail?.options || []}
						inputType="experienceDetail"
						handleSelect={handleInput}
						inputMessage={experienceDetail.errorText}
						inputMessageType={experienceDetail.isError && 'error'}
					/>
				</div>

			</div>

			<div className="flex item justify-between pt-6">
				<BackBtn handleBackBtn={handleBack} />
				{

					<ContinueBtn isDisable={occuptionDetail.value === 0 || annualIncome.value === 0 || educationDetail.value === "" || experienceDetail.value === "" ? true : false} handleContinueBtn={handleContinueBtn} />
				}
			</div>
		</div>
	);
};
