import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MainTitle } from '../../components/common/MainTitle';
import { SingleDetail } from './SingleDetail';

export const AddressDetail = ({ reviewData, isVerify,reviewFilesData }) => {
	const Address_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document === 'Address Proof'))

	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);
	return (
		<div className={Address_proof ? 'column-row':'mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]'}>
		<div className="column-col-6">
			<div className="mb-5">
				<MainTitle title="Permanent Address" />
			</div>
			<div className="grid grid-cols-12 gap-10">
				<div className="col-span-10">
					{
						isLoading ?
							<Skeleton count={5} height={50} />
							:
							<>
								<SingleDetail label="Address Line 1" value={reviewData?.PRAddr1 || ''} isVerify={isVerify} />
								<SingleDetail label="Address Line 2" value={reviewData?.PRAddr2} isVerify={isVerify} />
								{reviewData?.PRAddr3?.length > 2 && 
									<SingleDetail label="Address Line 3" value={reviewData?.PRAddr3} isVerify={isVerify} />
								}
								<SingleDetail label="State" value={reviewData?.CRState || ''} />
								<SingleDetail label="City" value={reviewData?.CRCity || ''} />
								<SingleDetail label="Pincode" value={reviewData?.CRPinCode || ''} isVerify={isVerify} />
								<SingleDetail label="AadhaarNo." value={reviewData?.AadhaarNo || ''} isVerify={isVerify} />
							</>
					}


				</div>
			</div>
		</div>
		{
			Address_proof &&
			<iframe style ={{height:'500px',width:'700px',overflow:'scroll'}} title="PDF Viewer" src={Address_proof?.ImagePath}  />

}
		
		</div>
	);
};
