import React, { useState } from 'react';

export const Hadding = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const accordionData = [
        { title: '1. What should I do if my selfie is rejected for being unclear or out of focus?', content: 'Ensure that your selfie is clear, well-lit, and in focus. Make sure your face is fully visible and not obstructed by anything such as sunglasses, hats, or shadows.' },
        { title: '2. What should I do if my selfie is rejected for being unclear or out of focus?', content: 'No, you should maintain a neutral expression without smiling or making exaggerated facial expressions. This helps ensure your photo matches your ID.' },
        { title: '3. Why cant I use filters or edit my selfie?', content: 'To ensure authenticity, your selfie must be unfiltered and unedited. Filters and edits can alter your appearance, making it difficult to verify your identity.' },
        { title: '4. What should I do if my selfie does not match my Proof of Identity (PoI)?', content: 'Make sure your facial features in the selfie closely match those in your PoI document. If there have been significant changes in your appearance, use a recent photo for both your selfie and PoI.' }
    ];

    return (
        <>
        <p style={{fontWeight:'bold',marginLeft:'10%',marginBottom:'20px'}}>FAQs for Invalid Photo / Selfie</p>
        <div style={styles.accordionContainer}>
           {accordionData.map((item, index) => (
                <div key={index} style={styles.accordionItem}>
                    <div
                        style={styles.accordionHeader}
                        onClick={() => handleToggle(index)}
                    >
                        <h3 style={{fontWeight:'bold'}}>{item.title}</h3>
                    </div>
                    <div
                        style={{
                            ...styles.accordionContent,
                            display: index === activeIndex ? 'block' : 'none'
                        }}
                    >
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

const styles = {
    accordionContainer: {
        width: '80%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
    },
    accordionItem: {
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px'
    },
    accordionHeader: {
        padding: '15px',
        // backgroundColor: '#007BFF',
        // color: '#fff',
        cursor: 'pointer'
    },
    accordionContent: {
        padding: '10px',
        borderTop: '1px solid #ddd',
        backgroundColor: '#fff'
    }
};





