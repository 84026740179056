// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
// import { pngAssets, svgAssets } from '../../../assets/asset';
// import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';

// import { DOMAIN } from '../../../config/config';
// import { changeStep6InputAction, getIncomeProofListAction, uploadBankProofAction, getBrokerageSchemeDetailDataAction, callSendUploadIncomeProofLinkAction, uploadSalarySlipAction } from '../../../redux/actions/kyc/step6.action';
// import { getClientFilesAction } from '../../../redux/actions/kyc/review.action';
// import {
// 	callCheckDocumentIsUploadedAction
// } from '../../../redux/actions/kyc/kyc.action'; 

// export const UploadIncomeProof = ({ isDocumentUploaded, rejectedFieldsData, isRefreshIncomeProof, selectedStepData, kycStep2 }) => {
// 	const dispatch = useDispatch();

// 	const {incomeProof } = useSelector(state => state.kycStep6);
// 	const incomeProofList = incomeProof?.data || [];
// 	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
// 	const validatePanDetails = useSelector(state => state?.kycStep2.validatePanDetails.data || {});
// 	const kycStep6 = useSelector(state => state?.kycStep6 || {});
// 	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);
// 	const reviewFiles2 = useSelector(state => state?.filesData || []);
// 	let user_doc = reviewFiles && reviewFiles?.filter(a => (a.Document === 'Income Proof'));
// 	user_doc = (user_doc?.length) ? user_doc : reviewFiles2 && reviewFiles2?.filter(a => (a?.Document === 'Income Proof'))
// 	const lastItemIndex = user_doc?.length - 1;
// 	const [files, setFiles] = useState([]);
// 	const [isSchemesLoading, setIssSchemesLoading] = useState(true);
// 	const [isUpload3MonthSalarySlip, setIsUpload3MonthSalarySlip] = useState(false);
// 	const [selectedFile, setSelectedFile] = useState();

// 	useEffect(() => {
// 		isRefreshIncomeProof();
// 		incomeProofList?.length < 1 && dispatch(getIncomeProofListAction());
// 	}, []);

// 	const handleImage = (e) => {
// 	console.log(e,'UUU');
// 		if (!e.target.files || e.target.files.length === 0) {
// 			setSelectedFile(undefined);
// 			return;
// 		}
// 		dispatch(uploadBankProofAction({
// 			PanNo: selectedStepData?.PanNo,
// 			Document: 'Income Proof',
// 			DocumentType: 'NA',
// 			step:15,
// 			CreatedBy: userDetail?.created_by,
// 			'DocumentImages[]': e.target.files[0]
// 		}));
// 		isDocumentUploaded.sucess = true;
// 		dispatch(callCheckDocumentIsUploadedAction(
// 			{
// 				"mobile":selectedStepData?.mobile,
// 				"pan":  selectedStepData?.PanNo,
// 				"document_type": "Income Proof"
// 			}
// 		));
// 		dispatch(getClientFilesAction({
// 			PanNo: selectedStepData?.PanNo,
// 			CreatedBy: userDetail?.created_by
// 		}));
// 		setSelectedFile(e.target.files[0]);
// 	};

// 	const handleFileSelect = (event, index) => {
// 		const newFiles = [...files];
// 		newFiles[index] = event.target.files[0];
// 		setFiles(newFiles);
// 		if (index === 0) {
// 			dispatch(uploadBankProofAction({
// 				PanNo:selectedStepData?.PanNo || ' ',
// 				Document: 'Income Proof',
// 				DocumentType: 'salary slip 1',
// 				step:15,
// 				CreatedBy: userDetail?.created_by,
// 				'DocumentImages[]': event.target.files[0]
// 			}));
// 		}
// 		if (index === 1) {
// 			dispatch(uploadBankProofAction({
// 				PanNo: selectedStepData?.PanNo || ' ',
// 				Document: 'Income Proof',
// 				DocumentType: 'salary slip 2',
// 				step:15,
// 				CreatedBy: userDetail?.created_by,
// 				'DocumentImages[]': event.target.files[0]
// 			}));
// 		}
// 		if (index === 2) {
// 			dispatch(uploadBankProofAction({
// 				PanNo: selectedStepData?.PanNo || ' ',
// 				Document: 'Income Proof',
// 				DocumentType: 'salary slip 3',
// 				step:15,
// 				CreatedBy: userDetail?.created_by,
// 				'DocumentImages[]': event.target.files[0]
// 			}));
// 		}
// 		dispatch(callCheckDocumentIsUploadedAction(
// 			{
// 				"mobile": selectedStepData?.mobile,
// 				"pan": selectedStepData?.PanNo,
// 				"document_type": "Income Proof"
// 			}
// 		));
// 		// handleSalarySlips({ type: 'salary slip 1', document: event.target.files[0] || '' })
// 		// handleSalarySlips({ type: 'salary slip 2', document: event.target.files[0] || '' })
// 		// handleSalarySlips({ type: 'salary slip 3', document: event.target.files[0] || '' })

// 	};


// 	const handleSalarySlips = (type, document) => {
// 		dispatch(uploadBankProofAction({
// 			PanNo:selectedStepData?.PanNo,
// 			Document: 'Income Proof',
// 			DocumentType: type,
// 			CreatedBy: userDetail?.created_by,
// 			'DocumentImages[]': document || ''
// 		}));

// 	};


// 	const handleInput = ({ type, value }) => {

// 		dispatch(changeStep6InputAction({ type, value }));
// 		if (type == 'brokerageScheme') {
// 			setIssSchemesLoading(true);
// 			dispatch(getBrokerageSchemeDetailDataAction(
// 				{
// 					"UserId": userDetail.user_id,
// 					"BrokerageType": 'future',
// 					"Segment": "Future and Option"
// 				}
// 				// {
// 				// 	"UserId":userDetail.user_id,
// 				// 	"BrokerageType":value,
// 				// 	"Segment":"Future and Option"
// 				// }
// 			));
// 			setTimeout(() => {
// 				setIssSchemesLoading(false);
// 			}, 3000);
// 		} else if (type == 'incomeProof') {
// 			if (value == 'Latest 3 Month Salary Slip') {
// 				setIsUpload3MonthSalarySlip(true);
// 			} else {
// 				setIsUpload3MonthSalarySlip(false);
// 			}
// 		}
// 	};

// 	// send link
// 	const handleSendLink = () => {
// 		dispatch(callSendUploadIncomeProofLinkAction(
// 			{
// 				"name": validatePanDetails.firstName + " " + validatePanDetails.lastName,
// 				"mobile": selectedStepData?.mobile,
// 				"link": `${DOMAIN}/dkyc-step2`
// 			}
// 		));
// 	}

// 	return (
// 		<>
// 			<div className="w-full mb-8 grid md:grid-cols-12 gap-10">
// 				<div className="w-full col-span-6 ">
// 					<SelectDropdown
// 						label="Upload Income Proof"
// 						placeholder="Please select documents type"
// 						options={incomeProofList}
// 						selectedValue={incomeProof.value}
// 						inputType="incomeProof"
// 						handleSelect={handleInput}
// 						// isDisabled={isDocumentUploaded?.success}
// 						inputMessage={incomeProof.errorText || ''}
// 						inputMessageType={incomeProof.isError && 'error'}
// 					/>

// 				</div>
// <div className="w-full flex items-center justify-between col-span-6 mt-10">
// 					{/* hKIM{JSON.stringify(incomeProof)} */}
// 					<div className='w-full'>
// 						<div className='w-full flex items-start justify-between'>
// 							{
// 								isUpload3MonthSalarySlip ?
// 									<>
// 										<div>
// 											<div className='flex items-center justify-between'>
// 												{
// 													incomeProof?.selectedDocument[0] ?
// 														<button className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813] mb-2`}>
// 															<img
// 																src={svgAssets.kyc.verify}
// 																className="mr-1.5"
// 															/> Uploaded
// 														</button>
// 														:
// 														<button
// 															className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  bg-black relative`}
// 														>
// 															{
// 																incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
// 																	<input
// 																		type="file"
// 																		accept="image/*"
// 																		onChange={(e) => handleFileSelect(e, 0)}
// 																	/>
// 																)
// 															}
// 															<img
// 																src={svgAssets.kyc.uploadWhite}
// 																className="mr-1.5"
// 															/>
// 															<span>Choose file 1</span>
// 														</button>
// 												}
// 												{
// 													files?.[0]?.name &&
// 													<>
// 														<div className="ml-[30px] flex flex-col items-center">

// 															<img
// 																className="rounded-[10px]"
// 																alt="user_image"
// 																src={pngAssets.kyc.doc}
// 															/>
// 															<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 																{files?.[0]?.name}
// 															</span>
// 														</div>
// 													</>
// 												}
// 											</div>
// 											<div className='flex items-center justify-between'>
// 												{
// 													incomeProof?.selectedDocument[1] ?
// 														<button className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813] mb-2`}>
// 															<img
// 																src={svgAssets.kyc.verify}
// 																className="mr-1.5"
// 															/> Uploaded
// 														</button>
// 														:
// 														<button
// 															className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] bg-black  relative mt-2`}
// 														>
// 															{
// 																incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
// 																	<input
// 																		type="file"
// 																		accept="image/*"
// 																		onChange={(e) => handleFileSelect(e, 1)}
// 																	/>
// 																)
// 															}
// 															<img
// 																src={svgAssets.kyc.uploadWhite}
// 																className="mr-1.5"
// 															/>
// 															<span>Choose file 2</span>
// 														</button>
// 												}
// 												{
// 													files?.[1]?.name &&
// 													<>
// 														<div className="ml-[30px] flex flex-col items-center">
// 															<img
// 																className="rounded-[10px]"
// 																alt="user_image"
// 																src={pngAssets.kyc.doc}
// 															/>
// 															<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 																{files?.[1]?.name}
// 															</span>
// 														</div>
// 													</>
// 												}
// 											</div>
// 											<div className='flex items-center justify-between'>
// 												{
// 													incomeProof?.selectedDocument[2] ?
// 														<button className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}>
// 															<img
// 																src={svgAssets.kyc.verify}
// 																className="mr-1.5"
// 															/> Uploaded
// 														</button>
// 														:
// 														<button
// 															className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  bg-black relative mt-2`}
// 														>
// 															{
// 																incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
// 																	<input
// 																		type="file"
// 																		accept="image/*"
// 																		onChange={(e) => handleFileSelect(e, 2)}
// 																	/>
// 																)
// 															}
// 															<img
// 																src={svgAssets.kyc.uploadWhite}
// 																className="mr-1.5"
// 															/>
// 															<span>Choose file 3</span>
// 														</button>
// 												}
// 												{
// 													files?.[2]?.name &&
// 													<>
// 														<div className="ml-[30px] flex flex-col items-center">
// 															<img
// 																className="rounded-[10px]"
// 																alt="user_image"
// 																src={pngAssets.kyc.doc}
// 															/>
// 															<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 																{files?.[2]?.name}
// 															</span>
// 														</div>
// 													</>
// 												}
// 											</div>

// 										</div>
// 									</>
// 									:
// 									<button
// 										className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  relative
// 									 ${(incomeProof?.value?.length < 1 || incomeProof?.value === '') ? 'bg-[#dce1e2] cursor-default' : 'bg-black cursor-pointer'}
// 								`}
// 										disabled={isDocumentUploaded?.success && true}
// 									>
// 										{
// 											incomeProof?.value?.length > 0 && incomeProof?.value !== '' && (
// 												<input
// 													type="file"
// 													accept="image/*"
// 													onChange={(e) => handleImage(e)}
// 												/>
// 											)
// 										}
// 										<img
// 											src={svgAssets.kyc.uploadWhite}
// 											className="mr-1.5"
// 										/>
// 										<span>Choose file</span>
// 									</button>
// 							}
// 							{/* 
// 							{
// 								selectedFile && (<div className="ml-[30px] flex flex-col items-center">
// 									<img
// 										className="rounded-[10px]"
// 										alt="user_image"
// 										src={pngAssets.kyc.doc}
// 									/>
// 									<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 										{selectedFile?.name}
// 									</span>
// 								</div>)
// 							} */}
// 							{ user_doc && user_doc.map((item, index) => {
// 								const isLastItem = index === lastItemIndex;
// 								return (
// 									<>
// 										{isLastItem &&
// 											<div className='relative'>
// 												<div key={index} className='bg-white p-2 w-[150px] h-[80px] flex items-center justify-center'>
// 												<img width='80' src={item?.ImagePath} />
// 											</div>
// 												<a href={item?.ImagePath} target='_blank' className='absolute right-2 top-2'>
// 													<ArrowDownTrayIcon className="h-8 w-8 text-blue-500 " />
// 												</a>
// 											</div>
// 										}
// 									</>
// 								);
// 							})}
// 							{/* <strong className='mx-4'>OR</strong> */}
// 							{/* <button
// 									className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
// 									onClick={handleSendLink}
// 								>
// 									<img
// 										src={svgAssets.kyc.send}
// 										className="mr-1.5"
// 									/>
// 									<span>Send Link</span>
// 								</button> */}
// 							{
// 								isDocumentUploaded?.success ?
// 									<button
// 										className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}
// 									>
// 										<img
// 											src={svgAssets.kyc.verify}
// 											className="mr-1.5"
// 										/>
// 										<span>
// 											Uploaded
// 										</span>
// 									</button>
// 									: ''
// 							}
// 						</div>

// 						<div className='w-full'>
// 							{
// 								kycStep6?.incomeProof?.isSendLink && <>
// 									<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
// 										<div className="grid md:grid-cols-1 gap-1">
// 											<h6 className="font-poppinsMedium font-medium col-span-1  flex w-full text-sm">Upload Income Proof link send successfully,please check email.
// 												<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} width='20px' height='20px' />
// 											</h6>
// 										</div>
// 										<button className='btn btn-primary bg-black text-white text-sm px-3 rounded mt-2 cursor-pointer' onClick={handleSendLink}>Resend Link</button>
// 									</div>
// 								</>
// 							}
// 						</div>
// 					</div>

// 				</div>


// 			</div>
// 		</>
// 	);
// };

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
import { svgAssets } from '../../../assets/asset';
import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';

import { DOMAIN } from '../../../config/config';
import { InputCopyBtnIcon1 } from '../../../components/inputs/RejectInput';
import Logo from '../../../assets/png/pdfImage.jpg'


export const UploadIncomeProof = ({ linktoken, isChildComp, rejectedFieldsData }) => {

	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);

	const { isError, errorText } = useSelector(state => state?.kycStep7 || '');


	let clientLink = DOMAIN + '/dkyc/';
	if (linktoken) {
		clientLink = DOMAIN + '/dkyc/' + linktoken + '?isSelfie=1';
	}

	const [isCopy, setIsCopy] = useState(false);
	const user_image = reviewFiles && reviewFiles?.filter(a => (a?.Document === 'Income Proof' && a?.ReUploadFlag == 1));
	const lastItemIndex = user_image?.length - 1;
	

	const handleCopyLink = () => {
		navigator.clipboard.writeText(clientLink);
		setIsCopy(true);
	}

	return (
		<div className="w-full flex flex-col rejected-otem-list">
			<div className={`${!isChildComp}`}>
				<div className="mb-0 grid md:grid-cols-2 gap-x-10 items-center mt-0">
					<div className="flex flex-col ">
						<div className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap mb-0`} style={{ color: '#FF4343' }}>
							{rejectedFieldsData !== null ? rejectedFieldsData : 'Income proof is not valid'}
						</div>
						<InputCopyBtnIcon1
							// icon={svgAssets.kyc.income}
							// label="Income Proof Link"
							// subLabel="Share below link with client"
							isImportant={true}
							placeholder={clientLink}
							btnWidth="67px"
							btnIcon={ClipboardIcon}
							inputMessage={errorText}
							inputMessageType={isError && 'error'}
							handleSubmit={handleCopyLink}
							defaultValue={clientLink}
							isDisable={true}
							isCopy={isCopy}
						/>

					</div>
					<div className="flex flex-col relative">
						<div className='hover:text-[#5466fc] relative flex items-center justify-end ' >
							{user_image && user_image.map((item, index) => {
								const isLastItem = index === lastItemIndex;
								return (
									<>
										{isLastItem &&
										<>
										<p>Re-uploaded Document By the client</p>
											<div key={index} className='bg-white p-1 ml-2 flex items-center justify-center'>
												<a href={item?.ImagePath} target='_blank'>
													<img width='80' src={item?.ImageType == 'application\/pdf' ? Logo : item.ImagePath} />
												</a>
											</div>
											</>}
									</>
								);
							})}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

