import { all, call, put, takeEvery } from 'redux-saga/effects';
import { postApi } from '../services/common';
import { getEsignReport, setEsignReportAction } from '../redux/actions/e-sign-report.action';


function* eSignReportSaga({ payload }) {
    try {
        const data = yield call(postApi, payload, '/GetESignInfo');
        yield put(setEsignReportAction(data?.data?.data || []));
    } catch (err) {
        console.log(err);
    };
};


function* e_SignReportSaga() {
    yield all([
        takeEvery(getEsignReport, eSignReportSaga),
    ]);
}

export default e_SignReportSaga;
