import { OutsideClickListner } from "../../hooks/OutsideClickListner";
import { useEffect, useRef, useState } from "react";
import { messageTypeParameter } from "../../utils/input";

const dummyOptions = [
  { label: "90", value: 0, id: 0 },
  { label: "option2", value: 1, id: 1 },
  { label: "option3", value: 2, id: 2 },
  { label: "option4", value: 3, id: 3 },
];

export const SelectDropdown = ({
  icon,
  label,
  isImportant,
  height,
  autoFocus,
  isDisabled,
  placeholder,
  options,
  selectedValue,
  inputType,
  border,
  handleSelect,
  inputMessage,
  inputMessageType,
  listHeight,
  handleSaveVal,
  error
}) => {
  const wrapperRef = useRef(null);
  const initOption = options || dummyOptions;
  // eslint-disable-next-line eqeqeq
  const mainValue =
    options &&
    selectedValue &&
    options.find((option) => option.value == selectedValue);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(mainValue?.label);
  

  const handleOutsideClick = () => {
    setIsSelected(false);
  };

  const handleOption = ({ label, value, id }) => {
    handleSaveVal && handleSaveVal(id);
    setSelectedOption(label);
    setIsSelected(false);
    handleSelect && handleSelect({ type: inputType, value });
  };

  useEffect(() => {
    setSelectedOption(mainValue?.label);
  }, [options?.length]);

  useEffect(() => {
    setSelectedOption(mainValue?.label);
  }, [selectedValue]);

  OutsideClickListner({ ref: wrapperRef, closeBox: handleOutsideClick });

  return (
    <div className="w-full flex flex-col">
      {label && (
        <label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">
          {icon && (
            <img
              className="max-h-[22px] mr-1.5 object-contain"
              alt="input_icon"
              src={icon}
            />
          )}
          <span className="mr-2">{label}</span>
          {isImportant && <span className="text-[#EA0000]">*</span>}
        </label>
      )}
      <div
        ref={wrapperRef}
        className={`w-full h-full rounded-[10px]
			  ${
          isSelected &&
          "bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10"
        }
        ${height || "h-[47px]"}
		  `}
      >
        <div
          disabled={isDisabled}
          className={`px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]
						${border || ""}
						${height || "h-[47px]"}
					`}
          onClick={() => setIsSelected(!isSelected)}
        >
          <input
            className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
            type="text"
            placeholder={placeholder}
            value={selectedValue ? selectedOption : ""}
            disabled={true}
            autoFocus={true}
          />
          <svg
            className={`fill-[#808080] h-6 w-6 transform duration-150 ease-in-out
						${isSelected && "rotate-180"}
					`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
        {inputMessage && inputMessageType && (
          <div
            style={{
              color: messageTypeParameter[inputMessageType].color,
            }}
            className="my-2.5 flex items-center text-sm font-poppinsRegular "
          >
            <img
              className="mr-1.5"
              alt="input_message_type_icon"
              src={messageTypeParameter[inputMessageType].icon}
            />
            <span className="inline-flex items-center">{inputMessage}</span>
          </div>
        )}
        {
      ( label == 'Annual Income' && error) && 
           <div
            style={{
              color: 'red',
            }}
            className="my-2.5 flex items-center text-sm font-poppinsRegular "
          >
         
            <span className="inline-flex items-center">{error}</span>
          </div>
        }
        <div
          className={`w-full transition-all duration-700 top-[49px] rounded-[10px] border-b-lg font-poppinsRegular bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] overflow-scroll
					${isSelected ? "absolute z-50" : "hidden"}
					${listHeight || "h-[200px]"}
				`}
        >
          <ul className={` px-1.5 py-1' `}>
            {initOption?.length > 0
              ? initOption.map((option, index) => (
                  <li
                    key={index}
                    className="px-3 py-2 my-0.5 rounded-md hover:bg-[#F6F8F9] cursor-pointer"
                    style={{
                      // eslint-disable-next-line eqeqeq
                      backgroundColor:
                        selectedOption ==
                          (option?.label ? option?.label : option?.name) &&
                        "#e7e7e7",
                    }}
                    onClick={() =>
                      handleOption({
                        label: option?.label ? option?.label : option?.name,
                        value: option?.value ? option?.value : option?.name,
                        id: option?.id ? option?.id : option?.id,
                      })
                    }
                  >
                    {option?.label ? option?.label : option?.name}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};
