import { all, call, put, takeEvery } from 'redux-saga/effects';
import { deleteClientAction, getApplication, getApplicationStatus, getClientFile, getClientInfo, getClientRejectReason, isClientDelete, saveClientRejectReason, saveVerifiedApplication, saveVerifierLog, setApplicationAction, setApplicationStatusAction, setClientFileAction, setClientInfoAction, setClientRejectReasonAction } from '../redux/actions/application.action';
import { postApi, postApiWithoutLoader } from '../services/common';


function* getApplicationStatusSaga({ payload }) {
	try {
		
		const data = yield call(postApi, payload, '/GetTotalApplicationStatus');
		yield put(setApplicationStatusAction({ data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* getApplicationSaga({ applicationType, payload }) {
	const url = applicationType == 'verifiedApplication' ? '/GetVerifiedApplicationDetails' : applicationType == 'pendingApplication' ? '/GetPendingApplicationDetails' : applicationType == 'rejectedApplication' ? '/GetRejectedApplicationDetails' :applicationType == 'resubmittedApplication'?'/GetResubmittedApplicationDetails' :applicationType == 'allApplication'?'/GetAllApplicationDetailsData':'/GetIncompleteApplicationDetails'

	try {
		const data = yield call(postApi, payload, url);
		yield put(setApplicationAction({ applicationType, data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* saveVerifierLogSaga({ payload }) {
	try {
		yield call(postApi, payload, '/SaveVerifierClientLog');
	} catch (err) {
		console.log(err);
	};
};

function* getClientInfoSaga({ payload }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/GetCltFullInfo');
		yield put(setClientInfoAction({ data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* getClientFileSaga({ payload }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/GetClientFiles');
		yield put(setClientFileAction({ data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* getClientRejectReasonSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/GetClientRejectedFeildsNew');
		yield put(setClientRejectReasonAction({ data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* saveClientRejectReasonSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/SaveClientRejectedFeilds');
		console.log(data);
	} catch (err) {
		console.log(err);
	};
};

function* saveVerifiedApplicationSaga({ payload }) {
	try {
		const data = yield call(postApi, payload, '/SaveFinalVerify');
		console.log(data);
	} catch (err) {
		console.log(err);
	};
};
function* deleteCLientSaga({ payload }) {
	console.log("deleteCLientSaga", payload)
	try {
		const data = yield call(postApi, payload, '/deleteClient ');
		delete payload.PanNo
		const data1 = yield call(postApi, payload, '/delete-kyc-journey');
		console.log(data);
	} catch (err) {
		console.log(err);
	};
};


function* applicationSaga() {
	yield all([
		takeEvery(getApplicationStatus, getApplicationStatusSaga),
		takeEvery(getApplication, getApplicationSaga),
		takeEvery(saveVerifierLog, saveVerifierLogSaga),
		takeEvery(getClientInfo, getClientInfoSaga), 
		takeEvery(getClientFile, getClientFileSaga),
		takeEvery(getClientRejectReason, getClientRejectReasonSaga),
		takeEvery(saveClientRejectReason, saveClientRejectReasonSaga),
		takeEvery(saveVerifiedApplication, saveVerifiedApplicationSaga),
		takeEvery(isClientDelete, deleteCLientSaga),
	]);
}

export default applicationSaga;
