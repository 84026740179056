import { json } from "react-router-dom";
import { useState } from 'react';
import { svgAssets } from '../../../assets/asset';
import { SubmitBtn } from '../../../components/buttons/SubmitBtn';
import { useDispatch, useSelector } from 'react-redux';
import { MyModal } from '../../../components/modal/Modal';
import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
import { callVerifyRejectionAction } from '../../../redux/actions/kyc/verify.action';
import { toast } from "react-toastify";
import { getClientRejectReasonAction } from "../../../redux/actions/application.action";
import refetch from "../../../assets/svg/refetch-icon.svg"

export default function ClientProfile({ reviewFilesData, isVerify, profileStatus, signStatus, reviewData, callback, selectedData }) {
	const user_doc = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a.Document == 'Client Photo' || a.Document == 'Client photo'));
	const lastItemIndex = user_doc?.length - 1;
	const user_doc2 = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a.Document === 'Client Signature'));
	const lastItemIndex2 = user_doc2?.length - 1;
	const [rejectModal, setRejectModal] = useState(false);
	const [verifyModal, setVerifyModal] = useState(false);
	const [imageOpen, setImageOpen] = useState(false);
	const [imageOpen1, setImageOpen1] = useState(false);
	const [isRejectionList, setIsRejectionList] = useState([]);
	const [rejectReason, setRejectReason] = useState(0);
	const [modalTitle, setModalTitle] = useState('');
	const clientId = reviewData?.ClientId;
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const rejectReasonsData = useSelector(state => state?.application.rejectReason || []);
	const new_data = Object.values(rejectReasonsData)
	const filteredData = new_data?.filter(entry => (entry?.reason == 'Captured selfie blur') || (entry?.reason == 'Live selfie required') || (entry?.reason == 'Incorrect selfie captured'));
	const signatureData = new_data?.filter(entry => (entry?.reason == 'Incorrect Signature uploaded') || (entry?.reason == 'Signature is not valid') || (entry?.reason == 'SIGN NOT VISIBLE') || (entry?.reason == 'Client sign mismatch as per existing DP'));

	const clientSelfieRejectionOption = [
		{ label: 'Captured selfie blur', value: 'Captured selfie blur' },
		{ label: 'Live selfie required', value: 'Live selfie required' },
		{ label: 'Incorrect selfie captured', value: 'Incorrect selfie captured' },
		{ label: 'Need To Submit only Client Photo', value: 'Need To Submit only Client Photo' },
		{ label: 'Image not valid', value: 'Image not valid' },
		{ label: 'Latest Photo Required', value: 'Latest Photo Required' },
		{ label: 'Two Image Not Allowed In Client Selfie', value: 'Two Image Not Allowed In Client Selfie' },
	];

	const signatureRejectionOption = [
		{ label: 'Incorrect Signature uploaded', value: 'Incorrect Signature uploaded' },
		{ label: 'Signature is not valid', value: 'Signature is not valid' },
		{ label: 'SIGN NOT VISIBLE', value: 'SIGN NOT VISIBLE' },
		{ label: 'Client sign mismatch as per existing DP', value: 'Client sign mismatch as per existing DP' },
		{ label: 'cut signature not Accepted', value: 'cut signature not Accepted' },
	];

	const handleModal = ({ isSubmit, label }) => {
		if (isSubmit == true && rejectReason == '') {
			toast("please select reason")
		}
		else {
			setRejectModal(false);
			setVerifyModal(false);
			setModalTitle(label)
			if (isSubmit) {
				let index;
				if (label == 'Client Photo') {
					index = 'photo';
					const photoIndex = reviewFilesData.findIndex((item) => item.Document === "Client Photo" || item.Document === "Client photo");
					runApi(0, label, photoIndex)
				} else {
					index = 'sign';
					const signIndex = reviewFilesData.findIndex((item) => item.Document === "Client Signature");
					runApi(0, label, signIndex)
				}

				callback('reject', index)
			}

		}
	};
	const handleInput = ({ type, value }) => {

		if (type == 'rejectReason') {
			setRejectReason(value)
		}
	};
	const runApi = (isVerify, label, index) => {
		dispatch(callVerifyRejectionAction({
			"ActualField": label,
			"PageNo": 0,
			"FieldName": label.replace(/\s/g, ''),
			"FieldValue": reviewFilesData[index]?.ImagePath,
			"IsVerify": isVerify,
			"VerifyBy": userDetail.user_id,
			"ClientId": clientId,
			"DocId": reviewFilesData[index]?.DocId,
			"Reson": rejectReason || ''
		}, callbackcontinoue));
		// setVerifyModal(false);
	}

	const callbackcontinoue = (resp) => {
		if (resp == 'success') {
			toast('Record Saved successfully')
			dispatch(getClientRejectReasonAction({
				ClientId: selectedData.ClientId,
				PanNo: selectedData.PanNo
			}));
			setRejectModal(false);
			setVerifyModal(false);
		}
		else {
			toast(resp.message ? resp.message : '"something went wrong')
		}
	}
	const handleSubmitVerifyModal = (label) => {
		let index;

		if (label == 'Client Photo') {
			index = 'photo';
			const photoIndex = reviewFilesData.findIndex((item) => item.Document === "Client Photo" || item.Document === "Client photo");
			runApi(1, label, photoIndex)
		} else {
			index = 'sign';
			const signIndex = reviewFilesData.findIndex((item) => item.Document === "Client Signature");
			runApi(1, label, signIndex)
		}
		// if (label === 'Client Photo') {
		// 	index = 'photo';
		// 	runApi(1, label, 1)
		// } else {
		// 	index = 'sign';
		// 	runApi(1, label, 0)
		// }
		callback('done', index)


	};

	const handleValidation = (actiontype, type) => {
		setModalTitle(type)
		if (actiontype == 'reject') {
			setRejectReason(0)
			setRejectModal(true);
			if (type === 'Client Photo') {
				setIsRejectionList(clientSelfieRejectionOption);
			} else {
				setIsRejectionList(signatureRejectionOption);
			}
		}
		else {
			setVerifyModal(true);

		}


	};
	return (
		<div className="mb-[15px] max-h-[1000px]  md:col-span-4">

			<div className="px-[20px] py-[20px] mb-[20px] flex items-center justify-center rounded-[10px] shadow-[0px_1px_12px_rgba(185,185,185,0.25)]">
				{/* <img className="object-contain h-[200px]" alt="user_image" src={user_image && user_image[0]?.ImagePath}></img> */}
				{user_doc && user_doc?.map((item, index) => {
					const isLastItem = index + 1 === lastItemIndex;
					return (
						<>

							{isLastItem || user_doc.length == 1 ?
								<div style={{ cursor: 'pointer' }} onClick={() => { setImageOpen(true) }} key={index}>

									<img src={item?.ImagePath} />
								</div> : ''}

						</>
					);
				})
				}
				{
					reviewData?.FinalVerify == 1 ?
						null
						:
						isVerify && (
							<div className='ml-5'>
								{
									profileStatus == "reject"
										? (
											<>
												<img
													alt="wrong_field"
													className="mr-5"
													src={
														svgAssets.review.wrongTick
													}
												/>
												<img
													alt="wrong_field"
													className="cursor-pointer mt-5"
													src={svgAssets.review.undo}
												// onClick={() => setIsValid({ ...isValid, triggered: false })}
												/>
											</>
										)
										:
										profileStatus == "done"
											? (
												<>

													<img
														alt="wrong_field"
														className="mr-5"
														src={svgAssets.review.correctTick}
													/>
												</>
											)
											:
											(
												<>
													{
														filteredData?.length > 0 &&
														<>

															<div onClick={() => handleValidation('approve', 'Client Photo')}>
																<button style={{ background: 'red' }} className={'d-inline-block text-white px-2 py-1 mt-4 rounded refetch-button'}><img src={refetch} className="mr-1" />Undo Rejection</button>
															</div>
															{/* <img
													alt="right_field"
													className="cursor-pointer mt-5"
													style={{height:30,width:30}}
													src={svgAssets.verifyApplication.rightField}
													onClick={() => handleValidation('approve', 'Client Photo')}
												/> */}
														</>
													}

													{
														filteredData?.length == 0 ?
															<img
																alt="wrong_field"
																className="mr-5 cursor-pointer"
																src={svgAssets.verifyApplication.wrongField}
																onClick={() => handleValidation('reject', 'Client Photo')}
															/>
															: null}

												</>
											)
								}
							</div>
						)
				}
			</div>
			<div className="py-4 flex items-center justify-center rounded-[10px] shadow-[0px_1px_12px_rgba(185,185,185,0.25)]">
				{user_doc2 && user_doc2?.map((item, index) => {
					const isLastItem2 = index + 1 === lastItemIndex2;
					return (
						<>

							{isLastItem2 || user_doc2.length == 1 ?
								<div key={index} style={{ cursor: 'pointer' }} onClick={() => { setImageOpen1(true) }}>
									<img src={item?.ImagePath} />
								</div> : ''}

						</>
					);
				})}

				{
					reviewData?.FinalVerify == 1 ?
						null
						:
						isVerify && (
							<div className='ml-5'>
								{
									signStatus == "reject"
										? (
											<>
												<img
													alt="wrong_field"
													className="mr-5"
													src={
														svgAssets.review.wrongTick
													}
												/>
												<img
													alt="wrong_field"
													className="cursor-pointer mt-5"
													src={svgAssets.review.undo}
												// onClick={() => setIsValid({ ...isValid, triggered: false })}
												/>
											</>
										)
										:
										signStatus == "done"
											? (
												<>

													<img
														alt="wrong_field"
														className="mr-5"
														src={svgAssets.review.correctTick}
													/>
												</>
											)
											:
											(
												<>
													{
														signatureData?.length > 0 && (
															<div onClick={() => handleValidation('approve', 'Client Signature')}>
																<button style={{ background: 'red', marginRight: '10px' }} className={'d-inline-block text-white px-2 py-1 mt-4 rounded refetch-button'}><img src={refetch} className="mr-1" />Undo Rejection</button>
															</div>
															// 		<img
															// 	alt="right_field"
															// 	className="cursor-pointer mt-5 mr-5"
															// 	style={{height:30,width:30}}
															// 	src={svgAssets.verifyApplication.rightField}
															// 	onClick={() => handleValidation('approve', 'Client Signature')}
															// />
														)
													}
													{
														signatureData?.length == 0 ?
															<img
																alt="wrong_field"
																className="mr-5  cursor-pointer"
																src={svgAssets.verifyApplication.wrongField}
																onClick={() => handleValidation('reject', 'Client Signature')}
															/>
															: null}

												</>
											)
								}
							</div>
						)
				}
				<MyModal
					isModalOpen={rejectModal}
					handleModal={() => handleModal({ isSubmit: false, label: modalTitle })}
					title=""
					centered={false}
					width={590}
				>
					<div className="">
						<h6 className="mb-4 text-lg font-medium">Tell us rejection reason</h6>
						{/* <SelectRejectionDropdown placeholder="Enter rejection reason" rejectionList={isRejectionList} /> */}
						<SelectDropdown
							label=""
							placeholder="Please select reason"
							options={isRejectionList}
							selectedValue={rejectReason}
							inputType="rejectReason"
							handleSelect={handleInput}
							inputMessage={''}
							inputMessageType={''}
						/>
						<div className="flex items-center justify-center mt-12">
							<SubmitBtn handleSubmitBtn={() => handleModal({ isSubmit: true, label: modalTitle })} />
						</div>
					</div>
				</MyModal >
				<MyModal
					isModalOpen={imageOpen}
					handleModal={() => { setImageOpen(false) }}
					title=""
					centered={false}
					width={550}
					height={550}

				>
					{user_doc && user_doc?.map((item, index) => {
						const isLastItem = index + 1 === lastItemIndex;
						return (
							<>

								{isLastItem || user_doc.length == 1 ?
									<div key={index}>

										<img style={{ height: 500, width: 500 }} src={item?.ImagePath} />
									</div> : ''}

							</>
						);
					})
					}
				</MyModal >
				<MyModal
					isModalOpen={imageOpen1}
					handleModal={() => { setImageOpen1(false) }}
					title=""
					centered={false}
					width={550}
					height={550}

				>
					{user_doc2 && user_doc2?.map((item, index) => {
						const isLastItem2 = index + 1 === lastItemIndex2;
						return (
							<>

								{isLastItem2 || user_doc2.length == 1 ?
									<div key={index}  >
										<img src={item?.ImagePath} />
									</div> : ''}

							</>
						);
					})}
				</MyModal >
				<MyModal
					isModalOpen={verifyModal}
					handleModal={handleModal}
					title=""
					centered={false}
					width={590}
				>
					<div className="">

						<h3 className='text-2xl'>Undo Rejection</h3>
						<div className="flex items-center justify-center mt-12">
							<botton
								className='bg-black px-8 py-2 text-white rounded cursor-pointer'
								onClick={() => { setVerifyModal(false); }}
							>
								Cancel
							</botton>
							<botton
								className='ml-3 bg-black px-8 py-2 text-white rounded cursor-pointer'
								onClick={() => handleSubmitVerifyModal(modalTitle)}
							>
								Ok
							</botton>

						</div>
					</div>
				</MyModal >
			</div>
		</div>
	);
}
