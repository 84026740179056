import '../modal.css';
import { Modal } from 'antd';
import {  postApi } from '../../../services/common';
import { toast } from 'react-toastify';
import { getVerifyApplicationAction, setVerifyApplication2Action, setVerifyApplicationAction } from '../../../redux/actions/kyc/verify.action';
import { useDispatch } from 'react-redux';

export const RemoveSegmentModal = ({ isModalOpen, modalToggle, postData,cl_id ,data}) => {
	const dispatch = useDispatch();

    const RemoveSegmentFunction = () => {
        postApi(postData, '/removeSegmentDirect')
            .then(response => {
               if (response?.data?.success) {
                window.location.reload();
                
// setTimeout(() => {
//     dispatch(getVerifyApplicationAction(
//         {
//             mobile:data?.MobileNumber,
//             ClientId:cl_id?.ClientId,
//         }
//     ));
// }, 1000);
              
toast(response?.data?.message)
                    modalToggle(false);
                } else {
                    toast(response?.data?.message ? response?.data?.message : 'something went wrong')
                }

            })
            .catch(error => {
                console.error('Error:', error);
                // Handle errors here
            });
    }
    return (
        <div>
            <Modal
                title=""
                className="w-full p-[33px]"
                width={390}
                open={isModalOpen}
                centered={true}
                onCancel={() => modalToggle(false)}
                footer={null}
            >
                <div className="mt-2 mb-1.5 flex flex-col items-center">

                    <div className="mb-[5px] text-xl font-medium font-poppinsMedium">
                        {'Do You Want to Remove Segment'}
                    </div>
                    <button className={'bg-[#fe9190] text-white px-5 py-2 w-full mt-5 rounded text-lg'} onClick={RemoveSegmentFunction}>
                        YES
                    </button>
                    <button className={'bg-[#fe9190] text-white px-5 py-2 w-full mt-5 rounded text-lg'} onClick={() => modalToggle(false)}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
};