export const changeStep5Input = 'CHANGE_STEP5_INPUT';
export const changeStep5Error = 'CHANGE_STEP5_ERROR';
export const fetchBankAccount = 'FETCH_BANK_ACCOUNT';
export const fetchSearchBankAccount = 'FETCH_SEARCH_BANK_ACCOUNT';
export const getBanksNameList = 'GET_BANKS_NAME_LIST';
export const setBanksNameList = 'SET_BANKS_NAME_LIST';
export const getBranchNameList = 'GET_BRANCH_NAME_LIST';
export const setBranchNameList = 'SET_BRANCH_NAME_LIST';
export const verifyBankAccount = 'VERIFY_BANK_ACCOUNT';
export const setBankInfo = 'SET_BANK_INFO';
export const setClearBankInfo = 'SET_CLEAR_BANK_INFO';
export const setSearchBankInfo = 'SET_SEARCH_BANK_INFO';
export const saveBankAllInfo = 'SAVE_BANK_ALL_INFO';
export const saveIfscCode = 'SAVE_IFSC_CODE';
export const callSendUploadBankProofLink = 'CALL_SEND_UPLOAD_BANK_PROOF_LINK';
export const sendUploadBankProofLink = 'SEND_UPLOAD_BANK_PROOF_LINK';
export const setIsVerifyBankDetails = 'SET_IS_VERIFY_BANK_DETAILS';
export const setIfscCode = 'SET_IFSC_CODE';
export const getIfscCode = 'GET_IFSC_CODE';


export const changeStep5InputAction = (payload) => ({
	type: changeStep5Input,
	payload
});

export const changeStep5ErrorAction = (payload) => ({
	type: changeStep5Error,
	payload
});

export const fetchBankAccountAction = (payload, callback) => ({
	type: fetchBankAccount,
	payload,
	callback
});

export const setBankInfoAction = (payload) => ({
	type: setBankInfo,
	payload
});

export const setClearBankInfoAction = (payload) => ({
	type: setClearBankInfo,
	payload
});

export const setSearchBankAction = (payload) => ({
	type: setSearchBankInfo,
	payload
});

export const fetchSearchBankAccountAction = (payload, callback) => ({
	type: fetchSearchBankAccount,
	payload,
	callback
});

export const verifyBankAccountAction = (payload, callback) => ({
	type: verifyBankAccount,
	payload,
	callback
});

export const saveBankAllInfoAction = (payload , callback) => ({
	type: saveBankAllInfo,
	payload,
	callback
});

export const setIfscCodeAction = (payload) => ({
	type: saveIfscCode,
	payload
});

export const setIsVerifyBankDetailsAction = (payload) => ({
	type: setIsVerifyBankDetails,
	payload
});

export const callSendUploadBankProofLinkAction = (payload) => {
	return(
		{
			type: callSendUploadBankProofLink,
			payload
		}
	)
};

export const sendSendUploadBankProofLinkAction = (payload) => {
	return(
		{
			type: sendUploadBankProofLink,
			payload
		}
	)
};


// new ifsc search code

export const getBanksNameListAction = (payload, callback) => ({
	type: getBanksNameList,
	payload,
	callback
});

export const setBanksNameListAction = (payload) => ({
	type: setBanksNameList,
	payload
});

export const getBranchNameListAction = (payload) => ({
	type: getBranchNameList,
	payload,
});

export const setBranchNameListAction = (payload) => ({
	type: setBranchNameList,
	payload
});
export const getIfscCodeAction = (payload) => ({
	type: getIfscCode,
	payload
});
export const saveIfscCodeAction = (payload) => ({
	type: setIfscCode,
	payload
});

