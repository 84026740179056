import { ClipboardIcon, DocumentCheckIcon } from '@heroicons/react/24/solid'
import { messageTypeParameter } from '../../utils/input';

export const InputCopyBtnIcon1 = ({ icon, label, subLabel, isImportant, height, isDisable, autoFocus, placeholder, btnWidth, inputWidth, btnIcon, btnText, inputMessage, inputMessageType, value, type, inputType, handleInputChange, handleSubmit, handlePhoneNumberKeyPress, defaultValue, isCopy}) => {
	return (
		<div className="w-full flex flex-col">
			<label className="flex items-center leading-6 font-medium font-poppinsMedium">
				{
					icon && (<img
						className="max-h-[22px] mr-1.5 object-contain"
						alt="input_icon"
						src={icon}
					/>)
				}
				<span className="mr-1">{label}</span>
				{/* {isImportant && <span className="text-[#EA0000]">*</span>} */}
			</label>
			{
				subLabel && (<span className="text-xs text-[#9F9F9F] font-poppinsRegular">{subLabel}</span>)
			}
			
            {/* <div style={{height:30}}>

            </div> */}
			{
				inputMessage && inputMessageType && (
					<div
						style={{
							color: messageTypeParameter[inputMessageType].color
						}}
						className="mt-2.5 flex items-center text-sm font-poppinsRegular"
					>
						<ClipboardIcon className="h-6 w-6 text-white-500  mr-2"/>
						<img className="mr-1.5" alt="input_message_type_icon" src={messageTypeParameter[inputMessageType].icon} />
						<span className="inline-flex items-center">{inputMessage}</span>
					</div>
				)
			}
		</div>
	);
};
