import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { BaseLogin } from '../../../components/d-kyc/d-kyc';
import { svgAssets } from '../../../assets/asset';
import Digital from '../../../assets/d-kyc assets/digital 1.png'
import Camera from '../../../assets/d-kyc assets/camera.png'
import Upload from '../../../assets/d-kyc assets/upload.svg'
import FIle1 from '../../../assets/d-kyc assets/file1.png'
import FIle2 from '../../../assets/d-kyc assets/file2.png'
import hand from '../../../assets/png/Hand.png'

import { clearLoginInputAction } from '../../../redux/actions/auth.action';
import { getPanNumberAction } from '../../../redux/actions/dkyc/dkyc.action';
import { getClientFilesAction, sendEquityEsignAction } from '../../../redux/actions/kyc/review.action';
import { BankProof } from '../step1/bankProof';
import { IncomeProof } from '../step2/incomeProof';
import TakeSelfie from '../step3/selfie';
import DrawSignature from '../step4/signature';
import { postApi, postApiWithoutLoader, postFormApiCo } from '../../../services/common';
import '../dkyc/submitdoc.css';
import { UploadPan } from '../step5/uploadPan';
import { UploadAddress } from '../step6/uploadAddress';
import { getClientRejectReasonAction, setClientRejectReasonAction } from '../../../redux/actions/application.action';
import complete from '../../../assets/png/kycSuccess.png'
import { callUpdateSegmentInfoAction, finalSubmitAction } from '../../../redux/actions/kyc/step8.action';
import { ToastContainer, toast } from 'react-toastify';

const SubmitDocuments = () => {
	const { slug } = useParams();
	const token_slug = slug;
	const dispatch = useDispatch();
	const [step, setStep] = useState(0);
	const [krnJourney, setKraJourney] = useState([]);
	const [kra, setKra] = useState(null);
	const [TokenData, setTokenData] = useState([]);
	const [isIncomeProof, setisIncomeProof] = useState(false);
	const [isSelfie, setisSelfie] = useState(false);
	const [isSign, setisSign] = useState(false);
	const [bankarify, setBank] = useState(false);
	const [panUpload, setPan] = useState(false);
	const [address, setAddress] = useState(false);
	const [stage, setStage] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [esign, setEsign] = useState(false);
	const [deseble, setDeseble] = useState(false);
	const [loading, setLoading] = useState(true);
	const [mobile, setMobile] = useState({})
	const [bankMicr, setBankDetails] = useState({})
	const [getsaveKraData, setKraData] = useState({})
	const rejectReasonsData = useSelector(state => state?.application?.rejectReason || []);
	const [stopKra, setStopKra] = useState(false)

	if (rejectReasonsData?.length > 0) {
		const filteredRecords = rejectReasonsData?.filter(record => record?.is_verify === "0");
		if (Array.isArray(filteredRecords)) {
			var keywords = ['Last 3 Months Salary', 'Income', 'ITR', 'Latest', 'Investment Proof', 'Statement', 'Audited Balance'];
			var bankKeword = ['penny', "Micr", 'Bank', 'Personalized Cheque', 'LDCode', 'Passbook', 'Cancelled Cheque', 'Other'];
			var PanKeword = ['NameAsOnPAN', 'E-SignerName', 'BirthDate', "Name", 'PANCard', 'pan'];
			var ImageKeword = ['ClientPhoto', 'photo', 'Client Photo'];
			var SignatureKeword = ['ClientSignature', 'signature', 'Signature'];
			var AddressKeword = ['Address', 'Aadhaar', 'CRAddr', 'CRPinCode', 'Driving', 'PRPinCode'];
			var bank = filteredRecords.find((item) => {
				return bankKeword.some(bankKeword => item?.verify_key?.includes(bankKeword));
			});
			var PanCheck = filteredRecords.find((item) => {
				return PanKeword.some(PanKeword => item?.verify_key?.includes(PanKeword));
			});
			var IncomeCheck = filteredRecords.find((item) => {
				return keywords.some(keyword => item?.verify_key?.includes(keyword));
			});
			var ImageCheck = filteredRecords.find((item) => {
				return ImageKeword.some(ImageKeword => item?.verify_key?.includes(ImageKeword));
			});
			var signatureCheck = filteredRecords.find((item) => {
				return SignatureKeword.some(SignatureKeword => item?.verify_key?.includes(SignatureKeword));
			});
			var AddressCheck = filteredRecords.find((item) => {
				return AddressKeword.some(AddressKeword => item?.verify_key?.includes(AddressKeword));
			});
		} else {
			console.error("rejectReasonsData is not an array");
		}
	}


	useEffect(() => {
		let intervalId;
		const fetchData1 = async () => {
			try {
				const payload = {
					mobile: mobile?.mobile,
					esign: 1
				}
				const response = await postApiWithoutLoader(payload, '/getKycJourney');
				if (response?.data?.success == true) {

					setLoading(false)
					const json_data = JSON.parse(response?.data?.data[0]?.json_data);
					setKraJourney(response?.data?.data[0])
					if (response?.data?.data[0].cmp_is_completed == 1) {
						setStage(true)
					}
					if (response?.data?.data[0].cl_doc_confirmation == 1) {
						setConfirm(true)
					}
					if (response?.data?.data[0].cl_doc_confirmation == null) {
						setConfirm(false)
					}
					if (response?.data?.data[0].cmp_is_esign == 1) {
						setEsign(true)
						const clientpayload = {
							ClientId: mobile?.client_id,
							PanNo: mobile?.pan
						}
						const response = await postApiWithoutLoader(clientpayload, '/rejectionVerifyStatus');
					}
					if (json_data) {
						setKra(json_data);
					} else {
						console.log('error')
					}
				}
				else {
					// toast('something went wrong')
				}
			}
			catch (error) {
				toast('something went wrong')

			}
		};
		if (stopKra == true) {
			intervalId = setInterval(fetchData1, 10000); // Call API every 10 seconds
		}

		return () => {
			clearInterval(intervalId); // Cleanup on unmount or condition change
		};
	}, [stopKra]);

	useEffect(() => {
		dispatch(setClientRejectReasonAction({}));
		dispatch(clearLoginInputAction());
		if (token_slug) {
			dispatch(getPanNumberAction({
				token: token_slug
			}, handleCallback));
		}

	}, []);

	// useEffect(() => {
	// 	if (mobile.mobile) {
	// 		fetchData();

	// 	}


	// }, [mobile.mobile]);

	useEffect(() => {
		if (mobile?.client_id) {
			bankDetails()
		}
	}, [mobile?.client_id])

	const bankDetails = async () => {
		const payload = {
			ClientId: mobile?.client_id,

		}
		const response = await postApiWithoutLoader(payload, '/GetCltFullInfo');
		if (response?.data?.success) {
			setBankDetails(response?.data?.data)

		}
		else {
			// toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
		}
	}


	const fetchData = async (mobile) => {
		try {
			const payload = {
				mobile: mobile,
				esign: 1
			}
			const response = await postApiWithoutLoader(payload, '/getKycJourney');
			if (response?.data?.success == true) {
				setStopKra(true)
				setLoading(false)
				const json_data = JSON.parse(response?.data?.data[0]?.json_data);
				setKraJourney(response?.data?.data[0])
				if (response?.data?.data[0].cmp_is_completed == 1) {
					setStage(true)
				}
				if (response?.data?.data[0].cl_doc_confirmation == 1) {
					setConfirm(true)
				}
				if (response?.data?.data[0].cl_doc_confirmation == null) {
					setConfirm(false)
				}
				if (response?.data?.data[0].cmp_is_esign == 1) {
					setEsign(true)
					const clientpayload = {
						ClientId: mobile?.client_id,
						PanNo: mobile?.pan
					}
					const response = await postApiWithoutLoader(clientpayload, '/rejectionVerifyStatus');
				}
				if (json_data) {
					setKra(json_data);
				} else {
					console.log('error')
				}
			}
			else {
				toast('something went wrong')
			}
		}
		catch (error) {
			toast('something went wrong')

		}
	};


	const handleCallback = (res) => {
		if (res.success) {
			fetchData(res?.data?.mobile);
			setMobile(res?.data)
			dispatch(getClientRejectReasonAction({
				'ClientId': res?.data?.client_id,
				'PanNo': res?.data?.pan
			}));

			if (!isIncomeProof || !isSelfie || !isSign) {
				setTokenData(res.data)
				// setTimeout(() => {
				updateFiles(res.data)
				// }, 2000);
			}

		}
		else {
			// setLoading(false)

		}

	}
	const updateFiles = (data) => {
		dispatch(getClientFilesAction({
			PanNo: data.pan || '',
			CreatedBy: data.created_by
		}, handleCallback2));
	}
	const handleCallback2 = (res) => {
		console.log(res, 'hhhhh');

		// setFileData(res)
		if (res?.find(doc => rejectReasonsData?.length == 0 ? doc.Document === "Income Proof" : doc.Document === "Income Proof" && doc?.ReUploadFlag == 1)) {
			setisIncomeProof(true)
		}
		if (res?.find(doc => rejectReasonsData?.length == 0 ? doc.Document === "Bank Proof" : doc.Document === "Bank Proof" && doc?.ReUploadFlag == 1)) {
			setBank(true)
		}
		if (res?.find(doc => rejectReasonsData?.length == 0 ? doc.Document === "Client Signature" : doc.Document === "Client Signature" && doc?.ReUploadFlag == 1)) {
			setisSign(true)
		}
		if (res?.find(doc => rejectReasonsData?.length == 0 ? doc.Document === "Other" : doc.Document === "Client Photo" && doc?.ReUploadFlag == 1)) {
			setisSelfie(true)
		}
		// const hasOther = res?.some(doc => doc.Document === "Other");
		// const hasClientPhoto = res?.some(doc => doc.Document === "Client Photo");

		// if (rejectReasonsData?.length === 0 && hasOther && hasClientPhoto) {
		// 	setisSelfie(true);
		// 	alert('jjj')
		// } else if (res?.find(doc => doc.Document === "Client Photo" && doc?.ReUploadFlag === 1)) {
		// 	setisSelfie(true);
		// 	alert('lllll')

		// }
		if (res?.find(doc => doc.Document === "Pan Upload" && doc?.ReUploadFlag == 1)) {
			setPan(true)
		}
		if (res?.find(doc => doc.Document === "Address Upload" && doc?.ReUploadFlag == 1)) {
			setAddress(true)
		}
	}
	const changeStep = (num) => {
		setStep(num)
		setTimeout(() => {
			updateFiles(TokenData)
		}, 0);
	}

	const handleUploadIncome = () => {
		changeStep(2)
	}

	const handleUploadSelfie = () => {
		if (rejectReasonsData?.length == 0) {
			if (krnJourney?.full_name == null) {
				toast('The name of the PAN card holder is unavailable.');
				return;
			}
			if (krnJourney?.kra_status == null || krnJourney?.kra_status == 0) {
				toast('The selfie data is not available.');
				return;
			}
			else if (krnJourney?.kra_status == 1) {
				const kraData = krnJourney?.kra_xml;
				if (kraData) {
					changeStep(3)
				} else {
					checkKra()
				}

			} else {
				changeStep(3)
			}
		}
		else {
			changeStep(3)
		}
	}

	const checkKra = async () => {
		try {
			const payload = {
				mobile: mobile?.mobile,
			}
			const response = await postFormApiCo(payload, 'get-saved-kra-data');
			if (response?.data?.status == 200) {
				setKraData(response?.data)
				changeStep(3)
			}
			else {
				toast('The selfie data is not available')
				setKraData()
			}
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const handleUploadSignature = () => {
		changeStep(4)
	}
	const handleuploadPan = () => {
		changeStep(5)
	}
	const handleUploadAddress = () => {
		changeStep(6)
	}

	const handleContinueBtn = () => {
		setDeseble(true)
		setStopKra(false)
		dispatch(callUpdateSegmentInfoAction({
			PanNo: mobile?.pan,
			// Fname: selectedData?.FName,
			Refcode: "90",
			Reftype: bankMicr?.RoleId == 3 ? "RM" : bankMicr?.RoleId == 8 ? "BRANCH" : bankMicr?.RoleId == 13 ? "DSA" : 'null',
			channel: krnJourney?.channel,

		}, callbackhandle));

	};

	const callbackhandle = (resp) => {
		if (resp.success) {
			dispatch(finalSubmitAction({
				mobile: mobile?.mobile,
				process_type: 'resubmit',
				ClientId: mobile?.client_id,
			}, callbackFinal));
		}
		else {
			setDeseble(false)
			toast(resp?.message ? resp?.message : 'something went  wrong')
		}
	}

	const callbackFinal = async (resp) => {
		if (resp.success) {
			toast('Your Re-submit document upload successfully')
			dispatch(sendEquityEsignAction(
				{
					ClientId: mobile?.client_id,
					channel: krnJourney?.IsETrade == 90 ? 'Bigul' : 'Bonanza',
				}, callbackhandle1));

		}
		else {
			setDeseble(false)
			toast(resp?.message ? resp?.message : 'something went  wrong')

		}
	}

	const callbackhandle1 = (resp) => {
		if (resp.success) {
			setDeseble(false)
			setStopKra(true)
			// setCheckEsign(true)
			setTimeout(() => {
				window.open(resp?.data?.URLL, '_blank');
			}, 1000);
		}
		else {
			setDeseble(false)
			toast(resp?.message ? resp?.message : 'something went wrong')
		}
	}

	return (
		<>
			{

				loading ? 'Loading...' : TokenData?.length == 0 ? <div>Token Expire</div> : <>
					<ToastContainer autoClose={1000} />
					{step == 0 ?
						<BaseLogin>
							{
								rejectReasonsData?.length == 0 ?
									stage ?
										<div className='col-12'>
											<div className='msg_wrapper'>
												<img src={complete} alt='image' />
												<p>Your documents have been submitted successfully</p>
											</div>
										</div>
										:
										<div className='flex flex-col justify-center items-center text-center my-8'>
											<div><h1 className=' font-semibold text-[20px] font-poppinsMedium'> Submit Documents</h1></div>
											<div className='py-4 w-full md:w-[70%] lg:w-[50%]'>
												<p className='text-[15px] text-[#909090] text-center font-poppinsRegular'>
													Please submit your required KYC documents, selfie and signature to complete your KYC process.
												</p>
											</div>
											<div className='py-8 w-full md:w-[80%] lg:w-[70%]'>
												{
													krnJourney?.penny_drop_status == 2 &&
													<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)] bg-white 
						 `} onClick={() => changeStep(1)}>

														<div className="px-4 pt-5 pb-5 flex items-start justify-between">
															<div className='flex justify-start text-start  items-center'>
																<img src={FIle1} />
																<h1 className='w-44 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Upload Bank Proof</h1>
															</div>
															{
																// DKycData.isIncomeProofUploaded?.success ?
																bankarify && bankarify ?
																	<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																		<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																	</div>
																	:
																	<img className=' h-5 ' src={hand} />
															}
														</div>
													</div>
												}

												{
													(krnJourney?.Derivative !== null || krnJourney?.Commodity !== null || krnJourney?.income_id_send > 3) &&
													<div className={`rounded-[10px] bg-white drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)] mt-3  

					`} onClick={() => { handleUploadIncome() }}>
														{/* ${incomeProofDocs?.success ? " cursor-auto" : "cursor-pointer"}  */}
														<div className="px-4 pt-5 pb-5 flex justify-between " >
															<div className='flex justify-start text-start items-center'>
																<img src={FIle2} />
																<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Upload Income Proof</h1>
															</div>
															{
																// DKycData.isIncomeProofUploaded?.success ?
																isIncomeProof && isIncomeProof ?
																	<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																		<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																	</div>
																	:
																	<img className=' h-5 ' src={hand} />
															}
														</div>
													</div>

												}

												{

													<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)]  mt-3 bg-white cursor-pointer
								`} onClick={() => { handleUploadSelfie() }}>
														{/* ${selfieUpload?.success ? " cursor-auto" : "cursor-pointer"}  */}
														<div className="px-4 pt-5 pb-5 flex justify-between">
															<div className='flex justify-start text-start items-center'>
																<img src={Camera} />
																<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap  pl-3'> Take A Selfie</h1>
															</div>
															{
																// DKycData.isSelfieUploaded?.success ?
																isSelfie && isSelfie ?
																	<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																		<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																	</div>
																	:
																	<img className=' h-5 ' src={hand} />
															}
														</div>
													</div>
												}
												<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)]  mt-3 bg-white cursor-pointer
					`} onClick={() => handleUploadSignature()}>
													{/* // ${signatureDraw?.success ? " cursor-auto" : "cursor-pointer"}  */}
													<div className="px-4 pt-5 pb-5 flex justify-between items-start">
														<div className='flex justify-start text-start items-center'>
															<img src={Digital} />
															<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Draw Your Signature</h1>
														</div>
														{
															// DKycData.isSignatureUploaded?.success ?
															isSign && isSign ?
																<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																	<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																</div>
																:
																<img className=' h-5 ' src={hand} />
														}
													</div>
												</div>
											</div>
										</div>
									:
									esign ?
										<div className='col-12'>
											<div className='msg_wrapper'>
												<img src={complete} alt='image' />
												<p style={{ textAlign: 'center' }}>
													Your rejected documents are already resubmitted for verification
												</p>
											</div>
										</div>
										:
										<div className='flex flex-col justify-center items-center text-center my-8'>
											<div><h1 className=' font-semibold text-[20px] font-poppinsMedium'>Resubmit Rejected Documents</h1></div>
											<div className='py-4 w-full md:w-[70%] lg:w-[50%]'>
												<p className='text-[15px] text-[#909090] text-center font-poppinsRegular'>
													Your Application has been rejected due to following reasons :
													Plesae Re-upload required updated documents to activate your account.
												</p>
											</div>
											{

												<div className='py-8 w-full md:w-[80%] lg:w-[70%]' >
													{
														bank &&
														<>
															<div className={`mt-2 text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`} style={{ color: '#FF4343' }}>
																{bankarify ? null : bank?.reason ? bank?.reason : 'Bank proof is not valid'}
															</div>
															<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)] bg-white 
						 `} onClick={() => changeStep(1)} disabled={confirm}>
																<div className="px-4 pt-5 pb-5 flex items-start justify-between">
																	<div className='flex justify-start text-start  items-center'>
																		<img src={FIle1} />
																		<h1 className='w-44 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Resubmit Bank Proof</h1>
																	</div>
																	{

																		bankarify && bankarify ?
																			<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																				<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																			</div>
																			:
																			// <div className=' bg-[#C9C9C9] w-10 h-10  flex items-center justify-center p-1 rounded-full'>
																			<img className=' h-5 ' src={hand} />
																		// </div>
																	}
																</div>
															</div>
														</>
													}

													{
														IncomeCheck &&
														<>

															<div className={`mt-2 text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`} style={{ color: '#FF4343' }}>
																{isIncomeProof ? null : IncomeCheck?.reason ? IncomeCheck?.reason : 'Incomeproof is not valid'}
															</div>
															{/* <p style={{ color: '#FF4343', textAlign: 'left', fontWeight: 'bold', padding: 5 }}>{IncomeCheck?.reason}</p> */}
															<div className={`rounded-[10px] bg-white drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)] mt-3  
											
					`} onClick={() => { handleUploadIncome() }} disabled={confirm}>
																{/* ${incomeProofDocs?.success ? " cursor-auto" : "cursor-pointer"}  */}
																<div className="px-4 pt-5 pb-5 flex justify-between " >
																	<div className='flex justify-start text-start items-center'>
																		<img src={FIle2} />
																		<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Resubmit Income Proof</h1>
																	</div>
																	{
																		isIncomeProof && isIncomeProof ?
																			<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																				<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																			</div>
																			:
																			<img className=' h-5 ' src={hand} />
																	}
																</div>
															</div>
														</>
													}

													{
														ImageCheck &&
														<>
															<div className={`mt-2 text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`} style={{ color: '#FF4343' }}>
																{isSelfie ? null : ImageCheck?.reason ? ImageCheck?.reason : 'Image not clear'}
															</div>
															{/* <p style={{ color: '#FF4343', textAlign: 'left', fontWeight: 'bold', padding: 5 }}>{ImageCheck?.reason}</p> */}
															<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)]  mt-3 bg-white cursor-pointer
								`} onClick={() => { handleUploadSelfie() }} disabled={confirm}>
																{/* ${selfieUpload?.success ? " cursor-auto" : "cursor-pointer"}  */}
																<div className="px-4 pt-5 pb-5 flex justify-between">
																	<div className='flex justify-start text-start items-center'>
																		<img src={Camera} />
																		<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap  pl-3'> Resubmit Your Selfie</h1>
																	</div>
																	{
																		// DKycData.isSelfieUploaded?.success ?
																		isSelfie && isSelfie ?
																			<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																				<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																			</div>
																			:
																			<img className=' h-5 ' src={hand} />
																	}
																</div>
															</div>
														</>
													}
													{
														signatureCheck &&
														<>
															<div className={`mt-2 text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`} style={{ color: '#FF4343' }}>
																{isSign ? null : signatureCheck?.reason ? signatureCheck?.reason : 'Signature not clear '}
															</div>
															{/* <p style={{ color: '#FF4343', textAlign: 'left', fontWeight: 'bold', padding: 5 }}>{signatureCheck?.reason}</p> */}

															<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)]  mt-3 bg-white cursor-pointer
											`} onClick={() => handleUploadSignature()} disabled={confirm}>
																{/* // ${signatureDraw?.success ? " cursor-auto" : "cursor-pointer"}  */}
																<div className="px-4 pt-5 pb-5 flex justify-between items-start">
																	<div className='flex justify-start text-start items-center'>
																		<img src={Digital} />
																		<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'>Resubmit Your Signature</h1>
																	</div>
																	{
																		// DKycData.isSignatureUploaded?.success ?
																		isSign && isSign ?
																			<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																				<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																			</div>
																			:
																			<img className=' h-5 ' src={hand} />
																	}
																</div>
															</div>
														</>
													}
													{
														PanCheck &&
														<>
															<div className={`mt-2 text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`} style={{ color: '#FF4343' }}>
																{panUpload ? null : PanCheck?.reason ? PanCheck?.reason : 'Pan not valid'}
															</div>
															{/* <p style={{ color: '#FF4343', textAlign: 'left', fontWeight: 'bold', padding: 5 }}>{PanCheck?.reason}</p> */}
															<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)]  mt-3 bg-white cursor-pointer
					`} onClick={() => handleuploadPan()} disabled={confirm}>
																{/* // ${signatureDraw?.success ? " cursor-auto" : "cursor-pointer"}  */}
																<div className="px-4 pt-5 pb-5 flex justify-between items-start">
																	<div className='flex justify-start text-start items-center'>
																		<img src={FIle2} />
																		<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Resubmit Your PAN</h1>
																	</div>
																	{
																		// DKycData.isSignatureUploaded?.success ?
																		panUpload && panUpload ?
																			<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																				<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																			</div>
																			:
																			<img className=' h-5 ' src={hand} />
																	}
																</div>

															</div>
														</>
													}
													{
														AddressCheck &&
														<>
															<div className={`mt-2 text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`} style={{ color: '#FF4343' }}>
																{address ? null : AddressCheck?.reason ? AddressCheck?.reason : 'Address Proof is not valid'}
															</div>
															{/* <p style={{ color: '#FF4343', textAlign: 'left', fontWeight: 'bold', padding: 5 }}>{AddressCheck?.reason}</p> */}
															<div>
																<div className={`rounded-[10px] drop-shadow-[0_3px_12px_rgba(174,174,174,0.25)]  mt-3 bg-white cursor-pointer
					`} onClick={() => handleUploadAddress()} disabled={confirm}>
																	{/* // ${signatureDraw?.success ? " cursor-auto" : "cursor-pointer"}  */}
																	<div className="px-4 pt-5 pb-5 flex justify-between items-start">
																		<div className='flex justify-start text-start items-center'>
																			<img src={FIle2} />
																			<h1 className='w-52 text-[16px] font-poppinsRegular whitespace-nowrap pl-3'> Resubmit Address Proof</h1>
																		</div>
																		{
																			// DKycData.isSignatureUploaded?.success ?
																			address && address ?
																				<div className=' w-7 h-7  flex items-center justify-center rounded-full'>
																					<img className=' w-full h-full' src={svgAssets.kyc.greencheck} />
																				</div>
																				:
																				<img className=' h-5 ' src={hand} />
																		}
																	</div>

																</div>
															</div>
														</>
													}
												</div>
											}

											{(rejectReasonsData.length > 0) && (ImageCheck !== undefined ? ImageCheck !== undefined && isSelfie : true) && (bank !== undefined ? bank !== undefined && bankarify : true) && (signatureCheck !== undefined ? signatureCheck !== undefined && isSign : true) && (IncomeCheck !== undefined ? IncomeCheck !== undefined && isIncomeProof : true) && (PanCheck !== undefined ? PanCheck !== undefined && panUpload : true) && (confirm) &&
												<div style={{
													background: '#121212',
													height: '50px',
													width: '150px',
													marginBottom: '30px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													alignSelf: 'center', borderRadius: 20, cursor: 'pointer',
												}} disabled={deseble} onClick={handleContinueBtn} >
													<p style={{ color: 'white', fontWeight: 'bold' }}>{deseble ? 'Please Wait...' : 'Proceed to Esign'}</p>
												</div>
											}
										</div>
							}
						</BaseLogin>
						: step == 1 ?
							<BankProof changeStep={changeStep} TokenData={TokenData} isIncomeProof={isIncomeProof} isSelfie={isSelfie} isSign={isSign} reject={rejectReasonsData} bankMicr={bankMicr} /> : step == 2 ? <IncomeProof changeStep={changeStep} TokenData={TokenData} isIncomeProof={isIncomeProof} isSelfie={isSelfie} isSign={isSign} reject={rejectReasonsData} mobileNo={mobile?.mobile} /> : step == 3 ? <TakeSelfie changeStep={changeStep} TokenData={TokenData} isIncomeProof={isIncomeProof} isSelfie={isSelfie} isSign={isSign} check={kra ? true : false} reject={rejectReasonsData} MobileNo={mobile} krnJourney={krnJourney} getsaveKraData={getsaveKraData} /> : step == 4 ? <DrawSignature changeStep={changeStep} TokenData={TokenData} isIncomeProof={isIncomeProof} isSelfie={isSelfie} isSign={isSign} reject={rejectReasonsData} /> :
								step == 5 ? <UploadPan changeStep={changeStep} TokenData={TokenData} isIncomeProof={isIncomeProof} isSelfie={isSelfie} isSign={isSign} /> : step == 6 ? <UploadAddress changeStep={changeStep} TokenData={TokenData} isIncomeProof={krnJourney} isSelfie={isSelfie} isSign={isSign} /> : null}
				</>
			}

		</>

	);
};
export default SubmitDocuments;

















