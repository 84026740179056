import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { pngAssets, svgAssets } from '../../assets/asset';
import { SendESignBtn } from '../../components/buttons/SendESignLink';
import { MainTitle } from '../../components/common/MainTitle';
import { StepProgressBar } from '../../components/progressBar/ProgressBar';
import { verificationSteps } from '../../containers/kyc/kycData';
import { AddressDetail } from '../../containers/review/AddressDetail';
import { BankDetail } from '../../containers/review/BankDetail';
import { BrokerageStep } from '../../containers/review/brokerage/BrokerageStep';
import { ClientDetail } from '../../containers/review/clientDetail/ClientDetail';
import { ClientPreview } from '../../containers/review/clientDetail/ClientPreview';
import { MobileEmailDetail } from '../../containers/review/MobileEmailDetail';
import { OccupationalDetail } from '../../containers/review/OccupationalDetail';
import { PanDetail } from '../../containers/review/PanDetail';
import { PersonalDetail } from '../../containers/review/PersonalDetail';
import { Debounce } from '../../hooks/Debounce';
import { getClientFilesAction, getReviewApplicationAction, sendEquityEsignAction, sendEquityEsignLinkAction } from '../../redux/actions/kyc/review.action';
import { DOMAIN } from '../../config/config';
import { Modal } from 'antd';
import { ReviewBtn } from '../../components/buttons/ReviewBtn';
import { Link } from 'react-router-dom';
import { postApi, postApiWithoutLoader } from '../../services/common';
import { StepReviewBar } from '../../components/progressBar/ReviewBar';
import { toast } from 'react-toastify';
import { AddressDetail1 } from '../../containers/review/AddressDetails1';
import { callUpdateSegmentInfoAction } from '../../redux/actions/kyc/step8.action';
import { Nominee } from '../../containers/kyc/step6/Nominee';
import NewLoader from '../../assets/gif/Dark Theme/Loading_big.gif';

const dummyData = [
	{
		id: 1,
		"DocumentType": "Client photo"
	},
	{
		id: 2,
		"DocumentType": "Client Signature"
	},
	{
		id: 2,
		"DocumentType": "PAN Card"
	},
]
const clientIntersectionOption = {
	rootMargin: '0px 0px -75% 0px',
	threshold: 0.3
};

const intersectionOption = {
	rootMargin: '0px 0px 50% 0px',
	threshold: 1
};

export const ReviewApplication = () => {
	const dispatch = useDispatch();
	const { ref: clientRef, inView: isClientRefInView } = useInView(clientIntersectionOption);
	const { ref: mobileRef, inView: isMobileRefView } = useInView(intersectionOption);
	const { ref: panRef, inView: isPanRefView } = useInView(intersectionOption);
	const { ref: addressRef, inView: isAddressRefView } = useInView(intersectionOption);
	const { ref: bankRef, inView: isBankRefView } = useInView(intersectionOption);
	const { ref: personalRef, inView: isPersonalRefView } = useInView(intersectionOption);
	const { ref: occupationalRef, inView: isOccupationalRefView } = useInView(intersectionOption);
	const { ref: brokerageRef, inView: isBrokerageRefView } = useInView(intersectionOption);
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const kycStep2 = useSelector(state => state?.kycStep2 || {});
	const kycStep3 = useSelector(state => state?.kycStep3 || {});
	const kycStep4 = useSelector(state => state?.kycStep4 || {});
	const kycStep6 = useSelector(state => state?.kycStep6 || {});
	const reviewData = useSelector(state => state?.reviewData.reviewData || {});
	const reviewData2 = useSelector(state => state?.reviewData.reviewData2 || {});
	const reviewFilesData = useSelector(state => state?.reviewData.filesData || {});
	const eSignLinkRes = useSelector(state => state?.reviewData?.sendEsignLinkRes || {});
	const euitySignRes = useSelector(state => state?.reviewData?.equityEsignRes || {});
	const eSignLinkDataRes = useSelector(state => state?.reviewData?.equityEsignRes.data || {});
	const [isClientPreviewVisible, setIsClientPreviewVisible] = useState(false);
	const [steps, setSteps] = useState(verificationSteps || []);
	const [isSendLink, setIsSendLink] = useState(false);
	const [isSendEquitSign, setIsSendEquitSign] = useState(false);
	const [isStopNewTabEquitSign, setIsStopNewTabEquitSign] = useState(false);
	const income_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Income Proof'))
	const salary1 = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a.DocumentType == 'Latest 3 Month Salary Slip') || (a.DocumentType == 'Last 3 Months Salary Slip'))
	const BankProof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Bank Proof'))
	const other = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Other'))
	const clientPhoto = dummyData && dummyData?.find(el => el.DocumentType === 'Client photo');
	const clientSignature = dummyData && dummyData.find(el => el.DocumentType === 'Client Signature');
	const panCardPdf = dummyData && dummyData?.find(el => el.DocumentType === 'PAN Card');
	const debounceValue = Debounce(isClientRefInView, 2000);
	const clientId = kycStep6.declarationInfoData.client_id || reviewData2.client_id;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [kraCHeck, setCheckKra] = useState(false);
	const [deseble, setDeseble] = useState(false);
	const [bankData, setBankData] = useState(null);
	const [kraJourney, setKraJouneydata] = useState(null);
	const [initialApiCallMade, setInitialApiCallMade] = useState(false);


	useEffect(() => {
		if (kraCHeck) {
			setIsModalOpen(true);
			ModalClose()
		}
	}, [kraCHeck]);

	useEffect(() => {
		// let interval;
		if (isSendEquitSign) {
			// interval = setInterval(() => {
			setIsStopNewTabEquitSign(true);
			fetchData()

			// }, 20000);
		}
		// return () => clearInterval(interval);

	}, [isSendEquitSign]);

	const fetchData = async () => {
		try {
			const payload = {
				mobile: kycStep1?.mobileInput?.value,
				esign: 1
			}
			const response = await postApiWithoutLoader(payload, '/getKycJourney');
			if (response?.data?.success) {
				const pennydropdata = JSON.parse(response?.data.data[0].pennydrop_data)
				setKraJouneydata(response?.data.data[0])
				// const response3 = await postApiWithoutLoader({ PanNo: response?.data.data[0]?.pan }, '/updateReviewStatus');

				setBankData(pennydropdata)
				if (!initialApiCallMade) {
					dispatch(getReviewApplicationAction(
						{
							mobile: kycStep1.mobileInput.value,
							ClientId: response?.data.data[0]?.client_id,
						}
					));
					dispatch(getClientFilesAction({
						PanNo: response?.data.data[0]?.pan,
						CreatedBy: userDetail.created_by
					}, handleCallback));
					setInitialApiCallMade(true); // Set initialApiCallMade to true after making the initial API call
				}
				if (response?.data?.data[0]?.cmp_is_esign == 1) {
					setCheckKra(true)

				}
			}
			else {
				console.error('Error fetching data:');
			}
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};


	useEffect(() => {
		setCheckKra(false)
		window.scrollTo(0, 0)
	}, []);


	const handleSteps = ({ index }) => {
		const updatedSteps = steps.map((el, i) => {
			if (i === index) {
				return ({
					...el,
					status: 'verifyView'
				});
			} else {
				return ({
					...el,
					status: 'success'
				});
			}
		});
		setSteps(updatedSteps);
	};

	const handleEquityEsignBtn = async () => {
		setDeseble(true)
		const payload = {
			PanNo: kraJourney?.pan,
			Refcode: kraJourney?.refcode,
			Reftype: reviewData?.RoleId == 3 ? "RM" : reviewData?.RoleId == 8 ? "BRANCH" : reviewData?.RoleId == 13 ? "DSA" : 'null',
			channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
		}
		const response = await postApi(payload, '/UpdateSegmentInfo');
		if (response?.data?.success) {
			// 
			dispatch(sendEquityEsignAction({
				ClientId: kraJourney?.client_id,
				channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
			}, useCallback1));
		}

		else {
			setDeseble(false)
			toast(response?.data?.message || 'Something went wrong')
		}
	};

	const useCallback1 = (res) => {
	
		setDeseble(false)
		if (res.success == true) {
			setIsSendLink(true);
			setIsSendEquitSign(true);
		}
	}
	const handleCheck = () => {
		fetchData()
		setTimeout(() => {
			if (kraJourney?.cmp_is_esign != 1) {
				toast('Your eSign process is pending')
			}
		}, 3000);
	}

	const handleViewPDF = async () => {
		const payload = {
			PanNo: kraJourney?.pan,
			Refcode: kraJourney?.refcode,
			Reftype: reviewData?.RoleId == 3 ? "RM" : reviewData?.RoleId == 8 ? "BRANCH" : reviewData?.RoleId == 13 ? "DSA" : 'null',
			channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
		}
		const response = await postApi(payload, '/UpdateSegmentInfo');
		if (response?.data?.success) {
			const payload2 = {
				ClientId: kraJourney?.client_id,
				channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
				"IsESign": "No",
				"IsType": "equity",
				view_html: 1,
			}
			const response = await postApi(payload2, (payload2?.channel == "Bigul" || payload2?.channel == "BIGUL") ? '/GetESignMyetrade' : '/GetESign');
			if (response?.data.success) {
				window.open(response?.data?.data?.PdfPath, '_blank');
			} else {
				toast(response?.data.message || 'something went wrong')
			}
		}

		else {
			toast(response?.data.message || 'something went wrong')
		}

	}

	useEffect(() => {
		if (euitySignRes?.success && eSignLinkDataRes?.URLL && isSendLink) {
			setTimeout(() => {
				window.open(eSignLinkDataRes.URLL, '_blank');
			}, 1000);
		}
	}, [euitySignRes?.success && eSignLinkDataRes?.URLL && isSendLink])


	const handleSendEsignLinkBtn = async () => {
		const payload = {
			PanNo: kraJourney?.pan,
			Refcode: kraJourney?.refcode,
			Reftype: reviewData?.RoleId == 3 ? "RM" : reviewData?.RoleId == 8 ? "BRANCH" : reviewData?.RoleId == 13 ? "DSA" : 'null',
			channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
		}
		const response = await postApi(payload, '/UpdateSegmentInfo');
		if (response?.data?.success) {
			dispatch(sendEquityEsignLinkAction(
				{
					ClientId: kraJourney?.client_id,
					link: `${DOMAIN}/client-esign/${kycStep1.mobileInput.value}`,
				}
			));

			setIsSendEquitSign(true)
		}

		else {
			toast(response?.data.message || 'something went wrong')
		}

	};

	useEffect(() => {
		setIsClientPreviewVisible(debounceValue);
		fetchData()
	}, []);

	useEffect(() => {
		if (initialApiCallMade == true) {
			console.log('check clientId', {
				mobile: kycStep1.mobileInput.value,
				ClientId: kraJourney?.client_id,
			})

		}

	}, [initialApiCallMade]);

	const handleCallback = (res) => {
		if (!res) {
			window.location.reload();
		}
	};

	useEffect(() => {
		if (isMobileRefView) {
			handleSteps({ index: 0 });
		} else if (isPanRefView) {
			handleSteps({ index: 1 });
		} else if (isAddressRefView) {
			handleSteps({ index: 2 });
		} else if (isBankRefView) {
			handleSteps({ index: 3 });
		} else if (isPersonalRefView) {
			handleSteps({ index: 4 });
		} else if (isOccupationalRefView) {
			handleSteps({ index: 5 });
		}
		else if (isBrokerageRefView) {
			handleSteps({ index: 6 });
		}
		else if (isBrokerageRefView) {
			handleSteps({ index: 7 });
		}
	}, [isMobileRefView, isPanRefView, isAddressRefView, isBankRefView, isPersonalRefView, isOccupationalRefView, isBrokerageRefView]);


	const ModalClose = async () => {
		try {
			const payload = {
				UserId: userDetail?.user_id,
				PanNo: kraJourney?.pan,
				// PanNo: reviewData2?.pan || reviewData2?.PanNo,
				roleId: userDetail?.role_id,
				rm_journey: 1,
				rejection: 0


			}
			const response = await postApi(payload, '/SaveClientSatus');
			if (response?.response) {
				setIsModalOpen(false);
				setCheckKra(false)
			}
		}
		catch (error) {
			setIsModalOpen(false);
			setCheckKra(false)
		}


	}

	const copyUrl = (status) => {

		const textToCopy = status?.data?.URLL; // Replace with your URL or text

		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				toast('Customer link copied to clipboard!')
			})
			.catch((err) => {
				toast(err)
				// setCopySuccess('Copy failed');
				// console.error('Unable to copy text: ', err);
			});
	}

	return (
		<>
			{

				deseble ?
					<img src={NewLoader} alt="Loading..." style={{
						// width: '50%',
						height: '100%',
						maxWidth: '100%',
						display: 'flex',
						justifyContent: 'center', alignItems: 'center',
						backgroundColor: 'transparent',
						textAlign: 'center',
						marginRight: 'auto',
						marginLeft: 'auto'

					}} />
					:
					reviewData?.ClientId ?
						<div className="w-full flex flex-col">
							<Modal
								open={isModalOpen}
								centered={true}
								width={400}
								closeIcon={() => setIsModalOpen(false)}
								footer={null}
								className="w-full"
							>
								<div className=''>
									<h6 className=" text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
										Congrats!<br></br>
										Esign completed successfully.
									</h6>
									<div className="flex item justify-center gap-x-3 mt-5">
										<Link to="/">
											<button onDoubleClick={() => {
												setTimeout(() => {
													setIsModalOpen(false)
												}, 3000);
											}} className='bg-black text-white px-8 py-2 rounded font-bold'
												style={
													{ borderRadius: '10px', background: '#007813' }
												}
											>
												{
													'Go to Dashboard'
												}
											</button>
										</Link>
									</div>
								</div>
							</Modal>

							<div className="flex flex-col md:flex-row items-center justify-between">
								<div className="w-full">
									<MainTitle title="Review Application Details" />
								</div>
								<div className="w-full flex items-center justify-center  md:justify-end text-[#848484] font-medium font-poppinsMedium  mb-4 md:mb-0">
									<span className="mr-2">Client of</span>
									{reviewData?.IsETrade == 90 ? (
										<img className="h-5" alt="bigul" src={svgAssets.bigul} />
									) : (
										<img className="h-8" alt="bigul" src={svgAssets.bonabza} />
									)}
								</div>
							</div>
							<div className="px-4 md:px-7 py-8 mb-[35px] rounded-[20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
								<ClientDetail
									isVerify={false}
									isKRA={kycStep2?.isKRA}
									reviewData={reviewData || {}}
									reviewData2={reviewData2 || {}}
									reviewFilesData={reviewFilesData}
									clientPhoto={clientPhoto?.DocumentImages || ''}
									clientSignature={clientSignature?.DocumentImages || ''}
								/>
							</div>

							{isClientPreviewVisible
								? (
									<>
										<div ref={clientRef} className="sticky top-[80px] z-50">
											<div className="px-5 md:px-7 py-3 rounded-[10px_10px_0_0] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
												<ClientPreview
													reviewData={reviewData || {}}
													reviewData2={reviewData2 || {}}
													reviewFilesData={reviewFilesData || []}
													clientPhoto={clientPhoto?.DocumentImages || ''}
													clientSignature={clientSignature?.DocumentImages || ''}
												/>
											</div>
											<div className="pt-5 pb-4 rounded-0 bg-[#E9F1FF]">
												<StepReviewBar selectedStep={8} steps={steps} setSteps={setSteps} showStepLabel={true} />
											</div>
										</div>
									</>
								)
								: (
									<>
										<div ref={clientRef} className="sticky top-[80px] z-50">
											<div className="px-5 md:px-7 py-3 rounded-[10px_10px_0_0] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
												<ClientPreview
													isKRA={kycStep2?.isKRA}
													reviewData={reviewData || {}}
													reviewData2={reviewData2 || {}}
													reviewFilesData={reviewFilesData || []}
													clientPhoto={clientPhoto?.DocumentImages || ''}
													clientSignature={clientSignature?.DocumentImages || ''}
												/>
											</div>
											<div className="pt-5 pb-4 rounded-0 bg-[#E9F1FF]">
												<StepReviewBar selectedStep={7} steps={steps} setSteps={setSteps} showStepLabel={true} />
											</div>
										</div>
									</>
								)
							}
							<div className="px-5 md:px-7 py-8 rounded-[0_0_20px_20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
								<div ref={mobileRef}>
									<MobileEmailDetail reviewData={reviewData || {}} reviewData2={reviewData2 || {}} />
								</div>
								<div ref={panRef}>
									<PanDetail kycStep2={kycStep2} isVerify={false} reviewData={reviewData || {}} reviewData2={reviewData2 || {}} panCardPdf={panCardPdf?.DocumentImages || ''} />
								</div>
								<div ref={addressRef}>
									<AddressDetail isVerify={false} reviewData={reviewData || {}} reviewData2={reviewData2 || {}} />
									<AddressDetail1
										reviewData={reviewData || {}}
										reviewData2={reviewData2 || {}}
										reviewFilesData={reviewFilesData}
									/>
								</div>
								<div ref={bankRef}>
									<BankDetail isVerify={false} pennyData={bankData && bankData || {}} reviewData={reviewData || {}} reviewData2={reviewData2 || {}} />
								</div>
								<div ref={personalRef}>
									<PersonalDetail isVerify={false} kycStep3={kycStep3} reviewData={reviewData || {}} />
								</div>
								<div ref={occupationalRef}>
									<OccupationalDetail isVerify={false} reviewData={reviewData || {}} kycStep4={kycStep4 || {}} />
								</div>
								<div ref={brokerageRef}>
									<BrokerageStep reviewData={reviewData || {}}
										reviewData2={reviewData2 || {}} />
								</div>
								<div>
									{
										reviewData?.nominee_arr != null &&
										<Nominee reviewData={reviewData?.nominee_arr} />
									}

								</div>
								{income_proof && !salary1.length > 0 &&
									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
										<div className="text-lg font-medium font-poppinsMedium">Income Proof - Documents Uploaded</div>
										<div
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={income_proof?.ImagePath} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
												</div>
											</a>
										</div>
									</div>

								}
								{salary1.length > 0 &&
									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
										<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip1</div>
										<div
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={salary1[0]?.ImagePath} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
												</div>
											</a>
										</div>
									</div>

								}
								{salary1.length > 0 &&
									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
										<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip 2</div>
										<div
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={salary1[1]?.ImagePath} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
												</div>
											</a>
										</div>
									</div>
								}
								{salary1.length > 0 &&
									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
										<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip 3</div>
										<div
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={salary1[2]?.ImagePath} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
												</div>
											</a>
										</div>
									</div>

								}
								{BankProof &&
									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
										<div className="text-lg font-medium font-poppinsMedium">Bank Proof Document</div>
										<div
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={BankProof.ImagePath} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
												</div>
											</a>
										</div>
									</div>
								}
								{other &&
									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
										<div className="text-lg font-medium font-poppinsMedium">Penny Drop Document</div>
										<div
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={other.ImagePath} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
												</div>
											</a>
										</div>
									</div>
								}
							</div>
							<div className="mt-10 flex justify-end mb-6">

								<SendESignBtn  handleBtn={handleCheck} name={"Check eSign Status"} color={'#0C82F7 '}/>
								

								<SendESignBtn handleBtn={handleViewPDF} name={"View KYC form before eSign"} />

								{

									<SendESignBtn handleBtn={handleEquityEsignBtn} name={'Equity eSign'} isDisabled={deseble} />
								}

								<SendESignBtn handleBtn={handleSendEsignLinkBtn} name='Send Aadhar e-sign link' successName='Successfully Send Aadhar e-sign link' isSuccess={eSignLinkRes.success} isDisabled={eSignLinkRes.success} />
							</div>
							<div className="flex justify-end ">
								{eSignLinkRes?.success &&
									<div className="bg-[#E0FFE5]  p-4 rounded my-5 ">
										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
											<p>Status :</p>
											<p className={eSignLinkRes?.success ? 'text-[#007813]' : 'text-[#000]'}>{eSignLinkRes.message}</p>
										</div>
										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
											<p>Registered Mobile Number :</p>
											<p className={eSignLinkRes?.success ? 'text-[#007813]' : 'text-[#000]'}>{eSignLinkRes.data?.mobile}</p>
										</div>
										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2 mt-4">
											<p><button onClick={handleSendEsignLinkBtn} className='bg-black px-5 py-1 text-white rounded'>Resend eSign Link </button></p>
										</div>
										<div className="flex"></div>
									</div>}
							</div>
							<div className="flex justify-end mb-6">
								{euitySignRes && euitySignRes?.message &&
									<div className="bg-[#E0FFE5]  p-4 rounded">
										{
											euitySignRes?.success &&
											<button style={{ maxWidth: '175px', justifyContent: 'flex-end' }}
												className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
												onClick={() => { copyUrl(euitySignRes) }}
											>
												{/* <img src={copy} width={'15px'} /> */}
												<span className='ml-2'>Copy eSign link</span>
											</button>
										}

										{/* <div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
							<p>Status :</p>
							<p className={euitySignRes?.success ? 'text-[#007813]' : 'text-[#F30000]'}>{euitySignRes?.success ? 'Success' : "Failed"}</p>
						</div> */}
										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
											<p>Message :</p>
											<p className={euitySignRes?.success ? 'text-[#007813]' : 'text-[#F30000]'}>{euitySignRes?.message}</p>
										</div>
										<div className="flex"></div>
									</div>}
							</div>

						</div>
						:

						<h1>Please wait..... data fetching</h1>
			}
		</>

	);
};



// import { useEffect, useState } from 'react';
// import { useInView } from 'react-intersection-observer';
// import { useDispatch, useSelector } from 'react-redux';
// import { pngAssets, svgAssets } from '../../assets/asset';
// import { SendESignBtn } from '../../components/buttons/SendESignLink';
// import { MainTitle } from '../../components/common/MainTitle';
// import { StepProgressBar } from '../../components/progressBar/ProgressBar';
// import { verificationSteps } from '../../containers/kyc/kycData';
// import { AddressDetail } from '../../containers/review/AddressDetail';
// import { BankDetail } from '../../containers/review/BankDetail';
// import { BrokerageStep } from '../../containers/review/brokerage/BrokerageStep';
// import { ClientDetail } from '../../containers/review/clientDetail/ClientDetail';
// import { ClientPreview } from '../../containers/review/clientDetail/ClientPreview';
// import { MobileEmailDetail } from '../../containers/review/MobileEmailDetail';
// import { OccupationalDetail } from '../../containers/review/OccupationalDetail';
// import { PanDetail } from '../../containers/review/PanDetail';
// import { PersonalDetail } from '../../containers/review/PersonalDetail';
// import { Debounce } from '../../hooks/Debounce';
// import { getClientFilesAction, getReviewApplicationAction, sendEquityEsignAction, sendEquityEsignLinkAction } from '../../redux/actions/kyc/review.action';
// import { DOMAIN } from '../../config/config';
// import { Modal, Button } from 'antd';
// import { ReviewBtn } from '../../components/buttons/ReviewBtn';
// import { Link } from 'react-router-dom';
// import { postApi, postApiWithoutLoader } from '../../services/common';
// import { StepReviewBar } from '../../components/progressBar/ReviewBar';
// import { toast } from 'react-toastify';
// import { AddressDetail1 } from '../../containers/review/AddressDetails1';
// import { callUpdateSegmentInfoAction } from '../../redux/actions/kyc/step8.action';
// import { Nominee } from '../../containers/kyc/step6/Nominee';
// import NewLoader from '../../assets/gif/Dark Theme/Loading_big.gif';

// const dummyData = [
// 	{
// 		id: 1,
// 		"DocumentType": "Client photo"
// 	},
// 	{
// 		id: 2,
// 		"DocumentType": "Client Signature"
// 	},
// 	{
// 		id: 2,
// 		"DocumentType": "PAN Card"
// 	},
// ]
// const clientIntersectionOption = {
// 	rootMargin: '0px 0px -75% 0px',
// 	threshold: 0.3
// };

// const intersectionOption = {
// 	rootMargin: '0px 0px 50% 0px',
// 	threshold: 1
// };

// export const ReviewApplication = () => {
// 	const dispatch = useDispatch();
// 	const { ref: clientRef, inView: isClientRefInView } = useInView(clientIntersectionOption);
// 	const { ref: mobileRef, inView: isMobileRefView } = useInView(intersectionOption);
// 	const { ref: panRef, inView: isPanRefView } = useInView(intersectionOption);
// 	const { ref: addressRef, inView: isAddressRefView } = useInView(intersectionOption);
// 	const { ref: bankRef, inView: isBankRefView } = useInView(intersectionOption);
// 	const { ref: personalRef, inView: isPersonalRefView } = useInView(intersectionOption);
// 	const { ref: occupationalRef, inView: isOccupationalRefView } = useInView(intersectionOption);
// 	const { ref: brokerageRef, inView: isBrokerageRefView } = useInView(intersectionOption);
// 	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
// 	const kycStep1 = useSelector(state => state?.kycStep1 || {});
// 	const kycStep2 = useSelector(state => state?.kycStep2 || {});
// 	const kycStep3 = useSelector(state => state?.kycStep3 || {});
// 	const kycStep4 = useSelector(state => state?.kycStep4 || {});
// 	const kycStep6 = useSelector(state => state?.kycStep6 || {});
// 	const reviewData = useSelector(state => state?.reviewData.reviewData || {});
// 	const reviewData2 = useSelector(state => state?.reviewData.reviewData2 || {});
// 	const reviewFilesData = useSelector(state => state?.reviewData.filesData || {});
// 	const eSignLinkRes = useSelector(state => state?.reviewData?.sendEsignLinkRes || {});
// 	const euitySignRes = useSelector(state => state?.reviewData?.equityEsignRes || {});
// 	const eSignLinkDataRes = useSelector(state => state?.reviewData?.equityEsignRes.data || {});
// 	const [isClientPreviewVisible, setIsClientPreviewVisible] = useState(false);
// 	const [steps, setSteps] = useState(verificationSteps || []);
// 	const [isSendLink, setIsSendLink] = useState(false);
// 	const [isSendEquitSign, setIsSendEquitSign] = useState(false);
// 	const [isStopNewTabEquitSign, setIsStopNewTabEquitSign] = useState(false);
// 	const income_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Income Proof'))
// 	const salary1 = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a.DocumentType == 'Latest 3 Month Salary Slip') || (a.DocumentType == 'Last 3 Months Salary Slip'))
// 	const BankProof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Bank Proof'))
// 	const other = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Other'))
// 	const clientPhoto = dummyData && dummyData?.find(el => el.DocumentType === 'Client photo');
// 	const clientSignature = dummyData && dummyData.find(el => el.DocumentType === 'Client Signature');
// 	const panCardPdf = dummyData && dummyData?.find(el => el.DocumentType === 'PAN Card');
// 	const debounceValue = Debounce(isClientRefInView, 2000);
// 	const clientId = kycStep6.declarationInfoData.client_id || reviewData2.client_id;
// 	const [isModalOpen, setIsModalOpen] = useState(false);
// 	const [kraCHeck, setCheckKra] = useState(false);
// 	const [deseble, setDeseble] = useState(false);
// 	const [bankData, setBankData] = useState(null);
// 	const [kraJourney, setKraJouneydata] = useState(null);
// 	const [initialApiCallMade, setInitialApiCallMade] = useState(false);
// 	const [checkStatus, setCheckStatus] = useState(false)


// 	useEffect(() => {
// 		if (kraCHeck) {
// 			setIsModalOpen(true);
// 			ModalClose()
// 		}
// 	}, [kraCHeck]);

// 	useEffect(() => {
// 		// let interval;
// 		if (isSendEquitSign) {
// 			// interval = setInterval(() => {
// 			setIsStopNewTabEquitSign(true);
// 			fetchData()

// 			// }, 20000);
// 		}
// 		// return () => clearInterval(interval);

// 	}, [isSendEquitSign]);

// 	const fetchData = async () => {
// 		try {
// 			const payload = {
// 				mobile: kycStep1?.mobileInput?.value,
// 				esign: 1
// 			}
// 			const response = await postApiWithoutLoader(payload, '/getKycJourney');
// 			if (response?.data?.success) {
// 				const pennydropdata = JSON.parse(response?.data.data[0].pennydrop_data)
// 				setKraJouneydata(response?.data.data[0])
// 				// const response3 = await postApiWithoutLoader({ PanNo: response?.data.data[0]?.pan }, '/updateReviewStatus');

// 				setBankData(pennydropdata)
// 				if (!initialApiCallMade) {
// 					dispatch(getReviewApplicationAction(
// 						{
// 							mobile: kycStep1.mobileInput.value,
// 							ClientId: response?.data.data[0]?.client_id,
// 						}
// 					));
// 					dispatch(getClientFilesAction({
// 						PanNo: response?.data.data[0]?.pan,
// 						CreatedBy: userDetail.created_by
// 					}, handleCallback));
// 					setInitialApiCallMade(true); // Set initialApiCallMade to true after making the initial API call
// 				}
// 				if (response?.data?.data[0]?.cmp_is_esign == 1) {
// 					setCheckKra(true)

// 				}
// 			}
// 			else {
// 				console.error('Error fetching data:');
// 			}
// 		}
// 		catch (error) {
// 			console.error('Error fetching data:', error);
// 		}
// 	};


// 	useEffect(() => {
// 		setCheckKra(false)
// 		window.scrollTo(0, 0)
// 	}, []);


// 	const handleSteps = ({ index }) => {
// 		const updatedSteps = steps.map((el, i) => {
// 			if (i === index) {
// 				return ({
// 					...el,
// 					status: 'verifyView'
// 				});
// 			} else {
// 				return ({
// 					...el,
// 					status: 'success'
// 				});
// 			}
// 		});
// 		setSteps(updatedSteps);
// 	};

// 	const handleEquityEsignBtn = async () => {
// 		setDeseble(true)
// 		const payload = {
// 			PanNo: kraJourney?.pan,
// 			Refcode: kraJourney?.refcode,
// 			Reftype: reviewData?.RoleId == 3 ? "RM" : reviewData?.RoleId == 8 ? "BRANCH" : reviewData?.RoleId == 13 ? "DSA" : 'null',
// 			channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
// 		}
// 		const response = await postApi(payload, '/UpdateSegmentInfo');
// 		if (response?.data?.success) {
// 			// 
// 			dispatch(sendEquityEsignAction({
// 				ClientId: kraJourney?.client_id,
// 				channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
// 			}, useCallback1));
// 		}

// 		else {
// 			setDeseble(false)
// 			setCheckStatus(false)
// 			toast(response?.data?.message || 'Something went wrong')
// 		}
// 	};

// 	const useCallback1 = (res) => {

// 		setDeseble(false)
// 		setCheckStatus(false)
// 		if (res.success == true) {
// 			setIsSendLink(true);
// 			setIsSendEquitSign(true);
// 		}
// 	}
// 	const handleCheck = () => {
// 		fetchData()
// 		setTimeout(() => {
// 			if (kraJourney?.cmp_is_esign != 1) {
// 				toast('Your eSign process is pending')
// 			}
// 		}, 3000);
// 	}

// 	const handleViewPDF = async () => {
// 		const payload = {
// 			PanNo: kraJourney?.pan,
// 			Refcode: kraJourney?.refcode,
// 			Reftype: reviewData?.RoleId == 3 ? "RM" : reviewData?.RoleId == 8 ? "BRANCH" : reviewData?.RoleId == 13 ? "DSA" : 'null',
// 			channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
// 		}
// 		const response = await postApi(payload, '/UpdateSegmentInfo');
// 		if (response?.data?.success) {
// 			const payload2 = {
// 				ClientId: kraJourney?.client_id,
// 				channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
// 				"IsESign": "No",
// 				"IsType": "equity",
// 				view_html: 1,
// 			}
// 			const response = await postApi(payload2, (payload2?.channel == "Bigul" || payload2?.channel == "BIGUL") ? '/GetESignMyetrade' : '/GetESign');
// 			if (response?.data.success) {
// 				window.open(response?.data?.data?.PdfPath, '_blank');
// 			} else {
// 				toast(response?.data.message || 'something went wrong')
// 			}
// 		}

// 		else {
// 			toast(response?.data.message || 'something went wrong')
// 		}

// 	}

// 	useEffect(() => {
// 		if (euitySignRes?.success && eSignLinkDataRes?.URLL && isSendLink) {
// 			setTimeout(() => {
// 				window.open(eSignLinkDataRes.URLL, '_blank');
// 			}, 1000);
// 		}
// 	}, [euitySignRes?.success && eSignLinkDataRes?.URLL && isSendLink])


// 	const handleSendEsignLinkBtn = async () => {
// 		const payload = {
// 			PanNo: kraJourney?.pan,
// 			Refcode: kraJourney?.refcode,
// 			Reftype: reviewData?.RoleId == 3 ? "RM" : reviewData?.RoleId == 8 ? "BRANCH" : reviewData?.RoleId == 13 ? "DSA" : 'null',
// 			channel: reviewData?.IsETrade == 90 ? "Bigul" : 'Bonanza',
// 		}
// 		const response = await postApi(payload, '/UpdateSegmentInfo');
// 		if (response?.data?.success) {
// 			dispatch(sendEquityEsignLinkAction(
// 				{
// 					ClientId: kraJourney?.client_id,
// 					link: `${DOMAIN}/client-esign/${kycStep1.mobileInput.value}`,
// 				}
// 			));

// 			setIsSendEquitSign(true)
// 		}

// 		else {
// 			toast(response?.data.message || 'something went wrong')
// 		}

// 	};

// 	useEffect(() => {
// 		setIsClientPreviewVisible(debounceValue);
// 		fetchData()
// 	}, []);

// 	useEffect(() => {
// 		if (initialApiCallMade == true) {
// 			console.log('check clientId', {
// 				mobile: kycStep1.mobileInput.value,
// 				ClientId: kraJourney?.client_id,
// 			})

// 		}

// 	}, [initialApiCallMade]);

// 	const handleCallback = (res) => {
// 		if (!res) {
// 			window.location.reload();
// 		}
// 	};

// 	useEffect(() => {
// 		if (isMobileRefView) {
// 			handleSteps({ index: 0 });
// 		} else if (isPanRefView) {
// 			handleSteps({ index: 1 });
// 		} else if (isAddressRefView) {
// 			handleSteps({ index: 2 });
// 		} else if (isBankRefView) {
// 			handleSteps({ index: 3 });
// 		} else if (isPersonalRefView) {
// 			handleSteps({ index: 4 });
// 		} else if (isOccupationalRefView) {
// 			handleSteps({ index: 5 });
// 		}
// 		else if (isBrokerageRefView) {
// 			handleSteps({ index: 6 });
// 		}
// 		else if (isBrokerageRefView) {
// 			handleSteps({ index: 7 });
// 		}
// 	}, [isMobileRefView, isPanRefView, isAddressRefView, isBankRefView, isPersonalRefView, isOccupationalRefView, isBrokerageRefView]);


// 	const ModalClose = async () => {
// 		try {
// 			const payload = {
// 				UserId: userDetail?.user_id,
// 				PanNo: kraJourney?.pan,
// 				// PanNo: reviewData2?.pan || reviewData2?.PanNo,
// 				roleId: userDetail?.role_id,
// 				rm_journey: 1,
// 				rejection: 0


// 			}
// 			const response = await postApi(payload, '/SaveClientSatus');
// 			if (response?.response) {
// 				setIsModalOpen(false);
// 				setCheckKra(false)
// 			}
// 		}
// 		catch (error) {
// 			setIsModalOpen(false);
// 			setCheckKra(false)
// 		}


// 	}

// 	const copyUrl = (status) => {

// 		const textToCopy = status?.data?.URLL; // Replace with your URL or text

// 		navigator.clipboard.writeText(textToCopy)
// 			.then(() => {
// 				toast('Customer link copied to clipboard!')
// 			})
// 			.catch((err) => {
// 				toast(err)
// 				// setCopySuccess('Copy failed');
// 				// console.error('Unable to copy text: ', err);
// 			});
// 	}

// 	return (
// 		<>
// 			{

// 				deseble ?
// 					<img src={NewLoader} alt="Loading..." style={{
// 						// width: '50%',
// 						height: '100%',
// 						maxWidth: '100%',
// 						display: 'flex',
// 						justifyContent: 'center', alignItems: 'center',
// 						backgroundColor: 'transparent',
// 						textAlign: 'center',
// 						marginRight: 'auto',
// 						marginLeft: 'auto'

// 					}} />
// 					:
// 					reviewData?.ClientId ?
// 						<div className="w-full flex flex-col">
// 							<Modal
// 								open={isModalOpen}
// 								centered={true}
// 								width={400}
// 								closeIcon={() => setIsModalOpen(false)}
// 								footer={null}
// 								className="w-full"
// 							>
// 								<div className=''>
// 									<h6 className=" text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
// 										Congrats!<br></br>
// 										Esign completed successfully.
// 									</h6>
// 									<div className="flex item justify-center gap-x-3 mt-5">
// 										<Link to="/">
// 											<button onDoubleClick={() => {
// 												setTimeout(() => {
// 													setIsModalOpen(false)
// 												}, 3000);
// 											}} className='bg-black text-white px-8 py-2 rounded font-bold'
// 												style={
// 													{ borderRadius: '10px', background: '#007813' }
// 												}
// 											>
// 												{
// 													'Go to Dashboard'
// 												}
// 											</button>
// 										</Link>
// 									</div>
// 								</div>
// 							</Modal>

// 							<Modal
// 								open={checkStatus}
// 								centered={true}
// 								width={700}
// 								onCancel={() => setCheckStatus(false)}
// 								footer={null}
// 								className="w-full"
// 							>
// 							<div className='' style={{width:'95%'}}>
// 									<h6 className="text-center text-1.5xl leading-9 font-medium font-poppinsMedium">
// 										Once you click on the "Equity Esign" button, you won't be able to make any changes to your selfie, signature, and other details. Please verify all the information before clicking on the "Equity Esign" button.
// 									</h6>
// 								</div>
// 								<div className="flex justify-center mt-4">
// 									<Button
// 										type="primary"
// 										style={{ backgroundColor: 'black', borderColor: 'black' }}
// 										onClick={handleEquityEsignBtn}
// 										className="mx-2"
// 									>
// 										Confirm
// 									</Button>
// 									<Button
// 										onClick={() => setCheckStatus(false)}
// 										className="mx-2"
// 									>
// 										Cancel
// 									</Button>
// 								</div>
// 							</Modal>

// 							<div className="flex flex-col md:flex-row items-center justify-between">
// 								<div className="w-full">
// 									<MainTitle title="Review Application Details" />
// 								</div>
// 								<div className="w-full flex items-center justify-center  md:justify-end text-[#848484] font-medium font-poppinsMedium  mb-4 md:mb-0">
// 									<span className="mr-2">Client of</span>
// 									{reviewData?.IsETrade == 90 ? (
// 										<img className="h-5" alt="bigul" src={svgAssets.bigul} />
// 									) : (
// 										<img className="h-8" alt="bigul" src={svgAssets.bonabza} />
// 									)}
// 								</div>
// 							</div>
// 							<div className="px-4 md:px-7 py-8 mb-[35px] rounded-[20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
// 								<ClientDetail
// 									isVerify={false}
// 									isKRA={kycStep2?.isKRA}
// 									reviewData={reviewData || {}}
// 									reviewData2={reviewData2 || {}}
// 									reviewFilesData={reviewFilesData}
// 									clientPhoto={clientPhoto?.DocumentImages || ''}
// 									clientSignature={clientSignature?.DocumentImages || ''}
// 								/>
// 							</div>

// 							{isClientPreviewVisible
// 								? (
// 									<>
// 										<div ref={clientRef} className="sticky top-[80px] z-50">
// 											<div className="px-5 md:px-7 py-3 rounded-[10px_10px_0_0] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
// 												<ClientPreview
// 													reviewData={reviewData || {}}
// 													reviewData2={reviewData2 || {}}
// 													reviewFilesData={reviewFilesData || []}
// 													clientPhoto={clientPhoto?.DocumentImages || ''}
// 													clientSignature={clientSignature?.DocumentImages || ''}
// 												/>
// 											</div>
// 											<div className="pt-5 pb-4 rounded-0 bg-[#E9F1FF]">
// 												<StepReviewBar selectedStep={8} steps={steps} setSteps={setSteps} showStepLabel={true} />
// 											</div>
// 										</div>
// 									</>
// 								)
// 								: (
// 									<>
// 										<div ref={clientRef} className="sticky top-[80px] z-50">
// 											<div className="px-5 md:px-7 py-3 rounded-[10px_10px_0_0] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
// 												<ClientPreview
// 													isKRA={kycStep2?.isKRA}
// 													reviewData={reviewData || {}}
// 													reviewData2={reviewData2 || {}}
// 													reviewFilesData={reviewFilesData || []}
// 													clientPhoto={clientPhoto?.DocumentImages || ''}
// 													clientSignature={clientSignature?.DocumentImages || ''}
// 												/>
// 											</div>
// 											<div className="pt-5 pb-4 rounded-0 bg-[#E9F1FF]">
// 												<StepReviewBar selectedStep={7} steps={steps} setSteps={setSteps} showStepLabel={true} />
// 											</div>
// 										</div>
// 									</>
// 								)
// 							}
// 							<div className="px-5 md:px-7 py-8 rounded-[0_0_20px_20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
// 								<div ref={mobileRef}>
// 									<MobileEmailDetail reviewData={reviewData || {}} reviewData2={reviewData2 || {}} />
// 								</div>
// 								<div ref={panRef}>
// 									<PanDetail kycStep2={kycStep2} isVerify={false} reviewData={reviewData || {}} reviewData2={reviewData2 || {}} panCardPdf={panCardPdf?.DocumentImages || ''} />
// 								</div>
// 								<div ref={addressRef}>
// 									<AddressDetail isVerify={false} reviewData={reviewData || {}} reviewData2={reviewData2 || {}} />
// 									<AddressDetail1
// 										reviewData={reviewData || {}}
// 										reviewData2={reviewData2 || {}}
// 										reviewFilesData={reviewFilesData}
// 									/>
// 								</div>
// 								<div ref={bankRef}>
// 									<BankDetail isVerify={false} pennyData={bankData && bankData || {}} reviewData={reviewData || {}} reviewData2={reviewData2 || {}} />
// 								</div>
// 								<div ref={personalRef}>
// 									<PersonalDetail isVerify={false} kycStep3={kycStep3} reviewData={reviewData || {}} />
// 								</div>
// 								<div ref={occupationalRef}>
// 									<OccupationalDetail isVerify={false} reviewData={reviewData || {}} kycStep4={kycStep4 || {}} />
// 								</div>
// 								<div ref={brokerageRef}>
// 									<BrokerageStep reviewData={reviewData || {}}
// 										reviewData2={reviewData2 || {}} />
// 								</div>
// 								<div>
// 									{
// 										reviewData?.nominee_arr != null &&
// 										<Nominee reviewData={reviewData?.nominee_arr} />
// 									}

// 								</div>
// 								{income_proof && !salary1.length > 0 &&
// 									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
// 										<div className="text-lg font-medium font-poppinsMedium">Income Proof - Documents Uploaded</div>
// 										<div
// 											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
// 										>
// 											<a href={income_proof?.ImagePath} target='_blank'>
// 												<div className="flex items-center justify-center">
// 													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
// 													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
// 												</div>
// 											</a>
// 										</div>
// 									</div>

// 								}
// 								{salary1.length > 0 &&
// 									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
// 										<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip1</div>
// 										<div
// 											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
// 										>
// 											<a href={salary1[0]?.ImagePath} target='_blank'>
// 												<div className="flex items-center justify-center">
// 													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
// 													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
// 												</div>
// 											</a>
// 										</div>
// 									</div>

// 								}
// 								{salary1.length > 0 &&
// 									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
// 										<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip 2</div>
// 										<div
// 											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
// 										>
// 											<a href={salary1[1]?.ImagePath} target='_blank'>
// 												<div className="flex items-center justify-center">
// 													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
// 													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
// 												</div>
// 											</a>
// 										</div>
// 									</div>
// 								}
// 								{salary1.length > 0 &&
// 									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
// 										<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip 3</div>
// 										<div
// 											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
// 										>
// 											<a href={salary1[2]?.ImagePath} target='_blank'>
// 												<div className="flex items-center justify-center">
// 													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
// 													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
// 												</div>
// 											</a>
// 										</div>
// 									</div>

// 								}
// 								{BankProof &&
// 									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
// 										<div className="text-lg font-medium font-poppinsMedium">Bank Proof Document</div>
// 										<div
// 											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
// 										>
// 											<a href={BankProof.ImagePath} target='_blank'>
// 												<div className="flex items-center justify-center">
// 													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
// 													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
// 												</div>
// 											</a>
// 										</div>
// 									</div>
// 								}
// 								{other &&
// 									<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
// 										<div className="text-lg font-medium font-poppinsMedium">Penny Drop Document</div>
// 										<div
// 											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
// 										>
// 											<a href={other.ImagePath} target='_blank'>
// 												<div className="flex items-center justify-center">
// 													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
// 													<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
// 												</div>
// 											</a>
// 										</div>
// 									</div>
// 								}
// 							</div>
// 							<div className="mt-10 flex justify-end mb-6">

// 								<SendESignBtn handleBtn={handleCheck} name={"Check eSign Status"} color={'#0C82F7 '} />


// 								<SendESignBtn handleBtn={handleViewPDF} name={"View KYC form before eSign"} />

// 								{

// 									<SendESignBtn handleBtn={() => setCheckStatus(!checkStatus)} name={'Equity eSign'} isDisabled={deseble} />
// 								}

// 								<SendESignBtn handleBtn={handleSendEsignLinkBtn} name='Send Aadhar e-sign link' successName='Successfully Send Aadhar e-sign link' isSuccess={eSignLinkRes.success} isDisabled={eSignLinkRes.success} />
// 							</div>
// 							<div className="flex justify-end ">
// 								{eSignLinkRes?.success &&
// 									<div className="bg-[#E0FFE5]  p-4 rounded my-5 ">
// 										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
// 											<p>Status :</p>
// 											<p className={eSignLinkRes?.success ? 'text-[#007813]' : 'text-[#000]'}>{eSignLinkRes.message}</p>
// 										</div>
// 										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
// 											<p>Registered Mobile Number :</p>
// 											<p className={eSignLinkRes?.success ? 'text-[#007813]' : 'text-[#000]'}>{eSignLinkRes.data?.mobile}</p>
// 										</div>
// 										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2 mt-4">
// 											<p><button onClick={handleSendEsignLinkBtn} className='bg-black px-5 py-1 text-white rounded'>Resend eSign Link </button></p>
// 										</div>
// 										<div className="flex"></div>
// 									</div>}
// 							</div>
// 							<div className="flex justify-end mb-6">
// 								{euitySignRes && euitySignRes?.message &&
// 									<div className="bg-[#E0FFE5]  p-4 rounded">
// 										{
// 											euitySignRes?.success &&
// 											<button style={{ maxWidth: '175px', justifyContent: 'flex-end' }}
// 												className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
// 												onClick={() => { copyUrl(euitySignRes) }}
// 											>
// 												{/* <img src={copy} width={'15px'} /> */}
// 												<span className='ml-2'>Copy eSign link</span>
// 											</button>
// 										}

// 										{/* <div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
// 							<p>Status :</p>
// 							<p className={euitySignRes?.success ? 'text-[#007813]' : 'text-[#F30000]'}>{euitySignRes?.success ? 'Success' : "Failed"}</p>
// 						</div> */}
// 										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
// 											<p>Message :</p>
// 											<p className={euitySignRes?.success ? 'text-[#007813]' : 'text-[#F30000]'}>{euitySignRes?.message}</p>
// 										</div>
// 										<div className="flex"></div>
// 									</div>}
// 							</div>

// 						</div>
// 						:

// 						<h1>Please wait..... data fetching</h1>
// 			}
// 		</>

// 	);
// };

