export const getReviewApplication = 'GET_REVIEW_APPLICATION';
export const setReviewApplication = 'SET_REVIEW_APPLICATION';
export const setReviewApplication2 = 'SET_REVIEW2_APPLICATION';
export const getClientFiles = 'GET_CLIENT_FILES';
export const setClientFiles = 'SET_CLIENT_FILES';
export const sendEquityEsign = 'SEND_EQUITY_ESIGN';
export const getEquityEsignRes = 'GET_EQUITY_ESIGN_RES';
export const sendEquityEsignLink = 'SEND_EQUITY_ESIGN_Link';
export const getEquityEsignLinkRes = 'GET_EQUITY_ESIGN_Link_RES';


export const getReviewApplicationAction = (payload , callback) => ({
	type: getReviewApplication,
	payload,
	callback
});

export const setReviewApplicationAction = (payload , callback) => ({
	type: setReviewApplication, 
	payload,
	callback
});

export const setReviewApplication2Action = (payload) => ({
	type: setReviewApplication2,
	payload
});

export const getClientFilesAction = (payload , callback) => ({
	type: getClientFiles,
	payload,
	callback
});

export const setClientFilesAction = (payload) => ({
	type: setClientFiles,
	payload
});

export const sendEquityEsignLinkAction = (payload) => ({
	type: sendEquityEsignLink,
	payload
});

export const getEquityEsignLinkResAction = (payload) => ({
	type: getEquityEsignLinkRes,
	payload
});

export const sendEquityEsignAction = (payload,callback) => ({
	type: sendEquityEsign,
	payload,
	callback
});

export const getEquityEsignResAction = (payload) => ({
	type: getEquityEsignRes,
	payload
});