import { clearKyc } from '../../actions/kyc/kyc.action';
import {
	changeStep5Error, changeStep5Input, setBankInfo,
	setClearBankInfo, setSearchBankInfo, saveIfscCode, setIsVerifyBankDetails,
	setBanksNameList, setBranchNameList, setIfscCode
} from '../../actions/kyc/step5.action';

const mutableState = {
	ifsc: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	bankName: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	cityName: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	branchName: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		options: []
	},
	bankAccountNo: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	confirmBankAccountNo: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	bankNamesList: [],
	branchNamesList: [],
	isVerifyBank: false,
	verifyBankDetails: {
		verified: 'pending'
	},
	fetchBankData: {},
	searchBankData: [],
	isSearch: false,
	status: ''
};

const initState = {
	...mutableState
};

export const kycStep5Reducer = (state = initState, action) => {
	const { type, value, isError, errorText } = action?.payload || {};
	switch (action.type) {
		case clearKyc:
			return {
				...mutableState
			};
		case changeStep5Input:
			return {
				...state,
				[type]: {
					...state[type],
					value,
					isError: false,
					errorText: ''
				}
			};
		case changeStep5Error:
			return {
				...state,
				[type]: {
					...state[type],
					isError,
					errorText
				}
			};
		case setBankInfo:
			return {
				...state,
				fetchBankData: action.payload,
			};
		case setIsVerifyBankDetails:
			return {
				...state,
				isVerifyBank: action.payload?.verified || false,
				verifyBankDetails: action.payload,
			};
		case setClearBankInfo:
			return {
				...state,
				fetchBankData: {},
			};
		case setSearchBankInfo:
			return {
				...state,
				searchBankData: action.payload,
			};
		case saveIfscCode:
			return {
				...state,
				ifsc: {
					...state.ifsc,
					value: action?.payload,
				},
			};
		case setBanksNameList:
			return {
				...state,
				bankNamesList: action?.payload,
			};
		case setBranchNameList:
			return {
				...state,
				branchNamesList: action?.payload,
			};
		case setIfscCode:
			return {
				...state,
				ifsc: {
					...state.ifsc,
					value: action?.payload?.ifsc_code,
				},
			};

		// case setKRA:
		// 	return {	
		// 		...state,
		// 		ifsc: {
		// 			...state.ifsc,
		// 			value: action.payload.data.BankIFSC
		// 		},
		// 		bankAccountNo: {
		// 			...state.bankAccountNo,
		// 			value: action.payload.data.BankAccountNumber
		// 		},
		// 		confirmBankAccountNo: {
		// 			...state.confirmBankAccountNo,
		// 			value: action.payload.data.BankAccountNumber
		// 		}
		// 	};
		default:
			return state;
	}
};
