import { useSelector } from "react-redux";

export const ClientPreview = ({ reviewData, reviewData2,reviewFilesData, clientPhoto, isKRA,pennyData }) => {
	const user_doc = reviewFilesData.length > 0 && reviewFilesData?.filter(a => (a.DocumentType === 'Client Photo') || (a.DocumentType === 'Client photo'));
	const lastItemIndex = user_doc?.length - 1; 
	const {verifyBankDetails} = useSelector(state => state?.kycStep5 || {});
	const user_doc2 = reviewFilesData.length > 0 && reviewFilesData?.filter(a => (a.DocumentType === 'Client Signature'));
	const lastItemIndex2 = user_doc?.length - 1;
	const FName= reviewData?.FName !== null ?reviewData?.FName:''
	const LName= reviewData?.LName !== null ?reviewData?.LName :''
	const MName= reviewData?.MName !== null ?reviewData?.MName :''
	
	const name =  FName+' '+ MName+' ' + LName
	
	return (
		<div className="md:grid-cols-4 gap-2.5 grid w-full overflow-scroll md:overflow-auto">
			<div className="flex md:flex-col  md:items-center gap-x-4">
				<div className="mb-1 text-xs text-[#90A5B5] md:text-center font-medium font-poppinsMedium w-[50%] md:w-auto">
					Name As On PAN
				</div>
				<h6 className="text-center font-poppinsRegular">{name|| reviewData2.name_on_pan || ''}</h6>
			</div>
			{/* <div className="flex md:flex-col items-center gap-x-5">
				<div className="mb-1 text-xs text-[#90A5B5] text-center font-medium font-poppinsMedium whitespace-nowrap">
					Name As On Aadhar
				</div>
				<h6 className="text-center font-poppinsRegular whitespace-nowrap">{reviewData?.name_on_aadhaar || reviewData2.name_on_aadhaar || ''}</h6>
			</div> */}
			{/* <div className="flex md:flex-col  items-center gap-x-5">
				<div className="mb-1 text-xs text-[#90A5B5] text-center font-medium font-poppinsMedium">
					E-Signer Name
				</div>
				<h6 className="text-center font-poppinsRegular">{reviewData?.name || reviewData?.FName || reviewData2.name || ''}</h6>
				<h6 className="text-center font-poppinsRegular">{'-'}</h6>
			</div> */}
			{/* <div className="flex md:flex-col  items-center gap-x-5">
				<div className="mb-1 text-xs text-[#90A5B5] text-center font-medium font-poppinsMedium">
					Name On Bank Account
				</div>
				<h6 className="text-center font-poppinsRegular">{verifyBankDetails?.beneficiary_name_with_bank||'-'}</h6>
			</div> */}
			<div className="flex md:flex-col  items-center gap-x-5">
				<div className="mb-1 text-xs text-[#90A5B5] text-center font-medium font-poppinsMedium">
					Bank Verification Status
				</div>
				<h6 className="text-center font-poppinsRegular">{reviewData2.penny_drop_status == 1 ? 'Verified' : 'Not verified'}</h6>
			</div>
			<div className="flex md:flex-col  items-center gap-x-5"> 
				<div className="mb-1 text-xs text-[#90A5B5] text-center font-medium font-poppinsMedium">
					Source 
				</div>
				<h6 className="text-center font-poppinsRegular">{reviewData2?.kra_status == 1 ? 'KRA' : 'DIGIO'}</h6>
			</div>
			<div className="flex gap-x-5 justify-center mt-5 md:mt-0">
				{user_doc && user_doc?.map((item, index) => {
					const isLastItem = index === lastItemIndex;
					return (
						<>
							{isLastItem &&
								<div key={index}>
									<img className="w-[150px] max-h-[55px] object-contain" src={item?.ImagePath} />
								</div>}
						</>
					);
				})}
				{user_doc2 && user_doc2?.map((item, index) => {
					const isLastItem2 = index === lastItemIndex2;
					return (
						<>
							{isLastItem2 &&
								<div key={index}>
									<img className="w-[150px] max-h-[55px] object-contain" src={item?.ImagePath} />
								</div>}
						</>
					);
				})}
				
			</div>
		</div>
	);
};
