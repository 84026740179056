export const changeStep3Input = 'CHANGE_STEP3_INPUT';
export const changeStep3Error = 'CHANGE_STEP3_ERROR';
export const setPersonalDetail = 'SET_PERSONAL_DETAIL';
export const setAddressDetail = 'SET_ADDRESS_DETAIL';

export const changeStep3InputAction = (payload) => ({ 
	type: changeStep3Input,
	payload
});

export const changeStep3ErrorAction = (payload) => ({ 
	type: changeStep3Error,
	payload
});

export const setPersonalDetailAction = (payload, callback) => ({
	type: setPersonalDetail,
	payload,
	callback
});
export const setAddressDetailAction = (payload, callback) => ({
	type: setAddressDetail,
	payload,
	callback
});
