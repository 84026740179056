import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep1Error, changeStep1Input, changeStep1Status, changeStep1MobileOtpStatus, changeStep1EmailOtpStatus } from '../../actions/kyc/step1.action';

const mutableState = {
	mobileInput: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	mobileOtp: {
		value: '000000',
		isError: false,
		errorText: '',
		status: 'pending',
		resend: false
	},
	verifyMobileOtp: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	emailInput: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	emailOtp: {
		value: '000000',
		isError: false,
		errorText: '',
		status: 'pending',
		resend: false
	},
	status: ''
};

const initState = {
	...mutableState
};

export const kycStep1Reducer = (state = initState, action) => {
	const { type, type1, type2, value, value1, value2, value3, isError, errorText } = action?.payload || {};
	switch (action.type) {
		case clearKyc:
			return {
				...mutableState
			};
		case changeStep1Input:
			return {
				...state,
				[type]: {
					...state[type],
					value,
					isError: false,
					errorText: '',
				}
			};
		case changeStep1Status:
			console.log("changeStep1Status",action.payload)
			return {
				...state,
				[type1]: {
					...state[type1],
					status: value1,
					isError: false,
					errorText: '',
					resend: value3
				},
				[type2]: {
					...state[type2],
					status: value2,
					resend: value3
				}
			};
		case changeStep1MobileOtpStatus:
			return {
				...state,
				[type]: {
					...state[type],
					value: action.payload.value,
					isError: false,
					errorText: '',
				}
			};
		case changeStep1EmailOtpStatus: 
			return {
				...state,
				[type]: {
					...state[type],
					value: action.payload.value,
					isError: false,
					errorText: '',
				}
			};
		case changeStep1Error:
			return {
				...state,
				[type]: {
					...state[type],
					isError,
					errorText
				}
			};
		default:
			return state;
	}
};
