import {
	setPanNumber, isBankProofUploaded, isIncomeProofUploaded,
	isSelfieUploaded, isSignatureUploaded
} from '../../actions/dkyc/dkyc.action';

const mutableState = {
	userDetails: {},
	tokenErr: '',
	isBankProofUploaded: {
		success: false
	},
	isIncomeProofUploaded: {
		success: false
	},
	isSelfieUploaded: {
		success: false
	},
	isSignatureUploaded: {
		success: false
	},
}
const initState = {
	...mutableState
};

export const dkycReducer = (state = initState, action) => {
	switch (action.type) {
		case setPanNumber:
			return {
				...state,
				userDetails: action?.payload?.data?.data || {},
				tokenErr: action?.payload?.data?.message || ''
			};

		case isBankProofUploaded:
			return {
				...state,
				isBankProofUploaded: action?.payload
			};
		case isIncomeProofUploaded:
			return {
				...state,
				isIncomeProofUploaded: action?.payload
			};
		case isSelfieUploaded:
			return {
				...state,
				isSelfieUploaded: action?.payload
			};
		case isSignatureUploaded:
			return {
				...state,
				isSignatureUploaded: action?.payload
			};
		default:
			return state;
	}
};
