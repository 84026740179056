import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep6Error, changeStep6Input, setBrokerageSchemeDetail, setBrokerageSchemeDataDetail, setDeclarationList, setDPTariffDetail, setIncomeProofList, setTransactionDetail, updateDeclarationValue, updateSegmentValue, updateTermsAndConditionValue, updateTransactionValue, uploadBankProof, saveDeclarationInfoData, sendUploadIncomeProofLink, setBrokerageSchemeList, segmentsValue, uploadSalarySlip } from '../../actions/kyc/step6.action';

const mutableState = {
	transactionData: {
		value: [],
		isError: false,
		errorText: '',
		status: 'edit',
		data: []
	},
	segments: {
		equity: {
			isDisable: false,
			isSelected: true,
			value: ['NSE', 'BSE']
		},
		commodity: {
			isDisable: true,
			isSelected: false,
			value: ['NCDX']
		},
		derivative: {
			isDisable: true,
			isSelected: false,
			value: ['NSEF', 'NSEC', 'BSEF', 'BSEC', 'MCX']
		},

		mutualFund: {
			isDisable: true,
			isSelected: false,
			value: ['NCDX']
		},
		mutualFund1: {
			isDisable: true,
			isSelected: false,
			value: ['MF']
		}
	},
	brokerageScheme: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		data: [],
		scheme_details: [],
		scheme_list: [],
	},
	dpTariff: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		data: []
	},
	declaration: {
		value: [],
		isError: false,
		errorText: '',
		status: 'edit',
		data: []
	},
	incomeProof: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		isSendLink: false,
		selectedDocument: [],
		data: [],
		salarySlip:[]
	},
	termsAndCondition: {
		value: false,
		isError: false,
		errorText: '',
		status: 'edit'
	},
	declarationInfoData: {},
	status: '',
	segmentVal: ''
};

const initState = {
	...mutableState
};

export const kycStep6Reducer = (state = initState, action) => {
	const { type, value, isError, errorText } = action?.payload || {};
	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
	switch (action.type) {
		case clearKyc:
			return {
				...mutableState
			};
		case changeStep6Input:
			return {
				...state,
				[type]: {
					...state[type],
					value,
					isError: false,
					errorText: ''
				}
			};
		case changeStep6Error:
			return {
				...state,
				[type]: {
					...state[type],
					isError,
					errorText
				}
			};
		case setTransactionDetail:
			return {
				...state,
				transactionData: {
					...state.transactionData,
					data: action.payload.data,
					value: action.payload.data
				}
			};
		case updateTransactionValue:
			return {
				...state,
				transactionData: {
					...state.transactionData,
					value: action.payload
				}
			};
		case updateSegmentValue:
			return {
				...state,
				segments: {
					...state.segments,
					[value]: {
						...state.segments[value],
						isSelected: action.payload.isChecked
					}
				}
			};
		case setBrokerageSchemeDetail:
			return {
				...state,
				brokerageScheme: {
					...state.brokerageScheme,
					data: action.payload.data
				}
			};
		case setBrokerageSchemeDataDetail:
			return {
				...state,
				brokerageScheme: {
					...state.brokerageScheme,
					scheme_details: action.payload.data
				}
			};
		case setDPTariffDetail:
			return {
				...state,
				dpTariff: {
					...state.dpTariff,
					data: action.payload.data
				}
			};
		case setDeclarationList:
			return {
				...state,
				declaration: {
					...state.declaration,
					data: action.payload.data
				}
			};
		case updateDeclarationValue:
			return {
				...state,
				declaration: {
					...state.declaration,
					data: action.payload.data
				}
			};
		case setIncomeProofList:
			return {
				...state,
				incomeProof: {
					...state.incomeProof,
					data: action.payload.data
				}
			};
		case sendUploadIncomeProofLink:
			return {
				...state,
				incomeProof: {
					...state.incomeProof,
					isSendLink: action.payload
				}
			};
		case uploadBankProof:
			console.log("uploadSalarySlip",imageUrl)
			return {
				...state,
				incomeProof: {
					...state.incomeProof,
					selectedDocument: state?.incomeProof?.value === 'Latest 3 Month Salary Slip'
						? [...state?.incomeProof?.selectedDocument, imageUrl]
						: [imageUrl],
					isError: false,
					errorText: ''
				}
			};
		// case uploadSalarySlip:
		// 	console.log("uploadSalarySlip",imageUrl)
		// 	return {
		// 		...state,
		// 		incomeProof: {
		// 			...state.incomeProof,
		// 			// selectedDocument: state.incomeProof.value === 'Latest 3 Month Salary Slip'
		// 			// 	? [...action.payload.state.incomeProof, imageUrl]
		// 			// 	: [imageUrl],
		// 			salarySlip: [...state?.incomeProof?.salarySlip, imageUrl],
		// 			isError: false,
		// 			errorText: ''
		// 		}
		// 	};
		case updateTermsAndConditionValue:
			return {
				...state,
				termsAndCondition: {
					...state.termsAndCondition,
					value: action.payload.data
				}
			};
		case saveDeclarationInfoData:
			return {
				...state,
				declarationInfoData: action.payload.data
			};
		case setBrokerageSchemeList:
			return {
				...state,
				brokerageScheme: {
					...state.brokerageScheme,
					scheme_list: action?.payload?.data
				}
			};
		case segmentsValue:
			return {
				...state,
				segmentVal: action?.payload?.value
			};
		// case setKRA:
		// 	return {
		// 		...state,
		// 		segments: {
		// 			...state.segments,
		// 			derivative: {
		// 				...state.segments.derivative,
		// 				isSelected: action.payload.data.Derivative !== ''
		// 			},
		// 			commodity: {
		// 				...state.segments.commodity,
		// 				isSelected: action.payload.data.Commodity !== ''
		// 			}
		// 		},
		// 		brokerageScheme: {
		// 			...state.brokerageScheme,
		// 			value: action.payload.data.McxScheme
		// 		},
		// 		dpTariff: {
		// 			...state.dpTariff,
		// 			value: action.payload.data.DPTariff
		// 		}
		// 	};
		default:
			return state;
	}
}; 

// import { clearKyc } from '../../actions/kyc/kyc.action';
// import { changeStep6Error, changeStep6Input, setBrokerageSchemeDetail, setBrokerageSchemeDataDetail, setDeclarationList, setDPTariffDetail, setIncomeProofList, setTransactionDetail, updateDeclarationValue, updateSegmentValue, updateTermsAndConditionValue, updateTransactionValue, uploadBankProof, saveDeclarationInfoData, sendUploadIncomeProofLink, setBrokerageSchemeList, segmentsValue, uploadSalarySlip, setDPTariffDetail1 } from '../../actions/kyc/step6.action';

// const mutableState = {
// 	transactionData: {
// 		value: [],
// 		isError: false,
// 		errorText: '',
// 		status: 'edit',
// 		data: []
// 	},
// 	segments: {
// 		equity: {
// 			isDisable: false,
// 			isSelected: true,
// 			value: ['NSE', 'BSE']
// 		},
// 		commodity: {
// 			isDisable: false,
// 			isSelected: false,
// 			value: ['NCDX']
// 		},
// 		derivative: {
// 			isDisable: true,
// 			isSelected: true,
// 			value: ['NSEF', 'NSEC', 'BSEF', 'BSEC', 'MCX']
// 		},

// 		mutualFund: {
// 			isDisable: true,
// 			isSelected: false,
// 			value: ['NCDX']
// 		}
// 	},
// 	brokerageScheme: {
// 		value: '',
// 		isError: false,
// 		errorText: '',
// 		status: 'edit',
// 		data: [],
// 		scheme_details: [],
// 		scheme_list: [],
// 	},
// 	dpTariff: {
// 		value: '',
// 		isError: false,
// 		errorText: '',
// 		status: 'edit',
// 		data: []
// 	},
// 	delevery: {
// 		value: '',
// 		isError: false,
// 		errorText: '',
// 		status: 'edit',
// 		data: []
// 	},
// 	declaration: {
// 		value: [],
// 		isError: false,
// 		errorText: '',
// 		status: 'edit',
// 		data: []
// 	},
// 	incomeProof: {
// 		value: '',
// 		isError: false,
// 		errorText: '',
// 		status: 'edit',
// 		isSendLink: false,
// 		selectedDocument: [],
// 		data: [],
// 		salarySlip:[]
// 	},
// 	termsAndCondition: {
// 		value: false,
// 		isError: false,
// 		errorText: '',
// 		status: 'edit'
// 	},
// 	declarationInfoData: {},
// 	status: '',
// 	segmentVal: ''
// };

// const initState = {
// 	...mutableState
// };

// export const kycStep6Reducer = (state = initState, action) => {
// 	const { type, value, isError, errorText } = action?.payload || {};
// 	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
// 	switch (action.type) {
// 		case clearKyc:
// 			return {
// 				...mutableState
// 			};
// 		case changeStep6Input:
// 			return {
// 				...state,
// 				[type]: {
// 					...state[type],
// 					value,
// 					isError: false,
// 					errorText: ''
// 				}
// 			};
// 		case changeStep6Error:
// 			return {
// 				...state,
// 				[type]: {
// 					...state[type],
// 					isError,
// 					errorText
// 				}
// 			};
// 		case setTransactionDetail:
// 			return {
// 				...state,
// 				transactionData: {
// 					...state.transactionData,
// 					data: action.payload.data,
// 					value: action.payload.data
// 				}
// 			};
// 		case updateTransactionValue:
// 			return {
// 				...state,
// 				transactionData: {
// 					...state.transactionData,
// 					value: action.payload
// 				}
// 			};
// 		case updateSegmentValue:
// 			return {
// 				...state,
// 				segments: {
// 					...state.segments,
// 					[value]: {
// 						...state.segments[value],
// 						isSelected: action.payload.isChecked
// 					}
// 				}
// 			};
// 		case setBrokerageSchemeDetail:
// 			return {
// 				...state,
// 				brokerageScheme: {
// 					...state.brokerageScheme,
// 					data: action.payload.data
// 				}
// 			};
// 		case setBrokerageSchemeDataDetail:
// 			return {
// 				...state,
// 				brokerageScheme: {
// 					...state.brokerageScheme,
// 					scheme_details: action.payload.data
// 				}
// 			};
// 		case setDPTariffDetail:
// 			return {
// 				...state,
// 				dpTariff: {
// 					...state.dpTariff,
// 					data: action.payload.data
// 				}
// 			};
// 		case setDPTariffDetail1:
// 			return {
// 				...state,
// 				delevery: {
// 					...state.delevery,
// 					data: action.payload.data
// 				}
// 			};
// 		case setDeclarationList:
// 			return {
// 				...state,
// 				declaration: {
// 					...state.declaration,
// 					data: action.payload.data
// 				}
// 			};
// 		case updateDeclarationValue:
// 			return {
// 				...state,
// 				declaration: {
// 					...state.declaration,
// 					data: action.payload.data
// 				}
// 			};
// 		case setIncomeProofList:
// 			return {
// 				...state,
// 				incomeProof: {
// 					...state.incomeProof,
// 					data: action.payload.data
// 				}
// 			};
// 		case sendUploadIncomeProofLink:
// 			return {
// 				...state,
// 				incomeProof: {
// 					...state.incomeProof,
// 					isSendLink: action.payload
// 				}
// 			};
// 		case uploadBankProof:
// 			console.log("uploadSalarySlip",imageUrl)
// 			return {
// 				...state,
// 				incomeProof: {
// 					...state.incomeProof,
// 					selectedDocument: state?.incomeProof?.value === 'Latest 3 Month Salary Slip'
// 						? [...state?.incomeProof?.selectedDocument, imageUrl]
// 						: [imageUrl],
// 					isError: false,
// 					errorText: ''
// 				}
// 			};
// 		// case uploadSalarySlip:
// 		// 	console.log("uploadSalarySlip",imageUrl)
// 		// 	return {
// 		// 		...state,
// 		// 		incomeProof: {
// 		// 			...state.incomeProof,
// 		// 			// selectedDocument: state.incomeProof.value === 'Latest 3 Month Salary Slip'
// 		// 			// 	? [...action.payload.state.incomeProof, imageUrl]
// 		// 			// 	: [imageUrl],
// 		// 			salarySlip: [...state?.incomeProof?.salarySlip, imageUrl],
// 		// 			isError: false,
// 		// 			errorText: ''
// 		// 		}
// 		// 	};
// 		case updateTermsAndConditionValue:
// 			return {
// 				...state,
// 				termsAndCondition: {
// 					...state.termsAndCondition,
// 					value: action.payload.data
// 				}
// 			};
// 		case saveDeclarationInfoData:
// 			return {
// 				...state,
// 				declarationInfoData: action.payload.data
// 			};
// 		case setBrokerageSchemeList:
// 			return {
// 				...state,
// 				brokerageScheme: {
// 					...state.brokerageScheme,
// 					scheme_list: action?.payload?.data
// 				}
// 			};
// 		case segmentsValue:
// 			return {
// 				...state,
// 				segmentVal: action?.payload?.value
// 			};
// 		// case setKRA:
// 		// 	return {
// 		// 		...state,
// 		// 		segments: {
// 		// 			...state.segments,
// 		// 			derivative: {
// 		// 				...state.segments.derivative,
// 		// 				isSelected: action.payload.data.Derivative !== ''
// 		// 			},
// 		// 			commodity: {
// 		// 				...state.segments.commodity,
// 		// 				isSelected: action.payload.data.Commodity !== ''
// 		// 			}
// 		// 		},
// 		// 		brokerageScheme: {
// 		// 			...state.brokerageScheme,
// 		// 			value: action.payload.data.McxScheme
// 		// 		},
// 		// 		dpTariff: {
// 		// 			...state.dpTariff,
// 		// 			value: action.payload.data.DPTariff
// 		// 		}
// 		// 	};
// 		default:
// 			return state;
// 	}
// };
