import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { svgAssets } from '../../assets/asset';
import { BaseLogin } from '../../components/login/BaseLogin';
import { changeLoginErrorAction, changeLoginInputAction, forgotPasswordAction } from '../../redux/actions/auth.action';
import { validateUserId } from '../../utils/verifyInput';
import { toast } from 'react-toastify';

export const ForgotPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(state => state?.user || {});

	const handleInput = ({ type, value }) => {
		dispatch(changeLoginInputAction({ type, value }));
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeLoginErrorAction({ type, isError, errorText }));
	};

	const loginApiCallback = ({ status, success, message }) => {
		if (status === 200 && success) {
			toast(message)
			setTimeout(() => {
				navigate('/login');
			}, 3000);
		}

		// if (status === 200 && success) {
		// 	// toast(message)
		// 	navigate(`/reset-password/${'directclient'}`);
		// }
		 else {
			alert(message || 'invalid credentials!');
		}
	};

	const handleSubmit = () => {
		const userId = validateUserId(user.userId.value || '');

		if (!userId) {
			handleInputError({ type: 'userId', isError: true, errorText: 'Please enter a valid user Id' });
		} else {
			dispatch(forgotPasswordAction({
				UserName: user.userId.value
			}, loginApiCallback));
		}
	};

	return (
		<BaseLogin>
			<div className="w-full max-h-max mt-[76px] flex flex-col justify-center">
				<h6 className="mb-7 text-black text-2xl capitalize font-semibold font-poppinsSemiBold">Forgot Password</h6>
				<div className="mb-16">
					<div className="flex flex-col mb-8">
						<label className="mb-3 flex items-center leading-6 font-medium font-poppinsMedium">
							<img
								className="max-h-[22px] mr-1.5 object-contain"
								alt="input_icon"
								src={svgAssets.user}
							/>
							<span className="mr-1">User Id</span>
							<span className="text-[#FF4343]">*</span>
						</label>
						<input
							className="h-[56px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
							placeholder="Please enter user id"
							value={user.userId.value}
							onChange={(e) => handleInput({ type: 'userId', value: e.target.value })}
							autoFocus={true}
						/>
						{
							user.userId.isError && (
								<span className="mt-2 text-[12px] text-red-400 font-medium font-poppinsMedium">
									{user.userId.errorText}
								</span>)
						}
					</div>
				</div>
				<div className="flex justify-center">
					<button
						className="w-full md:w-min px-9 py-3 flex items-center justify-center rounded-[10px] text-white bg-[#5367FC] active:bg-[#3d54fc] transition duration-150 ease-in-out"
						onClick={() => handleSubmit()}
					>
						<img className="mr-2.5" alt="login_screen_enter_img" src={svgAssets.login.loginEnter}></img>
						<span className="font-poppinsRegular">Send</span>
					</button>
				</div>
			</div>
		</BaseLogin>
	);
};
