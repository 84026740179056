
export const Checkbox = ({ value, isChecked, isDisable, handleCheckbox, labelText, labelWidth, labelTextColor, labelSize, labelWeight, labelFamily, marginBottom }) => {
	return (
		<label className={`inline-flex items-start
			${marginBottom || 'mb-6'}
		`}>
			<input
				type="checkbox"
				className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500"
				checked={isChecked}
				onChange={() => handleCheckbox({ isChecked, value })}
				disabled={isDisable}
			/>
			<span
				className={`
					ml-3 inline-block
					${labelSize || 'text-xs'}
					${labelWeight || ''}
					${labelFamily || 'font-poppinsRegular'}
					${labelWidth || 'w-full'}
					${labelTextColor || 'text-[#323232]'}
				`}
			>
				{labelText}
			</span>
		</label>
	);
};
