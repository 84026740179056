import React, { useState } from 'react';
import { Hadding } from './Hadding';
import { Hadding2 } from './Hadding2';
import { Hadding3 } from './Hadding3';
import { Hadding4 } from './Hadding4';
import { Hadding5 } from './Hadding5';


export const FAQ = () => {
    return (
        <div >
            <Hadding />
            <Hadding2 />
            <Hadding3 />
            <Hadding4 />
            <Hadding5 />
            <div style={{ marginBottom: '20px' }}></div>
        </div>
    );
};


