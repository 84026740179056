import { useDispatch } from 'react-redux';
import { Checkbox } from '../../../components/common/Checkbox';
import { updateDeclarationValueAction } from '../../../redux/actions/kyc/step6.action';
import { useState } from 'react';
import { Modal } from 'antd';

export const Declaration = ({ declarationList }) => {
	const [check, setCheck] = useState(false)
	console.log(declarationList);

	const dispatch = useDispatch();
	const handleDeclaration = ({ isChecked, value, selectedOption }) => {
		const declarationData = [...declarationList];
		if (selectedOption) {
			declarationData[value].selectedOption = selectedOption;
		} else {
			declarationData[value].isSelected = !isChecked;
			if (declarationData[value].label === "I am not politically exposed or related" && isChecked) {
				setCheck(true)
			}
		}
		dispatch(updateDeclarationValueAction({ data: declarationData }));
	};

	const modalToggle = () => {
		setCheck(false); // Close the modal
		// const updatedDeclarationData = declarationList.map(declaration => {
		// 	if (declaration.label == "I am not politically exposed or related") {
		// 		return { ...declaration, isSelected: true }; // Uncheck the checkbox
		// 	}
		// 	return declaration;
		// });
		// // setDeclarationData(updatedDeclarationData);
		// dispatch(updateDeclarationValueAction({ data: updatedDeclarationData }));
	};
	return (
		<div className="grid md:grid-cols-3 gap-x-10">
			<Modal
				title=""
				className="w-full p-[33px]"
				width={600}
				open={check}
				centered={true}
				onCancel={() => modalToggle()}
				footer={null}
			>
				<div className="mt-2 mb-1.5 flex flex-col items-center">

					<div className="mb-[5px] text-xl font-medium font-poppinsMedium">
						{declarationList[10]?.description}

						<div onClick={()=>{ modalToggle()}}
							style={{backgroundColor:'black',padding:'10px',borderRadius:'10px',cursor: 'pointer',width:'50px',display:'flex',justifyContent:'center',alignItems:'center',marginTop:30,marginLeft:'auto',marginRight:'auto'}}>
                <p style={{color:'white'}}>Ok</p>
                </div>
						{/* {'Online account opening is not allowed for Political Exposed Person. Our Relationship Manager will connect you for opening the account. In case if you have checked the option by mistake, uncheck and proceed with the opening journey'} */}
					</div>
					

				</div>
			</Modal>
			{
				declarationList?.length > 0
					? declarationList.map((el1, i1) => (
						<div
							key={`declaration_${i1}`}
							className="flex flex-col mb-6"
						>
							<Checkbox
								key={`declaration_${i1}`}
								labelText={el1.label}
								value={i1}
								isChecked={el1.isSelected}
								handleCheckbox={handleDeclaration}

							/>
							{
								el1?.options?.length > 0 &&
								<div className="flex items-center md:ml-[32px] flex-wrap">
									{
										el1.options.map((el2, i2) => (
											<div key={`declaration_${i2}`}>
												<label className={`inline-flex items-center ${i2 !== 0 && 'ml-[18px]'}`}>
													<input
														type="radio"
														className="w-4 h-4 accent-blue-500"
														checked={el2.label === el1.selectedOption}
														onChange={() => handleDeclaration({
															value: i1,
															selectedOption: el2.label
														})}
													/>
													<span
														className="ml-1.5 text-xs"
													>
														{el2.label}
													</span>
												</label>
											</div>
										))
									}
								</div>
							}
						</div>
					))
					: null
			}
		</div>
	);
};
