export const changeApplicationDetail = 'CHANGE_APPLICATION_DETAIL';
export const getApplicationStatus = 'GET_APPLICATION_STATUS';
export const setApplicationStatus = 'SET_APPLICATION_STATUS';
export const getApplication = 'GET_APPLICATION';
export const setApplication = 'SET_APPLICATION';
export const saveVerifierLog = 'SAVE_VERIFIER_LOG';
export const getClientInfo = 'GET_CLIENT_INFO';
export const setClientInfo = 'SET_CLIENT_INFO';
export const getClientFile = 'GET_CLIENT_FILE';
export const setClientFile = 'SET_CLIENT_FILE';
export const getClientRejectReason = 'GET_CLIENT_REJECT_REASON';
export const setClientRejectReason = 'SET_CLIENT_REJECT_REASON';
export const saveClientRejectReason = 'SAVE_CLIENT_REJECT_REASON';
export const saveVerifiedApplication = 'SAVE_VERIFIED_APPLICATION';
export const isRefreshFields = 'IS_REFRESH_FIELDS';
export const isClientDelete = 'IS_CLIENT_DELETE';

export const changeApplicationDetailAction = (payload) => ({
	type: changeApplicationDetail,
	payload
});

export const getApplicationStatusAction = (payload) => ({
	type: getApplicationStatus,
	payload
	
});

export const setApplicationStatusAction = (payload) => ({
	type: setApplicationStatus,
	payload
});

export const getApplicationAction = (applicationType, payload) => ({
	type: getApplication,
	applicationType,
	payload 
});

export const setApplicationAction = (payload) => ({
	
	type: setApplication,
	payload
});

export const saveVerifierLogAction = (payload) => ({
	type: saveVerifierLog,
	payload
});

export const getClientInfoAction = (payload) => ({
	type: getClientInfo,
	payload
});

export const setClientInfoAction = (payload) => ({
	type: setClientInfo,
	payload
});

export const getClientFileAction = (payload) => ({
	type: getClientFile,
	payload
});

export const setClientFileAction = (payload) => ({
	type: setClientFile,
	payload
});

export const getClientRejectReasonAction = (payload) => ({
	type: getClientRejectReason,
	payload
});

export const setClientRejectReasonAction = (payload) => ({
	type: setClientRejectReason,
	payload
});

export const saveClientRejectReasonAction = (payload) => ({
	type: saveClientRejectReason,
	payload
});

export const saveVerifiedApplicationAction = (payload) => ({
	type: saveVerifiedApplication,
	payload
});

export const isRefreshFieldsAction = (payload) => ({
	type: isRefreshFields,
	payload
});
export const deleteClientAction = (payload) => {
	console.log("deleteClientAction", payload)
	return ({
		type: isClientDelete,
		payload
	})
};