import { useSelector } from 'react-redux';
import { gif } from '../../assets/asset';

export const Loader = () => {
	const isLoading = useSelector(state => state?.app?.isLoading || false);

	return (
		isLoading
			? (
				<div className="opacity-[0.85] fixed inset-0 z-[2999] bg-black">
					<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center">
						<img src={gif.loader_white} alt="" className='h-[150px] md:h-[150px]' />
					</div>
				</div>
			)
			: null
	);
};
