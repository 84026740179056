import { useRef, useState } from 'react';
import { pngAssets } from '../../assets/asset';

const CustomDatePicker = ({ placeholder, inputType, limit, handleInput, handleSelect, disable }) => {

	const ref = useRef();
	const [isDateSelected, setIsDateSelected] = useState(false);
	const [selectedDate, setSelectedDate] = useState('');

	const currentDate = new Date().toISOString().split('T')[0];
	const handleDateChange = ({ type, value }) => {

		setSelectedDate(value);
		handleSelect({ type: type, value: value })
	};

	return (
		<div className="relative">
			{
				isDateSelected
					? (
						<input
							ref={ref}
							type="date"
							className="w-[135px] h-[44px] p-[13px] pl-[32px] appearance-none rounded-[10px] border border-[#D6D3D3] text-[#9A9A9A] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] focus:outline-none"
							// value={selectedDate}
							max={currentDate}
							min={limit}
							disabled={disable}
							onChange={(e) => handleDateChange({ type: inputType, value: e.target.value })}
						/>
					)
					: (
						<div
							onClick={() => setIsDateSelected(true)}
						>
							<input
								ref={ref}
								type="input"
								placeholder={placeholder || 'Date'}
								onBlur={() => (ref.current.type = 'date')}
								// onChange={handleChange}
								className="w-[135px] h-[44px] p-[13px] pl-[32px] appearance-none rounded-[10px] border border-[#D6D3D3] text-[#9A9A9A] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] focus:outline-none"
							/>
							<div className="absolute left-2.5 top-0 flex items-center h-full cursor-pointer ">
								<img alt="calender" src={pngAssets.reactTable.calendar} />
							</div>
						</div>
					)
			}
		</div>

	);
};

export default CustomDatePicker;
