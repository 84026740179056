import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseLogin } from '../../../components/d-kyc/d-kyc';
import '../style.css'
import { pngAssets, svgAssets } from '../../../assets/asset';
import { clearBankDetailsAction, uploadBankDetailsAction } from '../../../redux/actions/dkyc/step1.action';
import axios from 'axios';
import { BuildingLibraryIcon } from '@heroicons/react/24/solid';
import { postApi, postApiWithoutLoader, postFormApiCo } from '../../../services/common';
import { saveBankAllInfoAction } from '../../../redux/actions/kyc/step5.action';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const BankProof = ({ changeStep, TokenData, reject, bankMicr, krnJourney }) => {
    const dispatch = useDispatch();
    const [document1, setDocument1] = useState("")
    const [docPassword, setDocPassword] = useState("")
    const [ifsc, setIfsc] = useState(bankMicr?.BankIFSC)
    const [account, setAccount] = useState(bankMicr?.BankAccountNumber)
    const [reaccount, seRetAccount] = useState(bankMicr?.BankAccountNumber)
    const [micr, setMicr] = useState(bankMicr?.BankMICR)
    const [ifscData, setIfscData] = useState({})
    const [selectedOption, setSelectedOption] = useState('Select Bank proof');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleDocument = (e) => {
        if (e.target.files[0]) {
            setDocument1(e.target.files[0]);
        }
    }


    const handleSubmit = () => {
        if (!TokenData?.pan || TokenData?.pan === '') {
            alert("Somthing wents wrong or your link is expired, Please try again !!")
            return
        }
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        
        if (!allowedTypes.includes(document1.type)) {
            toast("Invalid file type. Please upload a .jpg, .jpeg, or .png file.");
            return;
        }
        else if (document1.size > 5 * 1024 * 1024) { // Check if file size is greater than 3MB
            alert("File size exceeds the limit of 5MB. Please choose a smaller image.");
            return;
        }
        else if (ifsc.length == 0) {
            toast("Please enter ifsc code")
        }
        else if (account.length == 0) {
            toast("Please enter account  number")
        }
        else if (reaccount.length == 0) {
            toast("Please enter re-enter account  number")
        }
        else if (account !== reaccount) {
            toast("Your account number and re-enter account number does not match")
        }
        // else if (holderName.length == 0) {
        //     toast("Please enter account holdername")
        // }
        else {
            fetchData()
            dispatch(uploadBankDetailsAction({
                PanNo: TokenData?.pan || '',
                Document: 'Bank Proof',
                DocumentType: selectedOption,
                step: reject?.length > 0 ? 15 : 11,
                rejectReUploadFlag: reject.length ? 1 : null,
                CreatedBy: TokenData?.created_by || "",
                'DocumentImages[]': document1
            }, handleCallback));

        }
    }



    const handleCallback = (res) => {
        if (res?.success) {
            // setRejectBankupload(true)
            toast('Your Bank Document upload successfully')
            setTimeout(() => {
                changeStep(0)
            }, 500);
        }
        else {
            toast(res.message)

        }
    }

    const handleClearDocument = () => {
        dispatch(clearBankDetailsAction())
        setDocument1("")
        setDocPassword("")
    }

    const getIfscCode = async (e) => {
        try {
            const payload = {
                BankVal: e,
                journey: "DKYC",
                mobile: TokenData?.mobile,
            }
            const response = await postApi(payload, '/GetBankMasterInfoNew');

            setIfscData(response?.data?.data[0])

        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchData = async () => {
        try {
            {
                dispatch(saveBankAllInfoAction({
                    PanNo: TokenData?.pan,
                    BankName: ifscData?.bank_name,
                    BankMICR: micr,
                    BankIFSC: ifsc,
                    BankAccountNumber: account,
                    MFBankName: ifscData?.bank_name
                }));
            }

        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const handleIfsc = (e) => {
        setIfsc(e?.target?.value)
        if (e?.target?.value > 10) {
            getIfscCode(e?.target?.value)
        }
    }

    useEffect(() => {
        // if (reject.length > 0) {
        if (ifsc?.length > 10) {
            getIfscCode(ifsc)

            // }
        }

    }, [ifsc])

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleTogglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    return (
        <BaseLogin image={pngAssets.dkyc.leftpart2}>
            <ToastContainer autoClose={1000} />
            <div className="flex flex-col justify-between items-center w-full md:w-[70%] h-full lg:mx-auto">
                <div className="w-full">
                    <div className='text-center my-8'>
                        <div className=' my-8'>
                            <h1 className='font-poppinsRegular text-[17px]'>{reject.length == 0 ? "Upload Bank Proof" : 'Resubmit Upload Bank Proof'}</h1>
                        </div>
                    </div>
                    {
                        // reject?.length > 0 &&
                        <main className="main-page address-page">
                            <div className="container">
                                <div className="form-input " >
                                    <div className="radius" style={{ marginBottom: '20px' }}>
                                        <span className='font-semibold d-block mb-1'>IFSC </span>
                                        <input
                                            style={{ width: '100%' }}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Ifsc code *"
                                            onChange={(e) => handleIfsc(e)}
                                            value={ifsc}

                                        />
                                        {
                                            ifsc?.length > 10 &&
                                            <div className='mt-0 p-4 bg-white shadow-lg rounded-2xl d-flex alig n-items-center'>
                                                <BuildingLibraryIcon className="h-14 w-14 text-black-400" />
                                                <div className='pl-2'>
                                                    <p className='text-sm'>
                                                        <span className='font-semibold '>Bank Name </span>
                                                        - {ifscData?.bank_name}
                                                    </p>
                                                    <p className='text-sm'>
                                                        <span className='font-semibold'>Branch Name </span>
                                                        - {ifscData?.branch_name}
                                                    </p>
                                                    <p className='text-sm'>
                                                        <span className='font-semibold'>ISFC Code </span>
                                                        - {ifscData?.ifsc}
                                                    </p>
                                                    <p className='text-sm'>
                                                        <span className='font-semibold'>MICR </span>
                                                        - {ifscData?.micr_code}
                                                    </p>
                                                </div>

                                            </div>
                                        }

                                    </div>

                                    <span className='font-semibold d-block mb-1'>Account number </span>

                                    <div style={{ display: 'flex', flexDirection: 'row',position:'relative'}} className="radius"  >
                                        <input
                                            style={{ width: '100%' }}
                                            type={showPassword ? 'number' : 'password'}
                                            className="form-control"
                                            placeholder="Enter account number *"
                                            value={account}
                                            onChange={(e) => { setAccount(e.target.value) }}
                                        />
                                        <img onClick={handleTogglePassword} src={svgAssets.widget.eye} style={{
                                            height: 30, width: 30, position: 'absolute',
                                            right: '20px',
                                            zIndex: 99,
                                            top: '10px',
                                        }} />

                                    </div>
                                    <div>
                                        <span className='font-semibold d-block mb-1'>Re-enter account number </span>
                                        <div style={{ display: 'flex', flexDirection: 'row',position:'relative' }} className="radius"  >
                                            <input
                                                style={{ width: '100%' }}
                                                type={showPassword1 ? 'number' : 'password'}
                                                className="form-control"
                                                placeholder="Enter account number *"
                                                value={reaccount}
                                                onChange={(e) => { seRetAccount(e.target.value) }}
                                            />
                                            <img onClick={handleTogglePassword1} src={svgAssets.widget.eye} style={{
                                                height: 30, width: 30, right: '20px',
                                                zIndex: 99,
                                                top: '10px',
                                                position: 'absolute',
                                                
                                            }} />

                                        </div>
                                    </div>

                                    <div className="radius"  >
                                        <span className='font-semibold'>MICR </span>

                                        <input
                                            style={{ width: '100%' }}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter MICR code *"
                                            value={micr}
                                            onChange={(e) => { setMicr(e.target.value) }}
                                        />
 </div>
 </div>

                            </div>
                        </main>
                    }

                    {

                        <>
                            <span className='font-semibold d-block mb-1'>Select type of document </span>
                            <div className="relative inline-block w-full">
                                <select
                                    className="block appearance-none w-full border-s-4 border border-[#5367FC] rounded-[30px] hover:border-[#5367FC] px-4 py-3 text-center focus:outline-none focus:shadow-none text-[#5367FC] font-poppinsMedium"
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                >
                                    <option value="Bank Account Proof">Bank Account Proof</option>
                                    <option value="Bank Statement">Bank Statement</option>
                                    <option value="1st page of Passbook">1st page of Passbook</option>
                                    <option value="Bank Letter">Bank Letter</option>
                                    <option value="Cancelled Cheque Leaf">Cancelled Cheque Leaf</option>
                                    {/* Add more options if needed */}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center px-2 text-blue-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            </div>
                            <div>

                                {/* {
                                    document1 &&
                                    <div className='mt-5'>
                                        <input type="text"
                                            className="input-text-custom border border-[#9E9E9E] text-[#9E9E9E] w-full border-s-4 rounded-[30px] py-3 px-5 cursor-pointer focus:outline-none focus:shadow-none font-poppinsRegular"
                                            accept="image/png, image/jpg, image/jpeg"
                                            placeholder='Enter Document Password, if any'
                                            value={docPassword}
                                            onChange={handleInputPassword}
                                        />
                                    </div>

                                } */}

                                <div className="relative mt-8 mb-5">
                                    <input id="file-upload" type="file" className="opacity-0 absolute" accept="image/png, image/jpg, image/jpeg" onChange={handleDocument} />
                                    <div className="bg-[#5367FC] flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 cursor-pointer text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                        </svg>

                                        <span className="font-medium font-poppinsMedium">Upload Document Image</span>
                                    </div>
                                </div>
                                {
                                    document1 &&
                                    <div className="flex justify-between items-center px-3 w-full">
                                        <div className="font-poppinsRegular w-[85%]">
                                            {/* <p className=' text-ellipsis whitespace-nowrap overflow-hidden '>{document1.name}</p> */}
                                            <p className=' text-ellipsis whitespace-nowrap overflow-hidden '>{document1.size > 5 * 1024 * 1024 ? <span style={{ color: 'red' }}>File size exceeds 5MB. Please choose a smaller image.</span> : document1?.name}</p>
                                        </div>
                                        <div className="bg-[#C9C9C9] rounded-full w-[15px] h-[15px] flex justify-center items-center p-[3.5px] font-poppinsMedium cursor-pointer"
                                            onClick={handleClearDocument}>
                                            <img src={svgAssets.closeIcon} alt="" />
                                        </div>
                                    </div>

                                }
                            </div>
                        </>
                    }
                </div>

                <div className='w-full '>

                    {document1 ?
                        <div className="">
                            <button className="bg-[#5367FC] w-full flex  justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
                                onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                        :
                        <div className="">
                            <div className="bg-[#C9C9C9] flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white"
                                onClick={handleClearDocument}
                            >
                                <p className="font-poppinsRegular">Submit</p>
                            </div>
                        </div>
                    }

                    <div className="my-5">
                        <div className="flex justify-center items-center cursor-pointer text-[#231F20] font-poppinsRegular"
                            onClick={() => { changeStep(0) }} >
                            <p className="">Back</p>
                        </div>
                    </div>
                </div>
            </div>
        </BaseLogin >
    );
};



