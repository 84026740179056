import React, { useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from 'react-table';
import { Pagination } from './Pagination';
import { svgAssets } from "../../assets/asset";

export const ReactTable = ({ columns, data, displayBlock,check }) => {
	const [pageSize, setPageSize] = useState(10);
	const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canNextPage, canPreviousPage,
		gotoPage, pageOptions, state, prepareRow,setPageSize: setTablePageSize, } = useTable(
			{ columns, data }, useSortBy, usePagination);
		

	const { pageIndex } = state

	return (
		<div className="w-full grid md:overflow-x-auto overflow-x-scroll">
			 <div className="mb-4" style={{display: "inline-block", marginLeft: "auto",
    marginRight: "0", border:" 1px solid #ccc", padding: "5px 10px", borderRadius: "6px"}}>
			 Rows per page:
        <select style={{padding:"5px 5px", outline:"none"}}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setTablePageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30,40, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
			<table
				{...getTableProps()}
				className="table-auto text-center h-full w-full overflow-x-auto scroll"
				style={{
					display: displayBlock && 'block'
				}}
			>
				<thead>
					{headerGroups.map((headerGroup, i1) => (
						<tr key={`head_group_${i1}`} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i2) => (
								<th
									key={`head_group_header_${i2}`}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className={`pt-2 py-4 text-[15px] text-[#9A9A9A] tracking-wide leading-[22px] font-medium font-poppinsMedium whitespace-nowrap
									 ${column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}
									`}
									style={{
										width: column.width,
										minWidth: column.minWidth
									}}
								>
									<div className="flex justify-center items-center">
										{column.render('Header')}
										{column.isSorted ? column.isSortedDesc ?
											<img src={svgAssets.sortIcon} alt="" />
											:
											<img src={svgAssets.sortIcon} alt="" className="ml-0.2 origin-center rotate-[-180deg]" />
											:
											''
										}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page?.map((row, i1) => {
						prepareRow(row);
						return (
							<>
								<tr
									key={`row_${i1}`}
									{...row.getRowProps()}
									className={`${row.cells.length - 1 !== i1 && 'border-b'} text-[15px]`}
								>
									{row.cells.map((cell, i2) => {
										return (
											<td
												key={`row_cell_${i2}`}
												{...cell.getCellProps()}
												className="py-4 text-[15px] leading-[22px] font-medium font-poppinsMedium"
											>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							</>
						);
					})}

				</tbody>
			</table>
			{
				(data?.length === 0)
					? (<div className="w-full h-[150px] text-[15px] text-[#9A9A9A] tracking-wide leading-[22px] font-medium font-poppinsMedium inline-flex items-center justify-center">
						No Data Found!
					</div>)
					: 
					
						check === false?
						null
						:
						<Pagination
						gotoPage={gotoPage}
						pageIndex={pageIndex}
						pageOptions={pageOptions}
						nextPage={nextPage}
						previousPage={previousPage}
						canNextPage={canNextPage}
						canPreviousPage={canPreviousPage}
						noOfRows={data?.length}
					/>
						
					
					
			}
		</div>
	);
};


// import React, { useMemo, useState } from "react";
// import { useTable, usePagination, useSortBy } from 'react-table';
// import { Pagination } from './Pagination';
// import { svgAssets } from "../../assets/asset";

// export const ReactTable = ({ columns, data, displayBlock, check }) => {
//   const [pageSize, setPageSize] = useState(10); // State for rows per page

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     gotoPage,
//     pageOptions,
//     state,
//     prepareRow,
//     setPageSize: setTablePageSize, // Set the pageSize for the table
//   } = useTable(
//     { columns, data, initialState: { pageSize } }, // Pass initial pageSize to useTable
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex } = state;

//   return (
//     <div className="w-full grid md:overflow-x-auto overflow-x-scroll">
//       {/* Add a dropdown for rows per page */}
//       <div className="mb-4">
//         Rows per page:
//         <select
//           value={pageSize}
//           onChange={(e) => {
//             setPageSize(Number(e.target.value));
//             setTablePageSize(Number(e.target.value));
//           }}
//         >
//           {[10, 20, 30, 50].map((size) => (
//             <option key={size} value={size}>
//               {size}
//             </option>
//           ))}
//         </select>
//       </div>

//       <table
//         {...getTableProps()}
//         className="table-auto text-center h-full w-full overflow-x-auto scroll"
//         style={{
//           display: displayBlock && "block",
//         }}
//       >
//         <thead>
//           {headerGroups.map((headerGroup, i1) => (
//             <tr key={`head_group_${i1}`} {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map((column, i2) => (
//                 <th
//                   key={`head_group_header_${i2}`}
//                   {...column.getHeaderProps(column.getSortByToggleProps())}
//                   className={`pt-2 py-4 text-[15px] text-[#9A9A9A] tracking-wide leading-[22px] font-medium font-poppinsMedium whitespace-nowrap
//                     ${column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}
//                   `}
//                   style={{
//                     width: column.width,
//                     minWidth: column.minWidth
//                   }}
//                 >
//                   <div className="flex justify-center items-center">
//                     {column.render('Header')}
//                     {column.isSorted ? (column.isSortedDesc ?
//                       <img src={svgAssets.sortIcon} alt="" />
//                       :
//                       <img src={svgAssets.sortIcon} alt="" className="ml-0.2 origin-center rotate-[-180deg]" />
//                     ) : ''}
//                   </div>
//                 </th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody {...getTableBodyProps()}>
//           {page?.map((row, i1) => {
//             prepareRow(row);
//             return (
//               <tr
//                 key={`row_${i1}`}
//                 {...row.getRowProps()}
//                 className={`${row.cells.length - 1 !== i1 ? 'border-b' : ''} text-[15px]`}
//               >
//                 {row.cells.map((cell, i2) => (
//                   <td
//                     key={`row_cell_${i2}`}
//                     {...cell.getCellProps()}
//                     className="py-4 text-[15px] leading-[22px] font-medium font-poppinsMedium"
//                   >
//                     {cell.render('Cell')}
//                   </td>
//                 ))}
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>

//       {data?.length === 0 ? (
//         <div className="w-full h-[150px] text-[15px] text-[#9A9A9A] tracking-wide leading-[22px] font-medium font-poppinsMedium inline-flex items-center justify-center">
//           No Data Found!
//         </div>
//       ) : check === false ? null : (
//         <Pagination
//           gotoPage={gotoPage}
//           pageIndex={pageIndex}
//           pageOptions={pageOptions}
//           nextPage={nextPage}
//           previousPage={previousPage}
//           canNextPage={canNextPage}
//           canPreviousPage={canPreviousPage}
//           noOfRows={data?.length}
//         />
//       )}
//     </div>
//   );
// };
