import { useState } from 'react';
import { svgAssets } from '../../assets/asset';
import { SubmitBtn } from '../../components/buttons/SubmitBtn';
import { useDispatch, useSelector } from 'react-redux';
import { MyModal } from '../../components/modal/Modal';
import { SelectDropdown } from '../../components/dropdown/SelectDropdown';
import { callVerifyRejectionAction } from '../../redux/actions/kyc/verify.action';
import { toast } from 'react-toastify';
import { getClientRejectReasonAction } from '../../redux/actions/application.action';
import refetch from "../../assets/svg/refetch-icon.svg"
const panRejectionOption = [
	{ label: 'PAN proof image is not clear', value: 'PAN proof image is not clear' },
	{ label: 'Enter PAN details mismatch with PAN proof', value: 'Enter PAN details mismatch with PAN proof' },
	{ label: 'PAN Name mismatch with PAN proof', value: 'PAN Name mismatch with PAN proof' },
	{ label: 'PAN DOB mismatch with PAN proof', value: 'PAN DOB mismatch with PAN proof' },
	{ label: 'Gender mismatch with PAN proof', value: 'Gender mismatch with PAN proof' },
	{ label: 'pan DOB mismatch with Aadhar proof', value: 'pan DOB mismatch with Aadhar proof' },
	{ label: 'Enter Father Name mismatch with PAN proof', value: 'Enter Father Name mismatch with PAN proof' },
	{ label: 'client name mismatch as per existing DP', value: 'client name mismatch as per existing DP' },
	{ label: 'Video IPV required', value: 'Video IPV required' },
	{ label: 'penny drop & Geo tagging not showing in verification as well as esign PDF', value: 'penny drop & Geo tagging not showing in verification as well as esign PDF' },
	{ label: 'Joint holder DP not allowed in DKYC for only trading', value: 'Joint holder DP not allowed in DKYC for only trading' },
	{ label: 'Digilocker adhar card image is missing in verification as well as in esign PDF', value: 'Digilocker adhar card image is missing in verification as well as in esign PDF' },
	{ label: 'Name Mismatch With ADHAR', value: 'Name Mismatch With ADHAR' },
	{ label: 'Signature Missing On Pan Card', value: 'Signature Missing On Pan Card' },
	{ label: 'client name required Pan saite', value: 'client name required Pan saite' },



];


const esighnRejectionOption = [
	{ label: 'Income proof required for income range selected 25lac-1cr (in case income range is high we require income document)', value: 'Income proof required for income range selected 25lac-1cr (in case income range is high we require income document)' },
];

export const SingleDetail = ({ label, value, isVerify, reviewData, status, callback,selectedData }) => {
	const [rejectModal, setRejectModal] = useState(false);
	const [verifyModal, setVerifyModal] = useState(false);
	const [isRejectionList, setIsRejectionList] = useState([]);
	const [rejectReason, setRejectReason] = useState(0);
	const clientId = reviewData?.ClientId;
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const rejectReasonsData = useSelector(state => state?.application.rejectReason || []);
	const new_data=Object.values(rejectReasonsData)
    const filteredData =label == 'Name As On PAN'? new_data?.filter(entry => entry?.verify_key == 'NameAsOnPAN'): new_data?.filter(entry => entry?.verify_key == 'AnnualIncome');
	
const handleModal = ({ isSubmit }) => {
		if(isSubmit == true && rejectReason == ''){
toast("please select reason")
		}
		else{
			setRejectModal(false);
			setVerifyModal(false);
			if (isSubmit) {
				runApi(0)
			}
		}
		

	};
	const handleInput = ({ type, value }) => {

		if (type == 'rejectReason') {
			setRejectReason(value)
		}
	};
	const runApi = (isVerify) => {
	dispatch(callVerifyRejectionAction({
				"ActualField": label,
				"PageNo": 1,
				"FieldName": label.replace(/\s/g, ''),
				"FieldValue": value,
				"IsVerify": isVerify,
				"VerifyBy": userDetail.user_id,
				"ClientId": clientId,
				"DocId": "",
				"Reson": rejectReason || ''
			}, callbackcontinoue));
		}

	const callbackcontinoue = (resp) => {
		console.log(resp,'resp');
		if (resp == 'success') {
			toast('Record Saved successfully')
			setRejectModal(false)
			dispatch(getClientRejectReasonAction({
				ClientId: selectedData.ClientId,
				PanNo: selectedData.PanNo
			}));
			setVerifyModal(false);
		}
		else {
			toast(resp.message ? resp.message : '"something went wrong')
		}
	}
	const handleSubmitVerifyModal = () => {
		runApi(1)

	};

	const handleValidation = (actiontype, type) => {

		if (actiontype == 'reject') {
			setRejectReason(0)
			setRejectModal(true);
			if (type === 'Name As On PAN') {
				setIsRejectionList(panRejectionOption);
			} else {
				setIsRejectionList(esighnRejectionOption);
			}
		}
		else {
			setVerifyModal(true);

		}


	};

	return (
		<>
			<div  className="w-full mb-2 md:mb-[15px] md:px-[18px] py-[11px] grid grid-cols-1 md:grid-cols-3 gap-x-2 md:gap-5 items-center rounded-[10px] md:shadow-[0px_1px_12px_rgba(185,185,185,0.25)]">
				<div className="text-[#90A5B5] font-poppinsRegular "><p>{label}</p></div>
				<div  className="text-black font-poppinsRegular"><p>{value}</p></div>
				{
					reviewData?.FinalVerify == 1 ?
						null
						:

isVerify && (
							<div className='flex items-center justify-end'>
								{
									status == "reject"
										? (
											<>
												<img
													alt="wrong_field"
													className="mr-5"
													src={
														svgAssets.review.wrongTick
													}
												/>
												{/* <img
												alt="wrong_field"
												className="cursor-pointer"
												src={svgAssets.review.undo}
												// onClick={() => setIsValid({ ...isValid, triggered: false })}
											/> */}
											</>
										)
										:
										status == "done"
											? (
												<>

													<img
														alt="wrong_field"
														className="mr-5"
														src={svgAssets.review.correctTick}
													/>
												</>
											)
											:
											(
												<>
												{
													filteredData.length>0 &&( 
														<div onClick={() => handleValidation('approve', label)}>
								<button style={{ background: 'red',maxWidth:"170px" }}  className={'d-inline-block text-white px-2 py-1 rounded refetch-button'}><img src={refetch} className="mr-1" />Undo Rejection</button>
							</div>
													// 	<img
													// 	alt="right_field"
													// 	className="cursor-pointer"
													// 	src={svgAssets.verifyApplication.rightField}
													// 	onClick={() => handleValidation('approve', label)}
													// />
													)
												}
												{
													filteredData.length == 0?
													<img
														alt="wrong_field"
														className="mr-5 cursor-pointer"
														src={svgAssets.verifyApplication.wrongField}
														onClick={() => handleValidation('reject', label)}
													/>
													:null
												}
													
													
												</>
											)
								}
							</div>
						)
				}
				
			</div>
			<MyModal
				isModalOpen={rejectModal}
				handleModal={handleModal}
				title=""
				centered={false}
				width={590}
			>
				<div className="">
					<h6 className="mb-4 text-lg font-medium">Tell us rejection reason</h6>
					{/* <SelectRejectionDropdown placeholder="Enter rejection reason" rejectionList={isRejectionList} /> */}
					<SelectDropdown
						label=""
						placeholder="Please select reason"
						options={isRejectionList}
						selectedValue={rejectReason}
						inputType="rejectReason"
						handleSelect={handleInput}
						inputMessage={''}
						inputMessageType={''}
					/>
					<div className="flex items-center justify-center mt-12">
						<SubmitBtn handleSubmitBtn={() => handleModal({ isSubmit: true })} />
					</div>
				</div>
			</MyModal >
			<MyModal
				isModalOpen={verifyModal}
				handleModal={handleModal}
				title=""
				centered={false}
				width={590}
			>
				<div className="">

					<h3 className='text-2xl'> Undo Rejection</h3>
					<div className="flex items-center justify-center mt-12">
						<botton
							className='bg-black px-8 py-2 text-white rounded cursor-pointer'
							onClick={() => { setVerifyModal(false); }}
						>
							Cancel
						</botton>
						<botton
							className='ml-3 bg-black px-8 py-2 text-white rounded cursor-pointer'
							onClick={handleSubmitVerifyModal}
						>
							Ok
						</botton>

					</div>
				</div>
			</MyModal >
		</>
	);
};
