import { clearBranchCode, clearBranchName, clearInputValidation, inputValidation, setDesignationMaster, setRoleMaster } from '../actions/manageuser.action';
import { setBranchCode } from '../actions/manageuser.action';
import { setBranchName } from '../actions/manageuser.action';

const mutableState = {
    roleMaster: [],
    branchCode: [],
    branchName: [],
    designationList: [],
    validation: {},
    errMessage: '',
    success: false,
    isLoadingBtn: false
};

const initState = {
    ...mutableState
};

export const manageuserReducer = (state = initState, action) => {
    switch (action.type) {
        case setRoleMaster:

            return {
                ...state,
                roleMaster: action.payload?.data || [],
            };

        case setBranchCode:
            return {
                ...state,
                branchCode: action?.payload?.data || [],
            };

        case clearBranchCode:
            return {
                ...state,
                branchCode: [],
            };

        case setBranchName:

            return {
                ...state,
                branchName: action?.payload?.data || [],
            };

        case setDesignationMaster:
console.log("data?.data?.data",action?.payload?.data)
            return {
                ...state,
                designationList: action?.payload?.data || [],
            };

        case inputValidation:
            return {
                ...state,
                validation: action?.payload?.data?.data || {},
                errMessage: action?.payload?.data?.message || '',
                success: action?.payload?.data?.success || false,
            };

        case clearInputValidation:
            return {
                ...state,
                validation: {},
            };

        case clearBranchName:
            return {
                ...state,
                branchName: [],
            };

        default:
            return state;
    }
};

