import React, { useEffect, useState } from 'react';
import { postApi_mod } from '../../../services/common';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { svgAssets } from '../../../assets/asset';
import { MainTitle } from '../../../components/common/MainTitle';
import { ClientDetail } from '../../review/clientDetail/ClientDetail';
import { MobileEmailDetail } from '../../review/MobileEmailDetail';
import { PanDetail } from '../../review/PanDetail';
import { AddressDetail } from '../../review/AddressDetail';
import { AddressDetail1 } from '../../review/AddressDetails1';
import { useDispatch, useSelector } from 'react-redux';
import { getClientFilesAction } from '../../../redux/actions/kyc/review.action';
import { Bank_modificationView } from './Bank_modificationView';
import { PersonalDeatils_modification } from './PersonalDeatils_modification';
import { Occuptional_modification } from './Occuptional_modification';
import { BrokerageStep } from '../../review/brokerage/BrokerageStep';

export const ModificationView = () => {
    const [information, setInformation] = useState({})
    const location = useLocation();
    const selectedData = location.state && location.state.selectedData;
    const dispatch = useDispatch();
    const reviewFilesData = useSelector(
        (state) => state?.reviewData.filesData || {}
    );
    const [error, setError] = useState('')

    useEffect(() => {
        fetchData()
        dispatch(
            getClientFilesAction({
                PanNo: selectedData.PanNo || "",
                CreatedBy: 1,
            })
        );
    }, [selectedData?.ClientId])

    const fetchData = async () => {
        setError('')
        try {
            const payload = {
                ClientId: selectedData?.ClientId
            }
            const response = await postApi_mod(payload, '/GetCltFullInfo');
            if (response?.data.success) {
                setInformation(response?.data?.data)
                GetApi(response?.data?.data)

            }
            else {
                setError('someting went wrong')

            }

        } catch (err) {
            setError('someting went wrong')

        }
    };

    const RejectField = async () => {
        try {
            const payload = {
                ClientId: selectedData?.ClientId
            }
            const response = await postApi_mod(payload, '/GetCltRejectFields');
            if (response?.data.success) {
                // if (information?.LDCode) {
                //     GetApi()
                // }
            }
            else {
                setError('someting went wrong')

            }

        } catch (err) {
            setError('someting went wrong')

        }
    };
    const GetApi = (id) => {
        const payload = {
            value: id?.LDCode,
        };
        axios.get(`https://dkyc.bonanzaonline.com/mod_ekyc/GetChange/${payload?.value}`)
            .then((response) => {
                if (response) {
                    RejectField()
                }
            })
            .catch((error) => {
                setError('someting went wrong')

            });
    };


    const RefreshPage = () => {
        window.location.reload();
    }
    return (
        <>

            {
                error ?
                    <div className="w-full h-[calc(100vh-125px)] flex items-center justify-center text-xl font-poppinsMedium">
                        Someting went wrong!
                        <div style={{ backgroundColor: 'black', marginLeft: '20px', padding: '10px', borderRadius: '10px', cursor: 'pointer' }} onClick={() => RefreshPage()}>
                            <p style={{ color: 'white' }}>Reload</p>
                        </div>
                    </div>
                    :
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div className="w-full">
                                <MainTitle title="Modification View Application Details" />
                            </div>
                            <div className="w-full flex items-center justify-center  md:justify-end text-[#848484] font-medium font-poppinsMedium  mb-4 md:mb-0">
                                <span className="mr-2">Client of</span>
                                {information?.IsETrade == 90 ? (
                                    <img className="h-5" alt="bigul" src={svgAssets.bigul} />
                                ) : (
                                    <img className="h-8" alt="bigul" src={svgAssets.bonabza} />
                                )}
                            </div>
                        </div>

                        <div className="px-4 md:px-7 py-8 mb-[35px] rounded-[20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
                            <ClientDetail
                                reviewData={information || {}}
                                reviewFilesData={reviewFilesData}
                            />

                            <div className='mt-5'>
                                <MobileEmailDetail
                                    reviewData={information || {}}

                                />
                            </div>
                            <div>
                                <PanDetail
                                    reviewData={information || {}}
                                />
                            </div>
                            <div>
                                <AddressDetail
                                    reviewData={information || {}}

                                />
                                <AddressDetail1
                                    reviewData={information || {}}
                                />
                            </div>
                            <Bank_modificationView
                                reviewData={information || {}} />
                            <PersonalDeatils_modification
                                reviewData={information || {}} />
                            <Occuptional_modification
                                reviewData={information || {}} />


                            {/* <BrokerageStep
        reviewData={information || {}} /> */}

                        </div>


                    </div>
            }
        </>
    );
};
