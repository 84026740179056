import React, { useState } from 'react';

export const Hadding2 = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const accordionData = [
        { title: '1. How closely does my signature need to match the one on my PoI document?', content: 'While it doesn t need to be an exact replica, your signature should be consistent enough to verify your identity.' },
        { title: '2. What should I do if my signature is unclear or illegible?', content: 'Ensure your signature is clear and easily readable. Practice writing it legibly, and use a pen that provides a consistent ink flow.' },
    ];

    return (
        <>
        <p style={{fontWeight:'bold',marginLeft:'10%',marginBottom:'20px',marginTop:'20px'}}>FAQs for Invalid Signature</p>
        <div style={styles.accordionContainer}>
           {accordionData.map((item, index) => (
                <div key={index} style={styles.accordionItem}>
                    <div
                        style={styles.accordionHeader}
                        onClick={() => handleToggle(index)}
                    >
                        <h3 style={{fontWeight:'bold'}}>{item.title}</h3>
                        
                    </div>
                    <div
                        style={{
                            ...styles.accordionContent,
                            display: index === activeIndex ? 'block' : 'none'
                        }}
                    >
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

const styles = {
    accordionContainer: {
        width: '80%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        
    },
    accordionItem: {
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px'
    },
    accordionHeader: {
        padding: '15px',
        // backgroundColor: '#007BFF',
        // color: '#fff',
        cursor: 'pointer'
    },
    accordionContent: {
        padding: '10px',
        borderTop: '1px solid #ddd',
        backgroundColor: '#fff'
    }
};





