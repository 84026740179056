import { OutsideClickListner } from '../../hooks/OutsideClickListner';
import { useEffect, useRef, useState } from 'react';
import { SelectRejectionDropdown } from '../dropdown/SelectRejectionDropdown';

export const SearchBar = ({ placeholder, border, data, handleSelect }) => {
	const wrapperRef = useRef(null);
	const [isSelected, setIsSelected] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [options, setOptions] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleOption = (event) => {
		setIsSelected(true);
		setSearchText(event.target.value.toUpperCase());
		const filteredData = data && data.filter((row) => {
			return (
				row?.FName !== null &&
				(row?.FName.includes(searchText.toUpperCase()) ||
					row?.FName.includes(searchText.toLowerCase()))
			);
		})

		setOptions(filteredData)
		if (event.target.value === "") {
			setOptions(data)
			handleSelect({ searchVal: "" });
			setIsSelected(false)
		}
	};


	const handleKeyDown = event => {
		if (event.key === 'ArrowDown') {
			console.log("down")
			event.preventDefault(); // Prevents scrolling the page
			setSelectedIndex((prevIndex) => (prevIndex + 1) % options.length);
		}
		if (event.key === 'Enter') {
			setIsSelected(false);
			handleSelect({ searchVal: event.target.value });
		}
	};


	const handleSearch = ({ value }) => {
		setIsSelected(false);
		setSearchText(value?.FName);
		handleSelect({ searchVal: value?.FName });
	};


	const handleOutsideClick = () => {
		setIsSelected(false);
	};

	OutsideClickListner({ ref: wrapperRef, closeBox: handleOutsideClick });


	// useEffect(() => {
	// 	setOptions(data)
	// }, [data])

	return (
		<>
			<div
				ref={wrapperRef}
				className={`w-[350px] rounded-[10px] relative hidden lg:block z-10 shadow-[0px_2px_10px_rgba(201,201,201,0.25)]
			  ${isSelected && 'bg-white'}
				${border || ''}
		  `}>
				<div
					className="h-[45px] px-3.5 flex items-center justify-between cursor-pointer rounded-[10px] bg-white">
					<input
						className="w-full pr-5 m-0 text-xs inline-flex items-center outline-none border-none font-poppinsRegular"
						type="text"
						placeholder={placeholder || 'Search by Name, Mobile Number, ID code'}
						value={searchText}
						onChange={handleOption}
						onKeyDown={handleKeyDown}
					/>
					{/* <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20" onClick={() => setIsSelected(!isSelected)}>
						<path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" fill='black' />
					</svg> */}
				</div>

			</div>
		</>
	);
};
