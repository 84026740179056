export const changeStep8Error = 'CHANGE_STEP8_ERROR';
export const uploadSignature = 'UPLOAD_SIGNATURE';
export const finalSubmit = 'FINAL_SUBMIT';
export const callUpdateSegmentInfo = 'CALL_UPDATE_SEGMENT_INFO';
export const clearSignature = 'CLEAR_SUBMIT';
export const callSendSignatureLink = 'CALL_SEND_SIGNATURE_LINK';
export const sendSignatureLink = 'SEND_SIGNATURE_LINK';

export const changeStep8ErrorAction = (payload) => ({
	type: changeStep8Error,
	payload
});

export const uploadSignatureAction = (payload) => ({
	type: uploadSignature,
	payload
});

export const finalSubmitAction = (payload,callback) => ({
	type: finalSubmit,
	payload,
	callback
});

export const callUpdateSegmentInfoAction = (payload,callback) => ({
	type: callUpdateSegmentInfo,
	payload,
	callback
});

export const clearSignatureAction = (payload) => ({
	type: clearSignature,
	payload
});

export const callSendSignatureLinkAction = (payload) => {
	return(
		{
			type: callSendSignatureLink,
			payload
		}
	)
};

export const sendSignatureLinkAction = (payload) => {
	return(
		{
			type: sendSignatureLink,
			payload
		}
	)
};