
export const ReactTableHeader = ({ title, numberOfApplications }) => {

	return (
		<div className="flex flex-col md:flex-row items-center justify-center gap-5 md:gap-1 px-1">
			<h6 className="mr-2.5 text-[22px] leading-[33px] font-semibold font-poppinsSemiBold whitespace-nowrap">
				{title}
			</h6>
			{
				(numberOfApplications > 0) && (
					<span className="px-3 py-[1px] rounded-md	inline-flex items-center justify-center font-semibold font-poppinsSemiBold bg-[#FFF1D7] w-full md:w-auto">
						{/* {numberOfApplications} */}
					</span>
				)
			}
		</div>
	);
};
