
// import dayjs from 'dayjs';
// import { all, call, put, takeEvery } from 'redux-saga/effects';
// import { changeStep2ErrorAction, addSegmentInfo, getValidatePanDetails, callSendDigilockerLink, isDigioKycCompleteData, getPanCardDetail, setKRAAction, setPanCardDetailAction, setValidatePanDetailsAction, getSendDigilockerLinkDataAction, setIsSendDigioLockerLinkAction, setDigioLokerDataAction, } from '../../redux/actions/kyc/step2.action';
// import { postApi, postApiWithoutLoader, postFormApiCo, postFormApiCo1, validatePanDetails } from '../../services/common';
// import { toast } from 'react-toastify';

// function* getValidatePanDetailsSaga({ payload, callback }) {
// 	try {
// 		const data = yield call(postApi, payload, '/GetPanName');
// 		yield call(callback, data?.data);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };
// function* addSegmentInfoSaga({ payload, callback }) {
// 	try {
// 		const data = yield call(postApi, payload, '/AddSegmentInfo');
// 		if (data?.data?.success && data?.data?.message == 'Please Wait Featching Personal Details...') {
// 			yield call(callback, 'success');
// 		}
// 		else {
// 			yield call(callback, 'fail');
// 		}
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* getPanCardDetailSaga({ payload, callback }) {
// 	const validatePan = yield call(validatePanDetails, {
// 		pan: payload.PanNo,
// 		dob: payload.BirthDate,
// 	});

// 	if (validatePan?.data) {
// 		yield put(setValidatePanDetailsAction(validatePan?.data || {}));
// 	}

// 	if (validatePan.data?.status === false) {
// 		yield call(callback, validatePan?.data);
// 	}
// 	if (validatePan.data?.data?.seeding == 'Y') {
// 		payload.kra_status = 1;
// 		const isKRA = true;
// 		if (!isKRA) {
// 			yield put(
// 				changeStep2ErrorAction({
// 					type: 'panInput',
// 					isError: true,
// 					errorText: 'Something went wrong!',
// 				})
// 			);
// 		} else {

// 			const panStatusData = yield call(
// 				postApi,
// 				{ panNo: payload.PanNo },
// 				'/GetCVLPanStatus'
// 			);
// 			const kycStatus = ['007', '107', '207', '307', '407',];
// 			const kycStatusModification = [
// 				'007',

// 				'002',

// 				'005',

// 				'107',

// 				'207',

// 				'307',

// 				'407',
// 			];
// 			if (panStatusData) {
// 				const panSts = panStatusData?.data?.APP_PAN_INQ?.APP_STATUS;
// 				const panStsModification = panStatusData?.data?.APP_PAN_INQ?.APP_UPDT_STATUS;
// 				const isValidKRA =
// 					panStatusData?.data?.APP_PAN_INQ?.APP_STATUS &&
// 					kycStatus.includes(panSts);
// 				const isValidKRA1 =
// 					panStatusData?.data?.APP_PAN_INQ?.APP_UPDT_STATUS &&
// 					kycStatusModification.includes(panStsModification);
// 				const dob = dayjs(payload.BirthDate, 'DD-MM-YYYY').format(
// 					'DD/MM/YYYY'
// 				);
// 				const panFullData = yield call(
// 					postApi,
// 					{ panNo: payload.PanNo, dob, status: panSts },
// 					'/GetCVLFullPanDetails'
// 				);
// 				const corAdd1 = panFullData?.data?.KYC_DATA?.APP_COR_ADD1;
// 				const corAdd2 = panFullData?.data?.KYC_DATA?.APP_COR_ADD2;

// 				if ((isValidKRA && isValidKRA1) && (panStatusData.data.APP_PAN_INQ.APP_PER_ADD_PROOF == '31' || panStatusData.data.APP_PAN_INQ.APP_PER_ADD_PROOF == '02' || panStatusData.data.APP_PAN_INQ.APP_PER_ADD_PROOF == '06' || panStatusData.data.APP_PAN_INQ.APP_PER_ADD_PROOF == '01') && (typeof corAdd1 === 'string' && corAdd1.trim() !== '') ||
// 					(typeof corAdd1 === 'object' && Object.keys(corAdd1).length > 0) || (typeof corAdd2 === 'string' && corAdd2.trim() !== '') ||
// 					(typeof corAdd2 === 'object' && Object.keys(corAdd2).length > 0)) {

// 					const isError =
// 						panFullData?.data?.KYC_DATA?.APP_ERROR_CODE &&
// 						panFullData.data.KYC_DATA.APP_ERROR_CODE.split('-').includes(
// 							'WEBERR'
// 						);

// 					if (
// 						panFullData?.data?.KYC_DATA?.APP_ERROR_CODE === 'WEBERR-012'
// 					) {
// 						yield put(
// 							changeStep2ErrorAction({
// 								type: 'dobInput',
// 								isError: true,
// 								errorText: 'INVALID DOB (APP_DOB_INCORP) PROVIDED',
// 							})
// 						);
// 					} else if (isError) {
// 						yield put(
// 							changeStep2ErrorAction({
// 								type: 'panInput',
// 								isError: true,
// 								errorText: 'Something went wrong!',
// 							})
// 						);
// 					} else {
// 						const InputXML = `<APP_REQ_ROOT>
// 									<APP_PAN_INQ>
// 									<APP_PAN_NO>${payload?.PanNo}</APP_PAN_NO>
// 									<APP_DOB_INCORP>${dob}</APP_DOB_INCORP>
// 									<APP_POS_CODE>1200000235</APP_POS_CODE>
// 									<APP_RTA_CODE>1200000235</APP_RTA_CODE>
// 									<APP_KRA_CODE>CVLKRA</APP_KRA_CODE>
// 									<FETCH_TYPE>I</FETCH_TYPE>
// 									</APP_PAN_INQ>
// 									</APP_REQ_ROOT>`;
// 						var postdata = {
// 							InputXML: InputXML
// 						};
// 						postFormApiCo1(postdata, 'kyraapisecond')
// 							.then(response => {
// 								const payload3 = {
// 									mobile: payload?.mobile,
// 									app_no: panSts,
// 									xml_data: response?.data,

// 								}
// 								const response1 = postFormApiCo(payload3, 'save-kra-data');

// 							})
// 							.catch(error => {
// 								toast(error.message)
// 							});
// 						yield put(
// 							setPanCardDetailAction({
// 								data: panFullData?.data?.KYC_DATA || {},
// 								isKRA: isValidKRA,
// 								isShowDigilockerLinkButton: false,
// 								update_status: panStatusData?.data?.APP_PAN_INQ
// 							})
// 						);
// 						yield put(
// 							setKRAAction({
// 								data: panFullData?.data?.KYC_DATA,
// 								update_status: panStatusData?.data?.APP_PAN_INQ
// 								 || {},
// 							})
// 						);
// 					}
// 				} else {
// 					yield put(
// 						setKRAAction({
// 							data: panStatusData?.data?.APP_PAN_INQ || {},
// 							update_status: panStatusData?.data?.APP_PAN_INQ ||{},
// 							isKRA: false,
// 						})
// 					);
// 					yield put(
// 						setPanCardDetailAction({ isShowDigilockerLinkButton: true })
// 					);
// 					yield call(callback, false);
// 				}
// 			} else {
// 				toast(panStatusData?.message ? panStatusData?.message : 'something went wrong')
// 			}

// 		}
// 		const data = yield call(postApi, payload, '/GetPanName');
// 	} else {
// 		toast('Pan not linked with Aadhar');
// 	}

// }

// function* callIsDigilockerCompletedSaga({ payload, callback }) {
// 	try {
// 		const data = yield call(postApiWithoutLoader, payload, '/is-digio-kyc-completed');

// 		if (data) {
// 			yield put(getSendDigilockerLinkDataAction(data?.data || {}));
// 			yield put(setDigioLokerDataAction(data?.data?.data || {}));

// 		}
// 		if (data?.success) {
// 			yield put(setIsSendDigioLockerLinkAction(false));
// 		}
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* callSendDigilockerLinkSaga({ payload, callback }) {
// 	try {
// 		const data = yield call(postApi, payload, '/send-digilocker-link');
// 		console.log(data, 'digilocker');
// 		// if(data){
// 		// 	yield put(getDigilockerCompletedDataAction(data?.data || {}));
// 		// }
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* kycStep2Saga() {
// 	yield all([
// 		takeEvery(getValidatePanDetails, getValidatePanDetailsSaga),
// 		takeEvery(addSegmentInfo, addSegmentInfoSaga),
// 		takeEvery(getPanCardDetail, getPanCardDetailSaga),
// 		takeEvery(callSendDigilockerLink, callSendDigilockerLinkSaga),
// 		takeEvery(isDigioKycCompleteData, callIsDigilockerCompletedSaga),
// 	]);
// }

// export default kycStep2Saga;


import dayjs from 'dayjs';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { changeStep2ErrorAction, addSegmentInfo, getValidatePanDetails, callSendDigilockerLink, isDigioKycCompleteData, getPanCardDetail, setKRAAction, setPanCardDetailAction, setValidatePanDetailsAction, getSendDigilockerLinkDataAction, setIsSendDigioLockerLinkAction, setDigioLokerDataAction, } from '../../redux/actions/kyc/step2.action';
import { postApi, postApiWithoutLoader, postFormApiCo, postFormApiCo1, validatePanDetails } from '../../services/common';
import { toast } from 'react-toastify';

function* getValidatePanDetailsSaga({ payload, callback }) {
	try {
		const data = yield call(postApi, payload, '/GetPanName');
		yield call(callback, data?.data);
	} catch (err) {
		console.log(err)
	};
};
function* addSegmentInfoSaga({ payload, callback }) {
	try {
		const data = yield call(postApi, payload, '/AddSegmentInfo');
		if (data?.data?.success && data?.data?.message == 'Please Wait Featching Personal Details...') {
			yield call(callback, 'success');
		}
		else {
			yield call(callback, data?.data);
		}
	} catch (err) {
		console.log(err);
	};
};

// function* getPanCardDetailSaga({ payload, callback }) {
// 	const validatePan = yield call(validatePanDetails, {
// 		pan: payload.PanNo,
// 		dob: payload.BirthDate,
// 	});
// 	if (validatePan.status == 200) {
// 		if (validatePan?.data) {
// 			yield put(setValidatePanDetailsAction(validatePan?.data || {}));
// 			yield call(callback, validatePan?.data);
// 		}
// 		if (validatePan.data?.status == false) {
// 			yield call(callback, validatePan?.data); 
// 		}
// 	}
// 	else {
// 		toast('something went wrong')
// 	}
// }

function* getPanCardDetailSaga({ payload, callback }) {
	try {
		const validatePan = yield call(validatePanDetails, {
			pan: payload.PanNo,
			dob: payload.BirthDate,
			name: payload.name
		});
		console.log('validatePan', validatePan);

		if (validatePan.status === 200) {
			if (validatePan.data) {
				yield put(setValidatePanDetailsAction(validatePan.data || {}));
				yield call(callback, validatePan.data);
			}
			if (validatePan.data?.status === false) {
				yield call(callback, validatePan.data);
				//   toast('something went wrong')
			}
		} else {
			toast('Something went wrong');
		}
	} catch (error) {
		toast('Something went wrong');

	}
}


function* callIsDigilockerCompletedSaga({ payload, callback }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/is-digio-kyc-completed');

		if (data) {
			yield put(getSendDigilockerLinkDataAction(data?.data || {}));
			yield put(setDigioLokerDataAction(data?.data?.data || {}));

		}
		if (data?.success) {
			yield put(setIsSendDigioLockerLinkAction(false));
		}
	} catch (err) {
		console.log(err);
	};
};

function* callSendDigilockerLinkSaga({ payload, callback }) {
	try {
		const data = yield call(postApi, payload, '/send-digilocker-link');
		console.log(data, 'digilocker');
		if (data) {
			yield call(callback, data);

		}
	} catch (err) {
		console.log(err);
	};
};

function* kycStep2Saga() {
	yield all([
		takeEvery(getValidatePanDetails, getValidatePanDetailsSaga),
		takeEvery(addSegmentInfo, addSegmentInfoSaga),
		takeEvery(getPanCardDetail, getPanCardDetailSaga),
		takeEvery(callSendDigilockerLink, callSendDigilockerLinkSaga),
		takeEvery(isDigioKycCompleteData, callIsDigilockerCompletedSaga),
	]);
}

export default kycStep2Saga;



