export const changeStep1Input = 'CHANGE_STEP1_INPUT';
export const changeStep1Status = 'CHANGE_STEP1_STATUS';
export const changeStep1MobileOtpStatus = 'CHANGE_STEP1_MOBILE_OTP_STATUS';
export const changeStep1EmailOtpStatus = 'CHANGE_STEP1_EMAIL_OTP_STATUS';
export const changeStep1Error = 'CHANGE_STEP1_ERROR';
export const sendMobileOtp = 'SEND_MOBILE_OTP';
export const checkMobileOtp = 'CHECK_MOBILE_OTP';
export const verifyMobileOtp = 'VERIFY_MOBILE_OTP';
export const sendEmailOtp = 'SEND_EMAIL_OTP';
export const checkEmailOtp = 'CHECK_EMAIL_OTP';
export const verifyEmailOtp = 'VERIFY_EMAIL_OTP';
export const clearKyc = 'CLEAR_KYC';

export const clearKycAction = () => ({
	type: clearKyc,
  });

export const changeStep1InputAction = (payload) => ({
	type: changeStep1Input,
	payload
});

export const changeStep1StatusAction = (payload) => ({
	type: changeStep1Status,
	payload
});

export const changeStep1MobileOtpStatusAction = (payload) => ({
	type: changeStep1MobileOtpStatus,
	payload
});

export const changeStep1EmailOtpStatusAction = (payload) => ({
	type: changeStep1EmailOtpStatus,
	payload
});

export const changeStep1ErrorAction = (payload) => ({
	type: changeStep1Error,
	payload
});

export const sendMobileOtpAction = (payload,callback) => ({
	type: sendMobileOtp,
	payload,
	callback
});

export const checkMobileOtpAction = (payload) => ({
	type: checkMobileOtp,
	payload
});

export const verifyMobileOtpAction = (payload) => ({
	type: verifyMobileOtp,
	payload
});

export const sendEmailOtpAction = (payload) => ({
	type: sendEmailOtp,
	payload
});

export const checkEmailOtpAction = (payload) => ({
	type: checkEmailOtp,
	payload
});

export const verifyEmailOtpAction = (payload) => ({
	type: verifyEmailOtp,
	payload
});
