import '../../modal.css';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { CommonInput } from '../../../inputs/CommonInput';
import { CommodityTab, CurrencyTab, EquityTab, FutureOptionTab } from './TabComponent';
import { SubmitBtn } from '../../../buttons/SubmitBtn';
import { postApi, } from './../../../../services/common';

const tabs = [
	{
		label: 'Equity',
		component: <EquityTab />
	},
	{
		label: 'Future Option',
		component: <FutureOptionTab />
	},
	{
		label: 'Commodity',
		component: <CommodityTab />
	},
	{
		label: 'Currency',
		component: <CurrencyTab />
	}
];

export const AddBrokerage = ({ title, isModalOpen, handleModel, closeModal, tradingOptions }) => {
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedOptionsData, setSelectedOptionsData] = useState([]);
	const [equityData, setEquityData] = useState([]);
	const [futureOptionData, setFutureOptionData] = useState([]);
	const [commodityData, setCommodityData] = useState([]);
	const [currencyData, setCurrencyData] = useState([]);


	useEffect(() => {
		handleTabChange(selectedTab);
	}, [selectedTab]);

	const handleTabChange = async (index) => {
		try {
			const segment = tabs[index].label;
			const payload = {
				Segment: segment
			};
			const response = await postApi(payload, '/GetBrokrageSlab');
			const tradingOptions = response.data.data;
			tradingOptions && tradingOptions.length > 0 && setSelectedTab(index);
		} catch (error) {
			console.error('Error fetching trading options:', error);
		}
	};

	const handleSelectOptions = ({ type, value }) => {
		setSelectedOptionsData(value);
	};

	const handleSubmit = async () => {
		try {
			const segment = tabs[selectedTab].label;
			const payload = {
				Segment: segment,
				BrokerageArray1: selectedOptionsData,
				BrokerageArray2: "",
				BrokerageType1: "",
				BrokerageType2: "",
				PlanName: "Name"
			};

			const response = await postApi(payload, '/AddBrokeragePlan');
			console.log('response', response);
			closeModal();
		} catch (error) {
			console.error('Error handling submit:', error);
		}
	};


	return (
		<div>
			<Modal
				title={title || ''}
				open={isModalOpen}
				onCancel={() => closeModal()}
				centered={true}
				width={670}
				closeIcon={false}
				footer={null}
				className="w-full"
			>
				<div className="w-full mt-5">
					<div className="mb-10">
						<CommonInput
							label="Brokerage Plan Name"
							isImportant={true}
							placeholder="Enter Brokerage plan name"
						/>
					</div>
					<div className="pb-4 mb-2.5 flex justify-items-center">
						{tabs.map((tab, index) => (
							<span
								key={`tab_${index}`}
								className={`pb-4 flex-grow text-center text-lg font-medium font-poppinsMedium cursor-pointer transition-colors duration-300 ease-in-out ${selectedTab === index ? 'border-b-4 border-[#5367FC]' : 'border-b-2'
									}`}
								onClick={() => setSelectedTab(index)}
							>
								{tab.label}
							</span>
						))}
					</div>
					{tabs[selectedTab].label === 'Equity' && (
						<EquityTab
							tradingOptions={tradingOptions}
							handleSelectOptions={handleSelectOptions}
							selectedOptionsData={selectedOptionsData}
							equityData={equityData}
						/>
					)}
					{tabs[selectedTab].label === 'Future Option' && (
						<FutureOptionTab
							tradingOptions={tradingOptions}
							handleSelectOptions={handleSelectOptions}
							selectedOptionsData={selectedOptionsData}
							futureOptionData={futureOptionData}
						/>
					)}
					{tabs[selectedTab].label === 'Commodity' && (
						<CommodityTab
							tradingOptions={tradingOptions}
							handleSelectOptions={handleSelectOptions}
							selectedOptionsData={selectedOptionsData}
							commodityData={commodityData}
						/>
					)}
					{tabs[selectedTab].label === 'Currency' && (
						<CurrencyTab
							tradingOptions={tradingOptions}
							handleSelectOptions={handleSelectOptions}
							selectedOptionsData={selectedOptionsData}
							currencyData={currencyData}
						/>
					)}
					<div className="w-full mb-[30px] flex items-center justify-center">
						<SubmitBtn height="h-10" handleSubmitBtn={handleSubmit} />
					</div>

				</div>
			</Modal>
		</div>
	);
};
