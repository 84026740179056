import { MainTitle } from '../../components/common/MainTitle';
import { SingleDetail } from './SingleDetail';

export const OccupationalDetail = ({ reviewData, occupation, annualIncome, education, kycStep4,reviewFilesData,isVerify,selectedData }) => {
	const income = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document === 'Income Proof'))
	
	
	return ( 
		<div className='column-row mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]'> 
		<div className="column-col-12">
			<div className="mb-5">
				<MainTitle title="Occupational Details"/>
			</div>
			<div className="grid grid-cols-12 gap-10">
				{/* {JSON.stringify(occupation)} */}
				<div className="col-span-10">
					<SingleDetail label="Occupation" value={reviewData?.occupation || reviewData?.OccupationName || occupation || '-'} />
					<SingleDetail label="Annual Income" value={reviewData?.annual_income || reviewData?.AnnualName || annualIncome || '-'} isVerify={isVerify} reviewData={reviewData} selectedData={selectedData} />
					<SingleDetail label="Education" value={reviewData?.education || education || reviewData?.EducationName || ''} />
					<SingleDetail label="Trading Experience" value={reviewData?.CommodityExperience == 0 ? "New":reviewData?.CommodityExperience == 1?'1-5 Year':reviewData?.CommodityExperience == 2?'5-10 Year':reviewData?.CommodityExperience == 3?'10-15 Year':reviewData?.CommodityExperience == 4?'15-20 Year':'-'} />
				</div>
			</div>
		</div>
		{/* {income &&
			<div className='column-col-6"'>
		  <img src={income?.ImagePath} alt='image' className='bank-side-img' />	
		 </div>
		} */}
		
		</div>
	);
};
