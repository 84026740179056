
// import { useState, useEffect } from 'react';

// export const Pagination = ({ gotoPage, pageIndex, pageOptions, nextPage, previousPage, canNextPage, canPreviousPage, noOfRows }) => {
//   const [currentPage, setCurrentPage] = useState(pageIndex);

//   useEffect(() => {
//     setCurrentPage(pageIndex);
//   }, [pageIndex]); // Update currentPage whenever pageIndex changes

//   const onChangePageIndexInput = (newPage) => {
//     setCurrentPage(newPage);
//     const page = newPage ? Number(newPage) - 1 : 0;
//     gotoPage(page);
//   };

//   const handlePrev = () => {
//     if (canPreviousPage) {
//       previousPage();
//       setCurrentPage(currentPage - 1);
//     }
//   };
//   const handleNext = () => {
//     if (canNextPage) {
//       nextPage();
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   return (
//     <div className="my-7 flex flex-col md:flex-row items-center justify-between w-full gap-y-5">
//       <div className="text-[#808080] font-poppinsRegular whitespace-nowrap">
//         {currentPage + 1 + '0'} out of {noOfRows} entries
//       </div>
//       <div className="flex items-center">
//         <button
//           onClick={handlePrev}
//           disabled={!canPreviousPage}
//           className={`md:mx-[5px] md:w-10 md:h-10 inline-flex items-center justify-center rounded-[50%] p-2 md:p-0 md:rounded-full hover-within:shadow-lg ${currentPage !== 0 ? 'bg-[#5367FC] text-white' : 'bg-[rgba(186,186,186,0.65)]'}`}
//         >
//           <svg className="w-5 h-5 md:-ml-1" viewBox="0 0 20 20" fill="#fff">
//             <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
//           </svg>
//         </button>
//         <div className="relative inline-flex items-center w-full md:w-auto mx-2">
//           {pageOptions?.map((page, index) => (
//             <button
//               key={index}
//               onClick={() => onChangePageIndexInput(page)}
//               className={`mx-[3px] md:mx-[5px] w-7 md:w-10 h-7 md:h-10 inline-flex items-center justify-center rounded-full font-semibold font-poppinsSemiBold ${currentPage === index ? 'bg-[#5367FC] text-white' : 'text-[#5367FC] hover:bg-[rgba(186,186,186,0.65)]'}`}
//             >
//               {page + 1}
//             </button>
//           ))}
//         </div>
//         <button
//           onClick={handleNext}
//           disabled={!canNextPage}
//           className={`md:mx-[5px] md:w-10 md:h-10 inline-flex items-center justify-center rounded-[50%] p-2 md:p-0 md:rounded-full ${currentPage !== pageOptions?.length - 1 ? 'bg-[#5367FC] text-white' : 'bg-[rgba(186,186,186,0.65)]'}`}
//         >
//           <svg className="w-5 h-5 md:-ml-1" viewBox="0 0 20 20" fill="#fff">
//             <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
//           </svg>
//         </button>
//       </div>
//     </div>
//   );
// };
import React, { useState, useEffect } from 'react';

export const Pagination = ({ gotoPage, pageIndex, pageOptions, nextPage, previousPage, canNextPage, canPreviousPage, noOfRows }) => {
  const [currentPage, setCurrentPage] = useState(pageIndex);

  useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex]); // Update currentPage whenever pageIndex changes

  const handlePrev = () => {
    if (canPreviousPage) {
      previousPage();
    }
  };

  const handleNext = () => {
    if (canNextPage) {
      nextPage();
    }
  };

  return (
    <div className="my-7 flex flex-col md:flex-row items-center justify-between w-full gap-y-5">
      {/* <div className="text-[#808080] font-poppinsRegular whitespace-nowrap">
        {currentPage * 10 + 1} - {Math.min((currentPage + 1) * 10, noOfRows)} out of {noOfRows} entries
      </div> */}
      <div></div>
      <div className="flex items-center">
        <button
          onClick={handlePrev}
          disabled={!canPreviousPage}
          className={`md:mx-[5px] md:w-10 md:h-10 inline-flex items-center justify-center rounded-[50%] p-2 md:p-0 md:rounded-full hover-within:shadow-lg ${currentPage !== 0 ? 'bg-[#5367FC] text-white' : 'bg-[rgba(186,186,186,0.65)]'}`}
        >
          <svg className="w-5 h-5 md:-ml-1" viewBox="0 0 20 20" fill="#fff">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        </button>
        <div className="relative inline-flex items-center w-full md:w-auto mx-2">
          {pageOptions?.map((page, index) => (
            <button
              key={index}
              onClick={() => gotoPage(page)}
              className={`mx-[3px] md:mx-[5px] w-7 md:w-10 h-7 md:h-10 inline-flex items-center justify-center rounded-full font-semibold font-poppinsSemiBold ${currentPage === index ? 'bg-[#5367FC] text-white' : 'text-[#5367FC] hover:bg-[rgba(186,186,186,0.65)]'}`}
            >
              {page + 1}
            </button>
          ))}
        </div>
        <button
          onClick={handleNext}
          disabled={!canNextPage}
          className={`md:mx-[5px] md:w-10 md:h-10 inline-flex items-center justify-center rounded-[50%] p-2 md:p-0 md:rounded-full ${currentPage !== pageOptions?.length - 1 ? 'bg-[#5367FC] text-white' : 'bg-[rgba(186,186,186,0.65)]'}`}
        >
          <svg className="w-5 h-5 md:-ml-1" viewBox="0 0 20 20" fill="#fff">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );
};
