export const changeStep3Error = 'CHANGE_STEP7_ERROR';
export const uploadSelfie = 'UPLOAD_SELFIE';
export const clearSelfie = 'CLEAR_SELFIE';
export const uploadImage = 'UPLOAD_IMAGE';

export const changeStep3ErrorAction = (payload) => ({
    type: changeStep3Error,
    payload
});

export const uploadSelfieAction = (payload,callback) => ({
    type: uploadSelfie,
    payload,callback
});

export const clearSelfieAction = () => ({
    type: clearSelfie,
});

export const uploadImageAction = (payload) => ({
	type: uploadImage,
	payload
});
