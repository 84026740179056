export const getRoleMaster = 'GET_ROLE_MASTER'
export const setRoleMaster = 'SET_ROLE_MASTER'
export const getBranchName = 'GET_BRANCH_NAME'
export const setBranchName = 'SET_BRANCH_NAME'
export const getBranchCode = 'GET_BRANCH_CODE'
export const setBranchCode = 'SET_BRANCH_CODE'
export const submitform = 'SUBMIT_FORM'
export const getDesignationMaster = 'GET_DESIGNTAION_MASTER'
export const setDesignationMaster = 'SET_DESIGNTAION_MASTER'
export const addUser = 'ADD_USER'
export const inputValidation = 'IS_ERROR'
export const clearBranchCode = 'CLEAR_BRANCH_CODE'
export const clearBranchName = 'CLEAR_BRANCH_NAME'
export const clearInputValidation = 'CLEAR_ERROR'


export const inputValidationAction = (payload) => ({
    type: inputValidation,
    payload
});

export const clearInputValidationAction = (payload) => ({
    type: clearInputValidation,
    payload
});


export const getRoleMasterAction = (payload) => ({
    type: getRoleMaster,
    payload,
});

export const setRoleMasterAction = (payload) => ({
    type: setRoleMaster,
    payload
});

export const getBranchNameAction = (payload) => {
    return ({
        type: getBranchName,
        payload,
    })
};

export const setBranchNameAction = (payload, callback) => ({
    type: setBranchName,
    payload,
    callback
});

export const getBranchCodeAction = (payload,) => ({
    type: getBranchCode,
    payload,

});

export const setBranchCodeAction = (payload) => ({
    type: setBranchCode,
    payload,
});

export const clearBranchCodeAction = (payload) => ({
    type: clearBranchCode,
    payload,
});

export const clearBranchNameAction = (payload) => ({
    type: clearBranchName,
    payload,
});

export const getDesignationMasterAction = (payload) => ({
    type: getDesignationMaster,
    payload,
});

export const setDesignationMasterAction = (payload,) => ({
    type: setDesignationMaster,
    payload,
});

export const addUserAction = (payload) => ({
    type: addUser,
    payload,
});
