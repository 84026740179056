export const startLoader = 'START_LOADER';
export const stopLoader = 'STOP_LOADER';

export const startLoaderAction = (payload) => ({
	type: startLoader,
	payload
});

export const stopLoaderAction = (payload) => ({
	type: stopLoader,
	payload
});
