import { OutsideClickListner } from '../../hooks/OutsideClickListner';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from "react-infinite-scroller";
import axios from 'axios';
export const BranchListDropdown = ({ icon, label, isImportant, height, autoFocus, isDisabled, placeholder, options, selectedValue, inputType, border, handleSelect, isSelectedBankName, isClearData,isBankNameVal }) => {
    const [optionsList, setOptionsList] = useState([]);
    const wrapperRef = useRef(null);
    const [isSelected, setIsSelected] = useState(false);
    const [isBranchName, setBranchName] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    console.log('isBranchName', isBranchName, isBankNameVal?.length)

    useEffect(() => {
        setBranchName('')
        setSelectedOption([])
        setOptionsList([])
    }, [isClearData])
    

    useEffect(() => {
        setIsLoading(true);
        setOptionsList([]);
        setTimeout(() => {
            if (isSelectedBankName) {
                fetchOptions();
            }
        }, 1000);
    }, [isSelectedBankName]);

    const fetchOptions = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `https://payment.bigul.app/api/banks/${isSelectedBankName}/branch`
            );
            const responseData = response.data;
            const data = responseData?.branch_code_with_city_arr;
            setOptionsList(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching options:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setOptionsList([])
        setBranchName('')
        fetchOptions()
    }, [isBankNameVal?.length == 0])
    

    const handleOption = (value, branchId) => {
        setBranchName(value);
        setIsSelected(false);
        handleSelect(branchId);
    };

    const showItems = (posts) => {
        return posts.slice(0, records).map((post) => (
            <li
                className="px-3 py-2 my-0.5 rounded-md hover:bg-[#F6F8F9] cursor-pointer"
                key={post?.id}
                style={{
                    backgroundColor: selectedOption?.includes(post?.id) && '#e7e7e7',
                }}
                onClick={() => handleOption(post?.branch_name, post?.id)}
            >
                {post?.branch_name}
            </li>
        ));
    };

    const itemsPerPage = 10;
    const [hasMore, setHasMore] = useState(true);
    const [records, setRecords] = useState(itemsPerPage);

    const loadMore = () => {
        if (records === optionsList?.length) {
            setHasMore(false);
        } else {
            setTimeout(() => {
                setRecords(records + itemsPerPage);
            }, 2000);
        }
    };

    //seacrh item
    const strIncludes = (str1, str2) => {
        if (_.isString(str1) && _.isString(str2)) {
            const lStr1 = str1.toLowerCase();
            const lStr2 = str2.toLowerCase();
            return lStr1.includes(lStr2);
        }
        return false;
    };

    const filterBankName = async (e) => {
        setIsSelected(true);
        setBranchName(e.target.value);
        if (!e.target.value) {
            fetchOptions()
        }
        let SearchList = [];
        if (_.isArray(optionsList) && optionsList.length > 0) {
            SearchList = optionsList;
            if (_.isString(e.target.value) && e.target.value !== '') {
                SearchList = _.filter(SearchList, uData => {
                    let uname =
                        _.isString(uData.branch_name) && strIncludes(uData.branch_name, e.target.value);
                    return uname;
                });
            } else {
                fetchOptions()
            }
            setOptionsList(SearchList);
        } else {
            if (optionsList.length == 0) {
                setOptionsList(SearchList);
            }
        }
    };

    const handleOutsideClick = () => {
        setIsSelected(false);
    };

    OutsideClickListner({ ref: wrapperRef, closeBox: handleOutsideClick });

    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={''}
            useWindow={false}
        >
            <div className="w-full flex flex-col">
                {
                    label && (
                        <label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">
                            {
                                icon && <img
                                    className="max-h-[22px] mr-1.5 object-contain"
                                    alt="input_icon"
                                    src={icon}
                                />
                            }
                            <span className="mr-2">{label}</span>
                            {isImportant && <span className="text-[#EA0000]">*</span>}
                        </label>
                    )
                }
                <div
                    ref={wrapperRef}
                    className={`w-full h-full rounded-[10px]
			  ${isSelected && 'bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10'}
        ${height || 'h-[47px]'}
		  `}>
                    <div
                        disabled={isDisabled}
                        className={`px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]
						${border || ''}
						${height || 'h-[47px]'}
					`}
                        onClick={() => setIsSelected(!isSelected)}
                    >
                        <input
                            className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white "
                            type="text"
                            placeholder={placeholder}
                            value={isBranchName !== "" ? isBranchName : ""}
                            autoFocus={true}
                            onChange={filterBankName}
                        />
                        <svg
                            className={`fill-[#808080] h-6 w-6 transform duration-150 ease-in-out
						${isSelected && 'rotate-180'}
					`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                        </svg>
                    </div>

                    <div
                        className={`w-full transition-all duration-700 top-[49px] h-[100px] overflow-y-scroll rounded-[10px] border-b-lg font-poppinsRegular bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]
					${isSelected ? 'absolute z-50' : 'hidden'} 
				`}
                    >
                        <ul className="px-1.5 py-1 my-2">
                            {showItems(optionsList)}
                            {isLoading && <li>Loading ....</li>}
                            {optionsList?.length === 0 && !isLoading && <li>No record found!</li>}
                        </ul>

                    </div>
                </div>
            </div>
        </InfiniteScroll>
    );
}