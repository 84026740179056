export const validateCommonInput = (value) => {
	if (value && value !== null) {
		return String(value).length > 0;
	} else {
		return false;
	}
};

export const validateUserId = (userId) => {
	return String(userId).length > 1;
};

export const validateEmail = (email) => {
	const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	return regex.test(email.toLowerCase());
};

export const validatePassword = (password) => {
	return String(password).length > 7;
};

export const validateOtp = (otp) => {
	return String(otp).length > 5;
};

export const validatePhoneNo = (value) => {
	const regexNum = /^\d{10}$/;
	return regexNum.test(value);
};

export const validatePanNo = (value) => {
	const regexNum = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
	return regexNum.test(value);
};

export const validateIfscNo = (value) => {
	const regexNum = /^[A-Za-z]{4}\d{7}$/;
	return regexNum.test(value);
};

export const validateAccountNo = (value) => {
	const regexNum = /^\d{9,20}$/;
	return regexNum.test(value);
};
