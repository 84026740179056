export const changeStep2Input = 'CHANGE_STEP6_INPUT';
export const changeStep2Error = 'CHANGE_STEP6_ERROR';
export const getIncomeProofList = 'GET_INCOME_PROOF_LIST';
export const setIncomeProofList = 'SET_INCOME_PROOF_LIST';
export const uploadBankProof = 'UPLOAD_BANK_PROOF';
export const clearBankProof = 'CLEAR_BANK_PROOF';
export const uploadSalarySlip = 'UPLOAD_SALARY_SLIP';

export const changeStep2InputAction = (payload) => ({
	type: changeStep2Input,
	payload
});

export const changeStep2ErrorAction = (payload) => ({
	type: changeStep2Error,
	payload
});

export const getIncomeProofListAction = (callback) => ({
	type: getIncomeProofList,
	callback
});

export const setIncomeProofListAction = (payload) => ({
	type: setIncomeProofList,
	payload
});

export const uploadBankProofAction = (payload,callback) => ({
	type: uploadBankProof,
	payload,
	callback
});

export const clearBankProofAction = (payload) => ({
	type: clearBankProof,
	payload
});

export const uploadSalarySlipAction = (payload) => {
	console.log("uploadBankProofSalarySlip", payload)
	return (
		{
			type: uploadSalarySlip,
			payload
		}
	)
};