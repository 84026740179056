import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep7Error, uploadSelfie ,clearSelfie, sendSelfieLink,changefileupload} from '../../actions/kyc/step7.action';

const mutableState = {
	isSendLink: false,
	selfieImage: null,
	isError: false,
	errorText: '',
	fileUpload:false
};
const initState = {
	...mutableState
};

export const kycStep7Reducer = (state = initState, action) => {
	const { isError, errorText } = action?.payload || {};
	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
	switch (action.type) {
		case clearKyc:
			return {
				...mutableState
			};
		case changeStep7Error:
			return {
				...state,
				isError,
				errorText
			};
		case sendSelfieLink:
			return {
				...state,
				isSendLink: true,
			};
		case uploadSelfie:
			return {
				...state,
				isSendLink: false,
				selfieImage: imageUrl || null,
				isError: false,
				errorText: ''
			};
		case clearSelfie:
			return {
				...state,
				selfieImage: null,
				isError: false,
				errorText: ''
			};
		case changefileupload:
			return {
				...state,
				fileUpload:action.payload
			
			};
		default:
			return state;
	}
};
