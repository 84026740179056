import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { pngAssets, svgAssets } from '../../assets/asset';
import { MyPopover } from '../../components/popover/Popover';
import { TableProgressBar } from '../../components/progressBar/TableProgressBar';
import { ReactTable } from '../../components/reactTable/ReactTable';
import { PopoverChildComp, PopoverParentComp } from '../../components/reactTable/ReactTablePopupBtn';
import { ApplicationWidget } from '../../components/widgets/Applications';
import { Date } from '../../components/widgets/Date';
import { getApplicationAction, getApplicationStatusAction, setApplicationStatusAction } from '../../redux/actions/application.action';
import { verificationSteps } from '../kyc/kycData';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { postApi, postApiWithoutLoader } from '../../services/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'antd';
import { store } from '../../redux/store';
import { startLoaderAction, stopLoaderAction } from '../../redux/actions/app.action';


export const RMDashboard = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const widgetIconBgColor = {
		'Reject': {
			bgColor: '#E0E3FF',
			icon: pngAssets.dashboard.all,
			route: '/rejected'
		},

		'Incompte': {
			bgColor: '#C3FFB9',
			icon: pngAssets.dashboard.reActive,
			route: '/incomplete'
		}
	};

	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const check_status = localStorage.getItem('checkStatus');
	const { applicationStatus } = useSelector(state => state?.application || []);
	const [rejectedApplication, setRejext] = useState([])
	const [incompleteApplication, setIncomplete] = useState([])
	const [steps, setSteps] = useState(verificationSteps || []);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsmodel] = useState(check_status == "true" ? true : false);
	const [response1, setResponse1] = useState({});
	const [response2, setResponse2] = useState({});
	const [response3, setResponse3] = useState({});
	const [mobile, setMobile] = useState(userDetail?.mobile?.length > 9 ? userDetail?.mobile : '')
	const [email, setEmail] = useState(userDetail?.email || '')
	const [statusCheck, setCheckStatus] = useState(true)

	useEffect(() => {
		dispatch(setApplicationStatusAction({}))
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);


	const datePickerRef = useRef(null);

	const handleCustomDateClick = () => {
		datePickerRef.current.setOpen(true);
	};

	const handleTodayFilter = (applicationType) => {
		const currentDate = dayjs().format('DD MMM YYYY');

		dispatch(getApplicationAction(applicationType, {
			Date: currentDate,
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			KYCStatus: "progress",
			start: 1,
			end: 20,
			search: ''
		})
		)
		dispatch(getApplicationStatusAction(
			{
				Date: currentDate,
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id
			}))
	};

	const handleYesterdayFilter = (applicationType, status) => {
		const yesterday = dayjs().subtract(1, 'day').format('DD MMM YYYY');
		dispatch(getApplicationAction(applicationType, {
			Date: yesterday,
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			KYCStatus: "progress",
			start: 1,
			end: 4,
			search: ''
		}));
		dispatch(getApplicationStatusAction(
			{
				Date: yesterday,
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id
			}));
	};
	const handleCustomDateFilter = (date, applicationType, status) => {
		const customDate = dayjs(date).format('DD MMM YYYY');
		dispatch(getApplicationAction(applicationType, {
			Date: customDate,
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			KYCStatus: "reviewpending",
			start: 1,
			end: 4,
			search: ''
		}));
		dispatch(getApplicationStatusAction(
			{
				Date: customDate,
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id
			}));
	};

	const handleAPICall = async () => {
		const payload = {
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id
		}

		try {
			const urls = ['/GetWeeklyTotalApplication', '/GetWeeklyVerifiedApplication', '/GetWeeklyPendingApplication'];
			const res1 = await postApiWithoutLoader(payload, urls[0]);
			const res2 = await postApiWithoutLoader(payload, urls[1]);
			const res3 = await postApiWithoutLoader(payload, urls[2]);
			const dateValueArray = Object.entries(res1?.data).map((ite) => ite);
			if (res1.data) {
				const values = dateValueArray.map((pair) => pair[1]);
				setResponse1(values);
			}
			if (res2.data) {
				const dateValueArray1 = Object.entries(res2?.data).map((ite) => ite);
				const values1 = dateValueArray1.map((pair) => pair[1]);
				setResponse2(values1);
			}
			if (res3.data) {
				const dateValueArray2 = Object.entries(res3?.data).map((ite) => ite);
				const values2 = dateValueArray2.map((pair) => pair[1]);
				setResponse3(values2);
			}
			else {
				toast('something went wrong')

			}

		} catch (error) {
			toast('something went wrong')
		}
	};

	const columns = [
		{
			Header: 'Client Name', accessor: 'clientName', minWidth: 130,
			Cell: ({ row }) => (
				`${row.original?.FName || ''} ${row.original?.MName || ''} ${row.original?.LName || ''}`
			),
		},
		{
			Header: 'PAN', accessor: 'pan', minWidth: 130,
			Cell: ({ row }) => (
				row.original?.PanNo
			)
		},
		{
			Header: 'Mobile Number',
			accessor: 'mobileNumber',
			minWidth: 130,
			Cell: ({ row }) => (

				row.original?.mobile ? 'XXXXXX' + row.original?.mobile.slice(-4) : ''
			)
		},
		{
			Header: 'Rm name',
			accessor: 'rmname',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.rm_name ? row.original.rm_name : '-'
			)
		},

		{
			Header: 'Steps',
			accessor: 'steps',
			minWidth: 150,
			Cell: ({ row }) => (
				<TableProgressBar
					selectedStep={row.original?.step}
					steps={steps || []}
					setSteps={setSteps}
					width="w-full"
					circleDim="10px"
					circleRadius="1px"
					lineDim="h-[2px]"
				/>
			)
		},
		{
			Header: 'Created At',
			accessor: 'createdAt',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.created_at && dayjs(row.original?.created_at).format('DD MMM YYYY' + ' ' + 'HH:mm:ss')
			),
			sortable: true
		},
		{
			Header: 'Last Updated At',
			accessor: 'updatedAt',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.updated_at && dayjs(row.original?.updated_at).format('DD MMM YYYY' + ' ' + 'HH:mm:ss')
			),
			sortable: true
		},
		{
			Header: 'Actions',
			accessor: 'actions',
			minWidth: 60,
			Cell: ({ row }) => (
				<MyPopover
					PopoverParentComp={PopoverParentComp}
					PopoverChildComp={PopoverChildComp}
					isVerify={true}
					redirectUrl="application/view-application"
					applicationType="allApplication"
					selectedData={row.original}
					isView={true}
					handleEdit={false}
					viewUrl="application/view-application"
				/>
			)
		}
	];
	const columns1 = [
		{
			Header: 'Client Name', accessor: 'clientName', minWidth: 130,
			Cell: ({ row }) => (
				`${row.original?.FName || ''} ${row.original?.MName || ''} ${row.original?.LName || ''}`
			),
		},
		{
			Header: 'PAN', accessor: 'pan', minWidth: 130,
			Cell: ({ row }) => (
				row.original?.PanNo
			)
		},
		{
			Header: 'Mobile Number',
			accessor: 'mobileNumber',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.mobile ? 'XXXXXX' + row.original?.mobile.slice(-4) : ''

			)
		},
		{
			Header: 'Rm name',
			accessor: 'rmname',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.mobile ? row.original.rm_name : '-'
			)
		},

		// {
		// 	Header: 'Steps',
		// 	accessor: 'steps',
		// 	minWidth: 150,
		// 	Cell: ({ row }) => (
		// 		<TableProgressBar
		// 			selectedStep={row.original?.step}
		// 			steps={steps || []}
		// 			setSteps={setSteps}
		// 			width="w-full"
		// 			circleDim="10px"
		// 			circleRadius="1px"
		// 			lineDim="h-[2px]"
		// 		/>
		// 	)
		// },
		{
			Header: 'Created At',
			accessor: 'createdAt',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.created_at && dayjs(row.original?.created_at).format('DD MMM YYYY' + ' ' + 'HH:mm:ss')
			),
			sortable: true
		},
		{
			Header: 'Last Updated At',
			accessor: 'updatedAt',
			minWidth: 130,
			Cell: ({ row }) => (
				row.original?.updated_at && dayjs(row.original?.updated_at).format('DD MMM YYYY' + ' ' + 'HH:mm:ss')
			),
			sortable: true
		},
		{
			Header: 'Actions',
			accessor: 'actions',
			minWidth: 60,
			Cell: ({ row }) => (
				<MyPopover
					PopoverParentComp={PopoverParentComp}
					PopoverChildComp={PopoverChildComp}
					isVerify={true}
					redirectUrl="application/view-application"
					applicationType="allApplication"
					selectedData={row.original}
					isView={true}
					handleEdit={false}
					viewUrl="/application/rejected-application"
				/>
			)
		}
	];

	useEffect(() => {
		fetchData1()
		fetchData()
	}, []);

	const fetchData1 = async () => {
		setCheckStatus(true)
		try {
			const payload = {
				UserId: userDetail?.user_id, RoleId: userDetail?.role_id
			}
			const response = await postApiWithoutLoader(payload, '/GetTotalApplicationStatus');
			if (response?.data.data) {
				setCheckStatus(false)
				dispatch(setApplicationStatusAction({ data: response?.data.data || [] }))

			}
			else {
				toast('something went wrong')


			}
		}
		catch (error) {
			toast('something went wrong')


		}
	};
	const fetchData = async () => {
		try {
			const payload = {
				UserId: userDetail?.user_id,
				RoleId: userDetail?.role_id,
				start: 1,
				end: 10,
				search: '',

			}
			const response = await postApi(payload, '/GetDashboardApplicationDetails');

			if (response.status) {
				setRejext(response?.data?.data?.rejected)
				setIncomplete(response?.data?.data?.incompleted)
			}
			else {
				setRejext([])
				setIncomplete([])
			}
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const handleCancel = () => {
		setIsmodel(false);
	};



	const handleUpdate = async () => {
		try {
			if (!mobile || mobile.length < 10 || mobile.length > 10) {
				toast('Please enter a valid mobile number');
				return;
			}
			if (!isValidEmail(email)) {
				toast('Please enter a valid email address');
				return;
			}
			const payload = {
				user_id: userDetail?.user_id,
				user_mobile: mobile,
				user_email: email
			};
			const response = await postApi(payload, '/updateUserProfile');
			if (response?.data?.success === true) {
				localStorage.setItem('checkStatus', false);
				setIsmodel(false);
			} else {
				toast(response?.data?.msg || response.data.message || 'something went wrong');
			}
		} catch (error) {
			// Handle any errors that occur during the execution of the function
			console.error('An error occurred:', error);
			toast('An unexpected error occurred. Please try again later.');
		}
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};
	return (
		<>
			<Modal
				title="Please update  your profile to get instant updates"
				className="w-full p-[33px]"
				width={'50%'}
				style={{ textAlign: 'center' }}
				open={isModalOpen}
				centered={true}
				closable={false}
				// onCancel={handleCancel}
				footer={null}
			>
				<div className="mx-auto px-6 py-5 ">
					<div className="flex justify-between mb-2.5">
						{
							<div className="">
								<input
									type='tel'
									className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
									placeholder="Please enter mobile number"
									value={mobile}
									onChange={(e) => setMobile(e.target.value)}
									maxLength={10}
								/>
								<input
									type='text'
									className="mt-5 h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
									placeholder="Please enter email "
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								// autoFocus={true}
								/>
								<div style={{
									background: '#121212',

									height: '50px',
									width: '150px',
									// marginBottom: '30px',
									marginTop: '20px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									alignSelf: 'center', borderRadius: 10, cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto'
								}} onClick={handleUpdate}   >
									<p style={{ color: 'white', fontWeight: 'bold' }}>Update</p>
								</div>
							</div>
						}


					</div>
				</div>
			</Modal>
			{/* <div className="mb-[30px] flex flex-wrap">
				<Date icon={svgAssets.dashboard.todayCalendar} label="Today" />
				<Date icon={svgAssets.dashboard.yesterdayCalendar} label="Yesterday" />
				<Date icon={svgAssets.dashboard.calendar} label="Custom Date" />
			</div> */}
			{/* <div className="mb-[30px] flex">
				<div onClick={handleTodayFilter}>
					<Date icon={svgAssets.dashboard.todayCalendar} label="Today" />
				</div>
				<div onClick={handleYesterdayFilter}>
					<Date icon={svgAssets.dashboard.yesterdayCalendar} label="Yesterday" />
				</div>

				<div className="date-picker-container" onClick={handleCustomDateClick}>
					<Date icon={svgAssets.dashboard.calendar} label='Custom Date' />
					<DatePicker
						ref={datePickerRef}
						// selected={customDate}
						onChange={handleCustomDateFilter}
						onClickOutside={() => datePickerRef.current.setOpen(false)}
						dateFormat=" dd MMM YYYY"
						showPopperArrow={false}
						customInput={<input type="hidden" />}
					/>
				</div>
			</div> */}
			<ToastContainer autoClose={1000} />
			<div className="mb-[30px] grid lg:grid-cols-3 lg:gap-x-9 gap-y-9">
				<ApplicationWidget title="All Applications" numberOfApplications={(applicationStatus?.[0]?.TotalRecords) ? applicationStatus?.[0]?.TotalRecords : 0} graph={response1} status={statusCheck} />
				<ApplicationWidget title="Verified Applications" numberOfApplications={(applicationStatus?.[0]?.KYCDone) ? applicationStatus?.[0]?.KYCDone : 0} graph={response2} status={statusCheck}  />
				<ApplicationWidget title="Pending Verification" numberOfApplications={(applicationStatus?.[0]?.TotalReviewPending) ? applicationStatus?.[0]?.TotalReviewPending : 0} graph={response3} status={statusCheck} />
			</div>
			<div>
				<div className="w-full py-5 px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex flex-col md:flex-row md:items-center justify-between mb-7">
						<div className="flex flex-row  justify-between lg:items-center items-left mb-4 lg:mb-0">
							<h6 className="font-semibold text-[22px] leading-[33px] mr-2.5 whitespace-nowrap">Rejected Applications</h6>
							{/* <span className="px-3 py-[1px] rounded-md shrink items-center font-semibold bg-[#FFF1D7]">{applicationStatus?.[0]?.TotalRMRework}</span> */}
						</div>
						<button onClick={() => navigate(`/application${widgetIconBgColor?.['Reject']?.route}`)} className="px-3 py-[1px] rounded-md	flex items-center justify-center text-center font-semibold bg-[#EBFFFA] shadow-[0px_3px_16px_rgba(171,171,171,0.25)] whitespace-nowrap">View All</button>
					</div>
					{
						isLoading ?
							<SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
								<Skeleton count={10} style={{ height: '30px', marginTop: '10px' }} borderRadius={10} />
							</SkeletonTheme>
							:
							<ReactTable
								columns={columns1}
								data={rejectedApplication || []}
								check={false}

							/>
					}
				</div>
				<div className="w-full py-5 px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex flex-col md:flex-row md:items-center justify-between mb-7">
						<div className="flex flex-row items-center items-left mb-4 md:mb-0 gap-x-2">
							<h6 className="font-semibold text-[22px] leading-[33px] mr-2.5 whitespace-nowrap">Incompleted Applications</h6>
							{/* <span className="px-3 py-[1px] rounded-md	shrink items-center font-semibold bg-[#FFF1D7]">{applicationStatus?.[0]?.TotalInProgress}</span> */}
						</div>
						<button onClick={() => navigate(`/application${widgetIconBgColor?.['Incompte']?.route}`)} className="px-3 py-[1px] rounded-md	flex justify-center items-center font-semibold bg-[#EBFFFA] shadow-[0px_3px_16px_rgba(171,171,171,0.25)]">View All</button>
					</div>
					<ReactTable
						columns={columns}
						data={incompleteApplication || []}
						check={false}

					/>
				</div>
			</div>
		</>

	);
};