import React, { useEffect } from 'react';

export const NomineeUpdate = () => {
    useEffect(() => {
        const url = 'https://dkyc.bonanzaonline.com/nominee/'; 

        const openNewWindow = () => {
            window.open(url, '_blank');
        };

        openNewWindow();
    }, []); 

    return (
        <div>
            <p>Nominee Updation</p>
        </div>
    );
};
