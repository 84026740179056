import { changeApplicationDetail, setApplication, setApplicationStatus, setClientFile, setClientInfo, setClientRejectReason } from '../actions/application.action';

const tableFormat = {
	search: '',
	show: '',
	startDate: '',
	endDate: '',
	selectedPage: '',
	data: [],
	selectedData: {}
};

const initState = {
	allApplication: {
		...tableFormat
	},
	verifiedApplication: {
		...tableFormat
	},
	rejectedApplication: {
		...tableFormat
	},
	pendingApplication: {
		...tableFormat
	},
	incompleteApplication: {
		...tableFormat
	},
	resubmittedApplication: {
		...tableFormat
	},
	selectedApplication: '',
	clientInfo: {},
	clientFile: {},
	rejectReason: {},
	applicationStatus: {},
};

export const applicationReducer = (state = initState, action) => {
	const { applicationType, selectedData } = action?.payload || '';
	switch (action.type) {
		case changeApplicationDetail:
			return {
				...state,
				[applicationType]: {
					...state[applicationType],
					search: '',
					show: '',
					startDate: '',
					endDate: '',
					selectedPage: '',
					selectedData
				},
				selectedApplication: applicationType
			};
		case setApplicationStatus:
			return {
				...state,
				applicationStatus: action.payload.data
			};
		case setApplication:
			return {
				...state,
				[applicationType]: {
					...state[applicationType],
					data: action.payload.data
				}
			};
		case setClientInfo:
			return {
				...state,
				clientInfo: action?.payload?.data || {}
			};
		case setClientFile:
			return {
				...state,
				clientFile: action?.payload?.data || []

			};
		case setClientRejectReason:
			return {
				...state,
				rejectReason: action?.payload?.data || []

			};
		default:
			return state;
	}
};
