// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
// import { svgAssets } from '../../../assets/asset';
// import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';
// import {
// 	changeStep7ErrorAction, uploadSelfieAction,
// 	callSendSelfieLinkAction
// } from '../../../redux/actions/kyc/step7.action';
// import { DOMAIN } from '../../../config/config';
// import { getClientFilesAction } from '../../../redux/actions/kyc/review.action';


// export const UploadSelfie = ({ linktoken, isDocumentUploaded,step, index, backBtn, continueBtn, isChildComp, rejectedFieldsData,selectedStepData }) => {
// 	const dispatch = useDispatch();
// 	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
// 	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);
// 	const { panInput } = useSelector(state => state?.kycStep2 || {});
// 	const { selfieImage, isError, errorText } = useSelector(state => state?.kycStep7 || '');
// 	const kycStep1 = useSelector(state => state?.kycStep1 || {});
// 	const kycStep2 = useSelector(state => state?.kycStep2 || {});
// 	const validatePanDetails = useSelector(state => state?.kycStep2.validatePanDetails.data || {});

// 	let clientLink = DOMAIN + '/dkyc/';
// 	if (linktoken) {
// 		clientLink = DOMAIN + '/dkyc/' + linktoken + '?isSelfie=1';
// 	}

// 	const [isCopy, setIsCopy] = useState(false);
// 	const user_image = reviewFiles && reviewFiles?.filter(a => (a?.Document === 'Client Photo' || a?.Document === 'Client photo'));
// 	const lastItemIndex = user_image?.length - 1;

// const handleCopyLink = () => {
// 		navigator.clipboard.writeText(clientLink);
// 		setIsCopy(true);
// 	}

// 	return (
// 		<div className="w-full flex flex-col">
// 			<div className={`${!isChildComp}`}>
// 				<div className="mb-8 grid md:grid-cols-2 gap-x-10 items-end mt-3">
// 					<div className="flex flex-col ">
// 						<InputCopyBtnIcon
// 							icon={svgAssets.kyc.selfie}
// 							label="Selfie Link"
// 							subLabel="Share below link with client"
// 							isImportant={true}
// 							placeholder={clientLink}
// 							btnWidth="67px"
// 							btnIcon={ClipboardIcon}
// 							inputMessage={errorText}
// 							inputMessageType={isError && 'error'}
// 							handleSubmit={handleCopyLink}
// 							defaultValue={clientLink}
// 							isDisable={true}
// 							isCopy={isCopy}
// 						/>
// 						{
// 							rejectedFieldsData?.rejectedClientPhoto?.length > 0 &&
// 							<>
// 								<div className='bg-[#FFC9C9] border-[#f99f9f] border-solid border-2 px-5 py-3 rounded mt-3'>
// 									<p
// 										className='text-[#343434]-500'
// 										style={{ fontWeight: '300', fontSize: '15px', color: '#343434' }}
// 									>
// 										{rejectedFieldsData.rejectedClientPhoto[0].reason}
// 									</p>
// 								</div>
// 							</>
// 						}
// 					</div>
// 					<div className="flex flex-col relative ">
// 						<div className='hover:text-[#5466fc] relative flex items-center justify-between ' >
// 							{ user_image && user_image.map((item, index) => {
// 								const isLastItem = index === lastItemIndex;
// 								return (
// 									<>
// 										{isLastItem &&
// 									<div key={index} className='bg-white p-2 w-[150px] h-[130px] flex items-center justify-center mt-3'>
// 										<a href={item?.ImagePath} target='_blank'>
// 											<img width='80' src={item?.ImagePath} />
// 										</a>
// 									</div>}
// 									</>
// 								);
// 							})}

// 							{
// 								isDocumentUploaded?.success &&
// 								<button
// 									className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}
// 								>
// 									<img
// 										src={svgAssets.kyc.verify}
// 										className="mr-1.5"
// 									/>
// 									<span>
// 										Uploaded
// 									</span>
// 								</button>
// 							}
// 						</div>
// 					</div>
// 					{/* <div className=" flex items-center gap-x-2 px-5 mt-4">
// 						<img className="w-[19px] h-[19px]" alt="back_icon" src={svgAssets.kyc.greencheck} />
// 						<span className="col-span-5 text-[#007813] text-[13px] leading-[18px] font-medium font-poppinsMedium ">
// 							Selfie successfully submitted by client
// 						</span>
// 					</div> */}

// 				</div>
// 			</div>
// 			{/* {
// 				!isChildComp && (
// 					<div className="flex item justify-between">
// 						<BackBtn handleBackBtn={() => backBtn({ step, index })} />
// 						{
// 							selfieImage === '' ?
// 								<ContinueBtn isDisable={true} />
// 								:
// 								<ContinueBtn handleContinueBtn={handleContinueBtn} />
// 						}
// 					</div>
// 				)
// 			} */}

// 		</div>
// 	);
// };

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
import { svgAssets } from '../../../assets/asset';
import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';

import { DOMAIN } from '../../../config/config';
import { InputCopyBtnIcon1 } from '../../../components/inputs/RejectInput';


export const UploadSelfie = ({ linktoken, isChildComp, rejectedFieldsData }) => {

	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);
	const [selfieCheck, setSelfieCheck] = useState(false)

	const { isError, errorText } = useSelector(state => state?.kycStep7 || '');


	let clientLink = DOMAIN + '/dkyc/';
	if (linktoken) {
		clientLink = DOMAIN + '/dkyc/' + linktoken + '?isSelfie=1';
	}

	const [isCopy, setIsCopy] = useState(false);
	const user_image = reviewFiles && reviewFiles?.filter(a => (a?.Document === 'Client Photo' && a?.ReUploadFlag == 1));
	const lastItemIndex = user_image?.length - 1;
	const handleCopyLink = () => {
		navigator.clipboard.writeText(clientLink);
		setIsCopy(true);
	}

	return (
		<div className="w-full flex flex-col rejected-otem-list">
			<div className={`${!isChildComp}`}>
				<div className="mb-0 grid md:grid-cols-2 gap-x-10 items-center mt-0">
					<div className="flex flex-col ">
						<div className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap mb-5`} style={{ color: '#FF4343' }}>
							{rejectedFieldsData !== null ? rejectedFieldsData : 'Selfie is not valid'}

						</div>
						<InputCopyBtnIcon1
							// icon={svgAssets.kyc.selfie}
							// label="Selfie Link"
							// subLabel="Share below link with client"
							isImportant={true}
							placeholder={clientLink}
							btnWidth="67px"
							btnIcon={ClipboardIcon}
							inputMessage={errorText}
							inputMessageType={isError && 'error'}
							handleSubmit={handleCopyLink}
							defaultValue={clientLink}
							isDisable={true}
							isCopy={isCopy}
						/>

					</div>
					<div className="flex flex-col relative">
						<div className='hover:text-[#5466fc] relative flex items-center justify-end ' >
							{user_image && user_image.map((item, index) => {
								const isLastItem = index === lastItemIndex;
								return (
									<>
										{isLastItem &&
										<>
										<p>Re-uploaded Document By the client</p>
											<div key={index} className='bg-white ml-2 p-1 flex items-center justify-center'>
												<a href={item?.ImagePath} target='_blank'>
													<img width='80' src={item?.ImagePath} />
												</a>
											</div>
											</>}
									</>
								);
							})}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
