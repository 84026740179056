import React, { useEffect, useRef, useState } from 'react';
import { svgAssets } from '../../assets/asset';
import { messageTypeParameter } from '../../utils/input';

export const OtpInput = ({ icon, label, subLabel, isImportant, height, isDisable, inputType, btnText, handleSubmit, inputMessage, inputMessageType, otpValue }) => {
	const [otp, setOtp] = useState(Array(6).fill(''));
	const [concatOtp, setConcatOtp] = useState('');
	const [otpArray, setOtpArray] = useState([]);
	const inputRefs = useRef([...Array(6)].map(() => React.createRef()));
	useEffect(() => {
		let numb = otpValue
		let otpNumber = numb; // This is your OTP number

		// Convert OTP number to an array
		let otpArray = otpNumber?.split("");

		// Store OTP array in component state
		setOtpArray(otpArray);
	}, [otpValue]);

	useEffect(() => {
		if (otpValue === '000000') {
			setOtp(Array(6).fill(''))
		}
	}, [otpValue]);

	const handleChange = (e, value, index) => {
		 // Check if the value is a number using a regular expression
		const isValidNumber = /^\d+$/.test(value);
		if(isValidNumber || value === ''){
			const newOtp = [...otp];
			newOtp[index] = value;
			setOtp(newOtp);
			setConcatOtp(newOtp.toString().replaceAll(',', ''));
	
			if (value.length === 1) {
				inputRefs.current[index + 1]?.current?.focus();
			}
		}else{
			e.preventDefault();
		}
		
	};

	const handleKeyDown = (event, index) => {
		if (event?.key === 'Backspace' && otp[index].length === 0) {
			inputRefs?.current[index - 1]?.current?.focus();
		} else if (event?.key === 'Enter' && concatOtp.length > 5) {
			handleSubmit({ type: inputType, value: concatOtp });
		};
	};

	return (
		<div className="w-full flex flex-col">
			<label className="flex items-center leading-6 font-medium font-poppinsMedium">
				{
					icon && (<img
						className="max-h-[22px] mr-1.5 object-contain"
						alt="input_icon"
						src={icon}
					/>)
				}
				<span className="mr-1">{label}</span>
				{isImportant && <span className="text-[#EA0000]">*</span>}
			</label>
			{
				subLabel && (<span className="text-xs text-[#9F9F9F] font-poppinsRegular">{subLabel}</span>)
			}
			<div className="flex items-center mt-4">
				<div className="grid grid-cols-6 gap-4 mr-3.5 justify-between">
					{
						otp?.map((value, index) => (
							<input
								key={`otp_input_${index}`}
								maxLength={1}
								className="w-full px-4 text-[#353535] text-center rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular focus:outline-none"
								style={{
									height: height || '47px',
									background: '#fff'
								}}
								disabled={isDisable}
								value={value}
								type='text'
								onChange={(e) => handleChange(e, e.target.value, index)}
								onKeyDown={(event) => handleKeyDown(event, index)}
								ref={inputRefs.current[index]}
								tabIndex={index + 1}
								
							/>
						))
					}

				</div>
				<button
					className={`min-w-max px-3.5 flex items-center whitespace-nowrap rounded-r-[10px] text-white  font-medium font-poppinsMedium
						${isDisable ? 'cursor-default bg-[#007813]' : 'cursor-pointer bg-black'}
					`}
					style={{
						height: height || '47px'
					}}
					onClick={() => handleSubmit({ type: inputType, value: concatOtp })}
					disabled={isDisable}
				>
					<img className="mr-2.5" alt="send_link_img" src={svgAssets.kyc.verify} />
					<span>{btnText || 'Verify'}</span>
				</button>
			</div>

			{
				inputMessage && inputMessageType && (
					<div
						style={{
							color: messageTypeParameter[inputMessageType].color
						}}
						className="mt-2.5 flex items-center text-sm font-poppinsRegular"
					>
						<img className="mr-1.5" alt="input_message_type_icon" src={messageTypeParameter[inputMessageType].icon} />
						<span className="inline-flex items-center">{inputMessage}</span>
					</div>
				)
			}
		</div>
	);
};
