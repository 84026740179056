import { setEsignReport } from '../actions/e-sign-report.action';

const tableFormat = {
    search: '',
    show: '',
    startDate: '',
    endDate: '',
    selectedPage: '',
    data: [],
    selectedData: {}
};

const initState = {
    eSignReportData: {
        ...tableFormat
    },
};

export const eSignReportReducer = (state = initState, action) => {
    switch (action.type) {
        case setEsignReport:
            return {
                ...state,
                eSignReportData: action?.payload?.data || []

            };
        default:
            return state;
    }
};
