import { SelectDropdown } from '../dropdown/SelectDropdown';

const options = [
	{ label: '10', value: 10 },
	{ label: '20', value: 20 },
	{ label: '30', value: 30 },
	{ label: '40', value: 40 }
];

export const PageCount = () => {
	return (
		<div className="flex items-center justify-center gap-x-5 md:justify-end font-medium font-poppinsMedium text-[#231F20] w-full">
			<span>Show</span>
			<div className="w-[105px] mx-[5px]">
				<SelectDropdown
					options={options}
					disabled={true}
					border="border"
					selectedValue={20}
					height="h-[44px]"
				/>
			</div>
			<span>entries</span>
		</div>
	);
};
