import { all, call, put, takeEvery } from 'redux-saga/effects';
import { uploadImage } from '../../redux/actions/dkyc/step3.action';
import { getApi, postApiWithoutLoader, postFormApi } from '../../services/common';
import { uploadBankDetails } from '../../redux/actions/dkyc/step1.action';
import { uploadBankProof } from '../../redux/actions/dkyc/step2.action';
import { uploadSelfie } from '../../redux/actions/dkyc/step3.action';
import { uploadSignature } from '../../redux/actions/dkyc/step4.action';
import {
	getPanNumber, setPanNumberAction, checkDKycDocumentIsUploaded,
	isBankProofUploadedAction, isIncomeProofUploadedAction,
	isSelfieUploadedAction, isSignatureUploadedAction
} from '../../redux/actions/dkyc/dkyc.action';


function* uploadImageSaga({ payload,callback}) { 
	try {
		const data = yield call(postFormApi, payload, '/uploadfiles');
		yield call(callback , data?.data);
	} catch (err) {
		yield call(callback ,err);
	};
};


function* getPanNumberSaga({ payload , callback }) {
	try {
		const data = yield call(getApi, "/get-pan/" + payload.token);
		yield put(setPanNumberAction({ data: data?.data || '' }));
		yield call(callback , data?.data);

	} catch (err) {
		console.log(err);
	};
};



function* checkIsDkycDocumentUploadedSaga({ payload }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/is-file-uploaded');
		if (data?.data) {
			if (payload.document_type == 'Bank Proof') {
				yield put(isBankProofUploadedAction(data?.data));
			}
			else if (payload.document_type == 'Income Proof') {
				yield put(isIncomeProofUploadedAction(data?.data));
			}
			else if (payload.document_type == 'Client Photo') {
				yield put(isSelfieUploadedAction(data?.data));
			}
			else if (payload.document_type == 'Client Signature') {
				yield put(isSignatureUploadedAction(data?.data));
			}
		}
	} catch (err) {
		console.log(err);
	};
};


function* dkycSaga() {
	yield all([
		takeEvery(uploadImage, uploadImageSaga),
		takeEvery(uploadBankProof, uploadImageSaga),
		takeEvery(uploadSelfie, uploadImageSaga),
		takeEvery(uploadSignature, uploadImageSaga),
		takeEvery(uploadBankDetails, uploadImageSaga),
		takeEvery(getPanNumber, getPanNumberSaga),
		takeEvery(checkDKycDocumentIsUploaded, checkIsDkycDocumentUploadedSaga),
	]);
}

export default dkycSaga;
