import React, { useState } from 'react';

export const Nominee = (reviewData) => {
   const [isVisible, setIsVisible] = useState(new Array(reviewData?.reviewData?.length).fill(true));
  const [dob,setDob]=useState('')

    const toggleVisibility = (index) => {
        setIsVisible((prev) => {
            const updatedVisibility = [...prev];
            updatedVisibility[index] = !updatedVisibility[index];
            return updatedVisibility;
        });
    
      
    };
    useState(()=>{
        const parts = reviewData?.reviewData[0].birthdate.split("-");
        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
        console.log(formattedDate,'formattedDate');
        setDob(formattedDate)
    },[])

return (
        <div style={{marginTop:'15px'}}>
         {reviewData?.reviewData?.map((nominee, index) => (
                <div
                    key={index}
                    id={`accordion-collapse-${index}`}
                    data-accordion="collapse"
                    className="mt-4 mb-4 custom-accordion-ec"
                    style={{ borderBottom: "1px solid #eee" }}
                >
                    <h2 id={`accordion-collapse-heading-${index}`}>
                        <button
                            type="button"
                            className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                            onClick={() => toggleVisibility(index)}
                            aria-expanded={isVisible[index]}
                            aria-controls={`accordion-collapse-body-${index}`}
                        >
                            <span>Nominee Details {index+1}</span>
                            <svg
                                data-accordion-icon=""
                                className={`w-3 h-3 rotate-${isVisible[index] ? '0':'180'} shrink-0`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5 5 1 1 5"
                                />
                            </svg>
                        </button>
                    </h2>
                    {isVisible[index] && (
                        <div
                            id={`accordion-collapse-body-${index}`}
                            className=""
                            aria-labelledby={`accordion-collapse-heading-${index}`}
                        >
                            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900" style={{ paddingBottom: "0" }}>
                                <div className="mb-4 custom-row" style={{ display: 'flex' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Full Name"
                                            value={nominee?.nm_name}
                                            readOnly={true}

                                        />
                                    </div>
                                    <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Birthdate"
                                            value={dob}
                                            readOnly={true}

                                        />
                                    </div>
                                </div>
                                <div className="mb-4 custom-row" style={{ display: 'flex' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Email"
                                            value={nominee?.email}
                                            readOnly={true}

                                        />
                                    </div>
                                    <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Mobile"
                                            value={nominee?.mobile}
                                            readOnly={true}

                                        />
                                    </div>
                                </div>
                                <div className="mb-4 custom-row" style={{ display: 'flex' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Full Name"
                                            value={nominee?.relation == 1 ?'Spouse':nominee?.relation == 2?'Son':nominee?.relation == 3?'Doughter':nominee?.relation == 4?'Father':nominee?.relation == 5?'Mother':nominee?.relation == 6?'Brother':nominee?.relation == 7?'Sister':nominee?.relation == 8?'Grand Son':nominee?.relation == 9?'Grand Doughter':nominee?.relation == 10?'Grand Father':nominee?.relation == 11?'Grand Mother':nominee?.relation == 12?'Not Provided':'Other'}
                                            readOnly={true}

                                        />
                                    </div>
                                    <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Birthdate"
                                            value={nominee?.nm_panno || 'XXXXXX' + nominee?.nm_aadhaar_no?.slice(-4)}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 custom-row" style={{ display: 'flex' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Full Name"
                                            value={nominee?.addr1}
                                            readOnly={true}

                                        />
                                    </div>
                                    <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Birthdate"
                                            value={nominee?.addr2}
                                            readOnly={true}

                                        />
                                    </div>
                                </div>
                                <div className="mb-4 custom-row" style={{ display: 'flex' }}>
                                    {
                                        nominee.addr3 != null &&
                                        <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Full Name"
                                            value={nominee?.addr3}
                                            readOnly={true}

                                        />
                                    </div>
                                    }
                                   
                                    <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Birthdate"
                                            value={nominee?.pinCode}
                                            readOnly={true}

                                        />
                                    </div>
                                </div>
                                <div className="mb-4 custom-row" style={{ display: 'flex' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Full Name"
                                            value={nominee?.state}
                                            readOnly={true}

                                        />
                                    </div>
                                    <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Birthdate"
                                            value={nominee?.city}
                                            readOnly={true}

                                        />
                                    </div>
                                  
                                </div>
                                <div className='col-md-6 col-md-date-picker'>
                                        <input
                                            type="text"
                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                            placeholder="Enter Birthdate"
                                            value={Math.floor(nominee.sharing)+'%'}
                                           readOnly={true}

                                        />
                                    </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};


