import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { pngAssets, svgAssets } from '../../../assets/asset';
import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
import { DOMAIN } from '../../../config/config';
import { changeStep6InputAction, getIncomeProofListAction, uploadBankProofAction, getBrokerageSchemeDetailDataAction, callSendUploadIncomeProofLinkAction, uploadSalarySlipAction } from '../../../redux/actions/kyc/step6.action';
import { getClientFilesAction } from '../../../redux/actions/kyc/review.action';
import {
	callCheckDocumentIsUploadedAction
} from '../../../redux/actions/kyc/kyc.action';
import { postApi, postFormApi, postFormApiCo } from '../../../services/common';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const UploadIncomeProof = ({ isDocumentUploaded, isRefreshIncomeProof, selectedStepData, MobileNo }) => {
	const dispatch = useDispatch();
	const { incomeProof } = useSelector(state => state.kycStep6);
	const { panInput } = useSelector(state => state?.kycStep2 || {});
	const incomeProofList = incomeProof?.data || [];
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const validatePanDetails = useSelector(state => state?.kycStep2.validatePanDetails.data || {});
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const kycStep6 = useSelector(state => state?.kycStep6 || {});
	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);
	const reviewFiles2 = useSelector(state => state?.filesData || []);
	let user_doc = reviewFiles && reviewFiles?.filter(a => (a.Document === 'Income Proof'));
	user_doc = (user_doc?.length) ? user_doc : reviewFiles2 && reviewFiles2?.filter(a => (a?.Document === 'Income Proof'))
	const lastItemIndex = user_doc?.length - 1;
	const [files, setFiles] = useState([]);
	const [isSchemesLoading, setIssSchemesLoading] = useState(true);
	const [isUpload3MonthSalarySlip, setIsUpload3MonthSalarySlip] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [select, setSelect] = useState('')
	const [success, setSuccess] = useState(false)
	console.log(files, 'klk');
	const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
	const kycStep2 = useSelector(state => state?.kycStep2 || {});

	useEffect(() => {
		isRefreshIncomeProof();
		incomeProofList?.length < 1 && dispatch(getIncomeProofListAction());
	}, []);



	const handleImage = async (e) => {
		const mobile = MobileNo;
const encryptionFormData = new FormData();
		encryptionFormData.append('mobile', mobile);
		encryptionFormData.append('doc_file', e.target.files[0]);
		const response1 = await postFormApiCo(encryptionFormData, 'check-encryption');
		if (response1?.data.msg == 'THIS FILE IS ENCRYPTED. PLEASE OPEN THIS FILE ON YOUR END AND SAVE AS A PDF AGAIN. AND PLEASE UPLOAD THIS NEW SAVED FILE') {
			toast(response1?.data.msg)
		}
		else if (response1?.data.status == 200) {
			if (!e.target.files || e.target.files.length === 0) {
				setSelectedFile(undefined);
				return;
			}
			if (e.target.files[0].size > 5 * 1024 * 1024) { // Check if file size is greater than 3MB
				toast("File size exceeds the limit of 5MB. Please choose a smaller image.");
				return;
			}

			const payload = {
				PanNo: kycStep2.panInput.value || clientKycStatus?.data?.pan,
				Document: 'Income Proof',
				DocumentType: select,
				CreatedBy: 261,
				step: 12,
				'DocumentImages[]': e.target.files[0],
				mobile: mobile,
				journey: "DKYC",
			}
			const response = await postFormApi(payload, '/uploadfiles');
			if (response.data.success == true) {
				toast(response.data.message)
				dispatch(callCheckDocumentIsUploadedAction(
					{
						"mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
						"pan": panInput?.value || selectedStepData?.PanNo,
						"document_type": "Income Proof"
					}
				));
				getClientFilesAction()
				setSelectedFile(e.target.files[0]);
			}
			else {
				toast(response.data.message)
			}
		}
		else {
			toast('something went wrong')
		}
};

	const handleFileSelect = async (event) => {
		setSuccess(false)
const file = event.target.files[0];

		if (file == undefined  ) {
			alert('FILE NOT SELECT')
		} 
		if (files.length == 3) {
			alert('You can only select up to 3 files.');
			return;
		}
		else {
			const mobile = MobileNo;
const encryptionFormData = new FormData();
			encryptionFormData.append('mobile', mobile);
			encryptionFormData.append('doc_file', event.target.files[0]);
			const response1 = await postFormApiCo(encryptionFormData, 'check-encryption');
			if (response1?.data.msg == 'THIS FILE IS ENCRYPTED. PLEASE OPEN THIS FILE ON YOUR END AND SAVE AS A PDF AGAIN. AND PLEASE UPLOAD THIS NEW SAVED FILE') {
				toast(response1?.data.msg)
			}
		else {
				setFiles([...files, file])

			}

		}
	};





	const handleInput = ({ type, value }) => {
		setSelect(value)

		dispatch(changeStep6InputAction({ type, value }));
		if (type == 'brokerageScheme') {
			setIssSchemesLoading(true);
			dispatch(getBrokerageSchemeDetailDataAction(
				{
					"UserId": userDetail.user_id,
					"BrokerageType": 'future',
					"Segment": "Future and Option"
				}

			));
			// setTimeout(() => {
			// 	setIssSchemesLoading(false);
			// }, 3000);
		} else if (type == 'incomeProof') {
			if (value == 'Latest 3 Month Salary Slip') {
				setIsUpload3MonthSalarySlip(true);
				setFiles([])
			} else {
				setIsUpload3MonthSalarySlip(false);
			}
		}
	};

	// send link
	const handleSendLink = () => {
		dispatch(callSendUploadIncomeProofLinkAction(
			{
				"name": validatePanDetails.firstName + " " + validatePanDetails.lastName,
				"mobile": kycStep1.mobileInput.value,
				"link": `${DOMAIN}/dkyc-step2`
			}
		));
	}


	const handlemultipla = async () => {
		const mobile = MobileNo;

		const payload = {
			PanNo: kycStep2.panInput.value || clientKycStatus?.data?.pan,
			Document: 'Income Proof',
			DocumentType: 'Latest 3 Month Salary Slip',
			CreatedBy: userDetail.created_by,
			step: 12,
			'DocumentImages[]': files,

		}
		const response = await postFormApi(payload, '/uploadfiles');
		if (response.data.success == true) {
			toast(response.data.message)
			setSuccess(true)

		}
		else {
			toast(response.data.message)
		}





	}
	return (
		<>
			<ToastContainer autoClose={100}  />

			<div className="w-full mb-8 grid md:grid-cols-12 gap-10">
				<div className="w-full col-span-6 ">
					<SelectDropdown
						label="Upload Income Proof"
						placeholder="Please select documents type"
						options={incomeProofList}
						selectedValue={incomeProof.value}
						inputType="incomeProof"
						handleSelect={handleInput}
						// isDisabled={isDocumentUploaded?.success}
						inputMessage={incomeProof.errorText || ''}
						inputMessageType={incomeProof.isError && 'error'}
					/>

				</div>
				<div className="w-full flex items-center justify-between col-span-6 mt-10">
					<div className='w-full'>
						<div className='w-full flex items-start justify-between'>
							{
								isUpload3MonthSalarySlip ?
									<>
										<div>
											<div className='flex items-center justify-between'>
												{

													<button
														className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  bg-black relative`}
													>
														{
															incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
																<input
																	type="file"
																	multiple
																	accept=".jpeg, .jpg, .png, .pdf"
																	onChange={handleFileSelect}
																/>
															)
														}
														<img
															src={svgAssets.kyc.uploadWhite}
															className="mr-1.5"
														/>
														<span>Choose 3 file</span>
													</button>
												}
												

												{files.map((file, index) => (
													<div key={index} className="ml-[30px] flex flex-col items-center">

														<img
															className="rounded-[10px]"
															alt="user_image"
															src={pngAssets.kyc.doc}
														/>
														<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
															{file?.name}
														</span>
													</div>
												))}
											</div>



										</div>
									</>
									:
									<button
										className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  relative
									 ${(incomeProof?.value?.length < 1 || incomeProof?.value === '') ? 'bg-[#dce1e2] cursor-default' : 'bg-black cursor-pointer'}
								`}
										disabled={isDocumentUploaded?.success && true}
									>
										{
											incomeProof?.value?.length > 0 && incomeProof?.value !== '' && (
												<input
													type="file"
													accept="image/*, .pdf"
													onChange={(e) => handleImage(e)}
												/>
											)
										}
										<img
											src={svgAssets.kyc.uploadWhite}
											className="mr-1.5"
										/>
										<span>Choose file</span>
									</button>
							}

							{
								isUpload3MonthSalarySlip && files.length == 3 &&
								<button style={{ backgroundColor: success ? "green" : 'black' }}
									className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  relative  cursor-pointer'}
								`}
									onClick={() => { handlemultipla() }}
								>

									<img
										src={svgAssets.kyc.uploadWhite}
										className="mr-1.5"
									/>
									<span>Upload 3 doc</span>
								</button>
							}
							
							{isDocumentUploaded?.success && user_doc && user_doc.map((item, index) => {
								const isLastItem = index === lastItemIndex;
								return (
									<>
										{isLastItem &&
											<div className='relative'>
												{!item?.ImagePath?.endsWith(".pdf") && <div key={index} className='bg-white p-2 w-[150px] h-[80px] flex items-center justify-center'>
													<img width='60' src={item?.ImagePath} />
												</div>
												}
												<a href={item?.ImagePath} target='_blank' className='absolute right-2 top-2'>
													<ArrowDownTrayIcon className="h-8 w-8 text-blue-500 " />
												</a>
											</div>
										}
									</>
								);
							})}


						</div>

						<div className='w-full'>
							{
								kycStep6?.incomeProof?.isSendLink && <>
									<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
										<div className="grid md:grid-cols-1 gap-1">
											<h6 className="font-poppinsMedium font-medium col-span-1  flex w-full text-sm">Upload Income Proof link send successfully,please check email.
												<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} width='20px' height='20px' />
											</h6>
										</div>
										<button className='btn btn-primary bg-black text-white text-sm px-3 rounded mt-2 cursor-pointer' onClick={handleSendLink}>Resend Link</button>
									</div>
								</>
							}
						</div>
					</div>

				</div>

			</div>
		</>
	);
};
