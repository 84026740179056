import { setUserList } from '../actions/user.action';

const initState = {
	userList: []
};

export const userListReducer = (state = initState, action) => {
	switch (action.type) {
		case setUserList:
			return {
				...state,
				userList: action?.payload?.data || [],
				isLoading: true
			};
		default:
			return state;
	}
};
