
export const SubmitBtn = ({ handleSubmitBtn, height, isSubmit }) => {
	return (
		<button
			className={isSubmit ? `${height || '35px'} px-8 py-2 flex items-center rounded-[10px] leading-6 text-white bg-[#007813] font-poppinsRegular cursor-default` : `${height || '35px'} px-8 py-2 flex items-center rounded-[10px] leading-6 text-white bg-black font-poppinsRegular`}
			onClick={() => handleSubmitBtn()}
		>
			<span>{isSubmit ? 'Submited' : 'Submit'}</span>
		</button>
	);
};
