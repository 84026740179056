

// import { useDispatch, useSelector } from 'react-redux';
// import { Checkbox } from '../../../components/common/Checkbox';
// import { useEffect } from 'react';
// import { getDeclarationListAction } from '../../../redux/actions/kyc/step6.action';


// export const Declaration = ({ reviewData,removeSegment,reviewData2}) => {
// 	const dispatch = useDispatch();
// 	const {declaration} = useSelector(state => state?.kycStep6);
// useEffect(()=>{
// 	dispatch(getDeclarationListAction())
// },[dispatch])

// console.log(declaration,'declaration');

// 	// const handleDeclaration = ({ isChecked, value, selectedOption }) => {
// 	// 	const declarationData = [...declaration];
// 	// 	if (selectedOption) {
// 	// 		declarationData[value].selectedOption = selectedOption;
// 	// 	} else {
// 	// 		declarationData[value].isSelected = !isChecked;
// 	// 	}
// 	// 	dispatch(updateDeclarationValueAction({ data: declarationData }));
// 	// };

// 	return (
// 		<div className="grid md:grid-cols-3 gap-x-10">
// 			{

// 					 declaration.data.map((el1, i1) => (
// 						<div
// 							key={`declaration_${i1}`}
// 							className="flex flex-col mb-6"
// 						>
// 							<Checkbox
// 								key={`declaration_${i1}`}
// 								labelText={el1.label}
// 								value={i1}
// 								isChecked={el1.isSelected}
// 								// handleCheckbox={handleDeclaration}
// 								isDisable={true}
// 							/>
// 							{
// 								el1?.options?.length > 0 &&
// 								<div className="flex items-center md:ml-[32px] flex-wrap">
// 									{
// 										el1.options.map((el2, i2) => (
// 											<div key={`declaration_${i2}`}>
// 												<label className={`inline-flex items-center ${i2 !== 0 && 'ml-[18px]'}`}>
// 													<input
// 														type="radio"
// 														className="w-4 h-4 accent-blue-500"
// 														checked={el2.label === el1.selectedOption}
// 														// onChange={() => handleDeclaration({
// 														// 	value: i1,
// 														// 	selectedOption: el2.label
// 														// })}
// 														isDisable={true}
// 													/>
// 													<span
// 														className="ml-1.5 text-xs"
// 													>
// 														{el2.label}
// 													</span>
// 												</label>
// 											</div>
// 											))
// 									}
// 								</div>
// 							}
// 						</div>
// 					))

// 			}
// 		</div>
// 	);
// };

// import React, { useState } from 'react';

// export const Declaration = ({ reviewData2 }) => {
// 	console.log(reviewData2.past_action == 'No' ? false : true, 'reviewData2');
// 	const initialData = [
// 		{
// 			"label": "I declare that I am a resident of India for tax purposes as per FATCA & CRS compliance."
// 		},
// 		{
// 			"label": "I require Delivery Instruction Slip (DIS) to be sent to me"
// 		},
// 		{
// 			"label": "I request you to send my account statement on my registered email address",
// 			"options": [
// 				{ "label": "As per SEBI Regulation" },
// 				{ "label": "Monthly" },
// 				{ "label": "Fortnightly" },
// 				{ "label": "Weekly" },
// 				{ "label": "Daily" }
// 			]
// 		},
// 		{
// 			"label": "I request you to do the settlement of my funds and securities on the basis of",
// 			"options": [
// 				{ "label": "Quarterly" },
// 				{ "label": "Monthly" }
// 			]
// 		},
// 		{
// 			"label": "I would like to receive dividend interest directly into my bank account"
// 		},
// 		{
// 			"label": "I would like to share my email ID with the Registrar and Transfer Agent"
// 		},
// 		{
// 			"label": "I authorize Bonanza Portfolio Limited to proceed on all pledge instructions without additional conf"
// 		},
// 		{
// 			"label": "I would like to receive annual reports (Tick the applicable box, if not marked the default option would be in physical)",
// 			"options": [
// 				{ "label": "Electronically" },
// 				{ "label": "Physical" },
// 				{ "label": "Both" }
// 			]
// 		},
// 		{
// 			"label": "Send me Contract note and Standard Documents",
// 			"options": [
// 				{ "label": "Electronically" },
// 				{ "label": "Physical" }
// 			]
// 		},
// 		{
// 			"label": "I request you to send electronic transaction-cum holding statement at the email id"
// 		},
// 		{
// 			"label": "Politically Exposed Person (PEP)"
// 		},
// 		{
// 			"label": "Related to Politically Exposed Person (RPEP)"
// 		},
// 		{
// 			"label": "Past action"
// 		}
// 	];

// 	const selectedStatus = [true, true, true, true, true, true, true, true, true, true, reviewData2?.dec_PEP == 'Yes' ? true : false, reviewData2?.dec_rpep == 'Yes' ? true : false, reviewData2.past_action == 'No' ? false : true]
// 	const selectedRadioOptions = ["", "", reviewData2?.dec_statement == 'As per SEBI Required' ? 'As per SEBI Regulation' : reviewData2?.dec_statement, reviewData2?.dec_settlement, "", "", "", reviewData2?.dec_annualreport, reviewData2?.dec_contracttype, "", "", "", ""];

// 	const [data, setData] = useState(
// 		initialData.map((item, index) => ({
// 			...item,
// 			isSelected: selectedStatus[index] || false,
// 			selectedOption: item.options ? selectedRadioOptions[index] : ""
// 		}))
// 	);

// 	const handleCheckboxChange = (index) => {
// 		const newData = [...data];
// 		newData[index].isSelected = !newData[index].isSelected;
// 		setData(newData);
// 	};

// 	const handleRadioChange = (index, option) => {
// 		const newData = [...data];
// 		newData[index].selectedOption = option;
// 		setData(newData);
// 	};

// 	return (
// 		<div className="grid md:grid-cols-3 gap-10">
// 			{data.map((item, index) => (
// 				<div key={index} >
// 					<div>
// 						<input
// 							type="checkbox"
// 							checked={item.isSelected}
// 							onChange={() => handleCheckboxChange(index)}
// 							disabled

// 						/>
// 						<span
// 							className="ml-1.5 text-xs"
// 						>
// 							{item.label}
// 						</span>
// 					</div>
// 					{item.options && item.isSelected && (
// 						<div className="flex items-center md:ml-[32px] flex-wrap gap-2">
// 							{item.options.map((option, optIndex) => (
// 								<div key={optIndex}>
// 									<input
// 										type="radio"
// 										name={`radio-${index}`}
// 										value={option.label}
// 										checked={item.selectedOption === option.label}
// 										onChange={() => handleRadioChange(index, option.label)}
// 										disabled
// 									/>
// 									<span
// 										className="ml-1.5 text-xs"
// 									>
// 										{option.label}
// 									</span>
// 								</div>
// 							))}
// 						</div>
// 					)}
// 				</div>
// 			))}
// 		</div>
// 	);
// };
import React, { useState, useEffect } from 'react';

export const Declaration = ({ reviewData2 }) => {
  const initialData = [
    {
      "label": "I declare that I am a resident of India for tax purposes as per FATCA & CRS compliance."
    },
    {
      "label": "I require Delivery Instruction Slip (DIS) to be sent to me"
    },
    {
      "label": "I request you to send my account statement on my registered email address",
      "options": [
        { "label": "As per SEBI Regulation" },
        { "label": "Monthly" },
        { "label": "Fortnightly" },
        { "label": "Weekly" },
        { "label": "Daily" }
      ]
    },
    {
      "label": "I request you to do the settlement of my funds and securities on the basis of",
      "options": [
        { "label": "Quarterly" },
        { "label": "Monthly" }
      ]
    },
    {
      "label": "I would like to receive dividend interest directly into my bank account"
    },
    {
      "label": "I would like to share my email ID with the Registrar and Transfer Agent"
    },
    {
      "label": "I authorize Bonanza Portfolio Limited to proceed on all pledge instructions without additional conf"
    },
    {
      "label": "I would like to receive annual reports (Tick the applicable box, if not marked the default option would be in physical)",
      "options": [
        { "label": "Electronically" },
        { "label": "Physical" },
        { "label": "Both" }
      ]
    },
    {
      "label": "Send me Contract note and Standard Documents",
      "options": [
        { "label": "Electronically" },
        { "label": "Physical" }
      ]
    },
    {
      "label": "I request you to send electronic transaction-cum holding statement at the email id"
    },
    {
      "label": "I am not politically exposed or related"
    },
    // {
    //   "label": "Related to Politically Exposed Person (RPEP)"
    // },
    {
      "label": "Past action"
    }
  ];

  useEffect(() => {
    if (reviewData2) {
      setData(
        initialData.map((item, index) => ({
          ...item,
          isSelected: selectedStatus[index] || false,
          selectedOption: item.options ? selectedRadioOptions[index] : ""
        }))
      );
    }
  }, [reviewData2]);

  const selectedStatus = [true, true, true, true, true, true, true, true, true, true, true, reviewData2?.past_action !== 'No'];
  const selectedRadioOptions = ["", "", reviewData2?.dec_statement === 'As per SEBI Required' ? 'As per SEBI Regulation' : reviewData2?.dec_statement, reviewData2?.dec_settlement, "", "", "", reviewData2?.dec_annualreport, reviewData2?.dec_contracttype, "", "", "", ""];

  const [data, setData] = useState(null);

  const handleCheckboxChange = (index) => {
    const newData = [...data];
    newData[index].isSelected = !newData[index].isSelected;
    setData(newData);
  };

  const handleRadioChange = (index, option) => {
    const newData = [...data];
    newData[index].selectedOption = option;
    setData(newData);
  };

  if (!data) {
    return <div>data not found</div>;
  }

  return (
    <div className="grid md:grid-cols-3 gap-10">
      {data.map((item, index) => (
        <div key={index}>
          <div>
            <input
              type="checkbox"
              checked={item.isSelected}
              onChange={() => handleCheckboxChange(index)}
              disabled
            />
            <span className="ml-1.5 text-xs">{item.label}</span>
          </div>
          {item.options && item.isSelected && (
            <div className="flex items-center md:ml-[32px] flex-wrap gap-2">
              {item.options.map((option, optIndex) => (
                <div key={optIndex}>
                  <input
                    type="radio"
                    name={`radio-${index}`}
                    value={option.label}
                    checked={item.selectedOption === option.label}
                    onChange={() => handleRadioChange(index, option.label)}
                    disabled
                  />
                  <span className="ml-1.5 text-xs">{option.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};



