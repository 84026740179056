import { useEffect, useState } from 'react';
import { svgAssets } from '../../assets/asset';
import { SubmitBtn } from '../../components/buttons/SubmitBtn';
import { useDispatch, useSelector } from 'react-redux';
import { MyModal } from '../../components/modal/Modal';
import { SelectDropdown } from '../../components/dropdown/SelectDropdown';
import { callVerifyRejectionAction } from '../../redux/actions/kyc/verify.action';
import { toast } from 'react-toastify';
import { getClientRejectReasonAction } from '../../redux/actions/application.action';
import refetch from "../../assets/svg/refetch-icon.svg"


export const IncomeProofUploaded = ({ reviewData, reviewFilesData, selectedData, isVerify, incomeStatus, updateStatus, type, label, onButtonPress, bankData, bankproof }) => {

	const [isLoading, setIsLoading] = useState(true);

	const incomeProofRejectionOption = [
		{ label: 'Uploaded Income proof is not valid', value: 'Uploaded Income proof is not valid' },
		{ label: 'Income proof required for income range selected 25lac-1cr (in case income range is high we require income document', value: 'Income proof required for income range selected 25lac-1cr (in case income range is high we require income document' },
		{ label: 'Income Proof uploaded image is blur', value: 'Income Proof uploaded image is blur' },
		{ label: 'Income Proof Name mismatch with PAN details', value: 'Income Proof Name mismatch with PAN details' },
		{ label: 'Income Proof document should be current FY', value: 'Income Proof document should be current FY' },
		{ label: 'Bank Name,Stamp,Logo is missing on Income proof', value: 'Bank Name,Stamp,Logo is missing on Income proof' },
		{ label: 'Company,Stamp,Logo is missing on Income proof', value: 'Company,Stamp,Logo is missing on Income proof' },
		{ label: 'Bank Transaction of Minimum 10,000/- transaction required', value: 'Bank Transaction of Minimum 10,000/- transaction required' },
		{ label: 'Bank seal sign required on income proof', value: 'Bank seal sign required on income proof' },
		{ label: 'Latest six months bank statement with min transaction of 10000/Latest 3 month salary slip/Latest ITR required as income proof', value: 'Latest six months bank statement with min transaction of 10000/Latest 3 month salary slip/Latest ITR required as income proof' },
	];
	const oterRejectionFieild = [
		{ label: 'Penny Drop pdf is not proper', value: 'Penny Drop pdf is not proper' },
		{ label: 'Beneficiary Name Wrong With Pan Name', value: 'Beneficiary Name Wrong With Pan Name' },
		{ label: 'Beneficiary Name Is Missing On Penny Drop', value: 'Beneficiary Name Is Missing On Penny Drop' },
		{ label: 'Name mismatch as per pan site', value: 'Name mismatch as per pan site' },
		{ label: 'KRA address does not match with the address in dkyc', value: 'KRA address does not match with the address in dkyc' },
		{ label: 'Alternate bank proof required as Beneficiary name with Bank differ', value: 'Alternate bank proof required as Beneficiary name with Bank differ' },

	];
	const BankRejectReason = [
		{ label: 'Bank Proof uploaded image is blur', value: 'Bank Proof uploaded image is blur' },
		{ label: 'Bank name mismatch with proof', value: 'Bank name mismatch with proof' },
		{ label: 'MICR & IFSC code both mismatch', value: 'MICR & IFSC code both mismatch' },
		{ label: ' MICR code mismatch with bank proof', value: ' MICR code mismatch with bank proof' },
		{ label: ' All bank details mismatch with proof', value: ' All bank details mismatch with proof' },
		{ label: 'Incorrect Bank proof uploaded instead of Bank Document', value: 'Incorrect Bank proof uploaded instead of Bank Document' },
		{ label: 'Bank Proof Name mismatch with PAN details required Bank Verification Letter for declaration', value: 'Bank Proof Name mismatch with PAN details required Bank Verification Letter for declaration' },
		{ label: 'Enter Bank A/c no is mismatch with Bank proof', value: 'Enter Bank A/c no is mismatch with Bank proof' },
		{ label: 'MICR code is missing on Bank proof', value: 'MICR code is missing on Bank proof' },
		{ label: 'Bank Name,Stamp,Logo is missing on Bank proof', value: 'Bank Name,Stamp,Logo is missing on Bank proof' },
		{ label: 'The name on the bank proof is not printed.', value: 'The name on the bank proof is not printed.' },
		{ label: 'Uploaded Bank proof is not valid', value: 'Uploaded Bank proof is not valid' },
		{ label: 'Enter Bank IFSC code  is mismatch with Bank proof', value: 'Enter Bank IFSC code  is mismatch with Bank proof' },
		{ label: 'Mode of operation or either or survivor proof required as client name is 2nd on bank proof', value: 'Mode of operation or either or survivor proof required as client name is 2nd on bank proof' },
		{ label: 'client name on bank proof mismatch with pan', value: 'client name on bank proof mismatch with pan' },
		{ label: 'Bank seal sign required on bank proof', value: 'Bank seal sign required on bank proof' },
		{ label: 'Account number not visible', value: 'Account number not visible' },
		{ label: 'Merged Bank. Need present Bank S Proof otherwise reject', value: 'Merged Bank. Need present BankS Proof otherwise reject' },
	];
	const AddressReason = [
		{ label: 'Address proof image is not clear', value: 'Address proof image is not clear' },
		{ label: 'Incorrect Address proof captured', value: 'Incorrect Address proof captured' },
		{ label: 'address mismatch as per proof', value: 'address mismatch as per proof' },
		{ label: 'Aadhar number wrong on KRA page & DKYC', value: 'Aadhar number wrong on KRA page & DKYC' },
		{ label: 'address proof name mismatch with pan card (all documents required with same name)', value: 'address proof name mismatch with pan card (all documents required with same name)' },
		{ label: 'Address Proof Name mismatch with PAN details required Bank Verification Letter for declaration', value: 'Address Proof Name mismatch with PAN details required Bank Verification Letter for declaration' },
		{ label: 'Image Not Clear', value: 'Image Not Clear' },
		{ label: 'Upload Valid Address Proof. ***** Is Not Accepted', value: 'Upload Valid Address Proof. ***** Is Not Accepted' },
		{ label: 'Pin code check', value: 'Pin code check' },
		{ label: 'Wrong Uid No', value: 'Wrong Uid No' },
		{ label: 'Incomplete Proof (incomplete Address update as per Adhar proof)', value: 'Incomplete Proof (incomplete Address update as per Adhar proof)' },
		{ label: 'Provide Bank Verification Letter As Name And Documents Do Not Match', value: 'Provide Bank Verification Letter As Name And Documents Do Not Match' },
		{ label: 'Address Not Matching With The Proof Submitted reject', value: 'Address Not Matching With The Proof Submitted reject' },

	];
	const Pan_digoReason = [
		{ label: ' PAN proof image is not clear', value: ' PAN proof image is not clear' },
		{ label: 'Enter PAN details mismatch with PAN proof', value: 'Enter PAN details mismatch with PAN proof' },
		{ label: 'PAN Name mismatch with PAN proof', value: 'PAN Name mismatch with PAN proof' },
		{ label: 'PAN DOB mismatch with PAN proof', value: 'PAN DOB mismatch with PAN proof' },
		{ label: 'Gender mismatch with PAN proof', value: 'Gender mismatch with PAN proof' },
		{ label: 'Enter Father Name mismatch with PAN proof', value: 'Enter Father Name mismatch with PAN proof' },

	];

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	const [rejectModal, setRejectModal] = useState(false);
	const [verifyModal, setVerifyModal] = useState(false);
	const [isRejectionList, setIsRejectionList] = useState([]);
	const [rejectReason, setRejectReason] = useState(0);
	const clientId = reviewData?.ClientId;
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const bankcheck = ["BankProof", "bank", "micr"];
	const incomecheck = ["Income", '3month'];
	const other = ["Other", 'other'];
	const Address = ["address", 'Address'];
	const pan = ["Pan", 'PAN', 'pan'];
	const rejectReasonsData = useSelector(state => state?.application.rejectReason || []);
	const new_data = Object.values(rejectReasonsData)
	const result = label == 'Bank Proof' ? new_data?.some(entry => bankcheck?.some(keyword => entry?.verify_key.includes(keyword))) : label == 'Income Proof' ? new_data?.some(entry => incomecheck?.some(keyword => entry?.verify_key.includes(keyword))) : label == 'Other' ? new_data?.some(entry => other?.some(keyword => entry?.verify_key.includes(keyword))) : label == 'Address Proof' ? new_data?.some(entry => Address?.some(keyword => entry?.verify_key.includes(keyword))) : (label == 'PAN Card') ? new_data?.some(entry => pan?.some(keyword => entry?.verify_key.includes(keyword))) : false;
	// const incomeIndex = reviewFilesData.findIndex((item) => item.Document === label);



	const handleModal = ({ isSubmit }) => {
		if (isSubmit == true && rejectReason == '') {
			toast("please select reason")
		}
		else {
			setRejectModal(false);
			setVerifyModal(false);
			if (isSubmit) {
				runApi(0)
				// updateStatus('reject', 'income')
			}
		}

	};

	const handleInput = ({ type, value }) => {
		if (type == 'rejectReason') {
			setRejectReason(value)
		}
	};
	const runApi = (isVerify) => {
		dispatch(callVerifyRejectionAction({
			"ActualField": label,
			"PageNo": 1,
			"FieldName": label.replace(/\s/g, ''),
			"FieldValue": reviewFilesData?.ImagePath,
			"IsVerify": isVerify,
			"VerifyBy": userDetail.user_id,
			"ClientId": clientId,
			"DocId": reviewFilesData?.DocId,
			"Reson": rejectReason || ''
		}, callbackcontinoue));
		// setVerifyModal(false)
	}

	const callbackcontinoue = (resp) => {
		if (resp == 'success') {
			toast('Record Saved successfully')
			setRejectModal(false);
			setVerifyModal(false);
			dispatch(getClientRejectReasonAction({
				ClientId: selectedData.ClientId,
				PanNo: selectedData.PanNo
			}));
		}
		else {
			toast(resp.message ? resp.message : 'something went wrong')
		}
	}
	const handleSubmitVerifyModal = ({ isSubmit }) => {
		runApi(1)
	};

	const handleValidation = (actiontype, type) => {
		if (actiontype == 'reject') {
			setRejectReason(0)
			setRejectModal(true);
			setIsRejectionList(label == 'Income Proof' ? incomeProofRejectionOption : label == 'Other' ? oterRejectionFieild : label == 'Bank Proof' ? BankRejectReason : label == 'Address Proof' ? AddressReason : Pan_digoReason);
		}
		else {
			setVerifyModal(true);

		}


	};

	const OnpressData = () => {

		if (label == 'Bank Proof' || label == 'Income Proof' || label == 'Other') {
			onButtonPress(label)
		} else {
			window.open(reviewFilesData?.ImagePath, '_blank')
		}
	}

	return (
		<>
			<div className="mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]">


				<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
					<div className="text-lg font-medium font-poppinsMedium">{type}</div>

					<div
						className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
					>
						{/*  */}
						<div className="flex items-center justify-center" onClick={OnpressData}>
							<img className="mr-2 w-[25px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
							<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
						</div>
						{/* </a> */}
					</div>
					{
						reviewData?.FinalVerify == 1 ?
							null
							:
							isVerify && (
								<div className='flex items-center justify-end'>
									{
										incomeStatus == "reject"
											? (
												<>
													<img
														alt="wrong_field"
														className="mr-5"
														src={
															svgAssets.review.wrongTick
														}
													/>
													<img
														alt="wrong_field"
														className="cursor-pointer"
														src={svgAssets.review.undo}
													// onClick={() => setIsValid({ ...isValid, triggered: false })}
													/>
												</>
											)
											:
											incomeStatus == "done"
												? (
													<>

														<img
															alt="wrong_field"
															className="mr-5"
															src={svgAssets.review.correctTick}
														/>
													</>
												)
												:
												(
													<>
														{
															result ?
																<div style={{ marginLeft: '30px', marginBottom: '10px' }} onClick={() => handleValidation('approve', label)}>
																	<button style={{ background: 'red' }} className={'d-inline-block text-white px-2 py-1 mt-4 rounded refetch-button'}><img src={refetch} className="mr-1" />Undo Rejection</button>
																</div>
																// <img
																// 	alt="right_field"
																// 	className="cursor-pointer"
																// 	src={svgAssets.verifyApplication.rightField}
																// 	onClick={() => handleValidation('approve', label)}
																// />
																:
																(label == "Other" && (bankproof)) ?
																	null
																	:
																	<img
																		alt="wrong_field"
																		className="mr-5 cursor-pointer"
																		src={svgAssets.verifyApplication.wrongField}
																		onClick={() => handleValidation('reject', label)}
																	/>
														}


													</>
												)
									}
								</div>
							)
					}
				</div>
				<MyModal
					isModalOpen={rejectModal}
					handleModal={handleModal}
					title=""
					centered={false}
					width={590}
				>
					<div className="">
						<h6 className="mb-4 text-lg font-medium">Tell us rejection reason</h6>
						{/* <SelectRejectionDropdown placeholder="Enter rejection reason" rejectionList={isRejectionList} /> */}
						<SelectDropdown
							label=""
							placeholder="Please select reason"
							options={isRejectionList}
							selectedValue={rejectReason}
							inputType="rejectReason"
							handleSelect={handleInput}
							inputMessage={''}
							inputMessageType={''}
						/>
						<div className="flex items-center justify-center mt-12">
							<SubmitBtn handleSubmitBtn={() => handleModal({ isSubmit: true })} />
						</div>
					</div>
				</MyModal >
				<MyModal
					isModalOpen={verifyModal}
					handleModal={handleModal}
					title=""
					centered={false}
					width={590}
				>
					<div className="">
						<h3 className='text-2xl'>Undo Rejection</h3>
						<div className="flex items-center justify-center mt-12">
							<botton
								className='bg-black px-8 py-2 text-white rounded cursor-pointer'
								onClick={() => { setVerifyModal(false); }}>
								Cancel
							</botton>
							<botton
								className='ml-3 bg-black px-8 py-2 text-white rounded cursor-pointer'
								onClick={handleSubmitVerifyModal}
							>
								Ok
							</botton>
						</div>
					</div>
				</MyModal >
			</div>

		</>

	);
};
