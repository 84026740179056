import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pngAssets, svgAssets } from '../../assets/asset';
import { MainTitle } from '../../components/common/MainTitle';
import { StepProgressBar } from '../../components/progressBar/ProgressBar';
import { verificationSteps } from '../../containers/kyc/kycData';
import { AddressDetail } from '../../containers/review/AddressDetail';
import { BankDetail } from '../../containers/review/BankDetail';
import { BrokerageStep } from '../../containers/review/brokerage/BrokerageStep';
import { IncomeProofUploaded } from '../../containers/review/IncomeProofUploaded';
import { ClientVerifyDetail } from '../../containers/review/clientDetail/ClientVerifyDetail';
import { ClientPreview } from '../../containers/review/clientDetail/ClientPreview';
import { MobileEmailDetail } from '../../containers/review/MobileEmailDetail';
import { OccupationalDetail } from '../../containers/review/OccupationalDetail';
import { PanDetail } from '../../containers/review/PanDetail';
import { PersonalDetail } from '../../containers/review/PersonalDetail';
import { Debounce } from '../../hooks/Debounce';
import { getClientFileAction, getClientInfoAction, getClientRejectReasonAction, saveVerifiedApplicationAction, saveClientRejectReasonAction } from '../../redux/actions/application.action';
import { getVerifyApplicationAction, getVerifyClientFilesAction, setVerifyApplication2Action, setVerifyApplicationAction, } from '../../redux/actions/kyc/verify.action';
import { StepReviewBar } from '../../components/progressBar/ReviewBar';
import { postApi, postApiWithoutLoader } from '../../services/common';
import { MyModal } from '../../components/modal/Modal';
import { BASE_URL } from "../../config/config";
import { toast } from 'react-toastify';
import { AddressDetail1 } from '../../containers/review/AddressDetails1';
import refetch from "../../assets/svg/refetch-icon.svg"
import { Nominee } from '../../containers/kyc/step6/Nominee';

const clientIntersectionOption = {
	rootMargin: '0px 0px -75% 0px',
	threshold: 0.3
};

const intersectionOption = {
	rootMargin: '0px 0px 50% 0px',
	threshold: 1
};

// /////  dummy data
const applicationData = [
	{}, {}, {}
]

export const NewApplication = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const selectedData = location.state && location.state.selectedData;
	const { ref: clientRef, inView: isClientRefInView } = useInView(intersectionOption);
	const { ref: mobileRef, inView: isMobileRefView } = useInView(intersectionOption);
	const { ref: panRef, inView: isPanRefView } = useInView(intersectionOption);
	const { ref: addressRef, inView: isAddressRefView } = useInView(intersectionOption);
	const { ref: bankRef, inView: isBankRefView } = useInView(intersectionOption);
	const { ref: personalRef, inView: isPersonalRefView } = useInView(intersectionOption);
	const { ref: occupationalRef, inView: isOccupationalRefView } = useInView(intersectionOption);
	const { ref: brokerageRef, inView: isBrokerageRefView } = useInView(intersectionOption);
	const { ref: EsignRef, inView: IsEsignRefView } = useInView(intersectionOption);
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const reviewData = useSelector(state => state?.verifyData.verifyData || {});
	const reviewData2 = useSelector(state => state?.verifyData.verifyData2 || {});
	const reviewFilesData = useSelector(state => state?.verifyData.filesData || {});
	const rejectReasonsData = useSelector(state => state?.application.rejectReason || []);
	const [isClientPreviewVisible, setIsClientPreviewVisible] = useState(false);
	const debounceValue = Debounce(isClientRefInView, 2000);
	const clientPhoto = reviewFilesData && reviewFilesData.find(el => el?.DocumentType === 'Client photo' || el.DocumentType === 'Client Photo');
	const clientSignature = reviewFilesData && reviewFilesData.find(el => el?.DocumentType === 'Client Signature');
	const income_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document === 'Income Proof'))
	const income_proof1 = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a?.DocumentType === 'Latest 3 Month Salary Slip'))
	const bank_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document === 'Bank Proof'))
	const other = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document == 'Other'))
	const PAN_Card = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document == 'PAN Card' || a?.Document == 'Pan Upload'))
	const Address_Proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document == 'Address Proof'))
	const [bankData, setBankData] = useState(null);
	const [clientDetails, setClientDtails] = useState();
	const [rejectModal, setRejectModal] = useState(false);
	const [verify, setSavevify] = useState(false);
	const new_data = Object.values(rejectReasonsData)
	const [selectedLabelText, setSelectedLabelText] = useState('Other');
	const [uniqueValue, setUniqueValue] = useState();

	const navigate = useNavigate();
	useEffect(() => {
		dispatch(setVerifyApplicationAction({}))
		dispatch(setVerifyApplication2Action({}))
	}, [])

	useEffect(() => {
		setIsClientPreviewVisible(debounceValue);
	}, [debounceValue]);
	const [steps, setSteps] = useState(verificationSteps || []);

	useEffect(() => {
		if (isClientRefInView) {
			handleSteps({ index: 0 });
		} else if (isMobileRefView) {
			handleSteps({ index: 1 });
		} else if (isPanRefView) {
			handleSteps({ index: 2 });
		}
		else if (isAddressRefView) {
			handleSteps({ index: 3 });
		} else if (isBankRefView) {
			handleSteps({ index: 4 });
		} else if (isPersonalRefView) {
			handleSteps({ index: 5 });
		}
		else if (isOccupationalRefView) {
			handleSteps({ index: 6 });
		}
		else if (IsEsignRefView) {
			handleSteps({ index: 7 });
		}

	}, [isClientRefInView, isMobileRefView, isPanRefView, isAddressRefView, isBankRefView, isPersonalRefView, isOccupationalRefView, isBrokerageRefView, IsEsignRefView,]);


	const handleSteps = ({ index }) => {
		const updatedSteps = steps.map((el, i) => {
			if (i === index) {
				return ({
					...el,
					status: 'verifyView'
				});
			} else {
				return ({
					...el,
					status: 'success'
				});
			}
		});
		setSteps(updatedSteps);
	};

	useEffect(() => {
		if (selectedData?.ClientId && selectedData?.mobile) {
			fetchData1()
			dispatch(getVerifyApplicationAction(
				{
					mobile: selectedData?.mobile,
					ClientId: selectedData?.ClientId,
				}
			));
			dispatch(getVerifyClientFilesAction({
				PanNo: selectedData?.PanNo || '',
				CreatedBy: 88
			}));
			dispatch(getClientRejectReasonAction({
				ClientId: selectedData?.ClientId,
				PanNo: selectedData?.PanNo
			}));
		}
	}, [selectedData?.mobile && selectedData?.ClientId]);

	const fetchData1 = async () => {
		try {
			const payload = {
				mobile: selectedData?.mobile,
			}
			const response = await postApiWithoutLoader(payload, '/getKycJourney');

			if (response?.data?.success) {
				const pennydropdata = JSON.parse(response?.data.data[0].pennydrop_data)
				setBankData(pennydropdata)
				setClientDtails(response?.data?.data[0])

			}
			else {
				console.error('Error fetching data:');

			}
		}
		catch (error) {
			toast('something went wrong')
		}
	};



	useEffect(() => {
		if (verify) {
			if (selectedData.ClientId) {
				dispatch(getClientRejectReasonAction({
					ClientId: selectedData.ClientId,
					PanNo: selectedData.PanNo
				}));

			}
		}
		// btnUpdate(false)
	}, [verify]);


	const handleSubmit = () => {
		setSavevify(true)
		setRejectModal(true);
	};


	const handleModal = () => {
		setRejectModal(false)
		setSavevify(false)

	};

	const SubmitVerify = async () => {
		const payload = {
			ClientId: selectedData.ClientId,
			VerifyBy: userDetail.user_id,
		}
		const response = await postApi(payload, '/SaveFinalVerify');

		if (response?.data?.success) {
			if (filteredData.length > 0) {
				navigate('/application/pending')
			}
			setRejectModal(false)
			dispatch(getVerifyApplicationAction(
				{
					mobile: selectedData.mobile,
					ClientId: selectedData.ClientId,
				}
			));

		}
		else {
			toast(response?.message ? response?.message : response?.data?.message ? response?.data?.message : 'something went wrong')

		}
	}

	const handleSendToLd = async () => {
		const payload = {
			ClientId: selectedData.ClientId,

		}
		const response = await postApi(payload, '/SendKycinfoToLD');
		if (response?.data?.success) {
			toast(response?.data?.message)
			navigate('/application/pending')
		}
		else {
			toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
		}
	}
	const handleButtonPress = (text) => {
		setSelectedLabelText(text);
	};
	const handleCheckboxChange = (e) => {
		if (e.value == true) {
			// alert()
			handleSubmit()
		}
	}
	const handleRefetch = async () => {
		const payload = {
			clientId: selectedData.ClientId,
		}
		const response = await postApi(payload, '/downloadPdfDigio');
		if (response?.data?.success) {
			// toast(response?.data?.message)
			window.open(response?.data?.data, '_blank');
		}
		else {
			toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
		}
	}
	const filteredData = new_data?.filter(entry => entry?.reason !== null);
	const hasIncomeProof = () => {
		return reviewFilesData?.some(document => document?.Document === "Income Proof");
	};

	const generateFourDigitNumber = () => {
		return Math.floor(1000 + Math.random() * 9000);
	  };
	
	  const handleClick = () => {
		const newUniqueValue = generateFourDigitNumber();
		setUniqueValue(newUniqueValue);
	  };

	return (
		<>
			{
				reviewData?.ClientId ?
					<div className="w-full flex flex-col" style={{width:'85%'}}>
						<MyModal
							isModalOpen={rejectModal}
							handleModal={handleModal}
							title=""
							centered={false}
						// width={'100%'}
						// Width={3}
						>
							<h1 className='text-center mb-3 font-18'>{new_data?.length > 0 ? 'Rejection List' : 'Do you wish to submit your documents?'}</h1>
							{filteredData?.map((item, index) => (
								<div key={index} style={{ flexDirection: 'row', display: 'flex', padding: 10 }}>
									<h1 >{index + 1}{')'}</h1>
									<h1 style={{ marginLeft: 20 }}>{item?.reason}</h1>
								</div>
							))}
							<div className='text-center'><button onClick={SubmitVerify} className='btn bg-black m-auto mt-3 mb-3 text-white py-2 px-3'>Submit</button></div>
						</MyModal >
						<div className="flex flex-col md:flex-row items-center justify-between mb-4 md:mb-0">
							<div className="w-full tittle-box">
								<MainTitle title="New Application Details" />
							</div>
							<div className="w-full flex items-center justify-center  md:justify-end text-[#848484] font-medium font-poppinsMedium  mb-4 md:mb-0">
								<span className="mr-2">Client of</span>
								{reviewData?.IsETrade == 90 ? (
									<img className="h-5" alt="bigul" src={svgAssets.bigul} />
								) : (
									<img className="h-8" alt="bigul" src={svgAssets.bonabza} />
								)}
							</div>
						</div>
						<div ref={clientRef} className="px-7 py-8 mb-[35px] rounded-[20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
							<ClientVerifyDetail
								isVerify={false}
								selectedData={selectedData}
								// isChecked={isChecked}
								reviewData={reviewData || {}}
								reviewData2={reviewData2 || {}}
								reviewFilesData={reviewFilesData}
								rejectReasonsData={rejectReasonsData}
								pennyData={clientDetails}
								handleCheckboxChange={handleCheckboxChange}

							/>
						</div>

						<div className="sticky top-[-0px] zi-9000">
							<>
								<div className="px-7 py-3 rounded-[10px_10px_0_0] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
									<ClientPreview
										reviewData={reviewData || {}}
										reviewData2={reviewData2 || {}}
										reviewFilesData={reviewFilesData || []}
										nameOnPan={applicationData?.clientInfo?.UserName || ''}
										bankName={applicationData?.clientInfo?.BankName || ''}
										clientPhoto={clientPhoto?.DocumentImages || ''}
										clientSignature={clientSignature?.DocumentImages || ''}
										rejectReasonsData={rejectReasonsData}
										pennyData={clientDetails}
									/>
								</div>
								<div className="pt-3 pb-1 rounded-0 bg-[#E9F1FF]">
									<div className="pt-2 pb-2 rounded-0 bg-[#E9F1FF]">
										<StepReviewBar selectedStep={7} steps={steps} setSteps={setSteps} showStepLabel={true} />
									</div>
								</div>
							</>
						</div>
						<div className="px-7 py-8 rounded-[0_0_20px_20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
							<div ref={mobileRef}>
								<MobileEmailDetail
									isVerify={false}
									email={applicationData?.clientInfo?.Email || ''}
									mobileNo={applicationData?.clientInfo?.MobileNumber || ''}
									reviewData={reviewData || {}}
									reviewData2={reviewData2 || {}}
								/>
							</div>
							<div ref={panRef}>
								<PanDetail
									panNo={applicationData?.clientInfo?.PanNo || ''}
									dob={applicationData?.clientInfo?.BirthDate || ''}
									nameOnPan={applicationData?.clientInfo?.UserName || ''}
									reviewData={reviewData || {}}
									reviewData2={reviewData2 || {}}
									reviewFilesData={reviewFilesData}

								/>
								{
									PAN_Card &&
									<IncomeProofUploaded
										reviewFilesData={PAN_Card}
										reviewData={reviewData}
										rejectReasonsData={rejectReasonsData}
										isVerify={true}
										// updateStatus={updateStatus}
										// incomeStatus={incomeStatus}
										label={"PAN Card"}
										type={"PAN Card"}
										selectedData={selectedData}

									/>
								}
							</div>
							<div ref={addressRef}>
								<AddressDetail
									reviewData={reviewData || {}}
									reviewData2={reviewData2 || {}}
									reviewFilesData={reviewFilesData}
								/>
								<AddressDetail1
									reviewData={reviewData || {}}
									reviewData2={reviewData2 || {}}
									reviewFilesData={reviewFilesData}
								/>
								{
									Address_Proof &&
									<IncomeProofUploaded
										reviewFilesData={Address_Proof}
										reviewData={reviewData}
										rejectReasonsData={rejectReasonsData}
										isVerify={true}
										// updateStatus={updateStatus}
										// incomeStatus={incomeStatus}
										label={"Address Proof"}
										type={"Address Proof"}
										selectedData={selectedData}
									/>
								}
							</div>
							<div ref={bankRef}>
								<BankDetail
									reviewData={reviewData || {}}
									reviewData2={reviewData2 || {}}
									ifsc={applicationData?.clientInfo?.BankIFSC || ''}
									bankAccountNo={applicationData?.clientInfo?.BankAccountNumber || ''}
									bankName={applicationData?.clientInfo?.BankName || ''}
									pennyData={bankData && bankData || {}}
									reviewFilesData={reviewFilesData}
									selectedLabelText={selectedLabelText}

								/>
								<div className='pt-5 grid md:grid-cols-3 gap-5 items-center'>
									{
										bank_proof &&
										<IncomeProofUploaded
											reviewFilesData={bank_proof}
											reviewData={reviewData}
											rejectReasonsData={rejectReasonsData}
											isVerify={true}
											// updateStatus={updateStatus}
											// incomeStatus={incomeStatus}
											label={"Bank Proof"}
											type={"Bank Proof"}
											selectedData={selectedData}
											onButtonPress={handleButtonPress}

										/>
									}
									{
										income_proof &&
										<IncomeProofUploaded
											reviewFilesData={income_proof}
											reviewData={reviewData}
											rejectReasonsData={rejectReasonsData}
											isVerify={true}
											// updateStatus={updateStatus}
											// incomeStatus={incomeStatus}
											label={"Income Proof"}
											type={"Income Proof"}
											selectedData={selectedData}
											onButtonPress={handleButtonPress}

										/>

									}
									{
										other &&
										<IncomeProofUploaded
											reviewFilesData={other}
											reviewData={reviewData}
											rejectReasonsData={rejectReasonsData}
											isVerify={true}
											bankData={bankData}
											bankproof={bank_proof}
											// updateStatus={updateStatus}
											// incomeStatus={incomeStatus}
											label={"Other"}
											type={"Penny Drop"}
											selectedData={selectedData}
											onButtonPress={handleButtonPress}
										/>
									}

								</div>

							</div>
							<div ref={personalRef}>
								<PersonalDetail
									reviewData={reviewData || {}}
									maritalStatus={applicationData?.clientInfo?.MaritalStatus || ''}
									gender={applicationData?.clientInfo?.Gender || ''}
									birthPlace={applicationData?.clientInfo?.birth_place || ''}
								/>
							</div>
							<div ref={occupationalRef}>
								<OccupationalDetail
									reviewData={reviewData || {}}
									occupation={applicationData?.clientInfo?.OccupationName || ''}
									annualIncome={applicationData?.clientInfo?.AnnualName || ''}
									education={applicationData?.clientInfo?.EducationName || ''}
									experience={applicationData?.clientInfo?.CommodityExperience || ''}
									reviewFilesData={reviewFilesData}
									isVerify={(reviewData?.IncomeId > 3 && !hasIncomeProof()) ? true : false}
									selectedData={selectedData}
								/>

							</div>
							<div ref={brokerageRef}>
								<BrokerageStep
									reviewData={reviewData && reviewData || {}
									}
									reviewData2={reviewData2 && reviewData2 || {}
									}
									removeSegment={true}

								/>
							</div>
							{
								reviewData?.nominee_arr != null &&
								<Nominee reviewData={reviewData?.nominee_arr} />
							}
							<div ref={EsignRef}>
								{(clientDetails?.esign_status == 1 || reviewData?.IsESign == 1) &&
									<div className='pt-5 grid md:grid-cols-3 gap-5 items-center' >
										<div className="text-lg font-medium font-poppinsMedium">View Esign PDF</div>
										<div onClick={handleClick}
											className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
										>
											<a href={BASE_URL + '/resources/final_esign_pdfs/EQ_' + selectedData.PanNo +'.pdf'+'?en='+uniqueValue} target='_blank'>
												<div className="flex items-center justify-center">
													<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
													<span className="text-[#757575] text-xs font-poppinsRegular">View Esign PDF</span>
												</div>
											</a>
										</div>
										<div><p className="text-danger">If pdf not found or wrong data is found in pdf </p>
											<button style={{ background: 'red' }} onClick={() => { handleRefetch() }} className={'d-inline-block text-white px-3 py-2 mt-4 rounded refetch-button'}><img src={refetch} className="mr-2" /> Click here</button>
										</div>
									</div>
								}
							</div>
							{/* {income_proof1.length >2 &&
						<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
							<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip1</div>
							<div
								className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
							>
								<a href={income_proof1[0]?.ImagePath} target='_blank'>
									<div className="flex items-center justify-center">
										<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
										<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
									</div>
								</a>
							</div>
						</div>

					}
				{income_proof1.length >2 &&
						<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
							<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip2</div>
							<div
								className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
							>
								<a href={income_proof1[1]?.ImagePath} target='_blank'>
									<div className="flex items-center justify-center">
										<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
										<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
									</div>
								</a>
							</div>
						</div>

					}
				{income_proof1.length >2 &&
						<div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
							<div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip3</div>
							<div
								className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
							>
								<a href={income_proof1[2]?.ImagePath} target='_blank'>
									<div className="flex items-center justify-center">
										<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
										<span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
									</div>
								</a>
							</div>
						</div>

					}  */}
							<div style={{ justifyContent: 'end' }} className='column-row gap-5'>
								<button onClick={handleSubmit} disabled={reviewData?.FinalVerify == 1 ? true : false} style={{ backgroundColor: reviewData?.FinalVerify == 1 ? 'green' : 'black' }} className={'text-white px-8 py-2 mt-4 rounded'}>{reviewData?.FinalVerify == 1 ? 'Submited Verification' : 'Submit Verification'} </button>

								{
									(reviewData?.FinalVerify == 1 && userDetail?.role_name == "VRF") && (reviewData?.IsESign == 1) &&
									<button onClick={() => { handleSendToLd() }} disabled={false} className={'bg-black  text-white px-8 py-2 mt-4 rounded'}>Send To LD </button>
								}
							</div>
						</div>
					</div>
					:
					<h1>Please wait..... data fetching</h1>

			}
		</>

	);
};
