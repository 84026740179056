import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pngAssets, svgAssets } from '../../../assets/asset';
import { MainTitle } from '../../../components/common/MainTitle';
import { InputBtnIcon } from '../../../components/inputs/InputBtnIcon';
import { OtpInput } from '../../../components/inputs/OtpInput';
import { VerifiedOtpInput } from '../../../components/inputs/VerifiedOtpInput';
import { validateEmail, validateOtp, validatePhoneNo } from '../../../utils/verifyInput';
import { changeStep1ErrorAction, changeStep1InputAction, sendEmailOtpAction, sendMobileOtpAction, checkMobileOtpAction, checkEmailOtpAction, verifyMobileOtpAction, verifyEmailOtpAction, changeStep1MobileOtpStatusAction, changeStep1EmailOtpStatusAction, clearKycAction } from '../../../redux/actions/kyc/step1.action';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { emailcheck, mobilecheck, validatePanDetails } from '../../../services/common';
import { setChannelAction } from '../../../redux/actions/kyc/kyc.action';


export const VerifyMobileEmail = ({ step, index, continueBtn, selectedData }) => {
	const mobileInputRef = useRef(null);
	const mobileTimeOut = 60;
	const emailTimeOut = 60;
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const channel = useSelector(state => state?.kyc?.channel || '');
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const [isResendCounter, setIsResendCounter] = useState(false);
	const [counter, setCounter] = React.useState(mobileTimeOut);
	const [emailCounter, setEmailCounter] = React.useState(emailTimeOut);
	const [isChangeNumber, setIsChangeNumber] = React.useState(true);
	const [isChangeEmail, setIsChangeEmail] = React.useState(true);
	const [isCallVerify, setIsCallVerify] = React.useState(false);
	const [isCallEmailVerify, setIsCallEmailVerify] = React.useState(false);
	const [isMobileResendOtp, setIsMobileResendOtp] = React.useState(false);
	const [isEmailResendOtp, setIsEmailResendOtp] = React.useState(false);
	const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});

	useEffect(() => {
		let interval;
		if (kycStep1.mobileOtp.status !== 'done' && isCallVerify) {
			const mobileInput = kycStep1.mobileInput.value || '';
			interval = setInterval(() => {
				dispatch(
					checkMobileOtpAction({
						mobile: mobileInput,
					}));
			}, 20000);
		}
		return () => clearInterval(interval);
	}, [isCallVerify, kycStep1.mobileOtp.status]);
	useEffect(() => {

		if (clientKycStatus?.data?.verify_email_status == 1) {
			kycStep1.emailOtp.status = "done";
			kycStep1.emailInput.value = clientKycStatus?.data?.email
			kycStep1.emailOtp.value = clientKycStatus?.data?.otp
			kycStep1.emailInput.status = "done"
			continueBtn({ step, index })
		}
	},);

	useEffect(() => {
		if (selectedData && selectedData.mobile) {
			// Check if selectedData has a mobile field and update the mobile input value
			dispatch(changeStep1InputAction({ type: 'mobileInput', value: selectedData.mobile }));
			dispatch(setChannelAction({ channel: selectedData?.IsETrade == 90 ? "Bigul" : "Bonanza" }))
		}
	}, [selectedData]);

	useEffect(() => {
		let emailinterval;
		if (kycStep1.emailOtp.status !== 'done' && isCallEmailVerify && emailCounter > 1) {
			const emailInput = kycStep1.emailInput.value || '';
			const mobileInput = kycStep1.mobileInput.value || '';
			emailinterval = setInterval(() => {
				dispatch(checkEmailOtpAction({
					email: emailInput,
					mobile: mobileInput
				}));
			}, 20000);
		}
		return () => clearInterval(emailinterval);
	}, [isCallEmailVerify, kycStep1.emailOtp.status]);

	// send mobile otp interval
	useEffect(() => {
		let timer;
		if (isMobileResendOtp && kycStep1.mobileOtp.status !== 'done' || kycStep1.mobileInput.status === 'done') {
			timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		}
		return () => clearInterval(timer);
	}, [counter, isMobileResendOtp]);

	// send email otp interval
	useEffect(() => {
		let emailtimer;
		if (isEmailResendOtp && kycStep1.emailOtp.status !== 'done' || kycStep1.emailInput.status === 'done') {
			emailtimer = emailCounter > 0 && setInterval(() => setEmailCounter(emailCounter - 1), 1000);
		}
		return () => clearInterval(emailtimer);
	}, [emailCounter, isEmailResendOtp]);

	const handleInput = ({ type, value }) => {
		dispatch(changeStep1InputAction({ type, value }));
		if (type === 'mobileInput') {
			// Validate the mobile number
			if (/^[0-5]/.test(value)) {
				handleInputError({ type: 'mobileInput', isError: true, errorText: 'Mobile numbers starting with digits 0-5 are invalid.' });
			} else {
				handleInputError({ type: 'mobileInput', isError: false, errorText: '' });
			}
		}
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeStep1ErrorAction({ type, isError, errorText }));
	};

	const handleResendMobileInput = ({ type, resend }) => {
		dispatch(checkMobileOtpAction({ type, resend }));
	};

	const handleStep = async ({ type, value }) => {
		if (type === 'mobileInput') {
			setIsResendCounter(true);
			setIsChangeNumber(true);
			setIsCallEmailVerify(false);
			setCounter(mobileTimeOut);
			const mobileInput = kycStep1.mobileInput.value || '';
			const isValidMobile = validatePhoneNo(mobileInput || '');

			if (isValidMobile) {
				try {
					const payload = {
						mobile: mobileInput // Replace with the mobile you want to validate
					};
					const response = await mobilecheck(payload);

					if (response?.data.status === true) {
						handleInputError({ type: 'mobileInput', isError: true, errorText: 'Mobile already exists! Please use a different Mobile.' });
					} else {
						handleInputError({ type: 'mobileInput', isError: false, errorText: '' });
						kycStep1.mobileInput.status = 'done';
						// dispatch(setChannelAction({channel:selectedData?.IsETrade == 90 ?"Bigul":"Bonanza"}))
						dispatch(sendMobileOtpAction({
							channel: channel,
							mobile: mobileInput,
							user_id: userDetail.user_id,
							journey: "DKYC",
							rm_name: userDetail?.FullName,
							username: userDetail?.username
						}, callbackFunction));
					}
				} catch (error) {
					handleInputError({ type: 'mobileInput', isError: true, errorText: 'Something went wrong.' });


					// You can set an error state, display an error message, or perform other error handling actions here
				}




			} else {
				if (mobileInput?.length === 0) {
					handleInputError({ type: 'mobileInput', isError: true, errorText: 'Mobile number is required.' });
				} else if (kycStep1.mobileInput.value === parseInt('0000000000')) {
					handleInputError({ type: 'mobileInput', isError: true, errorText: 'Invalid mobile number.' });
				} else {
					handleInputError({ type: 'mobileInput', isError: true, errorText: 'Enter valid mobile number.' });
				}
				// clearInterval(intervalId)
			}
		} else if (type === 'mobileOtp') {
			const isValidOtp = validateOtp(value || '');
			setEmailCounter(emailTimeOut);
			if (isValidOtp) {
				handleInput({ type, value });
				dispatch(verifyMobileOtpAction({
					mobile: kycStep1.mobileInput.value,
					mobile_otp: value,
					journey: "DKYC",

				}));
				setIsResendCounter(false);
			} else {
				handleInputError({ type: 'mobileOtp', isError: true, errorText: 'OTP is required.' });
			}

		} else if (type === 'emailInput') {
			const emailInput = kycStep1.emailInput.value;
			const isValidEmail = validateEmail(emailInput || '');
			setIsChangeEmail(true);
			setEmailCounter(emailTimeOut);
			if (isValidEmail) {
				try {
					const payload = {
						email: emailInput // Replace with the email you want to validate
					};
					const response = await emailcheck(payload);

					if (response?.data.status && response?.data.message === 'email exist') {
						handleInputError({ type: 'emailInput', isError: true, errorText: 'Email already exists! Please use a different email.' });
					} else {
						handleInputError({ type: 'emailInput', isError: false, errorText: '' });
						kycStep1.emailInput.status = 'done';
						dispatch(sendEmailOtpAction({
							mobile: kycStep1.mobileInput.value,
							email: emailInput,
							journey: "DKYC",
						}));
						setIsEmailResendOtp(true);
						setIsCallEmailVerify(true);
					}
				} catch (error) {
					// Handle errors here
					handleInputError({ type: 'emailInput', isError: true, errorText: 'Something went wrong.' });

					// You can set an error state, display an error message, or perform other error handling actions here
				}


			} else {
				if (emailInput?.length === 0) {
					handleInputError({ type: 'emailInput', isError: true, errorText: 'Email adddress is required.' });
				} else {
					handleInputError({ type: 'emailInput', isError: true, errorText: 'invalid email adddress!' });
				}
			};
		} else if (type === 'emailOtp') {
			const isValidOtp = validateOtp(value || '');
			if (isValidOtp) {
				handleInput({ type, value });
				dispatch(verifyEmailOtpAction({
					email: kycStep1.emailInput.value,
					email_otp: value,
					journey: "DKYC",
					mobile: kycStep1.mobileInput.value
				}));
			} else {
				handleInputError({ type: 'emailOtp', isError: true, errorText: 'OTP is required.' });
			}
		} else if (type === 'resendMobileOtpInput') {
			setCounter(mobileTimeOut);
			handleInputError({ type: 'mobileOtp', isError: false, errorText: '' });
			dispatch(changeStep1MobileOtpStatusAction({ type: 'mobileOtp', value: '000000' }));
			const mobileInput = kycStep1.mobileInput.value || '';
			const isValidMobile = validatePhoneNo(mobileInput || '');
			if (isValidMobile) {

				dispatch(sendMobileOtpAction({
					channel: channel,
					mobile: mobileInput,
					user_id: userDetail.user_id,
					journey: "DKYC",
					rm_name: userDetail?.FullName,
					username: userDetail?.username

				}));
				setIsMobileResendOtp(true);
			} else {
				handleInputError({ type: 'mobileInput', isError: true, errorText: 'invalid OTP number!' });
			}
		} else if (type === 'resendEmailOtpInput') {
			setEmailCounter(emailTimeOut);
			setIsEmailResendOtp(true);
			dispatch(changeStep1EmailOtpStatusAction({ type: 'emailOtp', value: '000000' }));
			handleInputError({ type: 'emailOtp', isError: false, errorText: '' });
			const emailInput = kycStep1.emailInput.value;
			const isValidEmail = validateEmail(emailInput || '');
			if (isValidEmail) {
				dispatch(sendEmailOtpAction({
					mobile: kycStep1.mobileInput.value,
					email: emailInput,
					journey: "DKYC",

				}));
			} else {
				handleInputError({ type: 'emailInput', isError: true, errorText: 'invalid email !' });
			};
		}
		else if (type === 'changeNumberInput') {
			setIsChangeNumber(!isChangeNumber);
			kycStep1.mobileInput.status = 'edit'
			mobileInputRef.current.focus();
			setIsCallVerify(false);
		} else
			if (type === 'changeEmailInput') {
				kycStep1.emailInput.status = 'edit'
				setIsChangeEmail(!isChangeEmail);
				setIsCallEmailVerify(false);
			}
	};

	const callbackFunction = (resp) => {

		if (resp?.success == true) {
			setIsMobileResendOtp(true);
			setIsCallVerify(true);
		} else {
			setIsMobileResendOtp(false);
			setIsCallVerify(false);
		}

	}

	function handlePhoneNumberKeyPress(event) {
		if (event.target.value.length >= 10) {
			event.preventDefault();
		}
	}
	return (
		<div className="w-full flex flex-col">
			<div className="min-h-[calc(100vh-300px)]">
				{/* <Link /> */}
				<MainTitle title={'Mobile and Email Verification' + ' (' + channel + ')'} />
				<div className="mb-2 md:grid md:grid-cols-2 gap-10">
					<div className="flex md:flex-col">
						<InputBtnIcon
							icon={pngAssets.kyc.mobileNumber}
							label="Mobile Number"
							isImportant={true}
							placeholder="Please enter mobile number"
							btnIcon={svgAssets.kyc.send}
							btnWidth="175px"
							btnText="Submit"
							value={kycStep1.mobileInput.value}
							type='number'
							inputType="mobileInput"
							handleInputChange={handleInput}
							handleSubmit={handleStep}
							inputMessage={kycStep1.mobileInput.errorText}
							inputMessageType={kycStep1.mobileInput.isError && 'error'}
							isDisable={kycStep1.mobileInput.status === 'done' && isChangeNumber}
							autoFocus={true}
							handlePhoneNumberKeyPress={handlePhoneNumberKeyPress}
							mobileInputRef={mobileInputRef}
						/>
					</div>

					{

						kycStep1.mobileInput.status === 'done' && (
							<div className="flex flex-col">
								{
									kycStep1.mobileOtp.status == 'done' ?
										<VerifiedOtpInput
											label="Mobile OTP"
											isImportant={true}
											inputType="mobileOtp"
											handleInputChange={handleInput}
											handleSubmit={handleStep}
											inputMessage={kycStep1.mobileOtp.errorText}
											inputMessageType={kycStep1.mobileOtp.isError && 'error'}
											btnText={kycStep1.mobileOtp.status === 'done' ? 'Verified' : 'Verify'}
											isDisable={kycStep1.mobileOtp.status === 'done'}
											isResendCounter={isResendCounter}
											otpValue={kycStep1.mobileOtp.value}
										/>
										:
										<OtpInput
											label="Mobile OTP"
											isImportant={true}
											inputType="mobileOtp"
											handleInputChange={handleInput}
											handleSubmit={handleStep}
											inputMessage={kycStep1.mobileOtp.errorText}
											inputMessageType={kycStep1.mobileOtp.isError && 'error'}
											btnText={kycStep1.mobileOtp.status === 'done' ? 'Verified' : 'Verify'}
											isDisable={kycStep1.mobileOtp.status === 'done'}
											isResendCounter={isResendCounter}
											otpValue={kycStep1.mobileOtp.value}
										/>
								}
								<div className='flex items-center h-[60px]'>
									{
										kycStep1.mobileOtp.status !== 'done' && isChangeNumber &&
										<div className='ml-2'>
											<InputBtnIcon
												// btnIcon={svgAssets.kyc.send}
												btnWidth="150px"
												inputWidth="0px"
												btnText="Change Number"
												inputType="changeNumberInput"
												handleSubmit={handleStep}
											/>
										</div>
									}

									{
										kycStep1.mobileOtp.status === 'done' ?
											<>
											</>
											:
											<>
												{
													counter >= 0 &&
													<div className="">
														<div className='flex items-center'>
															{
																counter !== 0 && <>
																	<p className='ml-5'>Haven't received the code, Resend code in {counter} sec.</p>
																</>
															}

															{counter === 0 &&
																<>


																	<div className='ml-2'>
																		<InputBtnIcon
																			// btnIcon={svgAssets.kyc.send}
																			btnWidth="120px"
																			inputWidth="0px"
																			btnText="Resend OTP"
																			inputType="resendMobileOtpInput"
																			handleSubmit={handleStep}
																		/>
																	</div>
																</>
															}
														</div>

													</div>
												}
											</>
									}
								</div>

							</div>
						)
					}
				</div>
				<div className="mb-2 md:grid md:grid-cols-2 gap-10">
					<div className="flex md:flex-col">
						{
							// kycStep1.emailInput.status !== 'pending' && (
							kycStep1.mobileOtp.status !== 'pending' && kycStep1.mobileOtp.status !== 'edit' && (
								<div className="flex flex-col mt-3">
									<InputBtnIcon
										icon={pngAssets.kyc.mail}
										label="Email Id"
										isImportant={true}
										placeholder="Please enter email id"
										btnIcon={svgAssets.kyc.send}
										btnWidth="175px"
										btnText="Send OTP"
										value={kycStep1.emailInput.value}
										inputType="emailInput"
										handleInputChange={handleInput}
										handleSubmit={handleStep}
										inputMessage={kycStep1.emailInput.errorText}
										inputMessageType={kycStep1.emailInput.isError && 'error'}
										isDisable={kycStep1.emailInput.status === 'done' && isChangeEmail}
										autoFocus={true}
									/>
								</div>
							)
						}
					</div>

					{
						// kycStep1.emailInput.status === 'pending' && (
						kycStep1.emailInput.status == 'done' && (
							<div className="flex flex-col mt-3">
								{
									kycStep1.emailOtp.status === 'done' ?
										<VerifiedOtpInput
											label="Email OTP"
											isImportant={true}
											inputType="emailOtp"
											handleInputChange={handleInput}
											handleSubmit={handleStep}
											inputMessage={kycStep1.emailOtp.errorText}
											inputMessageType={kycStep1.emailOtp.isError && 'error'}
											btnText={kycStep1.emailOtp.status === 'done' ? 'Verified' : 'Verify'}
											isDisable={kycStep1.emailOtp.status === 'done'}
											isResendCounter={isResendCounter}
											value={kycStep1.emailOtp.value}
											otpValue={kycStep1.emailOtp.value}
										/>
										:
										<OtpInput
											label="Email OTP"
											isImportant={true}
											inputType="emailOtp"
											handleInputChange={handleInput}
											handleSubmit={handleStep}
											inputMessage={kycStep1.emailOtp.errorText}
											inputMessageType={kycStep1.emailOtp.isError && 'error'}
											btnText={kycStep1.emailOtp.status === 'done' ? 'Verified' : 'Verify'}
											isDisable={kycStep1.emailOtp.status === 'done'}
											isResendCounter={isResendCounter}
											value={kycStep1.emailOtp.value}
											otpValue={kycStep1.emailOtp.value}
										/>
								}

								<div className='flex items-center  h-[60px]'>
									{
										kycStep1.emailOtp.status !== 'done' && isChangeEmail &&
										<div className='ml-2'>
											<InputBtnIcon
												// btnIcon={svgAssets.kyc.send}
												btnWidth="150px"
												inputWidth="0px"
												btnText="Change Email"
												inputType="changeEmailInput"
												handleSubmit={handleStep}
											/>
										</div>
									}

									{
										kycStep1.emailOtp.status === 'done' ?
											<>
											</>
											:
											<>
												{
													emailCounter >= 0 &&
													<div className="">
														<div className='flex items-center'>
															{
																emailCounter !== 0 && <>
																	<p className='ml-4'>Haven't received the code, Resend code in {emailCounter} sec.</p>
																</>
															}

															{emailCounter === 0 &&
																<>
																	<div className='ml-2'>
																		<InputBtnIcon
																			// btnIcon={svgAssets.kyc.send}
																			btnWidth="120px"
																			inputWidth="0px"
																			btnText="Resend OTP"
																			inputType="resendEmailOtpInput"
																			handleSubmit={handleStep}
																		/>
																	</div>
																</>
															}
														</div>

													</div>
												}
											</>
									}
								</div>


							</div>
						)
					}
				</div>
				<div className="grid grid-cols-2 gap-10">

				</div>
			</div>
			<div className="flex justify-end">
				{
					kycStep1.mobileOtp.status === 'done' && kycStep1.emailOtp.status === 'done' ?
						<ContinueBtn
							handleContinueBtn={() => continueBtn({ step, index })}
						/>
						:
						<ContinueBtn
							handleContinueBtn={() => continueBtn({ step, index })}
							isDisable={true}
						/>
				}

			</div>
		</div>
	);
};
