import { useSelector } from 'react-redux';
import { MainTitle } from '../../components/common/MainTitle';
import { SingleDetail } from './SingleDetail';
import { svgAssets } from '../../assets/asset';


export const BankDetail = ({ reviewData, isVerify, pennyData,reviewFilesData,selectedLabelText}) => {
	const bank_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document === selectedLabelText))
const name =pennyData ?pennyData?.beneficiary_name_with_bank :'-';
	return (
		<>
		<div className='column-row'>
		<div className={bank_proof ? 'column-col-12':'mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]'}>
<div className="mb-5">
				<MainTitle title="Bank Details" />
			</div>
			<div className="grid grid-cols-12 gap-10">
				<div className="col-span-10">
					<SingleDetail label="IFSC" value={reviewData?.BankIFSC || ''} isVerify={isVerify} />
					<SingleDetail label="Bank Account Number" value={reviewData?.BankAccountNumber || ''} isVerify={isVerify} />
					<SingleDetail label="Bank Name" value={reviewData?.BankName || ''} isVerify={isVerify} />
					<SingleDetail label="Bank Account Holder Name" value={ name || '-'} />
					<SingleDetail label="Bank MICR Code" value={reviewData?.BankMICR  || '-'} />
				</div>
			</div>
		</div>
		{
			bank_proof &&
			<>
			<iframe style ={{height:'400px',width:'700px',overflow:'scroll'}} title="PDF Viewer" src={bank_proof?.ImagePath}   onError={(e) => console.error('Error loading PDF:', e)}  />
			
						</>
		}
		
		</div>
		{
			bank_proof &&
			<div className='w-100 text-right flex justify-content-end mt-4' style={{justifyContent: "flex-end"}}>
				<div onClick={()=>window.open(bank_proof?.ImagePath, '_blank')
		}
						className="px-3 py-1.5 w-max rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
					>
						{/*  */}
						<div className="flex items-center justify-center">
							<img className="mr-2 w-[40px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
							<span className="text-[#757575] text-xs font-poppinsRegular">Open New Window</span>
						</div>
						{/* </a> */}
					</div>
					</div>
		}
		</>
	);
};
