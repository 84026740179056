import { Link } from 'react-router-dom';
import { svgAssets } from '../../assets/asset';

export const BaseLogin = ({ children }) => {
	return (
		<div className="w-full h-screen flex  flex-col md:flex-row items-center justify-between px-4 md:p-0 py-2 md:py-0">
			<div className="w-full md:w-[45%] h-full flex items-center bg-[#5367FC]">
				<img alt="login_screen_image" src={svgAssets.login.loginScreenImg}></img>
			</div>
			<div className="w-full md:w-[55%] h-full py-10  md:px-20 flex flex-col items-center ">
				<div className="w-full max-h-max flex items-center justify-center gap-10 md:justify-between ">
					<div className='w-[90px] md:w-auto'>
					<Link
						to="/login"
					>
						<img alt="app_logo" className='w-full h-auto' src={svgAssets.logo} />
					</Link>
					</div>
					<div className="w-[80px] md:w-auto">
					<Link
						to="/login"
					>
						<img className="w-full h-auto p-1" alt="kyc_assist" src={svgAssets.login.kycAssist} />
					</Link>
					</div>
				</div>
				{ children }
			</div>
		</div>
	);
};
