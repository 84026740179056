import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SignaturePad from 'react-signature-canvas'

import { BaseLogin } from '../../../components/d-kyc/d-kyc';
import { pngAssets, svgAssets } from '../../../assets/asset';
import {
    changeStep4ErrorAction, clearSignatureAction,
    uploadSignatureAction
} from '../../../redux/actions/dkyc/step4.action';
import '../style.css'
import { ToastContainer, toast } from "react-toastify";
import imageCompression from 'browser-image-compression';
import { postFormApi, postFormApiCo } from '../../../services/common';

const DrawSignature = ({ changeStep, TokenData, isIncomeProof, isSelfie, isSign, reject, setRejectSignature }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const videoRef = useRef(null)
    const [openCamera, setOpenCamera] = useState(null)
    const [signMethod, setSignMethod] = useState("")
    const [uploadSignImage, setuploadSignImage] = useState("")    //upload sign
    const [captureSignImage, setcaptureSignImage] = useState("")  // capture sign
    const [showSignaturePad, setShowSignaturePad] = useState(true); //sign pad
    const [drawSign, setdrawSign] = useState("")  // draw sign

    const [convertFIle, setconvertFIle] = useState("")
    const [errorUpload, setErrorUpload] = useState("")
    const [imageSaved, setImageSaved] = useState(false);
    const sigCanvas = useRef(null)




    //--------  convert url to file -------
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    //------ choose sign method -----
    const chooseSignMethod = event => {
        setSignMethod(event.target.value);
        setShowSignaturePad(false);
        setdrawSign("")
        if (event.target.value === "upload") {
            stopCamera()
        }
    }

    //------  upload signature -----
    // const handleSignatureImg = (e) => {
    //     e.preventDefault();
    //     setconvertFIle(e.target.files[0])
    //     const imageUrl = URL.createObjectURL(e.target.files[0]);
    //     setuploadSignImage(imageUrl);
    // }

    const handleSignatureImg = async (e) => {
        setErrorUpload("");
 const allowedFormats = ['image/jpeg', 'image/jpg'];
        if (e.target.files[0].size > 5 * 1024 * 1024) { // Check if file size is greater than 3MB
            setErrorUpload("File size should not exceed 5MB.");
            return;
        }
        if (!allowedFormats.includes(e.target.files[0].type)) {
            setErrorUpload('Only JPG/JPEG formats are allowed');
          
            return
        }
        else {
            const image = e.target.files[0];
            const formDataa = new FormData();

            formDataa.append("image", image);
            formDataa.append("source", 'DKYC');
            const imageConvert = await postFormApiCo(formDataa, 'processImage');
            if (imageConvert?.data?.status === true) {
                const base64String = imageConvert?.data?.data[0];
                const binaryData = atob(base64String);
                const blob = new Blob([new Uint8Array(Array.from(binaryData).map(char => char.charCodeAt(0)))], { type: 'image/jpg' });
                const file = new File([blob], 'image.jpg', { type: 'image/jpg' });
                const maxSizeInBytes = 15 * 1024
                if (file.size > maxSizeInBytes) {
                    const res = await compressImage(file)
                    const imageUrl = URL.createObjectURL(res);
                    setuploadSignImage(imageUrl);
                    setconvertFIle(res)
                } else {
                    const imageUrl = URL.createObjectURL(file);
                    setuploadSignImage(imageUrl);
                    setconvertFIle(file)
                }

            }
            else {
                toast(imageConvert?.data?.msg)
            }

        };
    }

    const compressImage = async (imageFile) => {
        const options = {
            maxSizeMB: 0.012, // Adjust the maximum size in MB according to your requirements
            maxWidthOrHeight: 256, // Adjust the maximum width or height according to your requirements
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);

            return imageFile; // Return the original file if compression fails
        }
    };


    //  funtion to take sign picture : open camera -
    const handleTakePicture = async () => {
        const image = {
            video: { facingMode: "environment" },
        };
        try {
            const isstream = await navigator.mediaDevices.getUserMedia(image);
            setOpenCamera(isstream);
            videoRef.current.srcObject = isstream;
        } catch (err) {
            console.error(err);
        }
    };

    const handleVideoLoad = () => {
        videoRef.current.play();
    };


    const handleCapture = async (e) => {
        // const canvas = document.createElement('canvas');
        // canvas.width = videoRef.current.videoWidth;
        // canvas.height = videoRef.current.videoHeight;
        // canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
        // canvas.toBlob(async (blob) => {
        //     const compressedBlob = await compressImage(blob);
        //     const compressedImageUrl = await new Promise((resolve) => {
        //         const reader = new FileReader();
        //         reader.onloadend = () => resolve(reader.result);
        //         reader.readAsDataURL(compressedBlob);
        //     });
        //     const convertFile = dataURLtoFile(compressedImageUrl, 'file');
        //     if (convertFile.size > 5 * 1024 * 1024) {
        //         setErrorUpload("File size should not exceed 5MB.");
        //         return;
        //     }
        //     else {
        //         setErrorUpload("");
        //         setcaptureSignImage(compressedImageUrl);
        //         setconvertFIle(convertFile);
        //         stopCamera();
        //     }


        // }, 'image/png');
    };


    // const compressImage1 = async (blob) => {
    //     const options = {
    //         maxSizeMB: 0.012, // Adjust the maximum size in MB according to your requirements
    //         maxWidthOrHeight: 256, // Adjust the maximum width or height according to your requirements
    //     };

    //     try {
    //         const compressedBlob = await imageCompression(blob, options);
    //         return compressedBlob;
    //     } catch (error) {
    //         return blob;
    //     }
    // };

    const stopCamera = () => {
        openCamera?.getTracks().forEach(track => track.stop());
        setOpenCamera(null);
        videoRef.current.srcObject = null;
    };

    // clear Image
    const DeleteSignatuteImage = () => {
        dispatch(clearSignatureAction(""))
    }

    const handleSubmit = async () => {
        if (!TokenData?.pan || TokenData?.pan === '') {
            alert("Somthing wents wrong or your link is expired, Please try again !!")
            return
        }
        if (convertFIle.size > 5 * 1024 * 1024) { // Check if file size is greater than 3MB
            toast("File size should not exceed 5MB");
            return;
        }
        else {
            const payload = {
                PanNo: TokenData?.pan || '',
                Document: 'Client Signature',
                DocumentType: 'NA',
                step: reject.length > 0 ? 15 : 14,
                rejectReUploadFlag: reject.length ? 1 : null,
                CreatedBy: TokenData?.created_by || '',
                'DocumentImages[]': convertFIle || ""
            }
            const response = await postFormApi(payload, '/uploadfiles');
            if (response?.data.success) {
                changeStep(0)

            }
            else {
                toast(response.data.message || response.data.msg || 'something went wrong')
            }
        }
    }



    const handleReset = () => {
        setconvertFIle('')
        setErrorUpload("");

        setShowSignaturePad(true);
        setdrawSign(""); // Reset the drawn signature
        setuploadSignImage(""); // Reset the uploaded image
        setcaptureSignImage(""); // Reset the captured image
        setImageSaved(false);
        setSignMethod('')
        sigCanvas.current.clear(); // Clear the signature pad
    };

    const handleErase = () => {
        setdrawSign("");
        setShowSignaturePad(true);
        sigCanvas.current.clear();
    };

    const endDraw = () => {
        const canvas = sigCanvas.current;
        if (!canvas.isEmpty()) {
            console.log("canvas?.toDataURL('image/png')", canvas?.toDataURL('image/png'))
            setdrawSign(canvas?.toDataURL('image/png'));
            setImageSaved(true)
            const convertFile = dataURLtoFile(canvas?.toDataURL('image/png'), 'file')
            setconvertFIle(convertFile)
        }

    };

    return (
        <BaseLogin image={pngAssets.dkyc.leftpart4}>
            <ToastContainer autoClose={1000} />
            <div className='flex flex-col justify-between w-full   md:w-[80%] lg:w-[70%] h-full mx-auto'>
                <div className="">
                    <div className='my-4 flex flex-col justify-center gap-5 items-center'>
                        <p className='text-[20px] text-center font-bold font-poppinsRegular cursor-pointer'
                            onClick={() => setShowSignaturePad(true)}>
                            Draw your Signature</p>
                    </div>
                    {showSignaturePad ? (
                        <>
                            <div className="w-full lg:w-[400px] h-[150px] rounded-[5px] mx-auto  border-[0.25px]  border-[#797979] drop-shadow-[0_2px_10px_rgba(0,0,0,0.1)] bg-[#FFFFFF] flex justify-center items-center p-1 cursor-grab">
                                <SignaturePad
                                    ref={sigCanvas}
                                    onEnd={endDraw}
                                    canvasProps={{ width: 370, height: 130, className: 'sigCanvas' }}
                                />
                            </div>
                            <div className="my-4">
                                <div className="flex justify-center m-2 gap-x-5 ">
                                    {/* <button className="bg-[#5367FC] text-[18px] w-[30%] flex justify-center items-center gap-x-5 border-s-4 rounded-[20px] py-[5px] text-white cursor-pointer"
                                        onClick={handleSave}>Save</button> */}
                                    {imageSaved && (
                                        <button onClick={handleErase}
                                            className="bg-[#5367FC] text-[18px] w-[30%] flex justify-center items-center gap-x-5 border-s-4 rounded-[20px] py-[5px] text-white cursor-pointer"
                                        >
                                            Erase
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div style={{ backgroundColor: '#EEE' }} className="w-full lg:w-[70%] h-[150px] rounded-[5px] mx-auto  border-[0.25px]  border-[#797979] drop-shadow-[0_2px_10px_rgba(0,0,0,0.1)]  py-1 overflow-hidden " >
                            {uploadSignImage && <img src={uploadSignImage} className='w-[190px] lg:w-[250px] h-auto  mx-auto ' />}
                            {captureSignImage && <img src={captureSignImage} className='w-[190px] h-auto mx-auto ' />}
                            {drawSign && <img src={drawSign} className='w-[150px] h-[100px] mx-auto' />}
                            {!captureSignImage && (
                                <video
                                    ref={videoRef}
                                    onLoadedData={handleVideoLoad}
                                    className="w-full h-full rounded-full"
                                />
                            )}
                        </div>

                    )}



                    <>
                        {/* {
                            openCamera &&
                            <div className="flex justify-center mt-4">
                                <button className="bg-[#5367FC] text-[18px] w-[30%] flex justify-center items-center gap-x-5 border-s-4 rounded-[20px] py-[5px] text-white cursor-pointer"
                                    onClick={handleCapture}> Capture
                                </button>
                            </div>
                        } */}

                        {
                            !captureSignImage ?
                                <>
                                    <div className="">

                                        <div className='flex flex-col justify-center items-center w-full lg:w-[70%] mx-auto font-poppinsMedium text-[#797979] pt-3' >
                                            <ul className='text-[15px] list-disc'>
                                                <li className='pt-1'>Use your finger to sign within the box</li>
                                                <li className='pt-1'>Avoid signing with your initials</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mt-6 text-[#C9C9C9] font-poppinsMedium relative">
                                        <div className="flex justify-center items-center gap-x-2 mr-10">
                                            <div className='w-[60px] border-b  border-[#C9C9C9]'></div>
                                            <p>OR</p>
                                            <div className="w-[60px] border-b  border-[#C9C9C9]"></div>
                                        </div>
                                    </div>
                                    <div style={{ color: 'gray', fontWeight: '600' }} onClick={() => { handleReset() }} className="cursor-pointer mt-3 text-center">Reset</div>

                                </>

                                : <div className="py-6"></div>
                        }

                    </>
                    <>
                        <div className="text-[#797979] color-[#797979] lg:flex  justify-center items-center my-6 " onChange={chooseSignMethod}>
                            <div className="flex justify-between">

                                {
                                    (captureSignImage) &&
                                    < div className="flex lg:gap-x-3 gap-x-5 cursor-pointer ml-5">
                                        <img src={svgAssets.dkyc.viewIcon} alt="" />
                                        <p className="" onClick={() => setcaptureSignImage("")}>
                                            <img src={svgAssets.dkyc.deleteIcon} alt="" className='w-full h-full' />
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className="flex justify-between  mt-4 lg:mt-0">
                                <label className='flex justify-start items-center gap-x-5 font-poppinsMedium cursor-pointer '>
                                    <input
                                        name="sign"
                                        type="radio"
                                        value="upload"
                                        checked={signMethod === "upload"}
                                    />
                                    Upload Photo of Sign
                                </label>
                                {
                                    (uploadSignImage) &&
                                    <div className="flex lg:gap-x-3 gap-x-5 cursor-pointer ml-5">
                                        <img src={svgAssets.dkyc.viewIcon} alt="" />
                                        <p className="" onClick={() => setuploadSignImage("")}>
                                            <img src={svgAssets.dkyc.deleteIcon} alt="" className='w-full h-full' />
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    {/*---- choose method end-----*/}

                </div>
                {
                    errorUpload &&
                    <div className="flex justify-between items-center px-3 w-full">
                        <div className="font-poppinsRegular w-[80%]">
                            <p className=' text-ellipsis whitespace-nowrap overflow-hidden '><span style={{ color: 'red' }}>{errorUpload}</span></p>
                        </div>

                    </div>
                }
                {
                    convertFIle &&
                    <div className="flex justify-between items-center px-3 w-full">
                        <div className="font-poppinsRegular w-[80%]">
                            <p className=' text-ellipsis whitespace-nowrap overflow-hidden '>{convertFIle.size > 5 * 1024 * 1024 ? <span style={{ color: 'red' }}>File size should not exceed 5MB</span> : convertFIle?.name}</p>
                        </div>

                    </div>

                }

                <div className="">
                    <>
                        <div className="">
                            {uploadSignImage || drawSign || captureSignImage ?
                                <div className="">
                                    <button className="bg-[#5367FC] w-full flex  justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
                                        onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div>
                                :
                                <>
                                    {(signMethod === 'capture') ?
                                        <div className="">
                                            <button className="bg-[#5367FC] w-full flex  justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
                                                onClick={handleTakePicture}>
                                                Take Piture of your Sign
                                            </button>
                                        </div>
                                        :
                                        <>
                                            {
                                                (signMethod === 'upload') ?
                                                    <div className="relative  mb-5">
                                                        <input id="file-upload" type="file" className="opacity-0 absolute cursor-pointer" accept="image/png, image/jpg, image/jpeg"
                                                            onChange={handleSignatureImg} />
                                                        <button className="bg-[#5367FC] w-full flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 text-white font-poppinsMedium ">
                                                            Upload Photo of your Sign
                                                        </button>
                                                    </div>
                                                    :

                                                    convertFIle.size > 5 * 1024 * 1024 ?

                                                        <div className="" onClick={DeleteSignatuteImage}>
                                                            <button className="bg-[#C9C9C9] w-full flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular">
                                                                Submit
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="" >
                                                            <button className="bg-[#C9C9C9] w-full flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular">
                                                                Submit
                                                            </button>
                                                        </div>

                                            }
                                        </>

                                    }
                                </>

                            }
                        </div>
                    </>


                    <div className="my-5">
                        <div className="flex justify-center items-center cursor-pointer text-[#000000] font-poppinsMedium"
                            onClick={() => { changeStep(0) }}>
                            <p className="">Back</p>
                        </div>
                    </div>
                </div>

            </div>

        </BaseLogin >
    );
};
export default DrawSignature;
