
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BaseLogin } from '../../../components/d-kyc/d-kyc';
import { pngAssets, svgAssets } from '../../../assets/asset';
import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
import {
    changeStep2InputAction, clearBankProofAction,
    getIncomeProofListAction, uploadBankProofAction, uploadSalarySlipAction
} from '../../../redux/actions/dkyc/step2.action';
import '../style.css'
import { uploadBankDetailsAction } from '../../../redux/actions/dkyc/step1.action';
import { ToastContainer, toast } from "react-toastify";
import { postFormApiCo } from '../../../services/common';
import { startLoaderAction, stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';


export const IncomeProof = ({ changeStep, TokenData, isIncomeProof, isSelfie, isSign, reject, setIncome, mobileNo }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [proofDoc, setProofDoc] = useState("")  //upload proof
    const [document2, setDocument2] = useState([]) //multiple file
    const [isUploadBtn, setUploadBtn] = useState(true);
    const [isUpload3MonthSalarySlip, setIsUpload3MonthSalarySlip] = useState(false);

    // const TokenData = useSelector(state => state?.dkyc?.userDetails || {});
    const { incomeProof } = useSelector(state => state.dkycStep2);
    const incomeProofList = incomeProof?.data || [];
const [selectType,setSelectType]=useState('')

    const handleInput = ({ type, value }) => {
        
        setSelectType(value)
        dispatch(changeStep2InputAction({ type, value }));
        if (value) {
            setUploadBtn(false)
        }
        if (value === 'Latest 3 Month Salary Slip') {
            setIsUpload3MonthSalarySlip(true);
            
        } else {
            setIsUpload3MonthSalarySlip(false);
        }
    };

    const handleClick = (e) => {
        setProofDoc(e.target.files[0])
    }

    const clearBankProof = () => {
        setProofDoc("")
        dispatch(clearBankProofAction())
    }


    const handleSubmit = async () => {
        const mobile = mobileNo;

        const encryptionFormData = new FormData();
        encryptionFormData.append('mobile', mobile);
        encryptionFormData.append('doc_file', proofDoc);

        if (proofDoc) {
            const response1 = await postFormApiCo(encryptionFormData, 'check-encryption');
            if (response1?.data.status == 404) {
                toast(response1?.data.msg)
            }
            else if (!TokenData?.pan || TokenData?.pan === '') {
                toast("Your link is expired, Please try again!");
                return;
            }
            else if (proofDoc.size > 5 * 1024 * 1024) { // Check if file size is greater than 3MB
                toast("File size exceeds the limit of 5MB. Please choose a smaller image.");
                return;
            }
            else {
                dispatch(uploadBankDetailsAction({
                    PanNo: TokenData?.pan || '',
                    Document: 'Income Proof',
                    DocumentType:selectType,
                    step: reject.length > 0 ? 15 : 12,
                    rejectReUploadFlag: reject.length ? 1 : null,
                    CreatedBy: TokenData?.created_by || "",
                    'DocumentImages[]': proofDoc
                }, handleCallback));
            }
        }
        else if (document2) {
            store.dispatch(startLoaderAction());
            if (!TokenData?.pan || TokenData?.pan === '') {
                alert("Your link is expired, Please try again!")
                return
            }
            if (document2.length < 3) {
                toast('please select three document')
            }
            else {
                const formData = new FormData();
                formData.append('PanNo', TokenData?.pan || '');
                formData.append('Document', 'Income Proof');
                formData.append('DocumentType',selectType);
                formData.append('step', reject.length > 0 ? 15 : 12);
                formData.append('CreatedBy', TokenData?.created_by || ''); 
                formData.append('rejectReUploadFlag', reject.length ? 1 : null || '');
                // formData.append(`DocumentImages[]`,document2);
                document2.forEach((file) => {
                    formData.append(`DocumentImages[]`, file);
                });
                fetch('https://ekycapi.bigul.co/uploadfiles', {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNiMzQ5MWRhY2I3OTgyYzNjODkwMmFmZmZiNzA5ZWNiMTBlY2JhYTlhYmVhMzE1M2I2MzQ4NjQxODUyNThjODliZGJkYmMyYTkyYmFhZTg0In0.eyJhdWQiOiI1IiwianRpIjoiM2IzNDkxZGFjYjc5ODJjM2M4OTAyYWZmZmI3MDllY2IxMGVjYmFhOWFiZWEzMTUzYjYzNDg2NDE4NTI1OGM4OWJkYmRiYzJhOTJiYWFlODQiLCJpYXQiOjE3MTQ5OTI0OTgsIm5iZiI6MTcxNDk5MjQ5OCwiZXhwIjoxNzQ2NTI4NDk4LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.kAP8OjjK3zuKz8dx8m2w2_OLGWIg42IQoETIeD6SOCHmLWAYRJH1qULKoHTpWCbwE85Dn2dBbA8ThvpstGPeQv-3z91o92-qGbiUtxNV7dFnHYDwtNGGBA_aStcF6Edripax_FkHIe5b4_6GToCImHddZ7GXM4SJNQEVxNG7nrb32aArObPMnTFknwAFym4y1zS2mqmqondPcldCw_mbp5IR5BxC2YY537G8MKwGukDXrcSdGxUBdzhql_nVRnJRMKwjxx4AYRifni9cNPwnQgHN0cKpFoQ5JnDtzatcgbaHWhCiO6zHiNTBD_CS_HPSic82LBI74xJMyDemCSu2hOrNVmPR5qn5JE4H_InjFQtrXRcCfTeWs6Y9Lv-ef5X11OkkwusKmcjvR4Ri5yYqI9gtn81LRZDGgHlNQg-SBP1TYxUAZWe04bAEaKWYnSyDLY1PyROPeQPRb52g59FWH8leepph_NYTpbvVHOwchV5UaY33OxuVoEguPTptxaZTA2HgOfgoT2WFKhrix57AjPIwOOkUM7XUb2QyXCSvc1Pd05xyd0-O4_LzWaDwD_WunQy1vo-Gfie6t3dJTZSBf0lA3fGdojdfkTlM1ZEapvxFJU3QeZG9fcwyp6M-7MklNfjHKmrkcKzOoyl75i0OBSgC7dUpXQC4Wb_HynuJHfQ', // Replace with your actual access token
                        // 'Content-Type': 'application/json',

                        // 'Content-Type': 'multipart/form-data', // Assuming you're sending multipart form data
                    },
                })
                    .then(response => {
                        if (response.ok) {
                            store.dispatch(stopLoaderAction());
                            // Handle the success case
                            console.log('Files uploaded successfully');
                            changeStep(0)
                        } else {
                            store.dispatch(stopLoaderAction());
                            // Handle the error case
                            toast('Failed to upload files');
                        }
                    })
                    .catch(error => {
                        toast(error)
                        store.dispatch(stopLoaderAction());
                    });



            }
        }
    }
    const handleCallback = (res) => {

        if (res?.success) {
            toast('Income proof upload successfully')
            // setIncome(true)
            setTimeout(() => {
                changeStep(0)
            }, 500);
        }
        else {
            toast(res.message)
        }
    }
    useEffect(() => {
        incomeProofList.length < 1 && dispatch(getIncomeProofListAction());
    }, []);

    const handleMulifpleFiles = async(e) => {
        const mobile = mobileNo;

     
       const encryptionFormData = new FormData();
       encryptionFormData.append('mobile', mobile);
       encryptionFormData.append('doc_file', (e.target.files[0]));

      
           const response1 = await postFormApiCo(encryptionFormData, 'check-encryption');
           if (response1?.data.status == 404) {
               toast(response1?.data.msg)
           }
           else{
  const files = Array.from(e.target.files);
        if (document2.length + files.length > 3) {
            alert('Your document is already uploaded. ')
            return;
        }

        setDocument2([...document2, ...files]);
           }
      
    };

    const clearMultiplefile = (itemToRemove) => {
        const updatedItems = document2.filter((item, inx) => inx !== itemToRemove);
        setDocument2(updatedItems);
        console.log("updatedItems", updatedItems)
    }


    return (
        <BaseLogin image={pngAssets.dkyc.leftpart2}>
           < ToastContainer autoClose={100} />

            <div className="flex flex-col justify-between items-center w-full md:w-[80%] lg:w-[70%] h-full mx-auto">
                <div className="w-full">
                    <   div className='text-center my-8'>
                        <div className=' my-6'>
                            <h1 className='font-poppinsRegular text-[17px]'>Upload Income Proof</h1>
                        </div>
                    </div>
                    <div className="w-full col-span-6 ">
                        <SelectDropdown
                            label="Upload Income Proof"
                            placeholder="Please select documents type"
                            options={incomeProofList}
                            selectedValue={incomeProof.value}
                            inputType="incomeProof"
                            handleSelect={handleInput}
                            inputMessage={incomeProof.errorText || ''}
                            inputMessageType={incomeProof.isError && 'error'}
                        />
                    </div>

                    <div>

                        {
                            proofDoc &&
                            <div className='mt-5'>
                                <input type="text"
                                    className="input-text-custom border border-[#9E9E9E] text-[#9E9E9E] w-full border-s-4 rounded-[30px] py-3 px-5 cursor-pointer focus:outline-none focus:shadow-none font-poppinsRegular"
                                    placeholder='Enter Document Password, if any'
                                />
                            </div>

                        }

                        {
                            isUploadBtn ?
                                <div className="bg-[#C9C9C9] flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white mt-8">
                                    <p className="font-poppinsRegular"> Upload Income Proof</p>
                                </div>
                                :
                                <>
                                    {
                                        isUpload3MonthSalarySlip ?
                                            <div className="relative mt-8 mb-5">
                                                <input
                                                    id="file-upload"
                                                    type="file"
                                                    multiple
                                                    className="opacity-0 absolute"
                                                    accept="image/png, image/jpg, image/jpeg, application/pdf"

                                                    onChange={handleMulifpleFiles}
                                                />
                                                {/* < input id="file-upload" type="file" multiple className="opacity-0 absolute" accept="image/png, image/jpg, image/jpeg"  onChange={handleMulifpleFiles} /> */}
                                                <div className="bg-[#5367FC] flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 cursor-pointer text-white">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                                    </svg>

                                                    <span className="font-medium font-poppinsMedium">Upload Salary Slip</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="relative mt-8 mb-5">
                                                <input
                                                    id="file-upload"
                                                    type="file"
                                                    className="opacity-0 absolute"
                                                    accept="image/png, image/jpg, image/jpeg, application/pdf"

                                                    onChange={handleClick}
                                                />
                                                {/* <input id="file-upload" type="file" className="opacity-0 absolute" accept="image/png, image/jpg, image/jpeg" onChange={handleClick} /> */}
                                                <div className="bg-[#5367FC] flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 cursor-pointer text-white">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                                    </svg>

                                                    <span className="font-medium font-poppinsMedium">Upload Income Proof</span>
                                                </div>
                                            </div>
                                    }
                                </>
                        }


                        {
                            proofDoc &&
                            <div className="flex justify-between items-center px-3 w-full">
                                <div className="font-poppinsRegular w-[80%]">
                                    <p className=' text-ellipsis whitespace-nowrap overflow-hidden '>{proofDoc.size > 5 * 1024 * 1024 ? <span style={{ color: 'red' }}>File size exceeds 5MB. Please choose a smaller file.</span> : proofDoc?.name}</p>
                                </div>
                                <div className="bg-[#C9C9C9] rounded-full w-[15px] h-[15px] flex justify-center items-center p-[3.5px] font-poppinsMedium cursor-pointer"
                                    onClick={clearBankProof}  >
                                    <img src={svgAssets.closeIcon} alt="" />
                                </div>
                            </div>

                        }

                       
                        <div className="flex justify-between items-center px-3 w-full">
                            {document2.map((file, index) => (
                                <div key={index}>

                                    <p>{file.name}</p>
                                    <div className="bg-[#C9C9C9] rounded-full w-[15px] h-[15px] flex justify-center items-center p-[3.5px] font-poppinsMedium cursor-pointer"
                                        onClick={() => clearMultiplefile(index)}  >
                                        <img src={svgAssets.closeIcon} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='w-full '>

                    {proofDoc || document2.length > 0 ?
                        <div className="">
                            <button className="bg-[#5367FC] w-full flex  justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
                                onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                        :
                        <div className="">
                            <div className="bg-[#C9C9C9] flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white"
                                onClick={clearBankProof}
                            >
                                <p className="font-poppinsRegular">Submit</p>
                            </div>
                        </div>
                    }


                    <div className="my-5">
                        <div className="flex justify-center items-center cursor-pointer text-[#231F20] font-poppinsRegular"
                            onClick={() => { changeStep(0) }}>
                            <p className="">Back</p>
                        </div>
                    </div>
                </div>
            </div>


        </BaseLogin >
    );
};




