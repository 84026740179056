import React, { useEffect, useState } from 'react';
import { validatePanDetails2 } from '../../../services/common';
import { toast } from 'react-toastify';
import { MainTitle } from '../../../components/common/MainTitle';

export const Nominee2 = ({ pan, handlesubmit }) => {
    const [dpidOptions, setDpidOptions] = useState([]);
    const [selectedDpid, setSelectedDpid] = useState('');
    const [error, setError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    
    const handleCheckboxChange = async () => {
        setError('');
        setIsChecked(false); 
        if(!isChecked){
            try {
                const response = await validatePanDetails2({pan});
                if (response?.data?.status === 'success' && response?.data?.data?.length > 0) {
                    const dpids = response.data.data.map(item => item.CCLIENTBOID.substring(8));
                    setDpidOptions(dpids);
                    setSelectedDpid(dpids[0]);
                    setIsChecked(true); 
                } else {
                    setError('Only Trading Data is not available.');
                }
            } catch (error) {
                setError(error.message || 'Something went wrong');
                setIsChecked(false); // Reset checkbox state if there's an error
            }

        }
        else{
            isChecked(false)
        }
        
       
    };

    const handleSelectChange = (e) => {
        setSelectedDpid(e.target.value);
    };

    useEffect(() => {
if(isChecked){
    handlesubmit(selectedDpid);

}
else{
    handlesubmit(null);

}

    }, [selectedDpid, handlesubmit,isChecked])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <input
                    className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500 mb-5"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
               <MainTitle title="Only trading" style={{ marginBottom: '0' }} />
            <p style={{color:'red', marginLeft:'20px', marginBottom:'20px'}}>{error}</p>

            </div>
            {isChecked && (
                <div className="ccustom-row">
                    <div className='col-md-6'>
                        <input
                            type='text'
                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                            placeholder="Enter DP Code"
                            value={'12033500'}
                            readOnly={true}
                        />
                    </div>
                    <div className='col-md-6'>
                        <select
                            className="h-[56px] w-[100px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                            value={selectedDpid}
                            onChange={handleSelectChange}
                        >
                            {dpidOptions.map((dpid, index) => (
                                <option key={index} value={dpid}>{dpid}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
           
        </div>
    );
};
