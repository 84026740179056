export const MARITAL_STATUS = {
  "01": "Married",
  "02": "Unmarried",
}

export const STATE_CODES = {
  "001": "Jammu and Kashmir",
  "002": 'Himachal Pradesh',
  "003": 'Punjab',
  "004": 'Chandigarh',
  "005": 'Uttarakhand',
  "006": 'Haryana',
  "007": 'Delhi',
  "008": 'Rajasthan',
  "009": 'Uttar Pradesh',
  "010": 'Bihar',
  "011": 'Sikkim',
  "012": 'Arunachal Pradesh',
  "013": 'Assam',
  "014": 'Manipur',
  "015": 'Mizoram',
  "016": 'Tripura',
  "017": 'Meghalaya',
  "018": 'Nagaland',
  "019": 'West Bengal',
  "020": 'Jharkhand',
  "021": 'Orissa',
  "022": 'Chhattisgarh',
  "023": 'Madhya Pradesh',
  "024": 'Gujarat',
  "025": 'Daman and Diu',
  "026": 'Dadra and Nagar Haveli',
  "027": 'Maharashtra',
  "028": 'Andhra Pradesh',
  "029": 'Karnataka',
  "030": 'Goa',
  "031": 'Lakshadweep',
  "032": 'Kerala',
  "033": 'Tamil Nadu',
  "034": 'Puducherry',
  "035": 'Andaman and Nicobar Islands',
  "036": 'APO',
  "037": 'Telangana',
}
