import { all, call, put, takeEvery } from 'redux-saga/effects';
import { generateTokenService } from '../services/generateToken.service';
import { forgotPassword, generateToken, loginUser, resetPassword, setLoginUserAction, setTokenAction } from '../redux/actions/auth.action';
import { postApi } from '../services/common';
import localStorage from 'redux-persist/es/storage';

function * generateTokenSaga () {
	try {
		const token = yield call(generateTokenService);
		yield put(setTokenAction({ token: token.data.data || '' }));
	} catch (err) {
		console.log(err);
	};
};

function * loginUserSaga ({ payload, callback }) {
	localStorage.setItem('checkStatus', false);
	try {
		const user = yield call(postApi, payload,'/UserLogin');
		yield put(setLoginUserAction({ userDetails: user?.data?.data || [] }));
		if(user?.data?.data[0]?.user_update_status == null){
			localStorage.setItem('checkStatus', true);
}
		yield call(callback, { status: 200, success: user?.data?.success, message: user?.data?.message,detail:user?.data?.data });
	} catch (err) {
		yield call(callback, { status: 401 });
		console.log(err);
	};
};

function * forgotPasswordSaga ({ payload, callback }) {
	try {
		const user = yield call(postApi, payload, '/ForgotPassword');
		yield call(callback, { status: 200, success: user?.data?.success, message: user?.data?.message });
	} catch (err) {
		yield call(callback, { status: 401 });
		console.log(err);
	};
};

function * resetPasswordSaga ({ payload, callback }) {
	try {
		const user = yield call(postApi, payload, '/ResetPassword');
		// yield call(callback, { status: 200 });
		yield call(callback, { status: 200, success: user?.data?.success, message: user?.data?.message });
	} catch (err) {
		yield call(callback, { status: 401 });
		console.log(err);
	};
};

function * authSaga () {
	yield all([
		takeEvery(generateToken, generateTokenSaga),
		takeEvery(loginUser, loginUserSaga),
		takeEvery(forgotPassword, forgotPasswordSaga),
		takeEvery(resetPassword, resetPasswordSaga)
	]);
}

export default authSaga;
