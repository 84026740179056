import { DatePicker, Space } from 'antd';
import { messageTypeParameter } from '../../utils/input';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { useEffect } from 'react';
import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const DatePickerInput = ({ icon, label, isImportant, height, isDisable, value, placeholder, inputType, handleInput, handleInputError, inputMessage, inputMessageType,handleEnterKeyPress }) => {
useEffect(() => {
		if (value?.length >= 10) {
			if (value?.length !== 10) {
				return handleInputError({ type: "dobInput", isError: true, errorText: "Invalid date format" })
			}
			if(!dayjs(value, "DD-MM-YYYY", true).isValid()){
				return handleInputError({ type: "dobInput", isError: true, errorText: "Enter Date of Birth this format DD-MM-YYYY" })
			}
			if(dayjs(value, "DD-MM-YYYY", true).isAfter(dayjs().subtract(18, 'year')))
				return handleInputError({ type: "dobInput", isError: true, errorText: "Minimum age requirement is 18 years" })
			return handleInputError({ type: "dobInput", isError: false, errorText: "" })
		}
	}, [value])

	const onChange = (date, dateString) => {
		handleInput({ value: dateString, type: inputType });
	};

	const disabledDate = (current) => {
		// Calculate the minimum allowed date (18 years ago)
		const minDate = moment().subtract(18, 'years');

		// Disable all dates that are after the minimum allowed date
		return current && current > minDate;
	};

	const formatter = (value) => {
		// Add a dash after the day part of the date
		return value ? moment(value).format('DD-') : '';
	};

	const parser = (value) => {
		// Remove the dash before parsing the input value
		return value ? moment(value.replace('-', ''), 'DDMMYYYY') : null;
	};
	const staticDate = moment().subtract(18, 'years').format('DD-MM-YYYY');

	return (
		<div className="flex flex-col relative">
			<label className="mb-4 flex items-center leading-6 font-poppinsMedium">
				{
					icon && <img
						className="max-h-[22px] mr-1.5 object-contain"
						alt="input_icon"
						src={icon}
					/>
				}
				<span className="mr-1">{label}</span>
				{isImportant && <span className="text-[#EA0000]">*</span>}
			</label>
			<Space direction="vertical">
				<div className='relative w-full'>
					<DatePicker
						id={"picker"}
						className="w-full px-4 text-[#353535] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular border-0 focus:outline-none"
						style={{ height: height || '47px' }}
						autoOk
						autoFocus={false}
						placement={'bottomRight'}
						format="DD-MM-YYYY"
						formatter={formatter}
						parser={parser}
						onChange={onChange}
						// defaultValue={18-10-2005}
						placeholder={value || placeholder}
						disabled={isDisable}
						disabledDate={disabledDate}
						/>
					<div className='absolute bottom-2 left-2 ' style={{ width: '50%' }}>
						<InputMask
							// mask='99-99-9999'
							value={value}
							onChange={(event) => {
								if (!handleInput) return;
								let newValue = event?.target?.value;
								if (newValue?.length < value?.length) {
									return handleInput({ value: newValue, type: inputType });
								}
								const splitArray = newValue.replace("-", "").split("");
								if (splitArray.length >= 2 && splitArray[2] !== "-") splitArray.splice(2, 0, "-");
								if (splitArray.length >= 5 && splitArray[5] !== "-") splitArray.splice(5, 0, "-");
								handleInput({ value: splitArray.join(""), type: inputType });
							}}
							placeholder="DD-MM-YYYY"
							className={isDisable ? 'bg-[#eceeef] focus:outline-none' : 'focus:outline-none'}
							disabled={isDisable}
							onKeyDown={handleEnterKeyPress}
							style={{ height: '35px' }} />
					</div>
				</div>
			</Space>

			{inputMessage && inputMessageType && (
				<div
					style={{
						color: messageTypeParameter[inputMessageType].color
					}}
					className="mt-2.5 flex items-center text-sm font-poppinsRegular"
				>
					<img className="mr-1.5" alt="input_message_type_icon" src={messageTypeParameter[inputMessageType].icon} />
					<span className="inline-flex items-center">{inputMessage}</span>
				</div>
			)
			}
		</div>
	);
};

export default DatePickerInput;
