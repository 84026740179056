import { all, call, takeEvery } from 'redux-saga/effects';
import { setAddressDetail, setPersonalDetail } from '../../redux/actions/kyc/step3.action';
import { postApi , postApiWithoutLoader} from '../../services/common';

function * setPersonalDetailSaga ({ payload, callback }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/AddClientInfo');
		yield call(callback , data?.data);
	} catch (err) {
		yield call(callback ,{success:'falies'});
	};
};
function * setAddressDetailSaga ({ payload, callback }) {
	try {
		const AddAddressInfo = yield call(postApi, payload, '/AddAddressInfo');
		yield call(callback, AddAddressInfo?.data);
	} catch (err) {
		console.log(err);
		yield call(callback ,{success:'falies'});
	};
};

function * kycStep3Saga () {
	yield all([
		takeEvery(setPersonalDetail, setPersonalDetailSaga),
		takeEvery(setAddressDetail, setAddressDetailSaga)
	]);
}

export default kycStep3Saga;
