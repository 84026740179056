import React, { useState, useEffect } from 'react'
import { statusParameter } from './progressBarData';


export const TableProgressBar = ({ steps, selectedStep, showStepLabel, width, circleDim, circleRadius, lineDim, reject }) => {
	var rejectReason = reject

	let displayedStep;
	if (selectedStep >= 1 && selectedStep <= 4) {
		displayedStep = 1;
	}
	else if (selectedStep == 5) {
		displayedStep = 2;

	}
	else if (selectedStep == 6) {
		displayedStep = 3;

	}

	else if (selectedStep == 8) {
		displayedStep = 4;

	}
	else if (selectedStep == 9) {
		displayedStep = 5;

	}
	else if (selectedStep == 10 || selectedStep == 11 || selectedStep == 12 || selectedStep == 13 || selectedStep == 14) {
		displayedStep = 6;
	}
	else if (selectedStep == 15) {
		displayedStep = 7;
	}

	return (
		<div className="w-full flex flex-col items-center justify-center">
			<div className={`${width || 'w-full md:w-3/4'} grid grid-cols-8 items-center mb-[15px]`}>
				{
					steps?.length > 0
						? steps.map((step, index) => index !== steps.length - 1
							? (
								<div key={`step_${index}`} className="w-full flex items-center">
									<div className="w-full flex items-center">
										<div className={`w-full ${lineDim || 'h-[5px]'} items-center ${index !== 0 ? displayedStep >= index ? 'bg-[#5367FC]' : 'bg-[gray]' : ''}`}>
											<div width={50} className={`w-1/2 ${lineDim || 'h-[5px]'} leading-none ${index !== 0 ? displayedStep >= index ? 'bg-[#5367FC]' : 'bg-[gray]' : ''}`}></div>
										</div>
									</div>
									<div
										className={`h-full rounded-full`}
										style={{
											minWidth: circleDim || '20px',
											width: '20px',
											height: '10px',
											backgroundColor: displayedStep >= index ? rejectReason ? index === 7 ? 'red' : '#5367FC' : '#5367FC' : 'gray',
										}}
									>
									</div>
									<div className="w-full flex items-center">
										<div className={`w-full ${lineDim || 'h-[5px]'} items-center ${index !== steps.length - 2 ? displayedStep > index ? 'bg-[#5367FC]' : 'bg-[gray]' : ''}`}>
											<div width={50} className={`w-1/2 ${lineDim || 'h-[5px]'} leading-none ${index !== steps.length - 2 ? selectedStep > index ? 'bg-[#5367FC]' : 'bg-[gray]' : ''}`}></div>
										</div>
									</div>
								</div>
							)
							: null)
						: null
				}
			</div>
			{
				showStepLabel && (
					<div className="w-full md:w-3/4 grid grid-cols-8 items-center justify-items-center">
						{
							steps.map((step, index) => index !== steps.length - 1
								? (
									<div
										key={`step_label_${index}`}
										className="font-medium font-poppinsMedium"
										style={{
											color: statusParameter[step.status].borderColor
										}}
									>
										<p className='flex'><span className='hidden lg:block mr-1'>Step</span>{step.label}</p>
									</div>
								)
								: null)
						}
					</div>
				)
			}
		</div>
	);
};
