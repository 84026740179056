import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CustomDatePicker from '../components/inputs/CustomDatePicker';
import { MyPopover } from '../components/popover/Popover';
import { PageCount } from '../components/reactTable/PageCount';
import { ReactTable } from '../components/reactTable/ReactTable';
import { reactTableStatusValue } from '../components/reactTable/reactTableData';
import { ReactTableHeader } from '../components/reactTable/ReactTableHeader';
import { PopoverChildComp, PopoverParentComp } from '../components/reactTable/ReactTablePopupBtn';
import { SearchBar } from '../components/searchbar/SearchBar';
import { getEsignReportAction } from '../redux/actions/e-sign-report.action';
import { useDispatch, useSelector } from 'react-redux';
import { postApi } from '../services/common';
import dayjs from "dayjs";
import { toast } from 'react-toastify';
import { sendEquityEsignAction } from '../redux/actions/kyc/review.action';
import axios from 'axios';
export const baseUrl = 'https://ekycapi.bigul.co';

export const ESignReport = () => {

	const dispatch = useDispatch("")
	const [isLoading, setIsLoading] = useState(true);
	const [esignData, setEsignData] = useState([]);
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	const handlePdfView = async (pan, client) => {
		const payload = {
			clientId: client,

		}
		const response = await postApi(payload, '/downloadPdfDigio');
		if (response?.data?.success) {
			console.log(response?.data, 'k');
			window.open(response?.data?.data, '_blank');
		}
		else {
			toast(response.msg ? response.msg : response?.data?.msg ? response?.data?.msg : 'something went wrong')
		}
	}
	const handlePdfView1 = async (client) => {
		const payload = {

			ClientId: client,
			IsESign: 'No',
			IsType: 'equity'
		}
		const response = await postApi(payload, '/GetESign');
		if (response?.data?.success) {

			window.open(response?.data?.data?.PdfPath, '_blank');
		}
		else {
			toast(response.message ? response.msg : response?.data?.msg ? response?.data?.msg : 'something went wrong')
		}
	}

	const handleEsign = async (c_id, check) => {
		// alert('pending')
		const payload = {

			ClientId: c_id,
			channel: check == 90 ? "Bigul" : 'Bonanza',
			"IsESign": "Yes",
			"IsType": "equity",
		}
		const response = await postApi(payload, check == 90 ? '/GetESignMyetrade' : '/GetESign');
		if (response?.data?.success) {
			window.open(response?.data?.data?.URLL, '_blank');
		}
		else {
			toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
		}
	}

	const handleSendToLd = async (id) => {
		const payload = {
			ClientId: id,

		}
		const response = await postApi(payload, '/SendKycinfoToLD');
		if (response?.data?.success) {
			toast(response?.data?.message)
			handleApplication({ start: 1, end: 100, search: '' });
		}
		else {
			toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
		}
	}

	const GetXmlfile=async(pan, client)=>{
		try {
		   const response = await axios.get(baseUrl+'/getClientXmlFile/'+client+'/'+pan);
		   console.log(response,'response');
		   if(response?.status == 200){
			window.open(baseUrl+'/getClientXmlFile/'+client+'/'+pan, '_blank');
		   }
		   else{
			toast('Data not found')
		   }
		 
		//    setLoading(false);
		 } catch (err) {
			toast('something went wrong')
		//    setError(err);
		//    setLoading(false);
		 }
	   };

	const columns = [
		{
			

			Header: 'Send to Ld', accessor: 'LD', minWidth: 130,
			Cell: ({ row }) => (
				row?.original?.IsSendToLD == null && userDetail?.role_id == 6 ?
					<div>
						<button className='btn-normal' onClick={() => { handleSendToLd(row?.original?.ClientId) }} style={{ backgroundColor: '#2196f3', marginLeft: '10px' }}>Send to Ld</button>
					</div>
					:
					userDetail?.role_id == 6 ?
					<button className='btn-normal' style={{ backgroundColor: 'green' }}>
						Done
					</button>
					:null
			),
		},
		{ Header: 'LD Code', accessor: 'ldCode', minWidth: 130, Cell: ({ row }) => row?.original?.LDCode },
		{ Header: 'Pan No.', accessor: 'Pan_no', minWidth: 130, Cell: ({ row }) => row?.original?.PanNo },
		{ Header: 'Name', accessor: 'name', minWidth: 130, Cell: ({ row }) =>  `${row?.original?.FName || ''} ${row?.original?.MName || ''} ${row?.original?.LName || ''}`.trim()},
		{ Header: 'XML.', accessor: 'xml', minWidth: 130, Cell: ({ row }) =>(
			<button className='btn-normal' onClick={() => {GetXmlfile(row?.original?.PanNo, row?.original?.ClientId)}} style={{ backgroundColor: '#689f38' }}>
						XML
					</button>
		)
	 },

		{
			Header: 'Pdf View', accessor: 'pdf', minWidth: 130,
			Cell: ({ row }) => (
				row?.original.IsESign == 1 ?
					<button className='btn-normal' onClick={() => handlePdfView(row?.original?.PanNo, row?.original?.ClientId)} style={{ backgroundColor: '#d50000' }}>
						View PDF
					</button>
					: null
			),
		},

		{
			Header: 'E-Sign', accessor: 'sign', minWidth: 130,
			Cell: ({ row }) => (
				row?.original?.IsESign == null ?
					<div>
						<button className='btn-normal' onClick={() => handleEsign(row?.original?.ClientId, row?.original?.IsETrade)} style={{ backgroundColor: '#689f38' }}>Esign</button>
					</div>
					:
					<button className='btn-normal' style={{ backgroundColor: 'green' }}>
						Done
					</button>
			),
		},

		{
			Header: 'Created At', accessor: 'createdAt', minWidth: 250, icon: true, icon: true, Cell: ({ row }) =>
				row.original?.created_at &&
				dayjs(row.original?.created_at).format("DD MMM YYYY" + ' ' + 'HH:mm:ss'),
			sortable: true,
		},
		{ Header: 'Reg.id', accessor: 'reg_id', minWidth: 130, icon: true, icon: true, Cell: ({ row }) => row?.original?.ClientId },
		{ Header: 'Rm name', accessor: 'rmname', minWidth: 130, icon: true, icon: true, Cell: ({ row }) => row?.original?.UserName },


	];

	const handleApplication = async ({ start, end, search }) => {
		const payload = {
			"UserId": userDetail?.user_id || '',
			"RoleId": userDetail?.role_id || '',
			"start": start,
			"end": end,
			search: search
		}
		const response = await postApi(payload, '/GetESignInfo');
		if (response?.data?.success) {
			setEsignData(response?.data?.data)
		} else {
			toast(response?.data.message)
		}
	};

	useEffect(() => {
		handleApplication({ start: 1, end: 100, search: '' });
	}, []);

	const handleSearch = ({ searchVal }) => {
		if (searchVal.length == 0) {
			handleApplication({ start: 1, end: 100, search: "" });
		}
		else {
			handleApplication({ start: "1", end: "100", search: searchVal });
		}
	};


	return (
		<>

			<div className="w-full flex flex-col ">
				<div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex flex-col md:flex-row items-center justify-between mb-7 gap-y-5 md:gap-y-0 w-full">
						<ReactTableHeader
							title="E-Sign Report"
							numberOfApplications={esignData && esignData?.length}
						/>
						{/* <PageCount /> */}
					</div>
					<div className="mb-6 flex items-center justify-between">
						<SearchBar border={"border"}
							data={esignData?.data}
							handleSelect={handleSearch} />

					</div>
					{
						isLoading ?
							<>
								<SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
									<Skeleton count={10} style={{ height: '30px', marginTop: '10px' }} borderRadius={10} />
								</SkeletonTheme>
							</>
							:
							<ReactTable columns={columns} data={esignData || []} />
					}
				</div>
			</div>
		</>
	);
};
