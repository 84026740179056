export const changeStep1Input = 'CHANGE_STEP1_INPUT';
export const changeStep1Error = 'CHANGE_STEP1_ERROR';
export const fetchBankAccount = 'FETCH_BANK_ACCOUNT';
export const fetchSearchBankAccount = 'FETCH_SEARCH_BANK_ACCOUNT';
export const verifyBankAccount = 'VERIFY_BANK_ACCOUNT';
export const setBankInfo = 'SET_BANK_INFO';
export const setClearBankInfo = 'SET_CLEAR_BANK_INFO';
export const setSearchBankInfo = 'SET_SEARCH_BANK_INFO';
export const saveBankAllInfo = 'SAVE_BANK_ALL_INFO';
export const uploadBankDetails = 'UPLOAD_BANK_DETAIL';
export const clearBankDetails = 'CLEAR_BANK_DETAIL';


export const changeStep1InputAction = (payload) => ({
	type: changeStep1Input,
	payload
});

export const changeStep1ErrorAction = (payload) => ({
	type: changeStep1Error,
	payload
});

export const fetchBankAccountAction = (payload, callback) => ({
	type: fetchBankAccount,
	payload,
	callback
});

export const setBankInfoAction = (payload) => ({
	type: setBankInfo,
	payload
});

export const setClearBankInfoAction = (payload) => ({
	type: setClearBankInfo,
	payload
});

export const setSearchBankAction = (payload) => ({
	type: setSearchBankInfo,
	payload
});

export const fetchSearchBankAccountAction = (payload, callback) => ({
	type: fetchSearchBankAccount,
	payload,
	callback
});

export const verifyBankAccountAction = (payload, callback) => ({
	type: verifyBankAccount,
	payload,
	callback
});

export const saveBankAllInfoAction = (payload) => ({
	type: saveBankAllInfo,
	payload
});

export const uploadBankDetailsAction = (payload,callback) => ({
	type: uploadBankDetails,
	payload,
	callback
});

export const clearBankDetailsAction = (payload) => ({
	type: clearBankDetails,
	payload
});
