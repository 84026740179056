// import { all, call, put, takeEvery } from 'redux-saga/effects';
// import { saveBankAllInfo, verifyBankAccount, fetchBankAccount, setBankInfoAction, fetchSearchBankAccount, setSearchBankAction, setIsVerifyBankDetailsAction, getBanksNameList, setBanksNameListAction, getBranchNameList, setBranchNameListAction, saveIfscCodeAction, getIfscCode } from '../../redux/actions/kyc/step5.action';
// import { postApi, postApiWithoutLoader, verifyBankAccountApi, verifyIsfcApi } from '../../services/common';
// import { isValidBankDetails } from '../../utils/commonFunctions';

// function* fetchBankAccountSaga({ payload , callback }) {
// 	try {
// 		const response = yield call(fetch, `https://ifsc.razorpay.com/${payload?.BankVal}`);
// 		const responseBody = yield response.json();
// 		if(response.status === 404){
// 			yield put(setBankInfoAction({status: response.statusText} || {}));
// 		}
// 		if(isValidBankDetails(responseBody)) {
// 			yield put(setBankInfoAction(responseBody || {}));
// 		} else {
// 			const res = yield call(postApi, payload, '/GetBankMasterInfo');
// 			responseBody.BANK = res.data.data[0]?.name.split(" / ")[0]
// 			responseBody.MICR = res.data.data[1]?.name.split(" / ")[2]
// 			console.log(res.data.data[1]?.name.split(" / ")[2])
// 			yield put(setBankInfoAction(responseBody || {}));
// 		}
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// //----- new code here------
// function* getBanksNameListSaga({ payload }) {
// 	try {
// 		const response = yield call(fetch, 'https://payment.bigul.app/api/banks');
// 		const responseBody = yield response?.json();
// 		yield put(setBanksNameListAction(responseBody?.bank_names || []));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };



// function* searchIfscCodeSaga({ payload }) {
// 	try {
// 		const response = yield call(fetch, `https://payment.bigul.app/api/banks/${payload?.bank_code}/ifsc`);
// 		const responseBody = yield response?.json();
// 		yield put(saveIfscCodeAction(responseBody || []));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };
// //----- new code end here------

// function* searchBankISFCSaga({ payload }) {
// 	try {
// 		const response = yield call(fetch, `https://payment.bigul.app/api/banks/${payload?.bank_code}/city`);
// 		const responseBody = yield response.json();
// 		yield put(setSearchBankAction(responseBody?.branches));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* verifyBankAccountSaga({ payload,callback}) {
// 	try {
// 		const response = yield call(verifyBankAccountApi, payload);
// 		yield call(callback, response?.data);


// 		if (response?.data?.verified) {
// 			//TODO:Add Bank 
// console.log("===>  function*verifyBankAccountSaga  response?.data?.verified:", response?.data?.verified)
// 			yield put(setIsVerifyBankDetailsAction(response?.data || {}));
// 		} else {
// 			//TODO: Next page, with bank proof option shown
// 			yield put(setIsVerifyBankDetailsAction({
// 				message: response?.data?.error_msg,
// 				verified:response?.data?.verified
// 			} || {}));
// 		}

// 	} catch (err) {
// 		console.log(err);
// 	};
// };
// function* saveBankAllInfoSaga({ payload , callback}) {
// 	try {
// 		const data = yield call(postApiWithoutLoader, payload, '/AddBankInfo');
// 		console.log("data",data);
// 		yield call(callback, data?.data);
// 		if(data?.data == undefined || data?.data.success == false)
// 		{
// 			yield put(setIsVerifyBankDetailsAction( {verified:'pending'}));
// 		}
// 	} catch (err) {
// 		console.log(err);
// 	}; 
// };

// function* kycStep5Saga() {
// 	yield all([
// 		takeEvery(fetchBankAccount, fetchBankAccountSaga),
// 		takeEvery(fetchSearchBankAccount, searchBankISFCSaga),
// 		takeEvery(verifyBankAccount, verifyBankAccountSaga),
// 		takeEvery(saveBankAllInfo, saveBankAllInfoSaga),
// 		takeEvery(getBanksNameList, getBanksNameListSaga),
// 		takeEvery(getIfscCode, searchIfscCodeSaga),
// 	]);
// }
// // takeEvery(getBranchNameList, getBranchNameListSaga),

// export default kycStep5Saga;
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { saveBankAllInfo, verifyBankAccount, fetchBankAccount, setBankInfoAction, fetchSearchBankAccount, setSearchBankAction, setIsVerifyBankDetailsAction, getBanksNameList, setBanksNameListAction, getBranchNameList, setBranchNameListAction, saveIfscCodeAction, getIfscCode } from '../../redux/actions/kyc/step5.action';
import { postApi, postApiWithoutLoader, postFormApiCo, verifyBankAccountApi, verifyIsfcApi } from '../../services/common';
import { isValidBankDetails } from '../../utils/commonFunctions';
import { toast } from 'react-toastify';

function* fetchBankAccountSaga({ payload, callback }) {
	try {
		const response = yield call(postApi, payload, '/GetBankMasterInfoNew');

		if (response.status === 404) {
			yield put(setBankInfoAction({ status: response.statusText } || {}));
		}
		if (isValidBankDetails(response)) {
			yield put(setBankInfoAction(response || {}));
		} else {
			yield put(setBankInfoAction(response || {}));
		}
	} catch (err) {
		console.log(err);
	};
};


function* getBanksNameListSaga({ payload }) {
	try {
		const response = yield call(fetch, 'https://payment.bigul.app/api/banks');
		const responseBody = yield response?.json();
		yield put(setBanksNameListAction(responseBody?.bank_names || []));
	} catch (err) {
		console.log(err);
	};
};

function* searchIfscCodeSaga({ payload }) {
	try {
		const response = yield call(fetch, `https://payment.bigul.app/api/banks/${payload?.bank_code}/ifsc`);
		const responseBody = yield response?.json();
		yield put(saveIfscCodeAction(responseBody || []));
	} catch (err) {
		console.log(err);
	};
};

function* searchBankISFCSaga({ payload }) {
	try {
		const response = yield call(fetch, `https://payment.bigul.app/api/banks/${payload?.bank_code}/city`);
		const responseBody = yield response.json();
		yield put(setSearchBankAction(responseBody?.branches));
	} catch (err) {
		toast('something went wrong')
	};
};

function* verifyBankAccountSaga({ payload, callback }) {
	try {
		const response = yield call(postFormApiCo, payload,'verify-bank-account');
		console.log(response,'response');
		if (response) {
			yield put(setIsVerifyBankDetailsAction(response?.data || {}));
			yield call(callback, response?.data);
		} else{
			yield call(callback, response);
		}

	} catch (err) {
		
	};
};
function* saveBankAllInfoSaga({ payload, callback }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/AddBankInfo');
		console.log("data", data);
		yield call(callback, data?.data);
		if (data?.data == undefined || data?.data.success == false) {
			yield put(setIsVerifyBankDetailsAction({ verified: 'pending' }));
		}
	} catch (err) {
		// toast('something went wrong')
	};
};

function* kycStep5Saga() {
	yield all([
		takeEvery(fetchBankAccount, fetchBankAccountSaga),
		takeEvery(fetchSearchBankAccount, searchBankISFCSaga),
		takeEvery(verifyBankAccount, verifyBankAccountSaga),
		takeEvery(saveBankAllInfo, saveBankAllInfoSaga),
		takeEvery(getBanksNameList, getBanksNameListSaga),
		takeEvery(getIfscCode, searchIfscCodeSaga),
	]);
}
// takeEvery(getBranchNameList, getBranchNameListSaga),

export default kycStep5Saga;
