import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep8Error, clearSignature, uploadSignature , sendSignatureLink} from '../../actions/kyc/step8.action';

const mutableState = {
	isSendLink: false,
	signatureImage: null,
	isError: false,
	errorText: ''
};
const initState = {
	...mutableState
};

export const kycStep8Reducer = (state = initState, action) => {
	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
	const { isError, errorText } = action?.payload || {};
	switch (action.type) {
	case clearKyc:
		return {
			...mutableState
		};
	case changeStep8Error:
		return {
			...state,
			isError,
			errorText
		};
	case uploadSignature:
		return {
			...state,
			isSendLink: false,
			signatureImage: imageUrl || null,
			isError: false,
			errorText: ''
		};
	case sendSignatureLink:
		return {
			...state,
			isSendLink: true,
		};
	case clearSignature:
		return {
			...state,
			signatureImage: null,
			isError: false,
			errorText: ''
		};
	default:
		return state;
	}
};
