import { useSelector } from 'react-redux';
import { AdminDashboard } from '../containers/dashboard/Admin';
import { RMDashboard } from '../containers/dashboard/RM';
import { VerifierDashboard } from '../containers/dashboard/Verifier';
import { TeleDashboard } from '../containers/dashboard/Telecaller';
import { getRole } from '../utils/getRole';
import { Modal } from 'antd';


const correspondingDashboard = {
	rm: <RMDashboard />,
	verifier: <VerifierDashboard />,
	admin: <RMDashboard />,
	TC: <TeleDashboard />

};

export const Dashboard = () => {
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const loginType = getRole(userDetail?.role_name);
	return correspondingDashboard[loginType];
};
