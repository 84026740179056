import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { 
  changeApplicationDetailAction,
  deleteClientAction,
} from "../../redux/actions/application.action";
import { clearKycAction } from '../../redux/actions/kyc/kyc.action';



export const PopoverParentComp1 = ({ isPopoverOpen, setIsPopoverOpen }) => (
  <button
    className="w-full flex items-center justify-center"
    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
  >
    <span
      className={`w-[6px] h-[6px] rounded-full mx-0.5 ${isPopoverOpen ? "bg-[#5367FC]" : "bg-black"
        }`}
    ></span>
    <span
      className={`w-[6px] h-[6px] rounded-full mx-0.5 ${isPopoverOpen ? "bg-[#5367FC]" : "bg-black"
        }`}
    ></span>
    <span
      className={`w-[6px] h-[6px] rounded-full mx-0.5 ${isPopoverOpen ? "bg-[#5367FC]" : "bg-black"
        }`}
    ></span>
  </button>
);

export const PopoverChildComp1 = ({
  handleModal,
  isVerify,
  redirectUrl,
  applicationType,
  selectedData,
  isSendESign,
  isView,
  viewUrl,
  handleReassignModal,
  handleEdit,
  isDelete,
  handleClickDel,
  status,
  viewUrl1
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(
    (state) => state?.user?.userDetails?.[0] || {}
  );
  
  const handleVerify = () => {
    dispatch(changeApplicationDetailAction({ applicationType, selectedData }));
  };

  const handleClick = () => {
    navigate(redirectUrl, { state: { selectedData: selectedData } });
  };

  const handleClickView = () => {

    navigate('/application/view-application', { state: { selectedData: selectedData } })

  };
  const navigation = () => {
      navigate('/rejectapp', { state: { selectedData: selectedData }})
     }
     
  const navigation1 = () => {
    dispatch(clearKycAction())
  
    selectedData?.IsETrade == 90?
    
    navigate('/kyc', { state: { step: 1, selectedData: selectedData } })
    
    :
    navigate('/kyc1', { state: { step: 1, selectedData: selectedData } })
    
  
  }
  const handleClickView1 = () => {
    viewUrl == 'Reject'?
      navigation()
      :navigation1()
  };
  const handleClickView2 = () => {
   
   if(selectedData.status == 'Rejected' || selectedData.status == 'Reject' || selectedData.status == 'reject'){
    navigate('/rejectapp', { state: { selectedData: selectedData }})
}
   if(selectedData.status == 'progress'){
    dispatch(clearKycAction())

   
    selectedData?.IsETrade == 90?
    
    navigate('/kyc', { state: { step: 1, selectedData: selectedData } })
    
    :
    navigate('/kyc1', { state: { step: 1, selectedData: selectedData } })
 }
  };

  return (
    <div
      className={`my-component-popover-content ${isVerify ? "w-28" : isSendESign ? "w-30" : "w-20"
        }  px-4 py-2 rounded-[10px] flex flex-col border-solid border-[#ECECEC] bg-white`}
    >
      {userDetail?.role_name == "VRF" && isVerify  && (
        <div className="mb-1" onClick={handleClick}>
          <span className="cursor-pointer hover:text-[#5367FC]">Verify</span>
        </div>
      )}
      {isSendESign && (
        <Link to={redirectUrl} className="mb-1" onClick={handleVerify}>
          <span className="cursor-pointer hover:text-[#5367FC]">
            Send for E-Sign
          </span>
        </Link>
      )}

      {isView && viewUrl && (
        <>
          <div className="mb-1" onClick={handleClickView}>
            <span className="cursor-pointer hover:text-[#5367FC]">View</span>
          </div>
        
          {/* <div
            className="mb-1"
            onClick={() => handleReassignModal(selectedData)}
          >
            <span className="cursor-pointer hover:text-[#5367FC]">
              Re-assign Task
            </span>
          </div> */}
        </>
      )}
      {
        !handleEdit && userDetail?.role_name !== "VRF"&&
        <div
        className="mb-1"
        onClick={selectedData?.status?handleClickView2:handleClickView1}
      >
        <span className="cursor-pointer hover:text-[#5367FC]">Edit</span>
      </div>
      }
      {/* <>
        {isDelete && userDetail?.role_name == "admin" && (
          <span
            className="cursor-pointer hover:text-[#5367FC]"
            onClick={handleClickDel}
          >
            Delete
          </span>
        )}
      </> */}

      {handleModal && (
        <span
          className="cursor-pointer hover:text-[#5367FC]"
          onClick={() => handleModal(true)}
        >
          Re-assign
        </span>
      )}
    </div>
  );
};
