import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const SegmentUrl = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
  
    useEffect(() => {
        
        const url = 'http://14.141.71.10:9494/income_admin/login'; 
      

        const openNewWindow = () => {
            window.open(url, '_blank');
        };

        openNewWindow();
    }, [userDetail?. role_id]); 

    return (
        <div>
            <p>{"Add Segment / Income verifier url"}</p>
        </div>
    );
};