import { toggleNavBar } from '../actions/toggleNavBar.action';

const initState = {
	isToggle: false
};

export const toggleReducer = (state = initState, action) => {
	switch (action.type) {
	case toggleNavBar:
		return {
			...state,
			isToggle: !state.isToggle
		};

	default:
		return state;
	}
};
