import { all, call, put, takeEvery } from 'redux-saga/effects';
import { postApi, postApiWithoutLoader } from '../../services/common';
import { getVerifyApplication, setVerifyApplication2Action, setVerifyApplicationAction, getVerifyClientFiles, setVerifyClientFilesAction, callVerifyRejection } from '../../redux/actions/kyc/verify.action';
import { toast } from 'react-toastify';

function* getVerifyApplicationSaga({ payload }) {
    try {
        const data = yield call(postApi, {
            ClientId: payload?.ClientId
        }, '/GetCltFullInfo');
        if (data?.data?.success) {
            console.log('')
            yield put(setVerifyApplicationAction({ data: data?.data?.data || {} }));
        }
        const data2 = yield call(postApiWithoutLoader, {
            mobile: payload?.mobile
        }, '/get-application-for-review');
        console.log('data2', data2)
        if (data2?.data?.success) {
            yield put(setVerifyApplication2Action({ data2: data2?.data?.data || {} }));
        }
    } catch (err) {
        console.log(err);
    };
};


function* getVerifyClientFilesSaga({ payload }) {
    try {
        const data = yield call(postApi, payload, '/GetClientAllFiles');
        yield put(setVerifyClientFilesAction({ data: data?.data?.data || [] }));
        console.log(data);
    } catch (err) {
        console.log(err);
    };
};

function* callVerifyRejectionSaga({ payload, callback }) {
    try {
        const data = yield call(postApi, payload, '/SaveVerifyInfo');
        if (data.data.success) {
            // toast('"Record Saved successfully')
            yield call(callback, 'success');
        }
        else {
            // toast('"something went')
            yield call(callback, data.data);

        }

    } catch (err) {
        console.log(err);
    };
};


function* verifyApplicationSaga() {
    yield all([
        takeEvery(getVerifyApplication, getVerifyApplicationSaga),
        takeEvery(getVerifyClientFiles, getVerifyClientFilesSaga),
        takeEvery(callVerifyRejection, callVerifyRejectionSaga),
    ]);
}

export default verifyApplicationSaga;
