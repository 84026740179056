import _ from "lodash";

export const getFormattedAddressLine = (str, lineNumber = 1) => {
  const chunked = _.chunk(str.split(","), 3).map(c => c.join(", "));
  if (!_.isEmpty(chunked[lineNumber - 1]))
    return chunked[lineNumber - 1];
  return chunked[0]
}

export const isValidBankDetails = (data) => {
  if (_.isEmpty(data.BANK) || _.isEmpty(data.MICR) || data.MICR == null) {
    return false;
  }
  return true
}

export const getFormattedAadharNumber = (number) => {
  if (!number) return false;

  let string = number;

  // Remove leading and trailing whitespace characters and newline characters
  if (typeof string === 'string') {
    string = string.trim().replace(/^\s+|\s+$/gm, '');
  } else {
    // Handle the case when the variable is not a string
    string = String(string).trim().replace(/^\s+|\s+$/gm, '');
  }

  // Make Aadhaar number 12 digits
  if (string.length < 12) {
    string = string + '0'.repeat(12 - string.length);
  } else if (string.length > 12) {
    string = string.slice(0, 12);
  }

  return string;
};








