import { clearKyc } from '../../actions/kyc/kyc.action';
import { clearVerifierData } from '../../actions/kyc/verify.action';

import { setVerifyClientFiles, setVerifyApplication, setVerifyApplication2 } from '../../actions/kyc/verify.action';

const mutableState = {
    verifyData: {},
    verifyData2: {},
    filesData: []
};

const initState = {
    ...mutableState
};

export const verifyApplicationReducer = (state = initState, action) => {
    switch (action.type) {
        case clearKyc:
            return {
                ...mutableState
            };
        case clearVerifierData:
            return {
                ...mutableState
            };
        case setVerifyApplication:
            return {
                ...state,
                verifyData: action?.payload?.data
            };
        case setVerifyApplication2:
            return {
                ...state,
                verifyData2: action?.payload?.data2
            };
        case setVerifyClientFiles:
            return {
                ...state,
                filesData: action?.payload?.data
            };
        default:
            return state;
    }
};
