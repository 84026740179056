


import { useDispatch, useSelector } from 'react-redux';
import { svgAssets } from '../../../assets/asset';
import { CommonInput } from '../../../components/inputs/CommonInput';
import { MainTitle } from '../../../components/common/MainTitle';
import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { changeStep3ErrorAction, changeStep3InputAction, setAddressDetailAction, setPersonalDetailAction } from '../../../redux/actions/kyc/step3.action';
import { BackBtn } from '../../../components/buttons/BackBtn';
import { validateCommonInput } from '../../../utils/verifyInput';
import { STATE_CODES } from '../../../utils/CVLKRA';
import { getFormattedAadharNumber } from '../../../utils/commonFunctions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { postApi, postApiWithoutLoader } from '../../../services/common';
import { addSegmentInfoAction } from '../../../redux/actions/kyc/step2.action';


export const PersonalDetail = ({ step, index, backBtn, continueBtn }) => {
	const dispatch = useDispatch();
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const kycStep2 = useSelector(state => state?.kycStep2 || {});
	const kycStep3 = useSelector(state => state?.kycStep3 || {});
	const panData = useSelector(state => state?.kycStep2.data || {});
	const clientKycData = useSelector(state => state?.kyc?.clientKycStatus?.data || {});
	const [digioData, setDigio] = useState();
	const digioAadhaarDetails = digioData?.aadhaar || {};
	const [PRAdd1, setPRAdd1] = useState('');
	const [PRAdd2, setPRAdd2] = useState('');
	const [PRAdd3, setPRAdd3] = useState('');
	const [details, setDetail] = useState({})
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	console.log(kycStep2?.validatePanDetails?.data?.firstName, 'abc');

	const handleInput = ({ type, value }) => {
		dispatch(changeStep3InputAction({ type, value: value.replace(/[^a-z]/gi, ' ') }));
	};
	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeStep3ErrorAction({ type, isError, errorText }));
	};



	useEffect(() => {
		fetchData()
		// setTimeout(() => {
		// 	// if (kycStep2.isKRA == false) {
		// 		
		// 	// }
		// }, 10000);
	}, []);

	const fetchData = async () => {
		try {
			const payload1 = {
				mobile: kycStep1.mobileInput.value,
				journey: "DKYC",

			}
			const response1 = await postApi(payload1, '/getKycJourney');
			if (response1?.data?.success) {
				const data = response1.data.data[0]
				if (data.client_id == null || data?.kra_status == 0) {
					const payload = {
						PanNo: data?.pan,
						fullName: data?.full_name,
						mobile: kycStep1.mobileInput.value,
						BirthDate: data?.dob,
						kra_status: data?.json_data == null ? 1 : 2,
						journey: "DKYC",
					}
					const response = await postApi(payload, '/GetPanName');
					dispatch(addSegmentInfoAction({
						PanNo: data?.pan,
						AccountType: ["TRADING", "CDSL"],
						Equity: ["NSE", "BSE"],
						Derivative: ["NSEF", "NSEC", "BSEF", "BSEC", "MCX"],
						Commodity: ["NCDX"],
						UserId: userDetail.user_id,
						BranchCode: data?.BranchCode,
						LDCode: data?.pan,
						TerminalCode: data?.pan,
						channel: "Bigul",
						mobile: kycStep1.mobileInput.value,
						journey: "DKYC",
					}))

				}
				setDetail(data)
				const jsonObject = JSON.parse(data?.json_data);

				if (jsonObject != null) {
					setDigio(jsonObject.actions[0].details)
					handleAddressChange(jsonObject.actions[0].details.aadhaar?.current_address_details?.address)
				}
				const payload = {
					"PanNo": data?.pan,
					"Email": data?.email,
					"MobileNumber": data?.mobile,
					journey: "DKYC",

				}
				const response = await postApiWithoutLoader(payload, '/GetFamilyInfo');
				let latestDetails = [];
				let relationsArray = [];
				let relationId = 1;

				response?.data?.data?.forEach((value) => {
					let familyObject = {
						'Name': value.name,
						'ClientCode': value.oowncode,
						'RelationId': value.Relation_Id
					};
					relationsArray.push(familyObject);
				});
				latestDetails = {
					'Name': data?.full_name,
					'ClientCode': data?.pan,
					'RelationId': relationId,
				};
				relationsArray.push(latestDetails);
				console.log(latestDetails, 'latestDetails');
				const RelationData = {
					"PanNo": data?.pan,
					"Email": data?.email,
					"MobileNumber": data?.mobile,
					"FamilyRelation": [latestDetails],
					journey: "DKYC",

				}
				const response2 = await postApiWithoutLoader(RelationData, '/SaveFamilyRelation');
				if (response2?.data.success) {

				}
				else {
					toast('something went wrong')
					setTimeout(() => {
						backBtn({ step, index });
					}, 500);
				}
			}
			else {
				toast('something went wrong')
				setTimeout(() => {
					backBtn({ step, index });
				}, 500);
			}
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const handleContinueBtn = () => {
		setTimeout(() => {
			const isMaritalStatusError = validateCommonInput(kycStep3.maritalStatus.value ||
				'');
			const isFatherSpouseNameError = validateCommonInput(kycStep3.fatherSpoName.value);
			const isGenderError = validateCommonInput(kycStep3.gender.value);
			const isBirthPlaceError = validateCommonInput(kycStep3.birthPlace.value);
			if (kycStep3.maritalStatus.value && kycStep3.fatherSpoName.value && kycStep3.gender.value && kycStep3.birthPlace.value) {
				setTimeout(() => {
					const adharNo = digioData?.aadhaar?.id_number
					const lastFourDigits = adharNo?.slice(-4);
					dispatch(setPersonalDetailAction({
						PanNo: kycStep2?.panInput?.value || clientKycData.pan || "",
						Title: kycStep2?.data?.APP_GEN == 'M' ? 'Mr' : kycStep2?.data?.APP_GEN == 'F' ? 'Mrs' : digioData?.aadhaar?.gender == 'M' ? 'Mr' : digioData?.aadhaar?.gender == 'F' ? 'Mrs' : 'Mr',
						FName: (details?.fname).toUpperCase() || '',
						fullname: details?.full_name,
						LName: (details?.lname).toUpperCase() || '',
						MName: (details?.mname).toUpperCase() || '',
						Gender: digioData?.aadhaar?.gender == 'F' ? 'F' : kycStep3.gender.value,
						MaritalStatus: kycStep2?.data?.APP_MAR_STATUS == "02" ? 'Single' : kycStep2?.data?.APP_MAR_STATUS == "01" ? 'Married' : kycStep3?.maritalStatus?.value,
						BirthDate: details?.dob || '',
						FamilyType: kycStep3.maritalStatus.value !== 'Married' ? 'Father' : 'Spouse',
						FamilyFName: kycStep2?.data?.APP_F_NAME || kycStep3.fatherSpoName.value,
						MotherName: '',
						FamilyTitle: kycStep2?.data?.APP_GEN == 'M' ? 'Mr' : kycStep2?.data?.APP_GEN == 'F' ? 'Mrs' : digioData?.aadhaar?.gender == 'M' ? 'Mr' : digioData?.aadhaar?.gender == 'F' ? 'Mrs' : 'Mr',
						BirthPlace: kycStep3.birthPlace.value,
						AadhaarNo: kycStep2?.data?.APP_PER_ADD1 ? '' : '00000000' + lastFourDigits,
						journey: "DKYC",
						mobile: clientKycData?.mobile,
					}, handleAddressCallback));
				}, 100);


			} if (!isMaritalStatusError) {
				handleInputError({ type: 'maritalStatus', isError: true, errorText: 'invalid martial status!' });
			} if (!isFatherSpouseNameError) {
				handleInputError({ type: 'fatherSpoName', isError: true, errorText: 'invalid input!' });
			} if (!isGenderError) {
				handleInputError({ type: 'gender', isError: true, errorText: 'invalid input!' });
			} if (!isBirthPlaceError) {
				handleInputError({ type: 'birthPlace', isError: true, errorText: 'invalid input!' });
			}

		}, 100);

		// else {
		// 	toast(res?.message ? res?.message : 'something went wrong')
		// }
	};


	const handleAddressChange = (add) => {

		var address = add;
		console.log(address, 'address');
		const pattern = /[^a-zA-Z0-9\/, ]/gu;
		const address1 = address?.replace(pattern, '');
		let remainingAddress = address1;
		const addressParts = [];
		while (remainingAddress?.length > 45) {
			let breakPoint = false;
			for (let i = 44; i >= 0; i--) {
				if (remainingAddress[i] === ',' || remainingAddress[i] === ' ') {
					breakPoint = i;
					break;
				}
			}
			if (breakPoint === false) {
				breakPoint = 45;
			}

			addressParts?.push(remainingAddress?.substring(0, breakPoint));
			remainingAddress = remainingAddress?.substring(breakPoint + 1);
		}

		addressParts.push(remainingAddress);
		console.log(addressParts, 'address');
		setPRAdd1(addressParts[0] || '');
		setPRAdd2(addressParts[1] || '');
		setPRAdd3(addressParts[2] || '');
	};

	const addressProofList = [
		{ code: "01", description: "Passport" },
		{ code: "02", description: "Driving License" },
		{ code: "03", description: "Latest Bank Passbook" },
		{ code: "04", description: "Latest Bank Account Statement" },
		{ code: "06", description: "Voter Identity Card" },
		{ code: "07", description: "Ration Card" },
		{ code: "08", description: "Registered Lease / Sale Agreement of Residence" },
		{ code: "09", description: "Latest Land Line Telephone Bill" },
		{ code: "10", description: "Latest Electricity Bill" },
		{ code: "11", description: "Gas Bill" },
		{ code: "13", description: "Flat Maintenance Bill" },
		{ code: "14", description: "Insurance copy" },
		{ code: "15", description: "Self Declaration by High Court / Supreme Court Judge" },
		{ code: "16", description: "Power of Attorney given by FII/sub-account to the Custodians (which are duly notarized and/or apostiled or consularized) giving registered address." },
		{ code: "17", description: "Proof of Address issued by Scheduled Commercial Banks / Scheduled Co-operative Banks / Multinational Foreign banks." },
		{ code: "18", description: "Proof of Address issued by Elected representatives to the Legislative Assembly" },
		{ code: "19", description: "Proof of Address issued by Parliament" },
		{ code: "20", description: "Proof of Address issued by any Government / Statutory Authority" },
		{ code: "21", description: "Proof of Address issued by Notary Public" },
		{ code: "22", description: "Proof of Address issued by Gazetted Officer" },
		{ code: "23", description: "ID Card with address issued by Central / State Government" },
		{ code: "24", description: "ID Card with address issued by Statutory / Regulatory Authorities" },
		{ code: "25", description: "ID Card with address issued by Public Sector Undertakings" },
		{ code: "26", description: "ID Card with address issued by Scheduled Commercial Banks" },
		{ code: "27", description: "ID Card with address issued by Public Financial Institutions" },
		{ code: "28", description: "ID Card with address issued by Colleges affiliated to universities" },
		{ code: "29", description: "ID Card issued by Professional Bodies such as ICAI, ICWAI, ICSI, Bar Council, etc. to their Members" },
		{ code: "31", description: "AADHAAR" },
		{ code: "33", description: "Nrega Job Card" },
		{ code: "34", description: "Pension Or Family Pension Payment Orders (PPOs) Issued To Retired Employees By Government Departments Or Public Sector Undertakings, If They Contain The Address." }
	];
	const findAddressProof = (code) => {
		const addressProof = addressProofList?.find((proof) => String(proof?.code) == String(code));
		if (addressProof) {
			return addressProof?.description;
		}
		return 'NA';
	};

	const handleAddressCallback = (resp) => {

		if (resp !== undefined && resp.success && resp?.message == 'Record Saved successfully.') {
			dispatch(setAddressDetailAction({
				"PanNo": kycStep2?.panInput?.value || clientKycData.pan || "",
				"MobileNumber": kycStep1?.mobileInput?.value || clientKycData.mobile || "",
				"Email": kycStep1?.emailInput?.value || clientKycData.email || "",
				"VFMobileStatus": 1,
				"VFEmailStatus": 1,
				"CRAddr1": kycStep2?.data?.APP_COR_ADD1 || PRAdd1 || '',
				"CRAddr2": kycStep2?.data?.APP_COR_ADD2 || PRAdd2 || '',
				"CRAddr3": kycStep2?.data?.APP_COR_ADD3 || PRAdd3 || '',
				"CRPinCode": kycStep2?.data?.APP_COR_PINCD || digioAadhaarDetails?.current_address_details?.pincode,
				"PRAddr1": kycStep2?.data?.APP_PER_ADD1 || PRAdd1,
				"PRAddr2": kycStep2?.data?.APP_PER_ADD2 || PRAdd2 || '',
				"PRAddr3": kycStep2?.data?.APP_PER_ADD3 || PRAdd3 || '',
				"PRPinCode": kycStep2?.data?.APP_PER_PINCD || digioAadhaarDetails?.permanent_address_details?.pincode,
				"CRCity": kycStep2?.data?.APP_COR_CITY || digioAadhaarDetails?.current_address_details?.district_or_city,
				"CRState": STATE_CODES[kycStep2?.data?.APP_COR_STATE] || digioAadhaarDetails?.current_address_details?.state,
				"PRCity": kycStep2?.data?.APP_PER_CITY || digioAadhaarDetails?.permanent_address_details?.district_or_city,
				"PRState": STATE_CODES[kycStep2?.data?.APP_PER_STATE] || digioAadhaarDetails?.permanent_address_details?.state,
				"CRAdSameAsPR": "Yes",
				"family": 1,
				app_status: kycStep2?.update_status?.app_statue,
				app_update_status: kycStep2?.update_status?.update_status,
				journey: "DKYC",
				uniqueKey: kycStep2?.data?.KRA_ID,
				PeraddressProof: findAddressProof(kycStep2?.data?.APP_PER_ADD_PROOF),
				CorAddressProof: findAddressProof(kycStep2?.data?.APP_COR_ADD_PROOF),
				PerAddressType: kycStep2?.data?.APP_PER_ADD_REF,
				CorAddressType: kycStep2?.data?.APP_COR_ADD_REF

				// "CR_full_address": ((kycStep2?.data?.APP_COR_ADD1) + (kycStep2?.data?.APP_COR_ADD2) + (typeof kycStep2?.data?.APP_COR_ADD3 === 'string' ? kycStep2?.data?.APP_COR_ADD3 : kycStep2?.data?.APP_COR_ADD3[0]) || digioData?.aadhaar?.current_address_details?.address) ?? '',
				// "PR_full_address": ((kycStep2?.data?.APP_PER_ADD1) !== undefined ? (kycStep2?.data?.APP_PER_ADD1) : '') + ((kycStep2?.data?.APP_PER_ADD2) !== undefined ? (kycStep2?.data?.APP_PER_ADD2) : '') + ((kycStep2?.data?.APP_PER_ADD3) !== undefined ? (typeof kycStep2?.data?.APP_PER_ADD3 === 'string' ? kycStep2?.data?.APP_PER_ADD3 : kycStep2?.data?.APP_PER_ADD3[0]) : '') || digioData?.aadhaar?.current_address_details?.address,
			}, handleAddressCallback1))
		}
		else {
			toast('something went wrong')
		}


	};
	const handleAddressCallback1 = (resp) => {
		if (resp !== undefined && resp.success && resp?.message == 'Record Saved successfully.') {
			continueBtn({ step, index });
			toast('Personal details verified Succesfully.')
		}
		else {
			toast(resp?.message || 'something went wrong')
		}
	}

	return (
		<div className="w-full flex flex-col">
			< ToastContainer autoClose={100} />
			<div className="min-h-[calc(100vh-300px)]">
				<MainTitle title="Personal Details" />
				<div className="grid md:grid-cols-2 gap-x-10 gap-y-10 mb-8">
					<SelectDropdown
						icon={svgAssets.kyc.weddingRing}
						label="Marital Status"
						isImportant={true}
						placeholder="Please select marital status"
						options={kycStep3.maritalStatus.options}
						inputType="maritalStatus"
						selectedValue={kycStep3.maritalStatus.value === '02' ? 'Single' : kycStep3.maritalStatus.value === '01' ? 'Married' : 'single'}
						handleSelect={handleInput}
						inputMessage={kycStep3.maritalStatus.errorText}
						inputMessageType={kycStep3.maritalStatus.isError && 'error'}
						autoFocus={true}
						value={'single'}
						isDisabled={panData?.APP_MAR_STATUS ? true : false}
					/>
					<CommonInput
						icon={svgAssets.kyc.idCard1}
						label="Father’s/Spouse Name"
						isImportant={true}
						placeholder="Please enter father’s/spouse name"
						inputType="fatherSpoName"
						value={panData.APP_F_NAME ? kycStep3.fatherSpoName.value : kycStep3.fatherSpoName.value}
						handleInput={handleInput}
						inputMessage={kycStep3.fatherSpoName.errorText}
						inputMessageType={kycStep3.fatherSpoName.isError && 'error'}
					// isDisable={panData?.APP_F_NAME ? true : false}
					/>
				</div>
				<div className="grid md:grid-cols-2 gap-10 mb-8">
					<SelectDropdown
						icon={svgAssets.kyc.gender}
						label="Gender"
						isImportant={true}
						placeholder="Please select gender"
						options={kycStep3.gender.options}
						inputType="gender"
						selectedValue={kycStep3.gender.value}
						handleSelect={handleInput}
						inputMessage={kycStep3.gender.errorText}
						inputMessageType={kycStep3.gender.isError && 'error'}
						isDisabled={panData?.APP_GEN ? true : false}
					/>
					<CommonInput
						icon={svgAssets.kyc.city}
						label="City Of Birth"
						isImportant={true}
						placeholder="Please enter city of birth"
						inputType="birthPlace"
						value={kycStep3.birthPlace.value}
						handleInput={handleInput}
						inputMessage={kycStep3.birthPlace.errorText}
						inputMessageType={kycStep3.birthPlace.isError && 'error'}
						isDisable={panData?.APP_COR_CITY ? true : false}
					/>
				</div>
				<div className="w-full flex items-end mb-4">
					<CommonInput
						icon={svgAssets.kyc.flag}
						label="Nationality"
						placeholder="Indian"
						isDisable={true}
					/>
				</div>
				{
					kycStep2?.isKRA ?
						<div className="w-full flex flex-col">
							{(
								<label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">

									<span className="mr-2">{'Address'}</span>
								</label>
							)}
							<div
								className={`w-full h-full rounded-[10px]
								${"bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10"
									}
						  ${"h-[47px]"}
							`}
							>
								<input
									className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white mt-2.5 ml-5"
									type="text"
									// placeholder={'Enter Your Occupation'}
									value={(kycStep2?.data?.APP_PER_ADD1) + ' ' + (kycStep2?.data?.APP_PER_ADD2) + ' ' + (kycStep2?.data?.APP_PER_ADD3.length > 5 ? kycStep2?.data?.APP_PER_ADD3 : '')}
									readOnly={true}
								/>
							</div>
						</div>
						:
						<div className="w-full flex flex-col">
							{(
								<label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">

									<span className="mr-2">{'Address'}</span>
								</label>
							)}
							<div
								className={`w-full h-full rounded-[10px]
								${"bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10"
									}
						  ${"h-[47px]"}
							`}
							>
								<input
									className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white mt-2.5 ml-5"
									type="text"
									placeholder={'Enter Your Address'}
									value={digioData?.aadhaar?.current_address_details?.address}
									readOnly={true}
								/>
							</div>
						</div>
				}
			</div>
			<div className="flex item justify-between mb-6">
				<BackBtn handleBackBtn={() => backBtn({ step, index })} />
				{
					kycStep3.gender.value ?
						<ContinueBtn handleContinueBtn={handleContinueBtn} />
						:
						<ContinueBtn isDisable={true} handleContinueBtn={''} />
				}
			</div>
		</div>
	);
};



