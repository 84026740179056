import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MainTitle } from '../../components/common/MainTitle';
import { SingleDetail } from './SingleDetail';

export const MobileEmailDetail = ({ isVerify, reviewData }) => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);
	return (
		<div className="mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]">
			<div className="mb-5">
				<MainTitle title="Mobile Number & Email Id" />
			</div>
			<div className="grid md:grid-cols-12 gap-10">
				<div className="md:col-span-10">
					{
						isLoading ?
							<Skeleton count={2} height={50} />
							:
							<>
								<SingleDetail label="Email Id" value={reviewData?.email || reviewData?.Email || ''} isVerify={isVerify} />
								<SingleDetail label="Mobile Number" value={reviewData?.mobile || reviewData?.MobileNumber || ''} isVerify={isVerify} />
							</>
					}
				</div>
			</div>
		</div>
	);
};
