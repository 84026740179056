
import { MultipleSelect } from '../../../dropdown/MultipleSelect';

export const EquityTab = ({ tradingOptions, selectedOptionsData1, selectedOptionsData2, handleSelectOptions, equityData }) => (
	<>
		<div className="mb-[36px]">
			<MultipleSelect
				label="Trading"
				isImportant={true}
				placeholder="Select Trading Plan"
				options={tradingOptions}
				value={selectedOptionsData1}
				handleSelect={handleSelectOptions}
			/>
			{console.log("1", selectedOptionsData1)}
		</div>
		<div className="mb-[50px]">
			<MultipleSelect
				label="Delivery"
				isImportant={true}
				placeholder="Select Delivery Plan"
				options={tradingOptions}
				value={selectedOptionsData2}
				handleSelect={handleSelectOptions}
			/>
			{console.log("2", selectedOptionsData2)}
		</div>
	</>
);

export const FutureOptionTab = ({ tradingOptions }) => (
	<>
		<div className="mb-[36px]">
			<MultipleSelect
				label="Future"
				isImportant={true}
				placeholder="Select Future Plan"
				options={tradingOptions}
			/>
		</div>
		<div className="mb-[50px]">
			<MultipleSelect
				label="Option"
				isImportant={true}
				placeholder="Select Option Plan"
				options={tradingOptions}
			/>
		</div>
	</>
);

export const CommodityTab = ({ tradingOptions }) => (
	<>
		<div className="mb-[36px]">
			<MultipleSelect
				label="MCX Future"
				isImportant={true}
				placeholder="Select MCX Future Plan"
				options={tradingOptions}
			/>
		</div>
		<div className="mb-[50px]">
			<MultipleSelect
				label="MCX Option"
				isImportant={true}
				placeholder="Select MCX Option Plan"
				options={tradingOptions}
			/>
		</div>
	</>
);

export const CurrencyTab = ({ tradingOptions }) => (
	<>
		<div className="mb-[36px]">
			<MultipleSelect
				label="Currency Future"
				isImportant={true}
				placeholder="Select Currency Future Plan"
				options={tradingOptions}
			/>
		</div>
		<div className="mb-[50px]">
			<MultipleSelect
				label="Currency Option"
				isImportant={true}
				placeholder="Select Currency Option Plan"
				options={tradingOptions}
			/>
		</div>
	</>
);
