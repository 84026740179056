import { pngAssets } from '../../../assets/asset';
import { BackBtn } from '../../../components/buttons/BackBtn';
import { ReviewBtn } from '../../../components/buttons/ReviewBtn';

export const KycSuccess = ({ step, index, backBtn, reviewBtn }) => {
	return (
		<>
			<div className="flex flex-col items-center justify-center min-h-[calc(100vh-300px)]">
				<h6 className="mb-6 text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
					Congrats<br></br>
					You have Successfully completed all the steps
				</h6>
				<img
					className="mb-5"
					alt="success_kyc"
					src={pngAssets.kyc.kycSuccess}
				/>
			</div>
			<div className="flex item justify-center gap-x-3">
				{/* <BackBtn handleBackBtn={() => backBtn({ step, index })} /> */}
				<ReviewBtn handleReviewBtn={reviewBtn}/>
			</div>
		</>
	);
};
