import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep3Error, uploadSelfie, clearSelfie } from '../../actions/dkyc/step3.action';

const mutableState = {
	selfieImage: null,
	isError: false,
	errorText: '',
	isSelfie: false
};
const initState = {
	...mutableState
};

export const dkycStep3Reducer = (state = initState, action) => {
	const { isError, errorText } = action?.payload || {};
	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
	switch (action.type) {
		case clearKyc:
			return {
				...mutableState
			};
		case changeStep3Error:
			return {
				...state,
				isError,
				errorText
			};
		case uploadSelfie:
			return {
				...state,
				selfieImage: imageUrl || null,
				isError: false,
				errorText: '',
				isSelfie: true
			};
		case clearSelfie:
			return {
				...state,
				selfieImage: null,
				isError: false,
				errorText: '',
				isSelfie: false
			};
		default:
			return state;
	}
};
