export const changeStep2Input = 'CHANGE_STEP2_INPUT';
export const changeStep2Error = 'CHANGE_STEP2_ERROR';
export const getPanCardDetail = 'GET_PAN_CARD_DETAIL';
export const getValidatePanDetails = 'GET_VALIDATE_PAN_DETAILS';
export const addSegmentInfo = 'ADD_SEGMENT_INFO';
export const callSendDigilockerLink = 'SET_DIGILOCKER_LINK';
export const getSendDigilockerDataLink = 'GET_DIGILOCKER_LINK_DATA';
export const isDigioKycCompleteData = 'IS_DIGIOKYC_KYC_COMPLETE';
export const setValidatePanDetails = 'SET_VALIDATE_PAN_DETAILS';
export const setPanCardDetail = 'SET_PAN_CARD_DETAIL';
export const setKRA = 'SET_KRA';
export const isSendDigioLockerLinkAction = 'IS_SEND_DIGIOLOCKER_LINK';
export const setDigioLokerData = 'SET_DIGIOLOCKER_DATA';

export const changeStep2InputAction = (payload) => ({
	type: changeStep2Input,
	payload
});

export const changeStep2ErrorAction = (payload) => ({
	type: changeStep2Error,
	payload
});

export const getPanCardDetailAction = (payload, callback) => ({
	type: getPanCardDetail,
	payload,
	callback
});

export const getValidatePanDetailsAction = (payload, callback) => ({
	type: getValidatePanDetails,
	payload,
	callback
});
export const addSegmentInfoAction = (payload,callback) => ({
	type: addSegmentInfo,
	payload,
	callback
	
});

export const callSendDigilockerAction = (payload, callback) => ({
	type: callSendDigilockerLink,
	payload,
	callback
});

export const getSendDigilockerLinkDataAction = (payload, callback) => ({
	type: getSendDigilockerDataLink,
	payload,
	callback
});

export const callIsDigioKycCompletedDataAction = (payload, callback) => ({
	type: isDigioKycCompleteData,
	payload,
	callback
});


export const setValidatePanDetailsAction = (payload) => ({
	type: setValidatePanDetails,
	payload
});

export const setPanCardDetailAction = (payload) => ({
	type: setPanCardDetail,
	payload
});

export const setKRAAction = (payload) => ({
	type: setKRA,
	payload
});

export const setIsSendDigioLockerLinkAction = (payload) => ({
	type: isSendDigioLockerLinkAction,
	payload
});

export const setDigioLokerDataAction = (payload) => ({
	type: setDigioLokerData,
	payload
});