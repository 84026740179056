import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svgAssets } from '../../../assets/asset';
import { BranchCodeDropdown } from '../../../components/dropdown/branchCodeDropdown';
import { postFormApi } from '../../../services/common';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export const UploadBankProof = () => {
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const reviewFiles = useSelector(state => state?.reviewData?.filesData || {});
	const { panInput } = useSelector(state => state?.kycStep2 || {});
	let user_doc = [];
	const client_file = reviewFiles.length > 0 && reviewFiles?.filter(a => (a.Document === 'Bank Proof'));
	const lastItemIndex = client_file?.length - 1;
	const [selectedFile, setSelectedFile] = useState();
	const kycStep2 = useSelector(state => state?.kycStep2 || {});
	const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
	const [select,setSelect]=useState('')

	useEffect(() => {
		if (reviewFiles) {
			user_doc = reviewFiles && reviewFiles?.filter(a => (a.Document === 'Bank Proof'));
		}
	}, [])

	const handleImage = async (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		if (e.target.files[0].size > 2 * 1024 * 1024) { // Check if file size is greater than 3MB
			toast("File size exceeds the limit of 2MB. Please choose a smaller image.");
			return;
		}
		const payload = {
			PanNo: kycStep2.panInput.value || clientKycStatus?.data?.pan,
			Document: 'Bank Proof',
			DocumentType:select,
			CreatedBy: 261,
			step: 11,
			'DocumentImages[]': e.target.files[0]
		}
		const response = await postFormApi(payload, '/uploadfiles');
		if (response.data.success == true) {
			toast(response.data.message)
		}
		else {
			toast(response.data.message)
		}
	};


	const handleInput=({ type, value })=>{
		setSelect(value)
	}
	return (
		<> 
		<ToastContainer autoClose={100}/>
			<div className="w-full mb-8 grid md:grid-cols-12 gap-10 items-start">
				<div className="w-full col-span-6 ">
					<BranchCodeDropdown
						label="Upload Bank Proof"
						placeholder="Please select BankProof"
						options={["Bank Account Proof","Bank Statement", 'Cancelled Cheque Leaf','1st page of Passbook','Bank Letter'] || []}
						inputType="BankProof"
						handleSelect={handleInput}


					/>
					{client_file && client_file?.map((item, index) => {
						const isLastItem = index === lastItemIndex;
						return (
							<>
								{isLastItem &&
									<div key={index} className='bg-white p-2 w-[150px] h-[130px] flex items-center justify-center mt-3'>
										<a href={item?.ImagePath} target='_blank'>
											<img width='80' src={item?.ImagePath} />
										</a>
									</div>
								}
							</>
						);
					})}
				</div>
				<div className="w-full flex items-center justify-between col-span-6 mt-10">
					<div className='w-full'>
						<div className='w-full flex items-center justify-between'>
							<button style={{ backgroundColor: 'black' }}
								className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  relative 'bg-black cursor-pointer'`}>
								{
									(
										<input
											type="file"
											accept="image/*"
											onChange={(e) => handleImage(e)}
										/>
									)
								}
								<img
									src={svgAssets.kyc.uploadWhite}
									className="mr-1.5" />
								<span>Choose file</span>
							</button>
							{
								client_file &&
								<button
									className={`h-[47px]  px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}
								>
									<img
										src={svgAssets.kyc.verify}
										className="mr-1.5"
									/>
									<span>
										Uploaded
									</span>
								</button>
							}
						</div>

					</div>

				</div>

			</div>
		</>
	);
};
