import { MainTitle } from "../../../components/common/MainTitle";
import { SingleDetail } from "../../review/SingleDetail";


export const PersonalDeatils_modification = ({
  reviewData2,
  reviewData,
  maritalStatus,
  gender,
  birthPlace,
  kycStep3,
}) => {
  const spouseName =
    reviewData?.father_spo_name ||
    reviewData?.FamilyFName ||
    kycStep3?.fatherSpoName?.value ||
    "-";
  return (
    <div className="mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]">
      <div className="mb-5">
        <MainTitle title="Personal Details" />
      </div>
      <div className="grid grid-cols-12 gap-10">
        {/* {JSON.stringify(reviewData)} */}
        <div className="col-span-10">
          <SingleDetail
            label="Marital Status"
            value={
              reviewData?.marital_status ||
              reviewData?.MaritalStatus ||
              maritalStatus ||
              ""
            }
          />
          <SingleDetail label="Father’s/Spouse Name" value={spouseName} />
          <SingleDetail
            label="Gender"
            value={
              reviewData?.Gender == "M"
                ? "Male"
                : reviewData?.Gender == "F"
                ? "Female"
                : "Other"
            }
          />
        
          <SingleDetail
            label="Nationality"
            value={reviewData?.nationality || "Indian"}
          />
        </div>
      </div>
    </div>
  );
};
