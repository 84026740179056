import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { pngAssets, svgAssets } from "../../assets/asset";
import { MainTitle } from "../../components/common/MainTitle";
import { StepProgressBar } from "../../components/progressBar/ProgressBar";
import { verificationSteps } from "../../containers/kyc/kycData";
import { AddressDetail } from "../../containers/review/AddressDetail";
import { BankDetail } from "../../containers/review/BankDetail";
import { BrokerageStep } from "../../containers/review/brokerage/BrokerageStep";
import { ClientDetail } from "../../containers/review/clientDetail/ClientDetail";
import { ClientPreview } from "../../containers/review/clientDetail/ClientPreview";
import { MobileEmailDetail } from "../../containers/review/MobileEmailDetail";
import { OccupationalDetail } from "../../containers/review/OccupationalDetail";
import { PanDetail } from "../../containers/review/PanDetail";
import { PersonalDetail } from "../../containers/review/PersonalDetail";
import { Debounce } from "../../hooks/Debounce";
import {
  getClientFilesAction,
  getReviewApplicationAction,
} from "../../redux/actions/kyc/review.action";
import { StepReviewBar } from "../../components/progressBar/ReviewBar";
import { postApi, postApiWithoutLoader } from "../../services/common";
import { AddressDetail1 } from "../../containers/review/AddressDetails1";
import { toast } from "react-toastify";
import refetch from "../../assets/svg/refetch-icon.svg"
import { setVerifyApplication2Action, setVerifyApplicationAction } from "../../redux/actions/kyc/verify.action";
import { BASE_URL } from "../../config/config";
import { Nominee } from "../../containers/kyc/step6/Nominee";
import axios from "axios";
import { store } from "../../redux/store";
import { startLoaderAction, stopLoaderAction } from "../../redux/actions/app.action";

const dummyData = [
  {
    id: 1,
    DocumentType: "Client photo",
  },
  {
    id: 2,
    DocumentType: "Client Signature",
  },
  {
    id: 2,
    DocumentType: "PAN Card",
  },
];
const clientIntersectionOption = {
  rootMargin: "0px 0px -75% 0px",
  threshold: 0.3,
};

const intersectionOption = {
  rootMargin: "0px 0px 50% 0px",
  threshold: 1,
};

export const ViewApplication = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate =useNavigate()

  const { ref: clientRef, inView: isClientRefInView } = useInView(
    clientIntersectionOption
  );
  const { ref: EsignRef, inView: IsEsignRefView } = useInView(intersectionOption);

  const { ref: mobileRef, inView: isMobileRefView } =
    useInView(intersectionOption);
  const { ref: panRef, inView: isPanRefView } = useInView(intersectionOption);
  const { ref: addressRef, inView: isAddressRefView } =
    useInView(intersectionOption);
  const { ref: bankRef, inView: isBankRefView } = useInView(intersectionOption);
  const { ref: personalRef, inView: isPersonalRefView } =
    useInView(intersectionOption);
  const { ref: occupationalRef, inView: isOccupationalRefView } =
    useInView(intersectionOption);
  const { ref: brokerageRef, inView: isBrokerageRefView } =
    useInView(intersectionOption);

  const selectedData = location.state && location.state.selectedData;
  console.log(selectedData, 'selectedData');
  // const reviewData = useSelector((state) => state?.reviewData.reviewData || {});
  const [reviewData, setReviewData] = useState({})
  const reviewData2 = useSelector(
    (state) => state?.reviewData.reviewData2 || {}
  );
  const reviewFilesData = useSelector(
    (state) => state?.reviewData.filesData || {}
  );
  const [isClientPreviewVisible, setIsClientPreviewVisible] = useState(false);
  const [steps, setSteps] = useState(verificationSteps || []);

  const clientPhoto =
    dummyData && dummyData?.find((el) => el.DocumentType === "Client photo");
  const clientSignature =
    dummyData && dummyData.find((el) => el.DocumentType === "Client Signature");
  const panCardPdf =
    dummyData && dummyData?.find((el) => el.DocumentType === "PAN Card");
  const debounceValue = Debounce(isClientRefInView, 2000);
  const salary1 = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a.DocumentType == 'Latest 3 Month Salary Slip') || (a.DocumentType == 'Last 3 Months Salary Slip'))
  const income_proof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Income Proof'))
  const income_proof1 = reviewFilesData?.length > 0 && reviewFilesData?.filter(a => (a.Document === 'Income Proof'))
  const lastItemIndex = income_proof1?.length - 1;
  const BankProof = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Bank Proof'))
  const pennyDrop = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a.Document === 'Other'))
  const [bankData, setBankData] = useState(null);
  const [clientDetails, setClientDtails] = useState();
  const [uniqueValue, setUniqueValue] = useState();
  const [cityName, setCityname] = useState([]);
  const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    dispatch(setVerifyApplicationAction({}))
    dispatch(setVerifyApplication2Action({}))
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };


  const handleSteps = ({ index }) => {
    const updatedSteps = steps.map((el, i) => {
      if (i === index) {
        return {
          ...el,
          status: "verifyView",
        };
      } else {
        return {
          ...el,
          status: "success",
        };
      }
    });
    setSteps(updatedSteps);
  };

  useEffect(() => {
    setIsClientPreviewVisible(debounceValue);
  }, []);

  useEffect(() => {
    fetchData1()
    dispatch(
      getReviewApplicationAction({
        mobile: selectedData.mobile,
        ClientId: selectedData.ClientId,
      }, callback)
    );
    dispatch(
      getClientFilesAction({
        PanNo: selectedData.PanNo || "",
        CreatedBy: 1,
      })
    );

  }, [selectedData?.mobile]);


  const callback = (resp) => {
    setReviewData(resp)
  }
  const fetchData1 = async () => {
    try {
      const payload = {
        mobile: selectedData?.mobile,
      }
      const response = await postApiWithoutLoader(payload, '/getKycJourney');
      if (response?.data?.success) {
        const pennydropdata = JSON.parse(response?.data.data[0].pennydrop_data)
        setBankData(pennydropdata)
        setClientDtails(response?.data?.data[0])
      }
      else {
        console.error('Error fetching data:');

      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (isClientRefInView) {
      handleSteps({ index: 0 });
    } else if (isMobileRefView) {
      handleSteps({ index: 1 });
    } else if (isPanRefView) {
      handleSteps({ index: 2 });
    }
    else if (isAddressRefView) {
      handleSteps({ index: 3 });
    } else if (isBankRefView) {
      handleSteps({ index: 4 });
    } else if (isPersonalRefView) {
      handleSteps({ index: 5 });
    }
    else if (isOccupationalRefView) {
      handleSteps({ index: 6 });
    }
    else if (IsEsignRefView) {
      handleSteps({ index: 7 });
    }

  }, [isClientRefInView, isMobileRefView, isPanRefView, isAddressRefView, isBankRefView, isPersonalRefView, isOccupationalRefView, isBrokerageRefView, IsEsignRefView,]);


  const handleSendToLd = async () => {
    
    const payload = {
      ClientId: selectedData.ClientId,

    }
    const response = await postApi(payload, '/SendKycinfoToLD');
    if (response?.data?.success) {
      toast(response?.data?.message)
      navigate('/application/pending')

    }
    else {
      toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
    }
  }

  useEffect(() => {
    if (reviewData?.CRPinCode) {
      Pincode(reviewData?.CRPinCode)

    }
  }, [reviewData?.CRPinCode])

  const Pincode = async (pincode) => {
    // Prevent default form submission

    try {
      const response = await axios.post('https://apinew.bigul.app:4040/api/v1/getCDSLStateCityByPincode', {
        pincode: pincode, // Sending pincode as the request body
      });
      if (response) {
        setCityname(response?.data?.data)
      }


    } catch (error) {

    }
  };

  const handleRefetch = async () => {
    const payload = {
      clientId: selectedData.ClientId,
    }
    const response = await postApi(payload, '/downloadPdfDigio');
    if (response?.data?.success) {
      // toast(response?.data?.message)
      window.open(response?.data?.data, '_blank');
    }
    else {
      toast(response.message ? response.message : response?.data?.message ? response?.data?.message : 'something went wrong')
    }
  }
  // const updateCIty = async () => {
  //   const payload = {
  //     clientId: reviewData.address_id,
  //   }
  //   const response = await postApi(payload, '/downloadPdfDigio');
    
  // }

  const updateClientAddress = async () => {
    store.dispatch(startLoaderAction());
    const url = 'https://apinew.bigul.app:4040/api/v1/updateClientAddress';
    const data = {
      address_id:reviewData.address_id,
      city:selectedCity
    };
  
    try {
      const response = await axios.post(url, data);
      store.dispatch(stopLoaderAction());
      toast.success('Adrees update scucessfully')

    } catch (error) {
      store.dispatch(stopLoaderAction());

      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const generateFourDigitNumber = () => {
    return Math.floor(1000 + Math.random() * 9000);
  };
  // const uniqueValue = generateFourDigitNumber();

  const handleClick = () => {
    const newUniqueValue = generateFourDigitNumber();
    setUniqueValue(newUniqueValue);
  };

  return (
    <>
      {
        reviewData?.ClientId ?
          <div className="w-full flex flex-col">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="w-full">
                <MainTitle title="View Application Details" />
              </div>
              <div className="w-full flex items-center justify-center  md:justify-end text-[#848484] font-medium font-poppinsMedium  mb-4 md:mb-0">
                <span className="mr-2">Client of</span>
                {reviewData?.IsETrade == 90 ? (
                  <img className="h-5" alt="bigul" src={svgAssets.bigul} />
                ) : (
                  <img className="h-8" alt="bigul" src={svgAssets.bonabza} />
                )}
              </div>
            </div>

            <div className="px-4 md:px-7 py-8 mb-[35px] rounded-[20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
              <ClientDetail
                reviewData={reviewData || {}}
                reviewData2={reviewData2 || {}}
                reviewFilesData={reviewFilesData}
                clientPhoto={clientPhoto?.DocumentImages || ""}
                clientSignature={clientSignature?.DocumentImages || ""}

              />
            </div>
            <div className="sticky top-[87px] z-50 pt-5 pb-4 rounded-0 bg-[#E9F1FF]">

              <StepReviewBar selectedStep={8} steps={steps} setSteps={setSteps} showStepLabel={true} />

            </div>
            <div className="px-5 md:px-7 py-8 rounded-[0_0_20px_20px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
              <div ref={mobileRef}>
                <MobileEmailDetail
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                />
              </div>
              <div ref={panRef}>
                <PanDetail
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                  panCardPdf={panCardPdf?.DocumentImages || ""}
                />
              </div>
              <div ref={addressRef}>
                <AddressDetail
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                />
                <AddressDetail1
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                  reviewFilesData={reviewFilesData}
                />
              </div>
              <div ref={bankRef}>
                <BankDetail
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                  pennyData={bankData && bankData || {}}

                />
              </div>
              <div ref={personalRef}>
                <PersonalDetail
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                />
              </div>
              <div ref={occupationalRef}>
                <OccupationalDetail
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                />
              </div>
              <div ref={brokerageRef}>
                <BrokerageStep
                  reviewData={reviewData || {}}
                  reviewData2={reviewData2 || {}}
                />
              </div>

              {
                reviewData?.nominee_arr != null &&
                <Nominee reviewData={reviewData?.nominee_arr} />
              }


              {income_proof && !salary1.length > 0 &&
                <div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
                  <div className="text-lg font-medium font-poppinsMedium">Income Proof - Documents Uploaded</div>
                  {lastItemIndex >= 0 && (
                    <div
                      className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                    >
                      <a href={income_proof1[lastItemIndex]?.ImagePath} target='_blank'>
                        <div className="flex items-center justify-center">
                          <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                          <span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
                        </div>
                      </a>
                    </div>
                  )}
                </div>

              }
              {salary1.length > 0 &&
                <div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
                  <div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip1</div>
                  <div
                    className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                  >
                    <a href={salary1[0]?.ImagePath} target='_blank'>
                      <div className="flex items-center justify-center">
                        <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                        <span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
                      </div>
                    </a>
                  </div>
                </div>

              }
              {salary1?.length > 0 &&
                <div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
                  <div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip 2</div>
                  <div
                    className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                  >
                    <a href={salary1[1]?.ImagePath} target='_blank'>
                      <div className="flex items-center justify-center">
                        <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                        <span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
                      </div>
                    </a>
                  </div>
                </div>
              }
              {salary1?.length > 0 &&
                <div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
                  <div className="text-lg font-medium font-poppinsMedium">Income Proof - salary slip 3</div>
                  <div
                    className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                  >
                    <a href={salary1[2]?.ImagePath} target='_blank'>
                      <div className="flex items-center justify-center">
                        <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                        <span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
                      </div>
                    </a>
                  </div>
                </div>
              }
              {BankProof &&
                <div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
                  <div className="text-lg font-medium font-poppinsMedium">Bank Proof Document</div>
                  <div
                    className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                  >
                    <a href={BankProof.ImagePath} target='_blank'>
                      <div className="flex items-center justify-center">
                        <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                        <span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
                      </div>
                    </a>
                  </div>
                </div>

              }
              {pennyDrop &&
                <div className="pt-5 grid md:grid-cols-3 gap-5 items-center">
                  <div className="text-lg font-medium font-poppinsMedium">Penny Drop Document</div>
                  <div
                    className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                  >
                    <a href={pennyDrop.ImagePath} target='_blank'>
                      <div className="flex items-center justify-center">
                        <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                        <span className="text-[#757575] text-xs font-poppinsRegular">View Document</span>
                      </div>
                    </a>
                  </div>
                </div>

              }
              <div ref={EsignRef}>
                {(clientDetails?.esign_status == 1 || reviewData?.IsESign == 1) &&
                  <div className='pt-5 grid md:grid-cols-3 gap-5 items-center' >
                    <div className="text-lg font-medium font-poppinsMedium">View Esign PDF</div>
                    <div
                      className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                      onClick={handleClick}
                    >
                      <a href={BASE_URL + '/resources/final_esign_pdfs/EQ_' + selectedData.PanNo + '.pdf' + '?en=' + uniqueValue} target='_blank'>
                        <div className="flex items-center justify-center">
                          <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                          <span className="text-[#757575] text-xs font-poppinsRegular">View Esign PDF</span>
                        </div>
                      </a>
                    </div>
                    <div><p className="text-danger">If pdf not found or wrong data is found in pdf </p>
                      <button style={{ background: 'red' }} onClick={() => { handleRefetch() }} className={'d-inline-block text-white px-3 py-2 mt-4 rounded refetch-button'}><img src={refetch} className="mr-2" /> Click here</button>
                    </div>
                  </div>
                }
              </div>
              {
               (reviewData?.IsESign == 1) && ( userDetail?.role_name == "VRF") && 
                <>
                  <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontWeight: 'bold', marginRight: '20px' }}>Send to LD Error</h1>
                    <select style={{
                      width: '70%',
                      height: '50px',
                      padding: '10px',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                      borderRadius: '4px',
                      // border: '.5px solid gray', // Set border color to gray
                      outline: 'none' // Remove the default outline on focus
                    }} value={selectedCity} onChange={handleCityChange}>
                      <option value="">Select a city</option>
                      {cityName?.map((city, index) => (
                        <option key={index} value={city?.city_name}>
                          {city?.city_name} 
                        </option>
                      ))}
                    </select>
                  </div>


                  <button onClick={() => {updateClientAddress() }} className={'bg-black  text-white px-8 py-2 mt-4 rounded'}>Update </button>

                  <br></br>

                </>
              }



              {
                (reviewData?.IsESign == 1) && ( userDetail?.role_name == "VRF") && 
                <button onClick={() => { handleSendToLd() }} className={'bg-black  text-white px-8 py-2 mt-4 rounded'}>Send To LD </button>


              }
            </div>
          </div>
          :
          <p>Loading...</p>
      }
    </>

  );
};
