import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getApi, postFormApi } from '../../services/common';
import { getIncomeProofList, setIncomeProofListAction, uploadSalarySlip } from '../../redux/actions/dkyc/step2.action';


function* getIncomeProofListSaga() {
    try {
        const data = yield call(getApi, '/GetIncomeProofType');
        const mapData = data?.data?.data && data.data.data.map((el) => ({ value: el.documentype_name, label: el.documentype_name }));
        yield put(setIncomeProofListAction({ data: mapData || [] }));
    } catch (err) {
        console.log(err);
    };
};

function* setBankPrrofSalarySlipSaga({ payload }) {

    try {
        const data = yield call(postFormApi, payload, '/uploadfiles');
        console.log("data", data);
    } catch (err) {
        console.log(err);
    };
};

function* dkycStep2Saga() {
    yield all([
        takeEvery(getIncomeProofList, getIncomeProofListSaga),
        takeEvery(uploadSalarySlip, setBankPrrofSalarySlipSaga),
    ]);
}

export default dkycStep2Saga;
