
// import { useEffect, useState, useRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { BaseLogin } from '../../../components/d-kyc/d-kyc';
// import '../style.css'
// import { pngAssets, svgAssets } from '../../../assets/asset';
// import { uploadSelfieAction } from '../../../redux/actions/dkyc/step3.action';
// import ImageCompressor from 'image-compressor';
// import { ToastContainer, toast } from "react-toastify";
// import html2canvas from 'html2canvas';
// import { BASE_URL } from "../../../config/config";
// import { postApi, postApiWithoutLoader, postFormApi, postFormApiCo, postFormApiCoselfie } from '../../../services/common';
// import { store } from '../../../redux/store';
// import { startLoaderAction, stopLoaderAction } from '../../../redux/actions/app.action';

// export const baseUrl = BASE_URL;
// const TakeSelfie = ({ changeStep, TokenData, reject, check, krnJourney, getsaveKraData }) => {
//     const digiopenny = JSON.parse(krnJourney?.pennydrop_data)
//     const KraJson1 = JSON.parse(krnJourney?.kra_xml)
//     const KraJson = KraJson1 !== null ? KraJson1 : getsaveKraData?.json
//     const divRef = useRef(null);
//     const dispatch = useDispatch();
//     const videoRef = useRef(null);
//     const [imageSelfie, setImageSelfie] = useState("")
//     const [isprocessing, setProcessing] = useState(false)
//     const addressProofList = [
//         { code: "01", description: "Passport" },
//         { code: "02", description: "Driving License" },
//         { code: "03", description: "Latest Bank Passbook" },
//         { code: "04", description: "Latest Bank Account Statement" },
//         { code: "06", description: "Voter Identity Card" },
//         { code: "07", description: "Ration Card" },
//         { code: "08", description: "Registered Lease / Sale Agreement of Residence" },
//         { code: "09", description: "Latest Land Line Telephone Bill" },
//         { code: "10", description: "Latest Electricity Bill" },
//         { code: "11", description: "Gas Bill" },
//         { code: "13", description: "Flat Maintenance Bill" },
//         { code: "14", description: "Insurance copy" },
//         { code: "15", description: "Self Declaration by High Court / Supreme Court Judge" },
//         { code: "16", description: "Power of Attorney given by FII/sub-account to the Custodians (which are duly notarized and/or apostiled or consularized) giving registered address." },
//         { code: "17", description: "Proof of Address issued by Scheduled Commercial Banks / Scheduled Co-operative Banks / Multinational Foreign banks." },
//         { code: "18", description: "Proof of Address issued by Elected representatives to the Legislative Assembly" },
//         { code: "19", description: "Proof of Address issued by Parliament" },
//         { code: "20", description: "Proof of Address issued by any Government / Statutory Authority" },
//         { code: "21", description: "Proof of Address issued by Notary Public" },
//         { code: "22", description: "Proof of Address issued by Gazetted Officer" },
//         { code: "23", description: "ID Card with address issued by Central / State Government" },
//         { code: "24", description: "ID Card with address issued by Statutory / Regulatory Authorities" },
//         { code: "25", description: "ID Card with address issued by Public Sector Undertakings" },
//         { code: "26", description: "ID Card with address issued by Scheduled Commercial Banks" },
//         { code: "27", description: "ID Card with address issued by Public Financial Institutions" },
//         { code: "28", description: "ID Card with address issued by Colleges affiliated to universities" },
//         { code: "29", description: "ID Card issued by Professional Bodies such as ICAI, ICWAI, ICSI, Bar Council, etc. to their Members" },
//         { code: "31", description: "AADHAAR" },
//         { code: "33", description: "Nrega Job Card" },
//         { code: "34", description: "Pension Or Family Pension Payment Orders (PPOs) Issued To Retired Employees By Government Departments Or Public Sector Undertakings, If They Contain The Address." }
//     ];
//     const [lat, setLatitude] = useState(null);
//     const [long, setLongitude] = useState(null);
//     const [enableLocation, setEnableLocation] = useState(false)
//     const [errorMasg, setErrorMsg] = useState('')
//     const [errorMasg1, setErrorMsg1] = useState('')

//     useEffect(() => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     const { latitude, longitude } = position?.coords;
//                     setLatitude(latitude)
//                     setLongitude(longitude)
//                     setEnableLocation(true);
//                 },

//                 (error) => {
//                     setEnableLocation(false);
//                     setErrorMsg('Please allow location access on your mobile to continue. Click here to refresh the page.');
//                     console.error('Error getting location:', error?.message);
//                 }

//             );
//         } else {
//             console.error('Geolocation is not supported by your browser');
//         }

//     }, []);
//     const findAddressProof = (code) => {
//         const addressProof = addressProofList?.find((proof) => String(proof?.code) == String(code));
//         if (addressProof) {
//             return addressProof.description;
//         }
//         return 'Address Proof Not Found';
//     };
//     const [stream, setStream] = useState(null);


//     const uploadImage = () => {
//         store.dispatch(startLoaderAction());
//         if (divRef.current) {
//             html2canvas(divRef.current).then((canvas) => {
//                 canvas.toBlob((blob) => {
//                     if (blob) {
//                         const formData = new FormData();
//                         formData.append('DocumentImages[]', blob, 'captured_image.png');
//                         formData.append('Document', 'Other');
//                         formData.append('DocumentType', 'Other');
//                         formData.append('CreatedBy', '2261');
//                         formData.append('PanNo', (TokenData?.pan));
//                         formData.append('step', reject?.length > 0 ? 15 : 13);
//                         fetch(`${baseUrl}${'/uploadfiles'}`, {
//                             method: 'POST',
//                             body: formData,
//                             headers: {
//                                 'Authorization': `Bearer ${'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc'}` // Include the token in the 'Authorization' header
//                             },
//                         })
//                             .then((response) => response.json())
//                             .then((data) => {
//                                 if (data?.success) {
//                                     store.dispatch(stopLoaderAction());

//                                     toast('Geoloction document upload succesfully')
//                                     // setRejectSelfie(true)
//                                     setTimeout(() => {
//                                         changeStep(0)

//                                     }, 500);

//                                 }
//                                 else {
//                                     store.dispatch(stopLoaderAction());

//                                     toast('Geo location upload document failed Please try again')
//                                 }

//                             })
//                             .catch((error) => {
//                                 store.dispatch(stopLoaderAction());

//                                 toast('Geo location upload document failed Please try again')
//                             });
//                     }
//                 }, 'image/png', 0.9);
//             });
//         }
//     };

//     const handleClick = async () => {
//         const image = { video: true };
//         // setProcessing(true)
//         try {
//             const isstream = await navigator.mediaDevices.getUserMedia(image);
//             const interval = setInterval(() => {
//                 if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
//                     clearInterval(interval);
//                     // setProcessing(false)
//                     //captureImage();
//                 }
//                 setStream(isstream);
//                 videoRef.current.srcObject = isstream;
//             }, 1000);


//         } catch (err) {
//             console.error(err);
//         }
//     };

//     const stopCamera = () => {
//         stream.getTracks().forEach(track => track.stop());
//         setStream(null);
//         videoRef.current.srcObject = null;
//     };

//     const handleVideoLoad = () => {
//         videoRef.current.play();
//     };

//     function dataURLtoFile(dataurl, filename) {
//         var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//             bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//         while (n--) {
//             u8arr[n] = bstr.charCodeAt(n);
//         }
//         return new File([u8arr], filename, { type: mime });
//     }



//     const captureImage = async () => {
//         setProcessing(true)
//         const canvas = document.createElement('canvas');
//         canvas.width = videoRef.current.videoWidth;
//         canvas.height = videoRef.current.videoHeight;
//         canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
//         const imageUrl = canvas.toDataURL('image/jpeg'); // Change format to JPEG for better compression
//         const convertedFile = dataURLtoFile(imageUrl, 'file');
//         if (convertedFile.size > 5 * 1024 * 1024) {
//             try {
//                 const compressedImage = await ImageCompressor.convertBase64ToFile(imageUrl, {
//                     size: 2, // target file size in MB
//                     quality: 0.6, // image quality after compression
//                     maxWidth: 1920, // maximum width after compression
//                     maxHeight: 1080, // maximum height after compression
//                 });
//                 setImageSelfie(compressedImage.dataURL);
//             } catch (error) {
//                 console.error('Image compression failed:', error);
//             }
//         } else {
//             setImageSelfie(imageUrl);
//         }

//         stopCamera();
//     };
//     useEffect(() => {
//         handleClick()

//     }, [])
//     const handleContinueBtn = async () => {
//         setErrorMsg1('')
//         const base64String = imageSelfie.split(",")[1];
//         const convertFile = dataURLtoFile(imageSelfie, 'file')
//         if (!TokenData?.pan || TokenData?.pan === '') {
//             alert("Your link is expired, Please try again !!")
//             return
//         }
//         else {
//             try {
//                 const formData = new FormData();
//                 formData.append('image', base64String);
//                 formData.append('source', 'DKYC');
//                 const response1 = await postFormApiCoselfie(formData, 'processSelfie');
//                 const response = response1.data
//                 if (response.status == 'completed') {
//                     if (response.result.face_detected) {
//                         if (response.result.is_live && (response.result.confidence > 70)) {
//                             if (response.result.are_eyes_open) {
//                                 if (response.result.multiple_faces_detected == false) {
//                                     if (response.result.is_front_facing) {
//                                         if (response.result.is_face_cropped == false) {
//                                             if (response.result.face_coverage.status == "optimal") {
//                                                 if (response.result.face_quality.status == "optimal") {
//                                                     if (response.result.is_nsfw == false) {
//                                                         const payload = {
//                                                             PanNo: TokenData?.pan || '',
//                                                             Document: 'Client Photo',
//                                                             DocumentType: 'NA',
//                                                             step: reject.length > 0 ? 15 : 13,
//                                                             rejectReUploadFlag: reject?.length ? 1 : null,
//                                                             CreatedBy: TokenData?.created_by || '',
//                                                             'DocumentImages[]': convertFile
//                                                         }
//                                                         const response = await postFormApi(payload, '/uploadfiles');
//                                                         if (response?.data.success) {
//                                                             const payload1 = {
//                                                                 mobile: TokenData?.mobile,
//                                                                 lat: lat,
//                                                                 lng: long
//                                                             }
//                                                             const response1 = await postApi(payload1, '/saveLatLngDetails');
//                                                             if (response1?.data.success) {
//                                                                 reject.length == 0 ?
//                                                                     uploadImage()
//                                                                     : changeStep(0)
//                                                             }
//                                                             else {
//                                                                 toast('something went wrong')

//                                                             }
//                                                         }
//                                                         else {
//                                                             toast(response?.data ? response?.data?.message : 'something went wrong')
//                                                         }

//                                                     }
//                                                     else {
//                                                         setErrorMsg1('The captured selfie is not appropriate for a work environment. Please take another one.');

//                                                         return;
//                                                     }
//                                                 } else {
//                                                     setErrorMsg1(response.result.face_quality.message);

//                                                     return;
//                                                 }
//                                             } else {
//                                                 setErrorMsg1(response.result.face_coverage.message);

//                                                 return;
//                                             }
//                                         } else {
//                                             setErrorMsg1('Please ensure the full face is visible');


//                                             return;
//                                         }
//                                     } else {
//                                         setErrorMsg1('Please take a selfie with the front-facing camera');


//                                         return;
//                                     }
//                                 } else {
//                                     setErrorMsg1('Muliple faces Detected');


//                                     return;
//                                 }
//                             } else {
//                                 setErrorMsg1('Please Capture a live Selfie with eyes open');


//                                 return;
//                             }
//                         } else {
//                             setErrorMsg1('Please Capture a live Selfie in a visible Background');


//                             return;
//                         }
//                     }
//                     else {
//                         setErrorMsg1('No face Detected');
//                         return;
//                     }
//                 } else if (response.status == 'failed') {
//                     setErrorMsg(response.message);
//                     return;
//                 } else {
//                     setErrorMsg('Please Capture the Selfie Again ');
//                     return;
//                 }
//             } catch (error) {
//                 toast(error.message || 'something went wrong')

//             }

//         }
//     };


//     const handleClickAgain = async () => {
//         handleClick()
//         setImageSelfie("")
//         setErrorMsg1('')

//     }

//     return (
//         <BaseLogin image={pngAssets.dkyc.leftpart3}>
//             <ToastContainer autoClose={1000} />
//             <div className=" flex flex-col justify-between w-full   md:w-[80%] lg:w-[70%] h-full mx-auto">
//                 <div className="">
//                     <div className="selfie-box block-center mb-3 w-[220px] h-[220px] rounded-full mx-auto  flex justify-center items-center">
//                         {imageSelfie && (
//                             <img src={imageSelfie}
//                                 className='w-full h-full rounded-full'
//                             />
//                         )}
//                         {!imageSelfie && (
//                             <video
//                                 ref={videoRef}
//                                 onLoadedData={handleVideoLoad}
//                                 className="w-full h-full rounded-full"
//                                 playsInline={true}
//                                 width={300}
//                                 mirrored={true}
//                                 style={{ transform: 'scaleX(-1)' }}
//                             />
//                         )}
//                     </div>
//                     <div className='my-4 flex flex-col gap-5 justify-evenly items-center'>
//                         {/* {stream &&
//                                 <button className="bg-[#5367FC] text-[18px] w-[30%] flex justify-center items-center gap-x-5 border-s-4 rounded-[20px] py-[5px] text-white cursor-pointer"
//                                     onClick={captureImage}> Capture </button>
//                             } */}
//                         {
//                             imageSelfie ?
//                                 <p className='text-[20px] text-center font-bold font-poppinsRegular'>This is your picture</p>
//                                 :
//                                 <p className='text-[20px] text-center font-bold font-poppinsRegular'

//                                 >Take a selfie!</p>
//                         }
//                     </div>
//                     <div className='flex flex-col justify-center items-center md:w-[70%] w-full mx-auto font-poppinsMedium text-[#797979] text-center' >
//                         {imageSelfie ?
//                             <>
//                                 <p className='px-10'>
//                                     If you are not happy with the picture, you can take it again.
//                                 </p>
//                                 <p className="mt-6 text-[#5367FC] underline text-[14px] cursor-pointer"
//                                     onClick={handleClickAgain}
//                                 >Take Selfie Again</p>

//                             </>
//                             :
//                             <>
//                                 <p className=' '>
//                                     Take a clear picture of yourself by placing your face within the circle as shown.
//                                 </p>
//                                 <p className="mt-6">Open the camera when ready!</p>
//                             </>
//                         }
//                     </div>
//                 </div>

//                 {
//                        ( lat && long ) &&
//                      <h1 style={{fontSize:'20px',textAlign:'center',margin:'10px'}}>Your Current Location is {lat} {long}</h1>
//                     }
//                 <div className=''>
//                     {enableLocation ?
//                         imageSelfie ?
//                             <div className="" disabled={errorMasg1 != '' ? true : false}>
//                                 <button className="bg-[#5367FC] w-full text-[18px] flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
//                                     onClick={handleContinueBtn}>
//                                     Submit
//                                 </button>
//                             </div>
//                             :
//                             <div className="">
//                                 {stream ? <div className="bg-[#5367FC] text-[18px] flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 text-white cursor-pointer" onClick={captureImage}>
//                                     <img src={svgAssets.camera} alt="" className='' />
//                                     <span className="font-medium font-poppinsMedium">Capture</span>
//                                 </div>
//                                     :
//                                     null
//                                 }
//                             </div>
//                         :
//                         <>
//                             <p style={{ color: 'red', textAlign: 'center' }} >{errorMasg}</p>
//                             <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { window.location.reload() }}>
//                                 <p style={{ fontWeight: 'bold', color: '#3498db' }}>Click Here</p>
//                             </div>
//                         </>


//                     }

//                     <p style={{ color: 'red', textAlign: 'center' }} >{errorMasg1}</p>
//                     <div className="my-5">
//                         <div className="flex justify-center items-center cursor-pointer text-[#000000] font-poppinsMedium"
//                             onClick={() => { changeStep(0) }}>
//                             <p className="">Back</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {reject?.length == 0 ?
//                 check && krnJourney?.kra_status == 2 ?
//                     <div
//                         ref={divRef} style={{
//                             opacity: '1',
//                             position: 'absolute',
//                             left: '-9999px',
//                             width: '300px',
//                             fontSize: '10px',
//                             marginLeft: 'auto',
//                             marginRight: 'auto'
//                         }} className='screenshot'>
//                         <table >
//                             <tbody>
//                                 <tr>
//                                     <td width="100%">
//                                         <div style={{}} id="userImageData" >
//                                             <span>
//                                                 {/* <img style={{ width: "100%" }} src={fileName} /> */}
//                                                 {/* <img style={{ width: '100%' }} src={imageData} /> */}
//                                                 {/* <img style={{ width: "100%" }} src={imageDigioFile} /> */}
//                                             </span>
//                                         </div>
//                                     </td>
//                                 </tr>
//                                 <tr>
//                                     <td width="100%" style={{ padding: 15 }}>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "5px 0px",
//                                                 justifyContent: "space-between",
//                                                 borderBottom: "2px solid blue",
//                                                 paddingBottom: 5
//                                             }}
//                                         >

//                                             <span />
//                                         </div>
//                                         <h4>Geo Location Info</h4>
//                                         {/* <div
// 													style={{
// 														display: "flex",
// 														margin: "10px 0px",
// 														justifyContent: "space-between"
// 													}}
// 												>

// 													<span>Address -</span>
// 													<span style={{ marginLeft: 10,wordBreak:'break-all' }}>
// 														{'address'}
// 													</span>
// 												</div> */}
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Latitude -</span>
//                                             <span>
//                                                 <b>
//                                                     {lat}
//                                                 </b>
//                                             </span>
//                                         </div>

//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Longitude -</span>
//                                             <span>
//                                                 <b>
//                                                     {long}
//                                                 </b>
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >

//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                         </div>
//                                     </td>
//                                 </tr>
//                                 <tr>
//                                     <td width="100%" style={{ padding: 15 }}>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "5px 0px",
//                                                 justifyContent: "space-between",
//                                                 borderBottom: "2px solid blue",
//                                                 paddingBottom: 5
//                                             }}
//                                         >
//                                             <span>
//                                                 <h4>Penny Drop Info</h4>
//                                             </span>
//                                             <span />
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Id -</span>
//                                             <span>
//                                                 {digiopenny?.id}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Verified Status -</span>
//                                             <span style={{ wordBreak: 'break-all' }}>

//                                                 <p>{krnJourney?.penny_drop_status == 1 ? digiopenny?.verified ? 'true' : 'false' : 'false'}</p>
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Date &amp; Time -</span>
//                                             <span>
//                                                 {digiopenny?.verified_at}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Beneficiary Name With Bank -</span>
//                                             <span style={{ wordBreak: 'break-all' }}>
//                                                 <b>
//                                                     {krnJourney?.penny_drop_status == 1 ? digiopenny?.beneficiary_name_with_bank : ''}
//                                                 </b>
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Pan-Card Full Name -</span>
//                                             <span style={{ wordBreak: 'break-all' }}>
//                                                 {krnJourney?.full_name}
//                                             </span>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div> :

//                     <div
//                         ref={divRef} style={{
//                             opacity: '1',
//                             position: 'absolute',
//                             left: '-9999px',
//                             width: '300px',
//                             fontSize: '10px',
//                             marginLeft: 'auto',
//                             marginRight: 'auto'
//                         }} className='screenshot'>
//                         <table>
//                             <tbody>
//                                 <tr>
//                                     <td width="100%" style={{ padding: 15 }}>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "5px 0px",
//                                                 justifyContent: "space-between",
//                                                 borderBottom: "2px solid blue",
//                                                 paddingBottom: 5
//                                             }}>
//                                             <span>
//                                                 <h4>KRA Info</h4>
//                                             </span>
//                                             <span />
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>KRA-Verification Code -</span>
//                                             <span>
//                                                 {KraJson?.APP_PAN_SUMM?.APP_OTHKRA_BATCH}
//                                             </span>
//                                         </div>

//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>KRA-Permanent Address Proof Type-</span>
//                                             <span>
//                                                 {findAddressProof(KraJson?.KYC_DATA?.APP_COR_ADD_PROOF)}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>KRA-Permanent Address Proof -</span>
//                                             <span>
//                                                 {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD_REF).length == 0 ? 'NA' : KraJson?.KYC_DATA?.APP_PER_ADD_REF}

//                                                 {/* {typeof kraData?.json?.KYC_DATA?.APP_PER_ADD_REF == 'object' ?"Na":kraData?.json?.KYC_DATA?.APP_PER_ADD_REF} */}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>KRA-Correspondence Address Proof Type -</span>
//                                             <span>
//                                                 {findAddressProof(KraJson?.KYC_DATA?.APP_COR_ADD_PROOF)}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>KRA-Correspondence Address Proof -</span>
//                                             <span>
//                                                 {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD_REF).length == 0 ? 'NA' : KraJson?.KYC_DATA?.APP_PER_ADD_REF}


//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Permanent Addresss - </span>
//                                             <span style={{ wordBreak: 'break-all' }}>
//                                                 {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD1).length == 0 ? '' : KraJson?.KYC_DATA?.APP_PER_ADD1}<br />
//                                                 {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD2).length == 0 ? '' : KraJson?.KYC_DATA?.APP_PER_ADD2}

//                                                 {/* {kraData?.json?.KYC_DATA?.APP_PER_ADD1}  <br /> {kraData?.json?.KYC_DATA?.APP_PER_ADD2} */}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Correspondence Address - </span>
//                                             <span style={{ wordBreak: 'break-all' }}>
//                                                 {Object.keys(KraJson?.KYC_DATA?.APP_COR_ADD1).length == 0 ? '' : KraJson?.KYC_DATA?.APP_COR_ADD1}<br />
//                                                 {Object.keys(KraJson?.KYC_DATA?.APP_COR_ADD2).length == 0 ? '' : KraJson?.KYC_DATA?.APP_COR_ADD2}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>PAN no -</span>
//                                             <span>
//                                                 {KraJson?.KYC_DATA?.APP_PAN_NO}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Latitude -</span>
//                                             <span>
//                                                 <b>
//                                                     {lat}
//                                                 </b>
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Longitude -</span>
//                                             <span>
//                                                 <b>
//                                                     {long}
//                                                 </b>
//                                             </span>
//                                         </div>
//                                     </td>
//                                 </tr>

//                                 <tr>
//                                     <td width="100%" style={{ padding: 15 }}>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "5px 0px",
//                                                 justifyContent: "space-between",
//                                                 borderBottom: "2px solid blue",
//                                                 paddingBottom: 5
//                                             }}
//                                         >
//                                             <span>
//                                                 <h4>Penny Drop Info</h4>
//                                             </span>
//                                             <span />
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Id -</span>
//                                             <span>
//                                                 {digiopenny?.id}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Verified Status -</span>
//                                             <span>
//                                                 <p>{krnJourney?.penny_drop_status == 1 ? digiopenny?.verified ? "true" : 'false' : 'false'}</p>
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Date &amp; Time -</span>
//                                             <span>
//                                                 {digiopenny?.verified_at}
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span>Beneficiary Name With Bank -</span>
//                                             <span style={{ wordBreak: 'break-all' }}>
//                                                 <b>
//                                                     {krnJourney?.penny_drop_status == 1 ? digiopenny?.beneficiary_name_with_bank : ''}
//                                                 </b>
//                                             </span>
//                                         </div>
//                                         <div
//                                             style={{
//                                                 display: "flex",
//                                                 margin: "10px 0px",
//                                                 justifyContent: "space-between"
//                                             }}
//                                         >
//                                             <span >Pan-Card Full Name -</span>
//                                             <span style={{ wordBreak: 'break-all' }}>
//                                                 {krnJourney?.full_name}
//                                             </span>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 : null
//             }
//         </BaseLogin >
//     );
// };
// export default TakeSelfie;



import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BaseLogin } from '../../../components/d-kyc/d-kyc';
import '../style.css'
import { pngAssets, svgAssets } from '../../../assets/asset';
import { uploadSelfieAction } from '../../../redux/actions/dkyc/step3.action';
import ImageCompressor from 'image-compressor';
import { ToastContainer, toast } from "react-toastify";
import html2canvas from 'html2canvas';
import { BASE_URL } from "../../../config/config";
import { postApi, postApiWithoutLoader, postFormApi, postFormApiCo, postFormApiCoselfie } from '../../../services/common';
import { store } from '../../../redux/store';
import { startLoaderAction, stopLoaderAction } from '../../../redux/actions/app.action';
import axios from 'axios';
import { Modal } from 'antd';
import NewLoader from '../../../assets/gif/Dark Theme/Loading_big.gif';
import wrong from '../../../assets/svg/selfie/wrong.svg'
import right from '../../../assets/svg/selfie/righ.svg'
import one from '../../../assets/svg/selfie/1.svg'
import goagle from '../../../assets/svg/selfie/goagle.svg'
import inner from '../../../assets/svg/selfie/inner.svg'
import perfect from '../../../assets/svg/selfie/perfect.svg'
import incorrect from '../../../assets/svg/selfie/incorrect.svg'
import incorrect1 from '../../../assets/svg/selfie/incorrect1.svg'

export const baseUrl = BASE_URL;
const TakeSelfie = ({ changeStep, TokenData, reject, check, krnJourney, getsaveKraData }) => {
    const digiopenny = JSON.parse(krnJourney?.pennydrop_data)
    const KraJson1 = JSON.parse(krnJourney?.kra_xml)
    const KraJson = KraJson1 !== null ? KraJson1 : getsaveKraData?.json
    const divRef = useRef(null);
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const [imageSelfie, setImageSelfie] = useState("")
    const [isprocessing, setProcessing] = useState(false)
    const [check1, setCheck] = useState(false)
    const addressProofList = [
        { code: "01", description: "Passport" },
        { code: "02", description: "Driving License" },
        { code: "03", description: "Latest Bank Passbook" },
        { code: "04", description: "Latest Bank Account Statement" },
        { code: "06", description: "Voter Identity Card" },
        { code: "07", description: "Ration Card" },
        { code: "08", description: "Registered Lease / Sale Agreement of Residence" },
        { code: "09", description: "Latest Land Line Telephone Bill" },
        { code: "10", description: "Latest Electricity Bill" },
        { code: "11", description: "Gas Bill" },
        { code: "13", description: "Flat Maintenance Bill" },
        { code: "14", description: "Insurance copy" },
        { code: "15", description: "Self Declaration by High Court / Supreme Court Judge" },
        { code: "16", description: "Power of Attorney given by FII/sub-account to the Custodians (which are duly notarized and/or apostiled or consularized) giving registered address." },
        { code: "17", description: "Proof of Address issued by Scheduled Commercial Banks / Scheduled Co-operative Banks / Multinational Foreign banks." },
        { code: "18", description: "Proof of Address issued by Elected representatives to the Legislative Assembly" },
        { code: "19", description: "Proof of Address issued by Parliament" },
        { code: "20", description: "Proof of Address issued by any Government / Statutory Authority" },
        { code: "21", description: "Proof of Address issued by Notary Public" },
        { code: "22", description: "Proof of Address issued by Gazetted Officer" },
        { code: "23", description: "ID Card with address issued by Central / State Government" },
        { code: "24", description: "ID Card with address issued by Statutory / Regulatory Authorities" },
        { code: "25", description: "ID Card with address issued by Public Sector Undertakings" },
        { code: "26", description: "ID Card with address issued by Scheduled Commercial Banks" },
        { code: "27", description: "ID Card with address issued by Public Financial Institutions" },
        { code: "28", description: "ID Card with address issued by Colleges affiliated to universities" },
        { code: "29", description: "ID Card issued by Professional Bodies such as ICAI, ICWAI, ICSI, Bar Council, etc. to their Members" },
        { code: "31", description: "AADHAAR" },
        { code: "33", description: "Nrega Job Card" },
        { code: "34", description: "Pension Or Family Pension Payment Orders (PPOs) Issued To Retired Employees By Government Departments Or Public Sector Undertakings, If They Contain The Address." }
    ];
    const [lat, setLatitude] = useState(null);
    const [long, setLongitude] = useState(null);
    const [enableLocation, setEnableLocation] = useState(false)
    const [deseble, setDeseble] = useState(false)
    const [errorMasg, setErrorMsg] = useState('')
    const [errorMasg1, setErrorMsg1] = useState('')

    useEffect(() => {
        setDeseble(false)

        setCheck(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position?.coords;
                    setLatitude(latitude)
                    setLongitude(longitude)
                    setEnableLocation(true);
                    if (!isInsideIndia(latitude, longitude)) {
                        toast('KYC can not be performed for Your Location.');
                        setDeseble(true)
                   
                    } else {
                        setDeseble(false)
                        console.log("Selfie location is within India.");
                       
}
                },

                (error) => {
                    setEnableLocation(false);
                    setErrorMsg('Please allow location access on your mobile to continue. Click here to refresh the page.');
                    console.error('Error getting location:', error?.message);
                }

            );
        } else {
            console.error('Geolocation is not supported by your browser');
        }

    }, []);


    function isInsideIndia(lat, lon) {
        const indiaBounds = {
            north: 37.6, // Max latitude (North)
            south: 6.8,  // Min latitude (South)
            east: 97.25, // Max longitude (East)
            west: 68.7   // Min longitude (West)
        };

        return lat >= indiaBounds.south && lat <= indiaBounds.north &&
               lon >= indiaBounds.west && lon <= indiaBounds.east;
    }
    const findAddressProof = (code) => {
        const addressProof = addressProofList?.find((proof) => String(proof?.code) == String(code));
        if (addressProof) {
            return addressProof.description;
        }
        return 'Address Proof Not Found';
    };
    const [stream, setStream] = useState(null);

    const modalToggle = () => {
        setCheck(false); // Close the modal

    };


    const uploadImage = () => {
        store.dispatch(startLoaderAction());
        if (divRef.current) {
            html2canvas(divRef.current).then((canvas) => {
                canvas.toBlob((blob) => {
                    if (blob) {
                        const formData = new FormData();
                        formData.append('DocumentImages[]', blob, 'captured_image.png');
                        formData.append('Document', 'Other');
                        formData.append('DocumentType', 'Other');
                        formData.append('CreatedBy', '2261');
                        formData.append('PanNo', (TokenData?.pan));
                        formData.append('step', reject?.length > 0 ? 15 : 13);
                        fetch(`${baseUrl}${'/uploadfiles'}`, {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Authorization': `Bearer ${'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNiMzQ5MWRhY2I3OTgyYzNjODkwMmFmZmZiNzA5ZWNiMTBlY2JhYTlhYmVhMzE1M2I2MzQ4NjQxODUyNThjODliZGJkYmMyYTkyYmFhZTg0In0.eyJhdWQiOiI1IiwianRpIjoiM2IzNDkxZGFjYjc5ODJjM2M4OTAyYWZmZmI3MDllY2IxMGVjYmFhOWFiZWEzMTUzYjYzNDg2NDE4NTI1OGM4OWJkYmRiYzJhOTJiYWFlODQiLCJpYXQiOjE3MTQ5OTI0OTgsIm5iZiI6MTcxNDk5MjQ5OCwiZXhwIjoxNzQ2NTI4NDk4LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.kAP8OjjK3zuKz8dx8m2w2_OLGWIg42IQoETIeD6SOCHmLWAYRJH1qULKoHTpWCbwE85Dn2dBbA8ThvpstGPeQv-3z91o92-qGbiUtxNV7dFnHYDwtNGGBA_aStcF6Edripax_FkHIe5b4_6GToCImHddZ7GXM4SJNQEVxNG7nrb32aArObPMnTFknwAFym4y1zS2mqmqondPcldCw_mbp5IR5BxC2YY537G8MKwGukDXrcSdGxUBdzhql_nVRnJRMKwjxx4AYRifni9cNPwnQgHN0cKpFoQ5JnDtzatcgbaHWhCiO6zHiNTBD_CS_HPSic82LBI74xJMyDemCSu2hOrNVmPR5qn5JE4H_InjFQtrXRcCfTeWs6Y9Lv-ef5X11OkkwusKmcjvR4Ri5yYqI9gtn81LRZDGgHlNQg-SBP1TYxUAZWe04bAEaKWYnSyDLY1PyROPeQPRb52g59FWH8leepph_NYTpbvVHOwchV5UaY33OxuVoEguPTptxaZTA2HgOfgoT2WFKhrix57AjPIwOOkUM7XUb2QyXCSvc1Pd05xyd0-O4_LzWaDwD_WunQy1vo-Gfie6t3dJTZSBf0lA3fGdojdfkTlM1ZEapvxFJU3QeZG9fcwyp6M-7MklNfjHKmrkcKzOoyl75i0OBSgC7dUpXQC4Wb_HynuJHfQ'}` // Include the token in the 'Authorization' header
                            },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data?.success) {
                                    store.dispatch(stopLoaderAction());

                                    toast('Geoloction document upload succesfully')
                                    // setRejectSelfie(true)
                                    setTimeout(() => {
                                        changeStep(0)

                                    }, 500);

                                }
                                else {
                                    store.dispatch(stopLoaderAction());

                                    toast('Geo location upload document failed Please try again')
                                }

                            })
                            .catch((error) => {
                                store.dispatch(stopLoaderAction());

                                toast('Geo location upload document failed Please try again')
                            });
                    }
                }, 'image/png', 0.9);
            });
        }
    };

    const handleClick = async () => {
        const image = { video: true };
        // setProcessing(true)
        try {
            const isstream = await navigator.mediaDevices.getUserMedia(image);
            const interval = setInterval(() => {
                if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
                    clearInterval(interval);
                    // setProcessing(false)
                    //captureImage();
                }
                setStream(isstream);
                videoRef.current.srcObject = isstream;
            }, 1000);


        } catch (err) {
            console.error(err);
        }
    };

    const stopCamera = () => {
        stream.getTracks().forEach(track => track.stop());
        setStream(null);
        videoRef.current.srcObject = null;
    };

    const handleVideoLoad = () => {
        videoRef.current.play();
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }



    const captureImage = async () => {
        setProcessing(true)
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
        const imageUrl = canvas.toDataURL('image/jpeg'); // Change format to JPEG for better compression
        const convertedFile = dataURLtoFile(imageUrl, 'file');
        if (convertedFile.size > 5 * 1024 * 1024) {
            try {
                const compressedImage = await ImageCompressor.convertBase64ToFile(imageUrl, {
                    size: 2, // target file size in MB
                    quality: 0.6, // image quality after compression
                    maxWidth: 1920, // maximum width after compression
                    maxHeight: 1080, // maximum height after compression
                });
                setImageSelfie(compressedImage.dataURL);
            } catch (error) {
                console.error('Image compression failed:', error);
            }
        } else {
            setImageSelfie(imageUrl);
        }

        stopCamera();
    };
    useEffect(() => {
        handleClick()

    }, [])
    const handleContinueBtn = async () => {
        setErrorMsg1('')
        const base64String = imageSelfie.split(",")[1];
        const convertFile = dataURLtoFile(imageSelfie, 'file')
        if (!TokenData?.pan || TokenData?.pan === '') {
            alert("Your link is expired, Please try again !!")
            return
        }
        else {
            try {
                const formData = new FormData();
                formData.append('image', base64String);
                formData.append('source', 'DKYC');
                const response1 = await postFormApiCoselfie(formData, 'processSelfie');
                const response = response1.data
                if (response.status == 'completed') {
                    if (response.result.face_detected) {
                        if (response.result.is_live && (response.result.confidence > 70)) {
                            if (response.result.are_eyes_open) {
                                if (response.result.multiple_faces_detected == false) {
                                    if (response.result.is_front_facing) {
                                        if (response.result.is_face_cropped == false) {
                                            if (response.result.face_coverage.status == "optimal") {
                                                if (response.result.face_quality.status == "optimal") {
                                                    if (response.result.is_nsfw == false) {
                                                        const payload = {
                                                            PanNo: TokenData?.pan || '',
                                                            Document: 'Client Photo',
                                                            DocumentType: 'NA',
                                                            step: reject.length > 0 ? 15 : 13,
                                                            rejectReUploadFlag: reject?.length ? 1 : null,
                                                            CreatedBy: TokenData?.created_by || '',
                                                            'DocumentImages[]': convertFile
                                                        }
                                                        const response = await postFormApi(payload, '/uploadfiles');
                                                        if (response?.data.success) {
                                                            const payload1 = {
                                                                mobile: TokenData?.mobile,
                                                                lat: lat,
                                                                lng: long
                                                            }
                                                            const response1 = await postApi(payload1, '/saveLatLngDetails');
                                                            if (response1?.data.success) {
                                                                reject.length == 0 ?
                                                                    uploadImage()
                                                                    : changeStep(0)
                                                            }
                                                            else {
                                                                toast('something went wrong')

                                                            }
                                                        }
                                                        else {
                                                            toast(response?.data ? response?.data?.message : 'something went wrong')
                                                        }

                                                    }
                                                    else {
                                                        setErrorMsg1('The captured selfie is not appropriate for a work environment. Please take another one.');

                                                        return;
                                                    }
                                                } else {
                                                    setErrorMsg1(response.result.face_quality.message);

                                                    return;
                                                }
                                            } else {
                                                setErrorMsg1(response.result.face_coverage.message);

                                                return;
                                            }
                                        } else {
                                            setErrorMsg1('Please ensure the full face is visible');
                                            return;
                                        }
                                    } else {
                                        setErrorMsg1('Please take a selfie with the front-facing camera');


                                        return;
                                    }
                                } else {
                                    setErrorMsg1('Muliple faces Detected');


                                    return;
                                }
                            } else {
                                setErrorMsg1('Please Capture a live Selfie with eyes open');


                                return;
                            }
                        } else {
                            setErrorMsg1('Please Capture a live Selfie in a visible Background');
                            return;
                        }
                    }
                    else {
                        setErrorMsg1('No face Detected');
                        return;
                    }
                } else if (response.status == 'failed') {
                    setErrorMsg(response.message);
                    return;
                } else {
                    setErrorMsg('Please Capture the Selfie Again ');
                    return;
                }
            } catch (error) {
                toast(error.message || 'something went wrong')

            }

        }
    };


    const handleClickAgain = async () => {
        handleClick()
        setImageSelfie("")
        setErrorMsg1('')

    }

  

    return (
        <BaseLogin image={pngAssets.dkyc.leftpart3}>
            <ToastContainer autoClose={1000} />
            <Modal
                title=""
                className="w-full p-[33px]"
                width={700}
                open={check1}
                centered={true}
                onCancel={() => modalToggle()}
                footer={null}
            >
                <div className="mb-1.5 flex flex-col">
                    <h2 style={{ fontWeight: '600', fontSize: '24px', marginBottom: '20px' }}>Photo Verification</h2>

                    <h2 style={{ fontWeight: '600', fontSize: '24px', marginTop: '20px', textAlign: 'center' }}>Correct Selfie</h2>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img alt="application_icon" src={perfect} style={{ height: 100, width: 100, }} />
                    </div>
                    <h2 style={{ fontWeight: '600', fontSize: '24px', marginTop: '40px', textAlign: 'center', marginBottom: '20px' }}>Incorrect Selfie</h2>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                        <img alt="application_icon" src={goagle} style={{ height: 100, width: 100, }} />
                        <img alt="application_icon" src={one} style={{ height: 100, width: 100, }} />
                        <img alt="application_icon" src={inner} style={{ height: 100, width: 100, }} />
                        <img alt="application_icon" src={incorrect} style={{ height: 100, width: 100, }} />
                        <img alt="application_icon" src={incorrect1} style={{ height: 100, width: 100, }} />

                    </div>
                    <button onClick={modalToggle} style={{
                        padding: '16px 30px',
                        border: 'none',
                        borderRadius: '8px',
                        background: '#000000',
                        fontSize: '14px',
                        color: '#fff',
                        fontWeight: '600',
                        display: 'table',
                        margin: 'auto',
                        marginTop: '40px',
                        boxSizing: 'border-box',
                    }}>Yes, I Understand</button>
                </div>
            </Modal>
            <div className=" flex flex-col justify-between w-full   md:w-[80%] lg:w-[70%] h-full mx-auto">
                <div className="">
                    <div className="selfie-box block-center mb-3 w-[220px] h-[220px] rounded-full mx-auto  flex justify-center items-center">
                        {imageSelfie && (
                            <img src={imageSelfie}
                                className='w-full h-full rounded-full'
                            />
                        )}
                        {!imageSelfie && (
                            <video
                                ref={videoRef}
                                onLoadedData={handleVideoLoad}
                                className="w-full h-full rounded-full"
                                playsInline={true}
                                width={300}
                                mirrored={true}
                                style={{ transform: 'scaleX(-1)' }}
                            />
                        )}
                    </div>
                    <div className='my-4 flex flex-col gap-5 justify-evenly items-center'>
                        {/* {stream &&
                                <button className="bg-[#5367FC] text-[18px] w-[30%] flex justify-center items-center gap-x-5 border-s-4 rounded-[20px] py-[5px] text-white cursor-pointer"
                                    onClick={captureImage}> Capture </button>
                            } */}
                        {
                            imageSelfie ?
                                <p className='text-[20px] text-center font-bold font-poppinsRegular'>This is your picture</p>
                                :
                                <p className='text-[20px] text-center font-bold font-poppinsRegular'

                                >Take a selfie!</p>
                        }
                    </div>
                    <div className='flex flex-col justify-center items-center md:w-[70%] w-full mx-auto font-poppinsMedium text-[#797979] text-center' >
                        {imageSelfie ?
                            <>
                                <p className='px-10'>
                                    If you are not happy with the picture, you can take it again.
                                </p>
                                <p className="mt-6 text-[#5367FC] underline text-[14px] cursor-pointer"
                                    onClick={handleClickAgain}
                                >Take Selfie Again</p>

                            </>
                            :
                            <>
                                <p className=' '>
                                    Take a clear picture of yourself by placing your face within the circle as shown.
                                </p>
                                <p className="mt-6">Open the camera when ready!</p>
                            </>
                        }
                    </div>
                </div>

                {
                    (lat && long) &&
                    <h1 style={{ fontSize: '20px', textAlign: 'center', margin: '10px' }}>Your Current Location is {lat} {long}</h1>
                }
                <div className=''>
                    {enableLocation ?
                        imageSelfie ?
                            <div className="" disabled={errorMasg1 != '' ? true : false}>
                                <button className="bg-[#5367FC] w-full text-[18px] flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
                                    onClick={handleContinueBtn}>
                                    Submit
                                </button>
                            </div>
                            :
                            <div className="">
                                {stream ? 
                                deseble == false &&
                                <div className="bg-[#5367FC] text-[18px] flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 text-white cursor-pointer" onClick={captureImage}>
                                    <img src={svgAssets.camera} alt="" className='' />
                                    <span className="font-medium font-poppinsMedium">Capture</span>
                                </div>
                                    :
                                    null
                                }
                            </div>
                        :
                        <>
                            <p style={{ color: 'red', textAlign: 'center' }} >{errorMasg}</p>
                            <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { window.location.reload() }}>
                                <p style={{ fontWeight: 'bold', color: '#3498db' }}>Click Here</p>
                            </div>
                        </>


                    }

                    <p style={{ color: 'red', textAlign: 'center' }} >{errorMasg1}</p>
                    <div className="my-5">
                        <div className="flex justify-center items-center cursor-pointer text-[#000000] font-poppinsMedium"
                            onClick={() => { changeStep(0) }}>
                            <p className="">Back</p>
                        </div>
                    </div>
                </div>
            </div>

            {reject?.length == 0 ?
                check && krnJourney?.kra_status == 2 ?
                    <div
                        ref={divRef} style={{
                            opacity: '1',
                            position: 'absolute',
                            left: '-9999px',
                            width: '300px',
                            fontSize: '10px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }} className='screenshot'>
                        <table >
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <div style={{}} id="userImageData" >
                                            <span>
                                                {/* <img style={{ width: "100%" }} src={fileName} /> */}
                                                {/* <img style={{ width: '100%' }} src={imageData} /> */}
                                                {/* <img style={{ width: "100%" }} src={imageDigioFile} /> */}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100%" style={{ padding: 15 }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "5px 0px",
                                                justifyContent: "space-between",
                                                borderBottom: "2px solid blue",
                                                paddingBottom: 5
                                            }}
                                        >

                                            <span />
                                        </div>
                                        <h4>Geo Location Info</h4>
                                        {/* <div
													style={{
														display: "flex",
														margin: "10px 0px",
														justifyContent: "space-between"
													}}
												>

													<span>Address -</span>
													<span style={{ marginLeft: 10,wordBreak:'break-all' }}>
														{'address'}
													</span>
												</div> */}
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Latitude -</span>
                                            <span>
                                                <b>
                                                    {lat}
                                                </b>
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Longitude -</span>
                                            <span>
                                                <b>
                                                    {long}
                                                </b>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >

                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100%" style={{ padding: 15 }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "5px 0px",
                                                justifyContent: "space-between",
                                                borderBottom: "2px solid blue",
                                                paddingBottom: 5
                                            }}
                                        >
                                            <span>
                                                <h4>Penny Drop Info</h4>
                                            </span>
                                            <span />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Id -</span>
                                            <span>
                                                {digiopenny?.id}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Verified Status -</span>
                                            <span style={{ wordBreak: 'break-all' }}>

                                                <p>{krnJourney?.penny_drop_status == 1 ? digiopenny?.verified ? 'true' : 'false' : 'false'}</p>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Date &amp; Time -</span>
                                            <span>
                                                {digiopenny?.verified_at}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Beneficiary Name With Bank -</span>
                                            <span style={{ wordBreak: 'break-all' }}>
                                                <b>
                                                    {krnJourney?.penny_drop_status == 1 ? digiopenny?.beneficiary_name_with_bank : ''}
                                                </b>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Pan-Card Full Name -</span>
                                            <span style={{ wordBreak: 'break-all' }}>
                                                {krnJourney?.full_name}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> :

                    <div
                        ref={divRef} style={{
                            opacity: '1',
                            position: 'absolute',
                            left: '-9999px',
                            width: '300px',
                            fontSize: '10px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }} className='screenshot'>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="100%" style={{ padding: 15 }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "5px 0px",
                                                justifyContent: "space-between",
                                                borderBottom: "2px solid blue",
                                                paddingBottom: 5
                                            }}>
                                            <span>
                                                <h4>KRA Info</h4>
                                            </span>
                                            <span />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>KRA-Verification Code -</span>
                                            <span>
                                                {KraJson?.APP_PAN_SUMM?.APP_OTHKRA_BATCH}
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>KRA-Permanent Address Proof Type-</span>
                                            <span>
                                                {findAddressProof(KraJson?.KYC_DATA?.APP_COR_ADD_PROOF)}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>KRA-Permanent Address Proof -</span>
                                            <span>
                                                {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD_REF).length == 0 ? 'NA' : KraJson?.KYC_DATA?.APP_PER_ADD_REF}

                                                {/* {typeof kraData?.json?.KYC_DATA?.APP_PER_ADD_REF == 'object' ?"Na":kraData?.json?.KYC_DATA?.APP_PER_ADD_REF} */}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>KRA-Correspondence Address Proof Type -</span>
                                            <span>
                                                {findAddressProof(KraJson?.KYC_DATA?.APP_COR_ADD_PROOF)}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>KRA-Correspondence Address Proof -</span>
                                            <span>
                                                {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD_REF).length == 0 ? 'NA' : KraJson?.KYC_DATA?.APP_PER_ADD_REF}


                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Permanent Addresss - </span>
                                            <span style={{ wordBreak: 'break-all' }}>
                                                {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD1).length == 0 ? '' : KraJson?.KYC_DATA?.APP_PER_ADD1}<br />
                                                {Object.keys(KraJson?.KYC_DATA?.APP_PER_ADD2).length == 0 ? '' : KraJson?.KYC_DATA?.APP_PER_ADD2}

                                                {/* {kraData?.json?.KYC_DATA?.APP_PER_ADD1}  <br /> {kraData?.json?.KYC_DATA?.APP_PER_ADD2} */}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Correspondence Address - </span>
                                            <span style={{ wordBreak: 'break-all' }}>
                                                {Object.keys(KraJson?.KYC_DATA?.APP_COR_ADD1).length == 0 ? '' : KraJson?.KYC_DATA?.APP_COR_ADD1}<br />
                                                {Object.keys(KraJson?.KYC_DATA?.APP_COR_ADD2).length == 0 ? '' : KraJson?.KYC_DATA?.APP_COR_ADD2}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>PAN no -</span>
                                            <span>
                                                {KraJson?.KYC_DATA?.APP_PAN_NO}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Latitude -</span>
                                            <span>
                                                <b>
                                                    {lat}
                                                </b>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Longitude -</span>
                                            <span>
                                                <b>
                                                    {long}
                                                </b>
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="100%" style={{ padding: 15 }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "5px 0px",
                                                justifyContent: "space-between",
                                                borderBottom: "2px solid blue",
                                                paddingBottom: 5
                                            }}
                                        >
                                            <span>
                                                <h4>Penny Drop Info</h4>
                                            </span>
                                            <span />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Id -</span>
                                            <span>
                                                {digiopenny?.id}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Verified Status -</span>
                                            <span>
                                                <p>{krnJourney?.penny_drop_status == 1 ? digiopenny?.verified ? "true" : 'false' : 'false'}</p>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Date &amp; Time -</span>
                                            <span>
                                                {digiopenny?.verified_at}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span>Beneficiary Name With Bank -</span>
                                            <span style={{ wordBreak: 'break-all' }}>
                                                <b>
                                                    {krnJourney?.penny_drop_status == 1 ? digiopenny?.beneficiary_name_with_bank : ''}
                                                </b>
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                margin: "10px 0px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span >Pan-Card Full Name -</span>
                                            <span style={{ wordBreak: 'break-all' }}>
                                                {krnJourney?.full_name}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                : null
            }
        </BaseLogin >
    );
};
export default TakeSelfie;

