import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { pngAssets, svgAssets } from '../../assets/asset';
import NewLoader from '../../assets/gif/loaderfloting.gif'

const widgetIconBgColor = {
	'All Applications': {
		bgColor: '#E0E3FF',
		icon: pngAssets.dashboard.all,
		route: '/all'
	},
	'Verified Applications': {
		bgColor: '#FFF4B8',
		icon: pngAssets.dashboard.verified,
		route: '/verified'
	},
	'Rejected Applications': {
		bgColor: '#FFF1D7',
		icon: pngAssets.dashboard.rejected,
		route: '/rejected'
	},
	'Pending Verification': {
		bgColor: '#FFF1D7',
		icon: pngAssets.dashboard.pending,
		route: '/pending'
	},
	Resubmitted: {
		bgColor: '#CBFFDD',
		icon: pngAssets.dashboard.resubmitted,
		route: '/resubmitted'
	},
	'Re-Activation': {
		bgColor: '#C3FFB9',
		icon: pngAssets.dashboard.reActive,
		route: '/incomplete'
	}
};


export const ApplicationWidget = ({ title, numberOfApplications, graph, status }) => {
	const navigate = useNavigate();
	return (
		<div style={{ cursor: 'pointer' }}
			onClick={() => {
				if (title && (title === 'Verified Applications' || title === 'Kyc Done')) {
					navigate('application/verified');
				} else if (title && (title === 'Pending Verification' || title === 'Work in Progress')) {
					navigate('/application/pending');
				} else if (title && title === 'SendToLD Pending') {
					navigate('/e-sign-report');
				}
			}}
			className="w-full px-6 py-5 bg-white rounded-[20px] shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
			<div className="flex justify-between mb-2.5">
				{

					<div>
						<span className="text-[#868686] leading-6 font-medium font-poppinsMedium">{title}</span>
						{status ? (
							<img src={NewLoader} alt="Loading..." style={{ height: '50px' }} />
						) : (
							<h6 className="text-[32px] leading-[48px] font-semibold font-poppinsSemiBold">
								{numberOfApplications}
							</h6>
						)}
					</div>
				}
				{

					<div className="p-5 bg-[#E0E3FF] rounded-full"
						style={{
							backgroundColor: widgetIconBgColor?.[title]?.bgColor
						}}
					>
						<img alt="application_icon" src={pngAssets.dashboard.verified}></img>
					</div>
				}

			</div>
			<div className="flex items-end justify-between">
				{

					<>

					</>
				}

			</div>
		</div>
	);
};



