import { all, call, put, takeEvery } from 'redux-saga/effects';
import { uploadImage } from '../../redux/actions/kyc/kyc.action';
import { changeStep6ErrorAction, uploadBankProof, callSendUploadIncomeProofLink, sendUploadIncomeProofLinkAction, uploadSalarySlip } from '../../redux/actions/kyc/step6.action';
import { uploadSelfie, callSendSelfieLink, sendSelfieLinkAction } from '../../redux/actions/kyc/step7.action';
import { callSendDkycLink, setSendDkycLinkAction, callCheckDocumentIsUploaded, isBankProofUploadedAction, isIncomeProofUploadedAction, isSelfieUploadedAction, isSignatureUploadedAction } from '../../redux/actions/kyc/kyc.action';
import { callSendSignatureLink, sendSignatureLinkAction } from '../../redux/actions/kyc/step8.action';
import { finalSubmit, callUpdateSegmentInfo, uploadSignature } from '../../redux/actions/kyc/step8.action';
import { postApi, postFormApi, postApiWithoutLoader } from '../../services/common';
import { toast } from 'react-toastify';

function* uploadImageSaga({ payload }) {
try {
		const data = yield call(postFormApi, payload, '/uploadfiles');
		console.log("uploadImageSaga", data);
	} catch (err) {
		console.log(err);
	};
};

function* callSendDkycLinkSaga({ payload }) {
	try {
		const data = yield call(postFormApi, payload, '/send-file-upload-link');
		yield put(setSendDkycLinkAction(true));
	} catch (err) {
		console.log(err);
	};
};

function* callCheckIsDocumentUploadedSaga({ payload }) {
	try {
		const data = yield call(postApiWithoutLoader, payload, '/is-file-uploaded');
		if (data.data) {
			if (payload.document_type == 'Bank Proof') {
				yield put(isBankProofUploadedAction(data?.data));
			} else if (payload.document_type == 'Income Proof') {
				yield put(isIncomeProofUploadedAction(data?.data));
			} else if (payload.document_type == 'Client Photo') {
				yield put(isSelfieUploadedAction(data?.data));
			} else if (payload.document_type == 'Client Signature') {
				yield put(isSignatureUploadedAction(data?.data));
			}
		}
	} catch (err) {
		console.log(err);
	};
};

function* callSendSelfieLinkSaga({ payload }) {
	try {
		const data = yield call(postFormApi, payload, '/send-file-upload-link');
		console.log(data);
		yield put(sendSelfieLinkAction(true));
	} catch (err) {
		console.log(err);
	};
};

function* callSendSignatureLinkSaga({ payload }) {
	try {
		const data = yield call(postFormApi, payload, '/send-file-upload-link');
		console.log(data);
		yield put(sendSignatureLinkAction(true));
	} catch (err) {
		console.log(err);
	};
};

function* callSendUploadIncomeProofLinkSaga({ payload }) {
	try {
		const data = yield call(postFormApi, payload, '/send-file-upload-link');
		console.log('data==>', data);
		const isError = data.data.error;
		const isErrorMessage = data.data.message;

		if (isError) {
			if (isErrorMessage.mobile) {
				yield put(changeStep6ErrorAction({ type: 'incomeProof', isError: true, errorText: 'Mobile number is required.' }));
			} else {
				yield put(changeStep6ErrorAction({ type: 'incomeProof', isError: true, errorText: 'Something wents wrong.' }));
			}
		} else {
			yield put(changeStep6ErrorAction({ type: 'incomeProof', isError: false, errorText: '' }));
			yield put(sendUploadIncomeProofLinkAction(true));
		}
		// yield put(changeStep6ErrorAction({ type: 'panInput', isError: true, errorText: 'Something went wrong!' }));
	} catch (err) {
		console.log(err);
	};
};

function* callUpdateSegmentInfoSaga({ payload, callback }) {
	try {
		const data = yield call(postApi, payload, '/UpdateSegmentInfo');
		console.log(data, 'data');
		if (data?.status) {
			yield call(callback, data?.data);
		}
		else {
			toast()
			// yield call(callback, {});
		}
	} catch (err) {
		console.log(err);
	};
};

function* finalSubmitSaga({ payload, callback }) {
	try {
		const data = yield call(postApi, payload, '/final-submit');
		yield call(callback, data?.data);
	} catch (err) {
		console.log(err);
	};
};

function* kycSaga() {
	yield all([
		takeEvery(uploadImage, uploadImageSaga),
		takeEvery(uploadBankProof, uploadImageSaga),
		takeEvery(uploadSalarySlip, uploadImageSaga),
		takeEvery(uploadSelfie, uploadImageSaga),
		takeEvery(callSendDkycLink, callSendDkycLinkSaga),
		takeEvery(callCheckDocumentIsUploaded, callCheckIsDocumentUploadedSaga),
		takeEvery(callSendSelfieLink, callSendSelfieLinkSaga),
		takeEvery(callSendSignatureLink, callSendSignatureLinkSaga),
		takeEvery(callSendUploadIncomeProofLink, callSendUploadIncomeProofLinkSaga),
		takeEvery(uploadSignature, uploadImageSaga),
		takeEvery(finalSubmit, finalSubmitSaga),
		takeEvery(callUpdateSegmentInfo, callUpdateSegmentInfoSaga)
	]);
}

export default kycSaga;
