import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { svgAssets } from '../../assets/asset';
import { BaseLogin } from '../../components/login/BaseLogin';
import { changeLoginErrorAction, changeLoginInputAction, resetPasswordAction, showPasswordAction } from '../../redux/actions/auth.action';
import { validatePassword } from '../../utils/verifyInput';
import { toast } from 'react-toastify';

export const ResetPassword = () => {
	const location = useLocation();
	const pathSegments = location.pathname.split('/').filter(Boolean);
	
	
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(state => state?.user || {});
	

	const handleInput = ({ type, value }) => {
		dispatch(changeLoginInputAction({ type, value }));
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeLoginErrorAction({ type, isError, errorText }));
	};

	const loginApiCallback = ({ status, success, message }) => {
		if (status === 200 && success) {
			toast('Congratulations! You have reset your password successfully.')
			setTimeout(() => {
			navigate('/login');
				
			},3000);
		} else {
			alert(message);
		}
	};

	const handleLogin = () => {
		const isValidPassword = validatePassword(user.password.value || '');
		const isValidConfirmPassword = validatePassword(user.confirmPassword.value || '') && user.password.value.toUpperCase() === user.confirmPassword.value.toUpperCase();
if (!isValidPassword) {
			handleInputError({ type: 'password', isError: true, errorText: 'Password should have atleast 8 characters!' });
		} 
		if (!isValidConfirmPassword) {
			handleInputError({ type: 'confirmPassword', isError: true, errorText: 'Password doesn\'t match' });
		}
		
		else {
			dispatch(resetPasswordAction({
				UserName:pathSegments[1],
				Key:pathSegments[2],
				Password: user.password.value,
				ConfirmPassword: user.password.value,
			
			}, loginApiCallback));
		}
	};

	return (
		<BaseLogin>
			<div className="w-full max-h-max mt-[76px] flex flex-col justify-center">
				<h6 className="mb-7 text-black text-2xl capitalize font-semibold font-poppinsSemiBold">Reset Password</h6>
				<div className="mb-16">
					<div className="flex flex-col mb-8">
						<span className="mb-3 flex items-center leading-6 font-medium font-poppinsMedium">
							<img
								className="max-h-[22px] mr-1.5 object-contain"
								alt="input_icon"
								src={svgAssets.login.password}
							/>
							<span className="mr-1">Password</span>
							<span className="text-[#FF4343]">*</span>
						</span>
						<div className="relative">
							<input
								className="w-full h-[56px] px-4 text-[#353535]  border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
								type={!user.password.showPassword ? 'password' : 'text'}
								value={user.password.value}
								onChange={(e) => handleInput({ type: 'password', value: e.target.value })}
								placeholder="Please enter password"
								required
								autoFocus={true}
								maxLength={15}
								
							/>
							<img
								className="cursor-pointer absolute right-3 top-0 bottom-0 mt-auto mb-auto"
								width={24} alt="hide_password"
								src={user.password.showPassword ? svgAssets.login.showPassword : svgAssets.login.hidePassword}
								onClick={() => dispatch(showPasswordAction({ type: 'password' }))}
							/>
						</div>
						{
							user.password.isError && (
								<span className=" mt-2 text-[12px] text-red-400 font-medium font-poppinsMedium">
									{user.password.errorText}
								</span>)
						}
					</div>
					<div className="flex flex-col mb-8">
						<span className="mb-3 flex items-center leading-6 font-medium font-poppinsMedium">
							<img
								className="max-h-[22px] mr-1.5 object-contain"
								alt="input_icon"
								src={svgAssets.login.password}
							/>
							<span className="mr-1">Confirm Password</span>
							<span className="text-[#FF4343]">*</span>
						</span>
						<div className="relative">
							<input
								className="w-full h-[56px] px-4 text-[#353535]  border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
								type={!user.confirmPassword.showPassword ? 'password' : 'text'}
								value={user.confirmPassword.value}
								onChange={(e) => handleInput({ type: 'confirmPassword', value: e.target.value })}
								placeholder="Enter confirm password"
								maxLength={15}
								required
							/>
							<img
								className="cursor-pointer absolute right-3 top-0 bottom-0 mt-auto mb-auto"
								width={24} alt="hide_password"
								src={user.confirmPassword.showPassword ? svgAssets.login.showPassword : svgAssets.login.hidePassword}
								onClick={() => dispatch(showPasswordAction({ type: 'confirmPassword' }))}
							/>
						</div>
						{
							user.confirmPassword.isError && (
								<span className=" mt-2 text-[12px] text-red-400 font-medium font-poppinsMedium">
									{user.confirmPassword.errorText}
								</span>)
						}
					</div>
				</div>
				<div className="flex justify-center">
					<button
						className="min-w-max px-4 py-3 flex items-center justify-center rounded-[10px] text-white bg-[#5367FC] active:bg-[#3d54fc] transition duration-150 ease-in-out"
						onClick={() => handleLogin()}
					>
						{/* <img className="mr-2.5" alt="login_screen_enter_img" src={svgAssets.login.loginEnter}></img> */}
						<span className="w-full font-poppinsRegular">Reset Password</span>
					</button>
				</div>
			</div>
		</BaseLogin>
	);
};
