import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dayjs from "dayjs";
import CustomDatePicker from "../../components/inputs/CustomDatePicker";
import { MyPopover } from "../../components/popover/Popover";
import { TableProgressBar } from "../../components/progressBar/TableProgressBar";
import { PageCount } from "../../components/reactTable/PageCount";
import { ReactTable } from "../../components/reactTable/ReactTable";
import { reactTableStatusValue } from "../../components/reactTable/reactTableData";
import { ReactTableHeader } from "../../components/reactTable/ReactTableHeader";
import {
  PopoverChildComp,
  PopoverParentComp,
} from "../../components/reactTable/ReactTablePopupBtn";
import { SearchBarTable } from "../../components/searchbar/SearchBarTable";
import { verificationSteps } from "../../containers/kyc/kycData";
import {
  deleteClientAction,
  getApplicationAction,
} from "../../redux/actions/application.action";
import { svgAssets } from "../../assets/asset";
import FilterPopover from "./filterPopover";
import moment from "moment";
import { ReAssignModal } from "../../components/modal/admin/ReAssignModal";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchBar } from "../../components/searchbar/SearchBar";
import { PopoverChildComp1, PopoverParentComp1 } from "../../components/reactTable/ReactTabaleAction";

export const AllApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [steps, setSteps] = useState(verificationSteps);
  const userDetail = useSelector(
    (state) => state?.user?.userDetails?.[0] || {}
  );


  const location = useLocation();
  const SearchValue = location.state?.value || "";

  const applicationData = useSelector(
    (state) => state?.application?.allApplication?.data || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const currentDate = dayjs().format("DD-MM-YYYY");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(currentDate);
  const [isDisable, setDisable] = useState(false);
  const [dateLimit, setDateLimit] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [clientId, setClientId] = useState();
  const handleReassignModal = (data) => {
    setIsModelOpen(true);
    setClientId(data.ClientId);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    if (fromDate === "") {
      setDisable(true);
    }
  }, []);

  console.log(userDetail?.role_id == 6,'applicationData');
  const handleDelete = (data) => {
    dispatch(
      deleteClientAction({
        PanNo: data?.PanNo || "",
        Mobile: data?.mobile || "",
      })
    );
    setTimeout(() => {
      dispatch(
        getApplicationAction("allApplication", {
          UserId: userDetail?.user_id,
          RoleId: userDetail?.role_id,
          KYCStatus: "total",
          start: 1,
          end: 50,
          search: "",
        })
      );
    }, 5000);
  };

  const columns = [
    {
      Header: "Client",
      accessor: "clientName",
      minWidth: 100,
      Cell: ({ row }) =>
        `${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
        }`,

      sortable: true,
    },
    {
      Header: "Pan",
      accessor: "pan",
      minWidth: 130,
      Cell: ({ row }) => row?.original?.PanNo || "-",
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 130,
      Cell: ({ row }) =>  row?.original?.IsETrade == null?'Bonanza':"Bigul",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      minWidth: 170,
      Cell: ({ row }) =>
        row.original?.created_at &&
        dayjs(row.original?.created_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
      sortable: true,
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      minWidth: 170,
      Cell: ({ row }) =>
        row.original?.updated_at &&
        dayjs(row.original?.updated_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
      sortable: true,
    },
    {
      Header: "Status",
      accessor: "status",
      minWidth: 120,
      Cell: ({ row }) => (
        <div
          className="min-w-[100px]  px-3 py-1 inline-flex justify-center rounded-[6px] text-xs font-medium font-poppinsMedium"
          style={{
            backgroundColor: '#D7FFE2'
          }}
        >
          <p style={{ color: 'black' }}>{row.original?.status?.charAt(0).toUpperCase() + row.original?.status?.slice(1)}</p>
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",

      minWidth: 60,
      Cell: ({ row }) => (
        <MyPopover
          PopoverParentComp={PopoverParentComp1}
          PopoverChildComp={PopoverChildComp1}
          isVerify={(row.original?.status == 'reviewpending') || (row.original?.status == 'pending') || (row.original?.status == 'myetradepending')?true:false}
          viewUrl1="/application/view-application"
          applicationType="allApplication"
          selectedData={row.original}
          redirectUrl="/application/new-application"
          // isView={userDetail?.role_id == 6 ? true :row.original?.status == 'Reject' || row.original?.status == 'reject' || row.original?.status =="Rejected" ?false :true}
          isView={true}
          viewUrl={row.original?.IsCompleted == null ? 'Incompte' : row.original.FinalReject == 1 ? 'final-Reject' : row.original.IsCompleted == "1" && row.original.IsReject == "1" && row.original.FinalVerify == null && row.original.FinalReject == null ? 'Reject' : 'verified'}
          isDelete={true}
          handleReassignModal={handleReassignModal}
          handleClick={() => handleDelete(row.original)}
          handleEdit={(row.original?.status !== 'verified') && (row.original?.status !== 'myetradepending') && (row.original?.status !== 'pending')&&(row.original?.status !== 'reviewpending') ? false : true}
        />
      ),
    },
  ];

  const handleApplication = ({ start, end }) => {
    dispatch(
      getApplicationAction("allApplication", {
        UserId: userDetail?.user_id,
        RoleId: userDetail?.role_id,
        // KYCStatus: "total",
        start: start || 1,
        end: end || 20,
        search: SearchValue,
      })
    );
  };

  useEffect(() => {
    handleApplication({ start: 1, end: 50, search: "" });
  }, []);

  return (
    <>
      <div className="w-full flex flex-col ">
        <div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
          <div className="flex flex-col md:flex-row gap-y-5 items-center justify-between mb-7">
            <ReactTableHeader
              title="All Applications"
              numberOfApplications={applicationData && applicationData?.length}
            />
            {/* <PageCount /> */}
          </div>

          {isLoading ? (
            <>
              <SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
                <Skeleton
                  count={10}
                  style={{ height: "30px", marginTop: "10px" }}
                  borderRadius={10}
                />
              </SkeletonTheme>
            </>
          ) : (
            <ReactTable
              columns={columns}
              data={applicationData.length >0?applicationData:[]  || []}
              displayBlock={true}
            />
          )}
        </div>
      </div>

    </>
  );
};
