import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { SendESignBtn } from '../../components/buttons/SendESignLink';
import { sendEquityEsignAction } from '../../redux/actions/kyc/review.action';
import { postApi, postApiWithoutLoader } from '../../services/common';
import complete from '../../../src/assets/png/kycSuccess.png'
import NewLoader from '../../assets/gif/Dark Theme/Loading_big.gif';
export const ClientEsign = () => {
	const dispatch = useDispatch();
	const { slug, clientId } = useParams();

	const [isSendLink, setIsSendLink] = useState(false);
	const euitySignRes = useSelector(state => state?.reviewData?.equityEsignRes || {});
	const eSignLinkDataRes = useSelector(state => state?.reviewData?.equityEsignRes.data || {});
	const [isSendEquitSign, setIsSendEquitSign] = useState(false);
	const [isStopNewTabEquitSign, setIsStopNewTabEquitSign] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const [kraCHeck, setCheckKra] = useState(false);
	const [panCheck, setPan] = useState({});
	const [deseble, setDeseble] = useState(false);
	


	console.log(panCheck, 'panCheck');
	useEffect(() => {
		if (kraCHeck) {
			setIsModalOpen(true);
			ModalClose()
		}
	}, [kraCHeck]);


	const ModalClose = async () => {
		try {
			const payload = {
				UserId: userDetail?.user_id,
				PanNo: panCheck?.pan || panCheck?.PanNo,
				roleId: userDetail?.role_id,
				rm_journey: 1

			}
			const response = await postApi(payload, '/SaveClientSatus');
			if (response?.response) {
				setIsModalOpen(false);
				setCheckKra(false)
			}

		}
		catch (error) {
			setIsModalOpen(false);
			setCheckKra(false)
		}


	}
	const handleEquityEsignBtn = () => {
		setDeseble(true)

		dispatch(sendEquityEsignAction(
			{
				ClientId: panCheck?.client_id,
				channel: panCheck?.IsETrade == 90 ? 'Bigul' : 'Bonanza',

			}, useCallback1));

	};

	const useCallback1 = (res) => {
		setDeseble(false)

		if (res.success == true) {
			setIsSendLink(true);
			setIsSendEquitSign(true);
		}
	}

	//Check euitySignRes
	useEffect(() => {
		let interval;
		if (isSendEquitSign) {
			interval = setInterval(() => {
				setIsStopNewTabEquitSign(true);
				fetchData()

			}, 10000);
		}
		return () => clearInterval(interval);

	}, [isSendEquitSign]);
	useEffect(() => {
		if (clientId) {
			fetchData()

		}

	}, []);


	const fetchData = async () => {

		try {
			const payload = {
				mobile: clientId,
				esign: 1
			}
			const response = await postApiWithoutLoader(payload, '/getKycJourney');

			if (response?.data?.success) {
				setPan(response?.data?.data[0])
				if (response?.data?.data[0]?.esign_status == 1) {
					setCheckKra(true)
					// setKraData(response?.data?.data[0]?.esign_status)
				}
			}
			else {
				console.error('Error fetching data:');
			}
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		if (euitySignRes?.success && eSignLinkDataRes?.URLL && isSendLink) {
			setTimeout(() => {
				window.open(eSignLinkDataRes.URLL, '_blank');
			}, 1000);
		}
	}, [euitySignRes?.success && eSignLinkDataRes?.URLL && isSendLink])
	return (
		<>
		{
		deseble ?
					<img src={NewLoader} alt="Loading..." style={{
						// width: '50%',
						height: '50%',
						maxWidth: '50%',
						display: 'flex',
						justifyContent: 'center', alignItems: 'center',
						backgroundColor: 'transparent',
						textAlign: 'center',
						marginRight: 'auto',
						marginLeft: 'auto'

					}} />
					:
			<div className="w-full flex items-center justify-center h-screen">
				<div className="w-[500px] py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<h3 className='text-center bold text-black text-2xl border-solid border-black-800 border-b-4 pb-4'>Continue to eSign</h3>

					<div className='text-center w-full'>
						{
							isModalOpen &&
							<>
								<img src={complete} alt='image' />
								<div className="flex justify-end mb-6">
									<div className="bg-[#E0FFE5]  p-4 rounded my-5 ">
										<div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
											<p className={euitySignRes?.success ? 'text-[#007813]' : 'text-[green]'}>{'Esign Done'}</p>
										</div>
										<div className="flex"></div>
									</div>
								</div>
							</>
						}
						<p className='mt-10 '>{!isModalOpen && 'Press Equity eSign button to start eSign'}</p>
						<div className='mt-20 flex items-center justify-center'>
							{
								!isModalOpen &&

								<SendESignBtn handleBtn={handleEquityEsignBtn} name={isSendEquitSign ? 'please wait....' : 'Equity eSign'} />

							}
						</div>
					</div>
					{/* {
                        euitySignRes?.message &&
                        <div className="flex justify-end mb-6">
                            <div className="bg-[#E0FFE5]  p-4 rounded my-5 ">
                                <div className="flex gap-5 font-semibold  font-poppinsSemiBold lg:pl-5 justify-between my-2">
                                    <p className={euitySignRes?.success ? 'text-[#007813]' : 'text-[green]'}>{euitySignRes.message}</p>
                                </div>
                                <div className="flex"></div>
                            </div>
                        </div>
                    } */}
				</div>
			</div>
}
		</>
	);
};
