// import { InitMobileEmail } from './step1/InitMobileEmail';
import { VerifyMobileEmail } from './step1/VerifyMobileEmail';
import { VerifyPan } from './step2/VerifyPan';
import { PersonalDetail } from './step3/PersonalDetail';
import { OccuptionDetail } from './step4/OccupationDetail';
import { VerifyBankDetails } from './step5/VerifyBankDetails';
import { BrokerageStep } from './step6/BrokerageStep';
import { KycFilesSteps } from './step7';
import { UploadSelfie } from './step7/UploadSelfie';
import { UploadSignature } from './step8/UploadSignature';
import { KycSuccess } from './success/KycSuccess';

export const kycSteps = () => [
	{
		label: '1',
		value: 'step1',
		status: 'active',
		isSuccess: true,
		component: <VerifyMobileEmail />
	},
	{
		label: '2',
		value: 'step2',
		status: 'inactive',
		isSuccess: true,
		component: <VerifyMobileEmail />
	},
	{
		label: '3',
		value: 'step3',
		status: 'inactive',
		isSuccess: true,
		component: <VerifyMobileEmail />
	},
	{
		label: '4',
		value: 'step4',
		status: 'inactive',
		isSuccess: true,
		component:<VerifyPan />
	},
	{
		label: '5',
		value: 'step5',
		status: 'inactive',
		isSuccess: true,
		component:<VerifyPan />
	},
	{
		label: '6',
		value: 'step6',
		status: 'inactive',
		isSuccess: true,
		component: <PersonalDetail />
	},
	{
		label: '7',
		value: 'step7',
		status: 'inactive',
		isSuccess: true,
		component:<OccuptionDetail />
	},
	{
		label: '8',
		value: 'step8',
		status: 'inactive',
		isSuccess: true,
		component:<VerifyBankDetails />
	},
	{
		label: '9',
		value: 'step9',
		status: 'inactive',
		isSuccess: true,
		component:<BrokerageStep />
	},
	{
		label: '10',
		value: 'step10',
		status: 'inactive',
		isSuccess: true,
		component: <KycFilesSteps />
	},
	{
		label: '11',
		value: 'step11',
		status: 'inactive',
		isSuccess: true,
		component:<KycFilesSteps />
	},
	// {
	// 	label: '12',
	// 	value: 'step12',
	// 	status: 'inactive',
	// 	isSuccess: true,
	// 	component:<KycFilesSteps />
	// },
	// {
	// 	label: '13',
	// 	value: 'step13',
	// 	status: 'inactive',
	// 	isSuccess: true,
	// 	component:<KycFilesSteps />
	// },
	
	
];

export const verificationSteps = () => [
	{
		label: '1',
		value: 'step1',
		status: 'verifyView',
		isSuccess: true,
		component: <VerifyMobileEmail />
	},
	{
		label: '2',
		value: 'step2',
		status: 'success',
		isSuccess: true,
		component: <VerifyPan />
	},
	{
		label: '3',
		value: 'step3',
		status: 'success',
		isSuccess: true,
		component: <PersonalDetail />
	},
	{
		label: '4',
		value: 'step4',
		status: 'success',
		isSuccess: true,
		component: <OccuptionDetail />
	},
	{
		label: '5',
		value: 'step5',
		status: 'success',
		isSuccess: true,
		component: <VerifyBankDetails />
	},
	{
		label: '6',
		value: 'step6',
		status: 'success',
		isSuccess: true,
		component: <BrokerageStep />
	},
	{
		label: '7',
		value: 'step7',
		status: 'success',
		isSuccess: true,
		component: <UploadSelfie />
	},
	{
		label: '8',
		value: 'step8',
		status: 'success',
		isSuccess: true,
		component: <UploadSignature />
	},
	{
		label: '9',
		value: 'step9',
		status: 'success',
		isSuccess: true,
		component: <KycSuccess />
	}
];
