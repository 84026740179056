import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { svgAssets } from '../../assets/asset';
import { MyPopover } from '../../components/popover/Popover';
import { StepProgressBar } from '../../components/progressBar/ProgressBar';
import { ReactTable } from '../../components/reactTable/ReactTable';
import { PopoverChildComp, PopoverParentComp } from '../../components/reactTable/ReactTablePopupBtn';
import { ApplicationWidget } from '../../components/widgets/Applications';
import { Date } from '../../components/widgets/Date';
import { getApplicationAction, getApplicationStatusAction, setApplicationStatusAction } from '../../redux/actions/application.action';
import { verificationSteps } from '../kyc/kycData';
import {
	startLoaderAction,
	stopLoaderAction,
} from "../../redux/actions/app.action";
import { store } from "../../redux/store";
import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../components/searchbar/SearchBar';
import { toast } from 'react-toastify';
import { postApi, postApiWithoutLoader } from '../../services/common';
import { Modal } from 'antd';


export const VerifierDashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const check_status = localStorage.getItem('checkStatus');
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const { pendingApplication, resubmittedApplication, applicationStatus } = useSelector(state => state?.application || []);
	const [steps, setSteps] = useState(verificationSteps || []);
	const [customDate, setCustomDate] = useState(null);
	const [selectedDateLabel, setSelectedDateLabel] = useState('Custom Date');
	const [isModalOpen, setIsmodel] = useState(check_status == "true" ? true : false);
	const [mobile, setMobile] = useState(userDetail?.mobile.length > 9 ? userDetail?.mobile : '')
	const [email, setEmail] = useState(userDetail?.email || '')
	console.log(check_status, 'check_status');
	const datePickerRef = useRef(null);
	const [statusCheck, setCheckStatus] = useState(true)
	const handleCustomDateClick = () => {
		datePickerRef.current.setOpen(true);
	};


	const formatDate = date => {
		if (date) {
			return date.toLocaleDateString();
		}
		return "Custom Date" || customDate;
	};


	const handleTodayFilter = (applicationType, status) => {
		const currentDate = dayjs().format('DD MMM YYYY');
		dispatch(getApplicationAction(applicationType, {
			Date: currentDate,
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			KYCStatus: "reviewpending",
			start: 1,
			end: 4,
			search: ''
		})
		)
		dispatch(getApplicationStatusAction(
			{
				Date: currentDate,
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id
			}));
	};

	const handleYesterdayFilter = (applicationType, status) => {
		const yesterday = dayjs().subtract(1, 'day').format('DD MMM YYYY');
		dispatch(getApplicationAction(applicationType, {
			Date: yesterday,
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			KYCStatus: "reviewpending",
			start: 1,
			end: 4,
			search: ''
		}));
		dispatch(getApplicationStatusAction(
			{
				Date: yesterday,
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id
			}));
	};


	const handleCustomDateFilter = (date, applicationType, status) => {
		const formattedDate = dayjs(date).format('DD MMM YYYY');
		setCustomDate(date);
		setSelectedDateLabel(formattedDate);
		dispatch(getApplicationAction(applicationType, {
			Date: customDate,
			UserId: userDetail.user_id,
			RoleId: userDetail.role_id,
			KYCStatus: "reviewpending",
			start: 1,
			end: 4,
			search: ''
		}));
		dispatch(getApplicationStatusAction(
			{
				Date: customDate,
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id
			}));
	};

	useEffect(() => {
		if (pendingApplication.data.length > 0) {
			const currentDate = dayjs().format('DD MMM YYYY');
			const filteredData = pendingApplication.data.filter(item => {
				const createdAt = dayjs(item.created_at, 'DD MMM YYYY');
				return createdAt.isSame(currentDate, 'day');
			});
		}
	}, [pendingApplication.data]);

	const pendingColumn = [
		{
			Header: "Client Name",
			accessor: "clientName",
			minWidth: 230,
			Cell: ({ row }) =>
				`${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
				}`,
		},
		{
			Header: "Pan",
			accessor: "pan",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.PanNo
		},
		{
			Header: 'Type', accessor: 'type', minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.channel ? row?.original?.channel : 'Bonanza'

		},
		{
			Header: "RM Name",
			accessor: "rm",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.rm_name
		},
		{
			Header: "verifier name",
			accessor: "v_name",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.verifier_name
		},


		{
			Header: 'Created At', accessor: 'createdAt', minWidth: 230,
			Cell: ({ row }) =>
				row.original?.created_at && dayjs(row.original?.created_at).format('DD MMM YYYY' + ' ' + 'HH:mm:ss')

		},
		{
			Header: 'Last Updated At', accessor: 'updatedAt', minWidth: 230,
			Cell: ({ row }) =>

				row?.original?.updated_at && dayjs(row?.original?.updated_at).format('DD MMM YYYY' + ' ' + 'HH:mm:ss')

		},


		{
			Header: 'Actions',
			accessor: 'actions',
			minWidth: 230,
			Cell: ({ row }) => (
				<MyPopover
					PopoverParentComp={PopoverParentComp}
					PopoverChildComp={PopoverChildComp}
					isVerify={true}
					redirectUrl="application/new-application"
					applicationType="allApplication"
					selectedData={row.original}
					isView={false}
					viewUrl="application/new-application"
					handleEdit={true}
				/>
			)
		}
	];

	const resubmitColumn = [
		{
			Header: 'Client Name',
			accessor: 'clientName',
			minWidth: 230,
			Cell: ({ row }) => (
				row.original?.UserName
					? row.original.UserName
					: `${row.original?.FName || ''} ${row.original?.MName || ''} ${row.original?.LName || ''}`
			)
		},
		{
			Header: 'PAN', accessor: 'pan', minWidth: 230, Cell: ({ row }) =>
				row?.original?.PanNo
		},
		{
			Header: 'Type', accessor: 'type', minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.channel ? row?.original?.channel : 'Bonanza'
		},

		{
			Header: "RM Name",
			accessor: "rm",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.rm_name
		},
		{
			Header: "verifier name",
			accessor: "v_name",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.verifier_name
		},
		
		{
			Header: 'Created At',
			accessor: 'createdAt',
			minWidth: 230,
			Cell: ({ row }) => (
				row.original?.created_at
			)
		},
		{
			Header: 'Last Updated At',
			accessor: 'updatedAt',
			minWidth: 230,
			Cell: ({ row }) => (
				row.original?.updated_at
			)
		},

		// { Header: 'RM', accessor: 'rm', minWidth: 100 },
		// { Header: 'Verifier1', accessor: 'verifier1', minWidth: 100 },
		// { Header: 'Verifier2', accessor: 'verifier2', minWidth: 100 },
		{
			Header: 'Actions',
			accessor: 'actions',
			minWidth: 230,
			Cell: ({ row }) => (
				<MyPopover
					PopoverParentComp={PopoverParentComp}
					PopoverChildComp={PopoverChildComp}
					isView={true}
					selectedData={row.original}
					viewUrl="application/view-application"
					handleEdit={true}
				/>
			)
		}
	];

	const handleApplication = ({ start, end, search }) => {
		dispatch(
			getApplicationAction("pendingApplication", {
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id,
				// KYCStatus: "pending",
				start: start || 1,
				end: end || 20,
				search: search || "",
			})
		);

		dispatch(
			getApplicationAction("resubmittedApplication", {
				UserId: userDetail.user_id,
				RoleId: userDetail.role_id,
				// KYCStatus: "pending",
				start: start || 1,
				end: end || 20,
				search: search || "",
			})
		);
	};


	useEffect(() => {
		fetchData1()
		// dispatch(getApplicationStatusAction({ UserId: userDetail.user_id, RoleId: userDetail.role_id }));
		dispatch(setApplicationStatusAction({}));
		handleApplication({ applicationType: 'pendingApplication' });
		handleApplication({ applicationType: 'resubmittedApplication' });
	}, []);
	const fetchData1 = async () => {
		setCheckStatus(true)
		try {
			const payload = {
				UserId: userDetail?.user_id, RoleId: userDetail?.role_id
			}
			const response = await postApiWithoutLoader(payload, '/GetTotalApplicationStatus');
			if (response?.data.data) {
				setCheckStatus(false)
				dispatch(setApplicationStatusAction({ data: response?.data.data || [] }))

			}
			else {
				toast('something went wrong')


			}
		}
		catch (error) {
			toast('something went wrong')


		}
	};

	const widgetIconBgColor = {
		'Pending': {
			bgColor: '#E0E3FF',
			// icon: pngAssets.dashboard.all,
			route: '/pending'
		},

		'Reubmit': {
			bgColor: '#C3FFB9',
			// icon: pngAssets.dashboard.reActive,
			route: '/resubmitted'
		}
	};

	
const handleUpdate = async () => {
		try {
			if (!mobile || mobile.length < 10 || mobile.length > 10) {
				toast('Please enter a valid mobile number');
				return;
			}
			if (!isValidEmail(email)) {
				toast('Please enter a valid email address');
				return;
			}
			const payload = {
				user_id: userDetail?.user_id,
				user_mobile: mobile,
				user_email: email
			};
			const response = await postApi(payload, '/updateUserProfile');
			if (response?.data?.success === true) {
				localStorage.setItem('checkStatus', false);
				setIsmodel(false);
			} else {
				toast(response?.data?.msg || response.data.message || 'something went wrong');
			}
		} catch (error) {
			// Handle any errors that occur during the execution of the function
			console.error('An error occurred:', error);
			toast('An unexpected error occurred. Please try again later.');
		}
	};
	

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<>
			<div className="mb-[30px] flex">
				<Modal
					title="Please update  your profile to get instant updates"
					className="w-full p-[33px]"
					width={'50%'}
					style={{ textAlign: 'center' }}
					open={isModalOpen}
					centered={true}
					closable={false}
					// onCancel={handleCancel}
					footer={null}
				>
					<div className="mx-auto px-6 py-5 ">
						<div className="flex justify-between mb-2.5">
							{
								<div className="">
									<input
										type='tel'
										className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
										placeholder="Please enter mobile number"
										value={mobile}
										onChange={(e) => setMobile(e.target.value)}
										maxLength={10}

									/>
									<input
										type='text'
										className="mt-5 h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
										placeholder="Please enter email "
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									// autoFocus={true}
									/>
									<div style={{
										background: '#121212',

										height: '50px',
										width: '150px',
										// marginBottom: '30px',
										marginTop: '20px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										alignSelf: 'center', borderRadius: 10, cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto'
									}} onClick={handleUpdate}   >
										<p style={{ color: 'white', fontWeight: 'bold' }}>Update</p>
									</div>
								</div>
							}


						</div>
					</div>
				</Modal>

			</div>
			<div className="mb-[30px] grid lg:grid-cols-3 lg:gap-x-9 gap-y-9">
				<ApplicationWidget title="Bonanza" numberOfApplications={(applicationStatus?.[0]?.TotalReviewPending) ? applicationStatus?.[0]?.TotalReviewPending : 0} graph={svgAssets.dashboard.graph1} status={statusCheck}/>
				<ApplicationWidget title="Bigul" numberOfApplications={(applicationStatus?.[2]?.my_etrade) ? applicationStatus?.[2]?.my_etrade : 0} graph={svgAssets.dashboard.graph2} status={statusCheck} />
				<ApplicationWidget title="Kyc Done" numberOfApplications={(applicationStatus?.[0]?.KYCDone) ? applicationStatus?.[0]?.KYCDone : 0} graph={svgAssets.dashboard.graph2} status={statusCheck} />
			</div>
			<div className="mb-[30px] grid lg:grid-cols-3 lg:gap-x-9 gap-y-9">
				<ApplicationWidget title="Total Reject" numberOfApplications={(applicationStatus?.[0]?.TotalReject) ? applicationStatus?.[0]?.TotalReject : 0} graph={svgAssets.dashboard.graph3} status={statusCheck} />
				<ApplicationWidget title="Work in Progress" numberOfApplications={(applicationStatus?.[0]?.TotalInProgress) ? applicationStatus?.[0]?.TotalInProgress : 0} graph={svgAssets.dashboard.graph1} status={statusCheck} />
				<ApplicationWidget title="SendToLD Pending" numberOfApplications={(applicationStatus?.[0]?.TotalSendToLD) ? applicationStatus?.[0]?.TotalSendToLD : 0} graph={svgAssets.dashboard.graph3} status={statusCheck} />
			</div>
			<div>
				<div className="w-full py-5 px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex items-center justify-between mb-7">
						<div className="lg:flex lg:flex-row lg:items-center items-left">
							<h6 className="font-semibold text-[22px] leading-[33px] mr-2.5">Pending Verification </h6>
							{/* <span className="px-3 py-[1px] rounded-md	shrink items-center font-semibold bg-[#FFF1D7]">{applicationStatus?.[0]?.TotalReviewPending ? (Number(applicationStatus?.[0]?.TotalReviewPending) + Number(applicationStatus?.[2]?.my_etrade)) : 0}</span> */}
						</div>
						<button onClick={() => navigate(`/application${widgetIconBgColor?.['Pending']?.route}`)} className="px-3 py-[1px] rounded-md	flex items-center font-semibold bg-[#EBFFFA] shadow-[0px_3px_16px_rgba(171,171,171,0.25)]">View All</button>
					</div>
					<ReactTable
						columns={pendingColumn}
						data={pendingApplication?.data || []}
						displayBlock={true}
					/>
				</div>
				<div className="w-full py-5 px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
					<div className="flex items-center justify-between mb-7">
						<div className="lg:flex lg:flex-row lg:items-center items-left">
							<h6 className="font-semibold text-[22px] leading-[33px] mr-2.5">Resubmitted</h6>
							{/* <span className="px-3 py-[1px] rounded-md	shrink items-center font-semibold bg-[#FFF1D7]">{applicationStatus?.[0]?.TotalRMRework}</span> */}
						</div>
						<button onClick={() => navigate(`/application${widgetIconBgColor?.['Reubmit']?.route}`)} className="px-3 py-[1px] rounded-md	flex items-center font-semibold bg-[#EBFFFA] shadow-[0px_3px_16px_rgba(171,171,171,0.25)]">View All</button>

						{/* <button  className="px-3 py-[1px] rounded-md	flex items-center font-semibold bg-[#EBFFFA] shadow-[0px_3px_16px_rgba(171,171,171,0.25)]">View All</button> */}
					</div>
					<ReactTable
						columns={resubmitColumn}
						data={resubmittedApplication?.data || []}
						displayBlock={true}
					/>
					{/* {
						resubmittedApplication?.data?.length === 0 && (<div className="w-full h-[150px] text-[15px] text-[#9A9A9A] tracking-wide leading-[22px] font-medium font-poppinsMedium inline-flex items-center justify-center">
							No Data Found!
						</div>)
					} */}
				</div>
			</div>
		</>
	);
};

