import { combineReducers } from 'redux';
import { appReducer } from './app.reducer';
import { applicationReducer } from './application.reducer';
import { userReducer } from './auth.reducer';
import { kycReducer } from './kyc/kyc.reducer';
import { reviewApplicationReducer } from './kyc/review.reducer';
import { kycStep1Reducer } from './kyc/step1.reducer';
import { kycStep2Reducer } from './kyc/step2.reducer';
import { kycStep3Reducer } from './kyc/step3.reducer';
import { kycStep4Reducer } from './kyc/step4.reducer';
import { kycStep5Reducer } from './kyc/step5.reducer';
import { kycStep6Reducer } from './kyc/step6.reducer';
import { kycStep7Reducer } from './kyc/step7.reducer';
import { kycStep8Reducer } from './kyc/step8.reducer';
import { userListReducer } from './user.reducer';
import { toggleReducer } from './toggleNavBar.reducer';
import { dkycStep1Reducer } from './dkyc/step1.reducer';
import { dkycStep2Reducer } from './dkyc/step2.reducer';
import { dkycStep3Reducer } from './dkyc/step3.reducer';
import { dkycStep4Reducer } from './dkyc/step4.reducer';
import { dkycReducer } from './dkyc/dkyc.reducer';
import { verifyApplicationReducer } from './kyc/verify.reducer';
import { eSignReportReducer } from './e-sign-report.reducer';
import { manageuserReducer } from './manageuser.reducer';


export default combineReducers({
	app: appReducer,
	user: userReducer,
	kyc: kycReducer,
	kycStep1: kycStep1Reducer,
	kycStep2: kycStep2Reducer,
	kycStep3: kycStep3Reducer,
	kycStep4: kycStep4Reducer,
	kycStep5: kycStep5Reducer,
	kycStep6: kycStep6Reducer,
	kycStep7: kycStep7Reducer,
	kycStep8: kycStep8Reducer,
	dkyc: dkycReducer,
	dkycStep1: dkycStep1Reducer,
	dkycStep2: dkycStep2Reducer,
	dkycStep3: dkycStep3Reducer,
	dkycStep4: dkycStep4Reducer,
	reviewData: reviewApplicationReducer,
	verifyData: verifyApplicationReducer,
	application: applicationReducer,
	e_signReport: eSignReportReducer,
	userList: userListReducer,
	toggle: toggleReducer,
	manageUser: manageuserReducer,
});
