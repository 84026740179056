import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const Segment = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
   
    useEffect(() => {
        
        const url = 'http://14.141.71.10:9494/income/'; 
        const url2 = 'https://bigul.co/crs-dashboard'; 

        const openNewWindow = () => {
            window.open(userDetail?.role_id == 6 ?url2: url, '_blank');
        };

        openNewWindow();
    }, [userDetail?. role_id]); 

    return (
        <div>
           
            <p>{userDetail?. role_id == 6 ? "Bigul Reactivation":'Segment & Income' }</p>
        </div>
    );
};
