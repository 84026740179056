
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BaseLogin } from '../../../components/d-kyc/d-kyc';
import '../style.css'
import { pngAssets, svgAssets } from '../../../assets/asset';
import { clearBankDetailsAction, uploadBankDetailsAction } from '../../../redux/actions/dkyc/step1.action';
import { ToastContainer, toast } from "react-toastify";



export const UploadPan = ({ changeStep,TokenData}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [document1, setDocument1] = useState("")
    const [docPassword, setDocPassword] = useState("")
    const DKycData = useSelector(state => state?.dkyc?.userDetails || {});

    const handleDocument = (e) => {
        if (e.target.files[0]) {
            setDocument1(e.target.files[0]);
        }
    }

    const handleInputPassword = (e) => {
        setDocPassword(e.target.value)
    }

    const handleSubmit = () => {
        if (!DKycData?.pan || DKycData?.pan === '') {
            alert("Somthing wents wrong or your link is expired, Please try again !!")
            return
        }
        else if (document1.size > 5 * 1024 * 1024) { // Check if file size is greater than 3MB
            alert("File size exceeds the limit of 5MB. Please choose a smaller image.");
            return;
        }
        else {
            dispatch(uploadBankDetailsAction({
                PanNo: TokenData?.pan || '',
                Document: 'Pan Upload',
                DocumentType: 'NA',
                step:15,
                rejectReUploadFlag:  1 ,
CreatedBy: TokenData?.created_by || "",
                'DocumentImages[]': document1
            },handleCallback));
            
        }
    }
    const handleCallback = (res) => {
        if (res?.success) {
            toast('Your Document upload successfully')
            setTimeout(() => {
                changeStep(0)
            }, 500);
        }
        else {
            toast(res.message)

        }
    }
    const handleClearDocument = () => {
        dispatch(clearBankDetailsAction())
        setDocument1("")
        setDocPassword("")
    }


    return (
        <BaseLogin image={pngAssets.dkyc.leftpart2}>
<ToastContainer autoClose={100}/>
            <div className="flex flex-col justify-between items-center w-full md:w-[70%] h-full lg:mx-auto">
                <div className="w-full">

                    <div className='text-center my-8'>
                        <div className=' my-8'>
                            <h1 className='font-poppinsRegular text-[17px]'> Upload Pan Proof</h1>
                        </div>
                    </div>
                  
                    <div>

                        {
                            document1 &&
                            <div className='mt-5'>
                                <input type="text"
                                    className="input-text-custom border border-[#9E9E9E] text-[#9E9E9E] w-full border-s-4 rounded-[30px] py-3 px-5 cursor-pointer focus:outline-none focus:shadow-none font-poppinsRegular"
                                    accept="image/png, image/jpg, image/jpeg"
                                    placeholder='Enter Document Password, if any'
                                    value={docPassword}
                                    onChange={handleInputPassword}
                                />
                            </div>

                        }

                        <div className="relative mt-8 mb-5">
                            <input id="file-upload" type="file" className="opacity-0 absolute"  accept="image/png, image/jpg, image/jpeg" onChange={handleDocument} />
                            <div className="bg-[#5367FC] flex justify-center items-center gap-x-5 border-s-4 rounded-[30px] py-3 cursor-pointer text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                </svg>

                                <span className="font-medium font-poppinsMedium">Upload Document Image</span>
                            </div>
                        </div>
                        {
                            document1 &&
                            <div className="flex justify-between items-center px-3 w-full">
                                <div className="font-poppinsRegular w-[85%]">
                                    {/* <p className=' text-ellipsis whitespace-nowrap overflow-hidden '>{document1.name}</p> */}
                                    <p className=' text-ellipsis whitespace-nowrap overflow-hidden '>{ document1.size > 5 * 1024 * 1024?<span style={{color:'red'}}>File size exceeds 5MB. Please choose a smaller image.</span>:document1?.name}</p>

                                </div>
                                <div className="bg-[#C9C9C9] rounded-full w-[15px] h-[15px] flex justify-center items-center p-[3.5px] font-poppinsMedium cursor-pointer"
                                    onClick={handleClearDocument}>
                                    <img src={svgAssets.closeIcon} alt="" />
                                </div>
                            </div>

                        }
                    </div>
                </div>

                <div className='w-full '>

                    {document1 ?
                        <div className="">
                            <button className="bg-[#5367FC] w-full flex  justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white font-poppinsRegular"
                                onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                        :
                        <div className="">
                            <div className="bg-[#C9C9C9] flex justify-center items-center border-s-4 rounded-[30px] py-3 px-2 cursor-pointer text-white"
                                onClick={handleClearDocument}
                            >
                                <p className="font-poppinsRegular">Submit</p>
                            </div>
                        </div>
                    }

                    <div className="my-5">
                        <div className="flex justify-center items-center cursor-pointer text-[#231F20] font-poppinsRegular"
                            onClick={() => { changeStep(0) }} >
                            <p className="">Back</p>
                        </div>
                    </div>
                </div>
            </div>
 </BaseLogin >
    );
};

