export const getEsignReport = 'GET_E_SIGN';
export const setEsignReport = 'SET_E_SIGN';

export const getEsignReportAction = (payload) => ({
	type: getEsignReport,
	payload
});

export const setEsignReportAction = (payload) => ({
	type: setEsignReport,
	payload
});
