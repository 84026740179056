// import { clearKyc } from '../../actions/kyc/kyc.action';
// import { changeStep2Error, changeStep2Input, setPanCardDetail, setValidatePanDetails, getSendDigilockerDataLink, setKRA, isSendDigioLockerLinkAction, setDigioLokerData } from '../../actions/kyc/step2.action';

// const mutableState = {
// 	panInput: {
// 		value: '',
// 		isError: false,
// 		errorText: '',
// 		status: 'edit'
// 	},
// 	dobInput: {
// 		value: '',
// 		isError: false,
// 		errorText: '',
// 		status: 'edit'
// 	},
// 	data: {},
// 	validatePanDetails: {},
// 	app_status: '',
// 	appInfo: '',
// 	isKRA: false,
// 	isShowDigilockerLinkButton: false,
// 	isSendDigilockerLink: false,
// 	digilockerLinkData: {
// 		success: false,
// 		message: '',
// 		data: {}
// 	},
// 	digioLokerData: {},
// 	status: '',
// };

// const initState = {
// 	...mutableState
// };

// export const kycStep2Reducer = (state = initState, action) => {
// 	const { type, value, isError, errorText } = action?.payload || {};
// 	switch (action.type) {
// 		case clearKyc:
// 			return {
// 				...mutableState
// 			};
// 		case changeStep2Input:
// 			return {
// 				...state,
// 				[type]: {
// 					...state[type],
// 					value,
// 					isError: false,
// 					errorText: ''
// 				}
// 			};
// 		case changeStep2Error:
// 			return {
// 				...state,
// 				[type]: {
// 					...state[type],
// 					isError,
// 					errorText
// 				}
// 			};
// 		case setValidatePanDetails:
// 			return {
// 				...state,
// 				validatePanDetails: action.payload,
// 			};
// 		case setPanCardDetail:
// 			return {
// 				...state,
// 				panInput: {
// 					...state.panInput,
// 					isError: false,
// 					errorText: ''
// 				},
// 				dobInput: {
// 					...state.dobInput,
// 					isError: false,
// 					errorText: ''
// 				},
// 				data: action.payload.data,
// 				isKRA: action.payload?.isKRA || false,
// 				isShowDigilockerLinkButton: action.payload?.isShowDigilockerLinkButton || false,
// 				app_status: action.payload?.app_status || '',
// 				status: 'success'
// 			};
// 		case setPanCardDetail:
// 			return {
// 				...state,
// 				panInput: {
// 					...state.panInput,
// 					isError: false,
// 					errorText: ''
// 				},
// 				dobInput: {
// 					...state.dobInput,
// 					isError: false,
// 					errorText: ''
// 				},
// 				data: action.payload.data,
// 				isKRA: action.payload?.isKRA || false,
// 				isSendDigilockerLink: action.payload?.isSendDigilockerLink || false,
// 				app_status: action.payload?.app_status || '',
// 				status: 'success'
// 			};
// 		case isSendDigioLockerLinkAction:
// 			return {
// 				...state,
// 				isSendDigilockerLink: action?.payload,
// 			};
// 		case getSendDigilockerDataLink:
// 			return {
// 				...state,
// 				digilockerLinkData: action?.payload,
// 			};
// 		case setDigioLokerData:
// 			return {
// 				...state,
// 				digioLokerData: action?.payload,
// 			};
// 		default:
// 			return state;
// 	}
// };
import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep2Error, changeStep2Input, setPanCardDetail, setValidatePanDetails, getSendDigilockerDataLink, setKRA, isSendDigioLockerLinkAction, setDigioLokerData } from '../../actions/kyc/step2.action';

const mutableState = {
	panInput: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	dobInput: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit'
	},
	data: {},
	validatePanDetails: {},
	app_status: '',
	appInfo: '',
	isKRA: false,
	isShowDigilockerLinkButton: false,
	update_status:{},
	isSendDigilockerLink: false,
	digilockerLinkData: {
		success: false,
		message: '',
		data: {}
	},
	digioLokerData: {},
	status: '',
	
};

const initState = {
	...mutableState
};

export const kycStep2Reducer = (state = initState, action) => {
	const { type, value, isError, errorText } = action?.payload || {};
	switch (action.type) {
		case clearKyc:
			return {
				...mutableState
			};
		case changeStep2Input:
			return {
				...state,
				[type]: {
					...state[type],
					value,
					isError: false,
					errorText: ''
				}
			};
		case changeStep2Error:
			return {
				...state,
				[type]: {
					...state[type],
					isError,
					errorText
				}
			};
		case setValidatePanDetails:
			return {
				...state,
				validatePanDetails: action.payload,
			};
		case setPanCardDetail:
			return {
				...state,
				panInput: {
					...state.panInput,
					isError: false,
					errorText: ''
				},
				dobInput: {
					...state.dobInput,
					isError: false,
					errorText: ''
				},
				data: action.payload.data,
				isKRA: action.payload?.isKRA || false,
				isShowDigilockerLinkButton: action.payload?.isShowDigilockerLinkButton || false,
				app_status: action.payload?.app_status || '',
				status: 'success',
				update_status:action.payload.update_status
			};
		case setPanCardDetail:
			return {
				...state,
				panInput: {
					...state.panInput,
					isError: false,
					errorText: ''
				},
				dobInput: {
					...state.dobInput,
					isError: false,
					errorText: ''
				},
				data: action.payload.data,
				isKRA: action.payload?.isKRA || false,
				isSendDigilockerLink: action.payload?.isSendDigilockerLink || false,
				app_status: action.payload?.app_status || '',
				status: 'success'
			};
		case isSendDigioLockerLinkAction:
			return {
				...state,
				isSendDigilockerLink: action?.payload,
			};
		case getSendDigilockerDataLink:
			return {
				...state,
				digilockerLinkData: action?.payload,
			};
		case setDigioLokerData:
			return {
				...state,
				digioLokerData: action?.payload,
			};
		default:
			return state;
	}
};
