

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const Reactivation = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
   console.log(userDetail,'kkk');
    useEffect(() => {
        
        const url = ' https://bigul.co/crs'; 
        const url2 = 'https://bigul.co/crs-dashboard'; 

        const openNewWindow = () => {
            window.open(userDetail?.role_id == 6 ? url2: url, '_blank');
        };

        openNewWindow();
    }, [userDetail?. role_id]); 

    return (
        <div>
           
            <p>{"Bigul Reactivation"}</p>
        </div>
    );
};
