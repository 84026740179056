// import react, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Modal } from 'antd';
// import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
// import { pngAssets, svgAssets } from '../../../assets/asset';
// import { BackBtn } from '../../../components/buttons/BackBtn';
// import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
// import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';
// import { ReviewBtn } from '../../../components/buttons/ReviewBtn';
// import {
// 	changeStep8ErrorAction, finalSubmitAction,callUpdateSegmentInfoAction,
// 	uploadSignatureAction, callSendSignatureLinkAction
// } from '../../../redux/actions/kyc/step8.action';
// import { UploadSelfie } from '../step7/UploadSelfie';
// import { DOMAIN } from '../../../config/config';

// export const UploadSignature = ({ linktoken ,isDocumentUploaded, isRefreshIncomeProof, reviewBtn, rejectedFieldsData ,isFinalModalOpen, handleCloseModal}) => {
// 	const dispatch = useDispatch();
// 	let clientLink = DOMAIN + '/dkyc/';
// 	if(linktoken)
// 	{
// 		 clientLink = DOMAIN + '/dkyc/'+linktoken+'?isSign=1';
// 	}
// 	const [isCopy, setIsCopy] = useState(false);
// 	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
// 	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);
// 	const kycStep1 = useSelector(state => state?.kycStep1 || {});

// 	const { panInput } = useSelector(state => state?.kycStep2 || {});
// 	const { isError, errorText } = useSelector(state => state?.kycStep8 || '');
// 	const validatePanDetails = useSelector(state => state?.kycStep2.validatePanDetails.data || {});
// 	const user_image = reviewFiles && reviewFiles?.filter(a => (a?.Document === 'Client Signature'));
// 	const lastItemIndex = user_image?.length - 1;

// 	const handleImage = (e) => {
// 		if (!e.target.files || e.target.files.length === 0) {
// 			return;
// 		}
// 		dispatch(uploadSignatureAction({
// 			PanNo: panInput?.value || '',
// 			Document: 'Client Signature',
// 			DocumentType: 'NA',
// 			CreatedBy: userDetail.created_by,
// 			'DocumentImages[]': e.target.files[0]
// 		}));
// 	};

// 	const handleSendLink = () => {
// 		dispatch(callSendSignatureLinkAction(
// 			{
// 				"name": validatePanDetails.firstName + " " + validatePanDetails.lastName,
// 				"mobile": kycStep1.mobileInput.value,
// 				"link": `${DOMAIN}/dkyc`
// 			}
// 		));
// 	}

// 	const handleCopyLink = () => {
// 		navigator.clipboard.writeText(clientLink);
// 		setIsCopy(true);
// 	}

// 	return (
// 		<div>
// 			<div className="testmin-h-[calc(100vh-300px)]">
// 				{/* <UploadSelfie isChildComp={true} /> */}
// 				<div className="w-full flex flex-col mb-10">
// 					<div className="mb-8 grid md:grid-cols-2 gap-x-10 items-end">
// 						<div className="flex flex-col ">
// 							<InputCopyBtnIcon
// 								icon={svgAssets.kyc.signatureInput}
// 								label="Signature Link"
// 								subLabel="Share below link with client"
// 								isImportant={true}
// 								placeholder={`${clientLink}`}
// 								btnWidth="67px"
// 								btnIcon={svgAssets.kyc.kycMessage}
// 								inputMessage={errorText}
// 								inputMessageType={isError && 'error'}
// 								handleSubmit={handleCopyLink}
// 								defaultValue={`${clientLink}`}
// 								isDisable={true}
// 								isCopy={isCopy}
// 							/>
// 							{
// 								rejectedFieldsData?.rejectedClientSignature?.length > 0 &&
// 								<>
// 									<div className='bg-[#FFC9C9] border-[#f99f9f] border-solid border-2 px-5 py-3 rounded mt-3'>
// 										<p
// 											className='text-[#343434]-500'
// 											style={{ fontWeight: '300', fontSize: '15px', color: '#343434' }}
// 										>
// 											{rejectedFieldsData.rejectedClientSignature[0].reason}
// 										</p>
// 									</div>
// 								</>
// 							}
// 						</div>
// 						<div className="flex flex-col relative ">
// 							<div className='hover:text-[#5466fc] relative flex items-center justify-between' >
// 								{isDocumentUploaded?.success && user_image && user_image.map((item, index) => {
// 									const isLastItem = index === lastItemIndex;
// 									return (
// 										<>
// 											{isLastItem &&
// 									<div key={index} className='bg-white p-2 w-[150px] h-[130px] flex items-center justify-center mt-3'>
// 										<a href={item?.ImagePath} target='_blank'>
// 											<img width='80' src={item?.ImagePath} />
// 										</a>
// 									</div>}
// 										</>
// 									);
// 								})}
// 								{

// 										isDocumentUploaded?.success &&
// 										<button
// 											className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}
// 										>
// 											<img
// 												src={svgAssets.kyc.verify}
// 												className="mr-1.5"
// 											/>
// 											<span>
// 												Uploaded
// 											</span>
// 										</button>
// 								}
// 							</div>
// 						</div>
// 						{/* <div className=" flex items-center gap-x-2 px-5 mt-4">
// 								<img className="w-[19px] h-[19px]" alt="back_icon" src={svgAssets.kyc.greencheck} />
// 								<span className="col-span-5 text-[#007813] text-[13px] leading-[18px] font-medium font-poppinsMedium ">
// 									Signature successfully submitted by client
// 								</span>
// 							</div> */}
// 					</div>
// 				</div>
// 			</div>
// 			<Modal
// 				// title={title || ''}
// 				open={isFinalModalOpen}
// 				onCancel={handleCloseModal}
// 				centered={true}
// 				width={400}
// 				closeIcon={false}
// 				footer={null}
// 				className="w-full"
// 			>
// 				<div className=''>
// 				<h6 className=" text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
// 							Congrats<br></br>
// 							You have Successfully completed all the steps
// 						</h6>
// 					{/* <div className="flex flex-col items-center justify-center min-h-[calc(100vh-300px)]">
// 						<h6 className="mb-6 text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
// 							Congrats<br></br>
// 							You have Successfully completed all the steps
// 						</h6>
// 						<img
// 							className="mb-5"
// 							alt="success_kyc"
// 							src={pngAssets.kyc.kycSuccess}
// 						/>
// 					</div> */}
// 					<div className="flex item justify-center gap-x-3 mt-5">

// 						<ReviewBtn handleReviewBtn={reviewBtn} />
// 					</div>
// 				</div>
// 			</Modal>

// 			{/* <div className="flex item justify-between">
// 				<BackBtn handleBackBtn={() => backBtn({ step, index })} />
// 				{
// 					!isDkycDocumentUploaded.isIncomeProofUploaded?.success || !isDkycDocumentUploaded.isSelfieUploaded?.success || !isDkycDocumentUploaded.isSignatureUploaded?.success ||
// 					(!isIncomeUploaded && !isContinueIncomeProof)
// 					?
// 						<ContinueBtn isDisable={true} />
// 						:
// 						<ContinueBtn handleContinueBtn={handleContinueBtn} />
// 				}
// 			</div> */}
// 		</div>
// 	);
// };

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
import { svgAssets } from '../../../assets/asset';
import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';

import { DOMAIN } from '../../../config/config';
import { InputCopyBtnIcon1 } from '../../../components/inputs/RejectInput';


export const UploadSignature = ({ linktoken, isChildComp, rejectedFieldsData }) => {

	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);

	const { isError, errorText } = useSelector(state => state?.kycStep7 || '');

	const [signatrue, setSignature] = useState(false)

	let clientLink = DOMAIN + '/dkyc/';
	if (linktoken) {
		clientLink = DOMAIN + '/dkyc/' + linktoken + '?isSelfie=1';
	}
	const [isCopy, setIsCopy] = useState(false);
	const user_image = reviewFiles && reviewFiles?.filter(a => (a?.DocumentType == 'Client Signature' && a?.ReUploadFlag == 1));
	const lastItemIndex = user_image?.length - 1;

	const handleCopyLink = () => {
		navigator.clipboard.writeText(clientLink);
		setIsCopy(true);
	}

	return (
		<div className="w-full flex flex-col rejected-otem-list">
			<div className={`${!isChildComp}`}>
				<div className="mb-0 grid md:grid-cols-2 gap-x-10 items-center mt-0">
					<div className="flex flex-col ">
						<div className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap mb-0`} style={{ color: '#FF4343' }}>
							{rejectedFieldsData !== null ? rejectedFieldsData : 'Selfie is not valid'}

						</div>
						<InputCopyBtnIcon1
							// icon={svgAssets.kyc.signature}
							// label="Signature Link"
							// subLabel="Share below link with client"
							isImportant={true}
							placeholder={clientLink}
							btnWidth="67px"
							btnIcon={ClipboardIcon}
							inputMessage={errorText}
							inputMessageType={isError && 'error'}
							handleSubmit={handleCopyLink}
							defaultValue={clientLink}
							isDisable={true}
							isCopy={isCopy}
						/>

					</div>
					<div className="flex flex-col relative">
						<div className='hover:text-[#5466fc] relative flex items-center justify-end ' >
							{user_image && user_image.map((item, index) => {
								const isLastItem = index === lastItemIndex;
								return (
									<>
										{isLastItem &&
											<>
											
										<p>Re-uploaded Document By the client</p>
												<div key={index} className='bg-white ml-2 p-1 flex items-center justify-center'>
													<a href={item?.ImagePath} target='_blank'>
														<img width='80' src={item?.ImagePath} />

													</a>
												</div>
											</>}
									</>
								);
							})}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
