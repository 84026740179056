export const clearKyc = 'CLEAR_KYC';
export const setChannel = 'SET_CHANNEL';
export const setClientKycStatus = 'SET_CLIENT_KYC_STATUS';
export const uploadImage = 'UPLOAD_IMAGE';
export const callSendDkycLink = 'CALL_SEND_DKYC_LINK_ACTION';
export const setSendDkycLink = 'SET_SEND_DKYC_LINK_ACTION';
export const callCheckIsBankProofUploaded = 'CALL_CHECK_IS_BANK_PROOF_UPLOADED';
export const isBankProofUploaded = 'IS_Bank_PROOF_UPLOADED';
export const isIncomeProofUploaded = 'IS_INCOME_PROOF_UPLOADED';
export const isSelfieUploaded = 'IS_SELFIE_UPLOADED';
export const isSignatureUploaded = 'IS_SIGNATURE_UPLOADED';
export const callCheckDocumentIsUploaded = 'CALL_CHECK_DOCUMENT_IS_UPLOADED';


export const clearKycAction = (payload) => ({
	type: clearKyc,
	payload
});

export const setChannelAction = (payload) => ({
	type: setChannel,
	payload
});

export const setClientKycStatusAction = (payload) => ({
	type: setClientKycStatus,
	payload
});

export const uploadImageAction = (payload) => ({
	type: uploadImage,
	payload
});

export const callSendDkycLinkAction = (payload) => {
	return(
		{
			type: callSendDkycLink,
			payload
		}
	)
};

export const callCheckDocumentIsUploadedAction = (payload) => {
	return(
		{
			type: callCheckDocumentIsUploaded,
			payload
		}
	)
};

export const isBankProofUploadedAction = (payload) => {
	return(
		{
			type: isBankProofUploaded,
			payload
		}
	)
};

export const isIncomeProofUploadedAction = (payload) => {
	return(
		{
			type: isIncomeProofUploaded,
			payload
		}
	)
};

export const isSelfieUploadedAction = (payload) => {
	return(
		{
			type: isSelfieUploaded,
			payload
		}
	)
};

export const isSignatureUploadedAction = (payload) => {
	return(
		{
			type: isSignatureUploaded,
			payload
		}
	)
};

export const setSendDkycLinkAction = (payload) => {
	return(
		{
			type: setSendDkycLink,
			payload
		}
	)
};
