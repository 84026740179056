export const clearVerifierData = 'CLEAR_VERIFIER_DATA';
export const getVerifyApplication = 'GET_VERIFY_APPLICATION';
export const setVerifyApplication = 'SET_VERIFY_APPLICATION';
export const setVerifyApplication2 = 'SET_VERIFY2_APPLICATION';
export const getVerifyClientFiles = 'GET_VERIFY_CLIENT_FILES';
export const setVerifyClientFiles = 'SET_VERIFY_CLIENT_FILES';
export const callVerifyRejection = 'CALL_VERIFY_REJECTION';


export const clearVerifierAction = (payload) => ({
	type: clearVerifierData,
	payload
});

export const getVerifyApplicationAction = (payload) => ({
	type: getVerifyApplication,
	payload
});

export const setVerifyApplicationAction = (payload) => ({
	type: setVerifyApplication,
	payload
});

export const setVerifyApplication2Action = (payload) => ({
	type: setVerifyApplication2,
	payload
});

export const getVerifyClientFilesAction = (payload) => ({
	type: getVerifyClientFiles,
	payload
});

export const setVerifyClientFilesAction = (payload) => ({
	type: setVerifyClientFiles,
	payload
});

export const callVerifyRejectionAction = (payload,callback) => ({
	type: callVerifyRejection,
	payload,
	callback
});
