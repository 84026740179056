import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addUser, getDesignationMaster, getRoleMaster, inputValidation, inputValidationAction, setDesignationMasterAction, setRoleMasterAction } from '../redux/actions/manageuser.action';
import { getBranchName, setBranchNameAction } from '../redux/actions/manageuser.action';
import { getBranchCode, setBranchCodeAction } from '../redux/actions/manageuser.action';
import { submitform } from '../redux/actions/manageuser.action';
import { postApi, getApi, commonPostApi, postFormApi, formPostApi } from '../services/common';




function* getRoleMasterSaga() {
    try {
        const response = yield call(getApi, '/GetRoleMaster');
        const data = yield response.data
        yield put(setRoleMasterAction(data));
    } catch (error) {
        yield put({ type: 'FETCH_DATA_FAILURE', error: error.message });
    }
}



function* getBranchNameSaga({ payload }) {
    try {
        const data = yield call(postApi, payload, '/GetBranchNameMaster');
        yield put(setBranchNameAction({ data: data?.data?.data || [] }));
    } catch (err) {
        console.log(err);
    };
};


function* getBranchCodeSaga({ payload }) {
    try {
        const data = yield call(postApi, payload, '/GetBranchCodeMaster');
        
        yield put(setBranchCodeAction({ data: data?.data?.data || [] }));
        console.log(setBranchCodeAction)
    } catch (error) {
        console.log(error);
    }
}

function* getDesignationMasterSaga() {
    try {
        const data = yield call(getApi, '/GetDesignationMaster');
        yield put(setDesignationMasterAction({ data: data?.data?.data || [] }));
    } catch (error) {
        console.log(error);
    }
}

function* addUserSaga({ payload }) {
    console.log("payload", payload)
    try {
        const data = yield call(postFormApi, payload, '/AddUser');
        console.log("addUserSaga", data)
        if (data?.data?.message === 'Validation Error!') {
            yield put(inputValidationAction({ data: data?.data || [] }));
        }
        else{
            yield put(inputValidationAction({ data: data?.data || [] }));
        }
    } catch (error) {
        console.log(error);
    }
}

function* manageUserSaga() {

    yield all([
        takeEvery(getRoleMaster, getRoleMasterSaga),
        takeEvery(getBranchName, getBranchNameSaga),
        takeEvery(getBranchCode, getBranchCodeSaga),
        takeEvery(getDesignationMaster, getDesignationMasterSaga),
        takeEvery(addUser, addUserSaga),
        // takeEvery(inputValidation, addUserSaga),
    ]);
};

export default manageUserSaga;