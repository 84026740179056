export const changeStep7Error = 'CHANGE_STEP7_ERROR';
export const uploadSelfie = 'UPLOAD_SELFIE';
export const callSendSelfieLink = 'CALL_SEND_SELFIE_LINK';
export const sendSelfieLink = 'SEND_SELFIE_LINK';
export const clearSelfie = 'CLEAR_SELFIE';
export const changefileupload= 'UPLOAD_FILE';

export const changeStep7ErrorAction = (payload) => ({
	type: changeStep7Error,
	payload
});

export const uploadSelfieActions = (payload) => {
	return(
		{
			type: uploadSelfie,
			payload
		}
	)
};

export const callSendSelfieLinkAction = (payload) => {
	return(
		{
			type: callSendSelfieLink,
			payload
		}
	)
};

export const sendSelfieLinkAction = (payload) => {
	return(
		{
			type: sendSelfieLink,
			payload
		}
	)
};
export const uploadFile = (payload) => {
	return(
		{
			type: changefileupload,
			payload
			
		}
	)
};

export const clearSelfieAction = () => ({
	type: clearSelfie,
});

