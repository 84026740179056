
export const MainTitle = ({ title, marginBottom }) => {
	return (
		<div style={{marginRight:10}} className={`text-lg leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap ml-2
			${marginBottom || 'mb-[22px]'}
		`}>
			{title}
		</div>
	);
};
