import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../../components/common/Checkbox';
import { CustomDropdown } from '../../../components/dropdown/CustomDropdown';
import { updateTransactionValueAction } from '../../../redux/actions/kyc/step6.action';

export const Transaction = ({ transactionData }) => {
	const dispatch = useDispatch();
	const transaction = useSelector(state => state.kycStep6.transactionData.value);
	console.log('transaction',transaction);

	const handleTransaction = ({ value }) => { 
		let newTransaction = [];
		if (value === 'all') {
			newTransaction = transaction.length === 4 ? [] : transactionData;
		} else {
			newTransaction = transaction.includes(value) ? transaction.filter((el) => el !== value) : [...transaction, value];
		}
		dispatch(updateTransactionValueAction(newTransaction || []));
	};

	return (
		<CustomDropdown 
			label="DDPI OPT-IN"
			position="relative"
		>
			<div className="px-3.5 py-[25px]">
				<Checkbox
					labelText="All Transactions"
					labelSize="18px"
					labelWeight="500"
					labelFamily="font-poppinsMedium"
					marginBottom="mb-4"
					value={'all'}
					isChecked={transaction?.length === 4}
					handleCheckbox={handleTransaction}
				/>
				<div className="ml-8 flex flex-col">
					{
						transactionData?.length > 0
							? (
								transactionData.map((el, index) => (
									<Checkbox
										key={`transaction_${index}`}
										labelText={el}
										labelSize="16px"
										labelWeight="400"
										labelFamily="font-poppinsRegular"
										marginBottom="mb-4"
										value={el}
										isChecked={transaction?.includes(el)}
										handleCheckbox={handleTransaction}
									/>
								))
							)
							: null
					}
				</div>
			</div>
		</CustomDropdown>
	);
};
