export const changeStep4Error = 'CHANGE_STEP8_ERROR';
export const uploadSignature = 'UPLOAD_SIGNATURE';
export const finalSubmit = 'FINAL_SUBMIT';
export const clearSignature = 'CLEAR_SUBMIT';

export const changeStep4ErrorAction = (payload) => ({
    type: changeStep4Error,
    payload
});

export const uploadSignatureAction = (payload,callback) => {
    return(
        {
            type: uploadSignature,
            payload,
            callback
        }
    )
};

export const finalSubmitAction = (payload) => ({
    type: finalSubmit,
    payload
});

export const clearSignatureAction = (payload) => ({
    type: clearSignature,
    payload
});
