import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainTitle } from '../../../components/common/MainTitle';
import { UploadSelfie } from "./UploadSelfie";
import { UploadIncomeProof } from "./UploadIncomeProof";
import { UploadSignature } from "./UploadSignature";
import {
    callSendDkycLinkAction,
} from '../../../redux/actions/kyc/kyc.action';
import { getClientFilesAction, sendEquityEsignAction } from '../../../redux/actions/kyc/review.action';
import { DOMAIN } from '../../../config/config';
import { svgAssets } from '../../../assets/asset';
import { finalSubmitAction, callUpdateSegmentInfoAction } from '../../../redux/actions/kyc/step8.action';
import { uploadFile } from '../../../redux/actions/kyc/step7.action';
import { DigiImage, postApi, postApiWithoutLoader } from '../../../services/common';
import { UploadBankProof } from "./UploadBankProof";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getClientRejectReasonAction, setClientRejectReasonAction } from '../../../redux/actions/application.action';
import { UploadPan } from './Upload Pan';
import { UploadAddress } from './UploadAddress';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import copy from '../../../assets/svg/copy-svgrepo-com.svg'
import { Modal } from 'antd';
import { SendESignBtn } from '../../../components/buttons/SendESignLink';

var channelId = ''
export const RejectDoc = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedData = location.state?.selectedData || null;
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const kycData = useSelector(state => state?.kyc || {});
    const kycStep2 = useSelector(state => state?.kycStep2 || {});
    const [isSendLink, setIsSendLink] = useState(false);
    const [isRefreshForIncomeProof, setIsRefreshForIncomeProof] = useState(false);
    const [linktoken, setToken] = useState('');
    const [status, setStatus] = useState(1);
    const [kraCHeck, setCheckKra] = useState(false);


    // const user_image = reviewFiles && reviewFiles?.find(a => (a?.Document === 'Client Photo'));
    // const pan_image = reviewFiles && reviewFiles?.find(a => (a?.Document === 'Pan Upload'));
    // const income_image = reviewFiles && reviewFiles?.find(a => (a?.Document === 'Income Proof'));
    // const Bank_image = reviewFiles && reviewFiles?.find(a => (a?.Document === 'Bank Proof'));
    // const signature_image = reviewFiles && reviewFiles?.find(a => (a?.Document == 'Client Signature'));

    const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
    const rejectReasonsData = useSelector(state => {
        const rejectReason = state?.application.rejectReason;
        return Array.isArray(rejectReason) ? rejectReason : [];
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const filteredRecords = rejectReasonsData ? rejectReasonsData?.filter(record => record?.is_verify == "0") : [];
    const [checkEsign, setCheckEsign] = useState(false)
    if (Array.isArray(filteredRecords)) {
        var keywords = ['Last 3 Months Salary', 'Income', 'ITR', 'Latest', 'Investment Proof', 'Statement', 'Audited Balance'];
        var bankKeword = ['penny', "Micr", 'Bank', 'Personalized Cheque', 'LDCode', 'Passbook', 'Cancelled Cheque', 'Other'];
        var PanKeword = ['NameAsOnPAN', 'E-SignerName', 'BirthDate', "Name", 'PANCard', 'pan'];
        var ImageKeword = ['ClientPhoto', 'photo', 'Client Photo'];
        var SignatureKeword = ['ClientSignature', 'signature', 'Signature'];
        var AddressKeword = ['Address', 'Aadhaar', 'CRAddr', 'CRPinCode', 'Driving', 'PRPinCode'];
        var bank = filteredRecords?.find((item) => {
            return bankKeword?.some(bankKeword => item?.verify_key?.includes(bankKeword));
        });
        var PanCheck = filteredRecords.find((item) => {
            return PanKeword.some(PanKeword => item?.verify_key?.includes(PanKeword));
        });
        var IncomeCheck = filteredRecords.find((item) => {
            return keywords.some(keyword => item?.verify_key?.includes(keyword));
        });
        var ImageCheck = filteredRecords.find((item) => {
            return ImageKeword.some(ImageKeword => item?.verify_key?.includes(ImageKeword));
        });
        var signatureCheck = filteredRecords.find((item) => {
            return SignatureKeword.some(SignatureKeword => item?.verify_key?.includes(SignatureKeword));
        });
        var AddressCheck = filteredRecords.find((item) => {
            return AddressKeword.some(AddressKeword => item?.verify_key?.includes(AddressKeword));
        });
    } else {
        console.error("rejectReasonsData is not an array");
    }


    useEffect(() => {
        if (kraCHeck) {
            setIsModalOpen(true);
            ModalClose()
        }
    }, [kraCHeck]);
    useEffect(() => {
        // let interval;
        // if (checkEsign) {
        // interval = setInterval(() => {
        fetchData1(1)
        // }, 10000);
        // }
        // return () => clearInterval(interval);

    }, [checkEsign]);



    // useEffect(() => {
    //     dispatch(setClientRejectReasonAction({}));

    //     const fetchData = () => {
    //         setTimeout(() => {
    //             dispatch(
    //                 getClientFilesAction({
    //                     PanNo: selectedData?.PanNo, // Change this to the appropriate field from selectedStepData
    //                     CreatedBy: 99,
    //                 })
    //             );
    //         }, 0);
    //     };


    //     fetchData();
    //     // const intervalId = setInterval(fetchData, 10000);
    //     // return () => clearInterval(intervalId);
    // }, []);


    const dummydata = {
        mobile: selectedData?.mobile,
        pan: selectedData?.PanNo
    }

    useEffect(() => {
        if (selectedData?.ClientId) {
            dispatch(getClientRejectReasonAction({
                'ClientId': selectedData?.ClientId,
                'PanNo': selectedData?.PanNo
            }));
        }
    }, [])


    const generateToken = () => {
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
        const token = Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
        return token
    }

    const handleSendLink = () => {
        sessionStorage.setItem('userfile', true)
        let ttoken = generateToken()
        setToken(ttoken)
        dispatch(callSendDkycLinkAction(
            {
                "mobile": selectedData?.mobile,
                "link": `${DOMAIN}/dkyc`,
                "token": ttoken
            }
        ));
        toast('Link has been successfully sent to the customer')
        // dispatch(uploadFile(true))
    }
    const handleCopyLink = () => {
        if (linktoken.length > 3) {
            setTimeout(() => {
                const url = DOMAIN + '/dkyc/' + linktoken;
                window.open(url, '_blank');
            }, 500);
        }
        else {
            let ttoken = generateToken()
            dispatch(callSendDkycLinkAction(
                {

                    "mobile": selectedData?.mobile,
                    "link": `${DOMAIN}/dkyc`,
                    "token": ttoken
                }
            ));
            setTimeout(() => {
                const url = DOMAIN + '/dkyc/' + ttoken;
                window.open(url, '_blank');
            }, 500);
        }


    }

    const checkImage = () => {
        dispatch(
            getClientFilesAction({
                PanNo: selectedData?.PanNo, // Change this to the appropriate field from selectedStepData
                CreatedBy: 99,
            })
        );
    }
    const fetchData1 = async (id) => {
        try {
            const payload = {
                mobile: dummydata?.mobile,
                esign: 1
            }
            const response = await postApiWithoutLoader(payload, '/getKycJourney');

            if (response?.data?.success) {
                channelId = response?.data?.data[0]?.IsETrade
                if (response?.data?.data[0]?.cmp_is_esign == 1) {
                    setCheckKra(true)
                }
                else {
                    if (id != 1) {
                        toast('your esign is pending')
                    }
                }
            }
            else {
                console.error('Error fetching data:');

            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(getClientFilesAction({
                PanNo: dummydata?.pan,
                CreatedBy: 88
            }));
        }, 2000);
    }, [isRefreshForIncomeProof])

    useEffect(() => {
        setIsSendLink(false);
        if (linktoken !== '') {
            setIsSendLink(true);
        }

    }, [linktoken])


    useEffect(() => {
        dispatch(getClientFilesAction({
            PanNo: dummydata.pan,
            CreatedBy: 88
        }));
    }, [isSendLink, kycData?.isSignatureUploaded?.success, kycData.isSelfieUploaded?.success, clientKycStatus?.data?.pan]);

    const isRefreshIncomeProof = () => {
        setIsRefreshForIncomeProof(!isRefreshForIncomeProof);
    }

    const handleContinueBtn = () => {
        dispatch(callUpdateSegmentInfoAction({
            PanNo: selectedData?.PanNo,
            Fname: selectedData?.FName,
            Refcode: "90",
            Reftype: "RM",
            channel: selectedData.channel,

        }, callbackhandle));
    };

    const callbackhandle = (resp) => {
        if (resp.success) {
            dispatch(finalSubmitAction({
                mobile: selectedData?.mobile,
                process_type: 'resubmit',
                ClientId: selectedData?.ClientId,
            }, callbackFinal));
        }
        else {
            toast(resp?.message ? resp?.message : 'something went wrong')
        }
    }

    const callbackFinal = async (resp) => {
        if (resp.success) {
            // toast('Your Re-submit document upload successfully')
            dispatch(sendEquityEsignAction(
                {
                    ClientId: selectedData?.ClientId,
                    channel: selectedData?.channel,
                }, callbackhandle1));

        }
    }

    const ModalClose = async () => {
        try {
            const payload = {
                UserId: userDetail?.user_id,
                PanNo: selectedData?.PanNo,
                roleId: userDetail?.role_id,
                rm_journey: 1,
                rejection: 1
            }
            const response = await postApi(payload, '/SaveClientSatus');
            if (response?.response) {
                setIsModalOpen(false);
                setCheckKra(false)
            }
        }
        catch (error) {
            setIsModalOpen(false);
            setCheckKra(false)
        }
    }
    const callbackhandle1 = (resp) => {
        if (resp?.data?.URLL) {
            setCheckEsign(true)
            setTimeout(() => {
                window.open(resp?.data?.URLL, '_blank');

            }, 1000);
        }
        else if (resp?.message == 'ESign Already Done.') {
            toast(resp?.message ? resp?.message : null)
            ModalClose()
            setIsModalOpen(true);
            setCheckEsign(true)
        }
        else {
            toast(resp?.message ? resp?.message : 'something went wrong')
        }
    }

    const copyUrl = () => {
        const textToCopy = DOMAIN + '/dkyc/' + linktoken; // Replace with your URL or text

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast('Customer link copied to clipboard!')
            })
            .catch((err) => {
                toast(err)
                // setCopySuccess('Copy failed');
                // console.error('Unable to copy text: ', err);
            });
    }

    const handleUpload = async () => {
        const payload = {
            panNo: selectedData?.PanNo,
        }
        const response = await postApi(payload, '/clDocumentUpload');

        if (response?.data?.success) {
            toast(response.data.message)
            setStatus(2)
        }
        else {
            console.error('Error fetching data:');

        }
    }
    const handleUpload1 = async () => {
        const payload = {
            panNo: selectedData?.PanNo,
        }
        const response = await postApi(payload, '/clDocumentUpload');

        if (response?.data?.success) {
            toast(response.data.message)
            setStatus(1)
        }
        else {
            console.error('Error fetching data:');

        }
    }


    const handleViewPDF = async () => {
        const payload2 = {
            ClientId: selectedData?.ClientId,
            channel: channelId == 90 ? "Bigul" : 'Bonanza',
            "IsESign": "No",
            "IsType": "equity",
            view_html: 1,
        }
        const response = await postApi(payload2, (payload2?.channel == "Bigul" || payload2?.channel == "BIGUL") ? '/GetESignMyetrade' : '/GetESign');
        if (response?.data.success) {
            window.open(response?.data?.data?.PdfPath, '_blank');
        } else {
            toast(response?.data.message || 'something went wrong')
        }
    }

    return (
        filteredRecords ?
            <div className="files-upload-main">
                <Modal
                    open={isModalOpen}
                    centered={true}
                    width={400}
                    closeIcon={() => setIsModalOpen(false)}
                    footer={null}
                    className="w-full"
                >
                    <div className=''>
                        <h6 className=" text-center text-[#007813] text-2xl leading-9 font-medium font-poppinsMedium">
                            Congrats!<br></br>
                            Esign completed successfully.
                        </h6>
                        <div className="flex item justify-center gap-x-3 mt-5">
                            <Link to="/">
                                <button onDoubleClick={() => {
                                    setTimeout(() => {
                                        setIsModalOpen(false)
                                    }, 3000);
                                }} className='bg-black text-white px-8 py-2 rounded font-bold'
                                    style={
                                        { borderRadius: '10px', background: '#007813' }
                                    }
                                >
                                    {
                                        'Go to Dashboard'
                                    }
                                </button>
                            </Link>
                        </div>
                    </div>
                </Modal>
                < ToastContainer autoClose={1000} />
                <h1 className='title-rejected-page' style={{ marginTop: '0px' }}>
                    <MainTitle title={rejectReasonsData.length == 1 ? "Resubmit Rejected Document" : "Resubmit Rejected Documents"} />
                    <p className='font-500 mb-3 font-16'>
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                            {selectedData?.FName && selectedData.FName + ' '}
                            {selectedData?.MName && selectedData.MName + ' '}
                            {selectedData?.LName && selectedData.LName}
                        </span>
                        {' with '}
                        <span style={{ color: 'green', fontWeight: 'bold' }}>{selectedData?.PanNo}  </span>
                        {' has been rejected because of the following reasons.'}</p>

                </h1>

                {/* <h1 style={{marginTop:'50px'}}>{selectedData?.FName+selectedData?.LName+' '+'with'+' '+ selectedData?.PanNo +' '+'has been rejected because of the following reasons.'}</h1> */}
                {IncomeCheck &&

                    <UploadIncomeProof isDocumentUploaded={kycData?.isIncomeProofUploaded || {}} rejectedFieldsData={IncomeCheck && IncomeCheck?.reason} isRefreshIncomeProof={isRefreshIncomeProof} selectedStepData={selectedData} />
                }
                {bank &&

                    <UploadBankProof isDocumentUploaded={kycData?.isIncomeProofUploaded || {}} rejectedFieldsData={bank && bank?.reason} isRefreshIncomeProof={isRefreshIncomeProof} selectedStepData={selectedData} />
                }
                {ImageCheck &&

                    <UploadSelfie linktoken={linktoken} isDocumentUploaded={kycData?.isIncomeProofUploaded || {}} rejectedFieldsData={ImageCheck && ImageCheck?.reason} isRefreshIncomeProof={isRefreshIncomeProof} selectedStepData={selectedData} />
                }
                {signatureCheck &&

                    <UploadSignature linktoken={linktoken} isDocumentUploaded={kycData?.isIncomeProofUploaded || {}} rejectedFieldsData={signatureCheck && signatureCheck?.reason} isRefreshIncomeProof={isRefreshIncomeProof} selectedStepData={selectedData} />
                }
                {PanCheck &&
                    <UploadPan isDocumentUploaded={kycData?.isBankProofUploaded || {}} rejectedFieldsData={PanCheck && PanCheck.reason} kycStep2={kycStep2} selectedStepData={selectedData} />
                }
                {AddressCheck &&

                    <UploadAddress isDocumentUploaded={kycData?.isBankProofUploaded || {}} rejectedFieldsData={AddressCheck && AddressCheck?.reason} kycStep2={kycStep2} selectedStepData={selectedData} />
                }

                <p className='font-500 mb-3 font-16'>Please resubmit the application with below options</p>
                <div className='flex items-center justify-start mt-5 mb-3'>

                    <button
                        className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                        onClick={handleSendLink}
                    >
                        {isSendLink ?
                            <img
                                src={svgAssets.kyc.verify}
                                className="mr-1.5"
                            />
                            :
                            <img
                                src={svgAssets.kyc.send}
                                className="mr-1.5"
                            />
                        }
                        <span>
                            {'Send link to customer'}
                        </span>
                    </button>
                    {
                        linktoken &&
                        <button
                            className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                            onClick={() => { copyUrl() }}
                        >
                            <img src={copy} width={'15px'} />
                            <span className='ml-2'>Copy customer link</span>
                        </button>
                    }
                    {

                        <button
                            className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                            onClick={() => { handleCopyLink() }}
                        >
                            <span>Assist here</span>
                        </button>
                    }
                    {

                        <button
                            className={`h-[47px] mr-3 mb-3 px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                            onClick={() => { checkImage() }}
                        >
                            <span>Check document</span>
                        </button>
                    }


                </div>
                {

                    <>
                        <div style={{ borderBottom: '2px solid #000', width: '100%' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                            <div style={{
                                // color: 'blue', // Set the color you prefer
                                // border: '1px solid #ccc', // Border for the note
                                // padding: '10px',
                            }}>
                                <p style={{ fontWeight: 'bold' }}>Note : Click here to check user documents</p>
                            </div>
                            <button style={{ backgroundColor: status == 2 ? 'green' : 'black' }}
                                className={`h-[47px] mr-3 ml-5  px-[18px] flex items-center text-white rounded-[10px] relative cursor-pointer `}
                                onClick={() => { handleUpload() }}
                            >
                                <span>Enable client eSign</span>
                            </button>
                            <button style={{ backgroundColor: '#0C82F7' }}
                                className={`h-[47px] mr-3 ml-5  px-[18px] flex items-center text-white rounded-[10px] relative cursor-pointer `}
                                onClick={() => { fetchData1(2) }}
                            >
                                <span>Check esign status</span>
                            </button>
                            <SendESignBtn handleBtn={handleViewPDF} name={"View KYC form before eSign"} />
                        </div>


                    </>
                    // :
                    // <>
                    //     <div style={{ borderBottom: '2px solid #000', width: '100%' }}></div>
                    //     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                    //         <div style={{
                    //             // color: 'blue', // Set the color you prefer
                    //             // border: '1px solid #ccc', // Border for the note
                    //             // padding: '10px',
                    //         }}>
                    //             <p style={{ fontWeight: 'bold' }}>Note:Click here to check user documents</p>

                    //         </div>
                    //         <button
                    //             className={`h-[47px] mr-3 ml-5  px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                    //             onClick={() => { handleUpload1() }}
                    //         >
                    //             <span>Disable client eSign</span>
                    //         </button>

                    //     </div>
                    // </>
                }


            </div>


            :
            <div className="w-full h-[calc(100vh-125px)] flex items-center justify-center text-xl font-poppinsMedium">
                Page Not Found !
            </div>
    );
};
