export const changeDkycInput = 'CHANGE_STEP1_INPUT';
export const changeDkycError = 'CHANGE_STEP1_ERROR';
export const fetchBankAccount = 'FETCH_BANK_ACCOUNT';
export const submitDocument = 'SUBMIT_DOCUMENT';
export const getPanNumber = 'PAN_NUMBER';
export const setPanNumber = 'SET_PAN_NUMBER';
export const isBankProofUploaded = 'IS_Bank_PROOF_UPLOADED';
export const isIncomeProofUploaded = 'IS_INCOME_PROOF_UPLOADED';
export const isSelfieUploaded = 'IS_SELFIE_UPLOADED';
export const isSignatureUploaded = 'IS_SIGNATURE_UPLOADED';
export const checkDKycDocumentIsUploaded = 'CHECK_DKYC_DOCUMENT_IS_UPLOADED';


export const changeInputAction = (payload) => ({
	type: changeDkycInput,
	payload
});

export const changeErrorAction = (payload) => ({
	type: changeDkycError,
	payload
});


export const getPanNumberAction = (payload, callback) => {
	return ({
		type: getPanNumber,
		payload,
		callback
	})
};
export const setPanNumberAction = (payload) => {
	return ({
		type: setPanNumber,
		payload
	})
};



export const callCheckDocumentIsUploadedAction = (payload) => {
	return (
		{
			type: checkDKycDocumentIsUploaded,
			payload
		}
	)
}

export const isBankProofUploadedAction = (payload) => {
	return (
		{
			type: isBankProofUploaded,
			payload
		}
	)
};

export const isIncomeProofUploadedAction = (payload) => {
	return (
		{
			type: isIncomeProofUploaded,
			payload
		}
	)
};

export const isSelfieUploadedAction = (payload) => {
	return (
		{
			type: isSelfieUploaded,
			payload
		}
	)
};

export const isSignatureUploadedAction = (payload) => {
	return (
		{
			type: isSignatureUploaded,
			payload
		}
	)
};