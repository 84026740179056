import React, { useState } from 'react';

export const Hadding5= () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const accordionData = [
        { title: '1. What should I do if the address on my Aadhaar card does not match my provided address? ', content: 'Update the address on your Aadhaar card to match the address you are providing for your Demat account. This can be done through the official UIDAI website or at an Aadhaar service center.' },
        { title: '2. How do I update my Aadhaar details?', content: 'You can update your Aadhaar details online through the UIDAI portal or visit an Aadhaar service center with the necessary documents to update your address or other details.' },
        { title: '3. Why is it important for my Aadhaar address to match my provided address?', content: 'Matching addresses on your Aadhaar card and provided address ensures consistency and helps in verifying your identity without any discrepancies.' },
    ];

    return (
        <>
        <p style={{fontWeight:'bold',marginLeft:'10%',marginBottom:'20px',marginTop:'20px'}}>FAQs for Invalid Aadhaar</p>
        <div style={styles.accordionContainer}>
           {accordionData.map((item, index) => (
                <div key={index} style={styles.accordionItem}>
                    <div
                        style={styles.accordionHeader}
                        onClick={() => handleToggle(index)}
                    >
                        <h3 style={{fontWeight:'bold'}}>{item.title}</h3>
                    </div>
                    <div
                        style={{
                            ...styles.accordionContent,
                            display: index === activeIndex ? 'block' : 'none'
                        }}
                    >
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

const styles = {
    accordionContainer: {
        width: '80%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        
        
    },
    accordionItem: {
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px'
    },
    accordionHeader: {
        padding: '15px',
        // backgroundColor: '#007BFF',
        // color: '#fff',
        cursor: 'pointer'
    },
    accordionContent: {
        padding: '10px',
        borderTop: '1px solid #ddd',
        backgroundColor: '#fff'
    }
};





