import { useEffect, useState } from "react";
import { postApi_mod } from "../../../services/common";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ReactTable } from "../../../components/reactTable/ReactTable";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { SearchBar } from "../../../components/searchbar/SearchBar";
import { PopoverChildComp, PopoverParentComp } from "../../../components/reactTable/ReactTablePopupBtn";
import { MyPopover } from "../../../components/popover/Popover";

export const Total = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const [data, setData] = useState([])
    const location = useLocation();
    const { myString } = location.state || {};

    const columns = [
        {
            Header: "Client Name",
            accessor: "clientName",
            minWidth: 130,
            Cell: ({ row }) =>
                `${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
                }`,
        },
        {
            Header: "Pan No", accessor: "PanNo", minWidth: 130,
        },
        {
            Header: "RM Name", accessor: "UserName", minWidth: 130,
        },
        {
            Header: "Created At",
            accessor: "createdAt",
            minWidth: 130,
            Cell: ({ row }) =>
                row.original?.created_at &&
                dayjs(row.original?.created_at).format("DD MMM YYYY" + ' ' + 'HH:mm:ss'),
            sortable: true,
        },
        {
            Header: "Last Updated",
            accessor: "updatedAt",
            minWidth: 130,
            Cell: ({ row }) =>
                row.original?.updated_at &&
                dayjs(row?.original?.updated_at).format("DD MMM YYYY" + ' ' + 'HH:mm:ss'),
        },
        {
            Header: "Actions",
            accessor: "actions",
            minWidth: 60,
            Cell: ({ row }) => (
                <MyPopover
                    PopoverParentComp={PopoverParentComp}
                    PopoverChildComp={PopoverChildComp}
                    // isVerify={true}
                    redirectUrl="/view"
                    applicationType="allApplication"
                    selectedData={row.original}
                    isView={true}
                    viewUrl="/view"

                />
            ),
        },
    ];

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async (search) => {
        try {
            const payload = {
                username: userDetail?.username,
                UserId: userDetail?.user_id,
                RoleId: userDetail?.role_id,
                KYCStatus: myString,
                start: 1,
                end: 50,
                search: search || ""
            }
            const response = await postApi_mod(payload, '/GetApplicationDetails');
            if (response?.data.success) {
                setData(response?.data.data)
            }
            else {
                toast('Something went wrong')
            }

        } catch (err) {
            toast(err.message || err.massage || 'Something went wrong')
        }
    };

    const handleSearch = ({ searchVal }) => {
        fetchData(searchVal);
    };

    return (
        <div className="w-full py-5 px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
            <div className="flex items-center justify-between mb-3">
                <div className="lg:flex lg:flex-row lg:items-center items-left">
                    <h6 className="font-semibold text-[22px] leading-[33px] mr-2.5">{myString} Application </h6>
                </div>
            </div>
            <SearchBar border={"border"}
                data={data}
                handleSelect={handleSearch} />
            <ReactTable columns={columns} data={data || []} />
        </div>
    );
};
