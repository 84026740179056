import React from 'react';

export const Downloadform = () => {
  const baseURL = 'https://ekycapi.bigul.co/resources/FORMAT/';

 const forms = [
    { name: 'CDSL Transmission Request Form (Death of one of the joint holders)', file: 'CDSL TRANSMISSION REQUEST FORM (Death of one of the joint holders)1.pdf' },
    { name: 'CDSL Transmission Request Form (Death of sole holder)', file: 'CDSL TRANSMISSION REQUEST FORM (Death of sole holder).pdf' },
    { name: 'Closure', file: 'CLOSURE.pdf' },
    { name: 'Family Declaration', file: 'Family_Declaration.pdf' },
    { name: 'Income & Modification Form', file: 'INCOME & MODIFICATION FORM.pdf' },
    { name: 'Internet Trading Facility Form', file: 'INTERNET TRADING FACILITY FORM.pdf' },
    { name: 'KRA Form', file: 'KRA FORM.pdf' },
    { name: 'Minor Correction in Name NSDL', file: 'MINOR CORRECTION IN NAME NSDL.pdf' },
    { name: 'Minor Correction Name CDSL', file: 'MINOR CORRECTION NAME CDSL.pdf' },
    { name: 'New Closer NSDL+Trading', file: 'NEW CLOSER NSDL+TRADING .pdf' },
    { name: 'New Derivatives Segment Request', file: 'NEW DERIVATIVES SEGMENT REQUEST.pdf' },
    { name: 'New Modification Request', file: 'NEW MODIFICATION REQUEST.pdf' },
    { name: 'No-Nominee Declaration', file: 'NO -NOMINEE DECLARATION.pdf' },
    { name: 'Nominee Request', file: 'NOMINEE_ REQUEST .pdf' },
    { name: 'NSDL Transmission File Format Form 30', file: 'NSDL TRANSMISSION FILE FORMAT FORM 30.pdf' },
    { name: 'PAN Change Form', file: 'PAN CHANGE FORM.pdf' },
    { name: 'Second Holder KRA', file: '2nd_Holder_Kra.pdf' },
    { name: 'CDSL and NSDL Sign Change Request', file: 'CDSL AND NSDL SIGN CHANGE REQUEST.pdf' },
  ];

  
  const handleOpenForm = (file) => {
    window.open(`${baseURL}${file}`, '_blank');
  };

  return (
    <div className="form-list">
      {forms.map((form, index) => (
        <div key={index} className="form-item" style={{ width: '80%',}}>
          <div className="form-box" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
            <h2>{form.name}</h2>
            <div style={{backgroundColor:'black',padding:5,borderRadius:10,cursor:'pointer',width:'100px'}} onClick={() => handleOpenForm(form.file)}>
            <p style={{color:'white',textAlign:'center'}}>Open</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
