import axios from 'axios';
import { TOKEN } from '../config/config';


export const generateTokenService = async (payload) => {
	const headers = {
		headers: {
			'Content-Type': 'application/json'
		}
	};
	const user = await axios.post(
		`${TOKEN}/ApiToken`,
		{
			email: 'dkyc@bonanzaonline.com',
			password: 'password'
		},
		headers
	);
	return user;
};
