export const generateToken = 'GENERATE_TOKEN';
export const setToken = 'SET_TOKEN';
export const loginUser = 'LOGIN_USER';
export const setLoginUser = 'SET_LOGIN_USER';
export const clearLoginInput = 'CLEAR_LOGIN_INPUT';
export const changeLoginInput = 'CHANGE_LOGIN_INPUT';
export const changeLoginError = 'CHANGE_LOGIN_ERROR';
export const showPassword = 'SHOW_PASSWORD';
export const forgotPassword = 'FORGOT_PASSWORD';
export const resetPassword = 'RESET_PASSWORD';

export const generateTokenAction = (callback) => ({
	type: generateToken,
	callback
});

export const setTokenAction = (payload, callback) => ({
	type: setToken,
	payload,
	callback
});

export const clearLoginInputAction = (payload) => ({
	type: clearLoginInput,
	payload
});

export const changeLoginInputAction = (payload) => ({
type: changeLoginInput,
	payload
	
});

export const changeLoginErrorAction = (payload) => ({
	type: changeLoginError,
	payload 
});

export const showPasswordAction = (payload) => ({
	type: showPassword,
	payload
});

export const loginUserAction = (payload, callback) => ({
	type: loginUser, 
	payload,
	callback
});

export const setLoginUserAction = (payload, callback) => ({
	type: setLoginUser,
	payload,
	callback
});

export const forgotPasswordAction = (payload, callback) => ({
	type: forgotPassword,
	payload,
	callback
});

export const resetPasswordAction = (payload, callback) => ({
	type: resetPassword,
	payload,
	callback
});
