import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep4Error, clearSignature, uploadSignature } from '../../actions/dkyc/step4.action';

const mutableState = {
	signatureImage: null,
	isError: false,
	errorText: '',
	isSign:false
};
const initState = {
	...mutableState
};

export const dkycStep4Reducer = (state = initState, action) => {
	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);

	const { isError, errorText } = action?.payload || {};
	switch (action.type) {
	case clearKyc:
		return {
			...mutableState
		};
	case changeStep4Error:
		return {
			...state,
			isError,
			errorText
		};
	case uploadSignature:
		return {
			...state,
			signatureImage: imageUrl || null,
			isError: false,
			errorText: '',
			isSign:true
		};
	case clearSignature:
		return {
			...state,
			signatureImage: null,
			isError: false,
			errorText: '',
			isSign:false
		};
	default:
		return state;
	}
};
