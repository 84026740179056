import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../../components/common/Checkbox';

import { MainTitle } from '../../../components/common/MainTitle';
import { RemoveSegmentModal } from '../../../components/modal/kyc/RemoveSegmentModal';
import { useEffect, useState } from 'react';
import { setVerifyApplication2Action, setVerifyApplicationAction } from '../../../redux/actions/kyc/verify.action';

export const Segment = ({ reviewData, remove }) => {
	const Equity = reviewData?.Equity?.split(',').map(item => item.trim()) || [];
	// const Derivative = reviewData?.Derivative !== '' && reviewData?.Derivative !== null 
	const Derivative = reviewData?.Derivative?.includes('NSEC' && 'BSEC')
	const Commodity = reviewData?.Commodity?.includes('MCX' && 'NCDX' && 'BCX' && 'NCX')
	const MF = reviewData?.AccountType?.includes('MF');
	const Futur = reviewData?.Derivative?.includes('NSEF' && 'BSEF');
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});

	const postData = {
		panNo: reviewData?.PanNo
	};
	const cl_id = {
		ClientId: reviewData?.ClientId
	};


	const [segmentModal, setSegmentModal] = useState({
		isOpen: false,
		isKra: false
	});
	const handleKycModelToggle = (isOpen) => {
		setSegmentModal(prevState => ({
			...prevState,
			isOpen: isOpen
		}));
	};
	return (
		<>

			<div className='d-flex justify-content-between' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<MainTitle title="Segment" />
				{
					remove &&
						(userDetail?.role_id == 6) &&
						(reviewData?.Derivative !== null || reviewData?.Commodity !== null) ?
						<button className='bg-black mb-3 text-white px-5 py-2 rounded' onClick={() => {
							handleKycModelToggle(true);

						}}>Remove Segment</button>
						: null
				}

			</div>
			{
				// (reviewData?.Derivative !== null || reviewData?.Commodity !==null ) ?
				<div className='flex items-center'>
					<div className="w-full px-3.5 py-[5px] mb-6 grid grid-cols-5">
						<Checkbox
							labelText="Equity"
							labelSize="16px"
							labelWeight="400"
							labelFamily="font-poppinsRegular"
							marginBottom="mb-4"
							value="equity"
							isChecked={Equity}
							isDisable={true}
						/>
						<Checkbox
							labelText="Commodities"
							labelSize="16px"
							labelWeight="400"
							labelFamily="font-poppinsRegular"
							marginBottom="mb-4"
							value="commodity"
							isChecked={Commodity}
							isDisable={true}

						/>
						<Checkbox
							labelText="Currency & Derivatives"
							labelSize="16px"
							labelWeight="400"
							labelFamily="font-poppinsRegular"
							marginBottom="mb-4"
							value="CurrencyAndDerivatives"
							isChecked={Derivative}
							// isDisable={derivative?.isDisable}

							isDisable={true}
						/>
						<Checkbox
							labelText="Future and Option"
							labelSize="16px"
							labelWeight="400"
							labelFamily="font-poppinsRegular"
							marginBottom="mb-4"
							value="FutureAndOption"
							isChecked={Futur}
							isDisable={true}
						/>
						<Checkbox
							labelText="Mutual fund"
							labelSize="16px"
							labelWeight="400"
							labelFamily="font-poppinsRegular"
							marginBottom="mb-4"
							value="mutualFund"
							isDisable={true}
							isChecked={MF}

						/>

					</div>
				</div>
				// 		:
				// 		<div className='flex items-center'>
				// <div className="w-full px-3.5 py-[5px] mb-6 grid grid-cols-5">
				// 			<Checkbox
				// 				labelText="Equity"
				// 				labelSize="16px"
				// 				labelWeight="400"
				// 				labelFamily="font-poppinsRegular"
				// 				marginBottom="mb-4"
				// 				value="equity"
				// 				isChecked={true}
				// 				isDisable={true}
				// 			/>
				// 			<Checkbox
				// 				labelText="Commodities"
				// 				labelSize="16px"
				// 				labelWeight="400"
				// 				labelFamily="font-poppinsRegular"
				// 				marginBottom="mb-4"
				// 				value="commodity"
				// 				isChecked={false}
				// 				isDisable={true}

				// 			/>
				// 			<Checkbox
				// 				labelText="Currency & Derivatives"
				// 				labelSize="16px"
				// 				labelWeight="400"
				// 				labelFamily="font-poppinsRegular"
				// 				marginBottom="mb-4"
				// 				value="CurrencyAndDerivatives"
				// 				isChecked={false}
				// 				// isDisable={derivative?.isDisable}

				// 				isDisable={true}
				// 			/>
				// 			<Checkbox
				// 				labelText="Future and Option"
				// 				labelSize="16px"
				// 				labelWeight="400"
				// 				labelFamily="font-poppinsRegular"
				// 				marginBottom="mb-4"
				// 				value="FutureAndOption"
				// 				isChecked={false}
				// 				isDisable={true}
				// 			/>
				// 			<Checkbox
				// 				labelText="Mutual fund"
				// 				labelSize="16px"
				// 				labelWeight="400"
				// 				labelFamily="font-poppinsRegular"
				// 				marginBottom="mb-4"
				// 				value="mutualFund"
				// 				isDisable={false}
				// 				isChecked={false}

				// 			/>

				// 		</div>
				// 		</div>
			}


			<RemoveSegmentModal
				isModalOpen={segmentModal.isOpen}
				modalToggle={handleKycModelToggle}
				postData={postData}
				cl_id={cl_id}
				data={reviewData}
			/>
		</>
	);
};
