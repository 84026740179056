// import { useDispatch, useSelector } from 'react-redux';
// import { useEffect, useState, useRef } from 'react';
// import { Declaration } from './Declaration';
// import { Checkbox } from '../../../components/common/Checkbox';
// import { MainTitle } from '../../../components/common/MainTitle';
// import { Transaction } from './Transaction';
// import { Segment } from './Segment';
// import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
// import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
// import { changeStep6ErrorAction, changeStep6InputAction, getBrokerageSchemeDetailDataAction, getDeclarationListAction, getDPTariffDetailAction, getIncomeProofListAction, getTransactionDetailAction, saveBrokerageInfoAction, saveBusinessInfoAction, saveDeclarationInfoAction, saveSegmentInfoAction, updateSegmentInfoAction, updateTermsAndConditionValueAction, uploadBankProofAction, callSendUploadIncomeProofLinkAction, getBrokerageSchemeListAction } from '../../../redux/actions/kyc/step6.action';

// import { BackBtn } from '../../../components/buttons/BackBtn';
// import { validateCommonInput } from '../../../utils/verifyInput';
// import { BrokrageDropdown } from '../../../components/dropdown/brokrageDropdown';
// import './Model.css'
// import { addSegmentInfoAction } from '../../../redux/actions/kyc/step2.action';
// import { BranchCodeDropdown } from '../../../components/dropdown/branchCodeDropdown';
// import { getApi, getApiWithoutLoader, postApi, postApiWithoutLoader, validatePanDetails1 } from '../../../services/common';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Nominee2 } from './Nominee2';
// import InputMask from "react-input-mask";
// import loader from '../../../assets/gif/Dark Theme/Circle Loader.gif';


// export const BrokerageStep = ({ step, index, backBtn, continueBtn }) => {
//     const dispatch = useDispatch();
//     const myRef = useRef(null);
//     const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
//     // const BRANCH = useSelector(state => state?.kyc?.channel || '')
//     const channel = useSelector(state => state?.kyc?.channel || '');
//     const { mobileInput } = useSelector(state => state?.kycStep1 || {});
//     const { transactionData, segments, brokerageScheme, dpTariff, declaration, incomeProof, termsAndCondition } = useSelector(state => state?.kycStep6);
//     const [isSchemesLoading, setIssSchemesLoading] = useState(true);
//     const [isUpload3MonthSalarySlip, setIsUpload3MonthSalarySlip] = useState(false);
//     const transactionList = transactionData?.data || [];
//     const brokerageSchemeList = brokerageScheme?.scheme_list || [];
//     const dpTariffList = dpTariff?.data || [];
//     const declarationList = declaration?.data || [];
//     const incomeProofList = incomeProof?.data || [];
//     const kycStep2 = useSelector(state => state?.kycStep2 || {});
//     const kycStep1 = useSelector(state => state?.kycStep1 || {});
//     const kycStep6 = useSelector(state => state?.kycStep6 || {});
//     const [currencyFuture, setCurrencyFuture] = useState('')
//     const [delevery, setDelevery] = useState('')
//     const [currencyOption, setCurrencyOption] = useState('')
//     const [future, setFuture] = useState('')
//     const [mcxfuture, setMcxFuture] = useState('')
//     const [option, setOption] = useState('')
//     const [mcxOption, setMcxOption] = useState('')
//     const [brancCodeselect, setBranchCode] = useState()
//     const [schemeSelect, setSelectScheme] = useState('custom')
//     const [schemeSelectType, setSelectSchemeType] = useState('')
//     const [deliveryList, setDeliveryList] = useState([])
//     const [poData, setPodata] = useState([])
//     const [poData1, setPodata1] = useState([])
//     const [schemeType, setSchemeType] = useState([])
//     const [error, setError] = useState('')
//     const [accout, setAccount] = useState('')
//     const [kra, setKra] = useState({})
//     const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
//     const [selectedOption, setSelectedOption] = useState('no');
//     const [isVisible, setIsVisible] = useState(true);
//     const [relationDta, setRelationData] = useState([]);
//     const [fullName, setFullName] = useState('')
//     const [dob, setDob] = useState('')
//     const [email, setEmail] = useState('')
//     const [mobile, setMobile] = useState('')
//     const [address1, setAddress1] = useState('')
//     const [address2, setAddress2] = useState('')
//     const [address3, setAddress3] = useState('')
//     const [pincode, setPincode] = useState('')
//     const [pan, setPan] = useState('')
//     const [state, setState] = useState('')
//     const [city, setCIty] = useState('')
//     const [share, setShare] = useState(100)
//     const [selectedIdentification, setSelectedIdentification] = useState('');
//     const [selectedRelation, setSelectedRelation] = useState(1);
//     const [isChecked, setIsChecked] = useState(true);
//     const [smallLoaderActive, setSmallLoaderActive] = useState(false);
//     const [check1, setCheck] = useState(false);
//     const [dpCode, setDpCode] = useState(null)
//     const filteredOptions = dpTariffList?.filter(option => option?.label != "999NEW" && option?.label !== "333" && option?.label !== 'FreeScheme');
//     const filteredOptions2 = dpTariffList?.filter(option => option?.label != "BIGULIND");

//     const handleCheckboxChange = () => {
//         setIsChecked(!isChecked);
//     };



//     useEffect(() => {
//         if (!isChecked) {
//             setAddress1('');
//             setAddress2('');
//             setAddress3('');
//             setPincode('');
//             setState('');
//             setCIty('');
//         }
//     }, [isChecked]);



//     useEffect(() => {
//         if (selectedOption == 'yes') {
//             DetailsApi()
//             RelationApi()
//         }
//     }, [selectedOption])

//     useEffect(() => {
//         if (isChecked) {
//             RelationApi()
//             DetailsApi()
//         }

//     }, [isChecked])


//     const RelationApi = async () => {
//         const response = await getApiWithoutLoader('/GetRelationMaster ');
//         if (response?.data?.success) {
//             setRelationData(response?.data?.data)
//         } else {
//             setRelationData([])
//         }
//     };

//     const DetailsApi = async (id) => {
//         setSmallLoaderActive(true)
//         const payload = {
//             ClientId: kra?.data[0]?.client_id,
//         }
//         const response = await postApiWithoutLoader(payload, '/GetCltFullInfo ');
//         const nominee = response?.data.data.nominee_arr
//         if (response?.data?.success) {
//             setSmallLoaderActive(false)

//             if (nominee == null) {
//                 setAddress1(response?.data?.data?.PRAddr1 || '');
//                 setAddress2(response?.data?.data?.PRAddr2 || '');
//                 setAddress3(response?.data?.data?.PRAddr3 || '');
//                 setPincode(response?.data?.data?.CRPinCode || '');

//             }
//             else {
//                 setSmallLoaderActive(false)
//                 // setIsChecked(false)
//                 setFullName(nominee[0]?.nm_name)
//                 setSelectedIdentification(nominee[0]?.nm_panno == null ? "Aadhaar" : 'PAN')
//                 setPan(nominee[0].nm_aadhaar_no == null ? nominee[0].nm_panno : nominee[0].nm_aadhaar_no)
//                 setEmail(nominee[0].email)
//                 setMobile(nominee[0].mobile)
//                 setDob(nominee[0]?.birthdate)
//                 setSelectedRelation(nominee[0].relation)
//                 setAddress1(nominee[0].addr1)
//                 setAddress2(nominee[0].addr2)
//                 setAddress3(nominee[0].addr3)
//                 setPincode(nominee[0].pinCode)
//                 setState(nominee[0].state)
//                 setCIty(nominee[0].city)
//                 setShare(Math.floor(nominee[0].sharing))
//             }

//         } else {
//             setSmallLoaderActive(false)
//             toast(response?.message || 'something went wrong')
//         }
//     };
//     const DetailsApi1 = async (id) => {
//         const payload = {
//             ClientId: id,
//         }
//         const response = await postApiWithoutLoader(payload, '/GetCltFullInfo ');
//         if (response?.data.data.nominee_arr !== null) {
//             setSelectedOption('yes')


//         }
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             if (selectedIdentification === "PAN" && pan.length == 10) {
//                 const response = await validatePanDetails1({ pan });
//                 if (response?.data?.status == true || (response?.data?.status == false && response?.data?.message != 'Invalid Pan Number')) {
//                     if (response?.data?.data?.Name) {
//                         setFullName(response?.data?.data?.Name);
//                     }
//                     if (response?.data?.data[0]?.Name) {
//                         setFullName(response?.data?.data[0]?.Name);
//                     }
//                 }
//                 else {
//                     toast('please enter valid pan number')
//                 }


//             }
//         };

//         fetchData();
//     }, [pan]);

//     useEffect(() => {
//         if (selectedIdentification == 'PAN' && pan?.length != 10) {
//             setFullName('')
//         }
//     }, [pan]);


//     useEffect(() => {
//         if (selectedIdentification == "Aadhaar") {
//             setFullName('')
//             setPan('')

//         }
//     }, []);

//     const PincodeApi = async (pin, num) => {
//         setCheck(false)
//         const response = await getApiWithoutLoader('/GetPincodeMaster?PinCode=' + pin);
//         if (response?.data?.success) {
//             setCheck(true)
//             if (num == 1) {
//                 setCIty(response?.data?.data?.[0]?.name.split('/')[1].trim())
//                 setState(response?.data?.data?.[0]?.name.split('/')[2].trim())
//             }

//         }
//     };

//     useEffect(() => {
//         PincodeApi(pincode, 1)
//     }, [pincode?.length == 6])

//     useEffect(() => {
//         if (pincode.length < 6) {
//             setState('')
//             setCIty('')
//         }

//     }, [pincode])
//     const handleShareChange = (e) => {
//         const value = Number(e.target.value);

//         // Check if the entered value is within the range 1 to 100
//         if (value >= 1 && value <= 100) {
//             setShare(value);
//         }
//     };

//     const toggleVisibility = () => {
//         setIsVisible(!isVisible);
//     };


//     const handleIdentificationChange = (event) => {
//         setSelectedIdentification(event.target.value);
//         if (event.target.value == '') {
//             setFullName('')
//         } // Update the selected identification in the state
//         if (event.target.value == "Aadhaar") {
//             setFullName('')
//             setPan('')
//         } // Update the selected identification in the state
//         if (event.target.value == "PAN") {
//             setFullName('')
//             setPan('')
//         } // Update the selected identification in the state
//     };

//     const handleRelationChange = (event) => {
//         setSelectedRelation(event.target.value); // Update the selected relation in the state
//     };

//     const [scheme] = useState([
//         { id: '34', name: 'Scheme' }])
//     const m = userDetail?.BranchCode?.split(',')
//     const branchCode = m
//     const { derivative, commodity, mutualFund, mutualFund1 } = segments;

//     useEffect(() => {
//         fetchData()
//         GetBrkgPlanMaster1()
//         check()
//     }, [])

//     useEffect(() => {
//         brocrageData()
//         KycJourney()
//         brocrageData1()
//     }, [])

//     const fetchData = async () => {
//         try {
//             const response = await getApiWithoutLoader('/get-delivery-brokerage');
//             if (response?.data?.success == true) {
//                 setDeliveryList(response?.data)
//             }
//             else {
//                 setError('something went wrong')
//                 // setTimeout(() => {
//                 //     backBtn({ step, index });
//                 // }, 5000);
//             }
//         }
//         catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//     const KycJourney = async () => {
//         try {
//             const payload = {
//                 mobile: kycStep1?.mobileInput?.value,
//                 journey: "DKYC",

//             }
//             const response = await postApi(payload, '/getKycJourney');
//             if (response.data.success) {
//                 setKra(response.data)
//                 DetailsApi1(response?.data.data[0].client_id)
//             }
//         }
//         catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };


//     const brocrageData = async () => {
//         try {
//             const payload = {
//                 brok_type: 'op',
//                 journey: "DKYC",
//                 mobile: kycStep1.mobileInput.value
//             }
//             const response = await postApiWithoutLoader(payload, '/get-intrade-brokerage');
//             if (response?.data?.success == true) {
//                 setPodata(response?.data)
//             }
//             else {
//                 setError('something went wrong')
//                 // setTimeout(() => {
//                 //     backBtn({ step, index });
//                 // }, 5000);
//             }
//         }
//         catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//     const brocrageData1 = async () => {
//         try {
//             const payload = {
//                 "UserId": userDetail?.user_id,
//                 "BrokerageType": "Future",
//                 "Segment": "Future and Option"
//             }
//             const response = await postApiWithoutLoader(payload, '/GetBrkgPlanSchemeDetails ');
//             if (response?.data?.success == true) {
//                 setPodata1(response?.data)
//             }
//             else {
//                 setError('something went wrong')
//                 // setTimeout(() => {
//                 //     backBtn({ step, index });
//                 // }, 2000);
//             }
//         }
//         catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//     const check = async () => {
//         try {
//             const payload = {

//                 "branch_code": userDetail?.BranchCode,
//                 Type: channel,
//                 journey: "DKYC",
//                 mobile: kycStep1.mobileInput.value
//             }

//             const response = await postApiWithoutLoader(payload, '/checkBranchAvailability ');
//             if (response?.data?.success == true) {
//                 setAccount(response?.data.data)

//             }
//             else {
//                 setError('something went wrong')
//                 // setTimeout(() => {
//                 //     backBtn({ step, index });
//                 // }, 500);
//             }
//         }
//         catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     const GetBrkgPlanMaster1 = async () => {
//         const payload =
//         {
//             "UserId": userDetail?.user_id,
//             "Segment": "equity",
//             journey: "DKYC",
//             mobile: kycStep1.mobileInput.value
//         }
//         try {
//             const response = await postApiWithoutLoader(payload, '/GetRMBrkgPlanMaster ');
//             if (response?.data?.success == true) {
//                 setSchemeType(response?.data?.data)
//             }
//             else {
//                 console.log('error')
//             }

//         }
//         catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

// const handleInput = ({ type, value }) => {
//         dispatch(changeStep6InputAction({ type, value }));
//         if (type == 'SchemeType') {
//             setIssSchemesLoading(true);
//             dispatch(getBrokerageSchemeDetailDataAction(
//                 {
//                     "UserId": userDetail?.user_id,
//                     "BrokerageType": value,
//                     "segmentType": 'equity',
//                     journey: "DKYC",
//                     mobile: kycStep1.mobileInput.value
//                 }

//             ));
//             setSelectSchemeType(value)
//             setTimeout(() => {
//                 setIssSchemesLoading(false);
//             }, 1000);
//         } else if (type == 'incomeProof') {
//             if (value == 'Latest 3 Month Salary Slip') {
//                 setIsUpload3MonthSalarySlip(true);
//             } else {
//                 setIsUpload3MonthSalarySlip(false);
//             }
//         }
//         else if (type == 'dpTariff') {
//             // setModelBrokerage(true)
//         }
//         else if (type == 'Currency_Future') {
//             setCurrencyFuture(value)
//         }
//         else if (type == 'Delevery') {
//             setDelevery(value)
//         }
//         else if (type == 'Currency_Option') {
//             setCurrencyOption(value)
//         }
//         else if (type == 'Future') {
//             setFuture(value)
//         }
//         else if (type == 'mcx_future') {
//             setMcxFuture(value)
//         }
//         else if (type == 'Option') {
//             setOption(value)
//         }
//         else if (type == 'mcx_option') {
//             setMcxOption(value)
//         }
//         else if (type == 'BranchCode') {
//             setBranchCode(value)
//         }
//         else if (type == 'Scheme') {
//             setSelectScheme(value)
//         }

//     };

//     const handleInputError = ({ type, isError, errorText }) => {
//         dispatch(changeStep6ErrorAction({ type, isError, errorText }));
//     };

//     const handleTermsAndCondition = ({ isChecked }) => {
//         dispatch(updateTermsAndConditionValueAction({ data: !isChecked }));
//     };

//     const executeScroll = () => myRef.current.scrollIntoView();

//     const isValidEmail = (email) => {
//         // Regular expression for basic email validation
//         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         return emailPattern.test(email);
//     };

//     const getCurrentDate = () => {
//         const today = new Date();
//         const year = today.getFullYear();
//         let month = today.getMonth() + 1; // Months are zero indexed
//         let day = today.getDate();

//         // Pad single digit months/days with leading zeros
//         if (month < 10) {
//             month = '0' + month;
//         }
//         if (day < 10) {
//             day = '0' + day;
//         }

//         return `${year}-${month}-${day}`;
//     };
//     const handleContinueBtn = async () => {
//         if (dpTariff.value.length > 0) {
//             if (schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Delivery : channel == 'Bigul' ? 'ORD18' : delevery != undefined) {
//                 if (selectedOption == 'yes') {
//                     if (!selectedIdentification) {
//                         toast('Please select Identification');
//                         return;
//                     }
//                     if (!pan) {
//                         toast('Please enter Identification number');
//                         return;
//                     }
//                     if (selectedIdentification == "PAN" && pan?.length != 10) {
//                         toast('Please enter valid Pan');
//                         return;
//                     }
//                     if (selectedIdentification == "Aadhaar" && pan?.length != 12) {
//                         toast('Please enter valid Addhar');
//                         return;
//                     }
//                     if (share != 100) {
//                         toast('Nominee Percentage should be 100');
//                         return;
//                     }
//                     if (!fullName) {
//                         toast('Please Enter Full Name')
//                         return
//                     }
//                     if (!dob) {
//                         toast('Please ENter DOB')
//                         return
//                     }
//                     if (dob > getCurrentDate()) {
//                         toast('Please Enter correct date of birth')
//                         return
//                     }
//                     if (!isValidEmail(email)) {
//                         toast('Please Enter a Valid Email Address');
//                         return;
//                     }
//                     if (!mobile || mobile?.length != 10) {
//                         toast('Please Enter a Valid Mobile umber');
//                         return;
//                     }
//                     if (!address1) {
//                         toast('Please enter address1');
//                         return;
//                     }
//                     if (!address2) {
//                         toast('Please enter address2');
//                         return;
//                     }
//                     if (!pincode) {
//                         toast('Please enter pincode');
//                         return;
//                     }
//                     if (!state) {
//                         toast('Please enter correct pincode');
//                         return;
//                     }
//                     if (!city) {
//                         toast('Please enter city');
//                         return;
//                     }

//                     const payload = {
//                         "panNo": clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                         "nominee_arr": [{
//                             "fullName": fullName,
//                             "dob": dob,
//                             "email": email,
//                             "mobile": mobile,
//                             "relation": selectedRelation,
//                             "identification": selectedIdentification == 'PAN' ? 'Pan' : 'Aadhaar',
//                             "identificationNumber": pan,
//                             "sameAddress": true,
//                             "address1": address1,
//                             "address2": address2,
//                             "address3": address3,
//                             "pincode": pincode,
//                             "state": state,
//                             "city": city,
//                             "sharing": share,
//                             journey: 'Dkyc'
//                         },

//                         ]
//                     };

//                     const response = await postApi(payload, '/AddNomineeInfo');
//                     if (response.data.success) {
//                         const { derivative, commodity, mutualFund } = segments;
//                         const brokerageSchemeValue = brokerageScheme?.value || '';
//                         const dpTariffValue = dpTariff?.value || '';
//                         const incomeProofValue = incomeProof?.value || '';
//                         const isValidBrokerageScheme = validateCommonInput(brokerageSchemeValue);
//                         const isValidDpTariffValue = validateCommonInput(dpTariffValue);
//                         const mFselect = mutualFund1.isSelected ? ["TRADING", accout, "MF"] : ["TRADING", accout];
//                         const channelselect = 'Bigul' || 'BIGUL'
//                         dispatch(addSegmentInfoAction({
//                             PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                             AccountType: mFselect,
//                             Equity: ["NSE", "BSE"],
//                             Derivative: (derivative.isSelected && mutualFund.isSelected) ? ['NSEC', 'BSEC', 'NSEF', 'BSEF'] : derivative.isSelected ? ['NSEC', 'BSEC'] : mutualFund.isSelected ? ['NSEF', 'BSEF'] : null,
//                             Commodity: commodity.isSelected ? ['MCX', 'NCDX', 'BCX', 'NCX'] : null,
//                             Mutualfund: mutualFund1.isSelected ? 'Yes' : 'No',
//                             UserId: userDetail.user_id,
//                             BranchCode: '',
//                             LDCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                             TerminalCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                             channel: channel,
//                             IsETrade: channel == channelselect ? 90 : null,
//                             journey: "DKYC",
//                             mobile: kycStep1.mobileInput.value,
//                             dpCOde: dpCode,
//                             dpId: dpCode != null ? '12033500' : null
//                         }, CallbackAddsegment));
//                         if (isValidBrokerageScheme && isValidDpTariffValue) {
//                         } if (!isValidBrokerageScheme) {
//                             handleInputError({ type: 'brokerageScheme', isError: true, errorText: 'Invalid Scheme!' });
//                             executeScroll();
//                         } if (!isValidDpTariffValue) {
//                             handleInputError({ type: 'dpTariff', isError: true, errorText: 'Invalid input!' });
//                             executeScroll();
//                         }
//                     }
//                     else {
//                         toast(response?.data?.message)
//                     }

//                 }
//                 else {
//                     const { derivative, commodity, mutualFund } = segments;
//                     const brokerageSchemeValue = brokerageScheme?.value || '';
//                     const dpTariffValue = dpTariff?.value || '';
//                     const incomeProofValue = incomeProof?.value || '';
//                     const isValidBrokerageScheme = validateCommonInput(brokerageSchemeValue);
//                     const isValidDpTariffValue = validateCommonInput(dpTariffValue);
//                     const mFselect = mutualFund1.isSelected ? ["TRADING", accout, "MF"] : ["TRADING", accout];
//                     const channelselect = 'Bigul' || 'BIGUL'
//                     dispatch(addSegmentInfoAction({
//                         PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                         AccountType: mFselect,
//                         Equity: ["NSE", "BSE"],
//                         Derivative: (derivative.isSelected && mutualFund.isSelected) ? ['NSEC', 'BSEC', 'NSEF', 'BSEF'] : derivative.isSelected ? ['NSEC', 'BSEC'] : mutualFund.isSelected ? ['NSEF', 'BSEF'] : null,
//                         Commodity: commodity.isSelected ? ['MCX', 'NCDX', 'BCX', 'NCX'] : null,
//                         Mutualfund: mutualFund1.isSelected ? 'Yes' : 'No',
//                         UserId: userDetail.user_id,
//                         BranchCode: '',
//                         LDCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                         TerminalCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                         channel: channel,
//                         IsETrade: channel == channelselect ? 90 : null,
//                         journey: "DKYC",
//                         mobile: kycStep1.mobileInput.value,
//                         dpCOde: dpCode,
//                         dpId: dpCode != null ? '12033500' : null
//                     }, CallbackAddsegment));

//                     if (isValidBrokerageScheme && isValidDpTariffValue) {
//                     } if (!isValidBrokerageScheme) {
//                         handleInputError({ type: 'brokerageScheme', isError: true, errorText: 'Invalid Scheme!' });
//                         executeScroll();
//                     } if (!isValidDpTariffValue) {
//                         handleInputError({ type: 'dpTariff', isError: true, errorText: 'Invalid input!' });
//                         executeScroll();
//                     }
//                 }
//             }
//             else {
//                 toast('Scheme is not avilabale  for your branch please contact support team')
//             }

//         }
//         else {
//             toast('Please select DP Tarriff')
//         }

//     };

//     const CallbackAddsegment = (resp) => {
//         const transactionValue = transactionData.value.toString();
//         if (resp == 'success') {
//             dispatch(saveBusinessInfoAction({
//                 PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                 ContractType: 'ECN',
//                 RunningActAuth: 'Quarterly',
//                 TradingAct: 'Both',
//                 MobileTrading: 'Yes',
//                 IsDPExit: 'No',
//                 DisSlip: 'No',
//                 DDPI: 'Yes',
//                 Ttype: transactionValue,
//                 journey: "DKYC",
//                 mobile: kycStep1.mobileInput.value
//             }, callBackBusinessInfo));
//         }
//         else {
//             toast(resp?.message ? resp?.message : 'something went wrong')
//         }

//     }
//     const callBackBusinessInfo = (response) => {
//         const { derivative, commodity, mutualFund } = segments;
//         const brokerageSchemeValue = brokerageScheme?.value || '';
//         const dpTariffValue = dpTariff?.value || '';

//         if (response.data.success) {
//             dispatch(saveBrokerageInfoAction({
//                 PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                 IsEquity: 'Yes',
//                 IsFuture: mutualFund.isSelected ? 'Yes' : "No",
//                 IsCurrency: derivative.isSelected ? 'Yes' : "No",
//                 IsCommodity: commodity.isSelected ? 'Yes' : "No",
//                 Derivatives: derivative.isSelected ? 'Yes' : "No",
//                 Mutualfund: mutualFund1.isSelected ? 'Yes' : "No",
//                 // FutureandOption: 'Yes',
//                 BrokerageScheme: schemeSelectType ? schemeSelectType : "MyeTrade_PlanA",
//                 EquityDelivery: schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Delivery : channel == 'Bigul' ? 'ORD18' : delevery,
//                 EquityTrading: schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Intraday : channel == 'Bigul' ? 'ORD18' : brokerageSchemeValue,
//                 EquityFuture: (schemeSelect == 'Scheme' && segments.mutualFund.isSelected) ? brokerageScheme?.scheme_details?.data?.Future_and_Option_Future : segments.mutualFund.isSelected ? channel == 'Bigul' ? 'ORD18' : future : null,
//                 EquityOption: (schemeSelect == 'Scheme' && segments.mutualFund.isSelected) ? brokerageScheme?.scheme_details?.data?.Future_and_Option_Option : segments.mutualFund.isSelected ? channel == 'Bigul' ? 'ORD18' : option : null,
//                 DPTariff: dpTariffValue,
//                 CurrencyFuture: (schemeSelect == 'Scheme' && segments.derivative.isSelected) ? brokerageScheme?.scheme_details?.data?.Currency_Future : segments.derivative.isSelected ? channel == 'Bigul' ? 'ORD18' : currencyFuture : null,
//                 CurrencyOption: (schemeSelect == 'Scheme' && segments.derivative.isSelected) ? brokerageScheme?.scheme_details?.data?.Currency_Option : segments.derivative.isSelected ? channel == 'Bigul' ? 'ORD18' : currencyOption : null,
//                 McxScheme: schemeSelectType ? schemeSelectType : "No",
//                 McxFuture: (schemeSelect == 'Scheme' && segments.commodity.isSelected) ? brokerageScheme?.scheme_details?.data?.Commodity_Future : segments.commodity.isSelected ? channel == 'Bigul' ? 'ORD18' : mcxfuture : null,
//                 McxOption: (schemeSelect == 'Scheme' && segments.commodity.isSelected) ? brokerageScheme?.scheme_details?.data?.Commodity_Option : segments.commodity.isSelected ? channel == 'Bigul' ? 'ORD18' : mcxOption : null,
//                 trading: schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Intraday : channel == 'Bigul' ? 'ORD18' : brokerageSchemeValue,
//                 journey: "DKYC",
//                 mobile: kycStep1.mobileInput.value

//             }, callbackUpadateSegment));
//         }
//         else {
//             toast('something went wrong')
//         }

//     }

//     const callbackUpadateSegment = (response) => {
//         const incomeProofValue = incomeProof?.value || '';

//         if (response.success == true) {
//             dispatch(saveDeclarationInfoAction({
//                 tnc: 1,
//                 income_proof_type: incomeProofValue,
//                 declaration1: declarationList[0].isSelected ? 'Yes' : 'No',
//                 declaration2: declarationList[1].isSelected ? 'Yes' : 'No',
//                 declaration3: declarationList[2].isSelected ? 'Yes' : 'No',
//                 declaration3_option: declarationList[2].selectedOption,
//                 declaration4: declarationList[3].isSelected ? 'Yes' : 'No',
//                 declaration5: declarationList[4].isSelected ? 'Yes' : 'No',
//                 declaration6: declarationList[5].isSelected ? 'Yes' : 'No',
//                 declaration7: declarationList[6].isSelected ? 'Yes' : 'No',
//                 declaration8: declarationList[7].isSelected ? 'Yes' : 'No',
//                 declaration8_option: declarationList[7].selectedOption,
//                 declaration9: declarationList[8].isSelected ? 'Yes' : 'No',
//                 mobile: mobileInput?.value,
//                 pan: clientKycStatus?.data?.pan || kycStep2.panInput.value,
//                 IsEquity: 'Yes',
//                 IsFuture: mutualFund.isSelected ? 'Yes' : 'No',
//                 IsCurrency: derivative.isSelected ? 'Yes' : 'No',
//                 IsCommodity: commodity.isSelected ? 'Yes' : 'No',
//                 Derivatives: derivative.isSelected ? 'Yes' : 'No',
//                 Mutualfund: mutualFund1.isSelected ? 'Yes' : 'No',
//                 FutureandOption: mutualFund.isSelected ? 'Yes' : 'No',
//                 journey: "DKYC",
//                 mobile: kycStep1.mobileInput.value
//             }, finalCallback));
//         }
//         else {
//             toast(response?.data?.EquityDelivery || 'something went wrong')
//         }
//     }

//     const finalCallback = async (response) => {
//         const payload = {
//             "PanNo": clientKycStatus?.data?.pan || kycStep2.panInput.value,
//             dpCOde: dpCode,
//             dpId: dpCode != null ? '12033500' : null
//         }

//         if (response.success) {
//             const response = await postApi(payload, '/AddDpidDpcode');
//             if (response.status) {
//                 toast('Scheme verified Succesfully.')
//                 continueBtn({ step, index });
//             }
//             else {
//                 toast('something went wrong')
//             }
//         }
//         else {
//             toast('something went wrong')
//         }
//     }

//     useEffect(() => {
//         transactionList.length < 1 && dispatch(getTransactionDetailAction());
//         dispatch(getBrokerageSchemeListAction({
//             brok_type: 'FU',
//             journey: "DKYC",
//             mobile: kycStep1.mobileInput.value
//         }))
//         // dpTariffList.length < 1 && dispatch(getDPTariffDetailAction());
//         dispatch(getDeclarationListAction());
//         incomeProofList.length < 1 && dispatch(getIncomeProofListAction());

//     }, []);

//     useEffect(() => {
//         dispatch(getDPTariffDetailAction())
//     }, [])

//     const handleOptionChange = (event) => {
//         setSelectedOption(event.target.value);
//     };

//     const handleSubmit = (dpCode) => {
//         setDpCode(dpCode)
//     };


//     return (
//         <div className="w-full flex flex-col ">
//             < ToastContainer autoClose={2000} />
//             <div className="min-h-[calc(100vh-300px)]">
//                 <div ref={myRef} className="w-full mb-6 grid md:grid-cols-2 gap-7">
//                     <Transaction
//                         transactionData={transactionList || []}
//                     />
//                     <Segment />
//                 </div>
//                 <MainTitle title="Brokerage Details" />
//                 <MainTitle title="Brokerage Scheme" />
//                 {
//                     channel == 'Bonanza' &&
//                     <>
//                         <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
//                             <div className="w-full md:col-span-12 mt-4 md:mt-0">
//                                 <BranchCodeDropdown
//                                     placeholder="Please select branch code"
//                                     options={scheme.map((item) => item.name) || []}
//                                     inputType="Scheme"
//                                     channel={channel}
//                                     handleSelect={handleInput}

//                                 />
//                             </div>
//                         </div>
//                         {
//                             schemeSelect == 'Scheme' &&
//                             <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
//                                 <div className="w-full md:col-span-12 mt-4 md:mt-0">
//                                     <BranchCodeDropdown
//                                         placeholder="Please select Scheme"
//                                         options={schemeType.map((item) => item.name) || []}
//                                         inputType="SchemeType"
//                                         channel={channel}
//                                         handleSelect={handleInput}

//                                     />
//                                 </div>
//                             </div>
//                         }
//                     </>

//                 }
//                 {
//                     segments.equity.isSelected &&
//                     <div className="w-full mb-8 md:grid md:grid-cols-12 gap-5">
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Trading:" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select brokerage scheme"
//                                         selectedValue={brokerageScheme?.value}
//                                         options={brokerageSchemeList || []}
//                                         inputType="brokerageScheme"
//                                         handleSelect={handleInput}
//                                         channel={channel}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <>
//                                     <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                         <MainTitle title="Trading:" />
//                                         <div className="w-full flex flex-col">
//                                             <div className="w-full h-full rounded-[10px]">
//                                                 <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                     <input
//                                                         className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                         type="text"
//                                                         value={brokerageScheme?.scheme_details?.data?.Equity_Cash_Intraday}
//                                                         disabled={true}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>
//                         }
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Delivery:" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select Delivery"
//                                         selectedValue={kycStep6?.Delevery?.value}
//                                         options={deliveryList || []}
//                                         inputType="Delevery"
//                                         channel={channel}
//                                         handleSelect={handleInput}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <>
//                                     <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                         <MainTitle title="Delivery:" />
//                                         <div className="w-full flex flex-col">
//                                             <div className="w-full h-full rounded-[10px]">
//                                                 <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                     <input
//                                                         className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                         type="text"
//                                                         value={brokerageScheme?.scheme_details?.data?.Equity_Cash_Delivery}
//                                                         disabled={true}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>
//                         }


//                     </div>
//                 }
//                 {
//                     segments.derivative.isSelected &&
//                     <div className="w-full mb-8 md:grid md:grid-cols-12 gap-5">
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Currency Future :" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select Currency Future"
//                                         selectedValue={kycStep6?.Currency_Future?.value}
//                                         options={brokerageSchemeList || []}
//                                         inputType="Currency_Future"
//                                         handleSelect={handleInput}
//                                         channel={channel}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <>
//                                     <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                         <MainTitle title="Currency Future :" />
//                                         <div className="w-full flex flex-col">
//                                             <div className="w-full h-full rounded-[10px]">
//                                                 <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                     <input
//                                                         className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                         type="text"
//                                                         value={brokerageScheme?.scheme_details?.data?.Currency_Future}
//                                                         disabled={true}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>
//                         }
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Currency Option :" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select Currency Option"
//                                         selectedValue={kycStep6?.Currency_Option?.value}
//                                         options={poData || []}
//                                         inputType="Currency_Option"
//                                         handleSelect={handleInput}
//                                         channel={channel}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Currency Option :" />
//                                     <div className="w-full flex flex-col">
//                                         <div className="w-full h-full rounded-[10px]">
//                                             <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                 <input
//                                                     className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                     type="text"
//                                                     value={brokerageScheme?.scheme_details?.data?.Currency_Option}
//                                                     disabled={true}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                         }

//                     </div>
//                 }
//                 {
//                     segments.mutualFund.isSelected &&
//                     <div className="w-full mb-4">
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6">
//                                     <MainTitle title="Future:" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select Future"
//                                         selectedValue={kycStep6?.Future?.value}
//                                         options={brokerageSchemeList || []}
//                                         inputType="Future"
//                                         channel={channel}
//                                         handleSelect={handleInput}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Future :" />
//                                     <div className="w-full flex flex-col">
//                                         <div className="w-full h-full rounded-[10px]">
//                                             <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                 <input
//                                                     className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                     type="text"
//                                                     value={brokerageScheme?.scheme_details?.data?.Future_and_Option_Future}
//                                                     disabled={true}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                         }
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Option:" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select Option"
//                                         selectedValue={kycStep6?.Option?.value}
//                                         options={poData || []}
//                                         inputType="Option"
//                                         channel={channel}
//                                         handleSelect={handleInput}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Option :" />
//                                     <div className="w-full flex flex-col">
//                                         <div className="w-full h-full rounded-[10px]">
//                                             <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                 <input
//                                                     className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                     type="text"
//                                                     value={brokerageScheme?.scheme_details?.data?.Future_and_Option_Option}
//                                                     disabled={true}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                         }
//                     </div>
//                 }
//                 {
//                     segments.commodity.isSelected &&
//                     <div className="w-full mb-8 md:grid md:grid-cols-12 gap-5">
//                         {schemeSelect == 'custom' ?
//                             <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                 <MainTitle title="Commodity Future :" />
//                                 <BrokrageDropdown
//                                     placeholder="Please select commodity Future"
//                                     selectedValue={kycStep6?.mcx_future?.value}
//                                     options={brokerageSchemeList || []}
//                                     inputType="mcx_future"
//                                     channel={channel}
//                                     handleSelect={handleInput}
//                                     inputMessage={error || ''}
//                                     inputMessageType={error && 'error'}
//                                 />
//                             </div>
//                             :
//                             <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                 <MainTitle title="Commodity Future :" />
//                                 <div className="w-full flex flex-col">
//                                     <div className="w-full h-full rounded-[10px]">
//                                         <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                             <input
//                                                 className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                 type="text"
//                                                 value={brokerageScheme?.scheme_details?.data?.Commodity_Future}
//                                                 disabled={true}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                         }
//                         {
//                             schemeSelect == 'custom' ?
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Commodity Option :" />
//                                     <BrokrageDropdown
//                                         placeholder="Please select commodity Option"
//                                         selectedValue={kycStep6?.mcx_option?.value}
//                                         options={poData || []}
//                                         inputType="mcx_option"
//                                         channel={channel}
//                                         handleSelect={handleInput}
//                                         inputMessage={error || ''}
//                                         inputMessageType={error && 'error'}
//                                     />
//                                 </div>
//                                 :
//                                 <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
//                                     <MainTitle title="Commodity Option :" />
//                                     <div className="w-full flex flex-col">
//                                         <div className="w-full h-full rounded-[10px]">
//                                             <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
//                                                 <input
//                                                     className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
//                                                     type="text"
//                                                     value={brokerageScheme?.scheme_details?.data?.Commodity_Option}
//                                                     disabled={true}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                         }

//                     </div>
//                 }
//                 <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
//                     <div className="w-full md:col-span-12 mt-4 md:mt-0">
//                         <SelectDropdown
//                             label="DP Tariff"
//                             placeholder="Please select dp tariff"
//                             selectedValue={dpTariff?.value}
//                             options={channel == "Bigul" ? filteredOptions : filteredOptions2 || []}
//                             inputType="dpTariff"
//                             handleSelect={handleInput}
//                             inputMessage={dpTariff.errorText || ''}
//                             inputMessageType={dpTariff.isError && 'error'}
//                         />
//                     </div>
//                 </div>
//                 <Nominee2 pan={clientKycStatus?.data?.pan || kycStep2.panInput.value} handlesubmit={handleSubmit} />
//                 {/* <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
//                     <div className="w-full md:col-span-12 mt-4 md:mt-0">

//                     </div>
//                 </div> */}
//                 <div>
//                     <MainTitle title="Declaration & Preferences" />
//                     <Declaration
//                         declarationList={declarationList || []}
//                     />
//                 </div>

//                 <div className="">
//                     <MainTitle title="Terms & Conditions" />
//                     <Checkbox
//                         isChecked={termsAndCondition?.value}
//                         handleCheckbox={handleTermsAndCondition}
//                         labelText="I have understood the information requirements of this form and hereby confirm and accept that the information provided by me on this
// 						form is true and correct and complete for the purpose of the demat debit and  pledge instruction."
//                         labelWidth="w-[90%]"
//                         labelSize="text-[14px]"
//                         labelTextColor="text-[#7C7C7C]"
//                     />
//                 </div>
//                 <div className="">
//                     <MainTitle title="Do you have a nominee" />
//                     <div className='nominee-yesno'>
//                         <label>
//                             <input
//                                 className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500 "
//                                 type="radio"
//                                 value="no"
//                                 checked={selectedOption === 'no'}
//                                 onChange={handleOptionChange}
//                             /> &nbsp;
//                             <span>No</span>
//                         </label>
//                         <label>
//                             <input
//                                 className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500 ml-5"
//                                 type="radio"
//                                 value="yes"
//                                 checked={selectedOption === 'yes'}
//                                 onChange={handleOptionChange}
//                             />
//                             <span> Yes </span>
//                         </label>
//                     </div>
//                 </div>

//                 {
//                     selectedOption == 'yes' &&
//                     <div id="accordion-collapse" data-accordion="collapse" className='mt-4 mb-4 custom-accordian-ec' style={{ borderBottom: "1px solid #eee" }}>
//                         <h2 id="accordion-collapse-heading-1">
//                             <button
//                                 type="button"
//                                 className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
//                                 onClick={toggleVisibility} // Call toggleVisibility function on button click
//                                 aria-expanded={isVisible} // Set aria-expanded attribute based on visibility state
//                                 aria-controls="accordion-collapse-body-1"
//                             >
//                                 <span>Add nominee</span>
//                                 <svg
//                                     data-accordion-icon=""
//                                     className={`w-3 h-3 rotate-${isVisible ? '0' : '180'} shrink-0`} // Rotate icon based on visibility state
//                                     aria-hidden="true"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     fill="none"
//                                     viewBox="0 0 10 6"
//                                 >
//                                     <path
//                                         stroke="currentColor"
//                                         strokeLinecap="round"
//                                         strokeLinejoin="round"
//                                         strokeWidth={2}
//                                         d="M9 5 5 1 1 5"
//                                     />
//                                 </svg>
//                             </button>
//                         </h2>
//                         {isVisible && ( // Conditionally render accordion content based on visibility state
//                             <div
//                                 id="accordion-collapse-body-1"
//                                 className=""
//                                 aria-labelledby="accordion-collapse-heading-1"
//                             >
//                                 <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900" style={{ paddingBottom: "0" }}>
//                                     <div>
//                                         <div className=" mb-4 ccustom-row">
//                                             <div className='col-md-6'>
//                                                 <select
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     value={selectedIdentification} // Set the value of the dropdown to the state
//                                                     onChange={handleIdentificationChange} // Call the function to handle change in dropdown
//                                                 >
//                                                     <option value="">Select proof of identity</option>
//                                                     <option value="Aadhaar">Aadhaar</option>
//                                                     <option value="PAN">PAN</option>
//                                                 </select>
//                                             </div>
//                                             {
//                                                 !selectedIdentification ?
//                                                     null
//                                                     :
//                                                     <div className='col-md-6'>

//                                                         <label style={{ display: 'block' }} className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Enter{' '}{selectedIdentification}</label>
//                                                         <InputMask
//                                                             mask={selectedIdentification === 'Aadhaar' ? "999999999999" : 'aaaaa9999a'}
//                                                             maskChar={null}
//                                                             type='text'
//                                                             className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                             placeholder={` ${selectedIdentification} Number`}
//                                                             value={pan}
//                                                             onChange={(e) => setPan(e.target.value.toUpperCase())}

//                                                         />
//                                                     </div>

//                                             }
//                                             <div className='col-md-6'>
//                                                 <label className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Full Name</label>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Full Name"
//                                                     value={fullName}
//                                                     onChange={(e) => setFullName(e.target.value)}
//                                                     readOnly={selectedIdentification == 'PAN' ? true : false}
//                                                     maxLength={100}
//                                                 />

//                                             </div>

//                                             <div className='col-md-6 col-md-date-picker'>
//                                                 <div style={{ position: 'relative' }}>
//                                                     <input
//                                                         type="date"
//                                                         name="dob"
//                                                         placeholder="DOB"
//                                                         className="h-[56px] w-[500px] pl-1 pr-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none appearance-none"
//                                                         value={dob}
//                                                         onChange={(e) => setDob(e.target.value.toUpperCase())}
//                                                         max={new Date().toISOString().split('T')[0]} // Optionally set a maximum date (today's date)
//                                                     />
//                                                     <div
//                                                         style={{ position: 'absolute', right: '10px', top: '5%', transform: 'translateY(-50%)', cursor: 'pointer' }}
//                                                     ></div>
//                                                 </div>
//                                             </div>

//                                             {/* <div className='col-md-6 col-md-date-picker' >


//                                                 <input
//                                                     type="date"
//                                                     name="dob"
//                                                     placeholder="DOB"
//                                                     className="h-[56px] w-[500px]  text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none "
//                                                     value={dob}
//                                                     onChange={(e) => setDob(e.target.value.toUpperCase())}
//                                                     max={new Date().toISOString().split('T')[0]} // Optionally set a maximum date (today's date)
//                                                 />

//                                             </div> */}

//                                             <div className='col-md-6'>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Email"
//                                                     value={email}
//                                                     onChange={(e) => setEmail(e.target.value)}


//                                                 />
//                                             </div>

//                                             <div className='col-md-6'>
//                                                 <InputMask
//                                                     mask="9999999999" maskChar={null}
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Mobile Number"
//                                                     value={mobile}
//                                                     onChange={(e) => setMobile(e.target.value)}

//                                                 />
//                                             </div>

//                                             <div className='col-md-6'>
//                                                 <select
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     value={selectedRelation} // Set the value of the dropdown to the selected relation state
//                                                     onChange={handleRelationChange}
//                                                 >
//                                                     {/* <option value="">Select Relation</option> */}
//                                                     {relationDta.map((option, index) => (
//                                                         <option key={index} value={option.id} >{option.name}</option>
//                                                     ))}
//                                                 </select>
//                                             </div>

//                                             <div className='col-md-12' style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
//                                                 <input
//                                                     type="checkbox"
//                                                     className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500"
//                                                     checked={isChecked}
//                                                     onChange={handleCheckboxChange}
//                                                     style={{ marginRight: '10px' }}
//                                                 />
//                                                 <label style={{ display: 'block' }} className={`loader-nominee-box text-md leading-[27px] font-medium font-poppinsMedium text-center d-block-label md:text-left whitespace-nowrap`}>Same as current address
//                                                     &nbsp;
//                                                     {
//                                                         smallLoaderActive == true &&
//                                                         <img src={loader} alt="" className='' />

//                                                     }


//                                                 </label>
//                                             </div>
//                                             <div className='col-md-4'>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Address1"
//                                                     value={address1}
//                                                     onChange={(e) => setAddress1(e.target.value)}

//                                                 />
//                                             </div>
//                                             <div className='col-md-4'>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Address2"
//                                                     value={address2}
//                                                     onChange={(e) => setAddress2(e.target.value)}

//                                                 />
//                                             </div>
//                                             <div className='col-md-4'>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Address3"
//                                                     value={address3}
//                                                     onChange={(e) => setAddress3(e.target.value)}

//                                                 />
//                                             </div>
//                                             <div className='col-md-4'>
//                                                 <InputMask
//                                                     mask="999999" maskChar={null}
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Pincode"
//                                                     value={pincode}
//                                                     onChange={(e) => setPincode(e.target.value)}



//                                                 />
//                                             </div>

//                                             <div className='col-md-4'>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="State"
//                                                     value={state}
//                                                     onChange={(e) => setState(e.target.value)}
//                                                     readOnly={check1 ? true : false}



//                                                 />
//                                             </div>
//                                             <div className='col-md-4'>
//                                                 <input
//                                                     type='text'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="City"
//                                                     value={city}
//                                                     onChange={(e) => setCIty(e.target.value)}
//                                                     readOnly={check1 ? true : false}
//                                                 />
//                                             </div>

//                                             <div className='col-md-4' style={{ overflow: 'hidden' }}>
//                                                 <input
//                                                     type='Number'
//                                                     className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
//                                                     placeholder="Sharing"
//                                                     value={share}
//                                                     onChange={handleShareChange}
//                                                 // onChange={(e) => setShare(Number(e.target.value))}

//                                                 />
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>

//                 }
//             </div>
//             <div className="my-5 flex item justify-between">
//                 <BackBtn handleBackBtn={() => backBtn({ step, index })} />
//                 <ContinueBtn
//                     handleContinueBtn={handleContinueBtn}
//                     isDisable={!termsAndCondition?.value}
//                 />
//             </div>
//         </div>
//     );
// };



import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { Declaration } from './Declaration';
import { Checkbox } from '../../../components/common/Checkbox';
import { MainTitle } from '../../../components/common/MainTitle';
import { Transaction } from './Transaction';
import { Segment } from './Segment';
import { SelectDropdown } from '../../../components/dropdown/SelectDropdown';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import { changeStep6ErrorAction, changeStep6InputAction, getBrokerageSchemeDetailDataAction, getDeclarationListAction, getDPTariffDetailAction, getIncomeProofListAction, getTransactionDetailAction, saveBrokerageInfoAction, saveBusinessInfoAction, saveDeclarationInfoAction, saveSegmentInfoAction, updateSegmentInfoAction, updateTermsAndConditionValueAction, uploadBankProofAction, callSendUploadIncomeProofLinkAction, getBrokerageSchemeListAction } from '../../../redux/actions/kyc/step6.action';

import { BackBtn } from '../../../components/buttons/BackBtn';
import { validateCommonInput } from '../../../utils/verifyInput';
import { BrokrageDropdown } from '../../../components/dropdown/brokrageDropdown';
import './Model.css'
import { addSegmentInfoAction } from '../../../redux/actions/kyc/step2.action';
import { BranchCodeDropdown } from '../../../components/dropdown/branchCodeDropdown';
import { getApi, getApiWithoutLoader, postApi, postApiWithoutLoader, validatePanDetails1 } from '../../../services/common';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Nominee2 } from './Nominee2';
import InputMask from "react-input-mask";
import loader from '../../../assets/gif/Dark Theme/Circle Loader.gif';


export const BrokerageStep = ({ step, index, backBtn, continueBtn }) => {
    const dispatch = useDispatch();
    const myRef = useRef(null);
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    // const BRANCH = useSelector(state => state?.kyc?.channel || '')
    const channel = useSelector(state => state?.kyc?.channel || '');
    const { mobileInput } = useSelector(state => state?.kycStep1 || {});
    const { transactionData, segments, brokerageScheme, dpTariff, declaration, incomeProof, termsAndCondition } = useSelector(state => state?.kycStep6);
    const [isSchemesLoading, setIssSchemesLoading] = useState(true);
    const [isUpload3MonthSalarySlip, setIsUpload3MonthSalarySlip] = useState(false);
    const transactionList = transactionData?.data || [];
    const brokerageSchemeList = brokerageScheme?.scheme_list || [];
    const dpTariffList = dpTariff?.data || [];
    const declarationList = declaration?.data || [];
    const incomeProofList = incomeProof?.data || [];
    const kycStep2 = useSelector(state => state?.kycStep2 || {});
    const kycStep1 = useSelector(state => state?.kycStep1 || {});
    const kycStep6 = useSelector(state => state?.kycStep6 || {});
    const [currencyFuture, setCurrencyFuture] = useState('')
    const [delevery, setDelevery] = useState('')
    const [currencyOption, setCurrencyOption] = useState('')
    const [future, setFuture] = useState('')
    const [mcxfuture, setMcxFuture] = useState('')
    const [option, setOption] = useState('')
    const [mcxOption, setMcxOption] = useState('')
    const [brancCodeselect, setBranchCode] = useState()
    const [schemeSelect, setSelectScheme] = useState('custom')
    const [schemeSelectType, setSelectSchemeType] = useState('')
    const [deliveryList, setDeliveryList] = useState([])
    const [poData, setPodata] = useState([])
    const [poData1, setPodata1] = useState([])
    const [schemeType, setSchemeType] = useState([])
    const [error, setError] = useState('')
    const [accout, setAccount] = useState('')
    const [kra, setKra] = useState({})
    const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
    const [selectedOption, setSelectedOption] = useState('no');
    const [isVisible, setIsVisible] = useState(true);
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [relationDta, setRelationData] = useState([]);
    const [fullName, setFullName] = useState('')
    const [dob, setDob] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [address3, setAddress3] = useState('')
    const [pincode, setPincode] = useState('')
    const [pan, setPan] = useState('')
    const [state, setState] = useState('')
    const [city, setCIty] = useState('')
    const [share, setShare] = useState(100)
    const [selectedIdentification, setSelectedIdentification] = useState('');
    const [selectedRelation, setSelectedRelation] = useState(1);
    const [isChecked, setIsChecked] = useState(true);
    const [smallLoaderActive, setSmallLoaderActive] = useState(false);
    const [check1, setCheck] = useState(false);
    const [fullName1, setFullName1] = useState('')
    const [dob1, setDob1] = useState('')
    const [email1, setEmail1] = useState('')
    const [mobile1, setMobile1] = useState('')
    const [address11, setAddress11] = useState('')
    const [address21, setAddress21] = useState('')
    const [address31, setAddress31] = useState('')
    const [pincode1, setPincode1] = useState('')
    const [pan1, setPan1] = useState('')
    const [state1, setState1] = useState('')
    const [city1, setCIty1] = useState('')
    const [share1, setShare1] = useState(0)
    const [selectedIdentification1, setSelectedIdentification1] = useState('');
    const [selectedRelation1, setSelectedRelation1] = useState(1);
    const [isChecked1, setIsChecked1] = useState(true);
    const [smallLoaderActive1, setSmallLoaderActive1] = useState(false);
    const [check11, setCheck1] = useState(false);
    const [fullName2, setFullName2] = useState('')
    const [dob2, setDob2] = useState('')
    const [email2, setEmail2] = useState('')
    const [mobile2, setMobile2] = useState('')
    const [address12, setAddress12] = useState('')
    const [address22, setAddress22] = useState('')
    const [address32, setAddress32] = useState('')
    const [pincode2, setPincode2] = useState('')
    const [pan2, setPan2] = useState('')
    const [state2, setState2] = useState('')
    const [city2, setCIty2] = useState('')
    const [share2, setShare2] = useState(0)
    const [selectedIdentification2, setSelectedIdentification2] = useState('');
    const [selectedRelation2, setSelectedRelation2] = useState(1);
    const [isChecked2, setIsChecked2] = useState(true);
    const [smallLoaderActive2, setSmallLoaderActive2] = useState(false);
    const [check12, setCheck2] = useState(false);
    const [dpCode, setDpCode] = useState(null)
    const filteredOptions = dpTariffList?.filter(option => option?.label != "999NEW" && option?.label !== "333" && option?.label !== 'FreeScheme');
    const filteredOptions2 = dpTariffList?.filter(option => option?.label != "BIGULIND");
    const [pastAction, setPastAction] = useState('')

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const handleCheckboxChange1 = () => {
        setIsChecked1(!isChecked1);
    };
    const handleCheckboxChange2 = () => {
        setIsChecked2(!isChecked2);
    };

    useEffect(() => {
        if (!isChecked) {
            setAddress1('');
            setAddress2('');
            setAddress3('');
            setPincode('');
            setState('');
            setCIty('');
        }
    }, [isChecked]);
    useEffect(() => {
        if (!isChecked1) {
            setAddress11('');
            setAddress21('');
            setAddress31('');
            setPincode1('');
            setState1('');
            setCIty1('');
        }
    }, [isChecked1]);
    useEffect(() => {
        if (!isChecked2) {
            setAddress12('');
            setAddress22('');
            setAddress32('');
            setPincode2('');
            setState2('');
            setCIty2('');
        }
    }, [isChecked2]);



    useEffect(() => {
        if (selectedOption == 'yes') {
            DetailsApi()
            RelationApi()
        }
    }, [selectedOption])

    useEffect(() => {
        if (isChecked && selectedOption == 'yes') {
            RelationApi()
            DetailsApi()
        }

    }, [isChecked])
    useEffect(() => {
        if (isChecked1 && selectedOption == 'yes') {
            RelationApi()
            DetailsApi()
        }

    }, [isChecked1])
    useEffect(() => {
        if (selectedOption == 'yes' && isChecked2) {
            RelationApi()
            DetailsApi()
        }

    }, [isChecked2])


    const RelationApi = async () => {
        const response = await getApiWithoutLoader('/GetRelationMaster ');
        if (response?.data?.success) {
            setRelationData(response?.data?.data)
        } else {
            setRelationData([])
        }
    };

    const DetailsApi = async (id) => {
        setSmallLoaderActive(true)
        setSmallLoaderActive1(true)
        setSmallLoaderActive2(true)
        const payload = {
            ClientId: kra?.data[0]?.client_id,
        }
        const response = await postApiWithoutLoader(payload, '/GetCltFullInfo ');
        const nominee = response?.data.data.nominee_arr
        if (response?.data?.success) {
            setSmallLoaderActive(false)
            setSmallLoaderActive1(false)
            setSmallLoaderActive2(false)

            if (nominee == null) {
                setAddress1(response?.data?.data?.PRAddr1 || '');
                setAddress2(response?.data?.data?.PRAddr2 || '');
                setAddress3(response?.data?.data?.PRAddr3 || '');
                setPincode(response?.data?.data?.CRPinCode || '');
                setAddress11(response?.data?.data?.PRAddr1 || '');
                setAddress21(response?.data?.data?.PRAddr2 || '');
                setAddress31(response?.data?.data?.PRAddr3 || '');
                setPincode1(response?.data?.data?.CRPinCode || '');
                setAddress12(response?.data?.data?.PRAddr1 || '');
                setAddress22(response?.data?.data?.PRAddr2 || '');
                setAddress32(response?.data?.data?.PRAddr3 || '');
                setPincode2(response?.data?.data?.CRPinCode || '');
            }
            else {
                nominee.length > 1 ? setIsVisible1(true) : setIsVisible1(false)
                nominee.length > 2 ? setIsVisible2(true) : setIsVisible2(false)
                setSmallLoaderActive(false)
                setFullName(nominee[0]?.nm_name)
                setSelectedIdentification(nominee[0]?.nm_panno == null ? "Aadhaar" : 'PAN')
                setPan(nominee[0].nm_aadhaar_no == null ? nominee[0].nm_panno : nominee[0].nm_aadhaar_no)
                setEmail(nominee[0].email)
                setMobile(nominee[0].mobile)
                setDob(nominee[0]?.birthdate)
                setSelectedRelation(nominee[0].relation)
                setAddress1(nominee[0].addr1)
                setAddress2(nominee[0].addr2)
                setAddress3(nominee[0].addr3)
                setPincode(nominee[0].pinCode)
                setState(nominee[0].state)
                setCIty(nominee[0].city)
                setShare(Math.floor(nominee[0].sharing))
                setSmallLoaderActive1(false)
                // setIsChecked(false)
                setFullName1(nominee[1]?.nm_name)
                setSelectedIdentification1(nominee[1]?.nm_panno == null ? "Aadhaar" : 'PAN')
                setPan1(nominee[1].nm_aadhaar_no == null ? nominee[1].nm_panno : nominee[1].nm_aadhaar_no)
                setEmail1(nominee[1].email)
                setMobile1(nominee[1].mobile)
                setDob1(nominee[1]?.birthdate)
                setSelectedRelation1(nominee[1].relation)
                setAddress11(nominee[1].addr1)
                setAddress21(nominee[1].addr2)
                setAddress31(nominee[1].addr3)
                setPincode1(nominee[1].pinCode)
                setState1(nominee[1].state)
                setCIty1(nominee[1].city)
                setShare1(Math.floor(nominee[1].sharing))
                setSmallLoaderActive2(false)
                // setIsChecked(false)
                setFullName2(nominee[2]?.nm_name)
                setSelectedIdentification2(nominee[2]?.nm_panno == null ? "Aadhaar" : 'PAN')
                setPan2(nominee[2].nm_aadhaar_no == null ? nominee[2].nm_panno : nominee[2].nm_aadhaar_no)
                setEmail2(nominee[2].email)
                setMobile2(nominee[2].mobile)
                setDob2(nominee[2]?.birthdate)
                setSelectedRelation2(nominee[2].relation)
                setAddress12(nominee[2].addr1)
                setAddress22(nominee[2].addr2)
                setAddress32(nominee[2].addr3)
                setPincode2(nominee[2].pinCode)
                setState2(nominee[2].state)
                setCIty2(nominee[2].city)
                setShare2(Math.floor(nominee[2].sharing))
            }

        } else {
            setSmallLoaderActive(false)
            setSmallLoaderActive1(false)
            setSmallLoaderActive2(false)
            toast(response?.message || 'something went wrong')
        }
    };
    const DetailsApi1 = async (id) => {
        const payload = {
            ClientId: id,
        }
        const response = await postApiWithoutLoader(payload, '/GetCltFullInfo ');
        if (response?.data.data.nominee_arr !== null) {
            setSelectedOption('yes')
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedIdentification === "PAN" && pan.length == 10) {
                const response = await validatePanDetails1({ pan });
                if (response?.data?.status == true || (response?.data?.status == false && response?.data?.message != 'Invalid Pan Number')) {
                    if (response?.data?.data?.Name) {
                        setFullName(response?.data?.data?.Name);
                    }
                    if (response?.data?.data[0]?.Name) {
                        setFullName(response?.data?.data[0]?.Name);
                    }
                }
                else {
                    // toast('please enter valid pan number')
                }
}
        };

        fetchData();
    }, [pan]);
    useEffect(() => {
        const fetchData1 = async () => {
            if (selectedIdentification1 === "PAN" && pan1.length == 10) {
                const response = await validatePanDetails1({ pan: pan1 });
                if (response?.data?.status == true || (response?.data?.status == false && response?.data?.message != 'Invalid Pan Number')) {
                    if (response?.data?.data?.Name) {
                        setFullName1(response?.data?.data?.Name);
                    }
                    if (response?.data?.data[0]?.Name) {
                        setFullName1(response?.data?.data[0]?.Name);
                    }
                }
                else {
                    // toast('please enter valid pan number')
                }
            }
        };

        fetchData1();
    }, [pan1]);
    useEffect(() => {
        const fetchData2 = async () => {
            if (selectedIdentification2 === "PAN" && pan2.length == 10) {
                const response = await validatePanDetails1({ pan: pan2 });
                if (response?.data?.status == true || (response?.data?.status == false && response?.data?.message != 'Invalid Pan Number')) {
                    if (response?.data?.data?.Name) {
                        setFullName2(response?.data?.data?.Name);
                    }
                    if (response?.data?.data[0]?.Name) {
                        setFullName2(response?.data?.data[0]?.Name);
                    }
                }
                else {
                    // toast('please enter valid pan number')
                }
            }
        };

        fetchData2();
    }, [pan2]);

    useEffect(() => {
        if (selectedIdentification == 'PAN' && pan?.length != 10) {
            setFullName('')
        }
    }, [pan]);
    useEffect(() => {
        if (selectedIdentification1 == 'PAN' && pan1?.length != 10) {
            setFullName1('')
        }
    }, [pan1]);
    useEffect(() => {
        if (selectedIdentification2 == 'PAN' && pan2?.length != 10) {
            setFullName2('')
        }
    }, [pan2]);


    useEffect(() => {
        if (selectedIdentification == "Aadhaar") {
            setFullName('')
            setPan('')

        }
    }, []);
    useEffect(() => {
        if (selectedIdentification1 == "Aadhaar") {
            setFullName1('')
            setPan1('')

        }
    }, []);
    useEffect(() => {
        if (selectedIdentification2 == "Aadhaar") {
            setFullName2('')
            setPan2('')

        }
    }, []);

    const PincodeApi = async (pin, num) => {
        setCheck(false)
        const response = await getApiWithoutLoader('/GetPincodeMaster?PinCode=' + pin);
        if (response?.data?.success) {
            setCheck(true)
            if (num == 1) {
                setCIty(response?.data?.data?.[0]?.name.split('/')[1].trim())
                setState(response?.data?.data?.[0]?.name.split('/')[2].trim())

            }

        }
    };
    const PincodeApi1 = async (pin, num) => {
        setCheck1(false)
        const response = await getApiWithoutLoader('/GetPincodeMaster?PinCode=' + pin);
        if (response?.data?.success) {
            setCheck1(true)
            if (num == 1) {
                setCIty1(response?.data?.data?.[0]?.name.split('/')[1].trim())
                setState1(response?.data?.data?.[0]?.name.split('/')[2].trim())
            }
        }
    };
    const PincodeApi2 = async (pin, num) => {
        setCheck2(false)
        const response = await getApiWithoutLoader('/GetPincodeMaster?PinCode=' + pin);
        if (response?.data?.success) {
            setCheck2(true)
            if (num == 1) {
                setCIty2(response?.data?.data?.[0]?.name.split('/')[1].trim())
                setState2(response?.data?.data?.[0]?.name.split('/')[2].trim())
            }
        }
    };

    useEffect(() => {
        if (selectedOption == 'yes' && pincode?.length == 6) {
            PincodeApi(pincode, 1)
        }
    }, [pincode?.length == 6])

    useEffect(() => {
        if (selectedOption == 'yes' && pincode1?.length == 6) {
            PincodeApi1(pincode1, 1)
        }
    }, [pincode1?.length == 6])

    useEffect(() => {
        if (selectedOption == 'yes' && pincode2?.length == 6) {
            PincodeApi2(pincode2, 1)
        }
    }, [pincode2?.length == 6])

    useEffect(() => {
        if (pincode.length < 6) {
            setState('')
            setCIty('')
        }

    }, [pincode])
    useEffect(() => {
        if (pincode1.length < 6) {
            setState1('')
            setCIty1('')
        }

    }, [pincode1])
    useEffect(() => {
        if (pincode2.length < 6) {
            setState2('')
            setCIty2('')
        }

    }, [pincode2])
    const handleShareChange = (e) => {
        const value = Number(e.target.value);

        // Check if the entered value is within the range 1 to 100
        if (value >= 0 && value <= 100) {
            setShare(value);
        }
    };
    const handleShareChange1 = (e) => {
        const value = Number(e.target.value);

        // Check if the entered value is within the range 1 to 100
        if (value >= 0 && value <= 99) {
            setShare1(value);
        }
    };
    const handleShareChange2 = (e) => {
        const value = Number(e.target.value);

        // Check if the entered value is within the range 1 to 100
        if (value >= 0 && value <= 99) {
            setShare2(value);
        }
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const toggleVisibility1 = () => {
        setIsVisible1(!isVisible1);
    };
    const toggleVisibility2 = () => {
        setIsVisible2(!isVisible2);
    };


    const handleIdentificationChange = (event) => {
        setSelectedIdentification(event.target.value);
        if (event.target.value == '') {
            setFullName('')
        } // Update the selected identification in the state
        if (event.target.value == "Aadhaar") {
            setFullName('')
            setPan('')
        } // Update the selected identification in the state
        if (event.target.value == "PAN") {
            setFullName('')
            setPan('')
        } // Update the selected identification in the state
    };
    const handleIdentificationChange1 = (event) => {
        setSelectedIdentification1(event.target.value);
        if (event.target.value == '') {
            setFullName1('')
        } // Update the selected identification in the state
        if (event.target.value == "Aadhaar") {
            setFullName1('')
            setPan1('')
        } // Update the selected identification in the state
        if (event.target.value == "PAN") {
            setFullName1('')
            setPan1('')
        } // Update the selected identification in the state
    };
    const handleIdentificationChange2 = (event) => {
        setSelectedIdentification2(event.target.value);
        if (event.target.value == '') {
            setFullName2('')
        } // Update the selected identification in the state
        if (event.target.value == "Aadhaar") {
            setFullName2('')
            setPan2('')
        } // Update the selected identification in the state
        if (event.target.value == "PAN") {
            setFullName2('')
            setPan2('')
        } // Update the selected identification in the state
    };

    const handleRelationChange = (event) => {
        setSelectedRelation(event.target.value); // Update the selected relation in the state
    };
    const handleRelationChange1 = (event) => {
        setSelectedRelation1(event.target.value); // Update the selected relation in the state
    };
    const handleRelationChange2 = (event) => {
        setSelectedRelation2(event.target.value); // Update the selected relation in the state
    };

    const [scheme] = useState([
        { id: '34', name: 'Scheme' }])
    const m = userDetail?.BranchCode?.split(',')
    const branchCode = m
    const { derivative, commodity, mutualFund, mutualFund1 } = segments;

    useEffect(() => {
        fetchData()
        GetBrkgPlanMaster1()
        check()
    }, [])

    useEffect(() => {
        brocrageData()
        KycJourney()
        brocrageData1()
    }, [])

    const fetchData = async () => {
        try {
            const response = await getApiWithoutLoader('/get-delivery-brokerage');
            if (response?.data?.success == true) {
                setDeliveryList(response?.data)
            }
            else {
                setError('something went wrong')
                // setTimeout(() => {
                //     backBtn({ step, index });
                // }, 5000);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const KycJourney = async () => {
        try {
            const payload = {
                mobile: kycStep1?.mobileInput?.value,
                journey: "DKYC",

            }
            const response = await postApi(payload, '/getKycJourney');
            if (response.data.success) {
                setKra(response.data)
                DetailsApi1(response?.data.data[0].client_id)
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const brocrageData = async () => {
        try {
            const payload = {
                brok_type: 'op',
                journey: "DKYC",
                mobile: kycStep1.mobileInput.value
            }
            const response = await postApiWithoutLoader(payload, '/get-intrade-brokerage');
            if (response?.data?.success == true) {
                setPodata(response?.data)
            }
            else {
                setError('something went wrong')
                // setTimeout(() => {
                //     backBtn({ step, index });
                // }, 5000);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const brocrageData1 = async () => {
        try {
            const payload = {
                "UserId": userDetail?.user_id,
                "BrokerageType": "Future",
                "Segment": "Future and Option"
            }
            const response = await postApiWithoutLoader(payload, '/GetBrkgPlanSchemeDetails ');
            if (response?.data?.success == true) {
                setPodata1(response?.data)
            }
            else {
                setError('something went wrong')
                // setTimeout(() => {
                //     backBtn({ step, index });
                // }, 2000);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const check = async () => {
        try {
            const payload = {

                "branch_code": userDetail?.BranchCode,
                Type: channel,
                journey: "DKYC",
                mobile: kycStep1.mobileInput.value
            }

            const response = await postApiWithoutLoader(payload, '/checkBranchAvailability ');
            if (response?.data?.success == true) {
                setAccount(response?.data.data)

            }
            else {
                setError('something went wrong')
                // setTimeout(() => {
                //     backBtn({ step, index });
                // }, 500);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const GetBrkgPlanMaster1 = async () => {
        const payload =
        {
            "UserId": userDetail?.user_id,
            "Segment": "equity",
            journey: "DKYC",
            mobile: kycStep1.mobileInput.value
        }
        try {
            const response = await postApiWithoutLoader(payload, '/GetRMBrkgPlanMaster ');
            if (response?.data?.success == true) {
                setSchemeType(response?.data?.data)
            }
            else {
                console.log('error')
            }

        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInput = ({ type, value }) => {
        dispatch(changeStep6InputAction({ type, value }));
        if (type == 'SchemeType') {
            setIssSchemesLoading(true);
            dispatch(getBrokerageSchemeDetailDataAction(
                {
                    "UserId": userDetail?.user_id,
                    "BrokerageType": value,
                    "segmentType": 'equity',
                    journey: "DKYC",
                    mobile: kycStep1.mobileInput.value
                }

            ));
            setSelectSchemeType(value)
            setTimeout(() => {
                setIssSchemesLoading(false);
            }, 1000);
        } else if (type == 'incomeProof') {
            if (value == 'Latest 3 Month Salary Slip') {
                setIsUpload3MonthSalarySlip(true);
            } else {
                setIsUpload3MonthSalarySlip(false);
            }
        }
        else if (type == 'dpTariff') {
            // setModelBrokerage(true)
        }
        else if (type == 'Currency_Future') {
            setCurrencyFuture(value)
        }
        else if (type == 'Delevery') {
            setDelevery(value)
        }
        else if (type == 'Currency_Option') {
            setCurrencyOption(value)
        }
        else if (type == 'Future') {
            setFuture(value)
        }
        else if (type == 'mcx_future') {
            setMcxFuture(value)
        }
        else if (type == 'Option') {
            setOption(value)
        }
        else if (type == 'mcx_option') {
            setMcxOption(value)
        }
        else if (type == 'BranchCode') {
            setBranchCode(value)
        }
        else if (type == 'Scheme') {
            setSelectScheme(value)
        }

    };

    const handleInputError = ({ type, isError, errorText }) => {
        dispatch(changeStep6ErrorAction({ type, isError, errorText }));
    };

    const handleTermsAndCondition = ({ isChecked }) => {
        dispatch(updateTermsAndConditionValueAction({ data: !isChecked }));
    };

    const executeScroll = () => myRef.current.scrollIntoView();

    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1; // Months are zero indexed
        let day = today.getDate();

        // Pad single digit months/days with leading zeros
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    };
    const handleContinueBtn = async () => {
        if(checkAction1?.isSelected == true){
        if (isPastActionSelected && pastAction.length == 0 ) {
            toast('please enter past action ')
        }
        else {
            if (dpTariff.value.length > 0) {
                if (schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Delivery : channel == 'Bigul' ? 'ORD18' : delevery != undefined) {
                    if (selectedOption == 'yes') {
                        if (!selectedIdentification) {
                            toast('Please select Identification');
                            return;
                        }
                        if (!pan) {
                            toast('Please enter Identification number');
                            return;
                        }
                        if (selectedIdentification == "PAN" && pan?.length != 10) {
                            toast('Please enter valid Pan');
                            return;
                        }
                        if (selectedIdentification == "Aadhaar" && pan?.length != 12) {
                            toast('Please enter valid Addhar');
                            return;
                        }
                        if (share + share1 + share2 != 100) {
                            toast('Nominee Percentage should be 100');
                            return;
                        }
                        if (!fullName) {
                            toast('Please Enter Full Name')
                            return
                        }
                        if (!dob) {
                            toast('Please Enter DOB')
                            return
                        }
                        if (dob > getCurrentDate()) {
                            toast('Please Enter correct date of birth')
                            return
                        }
                        if (!isValidEmail(email)) {
                            toast('Please Enter a Valid Email Address');
                            return;
                        }
                        if (!mobile || mobile?.length != 10) {
                            toast('Please Enter a Valid Mobile umber');
                            return;
                        }
                        if (!address1) {
                            toast('Please enter address1');
                            return;
                        }
                        if (!address2) {
                            toast('Please enter address2');
                            return;
                        }
                        if (!pincode) {
                            toast('Please enter pincode');
                            return;
                        }
                        if (!state) {
                            toast('Please enter correct pincode');
                            return;
                        }
                        if (!city) {
                            toast('Please enter city');
                            return;
                        }
                        else if (isVisible1) {
                            if (!selectedIdentification1) {
                                toast('Please select Identification nominee2');
                                return;
                            }
                            if (!pan1) {
                                toast('Please enter Identification number nominee2');
                                return;
                            }
                            if (selectedIdentification1 == "PAN" && pan1?.length != 10) {
                                toast('Please enter valid Pan nominee2');
                                return;
                            }
                            if (selectedIdentification1 == "Aadhaar" && pan1?.length != 12) {
                                toast('Please enter valid Addhar nominee2');
                                return;
                            }

                            if (!fullName1) {
                                toast('Please Enter Full Name nominee2')
                                return
                            }
                            if (!dob1) {
                                toast('Please Enter DOB nominee2')
                                return
                            }
                            if (dob1 > getCurrentDate()) {
                                toast('Please Enter correct date of birth nominee2')
                                return
                            }
                            if (!isValidEmail(email1)) {
                                toast('Please Enter a Valid Email Address nominee2');
                                return;
                            }
                            if (share1 == 0) {
                                toast('Nominee Percentage 2  Empty');
                                return;
                            }
                            if (!mobile1 || mobile1?.length != 10) {
                                toast('Please Enter a Valid Mobile Number nominee2');
                                return;
                            }
                            if (!address11) {
                                toast('Please enter address1 nominee2');
                                return;
                            }
                            if (!address21) {
                                toast('Please enter address2 nominee2');
                                return;
                            }
                            if (!pincode1) {
                                toast('Please enter pincode nominee2');
                                return;
                            }
                            if (!state1) {
                                toast('Please enter correct pincode nominee2');
                                return;
                            }
                            if (!city1) {
                                toast('Please enter city nominee2');
                                return;
                            }
                            else if (isVisible2) {
                                if (!selectedIdentification2) {
                                    toast('Please select Identification nominee3');
                                    return;
                                }
                                if (!pan2) {
                                    toast('Please enter Identification number nominee3');
                                    return;
                                }
                                if (selectedIdentification2 == "PAN" && pan2?.length != 10) {
                                    toast('Please enter valid Pan nominee3');
                                    return;
                                }
                                if (selectedIdentification2 == "Aadhaar" && pan2?.length != 12) {
                                    toast('Please enter valid Addhar nominee3');
                                    return;
                                }

                                if (!fullName2) {
                                    toast('Please Enter Full Name nominee3')
                                    return
                                }
                                if (!dob2) {
                                    toast('Please Enter DOB nominee3')
                                    return
                                }
                                if (dob2 > getCurrentDate()) {
                                    toast('Please Enter correct date of birth nominee3')
                                    return
                                }
                                if (share2 == 0) {
                                    toast('Nominee Percentage 3 Empty');
                                    return;
                                }
                                if (!isValidEmail(email2)) {
                                    toast('Please Enter a Valid Email Address nominee3');
                                    return;
                                }
                                if (!mobile2 || mobile2?.length != 10) {
                                    toast('Please Enter a Valid Mobile Number nominee3');
                                    return;
                                }
                                if (!address12) {
                                    toast('Please enter address1 nominee3');
                                    return;
                                }
                                if (!address22) {
                                    toast('Please enter address2 nominee3');
                                    return;
                                }
                                if (!pincode2) {
                                    toast('Please enter pincode nominee3');
                                    return;
                                }
                                if (!state2) {
                                    toast('Please enter correct pincode nominee3');
                                    return;
                                }
                                if (!city2) {
                                    toast('Please enter city nominee3');
                                    return;
                                }
                            }
                        }


                        const payload = {
                            "panNo": clientKycStatus?.data?.pan || kycStep2.panInput.value,
                            "nominee_arr": [
                                {
                                    "fullName": fullName,
                                    "dob": dob,
                                    "email": email,
                                    "mobile": mobile,
                                    "relation": selectedRelation,
                                    "identification": selectedIdentification == 'PAN' ? 'Pan' : 'Aadhaar',
                                    "identificationNumber": pan,
                                    "sameAddress": true,
                                    "address1": address1,
                                    "address2": address2,
                                    "address3": address3,
                                    "pincode": pincode,
                                    "state": state,
                                    "city": city,
                                    "sharing": share,
                                    journey: 'Dkyc'
                                },

                                isVisible1 ?
                                    {
                                        "fullName": fullName1,
                                        "dob": dob1,
                                        "email": email1,
                                        "mobile": mobile1,
                                        "relation": selectedRelation1,
                                        "identification": selectedIdentification1 == 'PAN' ? 'Pan' : 'Aadhaar',
                                        "identificationNumber": pan1,
                                        "sameAddress": true,
                                        "address1": address11,
                                        "address2": address21,
                                        "address3": address31,
                                        "pincode": pincode1,
                                        "state": state1,
                                        "city": city1,
                                        "sharing": share1,
                                        journey: 'Dkyc'
                                    } :
                                    null,
                                isVisible2 ?
                                    {
                                        "fullName": fullName2,
                                        "dob": dob2,
                                        "email": email2,
                                        "mobile": mobile2,
                                        "relation": selectedRelation2,
                                        "identification": selectedIdentification2 == 'PAN' ? 'Pan' : 'Aadhaar',
                                        "identificationNumber": pan2,
                                        "sameAddress": true,
                                        "address1": address12,
                                        "address2": address22,
                                        "address3": address32,
                                        "pincode": pincode2,
                                        "state": state2,
                                        "city": city2,
                                        "sharing": share2,
                                        journey: 'Dkyc'
                                    } :
                                    null

                            ]
                        };

                        const response = await postApi(payload, '/AddNomineeInfo');
                        if (response.data.success) {
                            const { derivative, commodity, mutualFund } = segments;
                            const brokerageSchemeValue = brokerageScheme?.value || '';
                            const dpTariffValue = dpTariff?.value || '';
                            const incomeProofValue = incomeProof?.value || '';
                            const isValidBrokerageScheme = validateCommonInput(brokerageSchemeValue);
                            const isValidDpTariffValue = validateCommonInput(dpTariffValue);
                            const mFselect = mutualFund1.isSelected ? ["TRADING", accout, "MF"] : ["TRADING", accout];
                            const channelselect = 'Bigul' || 'BIGUL'
                            dispatch(addSegmentInfoAction({
                                PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                                AccountType: mFselect,
                                Equity: ["NSE", "BSE"],
                                Derivative: (derivative.isSelected && mutualFund.isSelected) ? ['NSEC', 'BSEC', 'NSEF', 'BSEF'] : derivative.isSelected ? ['NSEC', 'BSEC'] : mutualFund.isSelected ? ['NSEF', 'BSEF'] : null,
                                Commodity: commodity.isSelected ? ['MCX', 'NCDX', 'BCX', 'NCX'] : null,
                                Mutualfund: mutualFund1.isSelected ? 'Yes' : 'No',
                                UserId: userDetail.user_id,
                                BranchCode: '',
                                LDCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                                TerminalCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                                channel: channel,
                                IsETrade: channel == channelselect ? 90 : null,
                                journey: "DKYC",
                                mobile: kycStep1.mobileInput.value,
                                dpCode: dpCode,
                                dpId: dpCode != null ? '12033500' : null
                            }, CallbackAddsegment));
                            if (isValidBrokerageScheme && isValidDpTariffValue) {
                            } if (!isValidBrokerageScheme) {
                                handleInputError({ type: 'brokerageScheme', isError: true, errorText: 'Invalid Scheme!' });
                                executeScroll();
                            } if (!isValidDpTariffValue) {
                                handleInputError({ type: 'dpTariff', isError: true, errorText: 'Invalid input!' });
                                executeScroll();
                            }
                        }
                        else {
                            toast(response?.data?.message)
                        }

                    }
                    else {
                        const { derivative, commodity, mutualFund } = segments;
                        const brokerageSchemeValue = brokerageScheme?.value || '';
                        const dpTariffValue = dpTariff?.value || '';
                        const incomeProofValue = incomeProof?.value || '';
                        const isValidBrokerageScheme = validateCommonInput(brokerageSchemeValue);
                        const isValidDpTariffValue = validateCommonInput(dpTariffValue);
                        const mFselect = mutualFund1.isSelected ? ["TRADING", accout, "MF"] : ["TRADING", accout];
                        const channelselect = 'Bigul' || 'BIGUL'
                        dispatch(addSegmentInfoAction({
                            PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                            AccountType: mFselect,
                            Equity: ["NSE", "BSE"],
                            Derivative: (derivative.isSelected && mutualFund.isSelected) ? ['NSEC', 'BSEC', 'NSEF', 'BSEF'] : derivative.isSelected ? ['NSEC', 'BSEC'] : mutualFund.isSelected ? ['NSEF', 'BSEF'] : null,
                            Commodity: commodity.isSelected ? ['MCX', 'NCDX', 'BCX', 'NCX'] : null,
                            Mutualfund: mutualFund1.isSelected ? 'Yes' : 'No',
                            UserId: userDetail.user_id,
                            BranchCode: '',
                            LDCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                            TerminalCode: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                            channel: channel,
                            IsETrade: channel == channelselect ? 90 : null,
                            journey: "DKYC",
                            mobile: kycStep1.mobileInput.value,
                            dpCode: dpCode,
                            dpId: dpCode != null ? '12033500' : null
                        }, CallbackAddsegment));

                        if (isValidBrokerageScheme && isValidDpTariffValue) {
                        } if (!isValidBrokerageScheme) {
                            handleInputError({ type: 'brokerageScheme', isError: true, errorText: 'Invalid Scheme!' });
                            executeScroll();
                        } if (!isValidDpTariffValue) {
                            handleInputError({ type: 'dpTariff', isError: true, errorText: 'Invalid input!' });
                            executeScroll();
                        }
                    }
                }
                else {
                    toast('Scheme is not avilabale  for your branch please contact support team')
                }

            }
            else {
                toast('Please select DP Tarriff')
            }
        }
    }
    else{
        toast(declarationList[10]?.description)
    }
 };

    const CallbackAddsegment = (resp) => {
        const transactionValue = transactionData.value.toString();
        if (resp == 'success') {
            dispatch(saveBusinessInfoAction({
                PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                ContractType: 'ECN',
                RunningActAuth: 'Quarterly',
                TradingAct: 'Both',
                MobileTrading: 'Yes',
                IsDPExit: 'No',
                DisSlip: 'No',
                DDPI: 'Yes',
                Ttype: transactionValue,
                journey: "DKYC",
                mobile: kycStep1.mobileInput.value
            }, callBackBusinessInfo));
        }
        else {
            toast(resp?.message ? resp?.message : 'something went wrong')
        }

    }
    const callBackBusinessInfo = (response) => {
        const { derivative, commodity, mutualFund } = segments;
        const brokerageSchemeValue = brokerageScheme?.value || '';
        const dpTariffValue = dpTariff?.value || '';

        if (response.data.success) {
            dispatch(saveBrokerageInfoAction({
                PanNo: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                IsEquity: 'Yes',
                IsFuture: mutualFund.isSelected ? 'Yes' : "No",
                IsCurrency: derivative.isSelected ? 'Yes' : "No",
                IsCommodity: commodity.isSelected ? 'Yes' : "No",
                Derivatives: derivative.isSelected ? 'Yes' : "No",
                Mutualfund: mutualFund1.isSelected ? 'Yes' : "No",
                // FutureandOption: 'Yes',
                BrokerageScheme: schemeSelectType ? schemeSelectType : "MyeTrade_PlanA",
                EquityDelivery: schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Delivery : channel == 'Bigul' ? 'ORD18' : delevery,
                EquityTrading: schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Intraday : channel == 'Bigul' ? 'ORD18' : brokerageSchemeValue,
                EquityFuture: (schemeSelect == 'Scheme' && segments.mutualFund.isSelected) ? brokerageScheme?.scheme_details?.data?.Future_and_Option_Future : segments.mutualFund.isSelected ? channel == 'Bigul' ? 'ORD18' : future : null,
                EquityOption: (schemeSelect == 'Scheme' && segments.mutualFund.isSelected) ? brokerageScheme?.scheme_details?.data?.Future_and_Option_Option : segments.mutualFund.isSelected ? channel == 'Bigul' ? 'ORD18' : option : null,
                DPTariff: dpTariffValue,
                CurrencyFuture: (schemeSelect == 'Scheme' && segments.derivative.isSelected) ? brokerageScheme?.scheme_details?.data?.Currency_Future : segments.derivative.isSelected ? channel == 'Bigul' ? 'ORD18' : currencyFuture : null,
                CurrencyOption: (schemeSelect == 'Scheme' && segments.derivative.isSelected) ? brokerageScheme?.scheme_details?.data?.Currency_Option : segments.derivative.isSelected ? channel == 'Bigul' ? 'ORD18' : currencyOption : null,
                McxScheme: schemeSelectType ? schemeSelectType : "No",
                McxFuture: (schemeSelect == 'Scheme' && segments.commodity.isSelected) ? brokerageScheme?.scheme_details?.data?.Commodity_Future : segments.commodity.isSelected ? channel == 'Bigul' ? 'ORD18' : mcxfuture : null,
                McxOption: (schemeSelect == 'Scheme' && segments.commodity.isSelected) ? brokerageScheme?.scheme_details?.data?.Commodity_Option : segments.commodity.isSelected ? channel == 'Bigul' ? 'ORD18' : mcxOption : null,
                trading: schemeSelect == 'Scheme' ? brokerageScheme?.scheme_details?.data?.Equity_Cash_Intraday : channel == 'Bigul' ? 'ORD18' : brokerageSchemeValue,
                journey: "DKYC",
                mobile: kycStep1.mobileInput.value

            }, callbackUpadateSegment));
        }
        else {
            toast('something went wrong')
        }

    }

    const callbackUpadateSegment = (response) => {
        const incomeProofValue = incomeProof?.value || '';

        if (response.success == true) {
            dispatch(saveDeclarationInfoAction({
                tnc: 1,
                income_proof_type: incomeProofValue,
                declaration1: declarationList[0].isSelected ? 'Yes' : 'No',
                declaration2: declarationList[1].isSelected ? 'Yes' : 'No',
                declaration3: declarationList[2].isSelected ? 'Yes' : 'No',
                declaration3_option: declarationList[2].selectedOption,
                declaration4: declarationList[3].isSelected ? 'Yes' : 'No',
                declaration4_option: declarationList[3].selectedOption,
                declaration5: declarationList[4].isSelected ? 'Yes' : 'No',
                declaration6: declarationList[5].isSelected ? 'Yes' : 'No',
                declaration7: declarationList[6].isSelected ? 'Yes' : 'No',
                declaration8: declarationList[7].isSelected ? 'Yes' : 'No',
                declaration8_option: declarationList[7].selectedOption,
                declaration9: declarationList[8].isSelected ? 'Yes' : 'No',
                declaration9_option: declarationList[8].selectedOption,
                declaration10: declarationList[9].isSelected ? 'Yes' : 'No',
                PEP:'No',
                // RPEP: declarationList[11].isSelected ? 'Yes' : 'No',
                past_action: declarationList[11].isSelected ? 'Yes' : 'No',
                past_action_desc: isPastActionSelected ? pastAction : '',
                mobile: mobileInput?.value,
                pan: clientKycStatus?.data?.pan || kycStep2.panInput.value,
                IsEquity: 'Yes',
                IsFuture: mutualFund.isSelected ? 'Yes' : 'No',
                IsCurrency: derivative.isSelected ? 'Yes' : 'No',
                IsCommodity: commodity.isSelected ? 'Yes' : 'No',
                Derivatives: derivative.isSelected ? 'Yes' : 'No',
                Mutualfund: mutualFund1.isSelected ? 'Yes' : 'No',
                FutureandOption: mutualFund.isSelected ? 'Yes' : 'No',
                journey: "DKYC",
                mobile: kycStep1.mobileInput.value
            }, finalCallback));
        }
        else {
            toast(response?.data?.EquityDelivery || 'something went wrong')
        }
    }

    const finalCallback = async (response) => {
        const payload = {
            "PanNo": clientKycStatus?.data?.pan || kycStep2.panInput.value,
            dpCode: dpCode,
            dpId: dpCode != null ? '12033500' : null
        }

        if (response.success) {
            const response = await postApi(payload, '/AddDpidDpcode');
            if (response.status) {
                toast('Scheme verified Succesfully.')
                continueBtn({ step, index });
            }
            else {
                toast('something went wrong')
            }
        }
        else {
            toast('something went wrong')
        }
    }

    useEffect(() => {
        transactionList.length < 1 && dispatch(getTransactionDetailAction());
        dispatch(getBrokerageSchemeListAction({
            brok_type: 'FU',
            journey: "DKYC",
            mobile: kycStep1.mobileInput.value
        }))
        // dpTariffList.length < 1 && dispatch(getDPTariffDetailAction());
        dispatch(getDeclarationListAction());
        incomeProofList.length < 1 && dispatch(getIncomeProofListAction());

    }, []);

    useEffect(() => {
        dispatch(getDPTariffDetailAction())
    }, [])

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = (dpCode) => {
        setDpCode(dpCode)
    };

    const checkAction = declarationList.find(option => option.label === "Past action");
    const checkAction1 = declarationList.find(option => option.label === "I am not politically exposed or related");

    const isPastActionSelected = checkAction ? checkAction.isSelected : false;

return (
        <div className="w-full flex flex-col ">
            < ToastContainer autoClose={2000} />
            <div className="min-h-[calc(100vh-300px)]">
                <div ref={myRef} className="w-full mb-6 grid md:grid-cols-2 gap-7">
                    <Transaction
                        transactionData={transactionList || []}
                    />
                    <Segment />
                </div>
                <MainTitle title="Brokerage Details" />
                <MainTitle title="Brokerage Scheme" />
                {
                    channel == 'Bonanza' &&
                    <>
                        <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
                            <div className="w-full md:col-span-12 mt-4 md:mt-0">
                                <BranchCodeDropdown
                                    placeholder="Please select branch code"
                                    options={scheme.map((item) => item.name) || []}
                                    inputType="Scheme"
                                    channel={channel}
                                    handleSelect={handleInput}

                                />
                            </div>
                        </div>
                        {
                            schemeSelect == 'Scheme' &&
                            <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
                                <div className="w-full md:col-span-12 mt-4 md:mt-0">
                                    <BranchCodeDropdown
                                        placeholder="Please select Scheme"
                                        options={schemeType.map((item) => item.name) || []}
                                        inputType="SchemeType"
                                        channel={channel}
                                        handleSelect={handleInput}

                                    />
                                </div>
                            </div>
                        }
                    </>

                }
                {
                    segments.equity.isSelected &&
                    <div className="w-full mb-8 md:grid md:grid-cols-12 gap-5">
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Trading:" />
                                    <BrokrageDropdown
                                        placeholder="Please select brokerage scheme"
                                        selectedValue={brokerageScheme?.value}
                                        options={brokerageSchemeList || []}
                                        inputType="brokerageScheme"
                                        handleSelect={handleInput}
                                        channel={channel}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <>
                                    <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                        <MainTitle title="Trading:" />
                                        <div className="w-full flex flex-col">
                                            <div className="w-full h-full rounded-[10px]">
                                                <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                    <input
                                                        className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                        type="text"
                                                        value={brokerageScheme?.scheme_details?.data?.Equity_Cash_Intraday}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Delivery:" />
                                    <BrokrageDropdown
                                        placeholder="Please select Delivery"
                                        selectedValue={kycStep6?.Delevery?.value}
                                        options={deliveryList || []}
                                        inputType="Delevery"
                                        channel={channel}
                                        handleSelect={handleInput}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <>
                                    <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                        <MainTitle title="Delivery:" />
                                        <div className="w-full flex flex-col">
                                            <div className="w-full h-full rounded-[10px]">
                                                <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                    <input
                                                        className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                        type="text"
                                                        value={brokerageScheme?.scheme_details?.data?.Equity_Cash_Delivery}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }


                    </div>
                }
                {
                    segments.derivative.isSelected &&
                    <div className="w-full mb-8 md:grid md:grid-cols-12 gap-5">
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Currency Future :" />
                                    <BrokrageDropdown
                                        placeholder="Please select Currency Future"
                                        selectedValue={kycStep6?.Currency_Future?.value}
                                        options={brokerageSchemeList || []}
                                        inputType="Currency_Future"
                                        handleSelect={handleInput}
                                        channel={channel}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <>
                                    <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                        <MainTitle title="Currency Future :" />
                                        <div className="w-full flex flex-col">
                                            <div className="w-full h-full rounded-[10px]">
                                                <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                    <input
                                                        className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                        type="text"
                                                        value={brokerageScheme?.scheme_details?.data?.Currency_Future}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Currency Option :" />
                                    <BrokrageDropdown
                                        placeholder="Please select Currency Option"
                                        selectedValue={kycStep6?.Currency_Option?.value}
                                        options={poData || []}
                                        inputType="Currency_Option"
                                        handleSelect={handleInput}
                                        channel={channel}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Currency Option :" />
                                    <div className="w-full flex flex-col">
                                        <div className="w-full h-full rounded-[10px]">
                                            <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                <input
                                                    className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                    type="text"
                                                    value={brokerageScheme?.scheme_details?.data?.Currency_Option}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                }
                {
                    segments.mutualFund.isSelected &&
                    <div className="w-full mb-4">
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6">
                                    <MainTitle title="Future:" />
                                    <BrokrageDropdown
                                        placeholder="Please select Future"
                                        selectedValue={kycStep6?.Future?.value}
                                        options={brokerageSchemeList || []}
                                        inputType="Future"
                                        channel={channel}
                                        handleSelect={handleInput}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Future :" />
                                    <div className="w-full flex flex-col">
                                        <div className="w-full h-full rounded-[10px]">
                                            <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                <input
                                                    className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                    type="text"
                                                    value={brokerageScheme?.scheme_details?.data?.Future_and_Option_Future}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Option:" />
                                    <BrokrageDropdown
                                        placeholder="Please select Option"
                                        selectedValue={kycStep6?.Option?.value}
                                        options={poData || []}
                                        inputType="Option"
                                        channel={channel}
                                        handleSelect={handleInput}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Option :" />
                                    <div className="w-full flex flex-col">
                                        <div className="w-full h-full rounded-[10px]">
                                            <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                <input
                                                    className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                    type="text"
                                                    value={brokerageScheme?.scheme_details?.data?.Future_and_Option_Option}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                }
                {
                    segments.commodity.isSelected &&
                    <div className="w-full mb-8 md:grid md:grid-cols-12 gap-5">
                        {schemeSelect == 'custom' ?
                            <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                <MainTitle title="Commodity Future :" />
                                <BrokrageDropdown
                                    placeholder="Please select commodity Future"
                                    selectedValue={kycStep6?.mcx_future?.value}
                                    options={brokerageSchemeList || []}
                                    inputType="mcx_future"
                                    channel={channel}
                                    handleSelect={handleInput}
                                    inputMessage={error || ''}
                                    inputMessageType={error && 'error'}
                                />
                            </div>
                            :
                            <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                <MainTitle title="Commodity Future :" />
                                <div className="w-full flex flex-col">
                                    <div className="w-full h-full rounded-[10px]">
                                        <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                            <input
                                                className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                type="text"
                                                value={brokerageScheme?.scheme_details?.data?.Commodity_Future}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        {
                            schemeSelect == 'custom' ?
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Commodity Option :" />
                                    <BrokrageDropdown
                                        placeholder="Please select commodity Option"
                                        selectedValue={kycStep6?.mcx_option?.value}
                                        options={poData || []}
                                        inputType="mcx_option"
                                        channel={channel}
                                        handleSelect={handleInput}
                                        inputMessage={error || ''}
                                        inputMessageType={error && 'error'}
                                    />
                                </div>
                                :
                                <div className="w-full md:col-span-6" style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
                                    <MainTitle title="Commodity Option :" />
                                    <div className="w-full flex flex-col">
                                        <div className="w-full h-full rounded-[10px]">
                                            <div className="px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]" >
                                                <input
                                                    className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white"
                                                    type="text"
                                                    value={brokerageScheme?.scheme_details?.data?.Commodity_Option}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                }
                <div className="w-full mb-12 md:grid md:grid-cols-12 gap-5">
                    <div className="w-full md:col-span-12 mt-4 md:mt-0">
                        <SelectDropdown
                            label="DP Tariff"
                            placeholder="Please select dp tariff"
                            selectedValue={dpTariff?.value}
                            options={channel == "Bigul" ? filteredOptions : filteredOptions2 || []}
                            inputType="dpTariff"
                            handleSelect={handleInput}
                            inputMessage={dpTariff.errorText || ''}
                            inputMessageType={dpTariff.isError && 'error'}
                        />
                    </div>
                </div>
                <Nominee2 pan={clientKycStatus?.data?.pan || kycStep2.panInput.value} handlesubmit={handleSubmit} />
                <div>
                    <MainTitle title="Declaration & Preferences" />
                    <Declaration
                        declarationList={declarationList || []}
                    />
                </div>
                {

                    isPastActionSelected == true &&
                    <div className="w-full flex flex-col mb-5">
                        {(
                            <label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">

                                <span className="mr-2">{'Past Action'}</span>
                            </label>
                        )}
                        <div
                            className={`w-full h-full rounded-[10px]
                        ${"bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10"
                                }
                  ${"h-[47px]"}
                    `}
                        >
                            <input
                                className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white mt-2.5 ml-5"
                                type="text"
                                placeholder={'Enter Your past action'}
                                onChange={(e) => { setPastAction(e.target.value) }}
                                value={pastAction}

                            />
                        </div>
                    </div>
                }

                <div className="">
                    <MainTitle title="Terms & Conditions" />
                    <Checkbox
                        isChecked={termsAndCondition?.value}
                        handleCheckbox={handleTermsAndCondition}
                        labelText="I have understood the information requirements of this form and hereby confirm and accept that the information provided by me on this
						form is true and correct and complete for the purpose of the demat debit and  pledge instruction."
                        labelWidth="w-[90%]"
                        labelSize="text-[14px]"
                        labelTextColor="text-[#7C7C7C]"
                    />
                </div>
                <div className="">
                    <MainTitle title="Do you have a nominee" />
                    <div className='nominee-yesno'>
                        <label>
                            <input
                                className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500 "
                                type="radio"
                                value="no"
                                checked={selectedOption === 'no'}
                                onChange={handleOptionChange}
                            /> &nbsp;
                            <span>No</span>
                        </label>
                        <label>
                            <input
                                className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500 ml-5"
                                type="radio"
                                value="yes"
                                checked={selectedOption === 'yes'}
                                onChange={handleOptionChange}
                            />
                            <span> Yes </span>
                        </label>
                    </div>
                </div>

                {
                    selectedOption == 'yes' &&
                    <div id="accordion-collapse" data-accordion="collapse" className='mt-4 mb-4 custom-accordian-ec' style={{ borderBottom: "1px solid #eee" }}>
                        <h2 id="accordion-collapse-heading-1">
                            <button
                                type="button"
                                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                                onClick={toggleVisibility} // Call toggleVisibility function on button click
                                aria-expanded={isVisible} // Set aria-expanded attribute based on visibility state
                                aria-controls="accordion-collapse-body-1"
                            >
                                <span>Add nominee</span>
                                <svg
                                    data-accordion-icon=""
                                    className={`w-3 h-3 rotate-${isVisible ? '0' : '180'} shrink-0`} // Rotate icon based on visibility state
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5 5 1 1 5"
                                    />
                                </svg>
                            </button>
                        </h2>
                        {isVisible && ( // Conditionally render accordion content based on visibility state
                            <div
                                id="accordion-collapse-body-1"
                                className=""
                                aria-labelledby="accordion-collapse-heading-1"
                            >
                                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900" style={{ paddingBottom: "0" }}>
                                    <div>
                                        <div className=" mb-4 ccustom-row">
                                            <div className='col-md-6'>
                                                <select
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    value={selectedIdentification} // Set the value of the dropdown to the state
                                                    onChange={handleIdentificationChange} // Call the function to handle change in dropdown
                                                >
                                                    <option value="">Select proof of identity</option>
                                                    <option value="Aadhaar">Aadhaar</option>
                                                    <option value="PAN">PAN</option>
                                                </select>
                                            </div>
                                            {
                                                !selectedIdentification ?
                                                    null
                                                    :
                                                    <div className='col-md-6'>

                                                        <label style={{ display: 'block' }} className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Enter{' '}{selectedIdentification}</label>
                                                        <InputMask
                                                            mask={selectedIdentification === 'Aadhaar' ? "999999999999" : 'aaaaa9999a'}
                                                            maskChar={null}
                                                            type='text'
                                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                            placeholder={` ${selectedIdentification} Number`}
                                                            value={pan}
                                                            onChange={(e) => setPan(e.target.value.toUpperCase())}

                                                        />
                                                    </div>

                                            }
                                            <div className='col-md-6'>
                                                <label className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Full Name</label>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Full Name"
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                    // readOnly={selectedIdentification == 'PAN' ? true : false}
                                                    maxLength={100}
                                                />

                                            </div>

                                            <div className='col-md-6 col-md-date-picker'>
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        type="date"
                                                        name="dob"
                                                        placeholder="DOB"
                                                        className="h-[56px] w-[500px] pl-1 pr-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none appearance-none"
                                                        value={dob}
                                                        onChange={(e) => setDob(e.target.value.toUpperCase())}
                                                        max={new Date().toISOString().split('T')[0]} // Optionally set a maximum date (today's date)
                                                    />
                                                    <div
                                                        style={{ position: 'absolute', right: '10px', top: '5%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}


                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <InputMask
                                                    mask="9999999999" maskChar={null}
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Mobile Number"
                                                    value={mobile}
                                                    onChange={(e) => setMobile(e.target.value)}

                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <select
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    value={selectedRelation} // Set the value of the dropdown to the selected relation state
                                                    onChange={handleRelationChange}
                                                >
                                                    {/* <option value="">Select Relation</option> */}
                                                    {relationDta.map((option, index) => (
                                                        <option key={index} value={option.id} >{option.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                <label style={{ display: 'block' }} className={`loader-nominee-box text-md leading-[27px] font-medium font-poppinsMedium text-center d-block-label md:text-left whitespace-nowrap`}>Same as current address
                                                    &nbsp;
                                                    {
                                                        smallLoaderActive == true &&
                                                        <img src={loader} alt="" className='' />

                                                    }


                                                </label>
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address1"
                                                    value={address1}
                                                    onChange={(e) => setAddress1(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address2"
                                                    value={address2}
                                                    onChange={(e) => setAddress2(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address3"
                                                    value={address3}
                                                    onChange={(e) => setAddress3(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <InputMask
                                                    mask="999999" maskChar={null}
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Pincode"
                                                    value={pincode}
                                                    onChange={(e) => setPincode(e.target.value)}



                                                />
                                            </div>

                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="State"
                                                    value={state}
                                                    onChange={(e) => setState(e.target.value)}
                                                    readOnly={check1 ? true : false}



                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="City"
                                                    value={city}
                                                    onChange={(e) => setCIty(e.target.value)}
                                                    readOnly={check1 ? true : false}
                                                />
                                            </div>

                                            <div className='col-md-4' style={{ overflow: 'hidden' }}>
                                                <input
                                                    // type='Number'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Sharing"
                                                    value={share}
                                                    onChange={handleShareChange}
                                                // onChange={(e) => setShare(Number(e.target.value))}

                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                }
                {
                    selectedOption == 'yes' &&
                    <div id="accordion-collapse" data-accordion="collapse" className='mt-4 mb-4 custom-accordian-ec' style={{ borderBottom: "1px solid #eee" }}>
                        <h2 id="accordion-collapse-heading-1">
                            <button
                                type="button"
                                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                                onClick={toggleVisibility1} // Call toggleVisibility function on button click
                                aria-expanded={isVisible1} // Set aria-expanded attribute based on visibility state
                                aria-controls="accordion-collapse-body-1"
                            >
                                <span>Add nominee2</span>
                                <svg
                                    data-accordion-icon=""
                                    className={`w-3 h-3 rotate-${isVisible1 ? '0' : '180'} shrink-0`} // Rotate icon based on visibility state
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5 5 1 1 5"
                                    />
                                </svg>
                            </button>
                        </h2>
                        {isVisible1 && ( // Conditionally render accordion content based on visibility state
                            <div
                                id="accordion-collapse-body-1"
                                className=""
                                aria-labelledby="accordion-collapse-heading-1"
                            >
                                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900" style={{ paddingBottom: "0" }}>
                                    <div>
                                        <div className=" mb-4 ccustom-row">
                                            <div className='col-md-6'>
                                                <select
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    value={selectedIdentification1} // Set the value of the dropdown to the state
                                                    onChange={handleIdentificationChange1} // Call the function to handle change in dropdown
                                                >
                                                    <option value="">Select proof of identity</option>
                                                    <option value="Aadhaar">Aadhaar</option>
                                                    <option value="PAN">PAN</option>
                                                </select>
                                            </div>
                                            {
                                                !selectedIdentification1 ?
                                                    null
                                                    :
                                                    <div className='col-md-6'>

                                                        <label style={{ display: 'block' }} className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Enter{' '}{selectedIdentification}</label>
                                                        <InputMask
                                                            mask={selectedIdentification1 === 'Aadhaar' ? "999999999999" : 'aaaaa9999a'}
                                                            maskChar={null}
                                                            type='text'
                                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                            placeholder={` ${selectedIdentification1} Number`}
                                                            value={pan1}
                                                            onChange={(e) => setPan1(e.target.value.toUpperCase())}

                                                        />
                                                    </div>

                                            }
                                            <div className='col-md-6'>
                                                <label className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Full Name</label>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Full Name"
                                                    value={fullName1}
                                                    onChange={(e) => setFullName1(e.target.value)}
                                                    // readOnly={selectedIdentification1 == 'PAN' ? true : false}
                                                    maxLength={100}
                                                />

                                            </div>

                                            <div className='col-md-6 col-md-date-picker'>
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        type="date"
                                                        name="dob"
                                                        placeholder="DOB"
                                                        className="h-[56px] w-[500px] pl-1 pr-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none appearance-none"
                                                        value={dob1}
                                                        onChange={(e) => setDob1(e.target.value.toUpperCase())}
                                                        max={new Date().toISOString().split('T')[0]} // Optionally set a maximum date (today's date)
                                                    />
                                                    <div
                                                        style={{ position: 'absolute', right: '10px', top: '5%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Email"
                                                    value={email1}
                                                    onChange={(e) => setEmail1(e.target.value)}


                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <InputMask
                                                    mask="9999999999" maskChar={null}
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Mobile Number"
                                                    value={mobile1}
                                                    onChange={(e) => setMobile1(e.target.value)}

                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <select
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    value={selectedRelation1} // Set the value of the dropdown to the selected relation state
                                                    onChange={handleRelationChange1}
                                                >
                                                    {/* <option value="">Select Relation</option> */}
                                                    {relationDta.map((option, index) => (
                                                        <option key={index} value={option.id} >{option.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500"
                                                    checked={isChecked1}
                                                    onChange={handleCheckboxChange1}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                <label style={{ display: 'block' }} className={`loader-nominee-box text-md leading-[27px] font-medium font-poppinsMedium text-center d-block-label md:text-left whitespace-nowrap`}>Same as current address
                                                    &nbsp;
                                                    {
                                                        smallLoaderActive1 == true &&
                                                        <img src={loader} alt="" className='' />

                                                    }


                                                </label>
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address1"
                                                    value={address11}
                                                    onChange={(e) => setAddress11(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address2"
                                                    value={address21}
                                                    onChange={(e) => setAddress21(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address3"
                                                    value={address31}
                                                    onChange={(e) => setAddress31(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <InputMask
                                                    mask="999999" maskChar={null}
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Pincode"
                                                    value={pincode1}
                                                    onChange={(e) => setPincode1(e.target.value)}



                                                />
                                            </div>

                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="State"
                                                    value={state1}
                                                    onChange={(e) => setState1(e.target.value)}
                                                    readOnly={check11 ? true : false}



                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="City"
                                                    value={city1}
                                                    onChange={(e) => setCIty1(e.target.value)}
                                                    readOnly={check11 ? true : false}
                                                />
                                            </div>

                                            <div className='col-md-4' style={{ overflow: 'hidden' }}>
                                                <input
                                                    // type='Number'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Sharing"
                                                    value={share1}
                                                    onChange={handleShareChange1}
                                                // onChange={(e) => setShare(Number(e.target.value))}

                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                }
                {
                    selectedOption == 'yes' &&
                    <div id="accordion-collapse" data-accordion="collapse" className='mt-4 mb-4 custom-accordian-ec' style={{ borderBottom: "1px solid #eee" }}>
                        <h2 id="accordion-collapse-heading-1">
                            <button
                                type="button"
                                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                                onClick={toggleVisibility2} // Call toggleVisibility function on button click
                                aria-expanded={isVisible2} // Set aria-expanded attribute based on visibility state
                                aria-controls="accordion-collapse-body-1"
                            >
                                <span>Add nominee3</span>
                                <svg
                                    data-accordion-icon=""
                                    className={`w-3 h-3 rotate-${isVisible2 ? '0' : '180'} shrink-0`} // Rotate icon based on visibility state
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5 5 1 1 5"
                                    />
                                </svg>
                            </button>
                        </h2>
                        {isVisible2 && ( // Conditionally render accordion content based on visibility state
                            <div
                                id="accordion-collapse-body-1"
                                className=""
                                aria-labelledby="accordion-collapse-heading-1"
                            >
                                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900" style={{ paddingBottom: "0" }}>
                                    <div>
                                        <div className=" mb-4 ccustom-row">
                                            <div className='col-md-6'>
                                                <select
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    value={selectedIdentification2} // Set the value of the dropdown to the state
                                                    onChange={handleIdentificationChange2} // Call the function to handle change in dropdown
                                                >
                                                    <option value="">Select proof of identity</option>
                                                    <option value="Aadhaar">Aadhaar</option>
                                                    <option value="PAN">PAN</option>
                                                </select>
                                            </div>
                                            {
                                                !selectedIdentification2 ?
                                                    null
                                                    :
                                                    <div className='col-md-6'>

                                                        <label style={{ display: 'block' }} className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Enter{' '}{selectedIdentification}</label>
                                                        <InputMask
                                                            mask={selectedIdentification2 === 'Aadhaar' ? "999999999999" : 'aaaaa9999a'}
                                                            maskChar={null}
                                                            type='text'
                                                            className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                            placeholder={` ${selectedIdentification2} Number`}
                                                            value={pan2}
                                                            onChange={(e) => setPan2(e.target.value.toUpperCase())}

                                                        />
                                                    </div>

                                            }
                                            <div className='col-md-6'>
                                                <label className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap`}>Full Name</label>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Full Name"
                                                    value={fullName2}
                                                    onChange={(e) => setFullName2(e.target.value)}
                                                    // readOnly={selectedIdentification2 == 'PAN' ? true : false}
                                                    maxLength={100}
                                                />

                                            </div>

                                            <div className='col-md-6 col-md-date-picker'>
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        type="date"
                                                        name="dob"
                                                        placeholder="DOB"
                                                        className="h-[56px] w-[500px] pl-1 pr-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none appearance-none"
                                                        value={dob2}
                                                        onChange={(e) => setDob2(e.target.value.toUpperCase())}
                                                        max={new Date().toISOString().split('T')[0]} // Optionally set a maximum date (today's date)
                                                    />
                                                    <div
                                                        style={{ position: 'absolute', right: '10px', top: '5%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Email"
                                                    value={email2}
                                                    onChange={(e) => setEmail2(e.target.value)}


                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <InputMask
                                                    mask="9999999999" maskChar={null}
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Mobile Number"
                                                    value={mobile2}
                                                    onChange={(e) => setMobile2(e.target.value)}

                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <select
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    value={selectedRelation2} // Set the value of the dropdown to the selected relation state
                                                    onChange={handleRelationChange2}
                                                >
                                                    {/* <option value="">Select Relation</option> */}
                                                    {relationDta.map((option, index) => (
                                                        <option key={index} value={option.id} >{option.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    className="min-w-[20px] h-5 inline-flex items-center text-[#323232] bg-white focus:ring-0 accent-blue-500"
                                                    checked={isChecked2}
                                                    onChange={handleCheckboxChange2}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                <label style={{ display: 'block' }} className={`loader-nominee-box text-md leading-[27px] font-medium font-poppinsMedium text-center d-block-label md:text-left whitespace-nowrap`}>Same as current address
                                                    &nbsp;
                                                    {
                                                        smallLoaderActive2 == true &&
                                                        <img src={loader} alt="" className='' />

                                                    }


                                                </label>
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address1"
                                                    value={address12}
                                                    onChange={(e) => setAddress12(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address2"
                                                    value={address22}
                                                    onChange={(e) => setAddress22(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Address3"
                                                    value={address32}
                                                    onChange={(e) => setAddress32(e.target.value)}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <InputMask
                                                    mask="999999" maskChar={null}
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Pincode"
                                                    value={pincode2}
                                                    onChange={(e) => setPincode2(e.target.value)}



                                                />
                                            </div>

                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="State"
                                                    value={state2}
                                                    onChange={(e) => setState2(e.target.value)}
                                                    readOnly={check12 ? true : false}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input
                                                    type='text'
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="City"
                                                    value={city2}
                                                    onChange={(e) => setCIty2(e.target.value)}
                                                    readOnly={check12 ? true : false}
                                                />
                                            </div>

                                            <div className='col-md-4' style={{ overflow: 'hidden' }}>
                                                <input
                                                    className="h-[56px] w-[500px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
                                                    placeholder="Sharing"
                                                    value={share2}
                                                    onChange={handleShareChange2}


                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                }
            </div>
            <div className="my-5 flex item justify-between">
                <BackBtn handleBackBtn={() => backBtn({ step, index })} />
                <ContinueBtn
                    handleContinueBtn={handleContinueBtn}
                    isDisable={!termsAndCondition?.value}
                />
            </div>
        </div>
    );
};


