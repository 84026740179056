import { svgAssets } from '../../assets/asset';

export const VerifyBankBtn = ({ height, isDisable, isVerify, verifyBtn , status}) => {
	console.log(status,'ghghghg');
	return (
		
		<button
			className={`px-5 h-[${height || '47px'}] flex items-center rounded-[10px] leading-6 text-white
				${isDisable ? 'cursor-default bg-[#dfe1e1]' : isVerify==='success'? 'bg-[#007813] cursor-default' : status === 'pending' && 'bg-[#FF4343] cursor-pointer'}
			`}
			onClick={() => !isDisable && verifyBtn()}
			style={status === 'success' ? {background: '#007813'} : status === 'failed' ? {background: '#FF4343'} : isDisable ? {background: '#dedede'}  : status === 'pending' ? {background: '#000'} : ''}
			disabled={isDisable}
		>
			<img className="mr-2" alt="back_icon" src={status === 'success' ? svgAssets.kyc.verify : status === 'pending' ? svgAssets.kyc.verify : status === 'failed' ? svgAssets.kyc.notverify : ''} />
			<span>{ status === 'success' ? 'Verified' : status === 'failed' ? 'Not Verified': 'Verify'}</span>
		</button>
	);
};
