import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MainTitle } from '../../components/common/MainTitle';
import moment from 'moment';
import { SingleDetail } from './SingleDetail';

export const PanDetail = ({ isVerify, reviewData, reviewData2, panCardPdf, kycStep2,img,reviewFilesData }) => {
	const [isLoading, setIsLoading] = useState(true);
	const FName = reviewData?.FName !== null ?reviewData?.FName:''
	const LName = reviewData?.LName !== null ? reviewData?.LName : ''
	const MName = reviewData?.MName !== null ? reviewData?.MName : ''
	const name = FName + ' ' + MName + ' ' + LName
	const PAN_Card = reviewFilesData?.length > 0 && reviewFilesData?.find(a => (a?.Document == 'PAN Card' ||a?.Document == 'Pan Upload'))


	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);


	return (
		// <div className="mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]">
		// 	<div className="mb-5">
		// 		<MainTitle title="PAN Details" />
		// 	</div>
		// 	<div className="grid grid-cols-12 gap-10">
		// 		<div className="col-span-6">
		// 			{
		// 				isLoading ?
		// 					<Skeleton count={4} height={50} />
		// 					:
		// 					<>
		// 						<SingleDetail label="PAN" value={reviewData?.pan || reviewData?.PanNo || ''} />
		// 						<SingleDetail label="DOB" value={moment(reviewData?.BirthDate).format('DD-MM-YYYY') || '-'} isVerify={isVerify} />
		// 						<SingleDetail label="Name as on PAN" value={name || '-'} />
		// 					</>
		// 			}
		// 			<div className="pt-[10px] mb-2 grid md:grid-cols-3 gap-5 items-center cursor-pointer">
		// 				<div className="text-lg font-medium font-poppinsMedium">Documents Uploaded</div>
		// 				<div
		// 					className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
		// 					onClick={() => {}}
		// 				>
		// 					<img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
		// 					<div className="flex flex-col justify-center">
		// 						<span className="text-[#757575] text-xs font-poppinsRegular">Pan Car...jpeg</span>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
			
		// </div>
<div className={PAN_Card  ? 'column-row':'mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]'}>
{/* <div className='column-row'> */}
		<div className="column-col-6">
    <div className="mb-5">
        <MainTitle title="PAN Details" />
    </div>
    <div className="grid grid-cols-12 gap-10">
        <div className="col-span-8">
            {isLoading ? (
                <Skeleton count={4} height={50} />
            ) : (
                <>
                    <SingleDetail label="PAN" value={reviewData?.pan || reviewData?.PanNo || ''} />
                    <SingleDetail label="DOB" value={moment(reviewData?.BirthDate).format('DD-MM-YYYY') || '-'} isVerify={isVerify} />
                    <SingleDetail label="Name as on PAN" value={name || '-'} />
                    <SingleDetail label="Gender" value={reviewData?.Gender == 'F'?"Female":reviewData?.Gender == 'M'?"Male":"Transgender" || '-'} />
                </>
            )}
            {/* <div className="pt-[10px] mb-2 grid md:grid-cols-3 gap-5 items-center cursor-pointer">
                <div className="text-lg font-medium font-poppinsMedium">Documents Uploaded</div>
                <div
                    className="px-3 py-1.5 w-max flex items-center justify-start rounded-[10px] border border-solid border-[#E0E0E0] cursor-pointer"
                    onClick={() => {setVerifyModal(!verifyModal)}}
                >
                    <img className="mr-2 w-[50px]" alt="doc_view" src={svgAssets.kyc.docView}></img>
                    <div className="flex flex-col justify-center">
                        <span className="text-[#757575] text-xs font-poppinsRegular">Pan Card</span>
                    </div>
                </div>
            </div> */}
        </div>
        {/* Add the following div for the image */}
      
{/* {verifyModal &&
  <div  className="col-span-6">
    <img className="w-full"style={{height:300}} src={'https://dkyc.bonanzaonline.com/ekycapi/storage/app/image_kyc/900129123/963432.jpg'} alt="additional_image" />
</div>
} */}

				
			
    </div>
</div>
{
	PAN_Card&&
	<iframe style ={{height:'400px',width:'700px',overflow:'scroll'}} title="PDF Viewer" src={PAN_Card?.ImagePath}  />
	
}

</div>

	);
};
