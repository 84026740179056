// import React, { useState } from 'react';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import { postApi } from '../services/common';
// import { useSelector } from 'react-redux';
// import { CommonInput } from '../components/inputs/CommonInput';

// export const AccountList = () => {
// 	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
// 	const [mobile, setMobile] = useState('')
// 	const [email, setEmail] = useState('')
// 	const [fname, setFname] = useState('')
// 	const [lname, setLname] = useState('')
// 	const [pan, setPan] = useState('')
// 	const [branchCode, setBranchCode] = useState('')
// 	const [introducer, setIntroducer] = useState('')
// 	const [online, setOnline] = useState('')
// 	const [rmcode, setRmcode] = useState('')
// 	const [mobileError, setMobileError] = useState('')
// 	const [emailError, setEmailError] = useState('')
// 	const [errorFname, setErrorFname] = useState('')
// 	const [errorLname, setErrorLname] = useState('')
// 	const [errorPan, setErrorPan] = useState('')
// 	const [errorBranch, setErrorBranch] = useState('')
// 	const [errorInduce, setErrorinducer] = useState('')
// 	const [errorOnline, setErronline] = useState('')
// 	const [errorRm, setErrRm] = useState('')
// 	const navigate = useNavigate();

// 	const handleUpdate = async () => {
// 		if (!mobile || mobile.length !== 10) {
// 			setMobileError('Please enter a valid 10 digit mobile number');
// 		}
// 		if (!fname) {
// 			setErrorFname('Please enter first name');
// 		}
// 		if (!lname) {
// 			setErrorLname('Please enter Last name');
// 		}
// 		if (!isValidEmail(email)) {
// 			setEmailError('Please enter a valid email address');

// 		}
// 		if (!pan) {
// 			setErrorPan('Please enter pan');

// 		}
// 		else {
// 			const payload = {
// 				user_id: userDetail?.user_id,
// 				user_mobile: mobile,
// 				user_email: email,
// 				f_name: fname,
// 				l_name: lname,
// 				pan: pan
// 			}
// 			const response = await postApi(payload, '/updateUserProfile');
// 			if (response?.data?.success === true) {
// 				navigate('/')
// 			}
// 			else {
// 				toast(response?.data?.msg || response.data.message || 'something went wrong')
// 			}
// 		}
// 	};

// 	const isValidEmail = (email) => {
// 		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// 		return emailRegex.test(email);
// 	};

// 	const handleInput = ({ type, value }) => {
// 		if (type == "branch_code") {
// 			setBranchCode(value)
// 			setErrorBranch('')
// 		}
// 		if (type == "introducer") {
// 			setIntroducer(value)
// 			setErrorinducer('')

// 		}
// 		if (type == "online_branch") {
// 			setOnline(value)
// 			setErronline('')

// 		}
// 		if (type == "rm_code") {
// 			setRmcode(value)
// 			setErrRm('')
// 		}
// 		if (type == "F_name") {
// 			setFname(value)
// 			setErrorFname('')
// 		}
// 		if (type == "l_name") {
// 			setLname(value)
// 			setErrorLname("")
// 		}
// 		if (type == "user_email") {
// 			setEmail(value)
// 			setEmailError('')
// 		}
// 		if (type == "user_mobile") {
// 			setMobile(value)
// 			setMobileError('');
// 		}
// 		if (type == "user_pan") {
// 			setPan(value)
// 			setErrorPan('')
// 		}
// 	};

// 	const handleSubmit = () => {
// 		if (!branchCode) {
// 			setErrorBranch('Enter branch code')
// 		}
// 		if (!introducer) {
// 			setErrorinducer('Enter introducer')
// 		}
// 		if (!online) {
// 			setErronline('Enter online')
// 		}
// 		if (!rmcode) {
// 			setErrRm('Enter rm code')
// 		}
// 		else {
// 			alert()
// 		}
// 	}
// 	return (
// 		<>
// 			<div style={{ width: '1000px' }} className=" flex-1 mx-auto px-6 py-5 bg-white rounded-[20px] shadow-[0px_4px_15px_rgba(171,171,171,0.25)]">
// 				<div className="w-full px-5 max-h-[calc(100vh-100px)] ">
// 					<h6 className="text-[20px] leading-[48px] font-semibold font-poppinsSemiBold">{'Update profile'}</h6>
// 					<div className="">
// 						<div className="mb-4 grid grid-cols-2 gap-10">
// 							<CommonInput
// 								label="First Name"
// 								isImportant={false}
// 								placeholder="Please enter first name"
// 								height="45px"
// 								inputType="F_name"
// 								handleInput={handleInput}
// 								value={fname}
// 								error={errorFname} />

// 							<CommonInput
// 								label="Last Name"
// 								isImportant={false}
// 								placeholder="Please enter last name"
// 								height="45px"
// 								inputType="l_name"
// 								handleInput={handleInput}
// 								value={lname}
// 								error={errorLname}
// 							/>
// 						</div>
// 						<div className="mb-3 grid grid-cols-2 gap-10">
// 							<CommonInput
// 								label="Email"
// 								isImportant={false}
// 								placeholder="Please enter email"
// 								inputType="user_email"
// 								handleInput={handleInput}
// 								value={email}
// 								error={emailError}
// 							/>
// 							<CommonInput
// 								type={'number'}
// 								label="Mobile"
// 								isImportant={false}
// 								placeholder="Please enter mobile"
// 								inputType="user_mobile"
// 								handleInput={handleInput}
// 								value={mobile}
// 								error={mobileError}
// 								maxLength={10} />
// 						</div>

// 						<div className="mb-3 grid grid-cols-2 gap-10">
// 							<CommonInput
// 								label="Pan"
// 								isImportant={false}
// 								placeholder="Please enter pan"
// 								inputType="user_pan"
// 								handleInput={handleInput}
// 								value={pan}
// 								error={errorPan} />
// 						</div>
// 					</div>
// 					<div className="flex items-center justify-center mb-5">
// 						<div
// 							className={`${'35px'} px-8 py-2 flex items-center rounded-[10px] leading-6 text-white bg-black font-poppinsRegular cursor-pointer`}
// 							onClick={handleUpdate}  >
// 							<p style={{ color: 'white', fontWeight: 'bold' }}>Update</p>
// 						</div>
// 					</div>
// 				</div>
// 				<div style={{ border: '1px solid gray', marginBottom: 25 }}></div>
// 				<div className="w-full px-5 max-h-[calc(100vh-100px)] ">
// 					<div className="">
// 						<div className="mb-4 grid grid-cols-2 gap-10">
// 							<CommonInput
// 								label="Branch code"
// 								isImportant={false}
// 								placeholder="Please enter branch code"
// 								height="45px"
// 								inputType="branch_code"
// 								handleInput={handleInput}
// 								value={branchCode}
// 								error={errorBranch}


// 							/>

// 							<CommonInput
// 								label="Introducer code"
// 								isImportant={false}
// 								placeholder="Please enter introducer code"
// 								height="45px"
// 								inputType="introducer"
// 								handleInput={handleInput}
// 								value={introducer}
// 								error={errorInduce}

// 							/>
// 						</div>

// 						<div className="mb-3 grid grid-cols-2 gap-10">
// 							<CommonInput
// 								label="Online branch"
// 								isImportant={false}
// 								placeholder="Please enter online branch"
// 								inputType="online_branch"
// 								handleInput={handleInput}
// 								value={online}
// 								error={errorOnline}

// 							/>

// 							<CommonInput
// 								label="Rm code"
// 								isImportant={false}
// 								placeholder="Please enter rm code"
// 								inputType="rm_code"
// 								handleInput={handleInput}
// 								value={rmcode}
// 								error={errorRm}

// 							/>
// 						</div>
// 					</div>

// 					<div className="flex items-center justify-center">
// 						<div onClick={handleSubmit}
// 							className={`${'35px'} px-8 py-2 flex items-center rounded-[10px] leading-6 text-white bg-black font-poppinsRegular cursor-pointer mt-5`}
// 						>
// 							<p style={{ color: 'white', fontWeight: 'bold' }}>Submit</p>
// 						</div>
// 					</div>
// 					<div style={{ marginTop: 20, marginBottom: 50 }}></div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { postApi } from '../services/common';
import { useSelector } from 'react-redux';

export const AccountList = () => {
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
const [mobile, setMobile] = useState(userDetail?.mobile )
	const [email, setEmail] = useState(userDetail?.email || '')
	const navigate = useNavigate();

const handleUpdate = async () => {
		if (!mobile || mobile.length < 10 || mobile.length > 10) {
			toast('Please enter a valid mobile number');
			return;
		}
		if (!isValidEmail(email)) {
			toast('Please enter a valid email address');
			return;
		}
		const payload = {
			user_id:userDetail?.user_id,
			user_mobile :mobile, 
			user_email:email
		}
		const response = await postApi(payload,'/updateUserProfile');
		if (response?.data?.success == true) {
			navigate('/')
		}
		else {
			toast(response?.data?.msg || response.data.message || 'something went wrong')
		}


	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<div style={{ width: '50%', textAlign: 'center' }} className="mx-auto px-6 py-5 bg-white rounded-[20px] shadow-[0px_4px_15px_rgba(171,171,171,0.25)] mt-5">
			<div className="flex justify-between mb-2.5">
				{
					<div className="">
						<h6 className="text-[20px] leading-[48px] font-semibold font-poppinsSemiBold">{'Update profile'}</h6>
						<input
							type='tel'
							className="h-[56px] w-[400px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
							placeholder="Please enter mobile number"
							value={mobile}
							onChange={(e) => setMobile(e.target.value)}
							maxLength={10}
					
						/>
						<input
							type='text'
							className="mt-5 h-[56px] w-[400px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
							placeholder="Please enter email "
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						// autoFocus={true}
						/>
						<div style={{
							background: '#121212',

							height: '50px',
							width: '150px',
							// marginBottom: '30px',
							marginTop: '20px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							alignSelf: 'center', borderRadius: 10, cursor: 'pointer',marginLeft:'auto',marginRight:'auto'
						}} onClick={handleUpdate}  >
							<p style={{ color: 'white', fontWeight: 'bold' }}>Update</p>
						</div>
					</div>
					
				}


			</div>
		</div>
	);
};

