import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getBrokerageSchemeDetail, getBrokerageSchemeDataDetail, getDeclarationList, getDPTariffDetail, getIncomeProofList, getTransactionDetail, saveBrokerageInfo, saveBusinessInfo, saveDeclarationInfo, saveSegmentInfo, updateSegmentInfo , setBrokerageSchemeDetailAction, setBrokerageSchemeDetailDataAction, setDeclarationListAction, setDPTariffDetailAction, setIncomeProofListAction, setTransactionDetailAction, saveDeclarationInfoDataAction, getBrokerageSchemeList, setBrokerageSchemeListAction } from '../../redux/actions/kyc/step6.action';
import { getApi, getApiWithoutLoader, postApi, postApiWithoutLoader } from '../../services/common';
import { toast } from 'react-toastify';

function* getTransactionDetailSaga() {
	try {
		const data = yield call(getApiWithoutLoader, '/GetTransactionMaster');
		yield put(setTransactionDetailAction({ data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* setBrokerageSchemeDetailSaga() {
	try {
		const data = yield call(getApiWithoutLoader, '/GetBrkgPlanMaster');
		const mapData = data?.data?.data && data.data.data.map((el) => ({ value: el.name, label: el.name }));
		yield put(setBrokerageSchemeDetailAction({ data: mapData || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* setBrokerageSchemeListSaga(payload) {
	try {
		const data = yield call(postApiWithoutLoader , payload?.payload,'/get-intrade-brokerage');
		yield put(setBrokerageSchemeListAction({ data: data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* getBrokerageSchemeDetailDataSaga(payload) {
	
	try {
		// const data = yield call(postApi, payload.payload, '/GetBrkgPlanSchemeDetails');
		const data = yield call(postApiWithoutLoader, payload?.payload, '/GetBrokerageType');
		yield put(setBrokerageSchemeDetailDataAction(data || {}));
	} catch (err) {
		console.log(err);
	};
};

function* getDPTariffDetailSaga() {
	try {
		const data = yield call(getApiWithoutLoader, '/GetDPSchemes');
		const mapData = data?.data?.data && data.data.data.map((el, index) => ({ value: el, label: el }));
		yield put(setDPTariffDetailAction({ data: mapData || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* getDeclarationListSaga() {
	try {
		const data = yield call(getApiWithoutLoader, '/GetDeclarationPreferences');
		console.log(data,'data');
		const mapData = data?.data?.data?.length > 0 && data.data.data.map((el1, i1) => ({
			label: el1[`declaration${i1 + 1}`],
			isSelected:el1?.check_status == 'Yes'? true:false,
			description:el1.description?el1.description:null,
			selectedOption: el1?.options?.length > 0 ? el1?.options[0] : undefined,
			options: el1?.options?.length > 0
				? el1.options.map((el2) => ({
					label: el2
				}))
				: undefined
		}));
		yield put(setDeclarationListAction({ data: mapData || [] }));
	} catch (err) {
		console.log(err);
	};
};

function* getIncomeProofListSaga({callback}) {
	try {
		const data = yield call(getApiWithoutLoader, '/GetIncomeProofType');
		const mapData = data?.data?.data && data.data.data.map((el) => ({ value: el.documentype_name, label: el.documentype_name }));
		yield put(setIncomeProofListAction({ data: mapData || [] }));
		yield call(callback, mapData);
	} catch (err) {
		console.log(err);
	};
};

function* saveBusinessInfoSaga({ payload,callback }) {
	try {
		const data = yield call(postApi, payload, '/AddBusinessInfo');
		yield call(callback, data);
	} catch (err) {
		console.log(err);
	};
};
 
function* saveBrokerageInfoSaga({ payload,callback }) {
	try {
		const data = yield call(postApi, payload, '/AddBrokerageInfo');
		yield call(callback, data?.data);
	} catch (err) {
		console.log(err);
	};
};

function* saveSegmentInfoSaga({ payload,callback }) {
	try {
		const data = yield call(postApi, payload, '/AddSegmentInfo');
		yield call(callback, data?.data);
	} catch (error) {
		if (error.response) {
			toast(`API error: ${error.response.status} - ${error.response.data.message}`);
		  } else if (error.message) {
			toast(`API error: ${error.message}`);
		  } else {
			toast('API error. Please try again.');
		  }
	};
};
function* updateSegmentInfoSaga({ payload,callback }) {
	try {
		const data = yield call(postApi, payload, '/UpdateSegmentInfo');
		yield call(callback, data?.data);
	} catch (error) {
		if (error.response) {
			toast(`API error: ${error.response.status} - ${error.response.data.message}`);
		  } else if (error.message) {
			toast(`API error: ${error.message}`);
		  } else {
			toast('API error. Please try again.');
		  }
	};
};

function* saveDeclarationInfoSaga({ payload,callback }) {
	try {
		const data = yield call(postApi, payload, '/save-transactions-info');
		yield put(saveDeclarationInfoDataAction(data?.data || {}));
		yield call(callback, data?.data);
		console.log(data);
	} catch (err) {
		console.log(err);
		yield call(callback,err);
	};
};

function* kycStep6Saga() { 
	yield all([
		takeEvery(getTransactionDetail, getTransactionDetailSaga),
		takeEvery(getBrokerageSchemeDetail, setBrokerageSchemeDetailSaga),
		takeEvery(getBrokerageSchemeDataDetail, getBrokerageSchemeDetailDataSaga),
		takeEvery(getDPTariffDetail, getDPTariffDetailSaga),
		takeEvery(getDeclarationList, getDeclarationListSaga),
		takeEvery(getIncomeProofList, getIncomeProofListSaga),
		takeEvery(saveBusinessInfo, saveBusinessInfoSaga),
		takeEvery(saveBrokerageInfo, saveBrokerageInfoSaga),
		takeEvery(saveSegmentInfo, saveSegmentInfoSaga),
		takeEvery(updateSegmentInfo, updateSegmentInfoSaga),
		takeEvery(saveDeclarationInfo, saveDeclarationInfoSaga),
		takeEvery(getBrokerageSchemeList, setBrokerageSchemeListSaga),
	]);
}

export default kycStep6Saga;

// import { all, call, put, takeEvery } from 'redux-saga/effects';
// import { getBrokerageSchemeDetail, getBrokerageSchemeDataDetail, getDeclarationList, getDPTariffDetail, getIncomeProofList, getTransactionDetail, saveBrokerageInfo, saveBusinessInfo, saveDeclarationInfo, saveSegmentInfo, updateSegmentInfo , setBrokerageSchemeDetailAction, setBrokerageSchemeDetailDataAction, setDeclarationListAction, setDPTariffDetailAction, setIncomeProofListAction, setTransactionDetailAction, saveDeclarationInfoDataAction, getBrokerageSchemeList, setBrokerageSchemeListAction,getDPTariffDetail1, setDPTariffDetailAction1} from '../../redux/actions/kyc/step6.action';
// import { getApi, postApi } from '../../services/common';

// function* getTransactionDetailSaga() {
// 	try {
// 		const data = yield call(getApi, '/GetTransactionMaster');
// 		yield put(setTransactionDetailAction({ data: data?.data?.data || [] }));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* setBrokerageSchemeDetailSaga() {
// 	try {
// 		const data = yield call(getApi, '/GetBrkgPlanMaster');
// 		const mapData = data?.data?.data && data.data.data.map((el) => ({ value: el.name, label: el.name }));
// 		yield put(setBrokerageSchemeDetailAction({ data: mapData || [] }));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// //--- brokerage scheme list api ----

// function* setBrokerageSchemeListSaga() {
// 	try {
// 		const data = yield call(getApi ,'/get-intrade-brokerage');
// 		yield put(setBrokerageSchemeListAction({ data: data?.data || [] }));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* getBrokerageSchemeDetailDataSaga(payload) {
// 	try {
// 		// const data = yield call(postApi, payload.payload, '/GetBrkgPlanSchemeDetails');
// 		const data = yield call(postApi, payload?.payload, '/GetBrokerageType');
// 		yield put(setBrokerageSchemeDetailDataAction(data || {}));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* getDPTariffDetailSaga() {
// 	try {
// 		const data = yield call(getApi, '/GetDPSchemes');
// 		const mapData = data?.data?.data && data.data.data.map((el, index) => ({ value: el, label: el }));
// 		yield put(setDPTariffDetailAction({ data: mapData || [] }));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };
// function* getDPTariffDetailSaga1() {
// 	try {
// 		const data = yield call(getApi, '/get-delivery-brokerage');
// 		// const mapData = data?.data?.data && data.data.data.map((el, index) => ({ value: el, label: el }));
// 		yield put(setDPTariffDetailAction1({ data: data?.data || [] }));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* getDeclarationListSaga() {
// 	try {
// 		const data = yield call(getApi, '/GetDeclarationPreferences');
// 		const mapData = data?.data?.data?.length > 0 && data.data.data.map((el1, i1) => ({
// 			label: el1[`declaration${i1 + 1}`],
// 			isSelected: true,
// 			selectedOption: el1?.options?.length > 0 ? el1?.options[0] : undefined,
// 			options: el1?.options?.length > 0
// 				? el1.options.map((el2) => ({
// 					label: el2
// 				}))
// 				: undefined
// 		}));
// 		yield put(setDeclarationListAction({ data: mapData || [] }));
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* getIncomeProofListSaga({callback}) {
// 	try {
// 		const data = yield call(getApi, '/GetIncomeProofType');
// 		const mapData = data?.data?.data && data.data.data.map((el) => ({ value: el.documentype_name, label: el.documentype_name }));
// 		yield put(setIncomeProofListAction({ data: mapData || [] }));
// 		yield call(callback, mapData);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* saveBusinessInfoSaga({ payload }) {
// 	try {
// 		const data = yield call(postApi, payload, '/AddBusinessInfo');
// 		console.log(data);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* saveBrokerageInfoSaga({ payload }) {
// 	try {
// 		const data = yield call(postApi, payload, '/AddBrokerageInfo');
// 		console.log(data);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* saveSegmentInfoSaga({ payload }) {
// 	try {
// 		const data = yield call(postApi, payload, '/AddSegmentInfo');
// 		console.log(data);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };
// function* updateSegmentInfoSaga({ payload }) {
// 	try {
// 		const data = yield call(postApi, payload, '/UpdateSegmentInfo');
// 		console.log(data);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* saveDeclarationInfoSaga({ payload }) {
// 	try {
// 		const data = yield call(postApi, payload, '/save-transactions-info');
// 		yield put(saveDeclarationInfoDataAction(data?.data || {}));
// 		console.log(data);
// 	} catch (err) {
// 		console.log(err);
// 	};
// };

// function* kycStep6Saga() {
// 	yield all([
// 		takeEvery(getTransactionDetail, getTransactionDetailSaga),
// 		takeEvery(getBrokerageSchemeDetail, setBrokerageSchemeDetailSaga),
// 		takeEvery(getBrokerageSchemeDataDetail, getBrokerageSchemeDetailDataSaga),
// 		takeEvery(getDPTariffDetail, getDPTariffDetailSaga),
// 		takeEvery(getDPTariffDetail1, getDPTariffDetailSaga1),
// 		takeEvery(getDeclarationList, getDeclarationListSaga),
// 		takeEvery(getIncomeProofList, getIncomeProofListSaga),
// 		takeEvery(saveBusinessInfo, saveBusinessInfoSaga),
// 		takeEvery(saveBrokerageInfo, saveBrokerageInfoSaga),
// 		takeEvery(saveSegmentInfo, saveSegmentInfoSaga),
// 		takeEvery(updateSegmentInfo, updateSegmentInfoSaga),
// 		takeEvery(saveDeclarationInfo, saveDeclarationInfoSaga),
// 		takeEvery(getBrokerageSchemeList, setBrokerageSchemeListSaga),
// 	]);
// }

// export default kycStep6Saga;