import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
import { svgAssets } from '../../../assets/asset';
import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';
import {
	changeStep7ErrorAction,
	callSendSelfieLinkAction
} from '../../../redux/actions/kyc/step7.action';
import { DOMAIN } from '../../../config/config';


export const UploadSelfie = ({ linktoken, isDocumentUploaded,step, index, backBtn, continueBtn, isChildComp, rejectedFieldsData }) => {
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);
	const { panInput } = useSelector(state => state?.kycStep2 || {});
	const { selfieImage, isError, errorText } = useSelector(state => state?.kycStep7 || '');
	const kycStep1 = useSelector(state => state?.kycStep1 || {});
	const kycStep2 = useSelector(state => state?.kycStep2 || {});
	const validatePanDetails = useSelector(state => state?.kycStep2.validatePanDetails.data || {});
	let clientLink = DOMAIN + '/dkyc/';
	if (linktoken) {
		clientLink = DOMAIN + '/dkyc/' + linktoken + '?isSelfie=1';
	}

	const [isCopy, setIsCopy] = useState(false);
	const user_image = reviewFiles && reviewFiles?.filter(a => (a?.Document === 'Client Photo' || a?.Document === 'Client photo'));
	const lastItemIndex = user_image?.length - 1;

	// const handleImage = (e) => {
	// 	if (!e.target.files || e.target.files.length === 0) {
	// 		return;
	// 	}
	// 	dispatch(uploadSelfieAction({
	// 		PanNo: panInput?.value || '',
	// 		Document: 'Client Photo',
	// 		DocumentType: 'NA',
			
	// 		CreatedBy: userDetail.created_by,
	// 		'DocumentImages[]': e.target.files[0]
	// 	}));
	// };

	const handleContinueBtn = () => {
		const isValidSelfie = selfieImage !== '';

		if (!selfieImage || !isValidSelfie) {
			dispatch(changeStep7ErrorAction({ isError: true, errorText: 'invalid input!' }));
		} else {
			continueBtn({ step, index });
		}
	};

	const handleSendLink = () => {
		dispatch(callSendSelfieLinkAction(
			{
				"name": validatePanDetails.firstName + " " + validatePanDetails.lastName,
				"mobile": kycStep1.mobileInput.value,
				"link": clientLink
			}
		));
	}

	const handleCopyLink = () => {
		navigator.clipboard.writeText(clientLink);
		setIsCopy(true);
	}

	return (
		<div className="w-full flex flex-col">
			<div className={`${!isChildComp}`}>
				<div className="mb-8 grid md:grid-cols-2 gap-x-10 items-end mt-3">
					<div className="flex flex-col ">
						{/* <InputCopyBtnIcon
							icon={svgAssets.kyc.selfie}
							label="Selfie Link"
							// subLabel="Share below link with client"
							isImportant={true}
							placeholder={clientLink}
							btnWidth="67px"
							btnIcon={ClipboardIcon}
							inputMessage={errorText}
							inputMessageType={isError && 'error'}
							handleSubmit={handleCopyLink}
							defaultValue={clientLink}
							isDisable={true}
							isCopy={isCopy}
						/> */}
						<label className="flex items-center leading-6 font-medium font-poppinsMedium">
				{
					 (<img
						className="max-h-[22px] mr-1.5 object-contain"
						alt="input_icon"
						src={svgAssets.kyc.selfie}
					/>)
				}
				<span className="mr-1">{'Selfie Image'}</span>
				{/* {isImportant && <span className="text-[#EA0000]">*</span>} */}
			</label>
						{
							rejectedFieldsData?.rejectedClientPhoto?.length > 0 &&
							<>
								<div className='bg-[#FFC9C9] border-[#f99f9f] border-solid border-2 px-5 py-3 rounded mt-3'>
									<p
										className='text-[#343434]-500'
										style={{ fontWeight: '300', fontSize: '15px', color: '#343434' }}
									>
										{rejectedFieldsData.rejectedClientPhoto[0].reason}
									</p>
								</div>
							</>
						}
					</div>
					<div className="flex flex-col relative ">
						<div className='hover:text-[#5466fc] relative flex items-center justify-between ' >
							{isDocumentUploaded?.success && user_image && user_image.map((item, index) => {
								const isLastItem = index === lastItemIndex;
								return (
									<>
										{isLastItem &&
											<div key={index} className='bg-white p-2 w-[150px] h-[130px] flex items-center justify-center mt-3'>
											<a href={item?.ImagePath} target='_blank'>
												<img width='80' src={item?.ImagePath} />
											</a>
										</div>}
									</>
								);
							})}

							{
								isDocumentUploaded?.success &&
								<button
									className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}
								>
									<img
										src={svgAssets.kyc.verify}
										className="mr-1.5"
									/>
									<span>
										Uploaded
									</span>
								</button>
							}
						</div>
					</div>
					{/* <div className=" flex items-center gap-x-2 px-5 mt-4">
						<img className="w-[19px] h-[19px]" alt="back_icon" src={svgAssets.kyc.greencheck} />
						<span className="col-span-5 text-[#007813] text-[13px] leading-[18px] font-medium font-poppinsMedium ">
							Selfie successfully submitted by client
						</span>
					</div> */}

				</div>
			</div>
			{/* {
				!isChildComp && (
					<div className="flex item justify-between">
						<BackBtn handleBackBtn={() => backBtn({ step, index })} />
						{
							selfieImage === '' ?
								<ContinueBtn isDisable={true} />
								:
								<ContinueBtn handleContinueBtn={handleContinueBtn} />
						}
					</div>
				)
			} */}

		</div>
	);
};
