import React, { useEffect, useState } from 'react';
import { store } from '../../../redux/store';
import { startLoaderAction, stopLoaderAction } from '../../../redux/actions/app.action';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import refLinkimg from '../../../assets/png/refLink.png'
import copyIcon from '../../../assets/svg/selfie/copyLink.svg'


export const RefLink = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const [refLink, setRefLink] = useState('');
    const [selectedType, setSelectedType] = useState('BIGUL'); // State to track selected radio button

    useEffect(() => {
        check();
    }, [selectedType]); // Call check when selectedType changes

    const check = async () => {
        try {
            store.dispatch(startLoaderAction());
            const response = await axios.post(
                'https://bigul.co/api/partnerReferalLink',
                {
                    username: userDetail?.username,
                    type: selectedType // Use selectedType here
                },
            );

            if (response.data?.status === 200) {
                store.dispatch(stopLoaderAction());
                setRefLink('https://bonanza.bigul.co/ba?ref=' + response?.data?.ref + '&channel=' + selectedType);
            } else {
                toast(response?.msg || 'something went wrong');
                store.dispatch(stopLoaderAction());
            }

        } catch (error) {
            toast('something went wrong');
            store.dispatch(stopLoaderAction());
        }
    };

    const copyUrl = () => {
        const textToCopy = refLink; // Replace with your URL or text

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast('Link copied!');
            })
            .catch((err) => {
                toast(err.message || 'Failed to copy link');
            });
    };

    return (
        <div className="referral-link-box">
            <img src={refLinkimg} alt="Link Building" className="ref-link-img" />
            <h4 className="ref-link-heading">Generate Your Referral Link</h4>
            <p className="ref-link-description">Invite New Business Ambassadors to join our Business Ambassadors network</p>

            <div className="radio-group" style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
                <label className="radio-label">
                    <input
                        type="radio"
                        value="BIGUL"
                        checked={selectedType === 'BIGUL'}
                        onChange={() => setSelectedType('BIGUL')}
                        className="radio-input"
                    />
                    Bigul
                </label>
                <label className="radio-label">
                    <input
                        type="radio"
                        value="Bonanza"
                        checked={selectedType === 'Bonanza'}
                        onChange={() => setSelectedType('Bonanza')}
                        className="radio-input"
                    />
                    Bonanza
                </label>
            </div>

            <div className="link-group">
                <input
                    type="text"
                    value={refLink}
                    className="form-control"
                    readOnly
                />
                <button className="copy-btn" onClick={copyUrl}>
                    <img src={copyIcon} alt="Copy Icon" />
                </button>
            </div>
        </div>
    );
};
