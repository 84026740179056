import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getUserList, setUserListAction } from '../redux/actions/user.action';
import { postApi } from '../services/common';
;

function * getUserListSaga ({ payload }) {
	try {
		const data = yield call(postApi, payload, '/GetUserDetails');
	yield put(setUserListAction({ data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function * userSaga () {
	yield all([
		takeEvery(getUserList, getUserListSaga)
	]);
};

export default userSaga;
