import React from 'react';
import { Checkbox } from '../../../components/common/Checkbox';
import { MainTitle } from '../../../components/common/MainTitle';

export const Transaction = ({ reviewData }) => {
  const allData = reviewData?.Ttype?.split(',').map(item => item.trim()) || [];
return (
    <div className='mb-5 pb-2.5 border-b border-solid border-[#D9D9D9]'>
      <MainTitle title="DDPI OPT-IN" marginBottom="mb-4" />
      <div className="">
        <Checkbox
          labelText="All Transactions"
          labelSize="18px"
          labelWeight="500"
          labelFamily="font-poppinsMedium"
          marginBottom="mb-4"
          isChecked={(allData?.length == 4 || reviewData?.Ttype=='All Transactions'|| reviewData?.Ttype=='All transactions' )? true : false}
          isDisable={true}
        />
        <div className="ml-8 flex flex-col">
          <Checkbox
            labelText="Settlement Transactions"
            labelSize="16px"
            labelWeight="400"
            labelFamily="font-poppinsRegular"
            marginBottom="mb-4"
            isChecked={(allData?.includes('Settlement Transactions') || (allData?.includes('All Transactions') ||(allData?.includes('All transactions'))))}
            isDisable={true}
          />
          <Checkbox
            labelText="Margin Pledge Transactions"
            labelSize="16px"
            labelWeight="400"
            labelFamily="font-poppinsRegular"
            marginBottom="mb-4"
            isChecked={(allData.includes('Margin Pledge Transactions') || (allData?.includes('All Transactions')) || (allData?.includes('All transactions')))}
            isDisable={true}
          />
          <Checkbox
            labelText="Mutual Fund Transactions"
            labelSize="16px"
            labelWeight="400"
            labelFamily="font-poppinsRegular"
            marginBottom="mb-4"
            isChecked={(allData.includes('Mutual Fund Transactions') || (allData?.includes('All Transactions')) || (allData?.includes('All transactions')))}
            isDisable={true}
          />
          <Checkbox
            labelText="Tendering Shares"
            labelSize="16px"
            labelWeight="400"
            labelFamily="font-poppinsRegular"
            marginBottom="mb-4"
            isChecked={allData.includes('Tendering Shares') ||(allData?.includes('All Transactions') || (allData?.includes('All transactions')))}
            isDisable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Transaction;



// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Checkbox } from '../../../components/common/Checkbox';
// import { MainTitle } from '../../../components/common/MainTitle';

// export const Transaction = ({ reviewData }) => {
//   const kycStep6 = useSelector(state => state?.kycStep6 || {});
//   // const responseArray = kycStep6.transactionData.value;

//   return (
//     <>
//       <MainTitle title="Transactions Type" marginBottom="mb-4" />
//       <div className="">
//         <Checkbox
//           labelText="All Transactions"
//           labelSize="18px"
//           labelWeight="500"
//           labelFamily="font-poppinsMedium"
//           marginBottom="mb-4"
//           // isChecked={kycStep6.transactionData?.allTransactions || false}
//           isChecked={true}
//           isDisable={true}
//         />
//         <div className="ml-8 flex flex-col">
//           <Checkbox
//             labelText="Settlement Transactions"
//             labelSize="16px"
//             labelWeight="400"
//             labelFamily="font-poppinsRegular"
//             marginBottom="mb-4"
//             // isChecked={responseArray.includes('Settlement Transactions')}
//             isChecked={true}
//             isDisable={true}
//           />
//           <Checkbox
//             labelText="Margin Pledge Transactions"
//             labelSize="16px"
//             labelWeight="400"
//             labelFamily="font-poppinsRegular"
//             marginBottom="mb-4"
//             // isChecked={responseArray.includes('Margin Pledge Transactions')}
//             isChecked={true}
//             isDisable={true}
//           />
//           <Checkbox
//             labelText="Mutual Fund Transactions"
//             labelSize="16px"
//             labelWeight="400"
//             labelFamily="font-poppinsRegular"
//             marginBottom="mb-4"
//             // isChecked={responseArray.includes('Mutual Fund Transactions')}
//             isChecked={true}
//             isDisable={true}
//           />
//           <Checkbox
//             labelText="Tendering Shares"
//             labelSize="16px"
//             labelWeight="400"
//             labelFamily="font-poppinsRegular"
//             marginBottom="mb-4"
//             // isChecked={responseArray.includes('Tendering Shares')}
//             isChecked={true}
//             isDisable={true}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default Transaction;

