import { svgAssets } from '../../assets/asset';

export const VerifyBtn = ({ height, isDisable, isVerify, verifyBtn }) => {
	return (
		<button
			className={`px-5 h-[${height || '47px'}] flex items-center rounded-[10px] leading-6 text-white
				${isDisable ? 'cursor-default bg-[#dfe1e1]' : isVerify ? 'bg-[#007813] cursor-default' : 'bg-black'}
			`}
			onClick={() => !isDisable && verifyBtn()}
			style={isVerify ? {background: '#007813'} : isDisable ? {background: '#dedede'}  : {background: '#000'}}
			disabled={isDisable}
		>
			<img className="mr-2" alt="back_icon" src={svgAssets.kyc.verify} />
			<span>{ isVerify ? 'Verified' : 'Verify'}</span>
		</button>
	);
};
