import { useEffect, useState } from 'react';
import { MainTitle } from '../../../components/common/MainTitle';
import { SingleDetail } from '../SingleDetail';
import ClientProfile from './ClientProfile';
import Skeleton from 'react-loading-skeleton'

export const ClientVerifyDetail = ({ reviewData, selectedData,reviewData2, clientPhoto, clientSignature, reviewFilesData, rejectReasonsData, isChecked, handleCheckboxChange, esignStatus, profileStatus, panStatus, signStatus, updateStatus, pennyData }) => {
	const FName = reviewData?.FName !== null ?reviewData?.FName:''
	const LName = reviewData?.LName !== null ? reviewData?.LName : ''
	const MName = reviewData?.MName !== null ? reviewData?.MName : ''

	const name = FName + ' ' + MName + ' ' + LName
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);

	}, []);
	const handleCallback = (status, type) => {
		updateStatus(status, type)
	}

	return (
		<>
			<div className='flex justify-between'>
				<div className="mb-5">
					<MainTitle title="Client Details" />
				</div>
				{/* {
					rejectReasonsData.length == 0 &&
					<div className="checkbox-wrapper font-poppinsMedium text-[16px]">
					<input
						type="checkbox"
						className="h-[20px] w-[40px]"
						value={isChecked}
						onChange={(e) => handleCheckboxChange({ value: e.target.checked })}
					/>
					Approve All
				</div>
				} */}
				

			</div>
			<div className="grid md:grid-cols-12  md:gap-10">
				<div className="md:col-span-8">
					{ 
						isLoading && reviewData ?
							<Skeleton count={9} height={50} /> 
							:
							<>
								<SingleDetail label="Name As On PAN" value={name} isVerify={true} reviewData={reviewData} status={panStatus} selectedData={selectedData} callback={handleCallback}/>
								<SingleDetail label="Bank Verification Status" value={reviewData2?.penny_drop_status == 1 ? 'Verified' : 'Not verified'} />
								<SingleDetail label="Source" value={reviewData2?.kra_status == 1 ? 'KRA' : 'DIGIO'} />
								<SingleDetail label="RM Name" value={reviewData?.UserName} />
								<SingleDetail label="RM Employee Code" value={reviewData?.UserEmpId} />
								<SingleDetail label="RM Email" value={reviewData?.UserEmail} />
								<SingleDetail label="RM Mobile" value={reviewData?.UserMobile} />
								<SingleDetail label="LDCode" value={reviewData?.LDCode} />
								<SingleDetail label="Latitude/Longitude" value={(reviewData2?.latitude == (null) ?'-' :reviewData2?.latitude) +','+(reviewData2?.longitude == null?'-':reviewData2?.longitude)} />

							</>
					}

				</div>
				{
					isLoading ?
						<>
							<div className='mb-[15px] max-h-[600px]  md:col-span-4' style={{ width: '100%' }}>
								<Skeleton height={330} style={{ width: '100%' }} />
								<Skeleton height={150} style={{ width: '100%' }} />
							</div>
						</>
						:
						<ClientProfile
							reviewData={reviewData || []}
							reviewFilesData={reviewFilesData || []}
							clientPhoto={clientPhoto}
							clientSignature={clientSignature}
							isVerify={true}
							profileStatus={profileStatus}
							signStatus={signStatus}
							selectedData={selectedData}
							callback={handleCallback}
						/>
				}

			</div>
		</>
	);
};
