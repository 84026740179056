import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { Login } from "./pages/login/login";
import { Navbar } from "./containers/navbar/Navbar";
import { Sidebar } from "./containers/sidebar/Sidebar";
import { Dashboard } from "./pages/dashboard";
import { Kyc } from "./pages/kyc/kyc";
import { Kyc2 } from "./pages/kyc/kyc2";
import { ESignReport } from "./pages/eSignReport";
import { AccountList } from "./pages/accountList";
import { AllApplication } from "./pages/application/all";
import { PendingApplication } from "./pages/application/pending";
import { NewApplication } from "./pages/application/newApplication";
import { RejectedApplication } from "./pages/application/rejected";
import { ResubmittedApplication } from "./pages/application/resubmitted";
import { VerifiedApplication } from "./pages/application/verified";
import { UserList } from "./pages/userList/userList";
import { AssignTask } from "./pages/userList/assignTask";
import { UserDetail } from "./pages/userDetail";
import { ReassignmentReport } from "./pages/reassignmentReport";
import { NoMatchRoute } from "./components/routes/NoMatchRoute";
import { SalesReport } from "./pages/reports/salesReport";
import { MonthlyReport } from "./pages/reports/monthlyReport";
import { VerifierReport } from "./pages/reports/verifierReport";
import { AgeingReport } from "./pages/reports/ageingReport";
import { BrokeragePlan } from "./pages/brokerageMaster/brokeragePlan";
import { MapBrokerage } from "./pages/brokerageMaster/mapBrokerage";
import { ReviewApplication } from "./pages/kyc/reviewApplication";
import { ViewRejectedApplication } from "./pages/kyc/viewRejectedApplication";
import { generateTokenAction } from "./redux/actions/auth.action";
import { ProtectedRoute } from "./components/routes/ProtectedRoute";
import { ForgotPassword } from "./pages/login/forgotPassword";
import { ResetPassword } from "./pages/login/resetPassoword";
import { IncompleteApplication } from "./pages/application/incomplete";
import { ClientEsign } from "./pages/clientEsign/clientEsign";
import { Loader } from "./components/loader/Loader";
import SubmitDocuments from "./containers/d-kyc/dkyc/submitdocuments";
import { ViewApplication } from "./pages/application/viewApplication";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RejectDoc } from "./containers/kyc/rejectDoc";
import { Vfdashboard } from "./containers/kyc/ModificatinVF/Vfdashboard";
import { Downloadform } from "./containers/kyc/ModificatinVF/Downloadform";
import { Total } from "./containers/kyc/ModificatinVF/Total";
import { ModificationView } from "./containers/kyc/ModificatinVF/ModificationView";
import { Other } from "./sidebarUrl/Other";
import { Segment } from "./sidebarUrl/Segment";
import { NomineeUpdate } from "./sidebarUrl/NomineeUpdate";
import { Reactivation } from "./sidebarUrl/Reactivation";
import { BonanzaReactivation } from "./sidebarUrl/BonanzaReactivation";
import { Ddpi } from "./sidebarUrl/Ddpi";
import { FAQ } from "./containers/kyc/ModificatinVF/FAQ";
import { RefLink } from "./containers/kyc/ModificatinVF/RefLink";
import { SegmentUrl } from "./sidebarUrl/SegmentUrl";
import { Username } from "./pages/login/Username";


export const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.accessToken);

  useEffect(() => {
    if (accessToken === "") {
      dispatch(generateTokenAction());
    }
  }, [accessToken]);

  const isResetPasswordRoute = location.pathname.includes('reset-password');

  return (
    <div className="App h-full w-full">
      <ToastContainer autoClose={1000} />
      <Loader />
      {location.pathname === "/login" ||
        location.pathname === "/forgot-password" ||
        isResetPasswordRoute ||
        location.pathname.split("/")[1] === "dkyc" ||
        location.pathname === "/dkyc-step1" ||
        location.pathname === "/dkyc-step2" ||
        location.pathname === "/dkyc-step3" ||
        location.pathname === "/dkyc-step4" ||
        location.pathname.split("/")[1] === "client-esign" ||
        location.pathname.startsWith("/login/") ? (
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password/:username/:key" element={<ResetPassword />}></Route>
          <Route path="/dkyc" element={<SubmitDocuments />}></Route>
          <Route path="/dkyc/:slug" element={<SubmitDocuments />}></Route>
          <Route path="/client-esign/:clientId" element={<ClientEsign />}></Route>
          <Route path="/login/:rm/:page?" element={<Username />}></Route>
          <Route path="*" element={<Login />}></Route>
        </Routes>
      ) : (
        <div>
          <Navbar />
          <div className="flex h-full">
            <Sidebar />
            <div className="w-full h-[calc(100vh-105px)] md:pl-5 md:pr-[30px] pl-5 pr-5 pb-5 lg:ml-[300px] mt-[105px] flex flex-col grow">
              <Routes>
                <Route
                  index
                  path="/"
                  element={<ProtectedRoute component={<Dashboard />} />}
                ></Route>
                <Route
                  index
                  path="/rejectapp"
                  element={<ProtectedRoute component={<RejectDoc />} />}
                ></Route>
                <Route
                  index
                  path="/faq"
                  element={<ProtectedRoute component={<FAQ />} />}
                ></Route>
                <Route
                  index
                  path="/link"
                  element={<ProtectedRoute component={<RefLink />} />}
                ></Route>
                <Route path="/kyc">
                  <Route
                    index
                    element={<ProtectedRoute component={<Kyc />} />}
                  ></Route>
                  <Route
                    path="review"
                    element={
                      <ProtectedRoute component={<ReviewApplication />} />
                    }
                  ></Route>
                </Route>
                <Route path="/kyc1">
                  <Route
                    index
                    element={<ProtectedRoute component={<Kyc2 />} />}
                  ></Route>
                  <Route
                    path="review"
                    element={
                      <ProtectedRoute component={<ReviewApplication />} />
                    }
                  ></Route>
                </Route>
                <Route path="/application">
                  <Route
                    index
                    element={<ProtectedRoute component={<AllApplication />} />}
                  ></Route>
                  {/* <Route
                    path="all"
                    element={<ProtectedRoute component={<AllApplication />} />}
                  ></Route> */}
                  <Route
                    path="pending"
                    element={
                      <ProtectedRoute component={<PendingApplication />} />
                    }
                  ></Route>
                  <Route
                    path="rejected"
                    element={
                      <ProtectedRoute component={<RejectedApplication />} />
                    }
                  ></Route>
                  <Route
                    path="verified"
                    element={
                      <ProtectedRoute component={<VerifiedApplication />} />
                    }
                  ></Route>
                  <Route
                    path="incomplete"
                    element={
                      <ProtectedRoute component={<IncompleteApplication />} />
                    }
                  ></Route>
                  <Route
                    path="resubmitted"
                    element={
                      <ProtectedRoute component={<ResubmittedApplication />} />
                    }
                  >
                  </Route>
                  <Route
                    path="resubmitted/new-application"
                    element={<ProtectedRoute component={<NewApplication />} />}
                  ></Route>
                  <Route
                    path="new-application"
                    element={<ProtectedRoute component={<NewApplication />} />}
                  ></Route>
                  <Route
                    path="view-application"
                    element={<ProtectedRoute component={<ViewApplication />} />}
                  ></Route>
                  <Route
                    path="rejected-application"
                    element={
                      <ProtectedRoute component={<ViewRejectedApplication />} />
                    }
                  ></Route>
                </Route>
                <Route
                  path="/e-sign-report"
                  element={<ProtectedRoute component={<ESignReport />} />}
                ></Route>
                <Route
                  path="/update"
                  element={<ProtectedRoute component={<AccountList />} />}
                ></Route>
                <Route
                  path="/user-list"
                  element={<ProtectedRoute component={<UserList />} />}
                ></Route>
                <Route
                  path="/user-list/assign-task"
                  element={<ProtectedRoute component={<AssignTask />} />}
                ></Route>
                <Route
                  path="/user-detail"
                  element={<ProtectedRoute component={<UserDetail />} />}
                ></Route>
                <Route
                  path="/reassignment-report"
                  element={
                    <ProtectedRoute component={<ReassignmentReport />} />
                  }
                ></Route>
                <Route path="/reports">
                  <Route
                    index
                    element={<ProtectedRoute component={<ESignReport />} />}
                  ></Route>
                  <Route
                    path="e-sign-report"
                    element={<ProtectedRoute component={<ESignReport />} />}
                  ></Route>
                  <Route
                    path="sales-report"
                    element={<ProtectedRoute component={<SalesReport />} />}
                  ></Route>
                  <Route
                    path="monthly-report"
                    element={<ProtectedRoute component={<MonthlyReport />} />}
                  ></Route>
                  <Route
                    path="verifier-report"
                    element={<ProtectedRoute component={<VerifierReport />} />}
                  ></Route>
                  <Route
                    path="ageing-report"
                    element={<ProtectedRoute component={<AgeingReport />} />}
                  ></Route>
                </Route>
                <Route path="/brokerage-master">
                  <Route
                    index
                    element={<ProtectedRoute component={<BrokeragePlan />} />
                    }
                  ></Route>
                  <Route
                    path="brokerage-plans"
                    element={<ProtectedRoute component={<BrokeragePlan />} />}
                  ></Route>
                  <Route
                    path="map-brokerage"
                    element={<ProtectedRoute component={<MapBrokerage />} />}
                  ></Route>
                </Route>
                <Route
                  path="modification"
                  element={<ProtectedRoute component={<Vfdashboard />} />}
                ></Route>
                <Route
                  path="view"
                  element={<ProtectedRoute component={<ModificationView />} />}
                ></Route>
                <Route
                  path="download-forms"
                  element={<ProtectedRoute component={<Downloadform />} />}
                ></Route>
                <Route
                  path="total"
                  element={<ProtectedRoute component={<Total />} />}
                ></Route>
                <Route path="/other">
                  <Route
                    index
                    element={<ProtectedRoute component={<Other />} />}

                  ></Route>
                  <Route
                    path="other2"
                    element={<ProtectedRoute component={<Segment />} />}

                  ></Route>
                  <Route
                    path="nominee_udateion"
                    element={<ProtectedRoute component={<NomineeUpdate />} />}

                  ></Route>
                  <Route
                    path="bigul"
                    element={<ProtectedRoute component={<Reactivation />} />}

                  ></Route>
                  <Route
                    path="bonanza"
                    element={<ProtectedRoute component={<BonanzaReactivation />} />}

                  ></Route>
                  <Route
                    path="Ddpi"
                    element={<ProtectedRoute component={<Ddpi />} />}

                  ></Route>
                  <Route
                    path="seg"
                    element={<ProtectedRoute component={<SegmentUrl />} />}

                  ></Route>
                </Route>
                <Route
                  path="*"
                  element={<ProtectedRoute component={<Login /> } />}
                ></Route>
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


