import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainTitle } from '../../../components/common/MainTitle';
import { UploadSelfie } from "./UploadSelfie";
import { UploadBankProof } from "../step5/UploadBankProof";
import { UploadIncomeProof } from "./UploadIncomeProof";
import { UploadSignature } from "./UploadSignature";
import { BackBtn } from '../../../components/buttons/BackBtn';
import { ContinueBtn } from '../../../components/buttons/ContinueBtn';
import {
    callSendDkycLinkAction, callCheckDocumentIsUploadedAction
} from '../../../redux/actions/kyc/kyc.action';
import { getClientFilesAction } from '../../../redux/actions/kyc/review.action';
import { DOMAIN } from '../../../config/config';
import { svgAssets } from '../../../assets/asset';
import { finalSubmitAction, } from '../../../redux/actions/kyc/step8.action';
import { uploadFile } from '../../../redux/actions/kyc/step7.action';
import {  postApi } from '../../../services/common';
import { toast } from 'react-toastify';

export const KycFilesSteps = ({ step, index, backBtn, reviewBtn, selectedStepData, rejectedFieldsData }) => {

    const dispatch = useDispatch();
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const kycData = useSelector(state => state?.kyc || {});
    const kycStep1 = useSelector(state => state?.kycStep1 || {});
    const kycStep2 = useSelector(state => state?.kycStep2 || {});
    const kycStep4 = useSelector(state => state?.kycStep4 || {});
    const kycStep6 = useSelector(state => state?.kycStep6 || {});
    const panData = useSelector(state => state?.kycStep2?.data || {});
    const [isSendLink, setIsSendLink] = useState(false);
    const [isFinalModalOpen, setIsFinalModalOpen] = useState(false);
    const [isRefreshForIncomeProof, setIsRefreshForIncomeProof] = useState(false);
    const [linktoken, setToken] = useState('');
    const isCommodity = kycStep6.segments?.commodity?.isSelected;
    const isFutureAndOption = kycStep6.segments?.FutureAndOption?.isSelected;
    const [kra, setKra] = useState(false);
    
    const [krnJourney, setKraJourney] = useState([]);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const clientKycStatus = useSelector(state => state.kyc?.clientKycStatus || {});
    const reviewFilesData = useSelector(
        (state) => state?.reviewData.filesData || {}
    );

    const filteredItems = reviewFilesData?.filter((item) => item?.DocumentType === 'Other');
    const hasItems = filteredItems.length > 0;

    useEffect(() => {
        fetchData()
    }, [])

 const generateToken = () => {
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
        const token = Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
        return token
    }
    const handleSendLink = () => {
        sessionStorage.setItem('userfile', true)
        let ttoken = generateToken()
        setToken(ttoken)
        dispatch(callSendDkycLinkAction(
            {

                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "link": `${DOMAIN}/dkyc`,
                "token": ttoken
            }
        ));
        dispatch(uploadFile(true))
    }

    const fetchData = async () => {
        setKra(false)
        try {
            const payload = {
                mobile: kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                journey: "DKYC",

            }
            const response = await postApi(payload, '/getKycJourney');
            if (response?.data.success) {
        setKra(true)
               setKraJourney(response?.data?.data[0])
                }
            else{
                setTimeout(() => {
                    backBtn({ step, index });
                }, 500);
           toast('something went wrong')

            }
        }
        catch (error) {
            setTimeout(() => {
                backBtn({ step, index });
            }, 500);
           toast('something went wrong')
        }
    };

   
    useEffect(() => {
        setIsSendLink(false);
        if (linktoken !== '') {
            setIsSendLink(true);
        }

    }, [linktoken])

   const UploadAlldoc=()=>{
        dispatch(getClientFilesAction({
            PanNo: kycStep2.panInput.value || clientKycStatus?.data?.pan,
            CreatedBy: 88
        }));
        setIsRefreshForIncomeProof(true);
        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Client Signature"
            }
        ));
        setIsRefreshForIncomeProof(false);

        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Client Photo"
            }
        ));

        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Income Proof"
            }
        ));

        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": clientKycStatus?.data?.pan || kycStep2.panInput.value,
                "document_type": "Bank Proof"
            }
        ));
    }

    // useEffect(() => {
    //     const fetchData = () => {
    //         setTimeout(() => {
    //             dispatch(
    //                 getClientFilesAction({
    //                     PanNo: clientKycStatus?.data?.pan || kycStep2?.panInput?.value,
    //                     CreatedBy: 99
    //                 })
    //             );
    //         }, 10000);
    //     };
    //     fetchData();
    //     const intervalId = setInterval(fetchData, 10000);


    //     return () => clearInterval(intervalId);
    // }, []);

    useEffect(() => {
        if (clientKycStatus?.data?.pan && selectedStepData?.mobile)
            dispatch(getClientFilesAction({
                PanNo: kycStep2?.panInput?.value || clientKycStatus?.data?.pan,
                CreatedBy: 99
            }));
        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Income Proof"
            }
        ));
        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Bank Proof"
            }
        ));
        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Client Photo"
            }
        ));
        dispatch(callCheckDocumentIsUploadedAction(
            {
                "mobile": kycStep1?.mobileInput?.value || selectedStepData?.mobile,
                "pan": kycStep2.panInput.value || clientKycStatus?.data?.pan,
                "document_type": "Client Signature"
            }
        ));

    }, [clientKycStatus?.data?.pan && selectedStepData?.mobile]);

    const handleContinueBtn = () => {
       setIsFinalModalOpen(true);
         dispatch(finalSubmitAction({ mobile: kycStep1.mobileInput.value }));
    };

    const handleCloseModal = () => {
        setIsFinalModalOpen(false);
    }
    const isRefreshIncomeProof = () => {
        setIsRefreshForIncomeProof(!isRefreshForIncomeProof);
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(DOMAIN + '/dkyc/' + linktoken);
        setIsLinkCopied(true);
    };

    return (
       <>
       {
       kra == true ?
        <div className="files-upload-main">
            <div className='flex items-center justify-between'>
                <MainTitle title="Upload Selfie, E-sign And Supporting Docs" />
                {isSendLink ?
                    <>
                        <div
                            className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813] cursor-pointer `} >
                            <img
                                src={svgAssets.kyc.verify}
                                className="mr-1.5"
                            />
                            <span>
                                Link sent successfully
                            </span>
                        </div>
                        <button
                            className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                            onClick={handleSendLink}
                        >
                            <img
                                src={svgAssets.kyc.send}
                                className="mr-1.5"
                            />
                            <span>
                                Resend Link
                            </span>
                        </button>
                    </> : <button
                        className={isSendLink ? `h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813] cursor-pointer ` : `h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                        onClick={handleSendLink}
                    >
                        {isSendLink ?
                            <img
                                src={svgAssets.kyc.verify}
                                className="mr-1.5"
                            />
                            :
                            <img
                                src={svgAssets.kyc.send}
                                className="mr-1.5"
                            />
                        }
                        <span>
                            {isSendLink ? 'Link send successfully' : 'Send Link'}
                        </span>
                    </button>}
                {
                    isSendLink &&
                    <button
                        className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] relative bg-black cursor-pointer `}
                        onClick={() => { handleCopyLink() }}
                    >
                        {isLinkCopied ? (
                            <span>Copied</span>
                        ) : (
                            <>
                                
                                <span>Copy Link</span>
                            </>
                        )}
                    </button>
                }

            </div>
            {
                krnJourney?.Derivative !== null || krnJourney?.Commodity !== null || krnJourney?.income_id_send > 3 ?
                    <UploadIncomeProof isDocumentUploaded={kycData?.isIncomeProofUploaded || {}} rejectedFieldsData={rejectedFieldsData} isRefreshIncomeProof={isRefreshIncomeProof} MobileNo={krnJourney?.mobile} />
                    :
                    null
            }

            {
                krnJourney?.penny_drop_status == 2 &&
                <UploadBankProof isDocumentUploaded={kycData?.isBankProofUploaded || {}} rejectedFieldsData={rejectedFieldsData} kycStep2={kycStep2} selectedStepData={selectedStepData} />
            }
            <UploadSelfie linktoken={linktoken} isDocumentUploaded={kycData?.isSelfieUploaded || {}} rejectedFieldsData={rejectedFieldsData} isRefreshIncomeProof={isRefreshIncomeProof} />
            <UploadSignature
                isDocumentUploaded={kycData?.isSignatureUploaded || {}}
                isFinalModalOpen={isFinalModalOpen}
                handleCloseModal={handleCloseModal}
                reviewBtn={reviewBtn}
                linktoken={linktoken}
                rejectedFieldsData={rejectedFieldsData} isRefreshIncomeProof={isRefreshIncomeProof} />
            <div className="flex item justify-between mb-10">
                <BackBtn handleBackBtn={() => backBtn({ step, index })} />
                <div onClick={()=>{UploadAlldoc()}}>
                <p style={{backgroundColor:'black',padding:'10px',color:'white',borderRadius:'10px',cursor: 'pointer',}}>Check status</p>
                </div>
                {
                    (kycData?.isSignatureUploaded.success == true) && (kycData?.isSelfieUploaded?.success == true) && (hasItems == true)
                        ?
                        <ContinueBtn handleContinueBtn={handleContinueBtn} />
                        :
                        <ContinueBtn isDisable={true} />
                }
            </div>
        </div>
        :
        <p>loading</p>
       }
       </>
    );
};


