import { svgAssets } from '../assets/asset';

export const messageTypeParameter = {
	success: {
		icon: '',
		color: '#007813'
	},
	error: {
		icon: svgAssets.kyc.invalidInput,
		color: '#FF4343'
	}
};
