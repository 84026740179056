import react, { useEffect, useState } from 'react';
import '../modal.css';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from '../../../services/common'
import { svgAssets } from '../../../assets/asset';
import { BranchListDropdown } from '../../dropdown/branchListDropdown';
import { changeStep5ErrorAction, fetchBankAccountAction, getIfscCodeAction, setIfscCodeAction } from '../../../redux/actions/kyc/step5.action';
import { SelectBankDropdown } from '../../dropdown/SelectBankDropdown';

export const SearchIfcModal = ({ setValidIfscCode ,isModalOpen, handleKycModel, isClearCode, isClearData, handleGetBankNameList }) => {
	const dispatch = useDispatch();
	const kycStep5 = useSelector(state => state?.kycStep5 || {});
	const [isSelectedBankName, setIsSelectedBankName] = useState('');
	const [isBankName, setIsBankName] = useState('');
	const [isBranchName, setIsBranchName] = useState('');
	const [branchIdVal, setBranchId] = useState('');
	const [isSearchCode, setIsSearchCode] = useState('');
	const [bankNameList, setBankNameList] = useState(kycStep5?.bankNamesList);
	const [branchNameList, setBranchNameList] = useState([]);
	const [isLoad, setLoad] = useState(false);
	const [isClear, setIsClearData] = useState(false);
	
	useEffect(() => {
		setIsSearchCode('');
	}, [isClearCode]);
	useEffect(() => {
		const interval = setInterval(() => {
			setBankNameList(kycStep5.bankNamesList);
			
			if (bankNameList) {
				
			  clearInterval(interval); // Stop the interval
			}
		  }, 1000); // Adjust the interval duration as needed (in milliseconds)
	});

	

	useEffect(() => {
		setIsSearchCode('')
		setBranchNameList([])
	}, [isClearData])
	
	

	const handleInput1 = async ({ type, value }) => {
		setIsSelectedBankName(value);
		setBranchNameList([]);
		setLoad(true);
	};
	const handleChangeBankName = (val) => {
		setIsBankName(val)
	}
	const handleChangeBranch = (val) => {
		setIsBranchName(val)
	}

	const handleInput2 = (branchId) => {
		setBranchId(branchId)
		
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeStep5ErrorAction({ type, isError, errorText }));
	};

	const handleSearchBtn = async () => {
		const response = await fetch(`https://payment.bigul.app/api/banks/${branchIdVal}/ifsc`);
		const jsonData = await response.json();
		setIsSearchCode(jsonData?.ifsc_code);
	}

	const handleConfirmBtn = () => {
		handleSearchBtn()
		dispatch(setIfscCodeAction(isSearchCode));
		dispatch(getIfscCodeAction({ bank_code: branchIdVal }));
		handleKycModel(false)
		setTimeout(() => {
			dispatch(fetchBankAccountAction({
				BankVal: isSearchCode || '',
			}));

			setIsSelectedBankName('');
			setIsBankName('');
			setBranchNameList([]);
			setIsSearchCode('');
			setBranchId('');
		}, 1000);
		setIsClearData(!isClear);
		setValidIfscCode()
	}

	return (
		<>
		 
		<div>
			<Modal
				title="Search IFSC"
				open={isModalOpen}
				onCancel={() => handleKycModel(false)}
				centered={true}
				width={860}
				closeIcon={false}
				footer={null}
				className="w-full p-[40px]"
			>
				{bankNameList.length > 0 ? (
				<div className="">
					<div className="grid md:grid-cols-2 gap-7 mb-[30px] mt-6">
						<SelectBankDropdown
							label="Select bank"
							placeholder="Please select bank"
							inputType="searched_ifsc"
							options={bankNameList}
							handleSelect={handleInput1}
							handleChange={handleChangeBankName}
							isClearData={isClear}
						/>

						<BranchListDropdown
							label="Select branch"
							placeholder="Please select branch"
							inputType="searched_ifsc"
							selectedValue={kycStep5?.searchBankData?.branch_name || ''}
							options={branchNameList || []}
							isSelectedBankName={isSelectedBankName}
							handleSelect={handleInput2}
							inputMessage={kycStep5?.searchBankData?.errorText || ''}
							inputMessageType={kycStep5?.searchBankData?.errorText && 'error'}
							isLoad={isLoad}
							isDisabled={!isBankName || !isSelectedBankName ? true : false}
							handleChange={handleChangeBankName}
							isClearData={isClear}
							isBankNameVal={isBankName}
						/>
					</div>

					{
						isSearchCode &&
						<div className="bg-[#007813] w-[40%] p-2 rounded mx-auto my-5 ">
							<p className="text-[#fff] font-poppinsMedium font-medium text-center">
								{isSearchCode}
							</p>
						</div>
					}

					<div className="w-full flex justify-center mb-2 pt-5">

						{isSearchCode && isSelectedBankName && branchIdVal?
							<button
								className="px-5 py-3 flex items-center rounded-[10px] leading-6 text-white bg-black"
								onClick={handleConfirmBtn}
							>
								<img className="mr-2 text-xl leading-[30px] font-semibold" alt="back_icon" src={svgAssets.kyc.verify} />
								<span>Confirm</span>
							</button>
							:

							<button
								className="px-5 py-3 flex items-center rounded-[10px] leading-6 text-white bg-black"
								onClick={handleSearchBtn}
							>
								<img className="mr-2 text-xl leading-[30px] font-semibold" alt="back_icon" src={svgAssets.kyc.verify} />
								<span>Search</span>
							</button>
						}

					</div>
				</div>
				)
				: (<p>loading..</p>)}
			</Modal>
		</div>
		
		</>
	);
};
