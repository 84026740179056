import React, { useState } from 'react';

export const Hadding4 = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const accordionData = [
        { title: '1. What documents can I provide as income proof? ', content: 'You can provide Income Tax Returns (ITR), recent salary slips (last three months), bank statements showing regular deposits or income, Form 16, or a holding statement worth a minimum of 10K.' },
        { title: '2. Why do I need to provide income proof?', content: 'Providing income proof is necessary for financial disclosure and to verify that you meet the required income levels for certain trading or investing activities.' },
    ];

    return (
        <>
        <p style={{fontWeight:'bold',marginLeft:'10%',marginBottom:'20px',marginTop:'20px'}}>FAQs for Invalid Income Proof</p>
        <div style={styles.accordionContainer}>
           {accordionData.map((item, index) => (
                <div key={index} style={styles.accordionItem}>
                    <div
                        style={styles.accordionHeader}
                        onClick={() => handleToggle(index)}
                    >
                        <h3 style={{fontWeight:'bold'}}>{item.title}</h3>
                    </div>
                    <div
                        style={{
                            ...styles.accordionContent,
                            display: index === activeIndex ? 'block' : 'none'
                        }}
                    >
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

const styles = {
    accordionContainer: {
        width: '80%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        
        
    },
    accordionItem: {
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px'
    },
    accordionHeader: {
        padding: '15px',
        // backgroundColor: '#007BFF',
        // color: '#fff',
        cursor: 'pointer'
    },
    accordionContent: {
        padding: '10px',
        borderTop: '1px solid #ddd',
        backgroundColor: '#fff'
    }
};





