// import React, { useEffect } from 'react';

// export const BonanzaReactivation = () => {
//     useEffect(() => {
//         const url = 'https://bigul.co/crs-bonanza'; 

//         const openNewWindow = () => {
//             window.open(url, '_blank');
//         };

//         openNewWindow();
//     }, []); 

//     return (
//         <div>
//             <p>Bonanza Reactivation</p>
//         </div>
//     );
// };




import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const BonanzaReactivation = () => {
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
   
    useEffect(() => {
        
        const url = 'https://bigul.co/crs-bonanza'; 
        const url2 = 'https://bigul.co/crs-dashboard-bonanza'

        const openNewWindow = () => {
            window.open(userDetail?.role_id == 6 ?url2: url, '_blank');
        };

        openNewWindow();
    }, [userDetail?. role_id]); 

    return (
        <div>
           
            <p>{"Bigul Reactivation"}</p>
        </div>
    );
};
