import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep2ErrorAction, changeStep2Input, uploadBankProof, clearBankProof, setIncomeProofList, uploadSalarySlip } from '../../actions/dkyc/step2.action';

const mutableState = {
    incomeProof: {
        value: '',
        isError: false,
        errorText: '',
        status: 'edit',
        selectedDocument: [],
        data: [],
        isIncomeProof: false

    },
    declarationInfoData: {},
    status: '',
};

const initState = {
    ...mutableState
};

export const dkycStep2Reducer = (state = initState, action) => {
    const { type, value, isError, errorText } = action?.payload || {};
    const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
    const salarySlips = action?.payload?.['DocumentImages[]']

    switch (action.type) {
        case clearKyc:
            return {
                ...mutableState
            };
        case changeStep2Input:
            return {
                ...state,
                [type]: {
                    ...state[type],
                    value,
                    isError: false,
                    errorText: ''
                }
            };
        case changeStep2ErrorAction:
            return {
                ...state,
                [type]: {
                    ...state[type],
                    isError,
                    errorText
                }
            };
        case setIncomeProofList:
            return {
                ...state,
                incomeProof: {
                    ...state.incomeProof,
                    data: action.payload.data
                }
            };

        case uploadBankProof:
            return {
                ...state,
                incomeProof: {
                    ...state.incomeProof,
                    selectedDocument: state.incomeProof.value === 'Latest 3 Month Salary Slip'
                        ? [...state.incomeProof.selectedDocument, imageUrl]
                        : [imageUrl],

                    isError: false,
                    errorText: '',
                    isIncomeProof: true
                }
            };
        case clearBankProof:
            return {
                ...state,
                incomeProof: {
                    ...state.incomeProof,
                    value: '',
                    selectedDocument: [],
                    isError: false,
                    errorText: '',
                    isIncomeProof: false
                }
            };
        case uploadSalarySlip:
            return {
                ...state,
                incomeProof: {
                    ...state.incomeProof,
                    selectedDocument: state.incomeProof.value === 'Latest 3 Month Salary Slip'
                        ? [...action.payload.state.incomeProof, salarySlips]
                        : [salarySlips],

                    isError: false,
                    errorText: '',
                    isIncomeProof: true
                }
            };


        default:
            return state;
    }
};
