import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../../components/common/Checkbox';
import { CustomDropdown } from '../../../components/dropdown/CustomDropdown';
import { segmentsValueAction, updateSegmentValueAction } from '../../../redux/actions/kyc/step6.action';

export const Segment = () => {
	const dispatch = useDispatch();
	const {derivative, commodity, mutualFund,mutualFund1 } = useSelector(state => state.kycStep6.segments);

	const handleSegment = ({ isChecked, value }) => {
		
		dispatch(updateSegmentValueAction({ isChecked: !isChecked, value }));
		dispatch(segmentsValueAction({ value: value }));
	};

	return (
		<CustomDropdown
			label="Segments"
			position="relative"
		>
			<div className="w-full px-3.5 py-[25px] mb-6 grid grid-cols-2 gap-x-7 gap-y-4">
				<Checkbox
					labelText="Equity"
					labelSize="16px"
					labelWeight="400"
					labelFamily="font-poppinsRegular"
					marginBottom="mb-4"
					value="equity"
					isChecked={true}
					// handleCheckbox={handleSegment}
				// isChecked={equity?.isSelected ? equity?.isSelected : false}
				// isDisable={equity.isDisable}
				/>
				<Checkbox
					labelText="Commodities"
					labelSize="16px"
					labelWeight="400"
					labelFamily="font-poppinsRegular"
					marginBottom="mb-4"
					value="commodity"
					isChecked={commodity?.isSelected}
					handleCheckbox={handleSegment}
				/>
				<Checkbox
					labelText="Currency & Derivatives"
					labelSize="16px"
					labelWeight="400"
					labelFamily="font-poppinsRegular"
					marginBottom="mb-4"
					value="derivative"
					isChecked={derivative?.isSelected}
					// isDisable={derivative?.isDisable}
					handleCheckbox={handleSegment}
				// isDisable={true}
				/>
				<Checkbox
					labelText="Future and Option"
					labelSize="16px"
					labelWeight="400"
					labelFamily="font-poppinsRegular"
					marginBottom="mb-4"
					value="mutualFund"
					handleCheckbox={handleSegment}
					isChecked={mutualFund?.isSelected}
			
				/>
				<Checkbox
					labelText="Mutual fund"
					labelSize="16px"
					labelWeight="400"
					labelFamily="font-poppinsRegular"
					marginBottom="mb-4"
					value="mutualFund1"
					handleCheckbox={handleSegment}
					isChecked={mutualFund1?.isSelected}
			
				/>
				
			</div>
		</CustomDropdown>
	);
};
