import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svgAssets } from '../../assets/asset';
import { ApplicationWidget } from '../../components/widgets/Applications';
import { Date } from '../../components/widgets/Date';
import { getApplicationStatusAction } from '../../redux/actions/application.action';

export const TeleDashboard = () => {
    const dispatch = useDispatch();
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const { applicationStatus } = useSelector(state => state?.application || []);

    useEffect(() => {
        dispatch(getApplicationStatusAction({ UserId: userDetail.user_id, RoleId: userDetail.role_id }));
    }, []);
    return (
        <>
            <div className="mb-[30px] flex flex-wrap">
                <Date icon={svgAssets.dashboard.todayCalendar} label="Today" />
                <Date icon={svgAssets.dashboard.yesterdayCalendar} label="Yesterday" />
                <Date icon={svgAssets.dashboard.calendar} label="Custom Date" />
            </div>
            <div className="mb-[30px] grid lg:grid-cols-3 lg:gap-x-9 gap-y-9">
            <ApplicationWidget title="All Applications" numberOfApplications={(applicationStatus?.[0]?.TotalRecords) ? applicationStatus?.[0]?.TotalRecords : 0} />
				<ApplicationWidget title="Verified Applications" numberOfApplications={(applicationStatus?.[0]?.KYCDone) ? applicationStatus?.[0]?.KYCDone : 0} />
				<ApplicationWidget title="Rejected Applications" numberOfApplications={(applicationStatus?.[0]?.TotalReject) ? applicationStatus?.[0]?.TotalReject : 0} />
				<ApplicationWidget title="Pending Verification" numberOfApplications={(applicationStatus?.[0]?.TotalReviewPending) ? applicationStatus?.[0]?.TotalReviewPending : 0} />
				<ApplicationWidget title="Resubmitted" numberOfApplications={(applicationStatus?.[0]?.TotalRMRework) ? applicationStatus?.[0]?.TotalRMRework : 0} />
				<ApplicationWidget title="Re-Activation" numberOfApplications={(applicationStatus?.[0]?.TotalRMRework) ? applicationStatus?.[0]?.TotalRMRework : 0} />
            </div>
        </>
    );
};
