export const changeStep4Input = 'CHANGE_STEP4_INPUT';
export const changeStep4Error = 'CHANGE_STEP4_ERROR';
export const getOccuptionDetail = 'GET_OCCUPTION_DETAIL';
export const getAnnualIncome = 'GET_ANNUAL_INCOME';
export const getEducationDetail = 'GET_EDUCATION_DETAIL';
export const getExperienceDetail = 'GET_EXPERIENCE_DETAIL';
export const setOccuptionOption = 'SET_OCCUPATION_OPTION';
export const unsetOccuptionOption = 'UNSET_OCCUPATION_OPTION';
export const saveOccuptionAllInfo = 'SAVE_OCCUPTION_ALL_INFO';
export const unsaveOccuptionAllInfo = 'SAVE_OCCUPTION_ALL_INFO';


export const changeStep4InputAction = (payload) => ({
	type: changeStep4Input,
	payload
});

export const changeStep4ErrorAction = (payload) => ({
	type: changeStep4Error,
	payload
});

export const getOccuptionDetailAction = (callback) => ({
	type: getOccuptionDetail,
	callback
});

export const getAnnualIncomeAction = (callback) => ({
	type: getAnnualIncome,
	callback
});

export const getEducationDetailAction = (callback) => ({
	type: getEducationDetail,
	callback
});

export const getExperienceDetailAction = (callback) => ({
	type: getExperienceDetail,
	callback
});

export const setOccuptionOptionAction = (payload) => ({
	type: setOccuptionOption,
	payload
});

export const unsetOccuptionOptionAction = (payload) => ({
	type: unsetOccuptionOption,
	payload
});

export const saveOccuptionAllInfoAction = (payload) => ({
	type: saveOccuptionAllInfo,
	payload
});