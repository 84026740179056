import { OutsideClickListner } from '../../hooks/OutsideClickListner';
import { useEffect, useRef, useState } from 'react';
import { messageTypeParameter } from '../../utils/input';


export const SelectBankDropdown = ({ icon, label, isImportant, height, autoFocus, isDisabled, placeholder, options, selectedValue, inputType, border, handleSelect,handleChange, isClearData }) => {
    const wrapperRef = useRef(null);
    const initOption = options;
    // eslint-disable-next-line eqeqeq

    const [isSelected, setIsSelected] = useState(false);
    const [isBankName, setBankName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(initOption);

    const filterBankName = (e) => {
        setIsSelected(true)
        handleChange(e.target.value);
        setBankName(e.target.value)
        const filteredData = options && options.filter((bankname) => {
            return (
                bankname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                bankname.toUpperCase().includes(e.target.value.toUpperCase())
            );
        });
        setSelectedOption(filteredData)
    };

    const handleOutsideClick = () => {
        setIsSelected(false);
    };

    const handleOption = ({ value }) => {
        setBankName(value);
        setIsSelected(false);
        handleSelect({ type: inputType, value });
    };

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setSelectedOption(options)
        }, 1000);
    }, [options?.length]);

    useEffect(() => {
        setBankName('')
        setSelectedOption(initOption)
    }, [isClearData])
    

    OutsideClickListner({ ref: wrapperRef, closeBox: handleOutsideClick });

    return (
        <div className="w-full flex flex-col">
            {
                label && (
                    <label className="mb-4 flex items-center leading-6 font-medium font-poppinsMedium">
                        {
                            icon && <img
                                className="max-h-[22px] mr-1.5 object-contain"
                                alt="input_icon"
                                src={icon}
                            />
                        }
                        <span className="mr-2">{label}</span>
                        {isImportant && <span className="text-[#EA0000]">*</span>}
                    </label>
                )
            }
            <div
                ref={wrapperRef}
                className={`w-full h-full rounded-[10px]
			  ${isSelected && 'bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)] relative z-10'}
        ${height || 'h-[47px]'}
		  `}>
                <div
                    // disabled={isDisabled}
                    className={`px-3.5 py-3 flex items-center justify-between cursor-pointer rounded-[10px] bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]
						${border || ''}
						${height || 'h-[47px]'}
					`}
                    onClick={() => setIsSelected(!isSelected)}
                >
                    <input
                        className="w-full pr-5 m-0 outline-none border-none font-poppinsRegular bg-white "
                        type="text"
                        placeholder={placeholder}
                        value={isBankName !== "" ? isBankName : ""}
                        autoFocus={true}
                        onChange={filterBankName}
                    />
                    <svg
                        className={`fill-[#808080] h-6 w-6 transform duration-150 ease-in-out
						${isSelected && 'rotate-180'}
					`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                    </svg>
                </div>
                {/* {
                    inputMessage && inputMessageType && (
                        <div
                            style={{
                                color: messageTypeParameter[inputMessageType].color
                            }}
                            className="my-2.5 flex items-center text-sm font-poppinsRegular "
                        >
                            <img className="mr-1.5" alt="input_message_type_icon" src={messageTypeParameter[inputMessageType].icon} />
                            <span className="inline-flex items-center">{inputMessage}</span>
                        </div>
                    )
                } */}
                <div
                    className={`w-full transition-all duration-700 top-[49px] h-[100px] overflow-y-scroll rounded-[10px] border-b-lg font-poppinsRegular bg-white shadow-[0px_2px_10px_rgba(201,201,201,0.25)]
					${isSelected ? 'absolute z-50' : 'hidden'} 
				`}
                >
                    <ul className="px-1.5 py-1 my-2">
                        {selectedOption?.length > 0
                            ? selectedOption?.map((option, index) => (
                                <li
                                    key={index}
                                    className="px-3 py-2 my-0.5 rounded-md hover:bg-[#F6F8F9] cursor-pointer"
                                    style={{
                                        // eslint-disable-next-line eqeqeq
                                        backgroundColor: selectedOption == option && '#e7e7e7'
                                    }}
                                    onClick={() => handleOption({ value: option })}
                                >
                                    {option}
                                </li>
                            ))
                            : <>No Record Found!</>}
                    </ul>
                </div>
            </div>
        </div>
    );
};
