import { useEffect, useState } from "react";
import "../modal.css";
import axios from "axios";
import { Modal } from "antd";
import { SelectDropdown } from "../../dropdown/SelectDropdown";
import { BASE_URL } from "../../../config/config";
import {
  baseUrl,
  commonPostApi,
  getApi,
  getHeaders,
} from "../../../services/common";
import { toast } from "react-toastify";

export const ReAssignModal = ({
  title,
  clientId,
  isModalOpen,
  closeModal,
  handleModal,
}) => {
  const [optionsData, setOptionsData] = useState([]);
  const [val, setVal] = useState("");

  const handleSaveVal = (value) => {
    setVal(value);
  };

  // get role
  const role = JSON.parse(
    JSON.parse(window.localStorage.getItem("persist:root"))?.user || ""
  )?.userDetails?.[0]?.role_code;
  const headers = getHeaders();

  // get placeholder
  const getPlaceholder = () => {
    switch (role) {
      case "VRFS":
        return "Verifier";

      case "RMS":
        return "RM";

      default:
        return "RM";
    }
  };

  const Placeholder = getPlaceholder();

  // const getData = (UserId) => {
  //   const getUrl = () => {
  //     switch (role) {
  //       case "VRFS":
  //         return "/GetVerifierMaster";

  //       case "RMS":
  //         return "/GetRMMaster";

  //       default:
  //         return "/GetRMMaster";
  //     }
  //   };
  //   const url = getUrl();
  //   getApi(url)
  //     .then((response) => {
  //       const data = response.data;
  //       const filereData = data.data.filter((item) => {
  //         return item.id !== UserId;
  //       });
  //       setOptionsData(filereData);
  //       console.log(filereData.length, data.data.length);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // GetCltInfo
  // const getCltFullInfo = () => {
  //   commonPostApi({ ClientId: clientId }, `${baseUrl}/GetCltFullInfo`, headers)
  //     .then((response) => {
  //       const data = response.data;
  //       // getData(data?.data?.UserId);
  //       console.log(data?.data?.UserId);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // handleSubmit
  const handleSubmit = () => {
    const getUrl = () => {
      switch (role) {
        case "VRFS":
          return "/AssignVerifier";

        case "RMS":
          return "/AssignRM";

        default:
          return "/AssignRM";
      }
    };
    const getPayload = () => {
      switch (role) {
        case "VRFS":
          return {
            client_id: clientId,
            verifier_id: parseInt(val),
          };

        case "RMS":
          return {
            client_id: clientId,
            rm_id: parseInt(val),
          };

        default:
          return {
            client_id: clientId,
            rm_id: parseInt(val),
          };
      }
    };
    const url = getUrl();
    const payload = getPayload();

    // call Api
    commonPostApi(payload, `${baseUrl}${url}`, headers)
      .then((response) => {
        const data = response.data;
        console.log(data);
        if (data.success) {
          toast.success(data.message);
          setVal();
          closeModal();
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong, Please try after sometime");
      });
  };

  // useEffect(() => {
  //   getCltFullInfo();
  // }, [clientId]);

  return (
    <div>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={() => {
          setVal();
          closeModal();
        }}
        centered={true}
        width={550}
        closeIcon={false}
        footer={null}
        className="w-full"
      >
        <div className="w-full mt-10">
          <div>
            <SelectDropdown
              label={`Select ${Placeholder} name`}
              isImportant={true}
              selectedValue={true}
              placeholder={`Please Select ${Placeholder} name`}
              options={optionsData || []}
              listHeight="h-[100px]"
              handleSaveVal={handleSaveVal}
            />
          </div>
          <div className="flex justify-end mt-32">
            <button
              className="bg-black text-white px-7 py-2 rounded"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
