import { clearKyc } from '../../actions/kyc/kyc.action';
import { changeStep1Error, changeStep1Input, uploadBankDetails, clearBankDetails } from '../../actions/dkyc/step1.action';

const mutableState = {
	bankDetails: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		selectedDcument: [],
		data: [],
		isBankProof: false

	},
	declarationInfoData: {},
	status: '',
};
const initState = {
	...mutableState
};

export const dkycStep1Reducer = (state = initState, action) => {

	const { type, value, isError, errorText } = action?.payload || {};
	const imageUrl = action?.payload?.['DocumentImages[]'] && URL.createObjectURL(action.payload['DocumentImages[]']);
	switch (action.type) {

		case changeStep1Input:
			return {
				...state,
				[type]: {
					...state[type],
					value,
					isError: false,
					errorText: ''
				}
			};
		case changeStep1Error:
			return {
				...state,
				[type]: {
					...state[type],
					isError,
					errorText
				}
			};


		case uploadBankDetails:
			return {
				...state,
				bankDetails: {
					...state.bankDetails,
					selectedDocument: imageUrl,
					isError: false,
					errorText: '',
					isBankProof: true
				}
			};

		case clearBankDetails:
			return {
				...state,
				bankDetails: {
					...state.bankDetails,
					value: '',
					selectedDocument: [],
					isError: false,
					errorText: '',
					isBankProof: false
				}
			};
		default:
			return state;
	}
};
