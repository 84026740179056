import { Link } from 'react-router-dom';
import { pngAssets, svgAssets } from '../../assets/asset';


export const BaseLogin = ({ children, image }) => {

    return (
        <div className="">
            <div className="w-full h-screen flex  lg:flex-row items-center justify-between p-4 lg:p-0">
                <div className="w-full lg:w-[50%] lg:flex items-center justify-center hidden ">
                    <img alt="login_screen_image" src={image ? image : pngAssets.dkyc.leftpart1} className='w-full h-screen' />
                </div>
                <div style={{overflow: 'scroll'}} className="w-full lg:w-[50%] h-screen  flex flex-col items-center justify-start lg:py-8 py-4">
                    <div className="w-full lg:w-[70%] flex items-center justify-start gap-10 pb-4 pt-2 lg:pt-0">
                        <Link
                            to="/login"
                        >
                            <div className='w-[120px] lg:w-[120px] '>
                                <img alt="app_logo" className='w-full h-auto' src={svgAssets.bigul} />
                            </div>
                        </Link>

                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};
