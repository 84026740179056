import { svgAssets } from '../../assets/asset';

export const SendDigilockerBtn = ({ height, isDisable, isSendDigilockerLink, sendLinkBtn, isSend, isSendDigilockerLinkLoading }) => {
	return (
		<button
			className={`px-5 h-[${height || '47px'}] flex items-center rounded-[10px] leading-6 text-white bg-[#007813]
				${isSend ? 'cursor-default bg-[#007813]' : 'bg-[#000]'}
			`}
			onClick={() => !isDisable && sendLinkBtn()}
			style={isSendDigilockerLink ? { marginBottom: '20px' } : { background: '#007813', marginBottom: '20px' }}
			disabled={isDisable}
		>
			{
				isSend ?
					<img className="mr-2" alt="back_icon" src={svgAssets.kyc.verify} />
					:
					<img className="mr-2" alt="back_icon" src={svgAssets.kyc.send} />

			}
			<span>{isSendDigilockerLinkLoading ? 'Sending please wait...' : isSend ? 'Successfully Send Digilocker Link' : 'Send Digilocker Link'}</span>
		</button>
	);
};
