import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getAnnualIncome, getEducationDetail, getExperienceDetail, getOccuptionDetail, saveOccuptionAllInfo, setOccuptionOptionAction } from '../../redux/actions/kyc/step4.action';
import { getApi, postApi , getApiWithoutLoader} from '../../services/common';

function * getOccuptionDetailSaga () {
	try {
		const data = yield call(getApiWithoutLoader, '/GetOccuptionMaster');
		yield put(setOccuptionOptionAction({ type: 'occuptionDetail', data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function * getAnnualIncomeSaga () {
	try {
		const data = yield call(getApiWithoutLoader, '/GetAnnualIncomeMaster');
		yield put(setOccuptionOptionAction({ type: 'annualIncome', data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function * getEducationDetailSaga () {
	try {
		const data = yield call(getApiWithoutLoader, '/GetEducationMaster');
		yield put(setOccuptionOptionAction({ type: 'educationDetail', data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function * getExperienceDetailSaga () {
	try {
		const data = yield call(getApiWithoutLoader, '/GetExperienceMaster');
		yield put(setOccuptionOptionAction({ type: 'experienceDetail', data: data?.data?.data || [] }));
	} catch (err) {
		console.log(err);
	};
};

function * saveOccuptionSaga ({ payload }) {
	try {
		const data = yield call(postApi, payload, '/save-occupational-info');
		console.log(data);
	} catch (err) {
		console.log(err);
	};
};

function * kycStep4Saga () {
	yield all([
		takeEvery(getOccuptionDetail, getOccuptionDetailSaga),
		takeEvery(getAnnualIncome, getAnnualIncomeSaga),
		takeEvery(getEducationDetail, getEducationDetailSaga),
		takeEvery(getExperienceDetail, getExperienceDetailSaga),
		takeEvery(saveOccuptionAllInfo, saveOccuptionSaga),
	]);
}

export default kycStep4Saga;
