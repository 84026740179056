import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dayjs from "dayjs";
import CustomDatePicker from "../../components/inputs/CustomDatePicker";
import { MyPopover } from "../../components/popover/Popover";
import { TableProgressBar } from "../../components/progressBar/TableProgressBar";
import { PageCount } from "../../components/reactTable/PageCount";
import { ReactTable } from "../../components/reactTable/ReactTable";
import { ReactTableHeader } from "../../components/reactTable/ReactTableHeader";
import {
  PopoverChildComp,
  PopoverParentComp,
} from "../../components/reactTable/ReactTablePopupBtn";
import { ReAssignModal } from "../../components/modal/admin/ReAssignModal";
import { SearchBar } from "../../components/searchbar/SearchBar";
import { verificationSteps } from "../../containers/kyc/kycData";
import { getApplicationAction } from "../../redux/actions/application.action";

export const RejectedApplication = () => {
  const dispatch = useDispatch();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const applicationData = useSelector(
    (state) => state?.application?.rejectedApplication?.data || []
  );
  const [selectedOption, setSelectedOption] = useState('');

  const userDetail = useSelector(
    (state) => state?.user?.userDetails?.[0] || {}
  );
	const { rejectedApplication, incompleteApplication, applicationStatus } = useSelector(state => state?.application || []);

  const [isLoading, setIsLoading] = useState(true);
  const [clientId, setClientId] = useState();
  const [search1, setSearch] = useState('');


  const handleReassignModal = (data) => {
    setIsModelOpen(true);
    setClientId(data.ClientId);
  };


  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);



  const columns = [
    {
      Header: "Client",
      accessor: "clientName",
      minWidth: 130,
      Cell: ({ row }) =>
        `${row.original?.FName || ""} ${row.original?.MName || ""} ${row.original?.LName || ""
        }`,
    },
    {
      Header: "PAN",
      accessor: "pan",
      minWidth: 130,
      Cell: ({ row }) => row.original?.PanNo,
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 130,
      Cell: ({ row }) => row?.original?.IsETrade == null?'Bonanza':"Bigul",
    },
    {
			Header: "verifier name",
			accessor: "v_name",
			minWidth: 230,
			Cell: ({ row }) =>
				row?.original?.verifier_name
		},
   
    {
      Header: "Reason",
      accessor: "reason",
      minWidth: 150,
      Cell: ({ row }) => row.original?.rejected_reasons,
    },
    // {
    //   Header: "Steps",
    //   accessor: "steps",
    //   minWidth: 150,
    //   Cell: ({ row }) => (
    //     <TableProgressBar
    //       selectedStep={row.original?.step}
    //       steps={steps || []}
    //       setSteps={setSteps}
    //       reject={row?.original?.rejected_reason}
    //       width="w-full"
    //       circleDim="10px"
    //       circleRadius="1px"
    //       lineDim="h-[2px]"
    //     />
    //   ),
    // },
    {
      Header: "Created At",
      accessor: "createdAt",
      minWidth: 130,
      Cell: ({ row }) =>
        row.original?.created_at &&
        dayjs(row.original?.created_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
      sortable: true,
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      minWidth: 130,
      Cell: ({ row }) =>
        row.original?.updated_at &&
        dayjs(row.original?.updated_at).format("DD MMM YYYY"+' '+'HH:mm:ss'),
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 60,
      Cell: ({ row }) => ( 
        <MyPopover
          PopoverParentComp={PopoverParentComp}
          PopoverChildComp={PopoverChildComp}
          isVerify={true}
          redirectUrl="/application/rejected-application"
          applicationType="allApplication"
          selectedData={row.original}
          isView={true}
          handleReassignModal={handleReassignModal}
          viewUrl="/application/rejected-application"
      
        />
      ),
    },
  ];

  const handleApplication = ({ start, end, search }) => {
    dispatch(
      getApplicationAction("rejectedApplication", {
        UserId: userDetail.user_id,
        RoleId: userDetail.role_id,
        // KYCStatus: "rework",
        start: start || 1,
        end: end || 20,
        search: search || "",
        channel_type:selectedOption

      })
    );
  };
 

  useEffect(() => {
    handleApplication({ start: 1, end: 50, search: search1 });
  }, [selectedOption]);
  // useEffect(() => {
  //   handleApplicationfilter({ start: 1, end: 50, search: "" });
  // }, [selectedOption]);

  // const handleApplication = ({ applicationType, status }) => {
  // 	dispatch(getApplicationAction(applicationType, {
  // 		UserId: userDetail.user_id,
  // 		RoleId: userDetail.role_id,
  // 		KYCStatus: status,
  // 		start: 1,
  // 		end: 50,
  // 		search: ''

  // 	}));
  // };

  // useEffect(() => {
  // 	dispatch(getApplicationStatusAction({ UserId: userDetail.user_id, RoleId: userDetail.role_id }));
  // 	handleApplication({ applicationType: 'applicationData', status: 'reject' });
  // }, []);
  const handleSearch = ({ searchVal }) => {

    setSearch(searchVal)
      handleApplication({ start: "", end: "", search: searchVal });
    

  };

  return (
    <>
      <div className="w-full flex flex-col ">
        <div className="w-full py-5 px-[15px] md:px-[25px] mb-[30px] overflow-auto rounded-[10px] bg-white shadow-[0px_4px_15px_rgba(171,171,171,0.25)] ">
          <div className="flex flex-col md:flex-row gap-y-5  items-center justify-between mb-7 ">
            <ReactTableHeader
              title="Rejected Applications"
              numberOfApplications={applicationStatus?.[0]?.TotalRMRework}
            />
            {/* {userDetail.role_code === "RMS" ? (
              <>
                <button className="bg-[#EBFFFA] px-7 py-2 rounded font-semibold shadow">
                  View All
                </button>
              </>
            ) : (
              <PageCount />
            )} */}
          </div> 
          {userDetail.role_code !== "RMS" && (
            <div className="mb-6 flex items-center ">
              <SearchBar border={"border"}
              data={applicationData}
              handleSelect={handleSearch} />

              <div style={{ height: '50px', width: '150px', borderWidth: 0.2, marginLeft: '20px', borderRadius: '10px', textAlign: 'center', alignItems: 'center' }}>
              <select value={selectedOption} onChange={handleSelectChange} style={{ width: '100%', height: '100%', border: 'none', outline: 'none', borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
                <option value="All">All</option>
                <option value="Bigul">Bigul</option>
                <option value="Bonanza">Bonanza</option>
              </select>
            </div>
            
            </div>
          )}
          {/* {userDetail.role_code === "RMS" && (
            <>
              <div className="flex justify-end mb-10">
                <button
                  className="bg-[#5367FC] px-7 py-2 rounded font-semibold shadow text-white"
                  onClick={handleReassignModal}
                >
                  Re-assign All Applications{" "}
                </button>
              </div>
            </>
          )} */}

          {isLoading ? (
            <>
              <SkeletonTheme baseColor="#e7e7e7" highlightColor="#dedede">
                <Skeleton
                  count={10}
                  style={{ height: "30px", marginTop: "10px" }}
                  borderRadius={10}
                />
              </SkeletonTheme>
            </>
          ) : (
            <ReactTable columns={columns} data={applicationData || []} />
          )}
        </div>
      </div>
      <ReAssignModal
        title="Re-assign Tasks"
        isModalOpen={isModelOpen}
        clientId={clientId}
        closeModal={() => {
          setIsModelOpen(false);
          setClientId();
        }}
      />
    </>
  );
};
