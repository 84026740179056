

// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'

// import { pngAssets, svgAssets } from '../../../assets/asset';

// import { changeStep6InputAction, getIncomeProofListAction, uploadBankProofAction, getBrokerageSchemeDetailDataAction, callSendUploadIncomeProofLinkAction } from '../../../redux/actions/kyc/step6.action';

// import { getClientFilesAction } from '../../../redux/actions/kyc/review.action';
// import { BranchCodeDropdown } from '../../../components/dropdown/branchCodeDropdown';
 

// export const UploadBankProof = ({ isDocumentUploaded, uploadDone,rejectedFieldsData,selectedStepData }) => {
	
// 	const dispatch = useDispatch();
// 	const { incomeProof } = useSelector(state => state.kycStep6);

// 	const [incomeProofList, setIncomeProofList] = useState([]);
// 	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
// 	const kycStep2 = useSelector(state => state?.kycStep2 || {});
// 	const reviewFiles = useSelector(state => state?.reviewData?.filesData || {});
// 	let user_doc = [];
// 	const client_file = reviewFiles.length > 0 && reviewFiles?.filter(a => (a.Document === 'Bank Proof'));
// 	const lastItemIndex = client_file?.length - 1;
// 	const [files, setFiles] = useState([]);
// 	const [isUpload3MonthSalarySlip, setIsUpload3MonthSalarySlip] = useState(false);
// 	const [selectedFile, setSelectedFile] = useState();
// 	const [isGetClientFiles, setIsGetClientFiles] = useState(false);


	
// 	useEffect(() => {
// 		// let bankinterval;
// 		// if (kycData.isBankProofUploaded?.success !== true) {
// 		//     bankinterval = setInterval(() => {
// 		//         dispatch(callCheckDocumentIsUploadedAction(
// 		//             {
// 		//                 "mobile": kycStep1?.mobileInput?.value || '',
// 		//                 "pan": kycStep2.panInput.value || '',
// 		//                 "document_type": "Bank Proof"
// 		//             }
// 		//         ));
// 		//     }, 5000);
// 		// }
// 		// return () => clearInterval(bankinterval);
// 	}, []);

// 	useEffect(() => {
// 		getClientDocuments();
// 	}, [isGetClientFiles])




// 	const getClientDocuments = () => {
// 		if (client_file) {
// 			dispatch(getClientFilesAction({
// 				PanNo:selectedStepData?.PanNo,
// 				CreatedBy: userDetail?.created_by
// 			}));
// 		}
// 	}
// 	const handleIncomeProof = (res) => {
// 		console.log("incomeProofList", res)
// 		setIncomeProofList(res);
// 	}

// 	useEffect(() => {
// 		if (reviewFiles) {
// 			user_doc = reviewFiles && reviewFiles?.filter(a => (a.Document === 'Bank Proof'));
// 		}
// 	}, [])


// 	const handleImage = (e) => {

// 		if (!e.target.files || e.target.files.length === 0) {
// 			uploadDone(false)
// 			setSelectedFile(undefined);
// 			return;
// 		}
// 		dispatch(uploadBankProofAction({
// 			PanNo: selectedStepData?.PanNo,
// 			Document: 'Bank Proof',
// 			DocumentType: 'NA',
// 			CreatedBy: userDetail.created_by,
// 			step: 15,
// 			'DocumentImages[]': e.target.files[0]
// 		}));

// 		setSelectedFile(e.target.files[0]);
// 		setIsGetClientFiles(!isGetClientFiles);
// 		uploadDone(true)
// 	};

// 	const handleFileSelect = (event, index) => {
// 		const newFiles = [...files];
// 		newFiles[index] = event.target.files[0];
// 		setFiles(newFiles);
// 	};

// 	const handleInput = ({ type, value }) => {
// 		dispatch(changeStep6InputAction({ type, value }));
// 		if (type == 'BankProof') {
// 			if (value == 'Latest 3 Month Salary Slip') {
// 				setIsUpload3MonthSalarySlip(true);
// 			} else {
// 				setIsUpload3MonthSalarySlip(false);
// 			}
// 		}
// 	};

// 	// send link
	

// 	return (
// 		<>
// 			<div className="w-full mb-8 grid md:grid-cols-12 gap-10 items-start">
// 				<div className="w-full col-span-6 ">
// 					{/* {incomeProofList.length > 0 ?
// 						<SelectDropdown
// 							label="Upload Bank Proof"
// 							placeholder="Please select documents type"
// 							options={incomeProofList}
// 							selectedValue={incomeProof.value}
// 							inputType="incomeProof"
// 							handleSelect={handleInput}
// 							inputMessage={incomeProof.errorText || ''}
// 							inputMessageType={incomeProof.isError && 'error'}
// 						/> : 'Loading...'} */}

// 					<BranchCodeDropdown
// 						label="Upload Bank Proof"
// 						placeholder="Please select BankProof"
// 						options={["Bank Statement",'Cancelled Cheque Leaf','Latest 3 Month Salary Slip'] || []}
// 						inputType="BankProof"
// 						handleSelect={handleInput}
// 						isDisabled={isDocumentUploaded && isDocumentUploaded?.success}

// 					/>

// 					{client_file && client_file?.map((item, index) => {
// 						const isLastItem = index === lastItemIndex;
// 						return (
// 							<>
// 								{isLastItem &&
// 									<div key={index} className='bg-white p-2 w-[150px] h-[130px] flex items-center justify-center mt-3'>
// 										<a href={item?.ImagePath} target='_blank'>
// 											<img width='80' src={item?.ImagePath} />
// 										</a>
// 									</div>}
// 							</>
// 						);
// 					})}
// 				</div>

// 				<div className="w-full flex items-center justify-between col-span-6 mt-10">
// 					<div className='w-full'>
// 						<div className='w-full flex items-center justify-between'>
// 							{
// 								isUpload3MonthSalarySlip ?
// 									<>
// 										<div>

// 											<div className='flex items-center justify-between'>
// 												<button
// 													className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  bg-black relative`}
// 												>
// 													{
// 														incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
// 															<input
// 																type="file"
// 																accept="image/*"
// 																onChange={(e) => handleFileSelect(e, 0)}
// 															/>
// 														)
// 													}
// 													<img
// 														src={svgAssets.kyc.uploadWhite}
// 														className="mr-1.5"
// 													/>
// 													<span>Choose file 1</span>
// 												</button>
// 												{
// 													files?.[0]?.name &&
// 													<>
// 														<div className="ml-[30px] flex flex-col items-center">
// 															<img
// 																className="rounded-[10px]"
// 																alt="client_file"
// 																src={pngAssets.kyc.doc}
// 															/>
// 															<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 																{files?.[0]?.name}
// 															</span>
// 														</div>
// 													</>
// 												}
// 											</div>
// 											<div className='flex items-center justify-between'>
// 												<button
// 													className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px] bg-black  relative mt-2`}
// 												>
// 													{
// 														incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
// 															<input
// 																type="file"
// 																accept="image/*"
// 																onChange={(e) => handleFileSelect(e, 1)}
// 															/>
// 														)
// 													}
// 													<img
// 														src={svgAssets.kyc.uploadWhite}
// 														className="mr-1.5"
// 													/>
// 													<span>Choose file 2</span>
// 												</button>
// 												{
// 													files?.[1]?.name &&
// 													<>
// 														<div className="ml-[30px] flex flex-col items-center">
// 															<img
// 																className="rounded-[10px]"
// 																alt="client_file"
// 																src={pngAssets.kyc.doc}
// 															/>
// 															<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 																{files?.[1]?.name}
// 															</span>
// 														</div>
// 													</>
// 												}
// 											</div>
// 											<div className='flex items-center justify-between'>
// 												<button
// 													className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  bg-black relative mt-2`}
// 												>
// 													{
// 														incomeProof?.value?.length > 0 && incomeProof.value !== '' && (
// 															<input
// 																type="file"
// 																accept="image/*"
// 																onChange={(e) => handleFileSelect(e, 2)}
// 															/>
// 														)
// 													}
// 													<img
// 														src={svgAssets.kyc.uploadWhite}
// 														className="mr-1.5"
// 													/>
// 													<span>Choose file 3</span>
// 												</button>
// 												{
// 													files?.[2]?.name &&
// 													<>
// 														<div className="ml-[30px] flex flex-col items-center">
// 															<img
// 																className="rounded-[10px]"
// 																alt="client_file"
// 																src={pngAssets.kyc.doc}
// 															/>
// 															<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 																{files?.[2]?.name}
// 															</span>
// 														</div>
// 													</>
// 												}
// 											</div>

// 										</div>
// 									</>
// 									:
// 									<button style={{backgroundColor:'black'}}
// 										className={`h-[47px] px-[18px] flex items-center text-white rounded-[10px]  relative 'bg-black cursor-pointer'`}
// 										// isDisabled={}
// 									>
// 										{
// 											(
// 												<input
// 													type="file"
// 													accept="image/*"
// 													onChange={(e) => handleImage(e)}
// 												/>
// 											)
// 										}
// 										<img
// 											src={svgAssets.kyc.uploadWhite}
// 											className="mr-1.5"
// 										/>
// 										<span>Choose file</span>
// 									</button>
// 							}

// 							{
// 								selectedFile && (<div className="ml-[30px] flex flex-col items-center">
// 									<img
// 										className="rounded-[10px]"
// 										alt="client_file"
// 										src={pngAssets.kyc.doc}
// 									/>
// 									<span className="w-[65px] mt-1 truncate font-poppinsRegular text-xs">
// 										{selectedFile?.name}
// 									</span>
// 								</div>)
// 							}
// 							{isDocumentUploaded && isDocumentUploaded?.success && user_doc.length > 0 && user_doc?.map((item, index) => {
// 								const isLastItem = index === lastItemIndex;
// 								return (
// 									<>
// 										{isLastItem &&
// 											<div className='relative'>
// 												<div key={index} className='bg-white p-2 w-[150px] h-[80px] flex items-center justify-center'>
// 													<img width='60' src={item?.ImagePath} />
// 												</div>

// 												<a href={item?.ImagePath} target='_blank' className='absolute right-2 top-2'>
// 													<ArrowDownTrayIcon className="h-8 w-8 text-blue-500 " />
// 												</a>
// 											</div>
// 										}
// 									</>
// 								);
// 							})}

// 							{
// 								client_file &&
// 								<button
// 									className={`h-[47px]  px-[18px] flex items-center text-white rounded-[10px] relative bg-[#007813]`}
// 								>
// 									<img
// 										src={svgAssets.kyc.verify}
// 										className="mr-1.5"
// 									/>
// 									<span>
// 										Uploaded
// 									</span>
// 								</button>
// 							}
// 						</div>
// 						{/* <div className='w-full'>
// 							{
// 								kycStep6?.incomeProof?.isSendLink && <>
// 									<div className="px-8 py-6 mt-[30px] bg-[#E0FFE5] rounded-[20px] mb-5">
// 										<div className="grid md:grid-cols-1 gap-1">
// 											<h6 className="font-poppinsMedium font-medium col-span-1  flex w-full text-sm">Upload Income Proof link send successfully,please check email.
// 												<img className="ml-2 text-[#007813]" alt="back_icon" src={svgAssets.kyc.greencheck} width='20px' height='20px' />
// 											</h6>
// 										</div>
// 										<button className='btn btn-primary bg-black text-white text-sm px-3 rounded mt-2 cursor-pointer' onClick={handleSendLink}>Resend Link</button>
// 									</div>
// 								</>
// 							}
// 						</div> */}
// 					</div>

// 				</div>

// 			</div>
// 		</>
// 	);
// };

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipboardIcon, UserPlusIcon } from '@heroicons/react/24/solid'
import { svgAssets } from '../../../assets/asset';
import { InputCopyBtnIcon } from '../../../components/inputs/InputCopyBtnIcon';

import { DOMAIN } from '../../../config/config';
import { InputCopyBtnIcon1 } from '../../../components/inputs/RejectInput';


export const UploadBankProof = ({ linktoken, isChildComp, rejectedFieldsData }) => {

	const reviewFiles = useSelector(state => state?.reviewData?.filesData || []);

	const { isError, errorText } = useSelector(state => state?.kycStep7 || '');


	let clientLink = DOMAIN + '/dkyc/';
	if (linktoken) {
		clientLink = DOMAIN + '/dkyc/' + linktoken + '?isSelfie=1';
	}

	const [isCopy, setIsCopy] = useState(false);
	const user_image = reviewFiles && reviewFiles?.filter(a => ((a?.Document === 'Bank Proof') && (a?.ReUploadFlag == 1)));
	const lastItemIndex = user_image?.length - 1;

	const handleCopyLink = () => {
		navigator.clipboard.writeText(clientLink);
		setIsCopy(true);
	}

	return (
		<div className="w-full flex flex-col rejected-otem-list">
			<div className={`${!isChildComp}`}>
				<div className="mb-0 grid md:grid-cols-2 gap-x-10 items-center mt-0">
					<div className="flex flex-col ">
						<div className={`text-md leading-[27px] font-medium font-poppinsMedium text-center md:text-left whitespace-nowrap mb-2`} style={{ color: '#FF4343' }}>
						{rejectedFieldsData !== null ?rejectedFieldsData :'Bank proof is not valid'}

						</div>
						<InputCopyBtnIcon1
							icon={svgAssets.kyc.UploadBankProof}
							// label="Bank Proof Link"
							// subLabel="Share below link with client"
							isImportant={true}
							placeholder={clientLink}
							btnWidth="67px"
							btnIcon={ClipboardIcon}
							inputMessage={errorText}
							inputMessageType={isError && 'error'}
							handleSubmit={handleCopyLink}
							defaultValue={clientLink}
							isDisable={true}
							isCopy={isCopy}
						/>

					</div>
					<div className="flex flex-col relative">
						<div className='hover:text-[#5466fc] relative flex items-center justify-end' >
							{user_image && user_image.map((item, index) => {
								const isLastItem = index === lastItemIndex;
								return (
									<>
										{isLastItem &&
										<>
										<p>Re-uploaded Document By the client</p>
											<div key={index} className='bg-white p-1 ml-2 flex items-center justify-center'>
												<a href={item?.ImagePath} target='_blank'>
													<img width='80' src={item?.ImagePath} />
												</a>
											</div>
											</>}
									</>
								);
							})}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

