import { clearKyc } from '../../actions/kyc/kyc.action';
import { setKRA } from '../../actions/kyc/step2.action';
import { changeStep4Error, changeStep4Input, setOccuptionOption ,unsetOccuptionOption } from '../../actions/kyc/step4.action';

const mutableState = {
	occuptionDetail: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		options: []
	},
	annualIncome: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		options: []
	},
	educationDetail: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		options: []
	},
	experienceDetail: {
		value: '',
		isError: false,
		errorText: '',
		status: 'edit',
		options: []
	},
	status: ''
};

const initState = {
	...mutableState
};

export const kycStep4Reducer = (state = initState, action) => {
	const { type, value, isError, errorText } = action?.payload || {};
	switch (action.type) {
	case clearKyc:
		return {
			...mutableState
		};
	case changeStep4Input:
		return {
			...state,
			[type]: {
				...state[type],
				value,
				isError: false,
				errorText: ''
			}
		};
	case changeStep4Error:
		return {
			...state,
			[type]: {
				...state[type],
				isError,
				errorText
			}
		};
	case setOccuptionOption:
		return {
			...state,
			[type]: {
				...state[type],
				options: action?.payload?.data?.length > 0
					? action.payload.data.map(el => ({
						label: el.name,
						value: String(el.id)
					}))
					: []
			}
		};
	case unsetOccuptionOption:
		return {
			...state,
			[type]: {
				...state[type],
				value,
				isError,
				errorText
			}
		};
	case setKRA:
		return {
			...state,
			occuptionDetail: {
				...state.occuptionDetail,
				value: Number(action.payload.data.APP_OCC)
			},
			annualIncome: {
				...state.annualIncome,
				value: Number(action.payload.data.APP_INCOME)
			}
		};
	default:
		return state;
	}
};
