import React, { useState } from 'react';

export const Hadding3 = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const accordionData = [
        { title: '1. What information should my bank statement include?', content: 'Your bank statement should show your name, address, account number, and recent transactions. Typically, statements from the last three to six months are required.' },
        { title: '2. Can I use a cancelled cheque as bank proof?', content: 'Yes, a cancelled cheque leaf from your bank account can serve as proof of account ownership and verify your bank account details, including your account number and MICR code.' },
        { title: '3. Is a bank passbook acceptable as bank proof?', content: 'Yes, a photocopy of the relevant pages of your bank passbook, including your name, account number, and the banks stamp or seal, is acceptable.' },
        { title: '4. What if my bank offers online verification? ', content: 'If available, you can link your bank account electronically through your online banking portal or other secure authentication methods to verify your account details.' },
    ];

    return (
        <>
        <p style={{fontWeight:'bold',marginLeft:'10%',marginBottom:'20px',marginTop:'20px'}}>FAQs for Invalid Bank Proof</p>
        <div style={styles.accordionContainer}>
           {accordionData.map((item, index) => (
                <div key={index} style={styles.accordionItem}>
                    <div
                        style={styles.accordionHeader}
                        onClick={() => handleToggle(index)}
                    >
                        <h3 style={{fontWeight:'bold'}}>{item.title}</h3>
                        
                    </div>
                    <div
                        style={{
                            ...styles.accordionContent,
                            display: index === activeIndex ? 'block' : 'none'
                        }}
                    >
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

const styles = {
    accordionContainer: {
        width: '80%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        
    },
    accordionItem: {
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px'
    },
    accordionHeader: {
        padding: '15px',
        // backgroundColor: '#007BFF',
        // color: '#fff',
        cursor: 'pointer'
    },
    accordionContent: {
        padding: '10px',
        borderTop: '1px solid #ddd',
        backgroundColor: '#fff'
    }
};





